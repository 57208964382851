import * as t from "io-ts";

export enum CryptoCurrency {
  AAVE = "AAVE",
  ALCX = "ALCX",
  ALGO = "ALGO",
  AMP = "AMP",
  ANKR = "ANKR",
  APE = "APE",
  API3 = "API3",
  AUDIO = "AUDIO",
  AVAX = "AVAX",
  AXS = "AXS",
  BAT = "BAT",
  BCH = "BCH",
  BNB = "BNB",
  BNT = "BNT",
  BOND = "BOND",
  BTC = "BTC",
  CHZ = "CHZ",
  COMP = "COMP",
  CRV = "CRV",
  CUBE = "CUBE",
  CVC = "CVC",
  DAI = "DAI",
  DOGE = "DOGE",
  DOT = "DOT",
  ELON = "ELON",
  ENS = "ENS",
  ETH = "ETH",
  FET = "FET",
  FIL = "FIL",
  FTM = "FTM",
  GAL = "GAL",
  GALA = "GALA",
  GMT = "GMT",
  GRT = "GRT",
  GUSD = "GUSD",
  INJ = "INJ",
  JAM = "JAM",
  KNC = "KNC",
  KP3R = "KP3R",
  LDO = "LDO",
  LINK = "LINK",
  LPT = "LPT",
  LRC = "LRC",
  LTC = "LTC",
  MANA = "MANA",
  MASK = "MASK",
  MATIC = "MATIC",
  MCO2 = "MCO2",
  MKR = "MKR",
  MOB = "MOB",
  NMR = "NMR",
  OXT = "OXT",
  PAXG = "PAXG",
  QNT = "QNT",
  QRDO = "QRDO",
  RAY = "RAY",
  REN = "REN",
  RNDR = "RNDR",
  SAMO = "SAMO",
  SAND = "SAND",
  SBR = "SBR",
  SHIB = "SHIB",
  SKL = "SKL",
  SLP = "SLP",
  SNX = "SNX",
  SOL = "SOL",
  STORJ = "STORJ",
  SUSHI = "SUSHI",
  UMA = "UMA",
  UNI = "UNI",
  USDC = "USDC",
  USDT = "USDT",
  XLM = "XLM",
  XRP = "XRP",
  XTZ = "XTZ",
  YFI = "YFI",
  ZBC = "ZBC",
  ZEC = "ZEC",
  ZRX = "ZRX",
}

export enum ContractType {
  ERC20 = "ERC-20",
  SPL = "SPL",
}

export const cryptoAddressCodec = t.intersection([
  t.type({
    name: t.string,
    symbol: t.string,
    address: t.string,
  }),
  t.partial({
    network: t.union([t.string, t.undefined]),
    tagMemo: t.union([t.string, t.undefined]),
  }),
]);

export const cryptoAddressesCodec = t.array(cryptoAddressCodec);

export type CryptoAddressResponse = t.TypeOf<typeof cryptoAddressCodec>;
