import { Auth0Error } from "auth0-js";

/**
 * Extracts a user-displayable error message from an Auth0Error.
 *
 * Auth0 has multiple "error description" fields and is not consistent about
 * what it uses. Sometimes the error messages are very unhelpful. This helper
 * helps us extract a non-null error message.
 *
 * TODO: Expand this after trying out different error states.
 */
export function getAuth0ErrorMessage(error: Auth0Error): string {
  if (error.code) {
    switch (error.code) {
      case "request_error":
        // When there is no internet, we get back this error code but the error
        // description from Auth0 is not helpful at all.
        return "We couldn't connect. Please try again later.";
    }
  }

  // Take the first non-null error description.
  return error.error_description || error.errorDescription || error.error;
}
