import { either } from "fp-ts/Either";
import { Type, UnknownRecord, string, failure, success, TypeOf } from "io-ts";

/**
 * Codec that takes an object to encode as JSON
 */
export const jsonStringCodec = new Type<TypeOf<typeof UnknownRecord>, string>(
  "JSONString",
  UnknownRecord.is,
  function validateJsonString(input, ctx) {
    return either.chain(string.validate(input, ctx), (strInput) => {
      try {
        return success(JSON.parse(strInput));
      } catch (err) {
        return failure(input, ctx, "Could not parse JSON string");
      }
    });
  },
  function encodeToJsonString(value) {
    return JSON.stringify(value);
  }
);
