export enum TextSize {
  xxl = "xxl",
  xl = "xl",
  l = "l",
  m = "m",
  s = "s",
  xs = "xs",
  xxs = "xxs",
  xxxs = "xxxs",
}

/**
 * Standard text sizes expressed as number of px
 *
 * Unless necessary for calculations, prefer to use the textSizeCss mixins,
 * since they a) define sizes as rems which respect user preferences
 */
export const numPxTextSizes = {
  [TextSize.xxl]: 48,
  [TextSize.xl]: 32,
  [TextSize.l]: 24,
  [TextSize.m]: 20,
  [TextSize.s]: 16,
  [TextSize.xs]: 14,
  [TextSize.xxs]: 12,
};

export const numPxLineHeights = {
  [TextSize.xxl]: 56,
  [TextSize.xl]: 36,
  [TextSize.l]: 32,
  [TextSize.m]: 24,
  [TextSize.s]: 24,
  [TextSize.xs]: 20,
  [TextSize.xxs]: 16,
};
