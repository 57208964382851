import { useAmountLimits } from "@components/donate/DonateV3/PaymentProcess/useAmountLimits";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { DonateFormSearchValues } from "@components/donate/DonateV3/useDonateFormSearchParams";
import { outsideBoundsErrorMessage } from "@components/donate/helpers";
import { Big } from "big.js";
import { FieldPath, UseFormSetError } from "react-hook-form";

import { isEmail } from "@every.org/common/src/codecs/contact";
import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import {
  commentTextCodec,
  MAX_COMMENT_LEN,
} from "@every.org/common/src/codecs/text";
import {
  Currency,
  DonationFrequency,
} from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import {
  donationValueIsInvalid,
  validDonationValueOrUndefined,
} from "@every.org/common/src/helpers/donationValue";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";

import { logger } from "src/utility/logger";
import { LATIN_NAME_REGEX } from "src/utility/userValidators";

// TODO #8478: parametrize currency
export function validateCryptoNumber({
  amount,
  shorten,
}: {
  amount: string;
  shorten: boolean;
}): string | undefined {
  let amountBig: Big;
  try {
    amountBig = new Big(amount);
  } catch (e) {
    return shorten
      ? "Please enter an amount"
      : "Please enter an amount to continue";
  }

  return amountBig.lte(0) ? "Amount must be greater than 0" : undefined;
}

// TODO #8478: parametrize currency
export function validateNumber({
  amount,
  shorten,
  minValue,
  maxValue,
}: {
  amount: string;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
  shorten: boolean;
}): string | undefined {
  let amountBig: Big;
  try {
    amountBig = new Big(amount);
  } catch (e) {
    return shorten
      ? "Please enter an amount"
      : "Please enter an amount to continue";
  }

  // TODO #8478: consider the selected currency
  const valueError = donationValueIsInvalid({
    value: { currency: Currency.USD, amount: amountBig },
    minValue,
    maxValue,
  });
  if (valueError) {
    return outsideBoundsErrorMessage({
      // TODO #8478: consider the selected currency
      currency: Currency.USD,
      maxOrMin: valueError,
      shorten,
      minValue,
      maxValue,
    });
  }

  if (amount.length && !isNaN(Number(amount))) {
    return undefined;
  }
  logger.error({ message: "Error parsing amount value", data: { amount } });
  return "Error parsing amount";
}

export function validateCryptoAmountAndCurrency({
  currency,
  amount,
  shorten,
  setError,
}: {
  currency?: CryptoCurrency;
  amount: string;
  shorten: boolean;
  setError: UseFormSetError<DonateFormType>;
}) {
  const currencyValidationResult = currency
    ? undefined
    : "Please choose a crypto currency";
  const amountValidationResult = validateCryptoNumber({
    amount,
    shorten,
  });
  if (currencyValidationResult) {
    setError("cryptoCurrency", {
      type: "string",
      message: currencyValidationResult,
    });
  }
  if (amountValidationResult) {
    setError("cryptoPledgeAmount", {
      type: "string",
      message: amountValidationResult,
    });
  }
  return !(currencyValidationResult || amountValidationResult);
}

/**
 * Validates amount and frequency values for the form.
 *
 * Returns true if they validate and false if either fails validation.
 */
export function validateAmountAndFrequency({
  frequency,
  amount,
  minValue,
  maxValue,
  shorten,
  setError,
}: {
  frequency?: DonationFrequency;
  amount: string;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
  shorten: boolean;
  setError: UseFormSetError<DonateFormType>;
}) {
  const frequencyValidationResult = frequency
    ? undefined
    : "Please choose a donation frequency";
  const amountValidationResult = validateNumber({
    amount,
    minValue,
    maxValue,
    shorten,
  });
  if (frequencyValidationResult) {
    setError("frequency", {
      type: "string",
      message: frequencyValidationResult,
    });
  }
  if (amountValidationResult) {
    setError("amount", { type: "string", message: amountValidationResult });
  }
  return !(frequencyValidationResult || amountValidationResult);
}

export function getValidCommentText(text?: string) {
  return text && commentTextCodec.is(text) ? text : undefined;
}

export function validateCommentText(
  text: string | undefined,
  setError: UseFormSetError<DonateFormType>,
  errorName: FieldPath<DonateFormType>,
  limitOverride?: number
) {
  if (
    text?.length &&
    (!getValidCommentText(text) ||
      (limitOverride && text.length > limitOverride))
  ) {
    setError(errorName, {
      type: "string",
      message: `Max note length is ${limitOverride || MAX_COMMENT_LEN}.`,
    });
    return false;
  }

  return true;
}

export function useValidValuesFromSearchParams(
  values: DonateFormSearchValues,
  options: { minDonationValue?: CurrencyValue }
) {
  const [minValue, maxValue] = useAmountLimits(
    values.minValue || options.minDonationValue
  );
  const validAmount =
    values.amount &&
    !!validDonationValueOrUndefined({
      value: { currency: Currency.USD, amount: new Big(values.amount) },
      minValue,
      maxValue,
    })
      ? values.amount
      : undefined;

  return removeUndefinedOrNullValues({
    ...values,
    amount: validAmount,
  });
}

export function validateStockSymbolAndAmount({
  stockSymbol,
  stockAmount,
  setError,
}: {
  stockSymbol?: string;
  stockAmount?: number;
  setError: UseFormSetError<DonateFormType>;
}) {
  let valid = true;
  if (!stockSymbol || stockSymbol.length === 0) {
    setError("stockSymbol", {
      message: "Please enter a valid stock symbol",
    });
    valid = false;
  }

  if (!stockAmount) {
    setError("stockAmount", {
      message: "Please enter a valid share amount",
    });
    valid = false;
  }

  return valid;
}

export function validateUserEmail(
  email: string | undefined,
  setError: UseFormSetError<DonateFormType>
) {
  if (!email || email.length === 0) {
    setError("email", { message: "Email is required." });
    return false;
  }
  const validationResult = isEmail(email);

  if (!validationResult) {
    setError("email", { message: "Incorrect email format." });
    return false;
  }

  return true;
}

export function validateText(
  value: string | undefined,
  varName: keyof DonateFormType,
  humanVarName: string,
  setError: UseFormSetError<DonateFormType>
) {
  if (!value || value.length === 0) {
    setError(varName, { message: `${humanVarName} is required.` });
    return false;
  }
  return true;
}

export function validateFullNameText(
  value: string | undefined,
  varName: keyof DonateFormType,
  humanVarName: string,
  setError: UseFormSetError<DonateFormType>
) {
  if (!value || value.length === 0) {
    setError(varName, { message: `${humanVarName} is required.` });
    return false;
  }
  if (!LATIN_NAME_REGEX.test(value)) {
    setError(varName, { message: "Only latin letters may be used" });
    return false;
  }
  return true;
}

export function validateBase64(
  value: string | undefined,
  varName: keyof DonateFormType,
  humanVarName: string,
  setError: UseFormSetError<DonateFormType>
) {
  if (!value || value.length === 0) {
    setError(varName, { message: `${humanVarName} is required.` });
    return false;
  }
  return true;
}
