import { css } from "@emotion/react";

import { spacing } from "@every.org/common/src/display/spacing";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";

export const pageGridCss = (hideFundraisers?: boolean) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      padd-tpadding-top: ${spacing.xl};
      ${hideFundraisers
        ? css`
            grid-template-columns: 8fr 4fr;
          `
        : css`
            grid-template-columns: 5fr 7fr;
          `}
      grid-column-gap: ${spacing.l};
    `,
  })}
`;
