import { ABTestingId } from "../../codecs/entities";

import crc32 from "./crc32";
import { ABTest } from "./types";

/**
 * Days that a logged out user's testing ID cookie is kept alive; upon expiry,
 * if the user made an account, future tests will use their user ID instead
 */
export const TEST_ID_CACHE_EXPIRY_DAYS = 365;

/**
 * Given an AB Test, returns the variant names and weights sorted together
 * So that they are in a deterministic order
 * @param abTest The AB Test to extract variants and weights for
 */
const getSortedVariants = <Variant extends string>(
  abTest: ABTest<Variant>
): [Variant, number][] => {
  // Sort the variants alphabetically
  return (Object.entries(abTest.variants) as [Variant, number][]).sort(
    ([aName], [bName]) => aName.localeCompare(bName)
  );
};

const calculateVariant = <Variant extends string>(
  abTest: ABTest<Variant>,
  userIdentifier: string
): Variant => {
  /*
    Choosing a weighted variant:
      For C, A, B with weights 2, 4, 8
      variants = A, B, C
      weights = 4, 8, 2
      weightSum = 14
      weightedIndex = 9
      AAAABBBBBBBBCC
      ========^
      Select B
    */
  const sortedVariants = getSortedVariants(abTest);
  const weightSum = sortedVariants.reduce((memo, [name, weight]) => {
    return memo + weight;
  }, 0);

  // A random number between 0 and weightSum
  let weightedIndex = Math.abs(crc32(userIdentifier) % weightSum);

  // Iterate through the sorted weights, and deduct each from the
  // weightedIndex. If weightedIndex drops < 0, select the variant. If
  // weightedIndex does not drop < 0, default to the last variant in the array
  // that is initially assigned.
  let [selectedVariant] = sortedVariants[0];
  for (const [curVariant, curWeight] of sortedVariants) {
    selectedVariant = curVariant;
    weightedIndex -= curWeight;
    if (weightedIndex < 0) {
      break;
    }
  }
  return selectedVariant;
};

/**
 * Deterministically chooses an AB test variant for a given identifier
 *
 * @param userTestingId A static ID used to assign test variants consistently
 * for a user across sessions
 *   - for logged in users, @see User entity's `abTestingId` member
 *   - for logged out users, this is a random value stored in a cookie;
 *     @see CookieKey.TESTING_ID_CACHE
 */
export function chooseABVariant<Variant extends string>(
  abTest: ABTest<Variant>,
  userTestingId: ABTestingId,
  onVariantChosen?: (abTestName: string, chosenVariant: string) => void
): Variant {
  const { name } = abTest;

  // Append the test name to the user identifier so that the same user doesn't get always get put into the 2nd bucket across tests
  const identifier = userTestingId + name;
  const calculatedVariant = calculateVariant(abTest, identifier);
  onVariantChosen && onVariantChosen(name, calculatedVariant);
  return calculatedVariant;
}
