import { css } from "@emotion/react";
import React from "react";

import { useComponentSize } from "src/hooks/useComponentSize";
import { TextSize, numPxTextSizes, TextCssVar } from "src/theme/text";

function calculateFontSize({
  width,
  minTextSize,
  maxTextSize,
  compressor = 1,
}: { width: number | null } & ResizingSpanProps): number {
  const minFontSize = numPxTextSizes[minTextSize];
  const maxFontSize = numPxTextSizes[maxTextSize];
  if (!width) {
    return maxFontSize;
  }

  return Math.min(
    Math.max(width / (10 * compressor), minFontSize),
    maxFontSize
  );
}

/**
 * Span that resizes text according to the component's width.
 *
 * @param minTextSize Minimum text size
 * @param maxTextSize Maximum text size
 * @param compressor The greater the value, the faster the text shrinks as width
 * shrinks. Default 1.
 */
export const ResizingSpan: React.FCC<ResizingSpanProps> = (props) => {
  const { children, className, ...rest } = props;
  // eslint-disable-next-line no-restricted-syntax
  const { ref: itemRef, size } = useComponentSize();
  const width = size?.width || null;
  const fontSize = calculateFontSize({ width, ...rest });
  return (
    <span
      ref={itemRef}
      className={className}
      css={css`
        display: inline-block;
        ${TextCssVar.SIZE}: ${fontSize / 16}rem;
        ${TextCssVar.LINE_HEIGHT}: ${(fontSize / 16) * 1.2}rem;
        line-height: var(${TextCssVar.LINE_HEIGHT});
        font-size: var(${TextCssVar.SIZE});
      `}
    >
      {children}
    </span>
  );
};

interface ResizingSpanProps {
  minTextSize: TextSize;
  maxTextSize: TextSize;
  compressor?: number;
  className?: string;
}
