import { CloudinaryImg } from "@components/CloudinaryImg";
import { useEffect } from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { isPresent } from "@every.org/common/src/helpers/objectUtilities";

import { Link } from "src/components/Link";
import { DefaultPageLayout } from "src/components/layout/DefaultPageLayout";
import { DefaultPageSection } from "src/components/layout/PageSection";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { compositionTextCss } from "src/theme/text";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const ILLUSTRATION_ID = "assets/notFound_mu8ubo";
const ILLUSTRATION_DIMENSIONS = {
  width: 313,
  height: 208,
};

function DonateButtonInstructions() {
  const { search } = useEdoRouter();
  useEffect(() => {
    if (!search) {
      return;
    }
    logger.error({
      message:
        "Donate button was misconfigured on a partner website, please reach out",
      data: {
        referer: getWindow()?.document.referrer,
        utmParams: Object.fromEntries(
          [...new URLSearchParams(search).entries()]
            .filter(([key, value]) =>
              key.startsWith("utm_") ? [key, value] : undefined
            )
            .filter(isPresent)
        ),
      },
    });
  }, [search]);
  return (
    <DefaultPageSection>
      <article css={compositionTextCss}>
        <h2>Did you get here using the Every.org Donate Button?</h2>
        <p>
          The site that brought you here to complete your donation appears to be
          having a problem.
        </p>
        <h3>If you were trying to donate:</h3>
        <p>
          Search for the nonprofit you were trying to donate to in the searchbar
          above.
        </p>
        <p>
          We also suggest that you contact the owner of the site you came from,
          and let them know that something is going wrong with their donate
          button.
        </p>

        <h3>If you own the site:</h3>
        <p>
          Your Donate Button appears to be misconfigured; please ensure that
          you&rsquo;ve set your nonprofit&rsquo;s slug in the{" "}
          <code>onSubmit.charity</code> option when setting up the Donate
          Button.
        </p>
        <h4>Don&rsquo;t know what your nonprofit&rsquo;s slug is?</h4>
        <p>
          Search for your nonprofit above, and check the URL of your
          nonprofit&rsquo;s profile. For example, Every.org&rsquo;s profile URL
          is{" "}
          <code>
            https://www.every.org/<b>everydotorg</b>
          </code>
          , and our slug is{" "}
          <b>
            <code>everydotorg</code>
          </b>
          .
        </p>
        <p>
          Check out{" "}
          <Link
            data-tname="donateButtonDocs"
            to="https://github.com/everydotorg/donate-button#everyorg-donate-button"
          >
            the Donate Button documentation
          </Link>{" "}
          for examples on how to correctly set up your Donate Button.
        </p>
      </article>
    </DefaultPageSection>
  );
}

/**
 * Full-page component for 404 status.
 */
export function NotFoundPage() {
  const { search } = useEdoRouter();
  const fromDonateButton = !!new URLSearchParams(search)
    .get("utm_medium")
    ?.startsWith("donate-button");

  return (
    <DefaultPageLayout
      pageTitle="Page not found"
      omitPageTitleSuffix
      ignoreNoExit
    >
      <DefaultPageSection
        css={!fromDonateButton && { paddingTop: spacing.xxl }}
        contentCss={[
          verticalStackCss.xs,
          {
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          },
        ]}
      >
        <CloudinaryImg
          layout="fixed"
          cloudinaryId={ILLUSTRATION_ID}
          dimensions={ILLUSTRATION_DIMENSIONS}
        />
        <h1>Page not found</h1>
        <p>
          Try using the search bar above, or head back to the{" "}
          <Link
            data-tname="notFoundPageHomepageLink"
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.HOME,
            })}
          >
            homepage
          </Link>
          .
        </p>
      </DefaultPageSection>
      {fromDonateButton && <DonateButtonInstructions />}
    </DefaultPageLayout>
  );
}
