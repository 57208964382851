import { Link } from "@components/Link";
import { Cover } from "@components/feed/NewNonprofitCard/Cover";
import { DefaultActionButtons } from "@components/feed/NewNonprofitCard/DefaultActionButtons";
import { TitleSection } from "@components/feed/NewNonprofitCard/TitleSection";
import {
  ContainerCard,
  InnerSection,
  Description,
  InnerLinkContainer,
} from "@components/feed/NewNonprofitCard/styles";
import { useNonprofitTags } from "@components/feed/NewNonprofitCard/useNonprofitTags";
import { FULL_SIZE_LINK_CLASS } from "@components/feed/styles";
import React, { useMemo, useState } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { PROFILE_IMG_PLACEHOLDER_ID } from "@every.org/common/src/entity/constants";
import { CauseCategory } from "@every.org/common/src/entity/types";
import { displayBWLSpec } from "@every.org/common/src/entity/types/configVariable";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
  DonateModalUrlParams,
} from "@every.org/common/src/helpers/clientRoutes";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";

import {
  UseConfigVariableStatus,
  useConfigVariable,
} from "src/context/ConfigVariableContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { UrlSearchOptions } from "src/context/SearchContext/helpers";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export const BLACK_WOMEN_LEAD_TAG_NAMES = ["black-led", "women-led"];

interface NonprofitCardProps {
  className?: string;
  nonprofit: ContextNonprofit;
  /**
   * If set to true, navigates to the admin page instead of the nonprofit's
   * profile.
   */
  isAdminLink?: boolean;

  /**
   * Describes after how many lines the description at the bottom of the card will be cut
   * @default 4
   */
  descriptionLines?: number;

  /**
   * If set to true, body background color matches the cause category's color
   */
  causeCategoryBackgroundColor?: boolean;

  /**
   * If set to true, only shows the cover and organization name.
   */
  shorten?: boolean;

  /**
   * If set to true, shows donate, like and share buttons below the title and description section
   */
  showButtons?: boolean;

  /**
   * If set to true, directs user directly to donate crypto flow by clicking the default donate button.
   */
  donateCrypto?: boolean;

  /**
   * If set to true, shows top pick badge
   */
  topPick?: boolean;

  /**
   * Replaces default full size link
   */
  customLink?: React.ReactNode;

  /**
   * Replaces default buttons with provided content.
   * Also hidden if "showButtons" is set to false
   */
  customFooter?: React.ReactNode;

  /**
   * Adds content above the Cover
   */
  customHeader?: React.ReactNode;

  /**
   * If set to false, then clicking on the nonprofit card will not navigate to
   * the nonprofit page.
   */
  clickable?: boolean;

  highlightedCauses?: string[];

  userToJoinId?: string;

  searchParams?: UrlSearchOptions;
}

export function getCauseCategoryFromNonprofitTags(
  nonprofitTags?: TagResponse[]
) {
  return nonprofitTags?.[0]?.causeCategory ?? CauseCategory.UNKNOWN;
}

export const NonprofitCard: React.FCC<NonprofitCardProps> = ({
  nonprofit: nonprofitFromProps,
  descriptionLines = 4,
  shorten = false,
  showButtons = true,
  donateCrypto = false,
  children,
  className,
  customHeader,
  customLink,
  customFooter,
  isAdminLink,
  highlightedCauses,
  causeCategoryBackgroundColor = false,
  clickable = true,
  userToJoinId,
  searchParams,
  ...rest
}) => {
  const [coverError, setCoverError] = useState<boolean>(false);

  const nonprofitTags = useNonprofitTags(nonprofitFromProps);

  const displayBWLConfig = useConfigVariable(displayBWLSpec);
  const displayBWL =
    displayBWLConfig.status === UseConfigVariableStatus.SUCCESS
      ? displayBWLConfig.value
      : false;
  const nonprofitTagsName = nonprofitTags.map((tag) => tag.tagName);
  const isMatchBlackWomenLead =
    nonprofitTagsName.length >= BLACK_WOMEN_LEAD_TAG_NAMES.length
      ? BLACK_WOMEN_LEAD_TAG_NAMES.every((r) => nonprofitTagsName.includes(r))
      : false;

  const nonprofit = useMemo(
    () => ({ ...nonprofitFromProps, nonprofitTags }),
    [nonprofitFromProps, nonprofitTags]
  );

  const noCover = coverError || !nonprofit.coverImageCloudinaryId;

  const coverImageCloudinaryId = noCover
    ? PROFILE_IMG_PLACEHOLDER_ID
    : nonprofit.coverImageCloudinaryId;

  const donationMatching = (
    <p css={{ fontWeight: FontWeight.BOLD }}>
      Double your donation! Get matched up to $100 #BlackWomenLead
    </p>
  );

  const query = removeUndefinedValues({
    [DonateModalUrlParams.JOIN_DONATION_USER_ID]: userToJoinId,
    [DonateModalUrlParams.SEARCH_META]: searchParams
      ? JSON.stringify(searchParams)
      : undefined,
  });

  const fullSizeLinkUrl = isAdminLink
    ? getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_ADMIN,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })
    : getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
        query,
      });

  const defaultLink = clickable ? (
    <Link
      title={`Feed nonprofit card link to ${nonprofit.name}${
        isAdminLink ? " admin dashboard" : ""
      }`}
      className={FULL_SIZE_LINK_CLASS}
      data-tname={"feedNonprofitCardLink--fullSize"}
      to={fullSizeLinkUrl}
    >
      Feed nonprofit card link to {nonprofit.name}{" "}
      {isAdminLink && " admin dashboard"}
    </Link>
  ) : undefined;

  const roundedTop = !customHeader;
  const showDefaultActionButtons =
    !shorten && showButtons && nonprofit.isDisbursable && !nonprofit.archived;
  const roundedBottom = shorten || (!showDefaultActionButtons && !customFooter);

  return (
    <ContainerCard className={className} shadowOnHover={clickable} {...rest}>
      {customHeader && <InnerSection>{customHeader}</InnerSection>}
      <InnerLinkContainer>
        <Cover
          nonprofit={{ ...nonprofit, coverImageCloudinaryId }}
          highlightedCauses={highlightedCauses}
          onError={() => setCoverError(true)}
          rounded={roundedTop}
        />
        <InnerSection
          cause={
            causeCategoryBackgroundColor
              ? getCauseCategoryFromNonprofitTags(nonprofitTags)
              : undefined
          }
          rounded={roundedBottom}
        >
          <div css={verticalStackCss.s}>
            <TitleSection nonprofit={nonprofit} />
            {!shorten && nonprofit.description && (
              <Description itemProp="description" lines={descriptionLines}>
                {nonprofit.description}
              </Description>
            )}
            {displayBWL && isMatchBlackWomenLead && donationMatching}
          </div>
        </InnerSection>
        {defaultLink}
      </InnerLinkContainer>
      {customFooter ? (
        <InnerSection>{customFooter}</InnerSection>
      ) : (
        showDefaultActionButtons && (
          <InnerSection>
            <DefaultActionButtons
              nonprofit={nonprofit}
              donateCrypto={donateCrypto}
            />
          </InnerSection>
        )
      )}
      {customLink ? customLink : defaultLink}
    </ContainerCard>
  );
};
