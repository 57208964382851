import {
  ShareButton,
  ShareButtonProps,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import React from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  INSTAGRAM_POST_DIMENSIONS,
} from "src/utility/opengraph";

type FundShareButtonProps = {
  fund: ContextNonprofit;
} & Omit<ShareButtonProps, "shareData" | "instagramImageCloudinaryParams">;

/**
 * Share button for a specific fund
 */
export const FundShareButton: React.FCC<FundShareButtonProps> = ({
  fund,
  ...rest
}) => {
  return (
    <ShareButton
      shareData={(medium) =>
        getSupportNonprofitShareData({
          medium,
          nonprofit: fund,
          body: fund?.description || undefined,
          url: getRoutePath({
            name: ClientRouteName.NONPROFIT_OR_CAUSE,
            tokens: { nonprofitSlug: fund.primarySlug },
            format: URLFormat.ABSOLUTE,
          }),
        })
      }
      instagramImageCloudinaryParams={
        nonprofitShareImageCloudinaryParams({
          logoCloudinaryId: fund.logoCloudinaryId,
          coverImageCloudinaryId: fund.coverImageCloudinaryId,
          imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
        }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
      }
      {...rest}
    />
  );
};
