import { RawAvatarProps, RawAvatar, AvatarSize } from "@components/Avatar";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { CauseCategory } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { ImageFormat } from "@every.org/common/src/helpers/cloudinary";

import { ReactComponent as PlaceholderIllustration } from "src/assets/illustrations/nonprofit.svg";
import { Link } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { ClickAction } from "src/utility/analytics";

interface TagIllustrationProps extends Omit<RawAvatarProps, "cloudinaryId"> {
  tagName: TagResponse["tagName"];
  tagImageCloudinaryId: TagResponse["tagImageCloudinaryId"];
  causeCategory: CauseCategory;
  disableLink?: boolean;
  imageAlt?: string;
}

interface TagBadgeProps extends Omit<RawAvatarProps, "cloudinaryId"> {
  tag: TagResponse;
  disableLink?: boolean;
}

const StyledPlaceholder = styled(PlaceholderIllustration)`
  width: 100%;
  height: auto;
`;

/**
 * @deprecated in favor of TagIllustration below which takes in tag
 * Eventually do not export this
 */
export const TagIllustrationOld: React.FCC<TagIllustrationProps> = ({
  tagName,
  tagImageCloudinaryId,
  causeCategory,
  size,
  disableLink,
  imageAlt,
  ...rest
}) => {
  const avatar = tagImageCloudinaryId ? (
    <RawAvatar
      cloudinaryId={tagImageCloudinaryId}
      placeholder={<PlaceholderIllustration css={StyledPlaceholder} />}
      imageFormat={ImageFormat.svg}
      size={size}
      alt={imageAlt}
      {...rest}
    />
  ) : (
    <TagIllustrationPlaceholder causeCategory={causeCategory} size={size} />
  );

  return disableLink ? (
    avatar
  ) : (
    <Link
      data-tname="trendingCause"
      data-action={ClickAction.TRENDING_CAUSE}
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: tagName },
      })}
      appearance={LinkAppearance.UNSTYLED}
    >
      {avatar}
    </Link>
  );
};

const TagIllustrationPlaceholder = styled.div<{
  causeCategory: CauseCategory;
  size: AvatarSize;
}>`
  display: inline-flex;
  border-radius: 50%;

  background-color: var(
    ${({ causeCategory }) =>
      colorCssVars.causeCategory[causeCategory].largeHighlight}
  );

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
`;

export const TagIllustration = ({
  tag,
  disableLink,
  ...rest
}: TagBadgeProps) => (
  <TagIllustrationOld
    tagName={tag.tagName}
    tagImageCloudinaryId={tag.tagImageCloudinaryId}
    causeCategory={tag.causeCategory}
    {...rest}
  />
);
