import {
  nameCss,
  creatorNameCss,
  locationCss,
} from "@components/feed/NewNonprofitCard/TitleSection/styles";
import React from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { FundCreatingEntityType } from "@every.org/common/src/entity/types";
import { isOfficialFundraiser } from "@every.org/common/src/helpers/fundraisers";
import { displayNonprofitLocation } from "@every.org/common/src/helpers/nonprofitLocation";
import { getUserFullName } from "@every.org/common/src/helpers/username";

import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useUser } from "src/context/UsersContext/hooks";
import { userOrUndefined } from "src/context/UsersContext/selectors";
import { verticalStackCss } from "src/theme/spacing";

function useFundCreatorName({
  fundCreatingEntityId: id,
  fundCreatingEntityType: type,
}: ContextNonprofit): string | undefined {
  let fundCreatorName;

  const [user, nonprofit] = [
    userOrUndefined(
      useUser(id && type === FundCreatingEntityType.USER ? { id } : undefined)
    ),
    nonprofitOrUndefined(
      useNonprofit(
        id && type === FundCreatingEntityType.NONPROFIT ? { id } : undefined
      )
    ),
  ];

  if (type === FundCreatingEntityType.USER) {
    fundCreatorName = (user && getUserFullName(user)) || undefined;
  }
  if (type === FundCreatingEntityType.NONPROFIT) {
    fundCreatorName = nonprofit?.name;
  }

  return fundCreatorName;
}

interface TitleSectionProps {
  nonprofit: ContextNonprofit;
}

export function TitleSection({ nonprofit }: TitleSectionProps) {
  const { locationAddress, name } = nonprofit;
  const fundCreatorName = useFundCreatorName(nonprofit);
  const displayLocation = locationAddress
    ? displayNonprofitLocation({ locationAddress })
    : undefined;

  return (
    <section css={verticalStackCss.xxs}>
      <h3 css={nameCss} itemProp="name">
        {name}
      </h3>
      {fundCreatorName ? (
        <span css={creatorNameCss}>
          by <b itemProp="founder">{fundCreatorName}</b>
        </span>
      ) : displayLocation ? (
        <b css={locationCss} itemProp="location">
          {displayLocation}
        </b>
      ) : null}
    </section>
  );
}

interface FundraiserTitleSectionProps {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse;
}

export function FundraiserTitleSection({
  nonprofit,
  fundraiser,
}: FundraiserTitleSectionProps) {
  const fromUser = userOrUndefined(
    useUser(
      fundraiser?.creatorUserId ? { id: fundraiser.creatorUserId } : undefined
    )
  );

  const userFullName =
    fundraiser &&
    !isOfficialFundraiser(fundraiser) &&
    fromUser &&
    getUserFullName(fromUser);

  return (
    <section css={verticalStackCss.xxs}>
      <h3 css={nameCss} itemProp="name">
        {fundraiser?.title || nonprofit.name}
      </h3>
      {userFullName && (
        <span css={[creatorNameCss]}>
          by <b>{userFullName}</b>
        </span>
      )}
    </section>
  );
}
