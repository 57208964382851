import { Big } from "big.js";

import {
  CurrencyValue,
  currencyValueCodec,
} from "@every.org/common/src/codecs/currency";
import { decodeOrThrow } from "@every.org/common/src/codecs/index";
import { coerceToSafeIntOrThrow } from "@every.org/common/src/codecs/number";
import { isValidUrl } from "@every.org/common/src/codecs/urls";
import {
  Currency,
  DonationFrequency,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import { GivelistRedirectTestVariant } from "@every.org/common/src/helpers/abtesting/GivelistRedirect";
import {
  ClientRouteName,
  DonateModalUrlParams,
  getDonateRoutePath,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { DonationValueErrorCode } from "@every.org/common/src/helpers/donationValue";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import { isRelativeUrl } from "@every.org/common/src/helpers/url";
import { GiftCardPurchaseInfo } from "@every.org/common/src/routes/donate";

import { EdoRouter } from "src/hooks/useEdoRouter";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

function isGivelistRedirectUrl(successUrl: string | URL): boolean {
  try {
    const url =
      typeof successUrl === "string" ? new URL(successUrl) : successUrl;
    if (
      url.hostname === "giveli.st" ||
      // testing urls are p22's givelist vercel links; this is hacky but works
      (url.hostname.endsWith(".vercel.app") &&
        url.hostname.includes("givelist"))
    ) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

interface RedirectToLoginProps {
  router: EdoRouter;
  userEmail: string;
  nonprofitSlug: string;
  amount: string;
  frequency: DonationFrequency;
  successUrl?: string;
  method?: PaymentMethod;
}

export function redirectToLogin({
  router,
  userEmail,
  nonprofitSlug,
  amount,
  frequency,
  successUrl,
  method,
}: RedirectToLoginProps) {
  const urlSearchParams = new URLSearchParams(router.search);
  // "donateTo" param causing dobule donate modal, remove it
  urlSearchParams.delete(DonateModalUrlParams.DONATE_NONPROFIT_ID);
  const queryParams = Object.fromEntries(urlSearchParams.entries());

  router.push(
    getRoutePath({
      format: URLFormat.RELATIVE,
      name: ClientRouteName.LOGIN,
      query: {
        redirectUrl: getDonateRoutePath({
          nonprofitSlug,
          format: URLFormat.RELATIVE,
          query: removeUndefinedValues({
            ...queryParams,
            amount,
            frequency,
            method,
            [DonateModalUrlParams.SUCCESS_URL]:
              successUrl && encodeURIComponent(successUrl),
          }),
        }),
        email: userEmail,
        message:
          "It looks like this email is already associated with an account! Sign in to complete your donation.",
      },
    })
  );
}

interface RedirectToSuccessUrlParams {
  successUrl: string;
  router: EdoRouter;
  isLoggedIn: boolean;
  givelistRedirectTestVariant: GivelistRedirectTestVariant | undefined;
}

/**
 * Redirects to the specified target URL if it was valid
 * @returns true if redirect success, false if refusing to redirect, either
 * since url was invalid or due to the selected Givelist AB test variant
 */
export function redirectToSuccessUrl({
  successUrl,
  router,
  isLoggedIn,
  givelistRedirectTestVariant,
}: RedirectToSuccessUrlParams) {
  if (
    ![undefined, GivelistRedirectTestVariant.CONTROL].includes(
      givelistRedirectTestVariant
    )
  ) {
    return false;
  }
  const window = getWindow();
  if (isRelativeUrl(successUrl)) {
    router.push(successUrl);
    return true;
  }
  if (window && isValidUrl(successUrl)) {
    const toRedirectUrl = new URL(successUrl);
    // add showsignup parameter for Givelist if not logged in
    if (!isLoggedIn && isGivelistRedirectUrl(toRedirectUrl)) {
      toRedirectUrl.searchParams.set("showsignup", "1");
    }
    const redirectUrl = toRedirectUrl.toString();
    logger.info({
      message: "Redirecting to successUrl",
      data: { redirectUrl },
    });
    window.location.href = redirectUrl;
    return true;
  }
  return false;
}

// TODO #8478: parametrize currency? sell gift cards always in USD?
export function getGiftCardPurchaseInfo(
  amount: string,
  quantity: string,
  nonprofitId?: string,
  tagId?: string
): GiftCardPurchaseInfo | undefined {
  try {
    return removeUndefinedValues({
      amount: decodeOrThrow(currencyValueCodec, {
        amount: new Big(amount),
        currency: Currency.USD,
      }),
      quantity: coerceToSafeIntOrThrow({
        num: parseInt(quantity),
      }),
      nonprofitId,
      tagId,
    });
  } catch (e) {
    logger.error({
      message: "Get gift card purchase info decoding failed",
      data: { amount, quantity },
    });
    return undefined;
  }
}

export const outsideBoundsErrorMessage = ({
  maxOrMin,
  shorten,
  minValue,
  maxValue,
}: {
  currency: Currency;
  maxOrMin: DonationValueErrorCode;
  shorten: boolean;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
}) => {
  if (maxOrMin === DonationValueErrorCode.TOO_LARGE) {
    const maxString = displayCurrencyValueInUserLocale({
      currencyValue: maxValue,
    });
    return `Amount must be less than ${maxString}`;
  }

  const minString = displayCurrencyValueInUserLocale({
    currencyValue: minValue,
  });
  return shorten
    ? `${minString} minimum`
    : `Amount must be at least ${minString}`;
};
