import { useContext } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { TagsContext } from "src/context/TagContext";

export function useNonprofitTags(nonprofit: ContextNonprofit): TagResponse[] {
  const { tagsById } = useContext(TagsContext);

  if (nonprofit.nonprofitTags) {
    return nonprofit.nonprofitTags;
  }

  const tagIds = nonprofit.tags;

  if (!tagIds) {
    return [];
  }

  const nonprofitTags: TagResponse[] = [];
  tagIds.forEach((id) => {
    const tagFromContext = tagsById.get(id);

    if (tagFromContext) {
      nonprofitTags.push(tagFromContext);
    }
  });

  return nonprofitTags;
}
