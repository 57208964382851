import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { dateFromStringCodec } from "../codecs/date";
import { matchingCampaignResponseCodec } from "../codecs/entities";
import { safeNonNegativeIntCodec } from "../codecs/number";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec } from ".";

export const claimCampaignGiftCardRouteSpec = makeRouteSpec({
  path: "/giftCardCampaign/:id/claim",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ canUserClaim: t.boolean, claimed: t.boolean }),
});

export const getDonationMatchCampaignRouteSpec = makeRouteSpec({
  path: "/donationMatchCampaign/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: t.partial({}),
  bodyCodec: t.type({}),
  publicRoute: {
    publicCacheLengthMinutes: 1,
    alsoCacheIfAuthenticated: true,
  },
  responseBodyCodec: matchingCampaignResponseCodec,
});

export const postDonationMatchCampaignRouteSpec = makeRouteSpec({
  path: "/donationMatchCampaign",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: t.partial({}),
  bodyCodec: t.type({
    maxTotalMatchAmount: safeNonNegativeIntCodec,
    maxMatchPerDonationOneTimeAmount: t.union([
      safeNonNegativeIntCodec,
      t.null,
    ]),
    startDate: t.union([dateFromStringCodec, t.null]),
    endDate: t.union([dateFromStringCodec, t.null]),
    fundraiserId: uuidCodec,
  }),
  responseBodyCodec: matchingCampaignResponseCodec,
});

export const updateDonationMatchCampaignRouteSpec = makeRouteSpec({
  path: "/donationMatchCampaign/:identifier",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: t.partial({}),
  bodyCodec: t.partial({
    maxTotalMatchAmount: safeNonNegativeIntCodec,
    maxMatchPerDonationOneTimeAmount: t.union([
      safeNonNegativeIntCodec,
      t.null,
    ]),
    startDate: t.union([dateFromStringCodec, t.null]),
    endDate: t.union([dateFromStringCodec, t.null]),
    active: t.boolean,
    fundraiserIds: t.array(uuidCodec),
  }),
  responseBodyCodec: matchingCampaignResponseCodec,
});
