import { ToggleSelector } from "@components/ToggleSelector";

import { NonprofitRevenueSize } from "@every.org/common/src/entity/types";

import { verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const nonprofitRevenueSizeOptions: {
  value: NonprofitRevenueSize;
  title: string;
}[] = [
  {
    value: NonprofitRevenueSize.SMALL,
    title: "Small",
  },
  {
    value: NonprofitRevenueSize.MEDIUM,
    title: "Medium",
  },
  {
    value: NonprofitRevenueSize.LARGE,
    title: "Large",
  },
];

export const SizeSelector = ({
  size: selectedSize,
  onSizeChange,
}: {
  size?: NonprofitRevenueSize;
  onSizeChange: (size: NonprofitRevenueSize | undefined) => void;
}) => (
  <div css={verticalStackCss.xs}>
    <h4 css={textSizeCss.s}>Nonprofit size</h4>
    <ToggleSelector
      onChange={(size) => {
        size && onSizeChange(size as NonprofitRevenueSize);
      }}
      selectorOptions={nonprofitRevenueSizeOptions}
      option={selectedSize}
    />
  </div>
);
