import { useState, useCallback, useEffect } from "react";

import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import {
  ResizeObserverConstructor,
  getResizeObserverOrPonyfill,
} from "src/utility/ResizeObserver";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

function getSize(elem: Element) {
  const boundingRect = elem.getBoundingClientRect();
  return {
    width: boundingRect.width,
    height: boundingRect.height,
  };
}

export function useComponentSize<T extends HTMLElement = HTMLElement>() {
  const [element, setElement] = useState<T | null>(null);
  const ref = useCallback((elem: T | null) => setElement(elem), []);
  const [ResizeObserver, setResizeObserverConstructor] =
    useState<ResizeObserverConstructor>();

  useAsyncEffect({
    asyncOperation: getResizeObserverOrPonyfill,
    handleResponse: (constructor) =>
      constructor && setResizeObserverConstructor(() => constructor),
    handleError: () => {
      logger.error({
        message: "Couldn't load ResizeObserver, can't listen on component size",
      });
    },
  });
  const [size, setSize] = useState<ReturnType<typeof getSize> | null>(null);
  const handleResize = useCallback(() => {
    // wrapped it in requestAnimationFrame to avoid - Error: ResizeObserver loop limit exceeded (#4777)
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523
    getWindow()?.requestAnimationFrame(() => {
      if (element) {
        const newSize = getSize(element);
        if (newSize.width === size?.width && newSize?.height === size.height) {
          return;
        }
        setSize(newSize);
      }
    });
  }, [element, size]);

  useEffect(() => {
    if (!element || !ResizeObserver) {
      return;
    }
    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ResizeObserver, element, handleResize]);

  return { ref, element, size };
}
