import {
  GiftCardCampaignResponse,
  NonprofitResponse,
  TagResponse,
} from "@every.org/common/src/codecs/entities";
import { Currency } from "@every.org/common/src/entity/types";

export interface NonprofitSlug {
  slug: string;
}

interface NonprofitId {
  id: string;
}

/**
 * An identifier that can be used to reference a nonprofit
 */
export type NonprofitIdentifier = NonprofitSlug | NonprofitId;

export type ContextNonprofit = NonprofitResponse & {
  supporterCount?: number;
  nonprofitTags?: TagResponse[];
  donationChargesCount?: number;
  endorsedNonprofitsCount?: number;
  giftCardCampaign?: GiftCardCampaignResponse | null;
  donatedCurrencies?: Currency[] | null;
  donationCount?: number;
};

export enum NonprofitActionType {
  FETCHING_NONPROFIT = "FETCHING_NONPROFIT",
  ADD_NONPROFIT = "ADD_NONPROFIT",
  ADD_NONPROFITS = "ADD_NONPROFITS",
  REGISTER_SLUG_ALIAS = "REGISTER_SLUG_ALIAS",
  NONPROFIT_NOT_FOUND = "NONPROFIT_NOT_FOUND",
}
interface FetchingNonprofitAction {
  type: NonprofitActionType.FETCHING_NONPROFIT;
  data: NonprofitIdentifier;
}
interface AddNonprofitAction {
  type: NonprofitActionType.ADD_NONPROFIT;
  data: ContextNonprofit;
}
interface AddNonprofitsAction {
  type: NonprofitActionType.ADD_NONPROFITS;
  data: ContextNonprofit[];
}
interface NonprofitNotFoundAction {
  type: NonprofitActionType.NONPROFIT_NOT_FOUND;
  data: NonprofitIdentifier;
}
interface RegisterSlugAliasAction {
  type: NonprofitActionType.REGISTER_SLUG_ALIAS;
  data: {
    nonprofitId: ContextNonprofit["id"];
    slug: NonprofitSlug;
  };
}
export type NonprofitsAction =
  | AddNonprofitAction
  | AddNonprofitsAction
  | FetchingNonprofitAction
  | NonprofitNotFoundAction
  | RegisterSlugAliasAction;

/**
 * Status of a nonprofit being searched for
 */
export enum NonprofitFetchStatus {
  FOUND = "FOUND",
  FETCHING_NONPROFIT = "FETCHING_NONPROFIT",
  NONPROFIT_NOT_FOUND = "NONPROFIT_NOT_FOUND",
}

export type NonprofitOrFetchStatus =
  | ContextNonprofit
  | Exclude<NonprofitFetchStatus, NonprofitFetchStatus.FOUND>;

/**
 * Current state in browser memory of nonprofits fetched
 */
export interface NonprofitsState {
  /**
   * Successfully fetched Nonprofit entities, indexed by id
   */
  nonprofitsById: Map<string, ContextNonprofit>;
  /**
   * Mapping from a slug to the id of the nonprofit it refers to
   */
  slugsToIds: Map<string, string>;
  /**
   * Mapping from an identifier used to fetch a slug (id or slug) to the status
   * of that fetch
   */
  nonprofitFetchStatus: Map<string, NonprofitFetchStatus>;
}
