import styled from "@emotion/styled";
import { Big } from "big.js";
import React from "react";

import {
  Currency,
  DonationFrequency,
  DonationFrequencyToUserFacingTextMap,
} from "@every.org/common/src/entity/types";

import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

const StyledLabel = styled.label`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

export const FixedAmount = ({ amount }: { amount: number }) => (
  <div css={[verticalStackCss.xs, { margin: "0 auto", textAlign: "center" }]}>
    <StyledLabel>Donation amount</StyledLabel>
    <span
      css={[
        textSizeCss.xl,
        {
          fontWeight: FontWeight.BOLD,
        },
      ]}
    >
      {displayCurrencyValueInUserLocale({
        currencyValue: {
          currency: Currency.USD,
          amount: new Big(amount),
        },
      })}
    </span>
  </div>
);

export const FixedFrequency = ({
  frequency,
}: {
  frequency: DonationFrequency;
}) => (
  <StyledLabel css={textSizeCss.l}>
    Frequency: {DonationFrequencyToUserFacingTextMap[frequency]}
  </StyledLabel>
);

export const FixedAmountAndFrequency = ({
  amount,
  frequency,
}: {
  amount: number;
  frequency: DonationFrequency;
}) => (
  <div css={[horizontalStackCss.m, { justifyContent: "space-between" }]}>
    <div css={verticalStackCss.s}>
      <StyledLabel>Frequency</StyledLabel>
      <span
        css={[
          textSizeCss.l,
          {
            fontWeight: FontWeight.BOLD,
          },
        ]}
      >
        {DonationFrequencyToUserFacingTextMap[frequency]}
      </span>
    </div>
    <div css={verticalStackCss.xs}>
      <StyledLabel>Donation amount</StyledLabel>
      <span
        css={[
          textSizeCss.xl,
          {
            fontWeight: FontWeight.BOLD,
          },
        ]}
      >
        {displayCurrencyValueInUserLocale({
          currencyValue: {
            currency: Currency.USD,
            amount: new Big(amount),
          },
        })}
      </span>
    </div>
  </div>
);
