import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { lightBgThemeCss, colorCssVars } from "src/theme/color";
import { BORDER_RADIUS } from "src/theme/common";

const ARROW_WIDTH = "16px";
const ARROW_HEIGHT = "7px";

export const Arrow = styled.svg`
  display: block;
  width: ${ARROW_WIDTH};
  height: ${ARROW_HEIGHT};
  path {
    fill: var(${colorCssVars.background.normal});
  }
`;

export const Box = styled.div<{
  disableInteraction: boolean;
}>`
  ${lightBgThemeCss};
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 8px 32px rgba(46, 52, 52, 0.2);
  max-width: calc(100vw - 20px); /* to prevent causing page to get wider */

  &[data-placement^="bottom"] > [data-popper-arrow] {
    top: -${ARROW_HEIGHT};
  }

  &[data-placement^="top"] > [data-popper-arrow] {
    bottom: -${ARROW_HEIGHT};
    & > ${Arrow} {
      transform: rotateZ(180deg);
    }
  }

  &[data-placement="top-start"] {
    border-bottom-left-radius: 0;
  }

  &[data-placement="bottom-end"] {
    border-top-right-radius: 0;
  }

  &[data-placement="top-end"] {
    border-bottom-right-radius: 0;
    [data-popper-arrow] > ${Arrow} {
      transform: rotateX(180deg);
    }
  }
  &[data-placement="bottom-start"] {
    border-top-left-radius: 0;
    [data-popper-arrow] > ${Arrow} {
      transform: rotateY(180deg);
    }
  }

  // by default tippy disables pointer events in the popover, this undoes that
  ${(props) =>
    !props.disableInteraction &&
    css`
      pointer-events: auto;
    `}
`;
