import { ClientRouteName } from "@every.org/common/src/helpers/clientRoutes";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import {
  SearchAllResponseBody,
  SearchParams,
} from "@every.org/common/src/routes/search";

export type SearchRouteNameType =
  | ClientRouteName.NONPROFIT_ADMIN_DONATIONS
  | ClientRouteName.NONPROFIT_OR_CAUSE
  | ClientRouteName.SEARCH_RESULTS
  | ClientRouteName.CAUSES;

/**
 * these pages use a url params to update their local search.
 * please disable any global search updates based on url changes on these pages
 */
export const LocalSearchRouteNames = [
  ClientRouteName.NONPROFIT_ADMIN_DONATIONS,
  ClientRouteName.NONPROFIT_OR_CAUSE,
];

export type ContextSearchParams = Omit<SearchParams, "take" | "skip"> & {
  address?: string;
  take?: TakeInt;
  skip?: SkipInt;
};

export enum SearchStateValue {
  READY = "READY",
  SEARCHING = "SEARCHING",
}

export type SearchState = {
  changeUrl: boolean;
  page: number;
  totalAmount?: number;
  prevSearchResults?: {
    searchParams: ContextSearchParams;
    results: SearchAllResponseBody;
  };
} & (
  | {
      value: SearchStateValue.READY;
      error?: string;
      submittedSearchParams: null;
    }
  | {
      value: SearchStateValue.SEARCHING;
      submittedSearchParams: ContextSearchParams;
    }
);

type SearchSubmitStateAction = {
  changeUrl: boolean;
  inputValue: ContextSearchParams;
};

export enum SearchActionType {
  SUBMIT_SEARCH = "SUBMIT_SEARCH",
  LOAD_MORE = "LOAD_MORE",
  EXECUTE_SEARCH = "EXECUTE_SEARCH",
}

interface SubmitSearchAction {
  type: SearchActionType.SUBMIT_SEARCH;
  data: SearchSubmitStateAction;
}

interface ExecuteSearchAction {
  type: SearchActionType.EXECUTE_SEARCH;
  data: SearchState;
}

interface LoadMoreAction {
  type: SearchActionType.LOAD_MORE;
  data: ContextSearchParams;
}

export type SearchAction =
  | SubmitSearchAction
  | ExecuteSearchAction
  | LoadMoreAction;
