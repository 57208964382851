import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { LoadingIndicator } from "@components/LoadingIndicator";
import { TextInputProps, TextInputType, Combobox } from "@components/TextInput";
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { Location } from "@every.org/common/src/codecs/location";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { useScript, ScriptLoadStatus } from "src/hooks/useScript";
import { colorCssVars } from "src/theme/color";
import { INPUT_BORDER_RADIUS } from "src/theme/common";
import { spacing } from "src/theme/spacing";
import { logger } from "src/utility/logger";

const suggestionItemCss = css`
  cursor: pointer;
  padding: ${spacing.xs} ${spacing.m};
  background-color: var(${colorCssVars.input.background.default});

  :first-of-type {
    margin-top: 1px;
  }
  :last-child {
    border-radius: 0 0 ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS};
  }
`;
const activeSuggestionItemCss = css`
  background-color: var(${colorCssVars.input.background.focus});
`;

const suggestionsContainerCss = css`
  position: absolute;
  width: 100%;
  z-index: 1;
`;

interface CitySearchInputProps extends TextInputProps {
  value?: string;

  /**
   * Handles when the input text changes.
   */
  handleTextChange: (value: string) => void;

  /**
   * Called when the selected location is changed. When user types in
   * the field, it will call this with undefined.
   */
  onLocationChanged: (location?: Location) => void;
  showClearButton?: boolean;
}

export default function CitySearchInput({
  value,
  handleTextChange,
  onLocationChanged,
  showClearButton,
  ...inputProps
}: CitySearchInputProps) {
  const googleMapsSdkStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${assertEnvPresent(
      process.env.REACT_APP_PLACES_API_KEY ||
        process.env.NEXT_PUBLIC_PLACES_API_KEY,
      "PLACES_API_KEY"
    )}&libraries=places`
  );

  const handleChange = (text: string) => {
    handleTextChange(text);
    onLocationChanged(undefined);
  };

  const handleSelect = async (address: string, placeId: string) => {
    try {
      const geocodeResults = await geocodeByAddress(address);
      const latLng = await getLatLng(geocodeResults[0]);
      onLocationChanged({
        address,
        lat: latLng.lat,
        lng: latLng.lng,
      });
    } catch (e) {
      logger.error({
        error: e,
        message:
          "Could not geocode address: " + address + " placeID: " + placeId,
      });
    }
  };

  const searchOptions = {
    types: [
      "locality",
      "administrative_area_level_3",
      "country",
      "administrative_area_level_1",
      "administrative_area_level_2",
    ],
  };

  useEffect(() => {
    if (googleMapsSdkStatus === ScriptLoadStatus.ERROR) {
      logger.error({
        message:
          "Could not load Google Maps SDK, cannot display places autocomplete",
      });
    }
  }, [googleMapsSdkStatus]);
  if (googleMapsSdkStatus === ScriptLoadStatus.LOADING) {
    return <LoadingIndicator />;
  }
  if (googleMapsSdkStatus === ScriptLoadStatus.ERROR) {
    return <React.Fragment />;
  }
  return (
    <PlacesAutocomplete
      value={value || ""}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        const { type, ...placesProps } = getInputProps();
        return (
          <Combobox
            {...inputProps}
            id={inputProps.name}
            type={TextInputType.TEXT}
            {...placesProps}
            placeholder="Search for city or country"
            inputPrefix={
              <Icon
                iconImport={() => import("@components/Icon/icons/SearchIcon")}
                css={{ marginRight: spacing.xs }}
                size={IconSize.MEDIUM}
                display={IconDisplay.SECONDARY}
              />
            }
            setInputPrefixColorAndHeight={false}
            inputSuffix={
              showClearButton &&
              !!value && (
                <Button
                  data-tname="CitySearchInput--clear"
                  role={ButtonRole.UNSTYLED}
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: (e) => {
                      e.stopPropagation();
                      onLocationChanged(undefined);
                      handleTextChange("");
                    },
                  }}
                >
                  <Icon
                    iconImport={() => import("@components/Icon/icons/XIcon")}
                    size={IconSize.MEDIUM}
                    display={IconDisplay.ACCENT}
                  />
                </Button>
              )
            }
            dropdown={
              <ul
                css={suggestionsContainerCss}
                className="autocomplete-dropdown-container"
              >
                {suggestions.length > 0 &&
                  suggestions.map((suggestion, i) => {
                    return (
                      <li
                        css={[
                          suggestionItemCss,
                          suggestion.active && activeSuggestionItemCss,
                        ]}
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.placeId}
                      >
                        {suggestion.description}
                      </li>
                    );
                  })}
              </ul>
            }
            isDropdownActive={suggestions.length > 0}
            autoComplete="none"
          />
        );
      }}
    </PlacesAutocomplete>
  );
}
