import {
  DonationCard,
  DonationCardCommentBodyProps,
} from "@components/DonationCard";
import {
  DonationCardActions,
  DonationCardActionsProps,
} from "@components/feed/DonationCardActions";
import { UUID } from "io-ts-types/UUID";
import React, { useContext, useState, useMemo, useCallback } from "react";

import { FeedUserDonationResponse } from "@every.org/common/src/codecs/entities";
import { commentTextCodec } from "@every.org/common/src/codecs/text";
import { FeedPage } from "@every.org/common/src/entity/types";
import { URLFormat } from "@every.org/common/src/helpers/clientRoutes";
import { getUserDonationShareUrl } from "@every.org/common/src/helpers/share";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { UsersContext } from "src/context/UsersContext";
import { getUser, userOrUndefined } from "src/context/UsersContext/selectors";
import {
  useEditableText,
  getSaveCommentCallback,
} from "src/hooks/useEditableText";

interface FeedDonationCardProps {
  item: FeedUserDonationResponse;
  feedId?: UUID;
  page: FeedPage;
  hideActions?: boolean;
  onDonationArchived: DonationCardActionsProps["onDonationArchived"];
}
/**
 * Card that displays a donation based on a feed donation response.
 */
export const FeedDonationCard: React.FCC<FeedDonationCardProps> = ({
  item,
  feedId,
  page,
  hideActions = false,
  onDonationArchived,
}) => {
  const { fromUserId, toNonprofitId, createdAt, donation } =
    item.donationCharge;
  const usersState = useContext(UsersContext);
  const nonprofitsState = useContext(NonprofitsContext);
  const [updatableDonationCharge, setUpdatableDonationCharge] = useState(
    item.donationCharge
  );
  const user = userOrUndefined(getUser(usersState, { id: fromUserId }));
  const nonprofit = nonprofitOrUndefined(
    getNonprofit(nonprofitsState, {
      id: toNonprofitId,
    })
  );
  const loggedInUser = useLoggedInUserOrUndefined();
  const isOwnDonation = loggedInUser?.id === fromUserId;
  const saveComment = useCallback(
    (text: string | null) => {
      commentTextCodec.is(text) &&
        setUpdatableDonationCharge({
          ...item.donationCharge,
          donation: { ...item.donationCharge.donation, commentText: text },
        });
      return getSaveCommentCallback(donation.id)(text);
    },
    [donation.id, item.donationCharge]
  );
  const commentInterface = useEditableText({
    initialText: donation.commentText,
    saveAction: saveComment,
    logTag: "FeedDonationCard-Comment",
  });

  const donationPageLinkInfo:
    | DonationCardCommentBodyProps["donationPageLinkInfo"]
    | undefined = useMemo(
    () =>
      nonprofit && user && user.username
        ? {
            url: getUserDonationShareUrl({
              username: user.username,
              nonprofitSlug: nonprofit.primarySlug,
              donationId: item.donationCharge.donation.id,
              donationShortId: item.donationCharge.donation.shortId,
              format: URLFormat.RELATIVE,
            }),
            data: {
              donationCharge: item.donationCharge,
              boost: item.boost,
              user,
              nonprofit,
            },
          }
        : undefined,
    [item.boost, item.donationCharge, nonprofit, user]
  );

  if (!nonprofit || !user) {
    return <React.Fragment />;
  }

  const { username, profileImageCloudinaryId, inviter, verifiedStatus } = user;

  return (
    <DonationCard
      feedId={feedId}
      donationId={donation.id}
      createdAt={createdAt}
      isPending={item.donationCharge.isPending}
      frequency={donation.frequency}
      timesCharged={donation.timesCharged}
      nonprofit={nonprofit}
      boost={item.boost}
      profileImageCloudinaryId={profileImageCloudinaryId}
      name={getUserFullNameOrPlaceholder(user)}
      username={username}
      verifiedStatus={verifiedStatus}
      footer={
        hideActions ? undefined : (
          <DonationCardActions
            donationCharge={updatableDonationCharge}
            numJoined={item.boost.numJoined}
            page={page}
            nonprofit={nonprofit}
            onDonationArchived={onDonationArchived}
          />
        )
      }
      commentEditDisabled={!isOwnDonation}
      commentInterface={commentInterface}
      page={page}
      removeTimestamp={page === FeedPage.LANDING}
      saveOnBlur
      donationPageLinkInfo={donationPageLinkInfo || null}
      donationToJoinId={
        donation.fromUserId !== loggedInUser?.id ? donation.id : undefined
      }
      userToJoinId={
        donation.fromUserId !== loggedInUser?.id
          ? donation.fromUserId
          : undefined
      }
      inviter={inviter}
      shortDescription={page === FeedPage.LANDING}
    />
  );
};
