import React, { createContext, useState, useEffect } from "react";

import { getAllApprovesRouteSpec } from "@every.org/common/src/routes/admin";

import { queryApi } from "src/utility/apiClient";

const INITIAL_APPROVE_STATE = {
  donationIds: new Set<string>(),
  isLoading: true,
};
type ApprovesState = typeof INITIAL_APPROVE_STATE;

export let setApproves: React.Dispatch<React.SetStateAction<ApprovesState>>;

export const ApprovesContext = createContext<ApprovesState>(
  INITIAL_APPROVE_STATE
);

export const ApprovesProvider: React.FCC = ({ children }) => {
  const [approves, setApproves_] = useState<ApprovesState>(
    INITIAL_APPROVE_STATE
  );
  setApproves = setApproves_;

  useEffect(() => {
    async function load() {
      const { approvedDonationIds } = await queryApi(getAllApprovesRouteSpec, {
        routeTokens: {},
        queryParams: {},
        body: {},
      });
      setApproves({
        isLoading: false,
        donationIds: new Set(approvedDonationIds),
      });
    }
    load();
  }, []);

  return (
    <ApprovesContext.Provider value={approves}>
      {children}
    </ApprovesContext.Provider>
  );
};
