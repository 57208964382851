import { css } from "@emotion/react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { DonateV3GridCard } from "src/pages/DonateV3/styles";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";

export const FundraiserLink = ({
  nonprofit,
}: {
  nonprofit: ContextNonprofit;
}) => (
  <DonateV3GridCard css={{ padding: spacing.m }}>
    <div
      css={css`
        ${cssForMediaSize({
          max: MediaSize.MEDIUM,
          css: css`
            border: 2px solid var(${colorCssVars.accent.large});
            text-align: center;
            border-radius: 16px;
            padding: ${spacing.s} ${spacing.l};
          `,
        })}
      `}
    >
      <Link
        appearance={LinkAppearance.HYPERLINK}
        to={getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.FUNDRAISER_CREATE,
          tokens: { nonprofitSlug: nonprofit.primarySlug },
        })}
        data-tname={"DonationFaq--seeTerms"}
      >
        Start a fundraiser
      </Link>{" "}
      to rally your friends&nbsp;and&nbsp;family
    </div>
  </DonateV3GridCard>
);
