/**
 * Collection of common microdata for population of knowledge graph data
 * throughout the site
 * @see {@link https://schema.org/docs/gs.html}
 */
import { NonprofitType } from "@every.org/common/src/entity/types";

export const NONPROFIT_ITEM_TYPES: {
  [key in NonprofitType]: string;
} = {
  [NonprofitType.NONPROFIT]: "https://schema.org/NGO",
  [NonprofitType.COMMUNITY]: "https://schema.org/Organization",
  [NonprofitType.FUND]: "https://schema.org/FundingScheme",
  [NonprofitType.LIST]: "https://schema.org/FundingScheme",
  [NonprofitType.PROJECT]: "https://schema.org/FundingScheme",
  [NonprofitType.PROJECT_WITH_DISBURSEMENT]: "https://schema.org/FundingScheme",
};
