import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";

import { AchievementResponse } from "@every.org/common/src/codecs/entities";
import { SafeInt } from "@every.org/common/src/codecs/number";
import { getMyAchievementsRouteSpec } from "@every.org/common/src/routes/me";

import {
  AchievementFetchStatus,
  AchievementMap,
  AchievementsAction,
  AchievementsContextResponse,
  AchievementsState,
  AchivementsActionType,
} from "src/context/AchievementsContext/types";
import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { queryApi } from "src/utility/apiClient";

const INITIAL_STATE: AchievementsState = {
  status: AchievementFetchStatus.NOT_FETCHED,
};
const AchievementsContext = createContext<AchievementsState>(INITIAL_STATE);
let dispatchAchievementsAction: React.Dispatch<AchievementsAction>;

function fetchAchievements(state: AchievementsState): AchievementsState {
  if (state.status !== AchievementFetchStatus.LOADING) {
    queryApi(getMyAchievementsRouteSpec, {
      routeTokens: {},
      queryParams: {},
      body: {},
    }).then((results) => {
      dispatchAchievementsAction({
        type: AchivementsActionType.SET_ACHIEVEMENTS,
        achievements: results.achievements,
      });
      dispatchAchievementsAction({
        type: AchivementsActionType.SET_CREDITS_EARNED,
        creditsEarned: results.totalCreditsEarned,
      });
    });
  }

  return {
    status: AchievementFetchStatus.LOADING,
  };
}

function setAchievements(
  state: AchievementsState,
  achievements: AchievementResponse[]
): AchievementsState {
  const creditsEarned =
    state.status === AchievementFetchStatus.READY
      ? state.creditsEarned
      : (0 as SafeInt);

  const achievementsMap: AchievementMap = {};
  achievements.forEach((achievement) => {
    achievementsMap[achievement.key] = achievement;
  });

  return {
    status: AchievementFetchStatus.READY,
    achievements: achievementsMap,
    creditsEarned,
  };
}

function getEarnedAchievements(
  achievements: AchievementResponse[]
): AchievementResponse[] {
  return achievements
    .filter((achievement) => achievement.enabled && achievement.numEarned)
    .sort((a, b) => {
      // Sort by most recently earned first
      if (a.lastEarnedDate === b.lastEarnedDate) {
        return 0;
      } else if (a.lastEarnedDate === null) {
        return 1;
      } else if (b.lastEarnedDate === null) {
        return -1;
      }

      return a.lastEarnedDate > b.lastEarnedDate ? -1 : 1;
    });
}

function getAvailableAchievements(
  achievements: AchievementResponse[]
): AchievementResponse[] {
  return achievements
    .filter(
      (achievement) =>
        achievement.enabled && // Enabled
        (!achievement.invalidAfter || achievement.invalidAfter > new Date()) && // Hasn't expired yet
        achievement.maxRedeemable > achievement.numEarned // Haven't earned all available
    )
    .sort((a, b) => {
      // Sort: Most reward $ and closest to expiring comes first
      if (a.incentiveRewardAmount === b.incentiveRewardAmount) {
        // If neither have reward, sort by expiry date. Most close to expiring first
        if (a.invalidAfter === b.invalidAfter) {
          return 0;
        } else if (a.invalidAfter === null) {
          return 1;
        } else if (b.invalidAfter === null) {
          return -1;
        }
        return a.invalidAfter > b.invalidAfter ? -1 : 1;
      }

      // Sort by reward amount, higher reward amount first
      if (a.incentiveRewardAmount === null) {
        return 1;
      } else if (b.incentiveRewardAmount === null) {
        return -1;
      }

      return a.incentiveRewardAmount > b.incentiveRewardAmount ? -1 : 1;
    });
}

function setEarnedCredits(
  state: AchievementsState,
  creditsEarned: SafeInt
): AchievementsState {
  const achievements =
    state.status === AchievementFetchStatus.READY ? state.achievements : {};

  return {
    status: AchievementFetchStatus.READY,
    achievements,
    creditsEarned,
  };
}

function achievementsReducer(
  state: AchievementsState,
  action: AchievementsAction
): AchievementsState {
  switch (action.type) {
    case AchivementsActionType.FETCH:
      return fetchAchievements(state);
    case AchivementsActionType.SET_ACHIEVEMENTS:
      return setAchievements(state, action.achievements);
    case AchivementsActionType.SET_CREDITS_EARNED:
      return setEarnedCredits(state, action.creditsEarned);
  }
}

export const AchievementsProvider: React.FCC = ({ children }) => {
  const [achievementsState, achievementsDispatcher] = useReducer(
    achievementsReducer,
    INITIAL_STATE
  );
  dispatchAchievementsAction = achievementsDispatcher;

  return (
    <AchievementsContext.Provider value={achievementsState}>
      {children}
    </AchievementsContext.Provider>
  );
};

export const useAchievements = (): AchievementsContextResponse => {
  const authState = useContext(AuthContext);
  const state = useContext(AchievementsContext);
  const [earnedAchievements, setEarnedAchievements] = useState<
    AchievementResponse[]
  >([]);
  const [availableAchievements, setAvailableAchievements] = useState<
    AchievementResponse[]
  >([]);

  useEffect(() => {
    if (
      state.status === AchievementFetchStatus.NOT_FETCHED &&
      authState.status === AuthStatus.LOGGED_IN
    ) {
      dispatchAchievementsAction({
        type: AchivementsActionType.FETCH,
      });
    }
  }, [state.status, authState.status]);

  useEffect(() => {
    if (state.status === AchievementFetchStatus.READY) {
      const achievements = Object.values(state.achievements);
      if (achievements.length > 0) {
        setAvailableAchievements(getAvailableAchievements(achievements));
        setEarnedAchievements(getEarnedAchievements(achievements));
      }
    }
  }, [state]);

  if (state.status !== AchievementFetchStatus.READY) {
    return state;
  }

  return {
    earnedAchievements,
    availableAchievements,
    ...state,
  };
};
