import React, { createContext, useReducer, useTransition } from "react";

import { CustomEvent } from "@every.org/common/src/helpers/analytics";
import { encodeCausesUrlQuery } from "@every.org/common/src/helpers/causes";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { searchParamToUrlQuery } from "@every.org/common/src/helpers/url";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import {
  SearchAllResponseBody,
  searchAllRouteSpec,
} from "@every.org/common/src/routes/search";

import { compareSearchParams } from "src/context/SearchContext/helpers";
import {
  ContextSearchParams,
  SearchState,
  SearchStateValue,
  SearchAction,
  SearchActionType,
  SearchRouteNameType,
  LocalSearchRouteNames,
} from "src/context/SearchContext/types";
import { ApiError } from "src/errors/ApiError";
import { trackEvent } from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const TAKE = 50 as TakeInt;

export function preprocessSearchParams(
  input: ContextSearchParams
): ContextSearchParams {
  const trimmedQuery = input.query ? input.query.trim() : "";
  const trimmedCauses = input.causes || undefined; // If causes is an empty string, treat it as undefined instead
  return { ...input, causes: trimmedCauses, query: trimmedQuery };
}

const initialState: SearchState = {
  changeUrl: true,
  value: SearchStateValue.READY,
  submittedSearchParams: null,
  page: 0,
};

export const submitSearchAction = (
  inputValue: ContextSearchParams,
  changeUrl = true
) => {
  dispatchSearchAction({
    type: SearchActionType.SUBMIT_SEARCH,
    data: {
      inputValue,
      changeUrl,
    },
  });
};

const updateSearchWithSubmit = (
  state: SearchState,
  inputValue: ContextSearchParams,
  changeUrl = true
): SearchState => {
  const searchParams = preprocessSearchParams(inputValue);

  // don't retrigger a search in progress
  if (
    state.value === SearchStateValue.SEARCHING &&
    compareSearchParams(state.submittedSearchParams, inputValue)
  ) {
    return state;
  }

  trackEvent(CustomEvent.SUBMIT_SEARCH, { searchParams });

  return compareSearchParams(
    searchParams,
    state.prevSearchResults?.searchParams || null
  )
    ? state
    : {
        changeUrl,
        value: SearchStateValue.SEARCHING,
        submittedSearchParams: searchParams,
        prevSearchResults: state.prevSearchResults,
        page: 0,
      };
};

let executeSearchController: AbortController;

export const executeSearch = async (
  state: SearchState,
  searchRouteName: SearchRouteNameType,
  routerPush: (path: string) => void
) => {
  if (state.value !== SearchStateValue.SEARCHING) {
    return;
  }
  executeSearchController?.abort();
  executeSearchController = AbortController && new AbortController();
  const changeUrl = () => {
    if (!state.submittedSearchParams) {
      return;
    }
    const routeQuery = searchParamToUrlQuery(state.submittedSearchParams);

    const searchPagePath = getRoutePath({
      format: URLFormat.RELATIVE,
      name: LocalSearchRouteNames.includes(searchRouteName)
        ? ClientRouteName.SEARCH_RESULTS
        : searchRouteName,
      query: routeQuery,
    });

    routerPush(encodeCausesUrlQuery(searchPagePath));
    getWindow()?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { address, ...paramsToSubmit } = state.submittedSearchParams;
  state.changeUrl && changeUrl();

  // If a query is provided the first page can include bing or guidestar results.
  // In this case some nonprofits from our database can be skipped in the second page.
  // And some can be skipped by the matching on slug or name having a lower score than the other.
  // This code is intended to load all the elements,
  // and then filter out duplicates and display them in the correct order.
  const [take, skip] = [
    TAKE,
    state.page == 0
      ? 0
      : state.prevSearchResults?.results.nonprofits.length || state.page * TAKE,
  ] as [TakeInt, SkipInt];

  try {
    const results = await queryApi(
      searchAllRouteSpec,
      {
        routeTokens: {},
        queryParams: {
          query: state.submittedSearchParams.query || "",
          ...Object.fromEntries(
            Object.entries(paramsToSubmit).filter(([, value]) => !!value)
          ),
          take,
          skip,
          prioritizeCauseCount: true,
          includeCount: state.page === 0,
        },
        body: {},
      },
      { signal: executeSearchController.signal }
    );

    if (!results || !state.submittedSearchParams) {
      return;
    }

    if (state.page !== 0) {
      // This code is for sorting items in the correct order in a feed-style response
      // If we already have A, C
      // and the response is A, B, C, D
      // Maintain the order of the previous elements by adding new elements to the end of the array
      // make it look like this A, C, B, D
      const prevNonprofits = state.prevSearchResults?.results.nonprofits || [];
      const prevNonprofitsIds = prevNonprofits.map(
        ({ nonprofit }) => nonprofit.id
      );
      const newNonprofits = results.nonprofits.filter(
        ({ nonprofit }) => !prevNonprofitsIds.includes(nonprofit.id)
      );
      const nonprofits = [...prevNonprofits, ...newNonprofits];

      dispatchSearchAction({
        type: SearchActionType.EXECUTE_SEARCH,
        data: {
          changeUrl: true,
          value: SearchStateValue.READY,
          submittedSearchParams: null,
          page: state.page,
          totalAmount: state.prevSearchResults?.results.totalAmount,
          prevSearchResults: {
            searchParams: state.submittedSearchParams,
            results: {
              ...results,
              nonprofits,
              nonprofitSearchSources: [
                ...(state.prevSearchResults?.results.nonprofitSearchSources ||
                  []),
                ...(results.nonprofitSearchSources || []),
              ],
            },
          },
        },
      });
    } else {
      dispatchSearchAction({
        type: SearchActionType.EXECUTE_SEARCH,
        data: {
          changeUrl: true,
          value: SearchStateValue.READY,
          submittedSearchParams: null,
          page: state.page,
          totalAmount: results?.totalAmount,
          prevSearchResults: {
            searchParams: state.submittedSearchParams,
            results,
          },
        },
      });
    }
  } catch (error) {
    if (error.name === "AbortError") {
      return;
    }
    if (
      !(error instanceof ApiError) ||
      error.httpStatus !== HttpStatus.UNAUTHENTICATED
    ) {
      logger.error({ message: "Error occurred executing search", error });
    }
    let prevSearchResults = state.prevSearchResults;

    // if prevSearchResults is undefined, fill prevSearchResult by empty values
    // to avoid instant refetching results for the same search params
    if (!state.prevSearchResults && !!state.submittedSearchParams) {
      prevSearchResults = {
        searchParams: state.submittedSearchParams,
        results: {
          nonprofits: [],
          users: [],
          hasMore: false,
          totalEstimatedMatchedNonprofits: 0,
        },
      };
    }

    dispatchSearchAction({
      type: SearchActionType.EXECUTE_SEARCH,
      data: {
        value: SearchStateValue.READY,
        changeUrl: true,
        submittedSearchParams: null,
        page: 0,
        totalAmount: prevSearchResults?.results.totalAmount,
        prevSearchResults,
        error: "Something went wrong; please try again",
      },
    });
  }
};

const updateStateWithLoadMore = (
  state: SearchState,
  searchParams: ContextSearchParams
): SearchState => {
  return {
    changeUrl: false,
    value: SearchStateValue.SEARCHING,
    submittedSearchParams: searchParams,
    prevSearchResults: state.prevSearchResults,
    page: state.page + 1,
  };
};

export const loadMoreAction = (searchParams: ContextSearchParams) => {
  dispatchSearchAction({
    type: SearchActionType.LOAD_MORE,
    data: searchParams,
  });
};

let dispatchSearchAction: React.Dispatch<SearchAction>;

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case SearchActionType.SUBMIT_SEARCH:
      return updateSearchWithSubmit(
        state,
        action.data.inputValue,
        action.data.changeUrl
      );
    case SearchActionType.EXECUTE_SEARCH:
      return action.data;
    case SearchActionType.LOAD_MORE:
      return updateStateWithLoadMore(state, action.data);
    default:
      throw new Error(`Search action with unknown type: ${action}`);
  }
}

export const SearchContext = createContext<SearchState>(initialState);

export const SearchProvider: React.FCC<{
  ssrSearch?: {
    searchResult: SearchAllResponseBody;
    searchParams: ContextSearchParams;
  };
}> = ({ children, ssrSearch }) => {
  const [, startTransition] = useTransition();
  const [searchState, searchDispatcher] = useReducer(
    searchReducer,
    ssrSearch
      ? {
          changeUrl: true,
          value: SearchStateValue.READY,
          submittedSearchParams: null,
          page: 0,
          totalAmount: ssrSearch.searchResult?.totalAmount,
          prevSearchResults: {
            searchParams: ssrSearch.searchParams,
            results: ssrSearch.searchResult,
          },
        }
      : initialState
  );

  dispatchSearchAction = (args: SearchAction) =>
    startTransition(() => searchDispatcher(args));

  return (
    <SearchContext.Provider value={searchState}>
      {children}
    </SearchContext.Provider>
  );
};
