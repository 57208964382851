import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { nonEmptyObjectCodec, stringEnumCodec } from "../codecs";
import { emailAddressCodec } from "../codecs/contact";
import { currencyCodec, currencyValueCodec } from "../codecs/currency";
import { dateFromStringCodec } from "../codecs/date";
import {
  causeCategoryCodec,
  cloudinaryAuthSignatureResponseBodyCodec,
  feedItemResponseCodec,
  personalGivingCreditResponseCodec,
  nonprofitResponseCodec,
  notificationResponseCodec,
  personalDonationChargeResponseCodec,
  personalUserResponseCodec,
  guestUserResponseCodec,
  tagResponseCodec,
  userResponseCodec,
  verifyEmailStatusCodec,
  signupIncentiveResponseCodec,
  givingPledgeResponseCodec,
  achievementResponseCodec,
  deactivateReasonCodec,
  confirmEmailChangeStatusCodec,
  abTestingIdCodec,
} from "../codecs/entities";
import { jsonStringCodec } from "../codecs/json";
import { locationCodec } from "../codecs/location";
import {
  nonNegativeIntegerFromStringCodec,
  safeIntCodec,
} from "../codecs/number";
import {
  nameCodec,
  userBioTextCodec,
  emptyStringAsNullCodec,
  legalNameCodec,
} from "../codecs/text";
import { usernameCodec } from "../codecs/username";
import { CreateGuestUserResponseStatus } from "../entity/types";
import { NotificationMedium } from "../entity/types/notifications";
import { signupMetadataCodec, utmMetadataCodec } from "../helpers/analytics";
import { HttpMethod } from "../helpers/http";

import { donationMatchDataCodec } from "./donate";

import { listParamsCodec, listResponseCodec, makeRouteSpec } from ".";

export const buildProfileQueryParamsCodec = t.partial({
  firstName: t.string,
  lastName: t.string,
  email: emailAddressCodec,
  profileImageId: t.string,
  // TODO: see if we are using this match or we can remove it
  match: t.union([
    jsonStringCodec.pipe(donationMatchDataCodec),
    t.array(jsonStringCodec.pipe(donationMatchDataCodec)),
  ]),
  condensed: BooleanFromString,
  skipWelcomeModal: BooleanFromString,
});

export const getCsrfRouteSpec = makeRouteSpec({
  path: "/csrf",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

const resetPasswordResponseCodec = t.partial({
  error: t.string,
});
export const resetPasswordRouteSpec = makeRouteSpec({
  path: "/resetPassword",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    email: t.string,
  }),
  responseBodyCodec: resetPasswordResponseCodec,
});

const getMeParamsCodec = t.type({});
const getMeBodyCodec = t.type({});
const getMeResponseCodec = t.partial({
  user: t.union([personalUserResponseCodec, guestUserResponseCodec]),
  nonprofitTags: t.array(tagResponseCodec),
});
export type GetMeResponse = t.TypeOf<typeof getMeResponseCodec>;
export const getMeRouteSpec = makeRouteSpec({
  path: "/me",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: getMeParamsCodec,
  bodyCodec: getMeBodyCodec,
  responseBodyCodec: getMeResponseCodec,
});

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
const createMeParamsCodec = t.type({});
const createMeBodyCodec = t.intersection([
  t.type({ password: t.string, email: emailAddressCodec }),
  t.partial({
    abTestingId: abTestingIdCodec,
    firstName: t.string,
    lastName: t.string,
    metadata: t.union([utmMetadataCodec, signupMetadataCodec]),
    guestToken: t.string,
    adminInviteToken: t.string,
    requestAdminNonprofitId: uuidCodec,
  }),
]);
export const createMeRouteSpec = makeRouteSpec({
  path: "/me",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: createMeParamsCodec,
  bodyCodec: createMeBodyCodec,
  responseBodyCodec: personalUserResponseCodec,
});

const checkUsernameCodec = t.type({});
const checkUsernameBodySpec = t.type({
  username: usernameCodec,
});
export const checkUsernameRouteSpec = makeRouteSpec({
  path: "/checkUsername",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: checkUsernameCodec,
  bodyCodec: checkUsernameBodySpec,
  responseBodyCodec: t.boolean,
});

const checkEmailCodec = t.type({});
const checkEmailBodyCodec = t.type({
  email: emailAddressCodec,
});
export const checkEmailRouteSpec = makeRouteSpec({
  path: "/checkEmail",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: checkEmailCodec,
  bodyCodec: checkEmailBodyCodec,
  responseBodyCodec: t.boolean,
});

const verifyEmailBodyCodec = t.intersection([
  t.type({ nonce: t.string }),
  t.partial({ adminInviteToken: t.string }),
]);
export const verifyEmailResponseCodec = t.type({
  user: personalUserResponseCodec,
  status: verifyEmailStatusCodec,
});
export const verifyEmailRouteSpec = makeRouteSpec({
  path: "/me/verifyEmail",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: verifyEmailBodyCodec,
  responseBodyCodec: verifyEmailResponseCodec,
});

export const sendVerifyEmailRouteSpec = makeRouteSpec({
  path: "/me/sendVerifyEmail",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.boolean,
});

const consumeAdminInviteTokenBodyCodec = t.type({ adminInviteToken: t.string });
const consumeAdminInviteTokenResponseCodec = t.type({ result: t.string });
export const consumeAdminInviteTokenRouteSpec = makeRouteSpec({
  path: "/me/acceptAdminInvite",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: consumeAdminInviteTokenBodyCodec,
  responseBodyCodec: consumeAdminInviteTokenResponseCodec,
});

const deactivateMeBodyCodec = t.partial({
  deactivateReason: deactivateReasonCodec,
  deactivateComment: t.string,
});
export const deactivateMeRouteSpec = makeRouteSpec({
  path: "/me/deactivate",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: deactivateMeBodyCodec,
  responseBodyCodec: t.boolean,
});

const reactivateMeResponseCodec = t.type({
  user: personalUserResponseCodec,
});
export const reactivateMeRouteSpec = makeRouteSpec({
  path: "/me/reactivate",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: reactivateMeResponseCodec,
});

const updateMeParamsCodec = t.type({});
const updateMeBodyCodec = t.partial({
  firstName: nameCodec,
  lastName: nameCodec,
  legalName: t.union([legalNameCodec, t.null]),
  birthday: dateFromStringCodec,
  username: usernameCodec,
  email: emailAddressCodec,
  bioText: t.union([userBioTextCodec, emptyStringAsNullCodec, t.null]),
  profileImageCloudinaryId: t.string,
  location: locationCodec,
  isPrivate: t.boolean,
  publicDonationAmount: t.boolean,
  twitterHandle: t.union([t.string, t.null]),
  facebookHandle: t.union([t.string, t.null]),
  instagramHandle: t.union([t.string, t.null]),
  linkedInHandle: t.union([t.string, t.null]),
  youtubeHandle: t.union([t.string, t.null]),
  tagMeOnSocial: t.boolean,
});
export const updateMeRouteSpec = makeRouteSpec({
  path: "/me",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: updateMeParamsCodec,
  bodyCodec: updateMeBodyCodec,
  responseBodyCodec: personalUserResponseCodec,
});

const getHomeFeedParamsCodec = listParamsCodec;
const getHomeFeedBodyParamsCodec = t.type({});
const getHomeFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
  }),
  listResponseCodec,
]);
export const getHomeFeedRouteSpec = makeRouteSpec({
  path: "/feed",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getHomeFeedParamsCodec,
  bodyCodec: getHomeFeedBodyParamsCodec,
  responseBodyCodec: getHomeFeedResponseBodyCodec,
});

const notificationMediumCodec = stringEnumCodec({
  name: "Notification Medium",
  enumObject: NotificationMedium,
});

const getMyNotificationsParamsCodec = t.type({
  medium: notificationMediumCodec,
});
const getMyNotificationsBodyCodec = t.type({});
const getMyNotificationsResponseBodyCodec = t.type({
  notifications: t.array(notificationResponseCodec),
  users: t.array(userResponseCodec),
  nonprofits: t.array(nonprofitResponseCodec),
});
/**
 * Route for getting all outstanding notifications for a medium
 */
export const getMyNotificationsRouteSpec = makeRouteSpec({
  path: "/me/notifications",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getMyNotificationsParamsCodec,
  bodyCodec: getMyNotificationsBodyCodec,
  responseBodyCodec: getMyNotificationsResponseBodyCodec,
});

const updateMyNotificationsParamsCodec = t.type({});
/**
 * toUpdate is an object from notification ID to details about how that
 * notification should be updated.
 */
const updateMyNotificationsBodyCodec = t.type({
  toUpdate: t.record(
    t.string,
    nonEmptyObjectCodec({
      codec: t.partial({
        clear: t.literal(true),
        setViewedAt: dateFromStringCodec,
        setInteractedWithAt: dateFromStringCodec,
      }),
    })
  ),
});
const updateMyNotificationsResponseBodyCodec = t.type({});
/**
 * Route to update status of notifications, like viewing or clearing them
 *
 * @param body.toUpdate.clear Whether or not to clear the notification. If
 * already set, no-op.
 * @param body.toUpdate.setViewedAt If present, time to set when notification
 * was viewed. If already set, no-op.
 */
export const updateMyNotificationsRouteSpec = makeRouteSpec({
  path: "/me/notifications",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: updateMyNotificationsParamsCodec,
  bodyCodec: updateMyNotificationsBodyCodec,
  responseBodyCodec: updateMyNotificationsResponseBodyCodec,
});

export interface UpdateMyNotificationsRequestBody
  extends t.TypeOf<typeof updateMyNotificationsBodyCodec> {}

const getCloudinarySignatureParamsCodec = t.type({});
const getCloudinarySignatureBodyCodec = t.union([
  t.record(t.string, t.string),
  t.type({}),
]);

/**
 * Route for generating request to upload new pic to Cloudinary
 */
export const getCloudinarySignatureRouteSpec = makeRouteSpec({
  path: "/me/cloudinaryAuth",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: getCloudinarySignatureParamsCodec,
  bodyCodec: getCloudinarySignatureBodyCodec,
  responseBodyCodec: cloudinaryAuthSignatureResponseBodyCodec,
});

const getAvailableGivingCreditResponseCodec = t.type({
  currency: currencyCodec,
  amount: nonNegativeIntegerFromStringCodec,
});
export const getAvailableGivingCreditRouteSpec = makeRouteSpec({
  path: "/me/availableGivingCredit",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.partial({
    currency: currencyCodec,
    restrictedByNonprofitId: uuidCodec,
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: getAvailableGivingCreditResponseCodec,
});

const getGivingCreditsResponseCodec = t.type({
  credits: t.array(personalGivingCreditResponseCodec),
  availableCreditAmount: nonNegativeIntegerFromStringCodec,
  currency: currencyCodec,
});
export const getGivingCreditsRouteSpec = makeRouteSpec({
  path: "/me/givingCredits",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.partial({ currency: currencyCodec }),
  bodyCodec: t.type({}),
  responseBodyCodec: getGivingCreditsResponseCodec,
});

export const sendYearEndReceiptRouteSpec = makeRouteSpec({
  path: "/me/sendYearEndReceipt",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    year: t.number,
  }),
  responseBodyCodec: t.type({}),
});

const getMyDonationsParamCodec = t.type({});
const getMyDonationsBodyCodec = t.type({});
const getMyDonationsResponseBodyCodec = t.type({
  donations: t.array(personalDonationChargeResponseCodec),
  nonprofits: t.array(nonprofitResponseCodec),
  tags: t.array(tagResponseCodec),
});
export const getMyDonationsRouteSpec = makeRouteSpec({
  path: "/me/donations",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getMyDonationsParamCodec,
  bodyCodec: getMyDonationsBodyCodec,
  responseBodyCodec: getMyDonationsResponseBodyCodec,
});

const createGuestUserResponseBodyCodec = t.union([
  t.type({
    status: t.literal(CreateGuestUserResponseStatus.FOUND),
    user: guestUserResponseCodec,
  }),
  t.type({
    status: t.literal(CreateGuestUserResponseStatus.CREATED),
    user: guestUserResponseCodec,
  }),
  t.type({
    status: t.literal(CreateGuestUserResponseStatus.UPDATED),
    user: guestUserResponseCodec,
  }),
  t.type({
    status: t.literal(CreateGuestUserResponseStatus.LOGGED_IN_USER_FOUND),
    user: personalUserResponseCodec,
  }),
]);
export const createGuestUserRouteSpec = makeRouteSpec({
  path: "/users/guest",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.intersection([
    t.partial({
      abTestingId: abTestingIdCodec,
      firstName: nameCodec,
      lastName: nameCodec,
      email: emailAddressCodec,
      cfTurnstileToken: t.string,
    }),
    t.type({
      metadata: utmMetadataCodec,
    }),
  ]),
  responseBodyCodec: createGuestUserResponseBodyCodec,
});

/**
 * Endpoint to get user signup invites from the logged in user that resulted in
 * a signup or donation
 */
export const getSignupInvitesRouteSpec = makeRouteSpec({
  path: "/me/signupInvites",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}), // TODO: make this paginated
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    incentives: t.array(signupIncentiveResponseCodec),
    users: t.array(userResponseCodec),
  }),
});

const givingPledgeResponseBodyCodec = t.type({
  givingPledge: t.union([givingPledgeResponseCodec, t.null]),
});

export const setMyGivingPledgeRouteSpec = makeRouteSpec({
  path: "/me/givingPledge",
  method: HttpMethod.PUT,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    pledgeAmount: currencyValueCodec,
    enableReminders: t.boolean,
  }),
  responseBodyCodec: givingPledgeResponseBodyCodec,
});

export const getMyGivingPledgeRouteSpec = makeRouteSpec({
  path: "/me/givingPledge",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: givingPledgeResponseBodyCodec,
});

export const dismissMyGivingPledgeRouteSpec = makeRouteSpec({
  path: "/me/givingPledge/dismiss",
  method: HttpMethod.PUT,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: givingPledgeResponseBodyCodec,
});

const GivingCauseBreakdownResponseBodyCodec = t.type({
  causePercentages: t.array(
    t.type({ cause: causeCategoryCodec, percentage: t.number })
  ),
});
export type GivingCauseBreakdownResponse = t.TypeOf<
  typeof GivingCauseBreakdownResponseBodyCodec
>;

export const getMyGivingCauseBreakdownSpec = makeRouteSpec({
  path: "/me/givingCauseBreakdown",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.partial({ currency: currencyCodec }),
  bodyCodec: t.type({}),
  responseBodyCodec: GivingCauseBreakdownResponseBodyCodec,
});

const MyAchievementsResponseBodyCodec = t.type({
  achievements: t.array(achievementResponseCodec),
  // TODO: this ought to be a currency value, not just a number
  totalCreditsEarned: safeIntCodec,
});

export const getMyAchievementsRouteSpec = makeRouteSpec({
  path: "/me/achievements",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: MyAchievementsResponseBodyCodec,
});

export const changeEmailRouteSpec = makeRouteSpec({
  path: "/me/changeEmail",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    newEmail: t.string,
    password: t.string,
  }),
  responseBodyCodec: t.boolean,
});

export const changeEmailConfirmRouteSpec = makeRouteSpec({
  path: "/me/confirmEmailChange",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({
    changeEmailRequestId: t.string,
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: confirmEmailChangeStatusCodec,
});
