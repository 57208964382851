import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";

const GUTTER_SIZE_SMALL = spacing.m;
const GUTTER_SIZE_LARGE = spacing.l;

type CellNumColumns = 1 | 2 | 3 | 4 | 5 | 6;
type CellNumColumnsLarge = CellNumColumns | 7 | 8 | 9 | 10 | 11 | 12;
/**
 * FILL makes the element grow or shrink to size.
 * FLEX_INITIAL makes the element be its natural size, but can shrink
 */
type CellAutoColumns = "FILL" | "FLEX_INITIAL";
interface CellProps {
  numCols: CellNumColumns | CellAutoColumns;
  numColsMedium?: CellNumColumnsLarge | CellAutoColumns;
  numColsLarge?: CellNumColumnsLarge | CellAutoColumns;
  numColsXLarge?: CellNumColumnsLarge | CellAutoColumns;
}

function flexCssForColVal({
  numCols,
  spannedCols,
}:
  | { numCols: 6; spannedCols: CellProps["numCols"] }
  | { numCols: 12; spannedCols: Required<CellProps>["numColsLarge"] }) {
  return spannedCols === "FLEX_INITIAL"
    ? css``
    : css`
        flex: ${spannedCols === "FILL"
          ? 1
          : `0 0 ${(spannedCols / numCols) * 100}%;`};
      `;
}

/**
 * An element in the grid system, enforcing our standard gutter spacings
 *
 * @params numCols num columns on a mobile-sized screen; 6-column grid, so only
 * 1-6 allowed
 * @params numColsMedium num columns on a medium size screen; 12-column grid
 * @params numColsLarge num columns on a larger screen; 12-column grid
 *
 * @see Grid for an example of usage
 */
export const Cell = styled.div<CellProps>`
  ${(props) => flexCssForColVal({ spannedCols: props.numCols, numCols: 6 })};
  ${(props) =>
    (
      [
        { size: MediaSize.MEDIUM, val: props.numColsMedium },
        { size: MediaSize.LARGE, val: props.numColsLarge },
        { size: MediaSize.XX_LARGE, val: props.numColsXLarge },
      ] as const
    )
      .filter(({ val }) => !!val)
      .map(({ size, val }) =>
        cssForMediaSize({
          min: size,
          css: flexCssForColVal({
            spannedCols: val as Exclude<typeof val, undefined>,
            numCols: 12,
          }),
        })
      )};

  min-width: 0; /* reset min-width: auto to confine contents */
`;

interface GridProps {
  /**
   * Customize the CSS of the flex parent of this grid
   */
  flexCss?: CSSInterpolation;
  className?: string;
}

/**
 * Wrapper for cells in our 6-column grid system
 *
 * If sum of columns is more than 6, it wraps to the next row
 *
 * @example
 * <Grid>
 *   <Cell numCols={4}>
 *     <h1 style={{ background: "green" }}>hi</h1>
 *   </Cell>
 *   <Cell numCols={1}>
 *     <h1 style={{ background: "green" }}>hi</h1>
 *   </Cell>
 *   <Cell numCols={1}>
 *     <h1 style={{ background: "green" }}>hi</h1>
 *   </Cell>
 * </Grid>
 *
 * // result: first cell has 2/3 of the space, the next 2 have 1/6 each
 */
export const Grid: React.FCC<GridProps> = ({
  className,
  children,
  flexCss,
}) => (
  <div
    className={className}
    css={[
      css`
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + ${GUTTER_SIZE_SMALL});
        margin-top: -${GUTTER_SIZE_SMALL};
        margin-left: -${GUTTER_SIZE_SMALL};
        ${Cell} {
          padding-left: ${GUTTER_SIZE_SMALL};
          margin-top: ${GUTTER_SIZE_SMALL};
        }
      `,
      cssForMediaSize({
        min: MediaSize.MEDIUM,
        css: css`
          width: calc(100% + ${GUTTER_SIZE_LARGE});
          margin-top: -${GUTTER_SIZE_LARGE};
          margin-left: -${GUTTER_SIZE_LARGE};
          ${Cell} {
            padding-left: ${GUTTER_SIZE_LARGE};
            margin-top: ${GUTTER_SIZE_LARGE};
          }
        `,
      }),
      flexCss,
    ]}
  >
    {children}
  </div>
);
