import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { spacing } from "@every.org/common/src/display/spacing";

import { LinkAppearance, linkStyleByAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { INPUT_BORDER_RADIUS } from "src/theme/common";
import { compositionTextCss } from "src/theme/text";

export const TextEditorContainer = styled.div<{
  validationSuccess?: boolean;
  isDropdownActive?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${spacing.xs};
  width: 100%;
  padding: ${spacing.s} ${spacing.m} ${spacing.m};
  border-radius: ${(props) =>
    props.isDropdownActive
      ? `${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS} 0 0`
      : INPUT_BORDER_RADIUS};

  background: var(${colorCssVars.input.background.default});
  &:not(:focus-within):hover {
    background: var(${colorCssVars.input.background.hover});
  }
  &:focus-within {
    border: 1px solid
      ${({ validationSuccess = true }) =>
        validationSuccess
          ? `var(${colorCssVars.input.border.focus})`
          : `var(${colorCssVars.input.border.error})`};
    box-shadow: 0px 0px 0px 2px
      ${({ validationSuccess = true }) =>
        validationSuccess
          ? `var(${colorCssVars.input.outline.focus})`
          : `var(${colorCssVars.input.outline.error})`};
    background: var(${colorCssVars.input.background.focus});
  }

  border: 1px solid
    ${({ validationSuccess = true }) =>
      validationSuccess
        ? `var(${colorCssVars.input.background.default});`
        : `var(${colorCssVars.input.border.error})`};

  ${({ validationSuccess = true }) =>
    validationSuccess &&
    `box-shadow: 0px 0px 0px 2px: var(${colorCssVars.input.outline.error})`};
`;

export const TextEditorPlaceholder = styled.div`
  color: var(${colorCssVars.text.secondary});
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

export const StyledContentEditable = styled(ContentEditable)`
  position: relative;
  resize: none;
  tab-size: 1;
  outline: 0;
  min-height: 250px;
`;

export const editorTextCss = css`
  ${compositionTextCss};
  & a {
    ${linkStyleByAppearance[LinkAppearance.HYPERLINK]}
  }
`;
