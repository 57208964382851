import CitySearchInput from "@components/Signup/CitySearchInput";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { Location } from "@every.org/common/src/codecs/location";

import { textSizeCss } from "src/theme/text";

const StyledCitySearchInput = styled(CitySearchInput)`
  z-index: 2;
  & > label {
    ${textSizeCss.s}
  }
`;

export const LocationSelector = ({
  address,
  onLocationChanged,
}: {
  address?: string;
  onLocationChanged: (location: Location | null) => void;
}) => {
  const [inputAddress, setInputAddress] = useState<string | undefined>(address);

  useEffect(() => {
    setInputAddress((prev) => (prev === address ? prev : address));
  }, [address]);

  return (
    <StyledCitySearchInput
      data-tname="location"
      name="location"
      labelText="Location"
      value={inputAddress}
      handleTextChange={(address: string) => {
        setInputAddress(address);
      }}
      collapseDescriptionSpace
      showClearButton
      onLocationChanged={(location?: Location) => {
        if (location) {
          setInputAddress(location.address);
        }
        onLocationChanged(location || null);
      }}
    />
  );
};
