import { CustomRange } from "@components/CustomRange";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import React, { useEffect, useMemo, useState } from "react";

import { colorPalette } from "@every.org/common/src/display/palette";
import { TIP_SLIDER_DEFAULT_SETTINGS } from "@every.org/common/src/entity/constants";

export function getInitialSliderSettingsForCrypto(amountInUSD?: Big) {
  if (!amountInUSD || amountInUSD.lt(500)) {
    return TIP_SLIDER_DEFAULT_SETTINGS;
  } else if (amountInUSD.lt(5000)) {
    return {
      ...TIP_SLIDER_DEFAULT_SETTINGS,
      initial: 10,
    };
  } else if (amountInUSD.lt(50000)) {
    return { ...TIP_SLIDER_DEFAULT_SETTINGS, initial: 5 };
  }
  return {
    ...TIP_SLIDER_DEFAULT_SETTINGS,
    step: 3,
    initial: 3,
  };
}

export const CryptoTipSlider = (props: AddTipProps) => {
  const { formContext, onChange, isZeroTip, amountBig } = props;
  const sliderSettings = useMemo(() => {
    const amountInUSD =
      amountBig && formContext.cryptoTokenRate?.rate
        ? amountBig.times(formContext.cryptoTokenRate?.rate).round(2)
        : undefined;

    return getInitialSliderSettingsForCrypto(amountInUSD);
  }, [amountBig, formContext.cryptoTokenRate?.rate]);

  const [sliderValue, setSliderValue] = useState(sliderSettings.initial);

  const computedValue = useMemo(
    () => amountBig.div(100).mul(sliderValue),
    [amountBig, sliderValue]
  );

  useEffect(() => {
    onChange(computedValue.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedValue]);

  const popoverContent = `${sliderValue}%`;

  return (
    <CustomRange
      themeColor={isZeroTip ? colorPalette.grayMedium : formContext.themeColor}
      {...sliderSettings}
      value={sliderValue}
      onChange={setSliderValue}
      popoverContent={popoverContent}
    />
  );
};
