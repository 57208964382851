import { css } from "@emotion/react";
import { useMemo } from "react";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";

import { colorCssVars } from "src/theme/color";

export const CharacterCounter: React.FCC<{
  text?: string;
  length: number;
  maxLength?: number;
  hideCount?: boolean;
}> = ({ text, hideCount, length, maxLength }) => {
  const tooLong = maxLength && length > maxLength;
  return (
    <span
      css={useMemo(
        () => css`
          color: ${tooLong
            ? SHARED_PALETTE.error
            : `var(${colorCssVars.text.secondary})`};
          display: flex;
          justify-content: space-between;
        `,
        [tooLong]
      )}
    >
      {text ? <span>{text}</span> : null}{" "}
      {maxLength && (!hideCount || tooLong) && `${length}/${maxLength}`}
    </span>
  );
};
