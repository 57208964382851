import { Feed, FeedProps } from "@components/feed";
import React, { useCallback } from "react";

import { FeedPage } from "@every.org/common/src/entity/types";

import { fetchFundFeed } from "src/context/DonationsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";

export const Donations: React.FCC<{ fund: ContextNonprofit }> = ({ fund }) => {
  const loadFundFeed = useCallback<FeedProps["loadMoreItems"]>(
    (params) => fetchFundFeed({ fundId: fund.id, ...params }),
    [fund.id]
  );

  return (
    <Feed
      loadMoreItems={loadFundFeed}
      css={verticalStackCss.l}
      page={FeedPage.FUND}
    />
  );
};
