function nthIndexOf(
  str: string | undefined | null,
  toFind: string,
  numberToFind: number
) {
  let startingIndex = undefined;
  if (!str) {
    return startingIndex;
  }
  for (let i = 0; i < numberToFind; ++i) {
    if (startingIndex === -1) {
      return startingIndex;
    }
    startingIndex = str.indexOf(toFind, startingIndex && startingIndex + 1);
  }
  return startingIndex;
}

const DONATION_COMMENT_CUTOFF_LENGTH = 240;

/**
 * Takes in text and returns comment to display if it's too long or has too
 * many newlines.
 */
export function commentTextToDisplay(text: string) {
  const thirdNewlineIndex = nthIndexOf(text, "\n", 3);
  // Cutoff the comment at the third newline or after cutoffLength characters
  return (
    text &&
    text.slice(
      0,
      thirdNewlineIndex &&
        thirdNewlineIndex > 0 &&
        thirdNewlineIndex < DONATION_COMMENT_CUTOFF_LENGTH
        ? thirdNewlineIndex
        : DONATION_COMMENT_CUTOFF_LENGTH
    )
  );
}
