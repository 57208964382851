import { Card } from "@components/Card";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";

const MAX_FEED_CARD_WIDTH_RAW = 440;

export const maxWidthFeedCardCss = cssForMediaSize({
  max: MediaSize.MEDIUM,
  css: css`
    max-width: ${MAX_FEED_CARD_WIDTH_RAW}px;
    margin: 0 auto;
  `,
});

export const feedCardInnerCardClass = "feedCardInnerCard";
export const FeedCard = styled(Card)`
  ${maxWidthFeedCardCss};
  padding: ${spacing.m} ${spacing.xs};
  ${verticalStackCss.m};
  > :not(.${feedCardInnerCardClass}) {
    padding-right: ${spacing.xs};
    padding-left: ${spacing.xs};
  }
  .${feedCardInnerCardClass}:last-child {
    margin-bottom: -${spacing.xs};
  }
`;

export function getFeedItemId(item: FeedItemResponse): string {
  switch (item.type) {
    case FeedItemType.CREATED_FUND:
    case FeedItemType.NONPROFIT_RECOMMENDATION:
      return item.nonprofitId;
    case FeedItemType.USER_DONATION:
      return item.donationCharge.id;
    default:
      // won't happen
      return "";
  }
}
