import { useState, useEffect } from "react";

import { stringEnumCodec } from "@every.org/common/src/codecs/index";

import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export enum ScriptLoadStatus {
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR",
}

const STATUS_ATTRIBUTE = "data-status";
const scriptLoadStatusCodec = stringEnumCodec({
  name: "ScriptLoadStatus",
  enumObject: ScriptLoadStatus,
});

function eventTypeToStatus(eventType: string) {
  switch (eventType) {
    case "load":
      return ScriptLoadStatus.READY;
    default:
      logger.warn({
        message: "Unexpected script load event type",
        data: { eventType },
      });
    // eslint-disable-next-line no-fallthrough
    case "error":
      return ScriptLoadStatus.ERROR;
  }
}

function getExistingOrAppendNewScript(src: string) {
  const document = getWindow()?.document;
  if (!document) {
    return;
  }
  // Fetch existing script element by src
  // It may have been added by another instance of this hook
  const existingScript = document.querySelector(`script[src="${src}"]`);
  if (existingScript) {
    return existingScript;
  }

  // Create script
  const newScript = document.createElement("script");
  newScript.setAttribute("src", src);
  newScript.setAttribute("async", "");
  newScript.setAttribute(STATUS_ATTRIBUTE, ScriptLoadStatus.LOADING);

  document.body.appendChild(newScript);

  // Store status in attribute on script
  // This can be read by other instances of this hook
  const setAttributeFromEvent: EventListener = (event) => {
    newScript.setAttribute(STATUS_ATTRIBUTE, eventTypeToStatus(event.type));
  };

  newScript.addEventListener("load", setAttributeFromEvent);
  newScript.addEventListener("error", setAttributeFromEvent);

  return newScript;
}

/**
 * Hook to load external script and act on its status
 *
 * Adapted from @see {@link https://usehooks.com/useScript/}
 */
export function useScript(src: string, removeScriptOnCleanup?: boolean) {
  // Keep track of script status ("loading", "ready", "error")
  const [status, setStatus] = useState<ScriptLoadStatus>(
    ScriptLoadStatus.LOADING
  );

  useEffect(() => {
    const script = getExistingOrAppendNewScript(src);
    if (!script) {
      return;
    }

    // Grab existing script status from attribute and set to state
    const scriptStatus = script.getAttribute(STATUS_ATTRIBUTE);
    const decodedScriptStatus = scriptLoadStatusCodec.decode(scriptStatus);
    if (decodedScriptStatus._tag === "Left") {
      logger.warn({
        message: `${STATUS_ATTRIBUTE} attribute did not match expected enum value, perhaps was not loaded by useScript() hook; assuming it's ready`,
        data: { scriptStatus },
      });
      setStatus(ScriptLoadStatus.READY);
    } else {
      setStatus(decodedScriptStatus.right);
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent: EventListener = (event) => {
      setStatus(eventTypeToStatus(event.type));
    };

    // Add event listeners
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);

        if (removeScriptOnCleanup) {
          script.remove();
        }
      }
    };
  }, [src, removeScriptOnCleanup]);

  return status;
}
