import {
  SECTION_WIDTH_VAR,
  MAX_PAGE_SECTION_WIDTH,
} from "@components/layout/PageSection";
import { css } from "@emotion/react";

import { loadingOverlayCss } from "src/theme/GlobalStyle/loadingOverlay";
import { styleReset } from "src/theme/GlobalStyle/styleReset";
import { textStyles } from "src/theme/GlobalStyle/textStyles";
import { colorCssVars, lightBgThemeCss } from "src/theme/color";

export const globalCss = css`
  ${styleReset};

  html,
  body,
  #reactRoot {
    min-height: 100vh; /* minimum full page screen */
    ${lightBgThemeCss};
    background: var(${colorCssVars.background.faded});
    scroll-behavior: smooth;
    ${SECTION_WIDTH_VAR}: ${MAX_PAGE_SECTION_WIDTH};
  }
  style {
    display: none !important;
  }
  ${textStyles};
  ${loadingOverlayCss};
`;
