import { NextPageContext } from "next";

import { addCacheControl } from "src/utilities/ssr/caching";
import { validateAndDecodeParam } from "src/utilities/ssr/validateAndDecodeParam";
import { getUserDonationsByShortId } from "src/utility/apiClient/usernamePage";

export type UserDonationsInitialProps = {
  nonprofitSlug: string;
  shortId: number;
  username: string;
} & Partial<Awaited<ReturnType<typeof getUserDonationsByShortId>>>;

export const getUserDonationInitalProps = async (
  ctx: NextPageContext
): Promise<UserDonationsInitialProps> => {
  const nonprofitSlug = validateAndDecodeParam(ctx, "nonprofitSlug");
  const username = validateAndDecodeParam(ctx, "username");
  const shortId = getShortId(ctx);

  const props = { nonprofitSlug, shortId, username };

  // Not on server, do nothing - let client-side data fetching handle this
  if (!ctx.res) {
    return props;
  }

  // Cache this SSR response at edge
  addCacheControl(ctx);

  try {
    const data = await getUserDonationsByShortId({
      nonprofitSlug,
      shortId: shortId.toString(),
      username,
    });
    return { ...props, ...data };
  } catch (error) {
    ctx.res.statusCode = 404;
    return props;
  }
};

function getShortId(ctx: NextPageContext): number {
  let shortId: number;

  /**
   * to avoid the "getInitialProps is missing expected param" error
   * validate and decode shortId only if presents
   *
   * ctx.query["shortId"] will not be undefined on /:@username:/:nonprofitSlug:/:shortId:/
   * and will be undefined on /:@username:/:nonprofitSlug:/
   */
  if (ctx.query["shortId"]) {
    try {
      shortId = parseInt(validateAndDecodeParam(ctx, "shortId"), 10);
    } catch (e) {
      shortId = 1;
    }
  } else {
    shortId = 1;
  }

  return shortId;
}
