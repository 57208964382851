import { useMemo } from "react";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import { Currency } from "@every.org/common/src/entity/types";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { DEFAULT_BOUNDS_FOR_DONATION_VALUE } from "@every.org/common/src/helpers/donationValue";

export function useAmountLimits(
  minDonationValue?: CurrencyValue,
  _currency?: Currency
): [min: CurrencyValue, max: CurrencyValue] {
  const currency = minDonationValue?.currency || _currency || Currency.USD;

  const defaultBounds = DEFAULT_BOUNDS_FOR_DONATION_VALUE[currency];

  const [min, max] = useMemo(
    () =>
      [minDonationValue || defaultBounds.min, defaultBounds.max].map((amt) =>
        typeof amt === "number"
          ? minimumDenominationAmountToCurrencyValue({
              currency,
              amountInMinDenom: amt,
            })
          : amt
      ),
    [defaultBounds.max, defaultBounds.min, minDonationValue, currency]
  );

  return [min, max];
}
