import { either } from "fp-ts/Either";
import * as t from "io-ts";

import { EmailAddress } from "../entity/types";

/**
 * We normalize emails to lower case for uniqueness and comparison.
 */
export function normalizeEmail(email: string) {
  return email.toLowerCase();
}

/**
 * See https://tylermcginnis.com/validate-email-address-javascript/
 */
export function isEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(normalizeEmail(email));
}

/**
 * A custom `io-ts` codec for email addresses.
 */
export const emailAddressCodec = new t.Type<EmailAddress, string, unknown>(
  "EmailAddress",
  (unknownValue): unknownValue is EmailAddress =>
    typeof unknownValue == "string" && isEmail(unknownValue),
  (unknownValue, context) =>
    either.chain(t.string.validate(unknownValue, context), (stringValue) =>
      isEmail(stringValue)
        ? t.success(normalizeEmail(stringValue))
        : t.failure(stringValue, context)
    ),
  (emailAddress) => emailAddress as string
);
