import { avatarSizeToPx } from "@components/Avatar";
import { CauseIllustration } from "@components/CauseIllustration";
import { CauseIllustrationSize } from "@components/CauseIllustration/types";
import { css } from "@emotion/react";
import React from "react";

import {
  CauseMetadata,
  CauseCategory,
} from "@every.org/common/src/entity/types";

import { spacing, horizontalStackCss } from "src/theme/spacing";

export const TilingCauseCategories: React.FCC<{
  className?: string;
  size: CauseIllustrationSize;
}> = ({ className, size }) => {
  return (
    <div
      className={className}
      css={css`
        position: relative;
        flex-shrink: 1;
        flex-basis: calc(${avatarSizeToPx[size]}px + ${spacing.xxl} * 2);
        z-index: 0;
        overflow: hidden;
      `}
    >
      <ul
        css={[
          horizontalStackCss.m,
          css`
            position: absolute;
            top: ${spacing.xxl};
            left: calc(-${spacing.oldM} - ${avatarSizeToPx[size] / 2}px);
            margin-left: auto;
            margin-right: auto;
          `,
        ]}
      >
        {Object.keys(CauseMetadata).map((cause) => {
          return (
            cause !== CauseCategory.UNKNOWN && (
              <CauseIllustration
                key={CauseCategory[cause]}
                cause={CauseCategory[cause]}
                size={size}
              />
            )
          );
        })}
      </ul>
    </div>
  );
};
