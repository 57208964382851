import {
  ButtonRole,
  Button,
  ButtonTargetKind,
  ButtonSize,
} from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { Modal, ModalProps } from "@components/Modal";
import DonateV3Modal from "@components/donate/DonateV3/Modal";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { Big } from "big.js";
import * as t from "io-ts";
import { UUID } from "io-ts-types";
import React, { useState, useEffect, useMemo, useCallback } from "react";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import {
  DonationResponse,
  UserResponse,
  Uuid,
} from "@every.org/common/src/codecs/entities";
import { stringEnumCodec } from "@every.org/common/src/codecs/index";
import {
  Currency,
  DonationFrequency,
} from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
  DonateModalUrlParams,
} from "@every.org/common/src/helpers/clientRoutes";
import { isUuid } from "@every.org/common/src/helpers/string";
import { isRelativeUrl } from "@every.org/common/src/helpers/url";
import {
  donationMatchCampaignIdentifierCodec,
  DonationMatchCampaignIdentifier,
} from "@every.org/common/src/routes/donate";

import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { NonprofitFetchStatus } from "src/context/NonprofitsContext/types";
import { ViewDonationProvider } from "src/context/ViewDonationContext";
import {
  GetDonationStatus,
  useDonationFromContext,
} from "src/hooks/useDonationFromContext";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { verticalStackCss } from "src/theme/spacing";
import { logger } from "src/utility/logger";

const LOADING = Symbol();

const frequencyOptionsCodec = t.array(
  stringEnumCodec({ name: "DonationFrequency", enumObject: DonationFrequency })
);

export function encodeFrequencyOptionsParam(
  frequencyOptions: DonationFrequency[]
) {
  return JSON.stringify(frequencyOptionsCodec.encode(frequencyOptions));
}

function parseFrequencyOptionParam(
  value: string
): DonationFrequency[] | undefined {
  try {
    const decodeResult = frequencyOptionsCodec.decode(JSON.parse(value));
    if (decodeResult._tag === "Right") {
      return decodeResult.right;
    }
  } catch (error) {
    logger.warn({ error, message: "Error parsing frequency options param" });
  }
  return undefined;
}

export function useJoinDonationParams() {
  const { search } = useEdoRouter();
  const [donationToJoinId, setDonationToJoinId] = useState<
    DonationResponse["id"] | null
  >(null);
  const [userToJoinId, setUserToJoinId] = useState<UserResponse["id"] | null>(
    null
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(search.substr(1));
    for (const { setter, param } of [
      {
        setter: setDonationToJoinId,
        param: DonateModalUrlParams.JOIN_DONATION_ID,
      },
      {
        setter: setUserToJoinId,
        param: DonateModalUrlParams.JOIN_DONATION_USER_ID,
      },
    ]) {
      const maybeId = searchParams.get(param);
      if (maybeId) {
        if (isUuid(maybeId)) {
          setter(maybeId);
          continue;
        } else {
          logger.warn({
            message: "Invalid query param provided for donate modal",
            data: { param, maybeId },
          });
        }
      }
      setter(null);
    }
  }, [search]);

  return {
    userToJoinId,
    donationToJoinId,
  };
}

interface DonateRouteModalProps {
  nonprofitSlug: string;
}

export const DonateRouteModal = (props: DonateRouteModalProps) => {
  return (
    <ViewDonationProvider>
      <DonateRouteModalImp {...props} />
    </ViewDonationProvider>
  );
};

const DonateRouteModalImp: React.FCC<DonateRouteModalProps> = ({
  nonprofitSlug,
}) => {
  const { pathname, search, push } = useEdoRouter();
  const { donationToJoinId, userToJoinId } = useJoinDonationParams();
  const [matchCampaign, setMatchCampaign] = useState<
    DonationMatchCampaignIdentifier | typeof LOADING | null
  >(LOADING);
  const [donationHeaderText, setDonationHeaderText] = useState<string | null>(
    null
  );
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [fromFundraiserId, setFromFundraiserId] = useState<UUID | null>(null);
  const [minDonationValue, setMinDonationAmount] =
    useState<CurrencyValue | null>(null);
  const [frequencyOptions, setFrequencyOptions] = useState<
    DonationFrequency[] | null
  >(null);
  const [requireShareInfo, setRequireShareInfo] = useState<boolean>();
  const [successUrl, setSuccessUrl] = useState<string>();

  useEffect(() => {
    const searchParams = new URLSearchParams(search.substr(1));

    const donationHeaderTextStr = searchParams.get(
      DonateModalUrlParams.DONATION_HEADER_TEXT
    );
    setDonationHeaderText(donationHeaderTextStr ? donationHeaderTextStr : null);

    const redirectUrlStr = searchParams.get(DonateModalUrlParams.REDIRECT_URL);
    setRedirectUrl(
      redirectUrlStr && isRelativeUrl(redirectUrlStr) ? redirectUrlStr : null
    );

    const fromFundraiserIdStr = searchParams.get(
      DonateModalUrlParams.FUNDRAISER_ID
    );
    setFromFundraiserId((fromFundraiserIdStr as Uuid) || null);

    const successUrlStr = searchParams.get(DonateModalUrlParams.SUCCESS_URL);
    if (successUrlStr) {
      setSuccessUrl(successUrlStr);
    }

    const minDonationAmountStr = searchParams.get(
      DonateModalUrlParams.MIN_VALUE
    );
    try {
      setMinDonationAmount(
        minDonationAmountStr
          ? {
              // TODO #8478: eventually we shouldn't assume this
              currency: Currency.USD,
              amount: new Big(minDonationAmountStr),
            }
          : null
      );
    } catch (e) {
      setMinDonationAmount(null);
    }

    const frequencyOptionsStr = searchParams.get(
      DonateModalUrlParams.FREQUENCY_OPTIONS
    );
    if (frequencyOptionsStr) {
      setFrequencyOptions(
        parseFrequencyOptionParam(frequencyOptionsStr) || null
      );
    }

    const requireShareInfoStr = searchParams.get(
      DonateModalUrlParams.REQUIRE_SHARE_INFO
    );
    if (requireShareInfoStr) {
      setRequireShareInfo(true);
    }

    const matchCampaignStr = searchParams.get(
      DonateModalUrlParams.MATCH_CAMPAIGN
    );
    if (!matchCampaignStr) {
      setMatchCampaign(null);
      return;
    }
    const matchCampaignDecoded = donationMatchCampaignIdentifierCodec.decode(
      JSON.parse(matchCampaignStr)
    );
    if (matchCampaignDecoded._tag === "Left") {
      logger.warn({
        message: "Invalid match campaign param in donate route modal",
        data: { matchCampaignStr },
      });
      setMatchCampaign(null);
      return;
    }

    setMatchCampaign(matchCampaignDecoded.right);
  }, [fromFundraiserId, redirectUrl, search]);

  const nonprofit = useNonprofit({ slug: nonprofitSlug });

  const donation = useDonationFromContext(
    useMemo(
      () => (donationToJoinId ? { donationId: donationToJoinId } : null),
      [donationToJoinId]
    )
  );

  const donateModalUrlParamsKeys: string[] =
    Object.values(DonateModalUrlParams);

  const onRequestClose = useCallback(() => {
    // save previous search params and delete associated with donate modal
    const urlParams = new URLSearchParams(
      [...new URLSearchParams(search).entries()].filter(
        ([key]) => !donateModalUrlParamsKeys.includes(key)
      )
    ).toString();

    push(`${pathname}${urlParams ? "?" + urlParams : ""}`);
  }, [donateModalUrlParamsKeys, pathname, search, push]);

  if (
    !nonprofitSlug ||
    nonprofit === NonprofitFetchStatus.FETCHING_NONPROFIT ||
    donation?.status === GetDonationStatus.LOADING
  ) {
    return null;
  }

  if (nonprofit === NonprofitFetchStatus.NONPROFIT_NOT_FOUND) {
    return (
      <ErrorModal isOpen>We were unable to retrieve that nonprofit</ErrorModal>
    );
  }

  if (donation?.status === GetDonationStatus.FETCH_ERROR) {
    return (
      <ErrorModal isOpen>
        We were unable to retrieve that donation you are trying to join.
      </ErrorModal>
    );
  }

  if ([matchCampaign, donationToJoinId, userToJoinId].includes(LOADING)) {
    return <React.Fragment />;
  }

  return (
    <DonateV3Modal
      donateAction={DonateModalAction.DONATE}
      nonprofit={nonprofit}
      isOpen
      onRequestClose={onRequestClose}
      donationToJoinId={donationToJoinId || undefined}
      userToJoinId={userToJoinId || undefined}
      matchCampaign={
        matchCampaign && typeof matchCampaign === "object"
          ? matchCampaign
          : undefined
      }
      donationHeaderText={donationHeaderText || undefined}
      fromFundraiserId={fromFundraiserId || undefined}
      minDonationValue={minDonationValue || undefined}
      frequencyOptions={frequencyOptions || undefined}
      redirectUrl={redirectUrl || undefined}
      requireShareInfo={requireShareInfo}
      successUrl={successUrl}
    />
  );
};

const ErrorModal: React.FCC<ModalProps> = ({ children, ...rest }) => {
  return (
    <Modal {...rest}>
      <div css={verticalStackCss.l}>
        <h2
          css={css`
            text-align: center;
          `}
        >
          {children}
        </h2>
        <Button
          css={css`
            margin: 0 auto;
          `}
          data-tname="homeButton"
          role={ButtonRole.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={{
            kind: ButtonTargetKind.LINK,
            to: getRoutePath({
              name: ClientRouteName.HOME,
              format: URLFormat.RELATIVE,
            }),
          }}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
              display={IconDisplay.CURRENT_COLOR}
              size={IconSize.MEDIUM}
            />
          }
        >
          Return to home page
        </Button>
      </div>
    </Modal>
  );
};
