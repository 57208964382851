import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";

export const pageGridCss = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacing.l};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      grid-template-columns: 380px 1fr;
    `,
  })}
`;

export const DirectoryFilterLargeContainer = styled.aside`
  display: none;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      display: block;
    `,
  })}
`;

export const smallScreenSearhInputCss = css`
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      display: none;
    `,
  })}
`;

export const smallScreenSearhInputBoxCss = css`
  ${lightBgThemeCss};
`;

export const nonprofitHeaderCardCss = css`
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      border-radius: 8px;
      padding: ${spacing.m};
    `,
  })}
`;
