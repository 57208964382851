import { css } from "@emotion/react";
import Markdown from "markdown-to-jsx";
import React from "react";

import { Link } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { FontWeight } from "src/theme/text";
import { logger } from "src/utility/logger";

/**
 * Applies our standard styling to links.
 */
function MarkdownLink(props: { href: string; children: string[] }) {
  const { children, href } = props;
  if (children.length === 0) {
    logger.warn({
      message: "Invalid value passed to Markdown Link",
      data: { children },
    });
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Link
      to={href}
      css={css`
        display: inline;
        font-weight: ${FontWeight.REGULAR};
      `}
      target="_blank"
      data-tname="MarkdownLink"
      appearance={LinkAppearance.HYPERLINK}
    >
      {children[0]}
    </Link>
  );
}

/**
 * Creates a line break for newlines in text unlike standard markdown which
 * does not break on newlines.
 */
function MarkdownParagaph({ children }: { children: React.ReactNode[] }) {
  return (
    <p>
      {children.map((child, index) => {
        if (typeof child === "string") {
          const lines = child.split("\n");
          return lines.map((line, index) => (
            <React.Fragment key={`line-${index}`}>
              {line}
              {index !== lines.length - 1 ? <br /> : undefined}
            </React.Fragment>
          ));
        }
        return <React.Fragment key={`child-${index}`}>{child}</React.Fragment>;
      })}
    </p>
  );
}

const DEFAULT_ALLOWED_TAGS = [
  "strong",
  "em",
  "del",
  "ul",
  "ol",
  "li",
  "h1",
  "h2",
  "h3",
];

/**
 * A component that enables a limited subset of markdown styling.
 */
export function MarkdownText({
  content,
  allowedTags,
  hideMarkdown = false,
}: {
  content: string;
  allowedTags?: string[];
  hideMarkdown?: boolean;
}) {
  // Limits the types of formatting we'll apply
  // https://github.com/probablyup/markdown-to-jsx/issues/274#issuecomment-572978481
  function createElement(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: any,
    props?: React.Attributes | null,
    children?: React.ReactNode
  ) {
    if (hideMarkdown) {
      return React.createElement(
        React.Fragment,
        { key: props?.key },
        <React.Fragment>{children} </React.Fragment>
      );
    }

    // Custom components like MarkdownLink and MarkdownParagraph will not have
    // type String
    return typeof type !== "string" ||
      (allowedTags || DEFAULT_ALLOWED_TAGS).includes(type)
      ? React.createElement(type, props, children)
      : React.createElement(React.Fragment, { key: props?.key }, children);
  }

  // there is markdown-to-jsx package issue https://github.com/quantizor/markdown-to-jsx/issues/368
  // to fix this issue we need to replace all '-' with '\-' in the content as in content.replace(/(?<!^)-/gm, "\\-")
  // but we have not seen any issues in production caused by this bug, and trying to fix it ourselves
  // seems a bit risky. Cannot use the below code because https://caniuse.com/?search=lookbehind shows
  // that is does not work on iOS 16.3 and earlier.
  // Our issue: https://github.com/everydotorg/every.org/issues/16080
  // content = content.replace(/(?<!^)-/gm, "\\-");

  return (
    <Markdown
      options={{
        disableParsingRawHTML: true,
        overrides: hideMarkdown
          ? undefined
          : {
              a: MarkdownLink,
              p: MarkdownParagaph,
            },
        createElement,
      }}
    >
      {content}
    </Markdown>
  );
}
