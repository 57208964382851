import React, { useState, useEffect } from "react";

import { ABTestingId } from "@every.org/common/src/codecs/entities";

import { AuthState, AuthStatus } from "src/context/AuthContext/types";
import { setAnalyticsAuthStatus } from "src/utility/analytics";

const INITIAL_AUTH_STATE: AuthState = {
  status: AuthStatus.LOADING,
  abTestingId: undefined,
  user: undefined,
  guestUser: undefined,
};

export let dispatchAuthState: React.Dispatch<AuthState>;
// this + the useEffect() hook in the auth provider is a hack to allow
// `queryApi()` to work without explicitly providing it with the current auth
// status
export let authStatus: AuthStatus;

export const AuthContext = React.createContext<AuthState>(INITIAL_AUTH_STATE);

export const AuthProvider: React.FCC<{
  abTestingId?: string | undefined;
}> = ({
  abTestingId,
  children,
}: {
  abTestingId?: string | undefined;
  children?: React.ReactNode;
}) => {
  const [authState, authStateSetter] = useState<AuthState>({
    ...INITIAL_AUTH_STATE,
    abTestingId: abTestingId as ABTestingId,
  });
  dispatchAuthState = authStateSetter;

  useEffect(() => {
    authStatus = authState.status;
    setAnalyticsAuthStatus(authState);
  }, [authState]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};
