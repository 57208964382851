import {
  FeedItemResponse,
  NonprofitResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { getDonationByShortIdRouteSpec } from "@every.org/common/src/routes/donation";
import { StaticApiRouteSpec } from "@every.org/common/src/routes/index";
import {
  getUserFeedRouteSpec,
  getUserFundsRouteSpec,
  getUserJoinsFeedRouteSpec,
  getUserLikesRouteSpec,
  getUserRouteSpec,
} from "@every.org/common/src/routes/users";

import { ViewDonationData } from "src/context/ViewDonationContext";
import { ApiError } from "src/errors/ApiError";
import { serversideStaticQueryApi } from "src/utility/apiClient";

type FeedQueryReturnType = {
  nonprofits: NonprofitResponse[];
  users: UserResponse[];
  items: FeedItemResponse[];
};

export const getUserPageDonationsFeed: GetFeedFunction = async (username) => {
  return serversideStaticQueryApi(getUserFeedRouteSpec as StaticApiRouteSpec, {
    routeTokens: {
      id: username,
    },
    queryParams: {
      take: 10,
      skip: 0,
    },
  });
};

export const getUserPageJoinsFeed: GetFeedFunction = async (username) => {
  return serversideStaticQueryApi(
    getUserJoinsFeedRouteSpec as StaticApiRouteSpec,
    {
      routeTokens: {
        id: username,
      },
      queryParams: {
        take: 10,
        skip: 0,
      },
    }
  );
};

export const getUserPageLikesFeed: GetFeedFunction = async (username) => {
  return serversideStaticQueryApi(getUserLikesRouteSpec as StaticApiRouteSpec, {
    routeTokens: {
      id: username,
    },
    queryParams: {
      take: 10,
      skip: 0,
    },
  });
};

export const getUserPageListFeed: GetFeedFunction = async (username) => {
  return serversideStaticQueryApi(getUserFundsRouteSpec as StaticApiRouteSpec, {
    routeTokens: {
      id: username,
    },
    queryParams: {
      take: 10,
      skip: 0,
    },
  });
};

type GetFeedFunction = (username: string) => Promise<FeedQueryReturnType>;

export const getUserInfo =
  (feedFn?: GetFeedFunction, prop?: string) => async (username: string) => {
    try {
      const getUserQuery = serversideStaticQueryApi(
        getUserRouteSpec as StaticApiRouteSpec,
        {
          routeTokens: { identifier: username },
          queryParams: {},
        }
      );

      if (!feedFn || !prop) {
        const data = await getUserQuery;

        return {
          initialData: {
            users: [data.user],
          },
          userInfo: data,
        };
      }

      const [data, getUserFeed] = await Promise.all([
        getUserQuery,
        feedFn(username),
      ]);

      const { users, nonprofits, items } = getUserFeed;

      return {
        initialData: {
          users: [data.user, ...(users ? users : [])],
          nonprofits: nonprofits ?? [],
        },
        userInfo: data,
        [prop]: items,
      };
    } catch (error) {
      if (error.constructor === ApiError && error.httpStatus === 404) {
        throw error;
      }
      return {};
    }
  };

export const getUserDonationsByShortId = async ({
  nonprofitSlug,
  username,
  shortId,
}: {
  nonprofitSlug: string;
  shortId: string;
  username: string;
}) => {
  const donationResult: ViewDonationData = await serversideStaticQueryApi(
    getDonationByShortIdRouteSpec as StaticApiRouteSpec,
    {
      routeTokens: {
        nonprofitSlug,
        shortId,
        username,
      },
      queryParams: {},
    }
  );

  return {
    initialData: {
      nonprofits: [donationResult.nonprofit],
      users: [donationResult.user],
      tags: donationResult.nonprofitTags,
    },
    donation: donationResult,
  };
};
