/**
 * A Dropdown component that renders a custom component on devices with mouse
 * input and the default html Select element for devices with touch as the
 * primary input. For such devices, the default OS Select interface is probably
 * better.
 */
import {
  InputContainer,
  InputContainerProps,
} from "@components/InputContainer";
import styled from "@emotion/styled";
import React from "react";
import ReactDropdown, { Option as ReactDropdownOption } from "react-dropdown";

import { colorCssVars } from "src/theme/color";
import { roundedInputBorder, INPUT_BORDER_RADIUS } from "src/theme/common";
import { spacing } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { getWindow } from "src/utility/window";

import "react-dropdown/style.css";
export type Option = ReactDropdownOption;

const SelectComponent = styled(ReactDropdown)`
  .Dropdown-control {
    color: var(${colorCssVars.text.secondary});
    border: 1px solid transparent;
    ${roundedInputBorder};

    padding: ${spacing.m};

    /* right padding to avoid arrow overlap */
    padding-right: ${spacing.xl};
    background: var(${colorCssVars.input.background.default});
  }

  &.is-open .Dropdown-control {
    border-radius: ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS} 0 0;

    background: var(${colorCssVars.input.background.focus});
  }

  .Dropdown-menu {
    background: var(${colorCssVars.input.background.default});

    padding-top: ${spacing.xs};
    padding-bottom: ${spacing.xs};

    border: 1px solid transparent;
    border-radius: 0 0 ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS};
  }

  .Dropdown-option {
    &:hover {
      background: var(${colorCssVars.input.background.focus});
    }

    &.is-selected {
      background-color: inherit;

      &:hover {
        background: var(${colorCssVars.input.background.focus});
      }
    }
  }

  & .Dropdown-arrow {
    top: 1.5em;
  }
`;

const NativeSelect = styled.select`
  appearance: none;
  outline: none;
  border: 1px solid transparent;
  ${roundedInputBorder};
  background: var(${colorCssVars.input.background.default});
  padding: ${spacing.m};
  font-weight: ${FontWeight.REGULAR};

  &:focus {
    outline: none;
    background: var(${colorCssVars.input.background.focus});
  }
`;

export interface SelectProps extends InputContainerProps {
  className?: string;
  options: Option[];
  value: string | Option;
  onChange: (arg: Option) => void;
  placeholder?: string;
  arrowClosed?: React.ReactNode;
  arrowOpen?: React.ReactNode;
  allowNative?: boolean;
}

export const Select: React.FCC<SelectProps> = ({
  options,
  onChange,
  value,
  className,
  labelText,
  description,
  collapseDescriptionSpace,
  "data-tname": dataTName,
  arrowOpen,
  arrowClosed,
  allowNative = true,
}) => {
  const window = getWindow();
  const isCoarsePointer: boolean | undefined =
    window?.matchMedia("(pointer: coarse)").matches;
  const strValue = typeof value === "string" ? value : value.value;
  const select =
    allowNative && isCoarsePointer ? (
      <NativeSelect
        className={className}
        value={strValue}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const selected = options.find(
            ({ value }) => value === e.target.value
          );
          selected && onChange(selected);
        }}
      >
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </NativeSelect>
    ) : (
      <SelectComponent
        className={className}
        options={options}
        onChange={onChange}
        value={value}
        arrowOpen={arrowOpen}
        arrowClosed={arrowClosed}
      />
    );

  return (
    <InputContainer
      data-tname={dataTName}
      labelText={labelText}
      description={description}
      collapseDescriptionSpace={collapseDescriptionSpace}
    >
      {select}
    </InputContainer>
  );
};
