import { useCallback, useState } from "react";

import {
  FundraiserResponse,
  MatchingCampaignResponse,
  NonEmptyMatchingCampaignResponse,
} from "@every.org/common/src/codecs/entities";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import { getNonprofitMatchingCampaignAndCheckDisbursabilityRouteSpec } from "@every.org/common/src/routes/nonprofit";

import { useIsLoggedIn } from "src/context/AuthContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

function nonEmptyMatchingCampaign(
  campaign: MatchingCampaignResponse
): NonEmptyMatchingCampaignResponse | undefined {
  if (Object.keys(campaign).length) {
    return campaign as NonEmptyMatchingCampaignResponse;
  }
  return undefined;
}

export function useNonprofitMatchingCampaign({
  nonprofitId,
  fundraiserId,
  disbursabilityCheck,
}: {
  nonprofitId?: ContextNonprofit["id"];
  fundraiserId?: FundraiserResponse["id"];
  disbursabilityCheck?: boolean;
}) {
  const isLoggedIn = useIsLoggedIn();

  const [nonprofitMatchCampaign, setNonprofitMatchCampaign] =
    useState<NonEmptyMatchingCampaignResponse>();

  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      return (
        nonprofitId &&
        (await queryApi(
          getNonprofitMatchingCampaignAndCheckDisbursabilityRouteSpec,
          {
            routeTokens: { nonprofitId },
            queryParams: removeUndefinedOrNullValues({
              disbursabilityCheck,
              fundraiserId,
            }),
            body: {},
          }
        ))
      );
    }, [nonprofitId, disbursabilityCheck, fundraiserId]),
    handleResponse: useCallback(
      (response: MatchingCampaignResponse | undefined) => {
        const matchingCampaignResponse =
          response && nonEmptyMatchingCampaign(response);

        if (matchingCampaignResponse?.matchGuests || isLoggedIn) {
          setNonprofitMatchCampaign(matchingCampaignResponse);
        }
      },
      [isLoggedIn]
    ),
    handleError: useCallback(
      (error) => {
        logger.error({
          error,
          message: `Error getting Matching Campaign for nonprofit id: ${nonprofitId}`,
          data: { nonprofitId: nonprofitId },
        });
      },
      [nonprofitId]
    ),
  });

  return nonprofitMatchCampaign;
}
