import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export enum RuntimeEnvironment {
  DEV = "dev",
  STAGING = "staging",
  PROD = "prod",
  TEST = "test",
}

/**
 * Gets the runtime environment, or null if environment is invalid
 */
function _getRuntimeEnvironment(): RuntimeEnvironment | null {
  if (process.env.NODE_ENV === "development") {
    return RuntimeEnvironment.DEV;
  }
  if (process.env.NODE_ENV === "test") {
    return RuntimeEnvironment.TEST;
  }
  const envValue = assertEnvPresent(
    process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ||
      process.env.REACT_APP_ENVIRONMENT_NAME,
    "ENVIRONMENT_NAME"
  );
  switch (envValue) {
    case "development":
      return RuntimeEnvironment.DEV;
    case "staging":
      return RuntimeEnvironment.STAGING;
    case "production":
      return RuntimeEnvironment.PROD;
    default:
      // defaults to dev
      return null;
  }
}

/**
 * Determines the current runtime environment; if invalid, defaults to dev
 */
export function getRuntimeEnvironment(): RuntimeEnvironment {
  return _getRuntimeEnvironment() || RuntimeEnvironment.DEV;
}

export const isDevOrTest = [
  RuntimeEnvironment.DEV,
  RuntimeEnvironment.TEST,
].includes(getRuntimeEnvironment());
