import { NextPageContext } from "next/types";
import { match } from "path-to-regexp";

import { logger } from "src/utility/logger";

export const validateAndDecodeParam = (
  ctx: NextPageContext,
  paramName: string,
  /**
   * Occasionally it looks like this function gets called on the client side and
   * ctx.query is missing the expected tokens. We've seen this happen for user
   * and cause pages. I think the issue could be that the nextjs route for those
   * pages is different than the client-side route (i.e. /cause/animals vs
   * /animals), so nextjs is unable to extract the proper query params on the
   * client. This is a hacky workaround to try to get the param from the path
   * if it's missing from the query.
   *
   * I'm only including this for known cases where we've seen this happen, so if
   * we see the fatal alerts, we can either add those cases here or re-evaluate
   * this workaround.
   */
  pathToTest?: string
): string => {
  let param: string | string[] | undefined = ctx.query[paramName];
  if (!param && pathToTest && ctx.asPath) {
    const matchResult = match(pathToTest)(ctx.asPath.split("?")[0]);
    if (matchResult) {
      param = matchResult.params[paramName];
      if (param) {
        logger.warn({
          message:
            "Forced to extract param from path instead of finding it in query",
          data: { paramName, query: ctx.query, path: ctx.asPath },
        });
      }
    }
  }
  if (!param) {
    logger.fatal({
      message: "getInitialProps is missing expected param",
      data: { paramName, query: ctx.query, path: ctx.asPath },
    });
    throw new Error(`getInitialProps got no ${paramName}`);
  }
  if (Array.isArray(param)) {
    logger.fatal({
      message: "getInitialProps param received unexpected array",
      data: { paramName, query: ctx.query, path: ctx.asPath },
    });
    throw new Error(`getInitialProps got an array of ${paramName}`);
  }

  return decodeURIComponent(param);
};
