import * as t from "io-ts";

import { UserResponse } from "@every.org/common/src/codecs/entities";
import {
  unfollowRouteSpec,
  existingUnfollowResponseBodyCodec,
  followRouteSpec,
} from "@every.org/common/src/routes/follow";
import {
  getUserFollowsRouteSpec,
  UserProfileResponse,
  getUserRouteSpec,
} from "@every.org/common/src/routes/users";

import { dispatchUsersAction } from "src/context/UsersContext/";
import {
  UserIdentifier,
  UsersActionType,
} from "src/context/UsersContext/types";
import { is400LevelApiError } from "src/errors/ApiError";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

/**
 * Fetches a user identified by username or id.
 *
 * @returns the user is found, or null if not
 */
export async function fetchUser(
  identifier: UserIdentifier
): Promise<UserProfileResponse | null> {
  if (dispatchUsersAction) {
    dispatchUsersAction({
      type: UsersActionType.FETCHING_USER,
      data: identifier,
    });
    try {
      const result = await queryApi(getUserRouteSpec, {
        routeTokens: { identifier: identifier["id"] || identifier["username"] },
        body: {},
        queryParams: {},
      });
      dispatchUsersAction({
        type: UsersActionType.ADD_USER,
        data: result.user,
      });
      return result;
    } catch (e) {
      if (is400LevelApiError(e)) {
        logger.info({
          message: "A 400-level error occurred fetching a user",
          error: e,
          data: { httpStatus: e.httpStatus },
        });
      } else {
        logger.error({
          message: "An error occurred fetching the user.",
          error: e,
        });
      }
    }
  }
  dispatchUsersAction({
    type: UsersActionType.USER_NOT_FOUND,
    data: identifier,
  });
  return null;
}

/**
 * Add user data to the UsersContext.
 */
export function addUser(user: UserResponse) {
  if (!dispatchUsersAction) {
    logger.warn({ message: "Dispatch is undefined for UsersContext." });
  }
  dispatchUsersAction({
    type: UsersActionType.ADD_USER,
    data: user,
  });
}

/**
 * Add user data to the UsersContext.
 */
export function addUsers(users: UserResponse[]) {
  if (!dispatchUsersAction) {
    logger.warn({ message: "Dispatch is undefined for UsersContext." });
  }
  dispatchUsersAction({
    type: UsersActionType.ADD_USERS,
    data: users,
  });
}

export async function follow(toFollowUserId: UserResponse["id"]) {
  const { user } = await queryApi(followRouteSpec, {
    body: { toId: toFollowUserId },
    routeTokens: {},
    queryParams: {},
  });
  addUser(user);
}

type UnfollowResponse = t.TypeOf<typeof existingUnfollowResponseBodyCodec>;
/**
 * A type-guard to determine the type of the response from the Unfollow
 * endpoint.
 */
function unfollowResponseIsUserFollow(
  unfollowResponse: t.TypeOf<typeof unfollowRouteSpec.responseBodyCodec>
): unfollowResponse is UnfollowResponse {
  return !!(unfollowResponse as UnfollowResponse).user;
}

export async function unfollow(followingUserId: UserResponse["id"]) {
  const result = await queryApi(unfollowRouteSpec, {
    body: { toId: followingUserId },
    routeTokens: {},
    queryParams: {},
  });
  if (unfollowResponseIsUserFollow(result)) {
    addUser(result.user);
  }
}

export interface FetchFollowsResult {
  followingIds: UserResponse["id"][];
  followersIds: UserResponse["id"][];
}
export async function fetchFollowsForUser(
  userId: UserResponse["id"]
): Promise<FetchFollowsResult> {
  const followsData = await queryApi(getUserFollowsRouteSpec, {
    body: {},
    queryParams: {},
    routeTokens: { id: userId },
  });
  const followersIds = followsData.followers.map((follow) => {
    return follow.fromUserId;
  });
  const followingIds = followsData.following.map((follow) => {
    return follow.toUserId;
  });
  addUsers(followsData.users);
  return {
    followersIds,
    followingIds,
  };
}
