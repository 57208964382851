import { NonEmptyMatchingCampaignResponse } from "@every.org/common/src/codecs/entities";
import { NO_GLOBAL_MATCHING } from "@every.org/common/src/entity/constants";
import { globalNonprofitAnnouncementConfigVariableSpec } from "@every.org/common/src/entity/types/configVariable";

import {
  useConfigVariable,
  UseConfigVariableStatus,
} from "src/context/ConfigVariableContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export function useNonprofitAnnouncement(
  nonprofit?: ContextNonprofit,
  matchingCampaign?: NonEmptyMatchingCampaignResponse
) {
  const globalNonprofitAnnouncmenetConfigVariable = useConfigVariable(
    globalNonprofitAnnouncementConfigVariableSpec
  );
  const globalNonprofitAnnouncement =
    globalNonprofitAnnouncmenetConfigVariable.status ===
    UseConfigVariableStatus.SUCCESS
      ? globalNonprofitAnnouncmenetConfigVariable.value
      : undefined;

  const nonprofitAnnouncement = nonprofit?.metadata?.announcement;

  const matchingAnnouncement =
    matchingCampaign &&
    matchingCampaign.availableForMatching > 0 &&
    matchingCampaign.description;

  const globalAnnouncement =
    nonprofit && NO_GLOBAL_MATCHING.includes(nonprofit.id)
      ? undefined
      : globalNonprofitAnnouncement;

  return nonprofitAnnouncement || matchingAnnouncement || globalAnnouncement;
}
