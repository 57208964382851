import { Big } from "big.js";

import { CryptoCurrencyValue } from "../codecs/crypto";
import { SafeInt } from "../codecs/number";
import { ContractType, CryptoCurrency } from "../entity/types/crypto";

export interface SharedCryptoCurrencyConfig {
  displayName: string;
  abbreviation: string;
  decimalOffset: number;
  contractType?: ContractType;
}

export const DISABLED_TOKENS = [
  // wyre is ded
  CryptoCurrency.ALGO,
  CryptoCurrency.MOB,
  CryptoCurrency.XLM,
  CryptoCurrency.BNB,
];

/**
 * Base configuration for crypto. Backend and frontend-specific configuration
 * are configured in //packages/api/src/helpers/cryptoCurrency.ts and
 * //packages/website-next/src/utility/cryptoCurrency.ts respectively.
 */
export const SharedCryptoCurrencyConfig: {
  [key in CryptoCurrency]: SharedCryptoCurrencyConfig;
} = {
  [CryptoCurrency.AAVE]: {
    displayName: "Aave",
    abbreviation: "AAVE",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.ALCX]: {
    displayName: "Alchemix",
    abbreviation: "ALCX",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.ALGO]: {
    displayName: "Algorand",
    abbreviation: "ALGO",
    decimalOffset: 6,
  },
  [CryptoCurrency.AMP]: {
    displayName: "Amp",
    abbreviation: "AMP",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.ANKR]: {
    displayName: "Ankr",
    abbreviation: "ANKR",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.APE]: {
    displayName: "Apecoin",
    abbreviation: "APE",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.API3]: {
    displayName: "API3",
    abbreviation: "API3",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.AUDIO]: {
    displayName: "Audius",
    abbreviation: "AUDIO",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.AVAX]: {
    displayName: "Avalanche",
    abbreviation: "AVAX",
    decimalOffset: 9,
  },
  [CryptoCurrency.AXS]: {
    displayName: "Axie Infinity",
    abbreviation: "AXS",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.BAT]: {
    displayName: "Basic Attention Token",
    abbreviation: "BAT",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.BCH]: {
    displayName: "Bitcoin Cash",
    abbreviation: "BCH",
    decimalOffset: 8,
  },
  [CryptoCurrency.BNB]: {
    displayName: "Binance Coin",
    abbreviation: "BNB",
    decimalOffset: 8,
  },
  [CryptoCurrency.BNT]: {
    displayName: "Bancor",
    abbreviation: "BNT",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.BOND]: {
    displayName: "BarnBridge",
    abbreviation: "BOND",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.BTC]: {
    displayName: "Bitcoin",
    abbreviation: "BTC",
    decimalOffset: 8,
  },
  [CryptoCurrency.CHZ]: {
    displayName: "Chiliz",
    abbreviation: "CHZ",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.COMP]: {
    displayName: "Compound",
    abbreviation: "COMP",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.CRV]: {
    displayName: "Curve DAO Token",
    abbreviation: "CRV",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.CUBE]: {
    displayName: "Cube",
    abbreviation: "CUBE",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.CVC]: {
    displayName: "Civic",
    abbreviation: "CVC",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.DAI]: {
    displayName: "Dai",
    abbreviation: "DAI",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.DOGE]: {
    displayName: "DogeCoin",
    abbreviation: "DOGE",
    decimalOffset: 8,
  },
  [CryptoCurrency.DOT]: {
    displayName: "Polkadot",
    abbreviation: "DOT",
    decimalOffset: 10,
  },
  [CryptoCurrency.ELON]: {
    displayName: "Dogelon Mars",
    abbreviation: "ELON",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.ENS]: {
    displayName: "Ethereum Name Service",
    abbreviation: "ENS",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.ETH]: {
    displayName: "Ethereum",
    abbreviation: "ETH",
    decimalOffset: 18,
  },
  [CryptoCurrency.FET]: {
    displayName: "Fetch.ai",
    abbreviation: "FET",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.FIL]: {
    displayName: "Filecoin",
    abbreviation: "FIL",
    decimalOffset: 18,
  },
  [CryptoCurrency.FTM]: {
    displayName: "Fantom",
    abbreviation: "FTM",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.GAL]: {
    displayName: "Galaxe",
    abbreviation: "GAL",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.GALA]: {
    displayName: "Gala",
    abbreviation: "GALA",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.GMT]: {
    displayName: "STEPN",
    abbreviation: "GMT",
    contractType: ContractType.SPL,
    decimalOffset: 9,
  },
  [CryptoCurrency.GRT]: {
    displayName: "The Graph",
    abbreviation: "GRT",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.GUSD]: {
    displayName: "Gemini Dollar",
    abbreviation: "GUSD",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.INJ]: {
    displayName: "Injective Protocol",
    abbreviation: "INJ",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.JAM]: {
    displayName: "Geojam",
    abbreviation: "JAM",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.KNC]: {
    displayName: "Kyber types.Network",
    abbreviation: "KNC",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.KP3R]: {
    displayName: "Keep3rV1",
    abbreviation: "KP3R",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.LDO]: {
    displayName: "Lido DAO",
    abbreviation: "LDO",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.LINK]: {
    displayName: "Chainlink",
    abbreviation: "LINK",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.LPT]: {
    displayName: "Livepeer",
    abbreviation: "LPT",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.LRC]: {
    displayName: "Loopring",
    abbreviation: "LRC",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.LTC]: {
    displayName: "Litecoin",
    abbreviation: "LTC",
    decimalOffset: 8,
  },
  [CryptoCurrency.MANA]: {
    displayName: "Mana",
    abbreviation: "MANA",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.MASK]: {
    displayName: "Mask Network",
    abbreviation: "MASK",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.MATIC]: {
    displayName: "Polygon",
    abbreviation: "MATIC",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.MCO2]: {
    displayName: "Moss Carbon Credit",
    abbreviation: "MCO2",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.MKR]: {
    displayName: "Maker",
    abbreviation: "MKR",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.MOB]: {
    displayName: "MobileCoin",
    abbreviation: "MOB",
    decimalOffset: 12,
  },
  [CryptoCurrency.NMR]: {
    displayName: "Numeraire",
    abbreviation: "NMR",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.OXT]: {
    displayName: "Orchid",
    abbreviation: "OXT",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.PAXG]: {
    displayName: "PAX Gold",
    abbreviation: "PAXG",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.QNT]: {
    displayName: "Quant",
    abbreviation: "QNT",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.QRDO]: {
    displayName: "Qredo",
    abbreviation: "QRDO",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.RAY]: {
    displayName: "Raydium",
    abbreviation: "RAY",
    decimalOffset: 6,
    contractType: ContractType.SPL,
  },
  [CryptoCurrency.REN]: {
    displayName: "Ren",
    abbreviation: "REN",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.RNDR]: {
    displayName: "Render Token",
    abbreviation: "RNDR",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.SAMO]: {
    displayName: "Samoyed",
    abbreviation: "SAMO",
    decimalOffset: 9,
    contractType: ContractType.SPL,
  },
  [CryptoCurrency.SAND]: {
    displayName: "Sandbox",
    abbreviation: "SAND",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.SBR]: {
    displayName: "Saber",
    abbreviation: "SBR",
    decimalOffset: 6,
    contractType: ContractType.SPL,
  },
  [CryptoCurrency.SHIB]: {
    displayName: "Shiba Inu",
    abbreviation: "SHIB",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.SKL]: {
    displayName: "Skale",
    abbreviation: "SKL",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.SLP]: {
    displayName: "Smooth Love Potion",
    abbreviation: "SLP",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.SNX]: {
    displayName: "Synthetix",
    abbreviation: "SNX",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.SOL]: {
    displayName: "Solana",
    abbreviation: "SOL",
    decimalOffset: 9,
  },
  [CryptoCurrency.STORJ]: {
    displayName: "Storj",
    abbreviation: "STORJ",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.SUSHI]: {
    displayName: "SushiSwap",
    abbreviation: "SUSHI",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.UMA]: {
    displayName: "UMA",
    abbreviation: "UMA",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.UNI]: {
    displayName: "Uniswap",
    abbreviation: "UNI",
    decimalOffset: 18,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.USDC]: {
    displayName: "USD Coin",
    abbreviation: "USDC",
    decimalOffset: 6,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.USDT]: {
    displayName: "Tether",
    abbreviation: "USDT",
    decimalOffset: 6,
    contractType: ContractType.ERC20,
  },
  [CryptoCurrency.XLM]: {
    displayName: "Stellar",
    abbreviation: "XLM",
    decimalOffset: 7,
  },
  [CryptoCurrency.XRP]: {
    displayName: "XRP",
    abbreviation: "XRP",
    decimalOffset: 6,
  },
  [CryptoCurrency.XTZ]: {
    displayName: "Tezos",
    abbreviation: "XTZ",
    decimalOffset: 6,
  },
  [CryptoCurrency.YFI]: {
    displayName: "Yearn Finance",
    abbreviation: "YFI",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
  [CryptoCurrency.ZBC]: {
    displayName: "Zebec Protocol",
    abbreviation: "ZBC",
    contractType: ContractType.SPL,
    decimalOffset: 9,
  },
  [CryptoCurrency.ZEC]: {
    displayName: "ZCash",
    abbreviation: "ZEC",
    decimalOffset: 8,
  },
  [CryptoCurrency.ZRX]: {
    displayName: "0x",
    abbreviation: "ZRX",
    contractType: ContractType.ERC20,
    decimalOffset: 18,
  },
};

/**
 * Represent a currency value displayed with decimal points, as a number in the
 * minimum denomination of that currency. Rounds values that have too much
 * precision.
 *
 * This function will no longer be needed once `CurrencyValue.amount` contains a
 * minimum precision integer throughout the app.
 *
 * @example
 * const amountInMinDenom = currencyValueToMinimumDenominationAmount({
 *   currency: Currency.USD, amount: new Big(5.25)
 * });
 * expect(amountInMinDenom).toBe("525"); // cents
 */
export function cryptoCurrencyValueToMinimumDenominationAmount(params: {
  value: CryptoCurrencyValue;
}): number {
  const {
    value: { amount, currency },
  } = params;
  return parseInt(
    amount
      .mul(Math.pow(10, SharedCryptoCurrencyConfig[currency].decimalOffset))
      .toFixed(0)
  );
}

export type MinDenomCryptoCurrencyValue = {
  currency: CryptoCurrency;
} & ({ amountInMinDenom: SafeInt } | { amount: SafeInt });

/**
 * Represent a number in the minimum denomination of a currency, as a currency
 * value whose amount is encoded in the display format of that currency.
 *
 * @example
 * const value = minimumDenominationAmountToCurrencyValue({
 *   currency: Currency.USD, amountInMinDenom: 525
 * });
 * expect(value.currency).toBe(Currency.USD);
 * expect(value.amount.eq(new Big(5.25))).toBe(true);
 */
export function minimumDenominationAmountToCurrencyValue(
  params: MinDenomCryptoCurrencyValue
): CryptoCurrencyValue {
  const { currency } = params;
  const amount =
    "amountInMinDenom" in params ? params.amountInMinDenom : params.amount;
  return {
    currency: params.currency,
    amount: new Big(
      amount / Math.pow(10, SharedCryptoCurrencyConfig[currency].decimalOffset)
    ),
  };
}

export function displayLabelForCryptoCurrency(cc: CryptoCurrency) {
  const config = SharedCryptoCurrencyConfig[cc];
  return `${config.displayName} (${config.abbreviation})`;
}

export function displayContractType(
  contractType: ContractType,
  shorten: boolean
) {
  switch (contractType) {
    case ContractType.ERC20:
      // X on "the Ethereum mainnet" is language used by Coinbase at
      // https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/unsupported-crypto-recovery
      return shorten ? contractType : `${contractType} on the Ethereum mainnet`;
    case ContractType.SPL:
      return contractType;
  }
}

export function displayContractTypeForCryptoCurrency(
  cc: CryptoCurrency,
  shorten = true
) {
  const config = SharedCryptoCurrencyConfig[cc];
  return `${
    config.contractType
      ? `${displayContractType(config.contractType, shorten)}`
      : ""
  }`;
}

export const MAX_CRYPTO_DECIMALS_FOR_DISPLAY = 7;
