import { TypeOf } from "io-ts";
import {
  ComponentProps,
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

import {
  DonationBoostResponse,
  DonationChargeResponse,
  NonprofitResponse,
  PersonalDonationChargeResponse,
  TagResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { valueRaisedCodec } from "@every.org/common/src/routes/donation";

/**
 * Data as it should be encoded in location state to pass donation route data
 * via Read More links
 */
export interface ViewDonationData {
  donationCharge: PersonalDonationChargeResponse | DonationChargeResponse;
  nonprofit: NonprofitResponse;
  user: UserResponse;
  boost?: DonationBoostResponse;
  nonprofitSupporterCount?: number;
  valueRaised?: TypeOf<typeof valueRaisedCodec> | null;
  nonprofitTags?: TagResponse[];
}

interface ViewDonationContextData {
  donationData: ViewDonationData | null;
  setDonationData: Dispatch<SetStateAction<ViewDonationData | null>>;
}

/**
 * Context for holding donation data that was already fetched, to be used when
 * displaying a donation (saving an API round trip)
 */
export const ViewDonationContext =
  createContext<ViewDonationContextData | null>(null);

export const ViewDonationProvider = (
  props: Omit<ComponentProps<typeof ViewDonationContext.Provider>, "value"> & {
    initialData?: ViewDonationData;
  }
) => {
  const [donationData, setDonationData] = useState<ViewDonationData | null>(
    props.initialData ? props.initialData : null
  );

  return (
    <ViewDonationContext.Provider
      {...props}
      value={{ donationData, setDonationData }}
    />
  );
};
