import { AvatarSize, RawAvatar } from "@components/Avatar";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Combobox, TextInputType } from "@components/TextInput";
import { css } from "@emotion/react";
import Fuse from "fuse.js";
import React, { useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import {
  DISABLED_TOKENS,
  displayContractTypeForCryptoCurrency,
  displayLabelForCryptoCurrency,
} from "@every.org/common/src/helpers/cryptoCurrency";

import { Link } from "src/components/Link";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { BORDER_RADIUS, INPUT_BORDER_RADIUS } from "src/theme/common";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { numPxTextSizes, TextSize } from "src/theme/text";
import { CryptoCurrencyConfig } from "src/utility/cryptoCurrency";
import { mailToLink } from "src/utility/helpers";

const rotateCss = css`
  position: relative;
  top: -1px;
  transform: rotate(180deg);
`;
const contractTypeCss = css`
  font-size: ${numPxTextSizes[TextSize.xs]}px;
  color: var(${colorCssVars.text.secondary});
  white-space: nowrap;
`;

const suggestionItemCss = css`
  cursor: pointer;
  padding: ${spacing.s} ${spacing.m};
  /* background-color: var(${colorCssVars.input.background.default}); */

  :first-of-type {
    margin-top: 1px;
  }
  :last-child {
    border-radius: 0 0 ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS};
  }
`;

const activeSuggestionItemCss = css`
  background-color: var(${colorCssVars.input.background.focus});
`;

const quickSelectTokens = [
  CryptoCurrency.BTC,
  CryptoCurrency.ETH,
  CryptoCurrency.USDC,
];

function cryptoCurrencyToOption(cc: CryptoCurrency) {
  return {
    value: cc,
    label: displayLabelForCryptoCurrency(cc),
    contractType: displayContractTypeForCryptoCurrency(cc),
  };
}

const quickSelectOptions = quickSelectTokens.map(cryptoCurrencyToOption);

const allowedCurrencyOptions = Object.values(CryptoCurrency).filter(
  (cc) => !DISABLED_TOKENS.includes(cc)
);

const otherOptions = allowedCurrencyOptions
  .filter((cc) => !quickSelectTokens.includes(cc))
  .map(cryptoCurrencyToOption);

const cryptoCurrencyOptions = allowedCurrencyOptions.map(
  cryptoCurrencyToOption
);

export const CoinSelector = ({
  selectedCryptoCurrency,
  setSelectedCryptoCurrency,
  nonprofit,
}: {
  selectedCryptoCurrency: CryptoCurrency | undefined;
  setSelectedCryptoCurrency: (cc: CryptoCurrency | undefined) => void;
  nonprofit: ContextNonprofit;
}) => {
  const loggedInUser = useLoggedInUserOrUndefined();
  const cryptoSupportBody = `Contents: I would like to make a crypto donation to support https://www.every.org/${
    nonprofit.primarySlug
  }.\n\n${
    loggedInUser
      ? "My account: https://www.every.org/@" + loggedInUser.username
      : "My name: "
  }\nToken name:\nToken symbol:\nToken quantity:\n\nPlease reply back with an address where I can donate, as this is worth over $100,000 USD.`;

  const [searchText, setSearchText] = useState("");

  const cryptoCurrencyOptionsFuse = new Fuse(
    cryptoCurrencyOptions.map((option) => ({
      ...option,
      ...CryptoCurrencyConfig[option.value],
    })),
    {
      keys: ["displayName", "abbreviation"],
      threshold: 0.2,
    }
  );

  const filteredOptions = searchText
    ? cryptoCurrencyOptionsFuse.search(searchText).map(({ item }) => item)
    : undefined;

  function optionToListItem(option: {
    value: CryptoCurrency;
    label: string;
    contractType: string;
  }) {
    const selected = selectedCryptoCurrency === option.value;
    const cryptoConfig = CryptoCurrencyConfig[option.value];

    return (
      <li
        key={option.value}
        css={[
          suggestionItemCss,
          selected && activeSuggestionItemCss,
          css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `,
        ]}
        role="option"
        aria-selected={selected}
        onKeyUp={() => setSelectedCryptoCurrency(option.value)}
        onClick={() => setSelectedCryptoCurrency(option.value)}
      >
        <div css={[horizontalStackCss.xs, { alignItems: "center" }]}>
          {"iconImport" in cryptoConfig ? (
            <Icon
              iconImport={cryptoConfig.iconImport}
              css={{ marginRight: spacing.xs, minWidth: "24px" }}
              size={IconSize.MEDIUM}
              display={IconDisplay.SECONDARY}
            />
          ) : (
            <RawAvatar
              size={AvatarSize.XX_SMALL}
              customCSS={{ marginRight: spacing.xs, minWidth: "24px" }}
              cloudinaryId={cryptoConfig.iconCloudinaryId}
              alt={`${cryptoConfig.displayName} icon`}
            />
          )}
          <span css={option.contractType !== "" && { maxWidth: "85%" }}>
            {option.label}
          </span>
        </div>
        <span css={contractTypeCss}>{option.contractType}</span>
      </li>
    );
  }

  const selectedCryptoConfig =
    selectedCryptoCurrency && CryptoCurrencyConfig[selectedCryptoCurrency];

  return (
    <Combobox
      type={TextInputType.TEXT}
      data-tname="cryptoCurrencySelector"
      css={css`
        input {
          text-overflow: ellipsis;
        }
      `}
      inputPrefix={
        !selectedCryptoConfig || "iconImport" in selectedCryptoConfig ? (
          <Icon
            iconImport={
              selectedCryptoConfig
                ? selectedCryptoConfig.iconImport
                : () => import("@components/Icon/icons/SearchIcon")
            }
            css={{ marginRight: spacing.xs }}
            size={IconSize.MEDIUM}
            display={IconDisplay.SECONDARY}
          />
        ) : (
          <RawAvatar
            size={AvatarSize.XX_SMALL}
            customCSS={{ marginRight: spacing.xs, minWidth: "24px" }}
            cloudinaryId={selectedCryptoConfig.iconCloudinaryId}
            alt={`${selectedCryptoConfig.displayName} icon`}
          />
        )
      }
      inputSuffix={
        <div
          css={css`
            width: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 3px;
            margin-left: ${spacing.s};
          `}
        >
          <span css={contractTypeCss}>
            {selectedCryptoCurrency &&
              displayContractTypeForCryptoCurrency(selectedCryptoCurrency)}
          </span>
          <div
            css={css`
              justify-self: right;
            `}
          >
            <Icon
              css={selectedCryptoCurrency && rotateCss}
              iconImport={() =>
                import("@components/Icon/icons/SelectArrowIcon")
              }
              size={IconSize.MEDIUM}
              display={IconDisplay.ACCENT}
            />
          </div>
        </div>
      }
      onChange={(e) => setSearchText(e.currentTarget.value)}
      onSelect={() => {
        if (selectedCryptoCurrency) {
          setSearchText("");
          setSelectedCryptoCurrency(undefined);
        }
      }}
      value={
        (selectedCryptoCurrency &&
          displayLabelForCryptoCurrency(selectedCryptoCurrency)) ||
        searchText
      }
      collapseDescriptionSpace
      dropdown={
        !selectedCryptoCurrency && (
          <div
            css={css`
              border-width: 0px 2px 2px;
              border-style: solid;
              border-color: var(${colorCssVars.dividerSoft});
              border-radius: 0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS};
              padding: ${spacing.xs} 0;
              ${cssForMediaSize({
                max: MediaSize.LARGE,
                css: css`
                  max-height: 190px;
                `,
              })}
              ${cssForMediaSize({
                min: MediaSize.LARGE,
                css: css`
                  max-height: 260px;
                `,
              })}
      overflow-y: auto;
            `}
          >
            {filteredOptions && filteredOptions.length === 0 ? (
              <div
                css={css`
                  padding: ${spacing.m} ${spacing.l};
                `}
              >
                We don’t currently support this coin on our site, but for
                donations worth over $100,000 USD we can do it manually. Please
                email{" "}
                <Link
                  data-tname={"cryptoSupport-link"}
                  to={mailToLink({
                    address: "crypto@every.org",
                    subject: `Crypto donation for ${nonprofit.name}`,
                    body: cryptoSupportBody,
                  })}
                  appearance={LinkAppearance.HYPERLINK}
                >
                  crypto@every.org
                </Link>{" "}
                to arrange.
              </div>
            ) : (
              <ul>
                {filteredOptions ? (
                  filteredOptions.map(optionToListItem)
                ) : (
                  <React.Fragment>
                    {quickSelectOptions.map(optionToListItem)}
                    <div
                      css={css`
                        border-top: 2px solid var(${colorCssVars.dividerSoft});
                        margin: ${spacing.xs} 0;
                      `}
                    />
                    {otherOptions.map(optionToListItem)}
                  </React.Fragment>
                )}
              </ul>
            )}
          </div>
        )
      }
      isDropdownActive={!selectedCryptoCurrency}
      autoComplete="none"
    />
  );
};
