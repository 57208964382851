import { IconDisplay, IconSize } from "@components/Icon";
import { LoadingIcon } from "@components/LoadingIndicator";
import { SuggestedNonprofitCard } from "@components/feed/SuggestedNonprofitCard";
import { MasonryList } from "@components/layout/MasonryList";
import { css } from "@emotion/react";
import React, { Fragment, useContext, useMemo } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import { notUndefined } from "@every.org/common/src/helpers/objectUtilities";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import {
  ContextNonprofit,
  NonprofitFetchStatus,
} from "src/context/NonprofitsContext/types";
import { logger } from "src/utility/logger";

const FoundersPledgeID = "77fb1ba5-6700-498d-8df5-ed8483dc0cca";

interface NonprofitsProps {
  fund: ContextNonprofit;
}
const NonprofitsImpl = ({
  fund,
  endorsedNonprofitIds,
}: NonprofitsProps & {
  endorsedNonprofitIds: Exclude<
    NonprofitsProps["fund"]["endorsedNonprofitIds"],
    null
  >;
}) => {
  const nonprofitsState = useContext(NonprofitsContext);

  const { fundNonprofits, loadingNonprofits, fetchedFundNonprofits } =
    useMemo(() => {
      const fundNonprofits = endorsedNonprofitIds
        .map((id) => getNonprofit(nonprofitsState, { id }))
        .filter(notUndefined);
      const loadingNonprofits = fundNonprofits.filter(
        (n) => n === NonprofitFetchStatus.FETCHING_NONPROFIT
      );
      const fetchedFundNonprofits = fundNonprofits
        .map(nonprofitOrUndefined)
        .filter(notUndefined)
        .filter((nonprofit) => nonprofit.isDisbursable);

      if (
        loadingNonprofits.length !== 0 &&
        loadingNonprofits.length !== endorsedNonprofitIds.length
      ) {
        // we expect all nonprofits to be loading, or none of them to;
        // intermediate state represents an issue with the API
        const fetchedIds = new Set(fetchedFundNonprofits.map((n) => n.id));
        const missingNonprofitIds = endorsedNonprofitIds.filter(
          (id) => !fetchedIds.has(id)
        );
        logger.warn({
          message:
            "fund nonprofits were not all loaded; is a fund nonprofit missing from API response?",
          data: { missingNonprofitIds, fundId: fund.id },
        });
      }

      return {
        fundNonprofits,
        loadingNonprofits,
        fetchedFundNonprofits,
      };
    }, [endorsedNonprofitIds, fund.id, nonprofitsState]);

  if (loadingNonprofits.length === fundNonprofits.length) {
    // as defense against bad data coming from the API, we will stop showing
    // loading icon the moment any nonprofit data becomes available
    return (
      <LoadingIcon
        css={css`
          margin: ${spacing.m} auto;
        `}
        size={IconSize.LARGE}
        display={IconDisplay.SECONDARY}
      />
    );
  }

  return (
    <Fragment>
      {fetchedFundNonprofits.length > 0 ? (
        <MasonryList
          items={[
            ...fetchedFundNonprofits.map((fundNonprofit, index) => ({
              key: `nonprofit-${fundNonprofit.id}`,
              elem: (
                <SuggestedNonprofitCard
                  nonprofit={fundNonprofit}
                  hideHeader
                  topPick={
                    FoundersPledgeID === fund.fundCreatingEntityId &&
                    index === 0
                  }
                />
              ),
            })),
          ]}
        />
      ) : (
        <h4>No nonprofits added to this fund yet.</h4>
      )}
    </Fragment>
  );
};

export const Nonprofits = ({ fund, ...rest }: NonprofitsProps) => {
  if (fund.endorsedNonprofitIds === null) {
    // any fund should have a non-null value
    return <Fragment />;
  }

  return (
    <NonprofitsImpl
      {...rest}
      fund={fund}
      endorsedNonprofitIds={fund.endorsedNonprofitIds}
    />
  );
};
