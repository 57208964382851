import NextLink from "next/link";
import React from "react";

import { RequireKeys } from "@every.org/common/src/helpers/types";
import { isAbsoluteUrl } from "@every.org/common/src/helpers/url";

import { LinkAppearance, linkStyleByAppearance } from "src/styles/link";
import { ClickAction } from "src/utility/analytics";

export { LinkAppearance } from "src/styles/link";

export type LinkComponent = (props: LinkProps) => React.ReactNode | null;

export interface LinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, "href" | "as" | "ref"> {
  "data-tname": string;
  "data-action"?: ClickAction;
  to: string;
  ref?: React.Ref<HTMLAnchorElement> | null;
  /**
   * An optional callback that will be called when the link is executed
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  /**
   * How the link should appear
   */
  appearance?: LinkAppearance;
  /**
   * Force a page refresh when the link is clicked; only takes effect if `to` is
   * a `string`, not a location descriptor
   *
   * This is useful when you're linking to something served from our domain, but
   * isn't served by the frontend web app - for example, a static file like a
   * PDF
   *
   * Normally, relative links cause this component to link via JavaScript
   * without a page refresh to a `react-router` route, bypassing normal HTML
   * <a /> tag behavior
   */
  forceExternal?: boolean;
  prefetch?: boolean;
  scroll?: boolean;
}

function AbsoluteLink({
  to,
  ref,
  appearance,
  children,
  ...rest
}: RequireKeys<LinkProps, "appearance">) {
  return (
    <a ref={ref} css={linkStyleByAppearance[appearance]} href={to} {...rest}>
      {children}
    </a>
  );
}

function RelativeLink({
  appearance,
  ref,
  to,
  children,
  prefetch = false,
  scroll = true, // default behavior true: scroll to top of page
  ...rest
}: RequireKeys<LinkProps, "appearance">) {
  return (
    <NextLink
      href={to}
      passHref
      prefetch={prefetch}
      scroll={scroll}
      legacyBehavior
    >
      <a css={linkStyleByAppearance[appearance]} ref={ref} {...rest}>
        {children}
      </a>
    </NextLink>
  );
}

/**
 * A link to external content.
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkImpl(
    {
      appearance = LinkAppearance.HYPERLINK,
      forceExternal = false,
      to,
      ...rest
    },
    ref
  ) {
    return typeof to === "string" && (forceExternal || isAbsoluteUrl(to)) ? (
      <AbsoluteLink ref={ref} {...rest} to={to} appearance={appearance} />
    ) : (
      <RelativeLink ref={ref} {...rest} to={to} appearance={appearance} />
    );
  }
);

/**
 * Use this link to recommend that someone should contact us.
 *
 * You can specify whatever text you want as the children,
 * or the default is simply the text "contact us".
 */
export const ContactUsLink: React.FCC = ({ children }) => (
  <Link
    appearance={LinkAppearance.HYPERLINK}
    data-tname="support-request"
    target="contact"
    to="https://support.every.org/hc/en-us/requests/new"
  >
    {children ?? "contact us"}
  </Link>
);
