import {
  FeedItemResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { getNonprofitFundraisersCachedRouteSpec } from "@every.org/common/src/routes/fundraiser";
import { StaticApiRouteSpec } from "@every.org/common/src/routes/index";
import {
  getNonprofitFeedRouteSpec,
  getNonprofitRouteSpec,
} from "@every.org/common/src/routes/nonprofit";

import { InitialContextData } from "src/context/ContextProviders";
import { serversideStaticQueryApi } from "src/utility/apiClient";

export const NUM_FUNDRAISERS_TO_FETCH = 3;
export const NUM_FUNDRAISERS_TO_SHOW = 2;

export async function getNonprofitPageInitialProps(slug: string): Promise<{
  initialData?: InitialContextData;
  feedItems?: FeedItemResponse[];
  feedHasMore?: boolean;
  isCausePage?: boolean;
  initialFundraisers?: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
}> {
  try {
    const {
      nonprofit,
      supporterCount,
      nonprofitTags,
      fundMetadata,
      giftCardCampaign,
      donatedCurrencies,
    } = await serversideStaticQueryApi(
      getNonprofitRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: { identifier: slug },
        queryParams: {},
      }
    );

    const fundraisersData = await serversideStaticQueryApi(
      getNonprofitFundraisersCachedRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: { id: nonprofit.id },
        queryParams: {
          take: NUM_FUNDRAISERS_TO_FETCH,
          skip: 0,
        },
      }
    );

    const fetchedNonprofit = {
      ...nonprofit,
      supporterCount,
      nonprofitTags,
      ...fundMetadata,
      giftCardCampaign,
      donatedCurrencies,
      allTimeRaised: {
        ...(nonprofit?.alltimeRaised || {}),
        // Amount is not coming serializable from the API.
        amount: nonprofit?.alltimeRaised?.amount?.toFixed(0) || null,
      },
    };

    const feedData = await serversideStaticQueryApi(
      getNonprofitFeedRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: {
          id: fetchedNonprofit.id,
        },
        queryParams: {
          take: 15,
          skip: 0,
        },
      }
    );

    return {
      initialData: {
        nonprofits: [fetchedNonprofit, ...feedData.nonprofits],
        users: [...feedData.users, ...fundraisersData.users],
        tags: feedData.tags,
      },
      feedItems: feedData.items,
      feedHasMore: feedData.hasMore,
      initialFundraisers: {
        fundraisers: fundraisersData.fundraisers,
        hasMore: fundraisersData.fundraisers.length > NUM_FUNDRAISERS_TO_SHOW,
      },
    };
  } catch (e) {
    if (e.httpStatus === 404) {
      throw e;
    }
    return {};
  }
}
