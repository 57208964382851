import { Popover } from "@components/Popover";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export const clickableCss = css`
  position: relative;
  z-index: 1;
`;

export const secondaryTextCss = css`
  color: var(${colorCssVars.text.secondary});
`;

export const HeaderClickablePopover = styled(Popover)`
  ${clickableCss};
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: var(${colorCssVars.text.body});
  }
`;

export const Header = styled.header`
  ${horizontalStackCss.xs};
  ${textSizeCss.xs};
  align-items: center;
`;

export const StyledSection = styled.section`
  ${horizontalStackCss.xxs};
  align-items: center;
  flex-wrap: wrap;
`;

export const TimesChargedAndTimeList = styled.ul`
  ${verticalStackCss.xs};
  li {
    ${horizontalStackCss.xs};
    align-items: center;
  }
`;
