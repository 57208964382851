import { Stepper } from "@components/Stepper";
import { useMemo } from "react";

export enum StepperType {
  MANUAL_DAF = "MANUAL_DAF",
  STOCKS = "STOCKS",
}

const StepsForStepperType = {
  [StepperType.MANUAL_DAF]: ["Donation details", "Review", "Instruction"],
  [StepperType.STOCKS]: ["Review", "Instruction"],
};

export function useStepper(type: StepperType, activeStep: number) {
  return useMemo(() => {
    return (
      <Stepper steps={StepsForStepperType[type]} activeStep={activeStep} />
    );
  }, [activeStep, type]);
}
