import { Button, ButtonTargetKind, ButtonRole } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { TextInput } from "@components/TextInput";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { ErrorMessage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { Big } from "big.js";
import React, { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { coerceToSafeIntOrThrow } from "@every.org/common/src/codecs/number";
import { GIVING_GAP_WEBHOOK_TOKEN } from "@every.org/common/src/entity/constants";
import {
  Currency,
  DonationFlowPaymentOption,
  GetUnclaimedGivingCreditStatus,
} from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import {
  GetUnclaimedGivingCreditResponse,
  getUnclaimedGivingCreditRouteSpec,
} from "@every.org/common/src/routes/donate";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { queryApi } from "src/utility/apiClient";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

const AvailableCreditDescription = ({
  availableGivingCredit,
}: {
  availableGivingCredit: Big | undefined;
}) => {
  const navigate = useNavigate();

  if (!availableGivingCredit || availableGivingCredit.lte(0)) {
    return null;
  }

  const creditString = displayCurrencyValueInUserLocale({
    currencyValue: {
      amount: availableGivingCredit,
      // TODO #8478: parametrize currency
      currency: Currency.USD,
    },
  });

  return (
    <span css={{ color: `var(${colorCssVars.text.secondary})` }}>
      You have {creditString} in donation credits available. Switch to the{" "}
      <Button
        data-tname="giftCardToCreditCardLink"
        role={ButtonRole.TEXT_ONLY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () =>
            navigate(
              paymentProcessRouteNameToPathMap[
                PaymentProcessRouteName.CREDIT_CARD
              ]
            ),
        }}
      >
        card
      </Button>{" "}
      or{" "}
      <Button
        data-tname="giftCardToBankLink"
        role={ButtonRole.TEXT_ONLY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () =>
            navigate(
              paymentProcessRouteNameToPathMap[PaymentProcessRouteName.BANK]
            ),
        }}
      >
        bank
      </Button>{" "}
      payment methods to use them.
    </span>
  );
};

export const Redeem = ({
  form,
  formContext,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
}) => {
  const [code, setCode] = useState<string | undefined>(
    formContext.giftCardCode
  );
  const [givingCreditResponse, setGivingCreditResponse] =
    useState<GetUnclaimedGivingCreditResponse>();
  const [codeFetching, setCodeFetching] = useState(false);
  const [error, setError] = useState<string>();

  const onGivingCreditResponse = useCallback(
    (response: GetUnclaimedGivingCreditResponse) => {
      setGivingCreditResponse(response);
      if (response.givingCredit) {
        form.setValue("givingCreditToRedeem", response.givingCredit);
        form.setValue("giftCardCode", code);
        form.setValue(
          "amount",
          minimumDenominationAmountToCurrencyValue({
            currency: Currency.USD,
            amountInMinDenom: coerceToSafeIntOrThrow({
              num: response.givingCredit?.amountRemaining || 0,
            }),
          }).amount.toNumber()
        );
      } else {
        form.setValue("givingCreditToRedeem", undefined);
      }
    },
    [code, form]
  );

  const fetchGiftCard = useCallback(async () => {
    if (code) {
      setCodeFetching(true);
      setError("");
      const result = await queryApi(getUnclaimedGivingCreditRouteSpec, {
        routeTokens: {},
        queryParams: { code },
        body: {},
      });
      if (result) {
        if (result.status === GetUnclaimedGivingCreditStatus.INVALID_CODE) {
          setError(`The code ${code} appears to be invalid.`);
        }
        if (result.status === GetUnclaimedGivingCreditStatus.ALREADY_CLAIMED) {
          setError(`The code ${code} has already been claimed.`);
        }
        if (result.status === GetUnclaimedGivingCreditStatus.EXPIRED) {
          setError(`Sorry, this gift card has expired.`);
        }
        onGivingCreditResponse(result);
      } else {
        setGivingCreditResponse(undefined);
      }

      setCodeFetching(false);
    }
  }, [code, onGivingCreditResponse]);

  useEffect(() => {
    if (formContext.giftCardCode && !givingCreditResponse) {
      fetchGiftCard();
    }
  }, [givingCreditResponse, formContext.giftCardCode, fetchGiftCard]);

  return (
    <React.Fragment>
      <h4 css={{ textAlign: "center" }}>Gift card code</h4>
      <TextInput
        name="code"
        id="code"
        data-tname="code"
        onChange={(e) => {
          setCode(e.target.value);
        }}
        value={code || ""}
        collapseDescriptionSpace
      />
      <p>You can find this five digit code on the back of your gift card</p>
      <Button
        data-tname="donateFlow-redeemGiftCard"
        role={ButtonRole.PRIMARY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: fetchGiftCard,
        }}
        css={{ width: "200px", alignSelf: "center" }}
        submitting={codeFetching}
      >
        Redeem gift card
      </Button>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </React.Fragment>
  );
};

export const Link = ({ nonprofit }: { nonprofit: ContextNonprofit }) => {
  return (
    <React.Fragment>
      <p>Redeem a gift card to add donation credits to your account.</p>
      <Button
        data-tname="donateFlow-redeemGiftCard"
        role={ButtonRole.PRIMARY}
        onClick={{
          kind: ButtonTargetKind.LINK,
          to: getRoutePath({
            name: ClientRouteName.GIFT_CARD,
            format: URLFormat.RELATIVE,
            query: {
              nonprofitSlug: nonprofit.primarySlug,
            },
          }),
        }}
        css={{ width: "200px", alignSelf: "center" }}
      >
        Redeem gift card
      </Button>
    </React.Fragment>
  );
};

export const GiftCard = ({
  form,
  formContext,
  nonprofit,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
}) => {
  const isGGNonprofit =
    formContext.partnerWebhookToken === GIVING_GAP_WEBHOOK_TOKEN;

  return (
    <PageContainer>
      <SelectDonationFlowPaymentOption
        selectedPaymentOption={DonationFlowPaymentOption.GIFT_CARD}
        paymentRequestReadyStatus={
          formContext.paymentRequestInitializer.readyStatus
        }
        paymentRequestIsApplePay={
          formContext.paymentRequestInitializer.isApplePay
        }
        paymentFlowOptions={formContext.paymentFlowOptions}
        showMorePaymentOptions={formContext.showMorePaymentOptions}
        setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
      />
      <ProcessContainer>
        <div
          css={css`
            ${verticalStackCss.l}
            ${cssForMediaSize({
              max: MediaSize.MEDIUM,
              css: css`
                padding: 0 ${spacing.xxl} 0 ${spacing.xxl};
              `,
            })}
          `}
        >
          <Icon
            display={IconDisplay.ACCENT}
            size={IconSize.XXX_LARGE}
            iconImport={() => import("@components/Icon/icons/GiftIcon")}
            css={{ alignSelf: "center" }}
          />
          {isGGNonprofit ? (
            <Redeem form={form} formContext={formContext} />
          ) : (
            <Link nonprofit={nonprofit} />
          )}
          <AvailableCreditDescription
            availableGivingCredit={formContext.availableGivingCredit}
          />
        </div>
      </ProcessContainer>
    </PageContainer>
  );
};
