import { IconSize } from "@components/Icon";
import { ShareIconButton } from "@components/ShareIconButton";
import {
  IconsContainer,
  shareIconButtonCss,
} from "@components/donate/DonateV3/PaymentProcess/components/ShareMatch/styles";
import { useShareData } from "@components/feed/DonationShareButton";
import React, { useCallback } from "react";

import {
  DonationResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { ShareMedium } from "@every.org/common/src/entity/types";
import { trackShareRouteSpec } from "@every.org/common/src/routes/donation";

import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

interface ShareButtonsProps {
  donation: DonationResponse | PersonalDonationResponse;
  // Override the donation comment for the share text.
  overrideComment?: string;
  // If true, don't use user donation URL for share
  skipUserDonationUrl?: boolean;
  onShared: () => void;
  overrideUrl?: string;
  campaign?: string;
}

export async function trackShare(
  donationId: ShareButtonsProps["donation"]["id"],
  shareMedium: ShareMedium
) {
  try {
    await queryApi(trackShareRouteSpec, {
      body: { shareMedium },
      routeTokens: { id: donationId },
      queryParams: {},
    });
  } catch (error) {
    logger.error({
      error,
      data: {
        shareMedium,
        donationId,
      },
      message: "Track share error",
    });
  }
}

export function ShareButtons({
  donation,
  overrideComment,
  skipUserDonationUrl,
  onShared,
  overrideUrl,
  campaign,
}: ShareButtonsProps) {
  const shareItems: Exclude<ShareMedium, ShareMedium.NATIVE>[] = [
    ShareMedium.FACEBOOK,
    ShareMedium.TWITTER,
    ShareMedium.WHATSAPP,
    ShareMedium.LINKEDIN,
    ShareMedium.LINK,
  ];

  const shareData = useShareData(donation, {
    overrideComment,
    skipUserDonationUrl,
    overrideUrl,
    campaign,
  });

  const onClick = useCallback(
    (shareMedium: ShareMedium) => {
      onShared();
      trackShare(donation.id, shareMedium);
    },
    [donation.id, onShared]
  );

  return (
    <IconsContainer>
      {shareItems.map((medium) => (
        <ShareIconButton
          css={shareIconButtonCss}
          key={medium}
          medium={medium}
          shareData={shareData}
          onClick={onClick}
          size={IconSize.X_LARGE}
        />
      ))}
    </IconsContainer>
  );
}
