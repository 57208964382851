import { BooleanFromString } from "io-ts-types/BooleanFromString";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import {
  isPresent,
  removeUndefinedValues,
} from "@every.org/common/src/helpers/objectUtilities";
import {
  getUserAgentBotType,
  UserBotType,
} from "@every.org/common/src/helpers/userAgent";

import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export function promiseWithTimeout<T>(
  timeoutMs: number,
  promise: () => Promise<T>,
  errorMsg: string
): Promise<T> {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise<T>((resolve, reject) => {
    timeoutHandle = setTimeout(() => {
      logger.warn({
        error: new Error("Promise timed out"),
        message: "Promise timed out",
        data: { timeoutMs, errorMsg },
      });
      return reject();
    }, timeoutMs);
  });

  return Promise.race([promise(), timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
}

export function getAndDecodeSearchParam(
  searchParams: URLSearchParams,
  paramName: string
) {
  const paramValue = searchParams.get(paramName);
  return paramValue && decodeURIComponent(paramValue);
}

export function getAndDecodeBooleanSearchParam(
  searchParams: URLSearchParams,
  paramName: string
) {
  const paramValidation = BooleanFromString.decode(
    getAndDecodeSearchParam(searchParams, paramName)?.toLowerCase()
  );
  return paramValidation._tag === "Left" ? undefined : paramValidation.right;
}

function addParams(
  link: string,
  params: { [key: string]: string | number | boolean | undefined }
) {
  const definedParams = removeUndefinedValues(params);
  // We don't use URLSearchParams here because it changes spaces to +s instead
  // of %20, and some mail clients like Apple don't handle that well.
  const queryString = Object.entries(definedParams)
    .map(([key, value]) => {
      try {
        return [key, value].map(encodeURIComponent).join("=");
      } catch (err) {
        logger.warn({
          message:
            "Bad surrogate pair when trying to uri encode string, probably bad truncation or bad value",
          data: { value },
        });
        return null;
      }
    })
    .filter(isPresent)
    .join("&");
  return queryString ? [link, queryString].join("?") : link;
}

export function mailToLink(params: {
  address?: string;
  subject?: string;
  body?: string;
  cc?: string;
}) {
  return addParams(`mailto:${params.address ?? ""}`, {
    subject: params.subject,
    body: params.body,
    cc: params.cc,
  });
}

let clientBotType: UserBotType | null | undefined = undefined;

export function getClientBotType() {
  if (clientBotType !== undefined) {
    return clientBotType;
  }
  clientBotType = getUserAgentBotType(getWindow()?.navigator.userAgent);
  return clientBotType;
}

const VALID_PAYMENT_OPTIONS = new Set(Object.values(DonationFlowPaymentOption));

function methodIsPaymentFlowOption(
  method: string
): method is DonationFlowPaymentOption {
  return VALID_PAYMENT_OPTIONS.has(method as DonationFlowPaymentOption);
}

export function paymentFlowOptionsFromString(string: string) {
  return string
    .split(",")
    .filter((paymentMethod): paymentMethod is DonationFlowPaymentOption =>
      methodIsPaymentFlowOption(paymentMethod)
    );
}

export function paymentFlowOptionsToString(
  paymentOptions: DonationFlowPaymentOption[]
): string {
  return paymentOptions.join(",");
}
