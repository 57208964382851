import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { DONATE_HASH } from "@every.org/common/src/helpers/clientRoutes";

export enum PaymentProcessRouteName {
  CREDIT_CARD = "CREDIT_CARD",
  CONFIRM_CARD = "CONFIRM_CARD",
  BANK = "BANK",
  CONFIRM_BANK = "CONFIRM_BANK",
  PAYPAL = "PAYPAL",
  CONFIRM_PAYPAL = "CONFIRM_PAYPAL",
  VENMO = "VENMO",
  CONFIRM_VENMO = "CONFIRM_VENMO",
  GIFT_CARD = "GIFT_CARD",
  APPLE_PAY = "APPLE_PAY",
  CONFIRM_APPLE_PAY = "CONFIRM_APPLE_PAY",
  CRYPTO = "CRYPTO",
  CONFIRM_CRYPTO = "CONFIRM_CRYPTO",
  CRYPTO_COMPLETE = "CRYPTO_COMPLETE",
  STOCKS = "STOCKS",
  STOCKS_CONFIRM = "STOCK_CONFIRM",
  STOCKS_INSTRUCTIONS = "STOCK_INSTRUCTIONS",
  DAF = "DAF",
  DAF_MANUAL = "DAF_MANUAL",
  DAF_MANUAL_CONFIRM = "DAF_MANUAL_CONFIRM",
  DAF_MANUAL_INSTRUCTIONS = "DAF_MANUAL_INSTRUCTIONS",
  DAF_CHARIOT = "DAF_CHARIOT",
  CONFIRM_DAF_CHARIOT = "CONFIRM_DAF_CHARIOT",
  SHARE_MATCH = "SHARE_MATCH",
  THANK_YOU = "THANK_YOU",
  LOGIN = "LOGIN",
}

export const CONFIRMATION_POSTFIX = "/confirm";

function buildDonateRoute(path: string) {
  return `${DONATE_HASH}/${path}`;
}

export const paymentProcessRouteNameToPathMap: {
  [key in PaymentProcessRouteName]: string;
} = {
  [PaymentProcessRouteName.CREDIT_CARD]: buildDonateRoute(
    DonationFlowPaymentOption.CREDIT_CARD
  ),
  [PaymentProcessRouteName.CONFIRM_CARD]: buildDonateRoute(
    `${DonationFlowPaymentOption.CREDIT_CARD}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.BANK]: buildDonateRoute(
    `${DonationFlowPaymentOption.BANK}`
  ),
  [PaymentProcessRouteName.CONFIRM_BANK]: buildDonateRoute(
    `${DonationFlowPaymentOption.BANK}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.PAYPAL]: buildDonateRoute(
    `${DonationFlowPaymentOption.PAYPAL}`
  ),
  [PaymentProcessRouteName.CONFIRM_PAYPAL]: buildDonateRoute(
    `${DonationFlowPaymentOption.PAYPAL}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.VENMO]: buildDonateRoute(
    `${DonationFlowPaymentOption.VENMO}`
  ),
  [PaymentProcessRouteName.CONFIRM_VENMO]: buildDonateRoute(
    `${DonationFlowPaymentOption.VENMO}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.GIFT_CARD]: buildDonateRoute(
    `${DonationFlowPaymentOption.GIFT_CARD}`
  ),
  [PaymentProcessRouteName.APPLE_PAY]: buildDonateRoute(
    `${DonationFlowPaymentOption.PAYMENT_REQUEST}`
  ),
  [PaymentProcessRouteName.CONFIRM_APPLE_PAY]: buildDonateRoute(
    `${DonationFlowPaymentOption.PAYMENT_REQUEST}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.CRYPTO]: buildDonateRoute(
    `${DonationFlowPaymentOption.CRYPTO}`
  ),
  [PaymentProcessRouteName.CONFIRM_CRYPTO]: buildDonateRoute(
    `${DonationFlowPaymentOption.CRYPTO}${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.CRYPTO_COMPLETE]: buildDonateRoute(
    `${DonationFlowPaymentOption.CRYPTO}${CONFIRMATION_POSTFIX}/complete`
  ),
  [PaymentProcessRouteName.STOCKS_INSTRUCTIONS]: buildDonateRoute(
    `${DonationFlowPaymentOption.STOCKS}/instructions`
  ),
  [PaymentProcessRouteName.STOCKS_CONFIRM]: buildDonateRoute(
    `${DonationFlowPaymentOption.STOCKS}/confirm`
  ),
  [PaymentProcessRouteName.STOCKS]: buildDonateRoute(
    `${DonationFlowPaymentOption.STOCKS}`
  ),
  [PaymentProcessRouteName.DAF]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}`
  ),
  [PaymentProcessRouteName.DAF_MANUAL]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}/manual`
  ),
  [PaymentProcessRouteName.DAF_MANUAL_CONFIRM]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}/manual/confirm`
  ),
  [PaymentProcessRouteName.DAF_MANUAL_INSTRUCTIONS]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}/manual/instructions`
  ),
  [PaymentProcessRouteName.DAF_CHARIOT]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}/chariot`
  ),
  [PaymentProcessRouteName.CONFIRM_DAF_CHARIOT]: buildDonateRoute(
    `${DonationFlowPaymentOption.DAF}/chariot${CONFIRMATION_POSTFIX}`
  ),
  [PaymentProcessRouteName.SHARE_MATCH]: buildDonateRoute("share"),
  [PaymentProcessRouteName.THANK_YOU]: buildDonateRoute("thanks"),
  [PaymentProcessRouteName.LOGIN]: buildDonateRoute("login"),
};

export const donateRoutePathCorrectionMap = new Map([
  [
    buildDonateRoute("stock"),
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS],
  ],
]);

export const donationFlowPaymentOptionToPaymentProcessRouteNameMap: {
  [key in DonationFlowPaymentOption]: PaymentProcessRouteName;
} = {
  [DonationFlowPaymentOption.CREDIT_CARD]: PaymentProcessRouteName.CREDIT_CARD,
  [DonationFlowPaymentOption.BANK]: PaymentProcessRouteName.BANK,
  [DonationFlowPaymentOption.PAYPAL]: PaymentProcessRouteName.PAYPAL,
  [DonationFlowPaymentOption.VENMO]: PaymentProcessRouteName.VENMO,
  [DonationFlowPaymentOption.GIFT_CARD]: PaymentProcessRouteName.GIFT_CARD,
  [DonationFlowPaymentOption.PAYMENT_REQUEST]:
    PaymentProcessRouteName.APPLE_PAY,
  [DonationFlowPaymentOption.CRYPTO]: PaymentProcessRouteName.CRYPTO,
  [DonationFlowPaymentOption.STOCKS]: PaymentProcessRouteName.STOCKS,
  [DonationFlowPaymentOption.DAF]: PaymentProcessRouteName.DAF,
};
