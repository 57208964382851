import { UUID } from "io-ts-types";
import { useState } from "react";

import { Button, ButtonTargetKind } from "src/components/Button";
import { Modal, ModalProps } from "src/components/Modal";
import { ButtonRole, ButtonSize } from "src/styles/button";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";

interface HideConfirmationModalProps extends Pick<ModalProps, "isOpen"> {
  onConfirm: () => void;
  onReject: () => void;
  nonprofitName: string;
  fundraiserId: UUID;
  nonprofitId: UUID;
  hidden: boolean | undefined;
}

export const HideConfirmationModal = (props: HideConfirmationModalProps) => {
  const [submitting, setSubmitting] = useState(false);

  const onHideFundraiser = () => {
    setSubmitting(true);
    props.onConfirm();
    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onReject}
      headerText={
        props.hidden ? "Show your fundraiser?" : "Hide your fundraiser?"
      }
      showHeader
      onAfterClose={props.onReject}
    >
      <div css={[verticalStackCss.s, { maxWidth: 440 }]}>
        <p>
          {props.hidden
            ? "The fundraiser can appear again on your nonprofit's page and in feeds."
            : "The fundraiser will no longer be shown on your nonprofit's page or in feeds. It will still be available via direct link for people to share and donate."}
        </p>
        <div css={[horizontalStackCss.s, { justifyContent: "space-between" }]}>
          <Button
            data-tname="fundraiserHideAbort"
            role={ButtonRole.TEXT_ONLY}
            size={ButtonSize.MEDIUM}
            disabled={submitting}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: props.onReject,
            }}
          >
            {props.hidden ? "Keep it invisible" : "Keep showing it"}
          </Button>
          <Button
            data-tname="fundraiserHideConfirm"
            role={ButtonRole.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={submitting}
            submitting={submitting}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                onHideFundraiser();
              },
            }}
          >
            {props.hidden ? "Unhide fundraiser" : "Hide fundraiser"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
