import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  BLACK_WOMEN_LEAD_TAG_NAMES,
  NonprofitCard,
} from "@components/feed/NewNonprofitCard";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { NextPage } from "next/types";
import React, { useContext, useEffect, useState } from "react";

import { Location } from "@every.org/common/src/codecs/location";
import { DEFAULT_OPENGRAPH_CLOUDINARY_ID } from "@every.org/common/src/entity/types";
import { CustomEvent } from "@every.org/common/src/helpers/analytics";
import {
  CAUSES_SEPARATOR,
  joinCauses,
  splitCauses,
} from "@every.org/common/src/helpers/causes";
import {
  clientRouteMetas,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import { filterObject } from "@every.org/common/src/helpers/objectUtilities";
import {
  joinEnglishWordSeries,
  toTitleCase,
} from "@every.org/common/src/helpers/string";
import { SearchAllResponseBody } from "@every.org/common/src/routes/search";

import { LoadingIndicator } from "src/components/LoadingIndicator";
import {
  SearchFilter,
  SearchFilterModalButton,
} from "src/components/SearchResults/SearchFilter";
import { SearchHeader } from "src/components/SearchResults/SearchHeader";
import { SearchInfoBox } from "src/components/SearchResults/SearchInfoBox";
import {
  AutocompleteSection,
  BrowseDropdownContainer,
  SearchTextInput,
  getLocationPrediction,
} from "src/components/SearchTextInput";
import { MAX_TEXT_INPUT_WIDTH } from "src/components/TextInput";
import { UserListCard } from "src/components/UserListCard";
import { DefaultPageLayout } from "src/components/layout/DefaultPageLayout";
import {
  MasonryList,
  MasonryListProps,
} from "src/components/layout/MasonryList";
import { MAX_PAGE_SECTION_WIDTH } from "src/components/layout/PageSection";
import { SkeletonDonationCard } from "src/components/skeleton/SkeletonDonationCard";
import { LoadImageWithPriorityProvider } from "src/context/LoadImageWithPriorityContext/LoadImageWithPriorityContext";
import { useSearchOptionsFromUrl } from "src/context/SearchContext/helpers";
import {
  SearchContext,
  submitSearchAction,
  loadMoreAction,
} from "src/context/SearchContext/index";
import { SearchStateValue } from "src/context/SearchContext/types";
import {
  SearchErrorMessageContext,
  SearchErrorMessageProvider,
} from "src/context/SearchErrorMessageContext";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { colorCssVars } from "src/theme/color";
import { BORDER_RADIUS, INPUT_BORDER_RADIUS } from "src/theme/common";
import {
  cssForMediaSize,
  MediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, TextCssVar, textSizeCss } from "src/theme/text";
import { trackEvent, ClickAction } from "src/utility/analytics";
import { OPENGRAPH_DIMENSIONS } from "src/utility/opengraph";

const SearchHeaderContainer = styled.div`
  ${verticalStackCss.none};
  padding: 0 ${spacing.l} ${spacing.l};

  h1 {
    display: inline-flex;
    ${cssForMediaSize({
      max: MediaSize.MEDIUM,
      css: css`
        span {
          ${textSizeCss.m}
          ${TextCssVar.LINE_HEIGHT}: 28px;
          font-weight: ${FontWeight.BOLD};
        }
      `,
    })}
  }

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding: ${spacing.l} ${spacing.xl};
    `,
  })};
`;

const StyledPageSection = styled.section`
  max-width: ${MAX_PAGE_SECTION_WIDTH};
  padding: 0 ${spacing.s} ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding: 0 ${spacing.xl} ${spacing.xl};
    `,
  })}
`;

const PageGrid = styled.div`
  display: grid;
  padding-top: ${spacing.l};
  height: 100%;
  max-width: ${MAX_PAGE_SECTION_WIDTH};
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 380px 1fr;
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      grid-template-columns: 1fr;
    `,
  })}
`;

export const NoResults: React.FCC = () => (
  <div
    css={css`
      width: 100%;
      padding: ${spacing.l} ${spacing.m};
      ${verticalStackCss.m};
      align-items: center;
      text-align: center;
    `}
  >
    <h2>No results found</h2>
    <p
      css={css`
        color: var(${colorCssVars.text.secondary});
      `}
    >
      Please check your spelling or try different keywords.
    </p>
  </div>
);

const LoadingSkeleton: React.FCC<{
  numColumns: MasonryListProps["numColumns"];
}> = ({ numColumns }) => {
  const items = new Array(10).fill(null).map((_, index) => ({
    key: `${index}`,
    elem: <SkeletonDonationCard />,
  }));
  return <MasonryList numColumns={numColumns} items={items} />;
};

const focusedSearchCss = css`
  &:focus-within {
    border: 1px solid transparent;
    background: var(${colorCssVars.input.background.focus});
  }
  border-radius: ${INPUT_BORDER_RADIUS};
`;

const StyledSearchTextInput = styled(SearchTextInput)`
  width: 100%;
  ${BrowseDropdownContainer} {
    top: 65px;
    border-radius: ${BORDER_RADIUS};
  }
`;

const errorMessageCss = css`
  ${textSizeCss.xl};
  margin: 0 auto;
  text-align: center;
  display: flex;
  width: 80%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SearchResults: React.FCC = () => {
  // eslint-disable-next-line no-restricted-syntax
  const isLargeScreen = useMatchesScreenSize({ min: MediaSize.LARGE }) ?? false;

  const searchState = useContext(SearchContext);
  const { getPathnameWithParams } = useEdoRouter();
  const { path } = getPathnameWithParams();
  const isCausesPage = clientRouteMetas[ClientRouteName.CAUSES].path === path;
  const errorMessageState = useContext(SearchErrorMessageContext);
  const [initiallySubmitted, setInitiallySubmitted] = useState(false);
  const searchParams = useSearchOptionsFromUrl();

  const { query } = useRouter();

  useEffect(() => {
    if (
      !initiallySubmitted &&
      isCausesPage &&
      submitSearchAction &&
      !searchState.prevSearchResults
    ) {
      setInitiallySubmitted(true);
      submitSearchAction(searchParams);
    }
  }, [
    initiallySubmitted,
    searchState.prevSearchResults,
    isCausesPage,
    searchParams,
  ]);

  if (
    (searchState.value === SearchStateValue.READY &&
      errorMessageState.message) ||
    (searchState.value === SearchStateValue.READY && searchState.error)
  ) {
    return (
      <h1 css={errorMessageCss}>
        {errorMessageState.message
          ? errorMessageState.message
          : searchState.error}
      </h1>
    );
  }

  if (
    !isCausesPage &&
    searchState.value === SearchStateValue.READY &&
    searchState.submittedSearchParams === null &&
    !searchState.prevSearchResults &&
    !(searchParams.query || searchParams.causes)
  ) {
    return (
      <section
        css={[
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: { minHeight: "500px" },
          }),
        ]}
      >
        <div
          css={css`
            width: 100%;
            height: 100%;
          `}
        >
          <div
            css={[
              verticalStackCss.l,
              css`
                max-width: ${MAX_TEXT_INPUT_WIDTH};
                margin: 0 auto;
                grid-row-start: 2;
                grid-row-end: 3;
                margin-top: ${spacing.xxl};
              `,
            ]}
          >
            <h2
              css={css`
                text-align: center;
              `}
            >
              Start searching nonprofits to support!
            </h2>
            <StyledSearchTextInput
              // this is only done on the empty search page, where it is the only
              // thing on the page, this seems not egregious
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocused
              data-tname="search"
              aria-label="Main search"
              disableScrollLock
              autocompleteSection={AutocompleteSection.NONPROFITS}
              showTrendingNonprofits
              focusCss={focusedSearchCss}
              escapeOverlayCss={css`
                background: transparent;
              `}
            />
          </div>
        </div>
      </section>
    );
  }

  const nonprofits = searchState.prevSearchResults?.results.nonprofits || [];
  const users = searchState.prevSearchResults?.results.users || [];
  const nonprofitSearchSources =
    searchState.prevSearchResults?.results.nonprofitSearchSources;
  const hasMore = searchState.prevSearchResults?.results.hasMore || false;
  const noResults = nonprofits.length + users.length === 0;
  const totalEstimatedMatchedNonprofits =
    searchState.prevSearchResults?.results.totalEstimatedMatchedNonprofits || 0;

  const highlightedCauses = splitCauses(
    searchState.prevSearchResults?.searchParams.causes
  );

  const preloadNonprofits = nonprofits.slice(0, 4).map((n) => n.nonprofit.id);

  const numColumns =
    query.viewport === "mobile" || query.viewport === "tablet"
      ? 1
      : { default: 2, 1100: 1 };

  return (
    <React.Fragment>
      <SearchInfoBox />
      <PageGrid>
        <SearchFilter
          customStyle={cssForMediaSize({
            max: MediaSize.MEDIUM,
            css: { display: "none" },
          })}
        />
        <div>
          {searchState.value === SearchStateValue.SEARCHING &&
          !searchState.prevSearchResults ? null : (
            <SearchHeaderContainer>
              <div
                css={[
                  horizontalStackCss.m,
                  { alignItems: "center", justifyContent: "space-between" },
                ]}
              >
                <h1>
                  <SearchHeader />
                </h1>
                {/* We still need to check this with the viewport since SearchFilterModalButton
            automatically opens a modal when navigating to /causes */}
                {!isLargeScreen && <SearchFilterModalButton hideText />}
              </div>
              <LoacationSuggestion />
            </SearchHeaderContainer>
          )}
          {searchState.prevSearchResults ? (
            noResults ? (
              <NoResults />
            ) : (
              <StyledPageSection>
                {searchState.value === SearchStateValue.SEARCHING ? (
                  <LoadingSkeleton numColumns={numColumns} />
                ) : (
                  <LoadImageWithPriorityProvider
                    initialValue={{
                      ids: preloadNonprofits,
                    }}
                  >
                    <ResultsList
                      nonprofits={nonprofits}
                      users={users}
                      nonprofitSearchSources={nonprofitSearchSources}
                      hasMore={hasMore}
                      highlightedCauses={highlightedCauses}
                      totalEstimatedMatchedNonprofits={
                        totalEstimatedMatchedNonprofits
                      }
                    />
                  </LoadImageWithPriorityProvider>
                )}
              </StyledPageSection>
            )
          ) : (
            <div
              css={css`
                display: flex;
                justify-content: center;
                padding: ${spacing.xxl} 0;
              `}
            >
              <LoadingIndicator />
            </div>
          )}
        </div>
      </PageGrid>
    </React.Fragment>
  );
};

const ResultsList: React.FCC<
  SearchAllResponseBody & { highlightedCauses?: string[] }
> = ({
  users,
  nonprofits,
  nonprofitSearchSources,
  hasMore,
  highlightedCauses,
}) => {
  const searchState = useContext(SearchContext);
  const searchParams = useSearchOptionsFromUrl();
  const { query } = useRouter();
  const numColumns =
    query.viewport === "mobile" || query.viewport === "tablet"
      ? 1
      : { default: 2, 1100: 1 };

  return (
    <div css={[verticalStackCss.xl, { alignItems: "center" }]}>
      <MasonryList
        numColumns={numColumns}
        items={[
          ...(users.length > 0
            ? [
                {
                  key: "users",
                  elem: (
                    <UserListCard
                      title="Matched Users"
                      usersInfo={users.slice(0, 8).map(({ user }) => ({
                        user,
                      }))}
                      showLocation
                    />
                  ),
                },
              ]
            : []),
          ...nonprofits.map(({ nonprofit }, i) => ({
            key: `nonprofit-${nonprofit.id}`,
            elem: (
              // TODO: remove div wrapper after #7489
              <div
                onClick={() => {
                  if (
                    nonprofitSearchSources !== undefined &&
                    nonprofitSearchSources[i]
                  ) {
                    trackEvent(CustomEvent.SEARCH_SUGGESTION_CLICKED, {
                      suggestionType: "Search page",
                      source: nonprofitSearchSources[i],
                    });
                  }
                }}
                aria-hidden="true"
              >
                <NonprofitCard
                  nonprofit={nonprofit}
                  highlightedCauses={highlightedCauses}
                  searchParams={searchParams}
                />
              </div>
            ),
          })),
        ]}
      />

      {hasMore && (
        <Button
          data-tname={"LoadMoreSearchResults--Button"}
          data-action={ClickAction.VIEW_MORE}
          role={ButtonRole.TEXT_ONLY}
          disabled={searchState.value === SearchStateValue.SEARCHING}
          submitting={searchState.value === SearchStateValue.SEARCHING}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => loadMoreAction(searchParams),
          }}
        >
          View more
        </Button>
      )}
    </div>
  );
};

const SearchResultsPage: NextPage = () => {
  return <SearchResultsPageImp />;
};

function SearchResultsPageImp() {
  const searchState = useContext(SearchContext);
  const router = useEdoRouter();
  const urlSearchOptions = useSearchOptionsFromUrl();
  const causeSlugs = splitCauses(urlSearchOptions.causes)?.sort();

  function generateNonprofitSearchDescriptor(maxLength?: number) {
    const MAX_TAGS_TO_SHOW = 2;
    const city = urlSearchOptions.address?.split(",")[0];
    const searchQuery = toTitleCase(urlSearchOptions.query);

    let descriptor = "";
    if (causeSlugs) {
      // Ideally this should be using tag Titles instead of the tagName slugs
      // but we don't want to wait to fetch the tag when setting title
      descriptor += `${joinEnglishWordSeries(
        causeSlugs?.map(toTitleCase)?.slice(0, MAX_TAGS_TO_SHOW)
      )} `;
    }
    descriptor += "nonprofits";
    if (
      city?.length &&
      !(maxLength && descriptor.length + city?.length > maxLength)
    ) {
      descriptor += ` in ${city}`;
    }

    if (
      searchQuery.length &&
      !(maxLength && descriptor.length + searchQuery.length > maxLength)
    ) {
      descriptor += ` for ${searchQuery}`;
    }

    return descriptor;
  }

  /**
   * Trying to optimize page title for SEO purposes with the following guidelines:
   * - Include the most relevent key words
   * - Aim for 30-60 characters
   * - Try to have the same title if the content is relatively the same (i.e. order of tags should not change title)
   * - Human readable
   *
   * TODO(#9786) Would be great to add in a number for how many nonprofits there are
   */
  function pageTitle() {
    const MAX_TITLE_LENGTH = 45; // 15 character buffer for things like "- Every.org", "Top "
    const nonprofitDesciptor =
      generateNonprofitSearchDescriptor(MAX_TITLE_LENGTH);

    return nonprofitDesciptor;
  }

  function pageDescription() {
    const nonprofitDesciptor = generateNonprofitSearchDescriptor();
    return `Discover top ${nonprofitDesciptor}. Donate with your bank, credit card, paypal, or cryptocurrency!`;
  }

  const causesString = joinCauses(causeSlugs);

  function pageUrl() {
    const { address, query } = urlSearchOptions;

    // Sort the tags so that we generate the same url for color=blue,red and color=red,blue
    const urlParams = filterObject(
      { address, query, causes: causesString },
      (k, v) => !!v?.length
    );

    const websiteOrigin = assertEnvPresent(
      process.env.REACT_APP_WEBSITE_ORIGIN ||
        process.env.NEXT_PUBLIC_WEBSITE_ORIGIN,
      "WEBSITE_ORIGIN"
    );
    const { pathname } = router;
    const searchParams = Object.entries(urlParams).length
      ? `?${new URLSearchParams(urlParams).toString()}`
      : null;
    return websiteOrigin + pathname + (searchParams ?? "");
  }

  function pageMetadata() {
    switch (router.pathname) {
      case clientRouteMetas[ClientRouteName.CAUSES].path:
        return {
          title: "Search For Causes and Nonprofits to Support",
          description:
            "Browse our full list of causes to identify the top nonprofits addressing issues such as education, climate change, food security, racial justice, and more.",
        };
      case clientRouteMetas[ClientRouteName.SEARCH_PAGE].path:
        return {
          title: "Discover Nonprofits to Support by Cause or Location",
          description:
            "Search our database of more than 1 million nonprofits to find organizations by location, size, or the causes you are most passionate about.",
        };
      default:
        return {
          title: pageTitle(),
          description: pageDescription(),
        };
    }
  }

  const pageMetadataValues = pageMetadata();

  return (
    <DefaultPageLayout
      pageTitle={pageMetadataValues.title}
      metas={{
        "og:description": pageMetadataValues.description,
        "og:url": pageUrl(),
        "og:image": constructCloudinaryUrl({
          cloudinaryId:
            causesString === BLACK_WOMEN_LEAD_TAG_NAMES.join(CAUSES_SEPARATOR)
              ? "profile_pics/etlqwhlaxwhts5wmgs9u"
              : DEFAULT_OPENGRAPH_CLOUDINARY_ID,
          dimensions: OPENGRAPH_DIMENSIONS,
        }),
      }}
      disableFooterMargin
      hideSearchbar={
        searchState.value === SearchStateValue.READY &&
        searchState.submittedSearchParams === null &&
        !searchState.prevSearchResults
      }
    >
      <SearchErrorMessageProvider>
        <SearchResults />
      </SearchErrorMessageProvider>
    </DefaultPageLayout>
  );
}

function LoacationSuggestion() {
  const searchState = useContext(SearchContext);
  const urlSearchOptions = useSearchOptionsFromUrl();

  const [predictedLocation, setPredictedLocation] = useState<Location | null>(
    null
  );

  useEffect(() => {
    async function predictLoaction() {
      if (
        // Only update predicted location if no location is already specified
        searchState.prevSearchResults?.searchParams.address ||
        !searchState.prevSearchResults?.searchParams.query
      ) {
        setPredictedLocation(null);
        return;
      }

      const location = await getLocationPrediction(
        searchState.prevSearchResults?.searchParams.query
      );

      setPredictedLocation(location);
    }
    predictLoaction();
  }, [
    searchState.prevSearchResults?.searchParams.address,
    searchState.prevSearchResults?.searchParams.query,
    urlSearchOptions,
  ]);

  if (!predictedLocation) {
    return null;
  }

  return (
    <div>
      Are you looking for nonprofits near{" "}
      <Button
        role={ButtonRole.TEXT_ONLY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            submitSearchAction({
              ...urlSearchOptions,
              ...predictedLocation,
              query: "",
            });
          },
        }}
        data-tname={""}
      >
        {predictedLocation.address}
      </Button>
      ?
    </div>
  );
}

export default SearchResultsPage;
