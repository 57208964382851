import { Loading } from "@components/LoadingIndicator";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { PageSection } from "@components/layout/PageSection";

export const LoadingPage = () => (
  <DefaultPageLayout>
    <PageSection>
      <Loading />
    </PageSection>
  </DefaultPageLayout>
);
