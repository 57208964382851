import { CauseCategory, ValidCauseCategory } from "../entity/types";
import { readonly, objectFromEnumValues } from "../helpers/objectUtilities";

// base colors in the app, not exposed directly - use the other palette objects
// to encourage consistency in how we use colors
export const colorPalette = readonly({
  // primary colors
  tealLogo: "#2BD7B0", // logo color, AA on dark background
  tealLogoDark: "#00a37f",
  teal: "#018669", // large text AA with white
  tealDark: "#276B5B", // small text AA with white

  // other highlight colors
  gold: "#F2C94C",
  darkGold: "#A2611E",

  blackWarmHex: "#2E3434",
  /* -----
   * gray palette
   *
   * uses HSL values to make lightness calculations easier
   */
  blackWarm: "hsl(180, 6%, 19%)", // #2E3434
  blackWarm20: "hsla(180, 6%, 19%, 20%)", // #2E3434 20%

  blackLight: "hsl(180, 6%, 26%)",
  grayXDark: "hsl(180, 6%, 30%)",

  grayDark: "hsl(0, 1%, 35%)",
  grayMedium: "#706E6E",
  grayMediumLight: "hsl(180, 1%, 81%)",
  grayLight: "hsl(180, 2%, 88%)",
  grayXLight: "hsl(180, 2%, 92%)",

  offWhite: "hsl(180, 14%, 96%)",
  white: "#FFFFFF",

  // category background colors produced by Moniker, named by
  // https://www.color-blindness.com/color-name-hue/
  // not accessible on white background
  tacaoOrange: "#F3B26E",
  persimmonOrange: "#C45330",
  portagePurple: "#988BD3",
  periwinkleBlue: "#B9CDE5",
  atlantisGreen: "#ABD24B",
  chantillyPink: "#EFAED3",
  turquoiseBlue: "#6FD9E0",
  caramelYellow: "#FFDA96",
  crustaOrange: "#F07752",
  emeraldGreen: "#55C987",
  jaggedIceGreen: "#CAE8E1",
  whiteIceGreen: "#DCF0EB",

  // other: error/success states, interactive elements
  red: "rgba(196, 83, 48, 1)",
  redTranslucent: "rgba(196, 83, 48, 0.2)",
  green: "rgba(0, 163, 127, 1)",
  greenTranslucent: "rgba(0, 163, 127, 0.2)",
  pink: "#EB008B",

  // social colors
  googleBlue: "#4285F4",

  // based on Every.org Design System
  grey: "#868A8A",
});

const causeCategoryPalette: {
  readonly [key in ValidCauseCategory]: {
    readonly extraDark: string;
    readonly dark: string;
    readonly medium: string;
    readonly light: string;
    readonly pastel40: string;
    readonly pastel20: string;
  };
} = {
  [CauseCategory.ANIMALS]: {
    extraDark: "#A2611E",
    dark: "#D49350",
    medium: "#DE9D5A",
    light: "#E8A764",
    pastel40: "#F6DCC1",
    pastel20: "#FAEDE0",
  },
  [CauseCategory.ARTS_CULTURE_HUMANITIES]: {
    extraDark: "#7063AB",
    dark: "#7A6DB5",
    medium: "#8E81C9",
    light: "#988BD3",
    pastel40: "#D6D1ED",
    pastel20: "#EAE8F6",
  },
  [CauseCategory.EDUCATION]: {
    extraDark: "#577598",
    dark: "#7593B6",
    medium: "#89A7CA",
    light: "#9DBBDE",
    pastel40: "#D8E4F2",
    pastel20: "#EBF1F8",
  },
  [CauseCategory.ENVIRONMENT]: {
    extraDark: "#5E7D10",
    dark: "#86A537",
    medium: "#9AB94B",
    light: "#A4C355",
    pastel40: "#DBE7BB",
    pastel20: "#EDF3DD",
  },
  [CauseCategory.HEALTH]: {
    extraDark: "#9E567F",
    dark: "#BC749D",
    medium: "#D088B1",
    light: "#DA92BB",
    pastel40: "#F0D3E4",
    pastel20: "#F8E9F1",
  },
  [CauseCategory.HUMAN_AND_CIVIL_RIGHTS]: {
    extraDark: "#397A7E",
    dark: "#57989C",
    medium: "#6BACB0",
    light: "#75B6BA",
    pastel40: "#C8E2E3",
    pastel20: "#E3F0F1",
  },
  [CauseCategory.HUMAN_SERVICES]: {
    extraDark: "#906515",
    dark: "#CCA151",
    medium: "#E0B565",
    light: "#EABF6F",
    pastel40: "#F7E5C5",
    pastel20: "#FBF2E2",
  },
  [CauseCategory.RELIGION]: {
    extraDark: "#BE5130",
    dark: "#D26544",
    medium: "#E67958",
    light: "#F08362",
    pastel40: "#F9CDC0",
    pastel20: "#FCE6E0",
  },
  [CauseCategory.RESEARCH_AND_PUBLIC_POLICY]: {
    extraDark: "#2F806D",
    dark: "#59A593",
    medium: "#61B29F",
    light: "#8AC8B9",
    pastel40: "#BBE9CF",
    pastel20: "#DDF4E7",
  },
};

export const DARK_THEME = readonly({
  text: readonly({
    body: colorPalette.white,
    secondary: colorPalette.grayMediumLight,
  }),
  accent: readonly({
    small: colorPalette.tealLogo,
    smallHighlight: colorPalette.teal,
    large: colorPalette.tealLogo,
    largeHighlight: colorPalette.teal,
  }),
  logo: colorPalette.tealLogo,
  emphasis: colorPalette.gold,
  background: readonly({
    normal: colorPalette.blackWarm,
    faded: colorPalette.grayDark,
  }),
  dividerSoft: colorPalette.grayDark,
  input: readonly({
    background: readonly({
      default: colorPalette.offWhite,
      hover: colorPalette.grayXLight,
      focus: colorPalette.white,
    }),
    border: readonly({
      error: colorPalette.red,
      focus: colorPalette.green,
    }),
    outline: readonly({
      error: colorPalette.redTranslucent,
      focus: colorPalette.greenTranslucent,
    }),
    caret: colorPalette.teal,
  }),
  causeCategory: readonly(
    objectFromEnumValues({
      enum: CauseCategory,
      mapFn: (c) =>
        c === CauseCategory.UNKNOWN
          ? {
              large: colorPalette.grayMedium,
              largeHighlight: colorPalette.grayMediumLight,
              small: colorPalette.grayMediumLight,
              smallHighlight: colorPalette.grayLight,
            }
          : {
              large: causeCategoryPalette[c].medium,
              largeHighlight: causeCategoryPalette[c].dark,
              small: causeCategoryPalette[c].light,
              smallHighlight: causeCategoryPalette[c].medium,
            },
    })
  ),
  googleButtonBorder: readonly({
    normal: "transparent",
    hover: "transparent",
  }),
  headerIcon: readonly({
    background: readonly({
      normal: colorPalette.blackLight,
      hover: colorPalette.grayXDark,
    }),
    text: readonly({
      normal: colorPalette.grayMediumLight,
      hover: colorPalette.grayMediumLight,
    }),
  }),
});

export type ColorTheme = typeof DARK_THEME;

export const LIGHT_THEME: ColorTheme = readonly({
  text: readonly({
    body: colorPalette.blackWarm,
    secondary: colorPalette.grayMedium,
  }),
  accent: readonly({
    small: colorPalette.tealDark,
    smallHighlight: colorPalette.teal,
    large: colorPalette.teal,
    largeHighlight: colorPalette.tealDark,
  }),
  logo: colorPalette.tealLogo,
  emphasis: colorPalette.darkGold,
  background: readonly({
    normal: colorPalette.white,
    faded: colorPalette.offWhite,
  }),
  dividerSoft: colorPalette.offWhite,
  input: readonly({
    background: readonly({
      default: colorPalette.offWhite,
      hover: colorPalette.grayXLight,
      focus: colorPalette.white,
    }),
    border: readonly({
      error: colorPalette.red,
      focus: colorPalette.green,
    }),
    outline: readonly({
      error: colorPalette.redTranslucent,
      focus: colorPalette.greenTranslucent,
    }),
    caret: colorPalette.teal,
  }),
  causeCategory: readonly(
    objectFromEnumValues({
      enum: CauseCategory,
      mapFn: (c) =>
        c === CauseCategory.UNKNOWN
          ? {
              large: colorPalette.grayMedium,
              largeHighlight: colorPalette.grayDark,
              small: colorPalette.grayDark,
              smallHighlight: colorPalette.grayXDark,
            }
          : {
              large: causeCategoryPalette[c].dark,
              largeHighlight: causeCategoryPalette[c].medium,
              small: causeCategoryPalette[c].extraDark,
              smallHighlight: causeCategoryPalette[c].dark,
            },
    })
  ),
  googleButtonBorder: readonly({
    normal: colorPalette.googleBlue,
    hover: colorPalette.blackWarm,
  }),
  headerIcon: readonly({
    background: readonly({
      normal: colorPalette.offWhite,
      hover: colorPalette.offWhite,
    }),
    text: readonly({
      normal: colorPalette.grayMedium,
      hover: colorPalette.blackWarm,
    }),
  }),
});

/**
 * Colors for background colors corresponding to each category; not accessible
 * for text
 */
export const CAUSE_PASTEL_BG_PALETTE: {
  readonly [key in CauseCategory]: { pastel40: string; pastel20: string };
} = objectFromEnumValues({
  enum: CauseCategory,
  mapFn: (c) =>
    c === CauseCategory.UNKNOWN
      ? {
          pastel40: colorPalette.jaggedIceGreen,
          pastel20: colorPalette.whiteIceGreen,
        }
      : {
          pastel40: causeCategoryPalette[c].pastel40,
          pastel20: causeCategoryPalette[c].pastel20,
        },
});
/**
 * Colors for background colors corresponding to each category; not accessible
 * for text
 */
export const CAUSE_ILLUSTRATION_BG_PALETTE: {
  readonly [key in CauseCategory]: string;
} = {
  [CauseCategory.ANIMALS]: colorPalette.tacaoOrange,
  [CauseCategory.ARTS_CULTURE_HUMANITIES]: colorPalette.portagePurple,
  [CauseCategory.EDUCATION]: colorPalette.periwinkleBlue,
  [CauseCategory.ENVIRONMENT]: colorPalette.atlantisGreen,
  [CauseCategory.HEALTH]: colorPalette.chantillyPink,
  [CauseCategory.HUMAN_AND_CIVIL_RIGHTS]: colorPalette.turquoiseBlue,
  [CauseCategory.HUMAN_SERVICES]: colorPalette.caramelYellow,
  [CauseCategory.RELIGION]: colorPalette.crustaOrange,
  [CauseCategory.RESEARCH_AND_PUBLIC_POLICY]: colorPalette.emeraldGreen,
  [CauseCategory.UNKNOWN]: colorPalette.jaggedIceGreen,
};

/**
 * Colors that don't change regardless of the color theme
 */
export const SHARED_PALETTE = readonly({
  logo: colorPalette.tealLogo,
  logoDark: colorPalette.teal,

  inputBorder: colorPalette.grey,
  popoverBorder: colorPalette.grayMediumLight,
  tableHighlight: colorPalette.jaggedIceGreen,

  // validation colors
  error: colorPalette.red,
  correct: colorPalette.green,

  // focus colors
  focus: colorPalette.red,
  focusComplementary: colorPalette.white,
  // misc
  notificationsIndicator: colorPalette.red,
  nonprofitCardBorder: colorPalette.blackWarm20,
  nonprofitProfileHeaderBackground: colorPalette.green,
});

/**
 * Colors used in emails
 */
export const EMAIL_PALETTE = readonly({
  background: colorPalette.offWhite,
  dividerSoft: colorPalette.grayXLight,
  accent: colorPalette.teal,
});

/**
 * Colors from external sources
 * NOTE: These are being used in emails, so they must be in HEX
 */
export const EXTERNAL_PALETTE = readonly({
  facebookBlue: "#4267b2",
  facebookBlueLight: "#5890ff",
  whatsappGreen: "#25D366",
  linkedinBlue: "#2867B2",
  redditOrange: "#FF4500",
  xBlack: "#0F141A",
  xBlackHover: "#000000",
});
