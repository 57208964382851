import { UUID } from "io-ts-types/UUID";
import React from "react";

import { FeedNonprofitRecResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";

import { NonprofitCard } from "src/components/feed/NewNonprofitCard";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { logger } from "src/utility/logger";

interface CauseFeedNonprofitCardProps {
  item: FeedNonprofitRecResponse;
  feedId?: UUID;
}

export const CauseFeedNonprofitCard: React.FCC<CauseFeedNonprofitCardProps> = ({
  item,
  feedId,
}) => {
  const nonprofit = nonprofitOrUndefined(
    useNonprofit({ id: item.nonprofitId })
  );

  if (!nonprofit) {
    logger.error({
      message: "unexpectedly loading for feed nonprofit card, bad local state",
    });
    return null;
  }

  return (
    <NonprofitCard
      data-feed-feedid={feedId}
      data-feed-itemid={nonprofit.id}
      data-feed-itemtype={FeedItemType.NONPROFIT_RECOMMENDATION}
      nonprofit={nonprofit}
    />
  );
};
