import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Head from "next/head";
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import ModalComponent from "react-modal";

import {
  PageStackingContext,
  LAYOUT_Z_INDEXES,
} from "src/context/PageStackingContext";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars, lightBgThemeCss } from "src/theme/color";
import { MODAL_BORDER_RADIUS } from "src/theme/common";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { logger } from "src/utility/logger";

export const DESKTOP_MODAL_MIN_WIDTH = "472px";

export const modalGradientCss = css`
  background: linear-gradient(
    180deg,
    var(${colorCssVars.background.normal}) 0%,
    var(${colorCssVars.background.faded}) 50.06%
  );
`;

const maxHeightSmallCss = css`
  min-width: 100%;
  height: 100%;
  max-height: 100vh;
  border-radius: 0;
  border: none;
`;
const maxHeightLargeCss = css`
  min-width: ${DESKTOP_MODAL_MIN_WIDTH};
  max-height: calc(100vh - 2 * ${spacing.l});
  border-radius: ${MODAL_BORDER_RADIUS};
`;
const maxHeightCss = css`
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: maxHeightLargeCss,
  })}
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: maxHeightSmallCss,
  })}
`;
/**
 * Time for animation to close modal in ms
 *
 * Required by react-modal:
 * https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md
 */
const closeAnimationTime = 400;
const closeAnimationTimeShare = 200;

const baseClassName = "everydotorgModal";
const modalClasses: ModalComponent.Classes = {
  base: baseClassName,
  afterOpen: `${baseClassName}--afterOpen`,
  beforeClose: `${baseClassName}--beforeClose`,
};
const modalClassesShare: ModalComponent.Classes = {
  base: `share-${modalClasses.base}`,
  afterOpen: `share-${modalClasses.afterOpen}`,
  beforeClose: `share-${modalClasses.beforeClose}`,
};

const baseOverlayClassName = `${baseClassName}--overlay`;
const noExitOverlayClassName = `${baseClassName}--overlay--noExit`;
const overlayClasses: ModalComponent.Classes = {
  base: baseOverlayClassName,
  afterOpen: `${baseOverlayClassName}--afterOpen`,
  beforeClose: `${baseOverlayClassName}--beforeClose`,
};

const overlayClassesShare: ModalComponent.Classes = {
  base: `share-${overlayClasses.base}`,
  afterOpen: `share-${overlayClasses.afterOpen}`,
  beforeClose: `share-${overlayClasses.beforeClose}`,
};

/**
 * Global styles needed to make the react modal sliding in animation
 */
export const modalGlobalCss = css`
  .${modalClasses.base} {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    outline: none;
    padding: 0;
    overflow: hidden;
    transition: transform ${closeAnimationTime}ms ease;
  }

  .${overlayClasses.base},
    .${overlayClassesShare.base},
    .${noExitOverlayClassName} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: ${LAYOUT_Z_INDEXES.modal};
    transition: opacity ${closeAnimationTime}ms ease;
  }

  .${overlayClasses.base}, .${noExitOverlayClassName} {
    padding: ${spacing.l};
  }

  .${overlayClasses.base} {
    /* cannot use opacity since overlay also contains contents */
    background-color: hsla(180, 6%, 19%, 70%);
  }
  .${noExitOverlayClassName} {
    ${modalGradientCss};
    .${modalClasses.base} {
      box-shadow: 0 24px 40px -16px rgba(46, 52, 52, 0.5);
      ${cssForMediaSize({
        min: MediaSize.MEDIUM,
        css: css`
          border-radius: ${MODAL_BORDER_RADIUS};
        `,
      })}
    }
  }

  ${cssForMediaSize({
    // animation styles
    min: MediaSize.MEDIUM,
    css: css`
      .${overlayClasses.base} {
        opacity: 0;
      }
      .${overlayClasses.afterOpen} {
        opacity: 1;
      }
      .${overlayClasses.beforeClose} {
        opacity: 0;
      }

      .${modalClasses.base} {
        transform: translate(-50%, calc(-50% + 200px)) scale(0.7);
      }

      .${modalClasses.afterOpen} {
        transform: translate(-50%, -50%) scale(1);
      }
    `,
  })};
  ${cssForMediaSize({
    // animation styles
    max: MediaSize.MEDIUM,
    css: css`
      .${overlayClasses.base} {
        background-color: transparent;
      }

      .${modalClasses.base} {
        width: 100%;
        height: 100%;
        transform: translate(-50%, 50%);
      }

      .${modalClasses.afterOpen} {
        transform: translate(-50%, -50%);
      }

      .${modalClasses.beforeClose} {
        transform: translate(-50%, 50%);
      }
    `,
  })};

  .${modalClassesShare.base} {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    outline: none;
    padding: 0;
    overflow: hidden;
    border-radius: 16px 16px 0px 0px;
    transition: transform ${closeAnimationTimeShare}ms ease-in-out;

    box-shadow: 0px 10px 20px 10px rgba(46, 52, 52, 0.2);
  }

  .${modalClassesShare.base} {
    transform: translateY(100%);
  }

  .${modalClassesShare.afterOpen} {
    transform: translateY(0);
  }

  .${modalClassesShare.beforeClose} {
    transform: translateY(100%);
  }
`;

const headerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: ${spacing.l} ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM_SMALL,
    css: css`
      padding: ${spacing.l} ${spacing.xl};
    `,
  })}

  position: sticky;
  top: 0;
  z-index: 1;
`;
const headerShadowCss = css`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
`;
const ModalHeaderText = styled.h4`
  ${truncatedTextCss({ numLines: 2 })}
  ${textSizeCss[TextSize.s]};
  font-weight: ${FontWeight.BOLD};
`;
const CloseModalButton = styled(Button)`
  svg {
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    fill: var(${colorCssVars.accent.smallHighlight});
  }
`;

export interface ModalProps extends ModalComponent.Props {
  showHeader?: boolean;
  headerText?: string | React.ReactNode;
  // if true, remove the close icon and disable the close action on esc or click outside;
  noExit?: boolean;
  /**
   * An element that is not enclosed within the modal contents padding - used
   * for decorative elements that want to span the entire modal (see examples
   * in the welcome and donation sign-up modals)
   */
  footerDecoration?: React.ReactNode;
  modalBodyCss?: CSSInterpolation;
  modalHeaderCss?: CSSInterpolation;
  isShareModal?: boolean;
}

export interface ModalElement extends React.HTMLProps<HTMLElement> {
  scrollToTop: () => void;
}

export const Modal = forwardRef<ModalElement, ModalProps>(function ModalWithRef(
  {
    children,
    showHeader,
    headerText,
    footerDecoration,
    onAfterOpen: customAfterOpen,
    onAfterClose: customAfterClose,
    onRequestClose,
    modalBodyCss,
    modalHeaderCss,
    noExit,
    shouldCloseOnEsc,
    shouldCloseOnOverlayClick,
    isShareModal = false,
    ...rest
  },
  ref
) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [scrolledDown, setScrolledDown] = useState(false);
  function onAfterOpen() {
    if (!contentRef.current) {
      logger.warn({
        message: "Expected ref to be present by modal open, but wasn't",
      });
      return;
    }
    disableBodyScroll(contentRef.current);
    customAfterOpen && customAfterOpen();
  }

  function onAfterClose() {
    clearAllBodyScrollLocks();
    customAfterClose && customAfterClose();
  }

  useImperativeHandle(ref, () => ({
    scrollToTop() {
      if (contentRef.current) {
        contentRef.current.scrollTo({ top: 0 });
      }
    },
  }));

  const specificModalProps = isShareModal
    ? {
        className: modalClassesShare,
        overlayClassName: overlayClassesShare,
        closeTimeoutMS: closeAnimationTimeShare,
      }
    : {
        className: modalClasses,
        overlayClassName: noExit
          ? { ...overlayClasses, base: noExitOverlayClassName }
          : overlayClasses,
        closeTimeoutMS: closeAnimationTime,
      };

  return (
    <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.modal}>
      {rest.isOpen && (
        // As of 2020-09, pages showing modals shouldn't be indexed by Google;
        // any new modal pages that also shouldn't be crawled, please add to
        // `robots.txt` to avoid Google from using crawling quota on these
        // pages, but this provides defense in depth for any routes we forget.
        // If we end up wanting some modal pages to be crawled, make sure you
        // test and ensure that Google considers the modal contents to be the
        // main page contents; we experienced issues with modal-based pages
        // being tagged erroneously by Google, likely because total page content
        // differed significantly from what is prominently visible by users
        <Head>
          <meta name="googlebot" content="noindex" key="googlenoindex" />
        </Head>
      )}
      <ModalComponent
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        onRequestClose={onRequestClose}
        shouldCloseOnEsc={noExit !== undefined ? !noExit : shouldCloseOnEsc}
        shouldCloseOnOverlayClick={
          noExit !== undefined ? !noExit : shouldCloseOnOverlayClick
        }
        {...specificModalProps}
        {...rest}
      >
        <div
          css={[
            lightBgThemeCss,
            modalBodyCss,
            css`
              overflow: hidden;
              display: flex;
              flex-direction: column;
            `,
            !isShareModal && maxHeightCss,
          ]}
        >
          {showHeader && (
            <header
              css={[
                lightBgThemeCss,
                headerCss,
                modalHeaderCss,
                scrolledDown && headerShadowCss,
              ]}
            >
              <ModalHeaderText>{headerText}</ModalHeaderText>
              {!noExit && (
                <CloseModalButton
                  aria-label="Close modal"
                  data-tname="Modal--CloseButton"
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: (e) => {
                      onRequestClose && onRequestClose(e);
                    },
                  }}
                  role={ButtonRole.UNSTYLED}
                >
                  <Icon
                    iconImport={() => import("@components/Icon/icons/XIcon")}
                    size={IconSize.MEDIUM}
                    display={IconDisplay.ACCENT}
                  />
                </CloseModalButton>
              )}
            </header>
          )}
          <div
            onScroll={(e) => {
              if (!contentRef.current) {
                return;
              }
              if (contentRef.current.scrollTop > 0) {
                !scrolledDown && setScrolledDown(true);
              } else {
                scrolledDown && setScrolledDown(false);
              }
            }}
            ref={contentRef}
            css={[
              css`
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                overflow: auto;
                // this is necessary to avoid the case
                // where the modal becomes blurred when the window is resized
                transform: translate3d(0, 0, 0);
              `,
              {
                padding: `${showHeader ? 0 : spacing.xl} ${spacing.xl}
              ${footerDecoration ? 0 : spacing.xl}`,
              },
              cssForMediaSize({
                max: MediaSize.SMALL,
                css: {
                  padding: `${showHeader ? 0 : spacing.l} ${spacing.l}
                ${footerDecoration ? 0 : spacing.l}`,
                },
              }),
            ]}
          >
            {children}
            {footerDecoration && (
              <div
                css={css`
                  margin: 0 -${spacing.xl};
                  display: flex;
                  flex-direction: column;
                  overflow: auto;
                `}
              >
                {footerDecoration}
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
    </PageStackingContext.Provider>
  );
});
