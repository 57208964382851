import {
  Button,
  ButtonProps,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { Big } from "big.js";
import React from "react";

import {
  Currency,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import { MAX_CRYPTO_DECIMALS_FOR_DISPLAY } from "@every.org/common/src/helpers/cryptoCurrency";

import { horizontalStackCss } from "src/theme/spacing";
import {
  displayCryptoValueInUserLocale,
  displayCurrencyValueInUserLocale,
} from "src/utility/currency";

function isCryptCurrency(
  currency: Currency | CryptoCurrency
): currency is CryptoCurrency {
  return currency in CryptoCurrency;
}

function getBackgroundColorOverride(paymentOption: DonationFlowPaymentOption) {
  switch (paymentOption) {
    case DonationFlowPaymentOption.PAYPAL:
      return "#FFBC33";
    case DonationFlowPaymentOption.VENMO:
      return "#3D95CE";
    case DonationFlowPaymentOption.PAYMENT_REQUEST:
      return "#000000";
    default:
      return undefined;
  }
}

export const DonateButton = ({
  paymentOption,
  creditsOnly,
  donateAction,
  totalAmountBig,
  currency,
  isDoubleDonation,
  isManualDaf,
  ...props
}: {
  paymentOption: DonationFlowPaymentOption;
  creditsOnly: boolean;
  donateAction: DonateModalAction;
  totalAmountBig: Big;
  currency: Currency | CryptoCurrency;
  isDoubleDonation?: boolean;
  isManualDaf?: boolean;
} & Pick<ButtonProps, "data-tname" | "submitting" | "disabled">) => {
  const backgroundColorOverride = creditsOnly
    ? undefined
    : getBackgroundColorOverride(paymentOption);

  const amount = isCryptCurrency(currency)
    ? displayCryptoValueInUserLocale({
        cryptoCurrency: currency,
        amount: totalAmountBig,
        maximumSignificantDigits: MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
      })
    : displayCurrencyValueInUserLocale({
        currencyValue: {
          currency,
          amount: totalAmountBig,
        },
      });

  return (
    <Button
      role={ButtonRole.PRIMARY}
      onClick={{ kind: ButtonTargetKind.SUBMIT }}
      css={[
        backgroundColorOverride && {
          backgroundColor: backgroundColorOverride,
        },
        { width: "100%" },
      ]}
      contentCss={css`
        ${horizontalStackCss.xs};
        justify-content: center;
        align-items: center;
      `}
      {...props}
    >
      {paymentOption === DonationFlowPaymentOption.CRYPTO ? (
        <span>Get donation address</span>
      ) : (paymentOption === DonationFlowPaymentOption.DAF && isManualDaf) ||
        paymentOption === DonationFlowPaymentOption.STOCKS ? (
        "Get instructions"
      ) : donateAction === DonateModalAction.UPDATE ? (
        <span>Update donation</span>
      ) : (
        <React.Fragment>
          <span>
            {isDoubleDonation ? "Yes, d" : "D"}onate {amount}
            {creditsOnly && " with credits"}
            {isDoubleDonation && " AGAIN"}
          </span>
        </React.Fragment>
      )}
    </Button>
  );
};
