/**
 * Components used during the sign up flow.
 */
import { ErrorMessage } from "@components/ErrorMessage";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Description } from "@components/Nonprofit/EditForm/shared";
import { Popover } from "@components/Popover";
import { PopoverInfoButton } from "@components/PopoverInfoButton";
import { SelectProfileVisibility } from "@components/Signup/SelectProfileVisibility";
import { TextInput, TextInputType } from "@components/TextInput";
import { css } from "@emotion/react";
import dynamic from "next/dynamic";
import React, { useState } from "react";

import { Location } from "@every.org/common/src/codecs/location";
import { pickKeys } from "@every.org/common/src/helpers/objectUtilities";
import { cleanSocialNetworkHandle } from "@every.org/common/src/helpers/socialNetworks";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import {
  UserProfileFormValues,
  UserProfileFormErrors,
} from "src/utility/signup/UserProfileTypes";
import { socialLinksMeta } from "src/utility/socialNetworks";
import { LATIN_NAME_REGEX } from "src/utility/userValidators";

const CitySearchInput = dynamic(() => import("./CitySearchInput"));
interface EditProfileFieldsProps {
  values: UserProfileFormValues;
  errors: UserProfileFormErrors;
  showEmail?: boolean;
  showSocials?: boolean;
  emailLabel?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setValues: (
    values: Partial<UserProfileFormValues>,
    formValueKey?: keyof UserProfileFormValues
  ) => void;
  clearServerErrors: () => void;
}

export const fullNameSectionCss = [
  cssForMediaSize({
    max: MediaSize.MEDIUM_SMALL,
    css: verticalStackCss.m,
  }),
  cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      ${horizontalStackCss.l};
      flex-wrap: nowrap;
      > * {
        flex-grow: 1;
      }
    `,
  }),
];

const profileFormCss = [
  verticalStackCss.m,
  cssForMediaSize({ min: MediaSize.MEDIUM, css: verticalStackCss.xxl }),
];

function CitySearchSection({
  setValues,
  clearServerErrors,
  errors,
  values,
  handleBlur,
}: EditProfileFieldsProps) {
  const onLocationChanged = (location?: Location) => {
    setValues(
      {
        location,
        ...(location ? { userInputLocation: location.address } : undefined),
      },
      "location"
    );
  };

  return (
    <CitySearchInput
      name="location"
      data-tname="location"
      labelText="Location"
      value={values.userInputLocation || ""}
      handleTextChange={(text: string) => {
        clearServerErrors();
        setValues({ userInputLocation: text }, "userInputLocation");
      }}
      onLocationChanged={onLocationChanged}
      validationStatus={
        errors.location
          ? { success: false, message: errors.location }
          : undefined
      }
      onBlur={handleBlur}
      collapseDescriptionSpace
    />
  );
}

export function FullNameSection({
  values,
  handleChange,
  handleBlur,
  className,
}: Pick<EditProfileFieldsProps, "values" | "handleChange" | "handleBlur"> & {
  className?: string;
}) {
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setIsValid: (value: boolean) => void
  ) => {
    setIsValid(true);
    if (!LATIN_NAME_REGEX.test(event.target.value)) {
      setIsValid(false);
    }
    handleChange(event);
  };

  return (
    <section className={className} css={verticalStackCss.s}>
      <div css={fullNameSectionCss}>
        <TextInput
          type={TextInputType.TEXT}
          id="firstName"
          data-tname="firstName"
          name="firstName"
          labelText="First name"
          autoComplete="given-name"
          value={values.firstName || ""}
          onChange={(event) => onChange(event, setIsFirstNameValid)}
          onBlur={handleBlur}
          collapseDescriptionSpace
          required
          pattern={LATIN_NAME_REGEX.source}
          onInvalid={() => setIsFirstNameValid(false)}
        />
        <TextInput
          type={TextInputType.TEXT}
          id="lastName"
          data-tname="lastName"
          name="lastName"
          labelText="Last name"
          autoComplete="family-name"
          value={values.lastName || ""}
          onChange={(event) => onChange(event, setIsLastNameValid)}
          onBlur={handleBlur}
          collapseDescriptionSpace
          required
          pattern={LATIN_NAME_REGEX.source}
          onInvalid={() => setIsLastNameValid(false)}
        />
      </div>
      {(!isFirstNameValid || !isLastNameValid) && (
        <ErrorMessage>
          {!values.firstName
            ? "First name is required."
            : !values.lastName
            ? "Last name is required."
            : "Only latin letters may be used."}
        </ErrorMessage>
      )}
    </section>
  );
}

function EmailInput({
  emailLabel,
  values,
  handleChange,
  errors,
}: Pick<
  EditProfileFieldsProps,
  "emailLabel" | "values" | "handleChange" | "errors"
>) {
  return (
    <TextInput
      type={TextInputType.EMAIL}
      labelText={emailLabel || "Confirm email"}
      name="email"
      id="email"
      data-tname="email"
      autoComplete="email"
      value={values.email || ""}
      onChange={handleChange}
      validationStatus={
        errors.email ? { success: false, message: errors.email } : undefined
      }
      required
    />
  );
}

/**
 * Group of input fields that allow a user to edit their basic profile
 * information, such as name, email, and location.
 *
 * Used in sign up's /buildProfile flow and in Settings.
 */
export function EditProfileFields(props: EditProfileFieldsProps) {
  const { values, errors, showEmail, showSocials, handleChange } = props;

  return (
    <div css={profileFormCss}>
      {showEmail && (
        <EmailInput
          {...pickKeys(props, [
            "emailLabel",
            "values",
            "errors",
            "handleChange",
          ] as const)}
        />
      )}
      <FullNameSection
        css={{ paddingBottom: spacing.xl }}
        {...pickKeys(props, ["handleChange", "handleBlur", "values"] as const)}
      />
      <TextInput
        type={TextInputType.TEXT}
        id="username"
        data-tname="username"
        name="username"
        labelText="Username"
        inputPrefix="every.org/@"
        value={values.username || ""}
        onChange={(e) => {
          e.currentTarget.value = e.currentTarget.value.toLowerCase().trim();
          handleChange(e);
        }}
        validationStatus={
          // uncomment this when we're ready to show success validation statuses
          // for other inputs
          // errors.username === VALID
          //  ? { success: true }
          //  :
          typeof errors.username === "string"
            ? { success: false, message: errors.username }
            : undefined
        }
        spellCheck={false}
        autoComplete="off"
        autoCapitalize="none"
        required
        collapseDescriptionSpace
      />
      <TextInput
        type={TextInputType.TEXT}
        id="legalName"
        data-tname="legalName"
        name="legalName"
        labelText={
          <span css={horizontalStackCss.xxs}>
            <span>Legal name</span>
            <PopoverInfoButton text="If your government issued legal name to show on tax deductible receipts is different from your public display name, specify your legal name here." />
          </span>
        }
        placeholder={
          values.legalName || values.firstName + " " + values.lastName
        }
        autoComplete="name"
        value={values.legalName || ""}
        onChange={handleChange}
        collapseDescriptionSpace
      />
      <CitySearchSection {...props} />

      {showSocials && (
        <div css={verticalStackCss.xxs}>
          <h4>Social media</h4>
          <Description>
            Paste links to your social accounts and they’ll appear on your
            profile page.
          </Description>
          <div css={verticalStackCss.s}>
            {socialLinksMeta.map(
              ({ key, placeholder, iconImport, popoverText }) => {
                const textInput = (
                  <TextInput
                    name={key}
                    data-tname={`${key}Handle`}
                    key={key}
                    inputPrefix={
                      <div css={horizontalStackCss.s}>
                        <Icon
                          css={{ marginRight: spacing.s }}
                          iconImport={iconImport}
                          size={IconSize.MEDIUM}
                          display={IconDisplay.ACCENT}
                        />
                        {placeholder}
                      </div>
                    }
                    value={
                      cleanSocialNetworkHandle(values[key] || "", key) ||
                      undefined
                    }
                    collapseDescriptionSpace
                    spellCheck={false}
                    autoComplete="off"
                    onChange={(e) => {
                      e.currentTarget.value = e.currentTarget.value.trim();
                      handleChange(e);
                    }}
                  />
                );
                if (popoverText) {
                  return (
                    <Popover
                      placement={"bottom"}
                      content={
                        <div css={{ maxWidth: "320px" }}>{popoverText}</div>
                      }
                    >
                      {textInput}
                    </Popover>
                  );
                }
                return textInput;
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function CondensedBuildProfileForm(props: EditProfileFieldsProps) {
  return (
    <div css={profileFormCss}>
      <FullNameSection
        css={{ paddingBottom: spacing.xl }}
        {...pickKeys(props, ["handleChange", "handleBlur", "values"] as const)}
      />
      {props.showEmail && (
        <EmailInput
          {...pickKeys(props, [
            "emailLabel",
            "values",
            "errors",
            "handleChange",
          ] as const)}
        />
      )}
      <SelectProfileVisibility
        isPrivate={props.values.isPrivate}
        setPrivate={(isPrivate) => props.setValues({ isPrivate }, "isPrivate")}
        publicDonationAmount={props.values.publicDonationAmount}
        setPublicDonationAmount={(publicDonationAmount) =>
          props.setValues({ publicDonationAmount }, "publicDonationAmount")
        }
      />
    </div>
  );
}
