import { Icon, IconDisplay, IconSize } from "@components/Icon";
import Popover from "@components/Popover/component";
import Thermometer, { ThermometerProps } from "@components/Thermometer";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { forwardRef } from "react";

import { colorCssVars } from "src/theme/color";
import {
  verticalStackCss,
  horizontalStackCss,
  spacing,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

// TODO #8478: parametrize currency
export const LabeledThermometer = forwardRef<HTMLDivElement, ThermometerProps>(
  function LabeledTermometerImpl({ labelPosition = "bottom", ...props }, ref) {
    const {
      className,
      currency,
      goalLabel = "Goal",
      progressLabel = "Amount raised",
    } = props;

    const monthlyAnnualTotal =
      !props.dataMissing && typeof props.monthly?.times === "function"
        ? { currency, amount: new Big(props.monthly.times(12).toFixed(0)) }
        : undefined;

    const totalAmount = props.dataMissing
      ? new Big(0)
      : new Big(props.currentAmount).add(props.raisedOffline ?? 0);

    const raisedOffline =
      !props.dataMissing && props.raisedOffline
        ? { currency, amount: new Big(props.raisedOffline.toFixed(0)) }
        : undefined;

    const raisedMatches =
      !props.dataMissing && props.raisedMatches
        ? { currency, amount: new Big(props.raisedMatches.toFixed(0)) }
        : undefined;

    const raisedOutsideTotal = !props.dataMissing
      ? new Big(props?.raisedOffline?.toFixed(0) ?? 0).add(
          props?.raisedMatches?.toFixed(0) ?? 0
        )
      : undefined;

    // If we aren't showing the recurring amount, then we split the thermometer
    // totals and labels around the bar itself.
    const showRecurringAmount = !!(
      monthlyAnnualTotal && monthlyAnnualTotal.amount.gt(0)
    );

    const RecurringLabel = () => {
      if (showRecurringAmount && monthlyAnnualTotal) {
        return (
          <section>
            <div
              css={[
                textSizeCss.xs,
                horizontalStackCss.xxs,
                css`
                  color: var(${colorCssVars.text.secondary});
                  align-items: center;
                `,
              ]}
            >
              <p>
                <span
                  css={css`
                    ${textSizeCss.s}
                    font-weight: ${FontWeight.BOLD};
                    color: var(${colorCssVars.text.body});
                  `}
                >
                  {displayCurrencyValueInUserLocale({
                    currencyValue: monthlyAnnualTotal,
                  })}
                </span>{" "}
                Annual recurring total
              </p>
              <Popover
                placement={"bottom"}
                css={css`
                  cursor: pointer;
                `}
                contentCss={css`
                  max-width: 300px;
                `}
                content={
                  "Total funds that current monthly donations would raise in the next 12 months, helping nonprofits operate sustainably and plan ahead."
                }
              >
                <Icon
                  iconImport={() => import("@components/Icon/icons/InfoIcon")}
                  display={IconDisplay.SECONDARY}
                  size={IconSize.X_SMALL}
                />
              </Popover>
            </div>
          </section>
        );
      }
      return null;
    };

    return (
      <div ref={ref} className={className} css={verticalStackCss.xs}>
        {labelPosition === "top" && <Thermometer {...props} />}
        <DefinitionList>
          <div>
            <dt>{progressLabel}</dt>
            <dd css={[horizontalStackCss.xxs, { alignItems: "center" }]}>
              <div>
                {props.dataMissing
                  ? "-"
                  : displayCurrencyValueInUserLocale({
                      currencyValue: {
                        currency,
                        amount: new Big(totalAmount.toFixed(0)),
                      },
                    })}
              </div>
            </dd>
          </div>
          <div css={{ textAlign: "right" }}>
            <dt>{goalLabel}</dt>
            <dd>
              {props.dataMissing
                ? "-"
                : displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency,
                      amount: new Big(props.goalAmount.toFixed(0)),
                    },
                  })}
            </dd>
          </div>
        </DefinitionList>
        {labelPosition === "bottom" && <Thermometer {...props} />}
        <RecurringLabel />
        {raisedOutsideTotal?.gt(0) && (
          <div
            css={css`
              text-align: center;
              padding-top: ${spacing.xs};
            `}
          >
            Includes{" "}
            {displayCurrencyValueInUserLocale({
              currencyValue: {
                currency,
                amount: raisedOutsideTotal,
              },
            })}{" "}
            in {raisedMatches?.amount.gt(0) && "matching funds"}
            {raisedMatches?.amount.gt(0) &&
              raisedOffline?.amount.gt(0) &&
              " and "}
            {raisedOffline?.amount.gt(0) && "offline donations"}
          </div>
        )}
      </div>
    );
  }
);

const DefinitionList = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column-reverse;
  }
  dt {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss.s};
  }
  dd {
    color: var(${colorCssVars.text.body});
    font-weight: ${FontWeight.BOLD};
    ${textSizeCss.s};
  }
`;
