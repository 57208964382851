import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";

import { stringEnumValues } from "@every.org/common/src/codecs/index";
import { AtLeastOneKeyPresent } from "@every.org/common/src/helpers/types";

import { getWindow } from "src/utility/window";

/**
 * Screen size breakpoints in our application
 *
 * If you change this, make sure to update the MEDIA_SIZE_ORDER field below
 */
export enum MediaSize {
  X_SMALL = "X_SMALL",
  SMALL = "SMALL",
  MEDIUM_SMALL = "SMALL_MEDIUM",
  MEDIUM = "MEDIUM",
  MEDIUM_LARGE = "MEDIUM_LARGE",
  LARGE = "LARGE",
  X_LARGE = "X_LARGE",
  XX_LARGE = "XX_LARGE",
}

/**
 * Relative order of sizing for breakpoints, from smallest to largest
 *
 * Useful for media size comparator methods; must be kept in sync with the enum
 * above if it changes
 */
const MEDIA_SIZE_ORDER_ASC = [
  MediaSize.X_SMALL,
  MediaSize.SMALL,
  MediaSize.MEDIUM_SMALL,
  MediaSize.MEDIUM,
  MediaSize.MEDIUM_LARGE,
  MediaSize.LARGE,
  MediaSize.X_LARGE,
  MediaSize.XX_LARGE,
];
const MEDIA_SIZE_ORDER_DESC = MEDIA_SIZE_ORDER_ASC.slice(0).reverse();

/**
 * Pixel sizes corresponding to the minimum size for each breakpoint, expressed
 * as number of pixels
 */
export const minWidthForMediaSize: {
  [key in Exclude<MediaSize, MediaSize.X_SMALL>]: number;
} = {
  /**
   * Min size for a small-screened device, like a normal sized smartphone.
   */
  [MediaSize.SMALL]: 350,
  /**
   * Min size for a medium-small device, like a small tablet or window.
   */
  [MediaSize.MEDIUM_SMALL]: 450,
  /**
   * Min size for a mid-sized device, like a portrait tablet.
   */
  [MediaSize.MEDIUM]: 600,
  /**
   * Min size for a slightly smaller than large devices, like a portrait tablet.
   */
  [MediaSize.MEDIUM_LARGE]: 744,
  /**
   * Min size for a large device, like a desktop browser.
   */
  [MediaSize.LARGE]: 800,
  /**
   * Min size for a slightly bigger than large devices, like a full screen desktop browser.
   */
  [MediaSize.X_LARGE]: 900,
  /**
   * Min size for a very large device, like a full screen desktop browser /
   * large monitor.
   */
  [MediaSize.XX_LARGE]: 1100,
};

/**
 * Min and max sizes for each media size
 */
const mediaSizeBounds = Object.fromEntries(
  stringEnumValues({ enumObject: MediaSize }).map((size) => [
    size,
    {
      min:
        size === MediaSize.X_SMALL
          ? undefined
          : `${minWidthForMediaSize[size]}px`,
      max:
        size === MediaSize.XX_LARGE
          ? undefined
          : `${
              minWidthForMediaSize[
                MEDIA_SIZE_ORDER_ASC[MEDIA_SIZE_ORDER_ASC.indexOf(size) + 1]
              ]
            }px`,
    },
  ])
) as { [key in MediaSize]: AtLeastOneKeyPresent<{ min: string; max: string }> };

function getMediaSizeForInnerWidth(innerWidth: number) {
  return (
    MEDIA_SIZE_ORDER_DESC.find((s) => innerWidth >= minWidthForMediaSize[s]) ||
    MediaSize.X_SMALL
  );
}

/**
 * Hook that returns current media size, or null if not possible (headless
 * browser for example)
 *
 * This hook will not get triggered on window height changes or if the media
 * size doesn't change.
 *
 * This was necessary to make sure that on Android phones, the soft keyboard
 * will trigger a window resize, which was leading to problems for the Stripe
 * element within a modal that uses this.
 */
export function useMediaSize(): MediaSize | null {
  const [mediaSize, setMediaSize] = useState<MediaSize | null>(null);

  useEffect(() => {
    const window = getWindow();
    if (!window) {
      return undefined;
    }
    function handleResize() {
      if (!window) {
        return;
      }
      const newMediaSize = getMediaSizeForInnerWidth(window.innerWidth);
      setMediaSize(newMediaSize);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return mediaSize;
}

/**
 * Specifies screen sizes that code should be applicable to
 */
export type MediaQueryConstraint = AtLeastOneKeyPresent<{
  /**
   * Min screen size that should match a given media query, inclusive
   *
   * Excludes MediaSize.X_SMALL since all sizes are larger than that
   */
  min: Exclude<MediaSize, MediaSize.X_SMALL>;
  /**
   * Max screen size that should match a given media query, inclusive
   *
   * Excludes MediaSize.XX_LARGE since all sizes are larger than that
   */
  max: Exclude<MediaSize, MediaSize.XX_LARGE>; // all sizes less than x_large
}>;

/**
 * Returns true or false if screen matches min/max size constraint
 *
 * @returns whether or not the value matched, or null if size not yet determined
 */
export function useMatchesScreenSize(
  constraints: MediaQueryConstraint
): boolean | null {
  // eslint-disable-next-line no-restricted-syntax
  const currentSize = useMediaSize();
  if (currentSize === null) {
    return null;
  }
  if (
    constraints.min &&
    _mediaSizeMin({ toCompare: [currentSize, constraints.min] }) !==
      constraints.min
  ) {
    return false;
  }
  if (
    constraints.max &&
    _mediaSizeMax({ toCompare: [currentSize, constraints.max] }) !==
      constraints.max
  ) {
    return false;
  }
  return true;
}

/**
 * Find the smallest media size in the list
 *
 * @private
 */
export function _mediaSizeMin(props: {
  toCompare: [MediaSize, MediaSize, ...MediaSize[]];
}): MediaSize {
  return props.toCompare
    .slice(0) // copy to avoid mutation
    .sort(
      (a, b) =>
        MEDIA_SIZE_ORDER_ASC.indexOf(a) - MEDIA_SIZE_ORDER_ASC.indexOf(b)
    )[0];
}

/**
 * Find the largest media size in the list
 *
 * @private
 */
export function _mediaSizeMax(props: {
  toCompare: [MediaSize, MediaSize, ...MediaSize[]];
}): MediaSize {
  return props.toCompare
    .slice(0) // copy to avoid mutation
    .sort(
      (a, b) =>
        MEDIA_SIZE_ORDER_ASC.indexOf(b) - MEDIA_SIZE_ORDER_ASC.indexOf(a)
    )[0];
}

/**
 * Scope a style to a min or max media size
 *
 * @params props.min Minimum screen size that matches the query, inclusive
 * @params props.max Maximum screen size that matches the query, inclusive
 */
export function cssForMediaSize(
  props: {
    css: CSSInterpolation | CSSInterpolation[];
  } & MediaQueryConstraint
): CSSInterpolation {
  const minBound = props.min
    ? `(min-width: ${mediaSizeBounds[props.min].min})`
    : undefined;
  const maxBound = props.max
    ? `(max-width: ${mediaSizeBounds[props.max].max})`
    : undefined;
  const mediaQuery = [minBound, maxBound]
    .filter((s): s is string => !!s)
    .join(" and ");
  return css`
    @media ${mediaQuery} {
      ${props.css};
    }
  `;
}
