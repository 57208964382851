import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { stringEnumCodec } from "../codecs";
import { currencyValueCodec } from "../codecs/currency";
import {
  donationChargeResponseCodec,
  nonprofitResponseCodec,
  personalDonationChargeResponseCodec,
  userResponseCodec,
  donationBoostResponseCodec,
  donationResponseCodec,
  tagResponseCodec,
} from "../codecs/entities";
import { nonNegativeIntegerFromStringCodec } from "../codecs/number";
import { usernameCodec } from "../codecs/username";
import { ShareMedium } from "../entity/types";
import { HttpMethod } from "../helpers/http";

import { DonationMatchStatus } from "./donate";

import { makeRouteSpec } from ".";

export const likeDonationRouteSpec = makeRouteSpec({
  path: "/donation/:id/like",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const unlikeDonationRouteSpec = makeRouteSpec({
  path: "/donation/:id/like",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const valueRaisedCodec = t.type({
  // values null if few matching donors, would reveal identity
  monthly: t.union([currencyValueCodec, t.nullType]),
  total: currencyValueCodec,
});
export type ValueRaisedResponse = t.TypeOf<typeof valueRaisedCodec>;
const getDonationResponseBodyCodec = t.intersection([
  t.type({
    donationCharge: t.union([
      personalDonationChargeResponseCodec,
      donationChargeResponseCodec,
    ]),
    nonprofit: nonprofitResponseCodec,
    user: userResponseCodec,
    boost: donationBoostResponseCodec,
    nonprofitSupporterCount: t.number,
    valueRaised: t.union([valueRaisedCodec, t.nullType]), // only shown if enough boosts exist for privacy
  }),
  t.partial({ nonprofitTags: t.array(tagResponseCodec) }),
]);
export const getDonationRouteSpec = makeRouteSpec({
  path: "/donation/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getDonationResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export const getDonationByShortIdRouteSpec = makeRouteSpec({
  // TODO: should it start with `/donation/...` or with `/users/...` ?
  path: "/donation/:username/:nonprofitSlug/:shortId",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({
    username: usernameCodec,
    nonprofitSlug: t.string,
    shortId: nonNegativeIntegerFromStringCodec,
  }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getDonationResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getDonationBoostsResponseBodyCodec = t.type({
  // These numbers may be higher than the number of donations / users returned
  // depending on the booster's privacy settings
  totalBoosts: t.number,
  totalBoostingUsers: t.number,
  donations: t.array(donationResponseCodec),
  users: t.array(userResponseCodec),
  valueRaised: t.union([valueRaisedCodec, t.nullType]), // only shown if enough boosts exist for privacy
});
export const getDonationBoostsRouteSpec = makeRouteSpec({
  path: "/donation/:id/boosts",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getDonationBoostsResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export const trackShareRouteSpec = makeRouteSpec({
  path: "/donation/:id/trackShare",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    shareMedium: stringEnumCodec({
      name: "ShareMedium",
      enumObject: ShareMedium,
    }),
  }),
  responseBodyCodec: t.partial({
    matching: stringEnumCodec({
      name: "DonationMatchStatus",
      enumObject: DonationMatchStatus,
    }),
  }),
});

const chariotSuccessWebhookBodyCodec = t.partial({
  grantId: t.string,
  workflowSessionId: t.string,
  amount: t.number,
  userFriendlyId: t.string,
  trackingId: t.string,
  fundId: t.string,
  externalGrantId: t.string,
  fee: t.number,
  coveredFees: t.boolean,
  status: t.string,
});
export type ChariotSuccessBody = t.TypeOf<
  typeof chariotSuccessWebhookBodyCodec
>;
export const chariotSuccessfulFrontendRouteSpec = makeRouteSpec({
  path: "/donation/:id/chariotSuccess",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: chariotSuccessWebhookBodyCodec,
  responseBodyCodec: t.type({}),
});
