import { PersonalDonationChargeResponse } from "@every.org/common/src/codecs/entities";

export enum DonationActionType {
  ADD_NEW_DONATION = "ADD_NEW_DONATION",
  UPDATE_DONATION = "UPDATE_DONATION",
}

/**
 * Action used when adding a new donation that was just created, used to quickly
 * give feedback to the user that the donation was processed.
 */
interface AddNewDonationAction {
  type: DonationActionType.ADD_NEW_DONATION;
  data: PersonalDonationChargeResponse;
}

interface UpdateDonationAction {
  type: DonationActionType.UPDATE_DONATION;
  data: PersonalDonationChargeResponse;
}

export type PersonalDonationsAction =
  | AddNewDonationAction
  | UpdateDonationAction;
