import { Big } from "big.js";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { UUID } from "io-ts-types/UUID";
import { useCallback, useState } from "react";

import {
  FundraiserResponse,
  PaymentSourceResponse as PaymentSource,
  PersonalGivingCreditResponse,
} from "@every.org/common/src/codecs/entities";
import { decodeOrUndefined } from "@every.org/common/src/codecs/index";
import { coerceToSafeIntOrThrow } from "@every.org/common/src/codecs/number";
import { isValidUrl } from "@every.org/common/src/codecs/urls";
import {
  Currency,
  PaymentMethod,
  PaymentSourceType,
} from "@every.org/common/src/entity/types";
import {
  UTM_QUERY_PARAM,
  UTM_QUERY_PARAM_TO_VAR_NAME,
} from "@every.org/common/src/helpers/analytics";
import { generateNonce } from "@every.org/common/src/helpers/auth";
import {
  DONATE_HASH,
  DonateModalUrlParams,
  LEGACY_DONATE_CRYPTO_HASH,
} from "@every.org/common/src/helpers/clientRoutes";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import {
  GetPaymentSourcesResponse,
  getPaymentSourcesRouteSpec,
} from "@every.org/common/src/routes/donate";

import { getLandingPageFromCookie } from "src/context/AuthContext/actions";
import { fetchAvailableGivingCredit } from "src/context/DonationsContext/actions";
import { useUser } from "src/context/UsersContext/hooks";
import { FETCHING_USER, USER_NOT_FOUND } from "src/context/UsersContext/types";
import { getMessageForError } from "src/errors";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

export function useAvailableGivingCredit({
  isLoggedIn,
  isInternational,
  isGiftCardPurchase,
  isFundAccount,
  restrictedByNonprofitId,
  paymentMethod,
  givingCreditToRedeem,
}: {
  isLoggedIn?: boolean;
  isInternational?: boolean;
  isGiftCardPurchase?: boolean;
  isFundAccount?: boolean;
  restrictedByNonprofitId?: string;
  paymentMethod?: PaymentMethod;
  givingCreditToRedeem?: PersonalGivingCreditResponse;
}) {
  const [availableGivingCredit, setAvailableGivingCredit] = useState<Big>();
  const [inaccessibleGivingCredit, setInaccessibleGivingCredit] =
    useState<Big>();

  const fetchGivingCredit = useCallback(async () => {
    if (!isLoggedIn) {
      return new Big(0);
    }
    return (await fetchAvailableGivingCredit({ restrictedByNonprofitId }))
      .amount;
  }, [isLoggedIn, restrictedByNonprofitId]);
  const isPaypalOrDaf =
    paymentMethod &&
    [PaymentMethod.PAYPAL, PaymentMethod.DAF].includes(paymentMethod);
  const handleGivingCredit = useCallback(
    (givingCredit: Big) => {
      if (
        isInternational ||
        isGiftCardPurchase ||
        isPaypalOrDaf ||
        isFundAccount
      ) {
        setAvailableGivingCredit(new Big(0));
        setInaccessibleGivingCredit(
          givingCredit.plus(
            minimumDenominationAmountToCurrencyValue({
              currency: Currency.USD,
              amountInMinDenom: coerceToSafeIntOrThrow({
                num: givingCreditToRedeem?.amountRemaining || 0,
              }),
            }).amount
          )
        );
      } else {
        setAvailableGivingCredit(
          givingCredit.plus(
            minimumDenominationAmountToCurrencyValue({
              currency: Currency.USD,
              amountInMinDenom: coerceToSafeIntOrThrow({
                num: givingCreditToRedeem?.amountRemaining || 0,
              }),
            }).amount
          )
        );
        setInaccessibleGivingCredit(new Big(0));
      }
    },
    [
      isInternational,
      isGiftCardPurchase,
      isPaypalOrDaf,
      isFundAccount,
      givingCreditToRedeem,
    ]
  );
  const handleGivingCreditError = useCallback((e: Error) => {
    logger.error({
      message: "An error occurred fetching giving credit.",
      error: e,
    });
    setAvailableGivingCredit(new Big(0));
  }, []);
  useAsyncEffect({
    asyncOperation: fetchGivingCredit,
    handleResponse: handleGivingCredit,
    handleError: handleGivingCreditError,
  });

  return { availableGivingCredit, inaccessibleGivingCredit };
}

export function usePaymentSourcesForUser({
  loggedInOrGuestUser,
  isInternational,
}: {
  loggedInOrGuestUser: boolean;
  isInternational: boolean;
}) {
  const [paymentSources, setPaymentSources] = useState<PaymentSource[]>();
  const [error, setError] = useState<string>();
  const getPaymentSourcesForUser = useCallback(() => {
    if (loggedInOrGuestUser) {
      setError(undefined);
      // setSelectedPaymentSource(undefined);
      return queryApi(getPaymentSourcesRouteSpec, {
        body: {},
        routeTokens: {},
        queryParams: {},
      });
    }
    return Promise.resolve({ sources: [], defaultSourceId: null });
  }, [loggedInOrGuestUser]);
  const handleSources = useCallback(
    (response: GetPaymentSourcesResponse) => {
      const { sources } = response;
      const availablePaymentSources = isInternational
        ? sources.filter(
            (source) =>
              source.paymentMethod === PaymentMethod.STRIPE &&
              source.type === PaymentSourceType.CARD
          )
        : sources;
      setPaymentSources(availablePaymentSources);
      const defaultPaymentSource = availablePaymentSources.find(
        (s) => s.default
      );
      if (
        !defaultPaymentSource &&
        availablePaymentSources.length > 0 &&
        availablePaymentSources[0].paymentMethod !== PaymentMethod.PAYPAL
      ) {
        logger.warn({
          message:
            "User had no default payment source, but already connected payment sources",
        });
      }
    },
    [isInternational]
  );
  const handleErrors = useCallback(
    (e: Error) => {
      setError(
        getMessageForError(
          e,
          "Unable to get all payment methods. Please try again."
        )
      );
    },
    [setError]
  );
  useAsyncEffect({
    asyncOperation: getPaymentSourcesForUser,
    handleResponse: handleSources,
    handleError: handleErrors,
  });
  return {
    paymentSources,
    defaultPaymentSource: paymentSources?.find((s) => s.default),
    error,
  };
}

export function useNonce() {
  const [nonce, setNonce] = useState<NonEmptyString>();

  const getNonce = useCallback(() => {
    return generateNonce();
  }, []);
  const handleGeneratedNonce = useCallback((nonce: NonEmptyString) => {
    setNonce(nonce);
  }, []);
  const handleNonceError = useCallback(() => {
    logger.error({ message: "Error generating donation nonce" });
  }, []);

  useAsyncEffect({
    asyncOperation: getNonce,
    handleResponse: handleGeneratedNonce,
    handleError: handleNonceError,
  });

  // TODO should this be an async function that only returns when the nonce has
  // been succesfully refreshed?
  const refreshNonce = useCallback(async () => {
    setNonce(await generateNonce());
  }, []);

  return { nonce, refreshNonce };
}

export const useIsExternal = (slug: string) => {
  const { search } = useEdoRouter();
  const searchParams = new URLSearchParams(search);

  const landingPageFromCookie = getLandingPageFromCookie()?.landingPage;
  const landingPage =
    landingPageFromCookie && isValidUrl(landingPageFromCookie)
      ? new URL(landingPageFromCookie)
      : undefined;

  const fundraiserId = decodeOrUndefined(
    UUID,
    searchParams.get(DonateModalUrlParams.FUNDRAISER_ID)
  );
  const fromDonateButton =
    (searchParams.get(UTM_QUERY_PARAM.utm_campaign) ||
      searchParams.get(UTM_QUERY_PARAM_TO_VAR_NAME.utm_campaign)) ===
    "donate-button";

  const fromDonateLink =
    (searchParams.get(UTM_QUERY_PARAM.utm_campaign) ||
      searchParams.get(UTM_QUERY_PARAM_TO_VAR_NAME.utm_campaign)) ===
    "donate-link";

  const landingPageIsDonatePage =
    !!landingPage &&
    landingPage.pathname.includes(slug) &&
    (landingPage.hash.includes(`#${DONATE_HASH}`) ||
      landingPage.hash.includes(`#${LEGACY_DONATE_CRYPTO_HASH}`));

  return {
    isExternal:
      (fromDonateButton || fromDonateLink || landingPageIsDonatePage) &&
      !fundraiserId,
    fromDonateLink,
    fromDonateButton,
  };
};

export const useFundraiserCreatorName = (
  fundraiser?: FundraiserResponse | null
) => {
  const fundraiserCreator = useUser(
    fundraiser && !fundraiser.creatorNonprofitId && fundraiser.creatorUserId
      ? { id: fundraiser?.creatorUserId }
      : undefined
  );
  const fundraiserCreatorName =
    fundraiser && !fundraiser.creatorNonprofitId && fundraiserCreator
      ? fundraiserCreator !== FETCHING_USER &&
        fundraiserCreator !== USER_NOT_FOUND &&
        fundraiserCreator.firstName
        ? fundraiserCreator.firstName
        : "the fundraiser creator"
      : null;

  return fundraiserCreatorName;
};
