import React, { useCallback } from "react";

import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";

import { SupportersList, SupportersListProps } from "./";

import { fetchNonprofitSupporters } from "src/context/NonprofitsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";

interface NonprofitSupportersProps
  extends Omit<SupportersListProps, "fetchQuery"> {
  nonprofitId: ContextNonprofit["id"];
}

export const NonprofitSupporters: React.FCC<NonprofitSupportersProps> = ({
  nonprofitId,
  ...rest
}) => {
  const fetchQuery = useCallback(
    (take: TakeInt, skip: SkipInt) => {
      return fetchNonprofitSupporters(nonprofitId, take, skip);
    },
    [nonprofitId]
  );

  return <SupportersList fetchQuery={fetchQuery} {...rest} />;
};
