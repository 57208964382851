import { createClient } from "@vercel/edge-config";
import { EdgeConfigDataAdapter } from "statsig-node-vercel";

import { StatsigTier } from "@every.org/common/src/entity/types";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export type StatsigNode = typeof import("statsig-node")["Statsig"];

// This points to a cloudflare worker that proxies requests to statsig
// TODO - probably simpler and maybe more performant to use vercel / nextjs to
// proxy these rather than a cloudflare worker
// https://docs.statsig.com/client/javascript-sdk/next-js#advanced---network-proxy
export const STATSIG_PROXY_URL = "https://p.every.org/v1";

let initialized = false;
const initializationPromise = (statsig: StatsigNode) => {
  const tier: StatsigTier = ["production", "staging", "development"].includes(
    process.env.NEXT_PUBLIC_ENVIRONMENT_NAME || ""
  )
    ? (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME as StatsigTier)
    : undefined;

  return statsig
    .initialize(
      assertEnvPresent(
        process.env.STATSIG_SECRET_API_KEY,
        "STATSIG_SECRET_API_KEY"
      ),
      {
        environment: {
          tier,
        },
        initTimeoutMs: 5000,
        dataAdapter: getEdgeConfigDataAdapter(),
        api: STATSIG_PROXY_URL,
      }
    )
    .then(() => {
      initialized = true;
    });
};

const getEdgeConfigDataAdapter = () => {
  if (process.env.EDGE_CONFIG && process.env.STATSIG_EDGE_CONFIG_ITEM_KEY) {
    const edgeConfigClient = createClient(process.env.EDGE_CONFIG);
    const dataAdapter = new EdgeConfigDataAdapter({
      edgeConfigClient: edgeConfigClient,
      edgeConfigItemKey: process.env.STATSIG_EDGE_CONFIG_ITEM_KEY,
    });

    return dataAdapter;
  }

  return undefined;
};

export const initializeStatsig = async (statsig: StatsigNode) => {
  if (!initialized) {
    await initializationPromise(statsig);
  }
};
