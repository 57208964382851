import { fullNameSectionCss } from "@components/Signup/BuildProfileSteps";
import { TextInput, TextInputType } from "@components/TextInput";
import { DonateFormType } from "@components/donate/DonateV3/types";
import styled from "@emotion/styled";
import { Control, Controller } from "react-hook-form";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { LATIN_NAME_REGEX } from "src/utility/userValidators";

const StyledTextInput = styled(TextInput)`
  label {
    ${cssForMediaSize({
      min: MediaSize.MEDIUM,
      css: textSizeCss[TextSize.s],
    })}
  }
`;

export const FullNameSection = ({
  control,
}: {
  control?: Control<DonateFormType>;
}) => {
  return (
    <section css={[verticalStackCss.s]}>
      <div css={fullNameSectionCss}>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextInput
              type={TextInputType.TEXT}
              id="firstName"
              data-tname="firstName"
              name="firstName"
              labelText="First name"
              autoComplete="given-name"
              value={value}
              onChange={(event) => {
                onChange(event);
              }}
              collapseDescriptionSpace
              required
              pattern={LATIN_NAME_REGEX.source}
              validationStatus={
                error && {
                  success: false,
                  message: error?.message,
                }
              }
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextInput
              type={TextInputType.TEXT}
              id="lastName"
              data-tname="lastName"
              name="lastName"
              labelText="Last name"
              autoComplete="family-name"
              value={value}
              onChange={(event) => {
                onChange(event);
              }}
              collapseDescriptionSpace
              required
              pattern={LATIN_NAME_REGEX.source}
              validationStatus={
                error && {
                  success: false,
                  message: error?.message,
                }
              }
            />
          )}
        />
      </div>
    </section>
  );
};
