import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyValueCodec } from "../codecs/currency";
import { dateFromStringCodec } from "../codecs/date";
import {
  personalDonationResponseCodec,
  recurringStatusCodec,
  nonprofitResponseCodec,
  tagResponseCodec,
} from "../codecs/entities";
import { nonNegativeIntegerFromStringCodec } from "../codecs/number";
import { DonationFrequency } from "../entity/types";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec } from ".";

/**
 * Route that updates a recurring donation
 */
const updateRecurringBodyOptional = t.union([
  t.type({ nextScheduled: dateFromStringCodec }),
  t.type({ currencyValue: currencyValueCodec }),
  t.type({ status: recurringStatusCodec }),
  t.type({
    frequency: t.union([
      t.literal(DonationFrequency.WEEKLY),
      t.literal(DonationFrequency.MONTHLY),
    ]),
  }),
  t.type({
    comment: t.type({
      text: t.union([t.string, t.null]),
      updateMostRecentDonation: t.boolean,
    }),
  }),
  t.type({ externalPaymentSourceId: t.string }),
  t.type({ shareInfo: t.boolean }),
  t.type({ tipAmount: nonNegativeIntegerFromStringCodec }),
]);
const updateRecurringBodyRequired = t.type({
  donationId: uuidCodec,
});
export const updateRecurringBodyCodec = t.intersection([
  updateRecurringBodyRequired,
  updateRecurringBodyOptional,
]);
const updateRecurringResponseBodyCodec = personalDonationResponseCodec;
export const updateRecurringDonationRouteSpec = makeRouteSpec({
  path: "/recurring",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: updateRecurringBodyCodec,
  responseBodyCodec: updateRecurringResponseBodyCodec,
});

/**
 * Route that gets all active or paused recurring donations for the user
 */
const getRecurringParamsCodec = t.type({});
const getRecurringBodyCodec = t.type({});
// TODO: replace the definition below with this one after deploying
// const getRecurringResponseBodyCodec = t.type({
//   recurringDonations: t.array(personalDonationResponseCodec),
//   nonprofits: t.array(nonprofitResponseCodec),
//   tags: t.array(tagResponseCodec),
// });
const getRecurringResponseBodyCodec = t.intersection([
  t.type({
    recurringDonations: t.array(personalDonationResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
  }),
  t.partial({
    tags: t.array(tagResponseCodec),
  }),
]);
export const getRecurringDonationsRouteSpec = makeRouteSpec({
  path: "/recurring",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getRecurringParamsCodec,
  bodyCodec: getRecurringBodyCodec,
  responseBodyCodec: getRecurringResponseBodyCodec,
});
