/**
 * Inspired by reecelucas's gist, ported to TypeScript with some changes
 * https://gist.github.com/reecelucas/cd110ece696cca8468db895281fa28cb
 */
import { useState, useEffect } from "react";

import { getWindow } from "src/utility/window";

const window = getWindow();

/**
 * Scroll direction, as reported by useScrollDirection hook
 */
export enum ScrollDirection {
  UP = "UP",
  DOWN = "DOWN",
}

interface UseScrollDirectionProps {
  /**
   * Number of pixels to scroll before scroll direction is decided
   *
   * @default 0
   */
  threshold?: number;
  /**
   * If true, disables scroll direction tracking
   *
   * @default false
   */
  disabled?: boolean;
}

/**
 * Hook that returns the direction the user is scrolling
 *
 * Before scroll occurs, defaults to `null`
 */
export const useScrollDirection = (props?: UseScrollDirectionProps) => {
  const { threshold = 0, disabled = false } = props || {};
  const [scrollDir, setScrollDir] = useState<ScrollDirection | null>(null);

  useEffect(() => {
    if (!window) {
      return;
    }
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(
        scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP
      );
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    disabled ? setScrollDir(null) : window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [threshold, disabled]);

  return scrollDir;
};
