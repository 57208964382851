import { ButtonRole, Button, ButtonTargetKind } from "@components/Button";
import { Popover } from "@components/Popover";
import { PopoverProps } from "@components/Popover/types";
import { appearAnimationCss } from "@components/popoverMenu";
import React, { useRef } from "react";

import { rawSpacing } from "src/theme/spacing";

interface PreconfiguredPopoverProps {
  content: PopoverProps["content"];
}
export const PreconfiguredPopover: React.FCC<PreconfiguredPopoverProps> = ({
  content,
  children,
}) => {
  const buttonRef = useRef<HTMLElement>(null);

  return (
    <Popover
      interactive
      interactiveBorder={rawSpacing.m}
      hideOnClick="toggle"
      css={{ display: "inline-flex" }}
      onCreate={(instance) => {
        // it necessary for keyboard accessibility,
        // causes a popper to hide if no elements within it are in focus.
        instance.popper.addEventListener("focusout", (event) => {
          if (
            !(
              event.currentTarget &&
              instance.popper.contains(event.relatedTarget as HTMLElement)
            )
          ) {
            instance.hide();
          }
        });
      }}
      placement="bottom-start"
      reference={buttonRef}
      content={content}
      contentCss={[{ minWidth: 200 }, appearAnimationCss]}
      onClickOutside={(instance) => instance.hide()}
    >
      <Button
        data-tname="MultiCausesPopoverButton"
        role={ButtonRole.UNSTYLED}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            // do nothing, popover will triggered by reference
          },
        }}
        ref={buttonRef}
      >
        {children}
      </Button>
    </Popover>
  );
};
