import { ButtonRole, ButtonTargetKind, Button } from "@components/Button";
import { Loading } from "@components/LoadingIndicator";
import { UserList } from "@components/UserListCard";
import { SecondaryText } from "@components/textHelpers";
import React from "react";

import { UserResponse } from "@every.org/common/src/codecs/entities";

import { verticalStackCss } from "src/theme/spacing";

interface SupportersListPresentationProps {
  hasMore: boolean;
  isFetching: boolean;
  onClickResult?: () => void;
  onClickViewMore?: () => void;
  supporters: UserResponse[] | null;
  supporterCount: number;
  label?: string;
}

export const SupportersListPresentation: React.FCC<
  SupportersListPresentationProps
> = ({
  hasMore,
  isFetching,
  onClickResult,
  onClickViewMore,
  supporters,
  supporterCount,
  label = "supporters",
}) => {
  if (!supporters && isFetching) {
    return <Loading />;
  }
  if (supporters && supporters.length) {
    return (
      <div css={verticalStackCss.m}>
        <UserList
          usersInfo={supporters.map((user: UserResponse) => ({
            user,
          }))}
          showLocation
          onClick={onClickResult}
        />
        {isFetching && <Loading />}
        {hasMore && onClickViewMore && !isFetching && (
          <Button
            data-tname={"LoadMoreSupporters--Button"}
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: onClickViewMore,
            }}
          >
            View more
          </Button>
        )}
        {!hasMore && !isFetching && supporterCount > supporters.length && (
          <SecondaryText>and more private {label}</SecondaryText>
        )}
      </div>
    );
  }

  return <p>No public {label}. Be the first!</p>;
};
