import React from "react";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_WDYR === "true"
) {
  // explicitly only run in dev because why-did-you-render tanks performance;
  // it's useful for finding unnecessary rerenders.
  // eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackAllPureComponents: true });
}
