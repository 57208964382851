import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import memoize from "lodash.memoize";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import { mapValues } from "@every.org/common/src/helpers/objectUtilities";

import { wrapStyleInFocusBlock } from "src/styles/common";
import { linkFocusCss } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

export enum ButtonRole {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TEXT_ONLY = "TEXT_ONLY",
  TEXT_SECONDARY = "TEXT_SECONDARY",
  UNSTYLED = "UNSTYLED",
}

export enum ButtonSize {
  SMALL = "s",
  MEDIUM = "m",
  LARGE = "l",
}

export const BUTTON_CONTENT_COLOR_CSS_VAR = "--btnColor";
export const BUTTON_BACKGROUND_COLOR_CSS_VAR = "--btnBgColor";

const unstyledButtonCss = css`
  /* reset button styles */
  color: inherit;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-transform: inherit;
  cursor: pointer; // still show interactivity with cursor
`;

const baseButtonCss = css`
  border-radius: 500px;
  position: relative;
  border: none;
  appearance: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
  text-decoration: none;
  font-weight: ${FontWeight.MEDIUM};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:focus {
    outline: none;
  }

  &,
  &:hover {
    color: var(${BUTTON_CONTENT_COLOR_CSS_VAR});
  }
  background-color: var(${BUTTON_BACKGROUND_COLOR_CSS_VAR});

  transition: background-color 0.1s ease-in, border-color 0.1s ease-in,
    color 0.1s ease-in;
`;

const baseTextButtonFocusCss = css`
  position: relative;
  text-decoration: underline;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: ${spacing.xxs} ${spacing.xs};
    box-sizing: content-box;

    ${linkFocusCss};
  }
`;

export const textButtonFocusCss = wrapStyleInFocusBlock(baseTextButtonFocusCss);

const focusCss: {
  [key in Exclude<ButtonRole, ButtonRole.UNSTYLED>]: CSSInterpolation;
} = mapValues({
  obj: {
    [ButtonRole.PRIMARY]: css`
      box-shadow: 0 0 0 2px ${SHARED_PALETTE.focusComplementary},
        0 0 0 3px ${SHARED_PALETTE.focus}, 0 0 6px ${SHARED_PALETTE.focus};
    }
  `,

    [ButtonRole.SECONDARY]: css`
      border-color: ${SHARED_PALETTE.focus};
      box-shadow: 0 0 0 2px ${SHARED_PALETTE.focusComplementary},
        0 0 4px ${SHARED_PALETTE.focus};
    `,
    [ButtonRole.TEXT_ONLY]: baseTextButtonFocusCss,
    [ButtonRole.TEXT_SECONDARY]: baseTextButtonFocusCss,
  },
  mapper: wrapStyleInFocusBlock,
});

const noBackgroundCss = css`
  background-color: transparent;
  ${BUTTON_BACKGROUND_COLOR_CSS_VAR}: transparent;
`;
const secondaryContentColorCss = [
  noBackgroundCss,
  css`
    ${BUTTON_CONTENT_COLOR_CSS_VAR}: var(${colorCssVars.accent.large});
    &:hover:not(:disabled) {
      ${BUTTON_CONTENT_COLOR_CSS_VAR}: var(${colorCssVars.accent
        .largeHighlight});
    }
    &:disabled {
      ${BUTTON_CONTENT_COLOR_CSS_VAR}: var(${colorCssVars.text.secondary});
    }
  `,
];

const buttonColorCss: {
  [key in Exclude<ButtonRole, ButtonRole.UNSTYLED>]: CSSInterpolation;
} = {
  [ButtonRole.PRIMARY]: css`
    ${BUTTON_CONTENT_COLOR_CSS_VAR}: white;
    ${BUTTON_BACKGROUND_COLOR_CSS_VAR}: var(${colorCssVars.accent.large});
    &:disabled {
      ${BUTTON_BACKGROUND_COLOR_CSS_VAR}: var(${colorCssVars.text.secondary})
    }

    &:hover:not(:disabled) {
      ${BUTTON_BACKGROUND_COLOR_CSS_VAR}: var(${colorCssVars.accent
        .largeHighlight});
    }
  `,
  [ButtonRole.SECONDARY]: secondaryContentColorCss,
  [ButtonRole.TEXT_ONLY]: secondaryContentColorCss,
  [ButtonRole.TEXT_SECONDARY]: [
    noBackgroundCss,
    css`
      ${BUTTON_CONTENT_COLOR_CSS_VAR}: var(${colorCssVars.text.secondary});
      &:hover:not(:disabled) {
        ${BUTTON_CONTENT_COLOR_CSS_VAR}: var(${colorCssVars.text.body});
      }
    `,
  ],
};

const BUTTON_BORDER_WIDTH = "2px";

const secondaryButtonBorderCss = css`
  border-width: ${BUTTON_BORDER_WIDTH};
  border-style: solid;
  border-color: var(${BUTTON_CONTENT_COLOR_CSS_VAR});
`;

const buttonCssBySize: { [key in ButtonSize]: CSSInterpolation } = {
  [ButtonSize.SMALL]: textSizeCss.s,
  [ButtonSize.MEDIUM]: textSizeCss.s,
  [ButtonSize.LARGE]: textSizeCss.m,
};

const textButtonPaddingCss = css`
  padding: 0;
`;
const secondaryButtonPaddingCss: {
  [key in ButtonSize]: CSSInterpolation;
} = {
  [ButtonSize.SMALL]: css`
    padding: ${spacing.xs} ${spacing.s};
  `,
  [ButtonSize.MEDIUM]: css`
    padding: ${spacing.m} ${spacing.l};
  `,
  [ButtonSize.LARGE]: css`
    padding: ${spacing.m} ${spacing.xl};
  `,
};

export const primaryButtonPaddingCss: {
  [key in ButtonSize]: CSSInterpolation;
} = {
  [ButtonSize.SMALL]: css`
    padding: calc(${spacing.xs} + ${BUTTON_BORDER_WIDTH})
      calc(${spacing.s} + ${BUTTON_BORDER_WIDTH});
  `,
  [ButtonSize.MEDIUM]: css`
    padding: calc(${spacing.m} + ${BUTTON_BORDER_WIDTH})
      calc(${spacing.l} + ${BUTTON_BORDER_WIDTH});
  `,
  [ButtonSize.LARGE]: css`
    padding: calc(${spacing.m} + ${BUTTON_BORDER_WIDTH})
      calc(${spacing.xl} + ${BUTTON_BORDER_WIDTH});
  `,
};

export const buttonCss = memoize(
  (role: ButtonRole, size: ButtonSize): CSSInterpolation =>
    role === ButtonRole.UNSTYLED
      ? unstyledButtonCss
      : [
          baseButtonCss,
          focusCss[role],
          buttonColorCss[role],
          role === ButtonRole.SECONDARY && secondaryButtonBorderCss,
          buttonCssBySize[size],
          [ButtonRole.TEXT_ONLY, ButtonRole.TEXT_SECONDARY].includes(role)
            ? textButtonPaddingCss
            : role === ButtonRole.PRIMARY
            ? primaryButtonPaddingCss[size]
            : secondaryButtonPaddingCss[size],
        ],
  (role, size) => [role, size].join()
);
