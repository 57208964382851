import { ABTest } from "./types";

export enum GivelistRedirectTestVariant {
  CONTROL = "CONTROL",
  BEFORE = "BEFORE",
  NEVER = "NEVER",
}

export const GivelistRedirectTest: ABTest<GivelistRedirectTestVariant> = {
  name: "2021-08-11-GivelistRedirect",
  variants: {
    [GivelistRedirectTestVariant.CONTROL]: 1,
    [GivelistRedirectTestVariant.BEFORE]: 1,
    [GivelistRedirectTestVariant.NEVER]: 1,
  },
};
