import { PaymentSourceResponse as PaymentSource } from "@every.org/common/src/codecs/entities";
import { addPlaidAccountRouteSpec } from "@every.org/common/src/routes/donate";

import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export interface OpenPlaidModalParams {
  /**
   * Called when the user logs in and retrieves a bank account from Plaid
   * successfully, but before it's added to our databases
   */
  onPlaidConnectionSuccess: () => void;
  /**
   * Called when a retrieved account from Plaid is added to our database and
   * details retrieved.
   */
  onCompletedAddingAccount: (
    result:
      | { success: true; paymentSource: PaymentSource }
      | { success: false; error: Error }
  ) => void;
  onExit: () => void;
  firstName?: string;
  lastName?: string;
  email?: string;
}

const UNLOGGED_ERROR_CODES = ["INVALID_CREDENTIALS", "INVALID_MFA"];

let linkHandler:
  | { open: () => void; exit: () => void; destroy: () => void }
  | undefined;

function _openPlaidModal({
  onPlaidConnectionSuccess,
  onCompletedAddingAccount,
  onExit,
  linkToken,
}: OpenPlaidModalParams & { linkToken: string }): void {
  linkHandler = getWindow()?.Plaid.create({
    token: linkToken,
    selectAccount: true,

    // no plaid types, so allow any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async function (publicToken: string, metadata: any) {
      onPlaidConnectionSuccess();
      try {
        const source = await queryApi(addPlaidAccountRouteSpec, {
          body: {
            publicToken,
            institutionName: metadata.institution.name || null,
            accounts: metadata.accounts,
            linkSessionId: metadata.link_session_id,
          },
          routeTokens: {},
          queryParams: {},
        });
        onCompletedAddingAccount({ success: true, paymentSource: source });
      } catch (error) {
        logger.error({
          error,
          message: "Could not add bank account",
          data: metadata,
        });
        if (error instanceof Error) {
          onCompletedAddingAccount({ success: false, error });
        }
      }
    },
    onExit: function (
      /* eslint-disable @typescript-eslint/naming-convention */
      // plaid.com/docs/#error-object-schema
      error: {
        display_message: string;
        error_code: string;
        error_message: string;
        error_type: string;
      },
      // plaid.com/docs/#onexit-metadata-schema
      metadata: {
        link_session_id: string;
        request_id: string;
        institution: {
          name: string;
          institution_id: string;
        };
        // plaid.com/docs/#metadata-status
        status: string;
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    ) {
      onExit();
      // The user exited the Link flow.
      if (error != null && !UNLOGGED_ERROR_CODES.includes(error.error_code)) {
        // The user encountered a Plaid API error prior to exiting.
        logger.error({
          message: "A Plaid API error occurred",
          data: { metadata, plaidError: error },
        });
      }
    },
  });
  if (linkHandler) {
    linkHandler.open(); // opens modal
  }
}

export function closePlaidModal() {
  if (linkHandler) {
    linkHandler.destroy();
    linkHandler = undefined;
  }
}

export function openPlaidModal(
  params: OpenPlaidModalParams & { linkToken: string }
) {
  if (linkHandler) {
    return;
  }
  const window = getWindow();
  if (!window) {
    return;
  }
  if ("Plaid" in window) {
    _openPlaidModal(params);
  } else {
    (
      window.document.getElementById("plaid-js") as HTMLElement
    ).addEventListener("load", () => _openPlaidModal(params));
  }
}
