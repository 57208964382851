import { UUID } from "io-ts-types/UUID";
import React, { createContext, useReducer } from "react";

import { LikeableType } from "@every.org/common/src/entity/types";

//#region Types
export enum LikeChange {
  LIKED = "LIKED",
  UNLIKED = "UNLIKED",
  ERROR = "ERROR",
}

export enum LikesActionType {
  FORCE_UPDATE = "FORCE_UPDATE",
  ADD_TO_CONTEXT = "ADD_TO_CONTEXT",
  SUBMIT_LIKE = "SUBMIT_LIKE",
  SUBMIT_UNLIKE = "SUBMIT_UNLIKE",
  LIKE_SAVED = "LIKE_SAVED",
  UNLIKE_SAVED = "UNLIKE_SAVED",
}

interface LikeData {
  likeCount: number;
  loggedInUserLikes: boolean;
}

export interface LikeIdentificator {
  id: UUID;
  type: LikeableType;
}

type LikeInstance = LikeIdentificator & LikeData;

interface AddLikeAction {
  type: LikesActionType.ADD_TO_CONTEXT | LikesActionType.FORCE_UPDATE;
  data: LikeInstance;
}

type LikesAction =
  | AddLikeAction
  | {
      type: Exclude<
        LikesActionType,
        LikesActionType.ADD_TO_CONTEXT | LikesActionType.FORCE_UPDATE
      >;
      data: LikeIdentificator;
    };

type LikesState = {
  [key in LikeableType]: Map<UUID, LikeData & { submitting?: boolean }>;
};
//#endregion

const initialState = {
  [LikeableType.DONATION]: new Map(),
  [LikeableType.FUND]: new Map(),
  [LikeableType.NONPROFIT]: new Map(),
  [LikeableType.TAG]: new Map(),
};

function likesReducer(state: LikesState, action: LikesAction) {
  let newState = state;
  const {
    data: { type, id },
  } = action;
  const like = state[type].get(id);

  function insertToState(
    id: UUID,
    payload: LikeData & { submitting?: boolean }
  ): LikesState {
    return { ...state, [type]: new Map([...state[type], [id, payload]]) };
  }

  switch (action.type) {
    case LikesActionType.FORCE_UPDATE:
      newState[type].set(id, {
        likeCount: action.data.likeCount,
        loggedInUserLikes: action.data.loggedInUserLikes,
      });
      break;
    case LikesActionType.ADD_TO_CONTEXT:
      !like &&
        newState[type].set(id, {
          likeCount: action.data.likeCount,
          loggedInUserLikes: action.data.loggedInUserLikes,
        });
      break;
    case LikesActionType.SUBMIT_LIKE:
      if (like) {
        newState = insertToState(id, {
          likeCount: like.likeCount + 1,
          loggedInUserLikes: true,
          submitting: true,
        });
      }
      break;
    case LikesActionType.SUBMIT_UNLIKE:
      if (like) {
        newState = insertToState(id, {
          likeCount: like.likeCount - 1,
          loggedInUserLikes: false,
          submitting: true,
        });
      }
      break;
    case LikesActionType.LIKE_SAVED:
    case LikesActionType.UNLIKE_SAVED:
      if (like) {
        newState = insertToState(id, {
          ...like,
          submitting: false,
        });
      }
  }
  return newState;
}

export const LikesContext = createContext<LikesState>(initialState);

export let dispatchLikesAction: React.Dispatch<LikesAction>;

export const LikesProvider: React.FCC = ({ children }) => {
  const [likesState, likesDispatcher] = useReducer(likesReducer, initialState);
  dispatchLikesAction = likesDispatcher;
  return (
    <LikesContext.Provider value={likesState}>{children}</LikesContext.Provider>
  );
};
