import { Big } from "big.js";
import * as t from "io-ts";

import { CURRENCY_SCALE } from "../entity/constants";
import { Currency } from "../entity/types";
import { isBig } from "../helpers/big";

import { stringEnumCodec } from "./";

/**
 * A custom `io-ts` type for our currency enum. Validates that the value we
 * receive is a member of the currency enum.
 */
export const currencyCodec = stringEnumCodec({
  name: "Currency",
  enumObject: Currency,
});

export const currencyAmountCodec = new t.Type<Big, string>(
  "CurrencyAmount",
  isBig,
  /**
   * Decode an unknown value to a `CurrencyValue` object, or fail if not a
   * validly serialized `CurrencyValue` (see `CurrencyValueSerialized`).
   */
  function decode(unknownValue, context): t.Validation<Big> {
    if (
      !(
        typeof unknownValue === "string" ||
        typeof unknownValue === "number" ||
        isBig(unknownValue)
      )
    ) {
      return t.failure(unknownValue, context, "value was not a Big instance");
    }
    const amountString = unknownValue.toString();
    if (amountString.toLowerCase().includes("e")) {
      return t.failure(
        unknownValue,
        context,
        "Exponential numbers not allowed"
      );
    }

    try {
      return t.success(new Big(amountString));
    } catch (e) {
      return t.failure(
        unknownValue,
        context,
        "Could not convert amount to Big"
      );
    }
  },
  function encode(value): string {
    // represent at maximum scale we store at, with trailing 0's removed
    return value.toFixed(CURRENCY_SCALE).replace(/0+$/, "");
  }
);

export const currencyValueCodec = t.type({
  currency: currencyCodec,
  amount: currencyAmountCodec,
});
/**
 * Refers to an amount of a currency.
 */
export type CurrencyValue = t.TypeOf<typeof currencyValueCodec>;

export type CurrencyValueSerialized = { [key in keyof CurrencyValue]: string };
