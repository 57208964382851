import { InputContainer } from "@components/InputContainer";
import { SelectPaymentSource } from "@components/donate/DonateV3/PaymentProcess/components/SelectPaymentSource";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { Big } from "big.js";
import { useState } from "react";

import { PaymentSourceResponse as PaymentSource } from "@every.org/common/src/codecs/entities";
import { PaymentSourceType } from "@every.org/common/src/entity/types";

export const PaymentMethodPicker = ({
  amountToCharge,
  creditAmount,
  renewablePaymentSourceRequired,
  isInternational,
  isGiftCardPurchase,
  paymentSources,
  setPaymentSources,
  onChange,
  creditsOnly,
  selectedPaymentSource,
  paymentSourceType,
  firstName,
  lastName,
  email,
  clearFormError,
}: {
  paymentSourceType: PaymentSourceType;
  amountToCharge: Big;
  creditAmount: Big;
  creditsOnly: boolean;
  renewablePaymentSourceRequired: boolean;
  isInternational: boolean;
  isGiftCardPurchase: boolean;
  paymentSources?: DonateFormContext["paymentSources"];
  setPaymentSources: DonateFormContext["setPaymentSources"];
  onChange: (selectedSource?: PaymentSource) => void;
  selectedPaymentSource?: PaymentSource;
  firstName?: string;
  lastName?: string;
  email?: string;
  clearFormError?: () => void;
}) => {
  // TODO use error message
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <InputContainer data-tname="selectPaymentSource" collapseDescriptionSpace>
      <SelectPaymentSource
        selectedPaymentSourceType={paymentSourceType}
        paymentSources={paymentSources}
        setPaymentSources={setPaymentSources}
        selectedPaymentSource={selectedPaymentSource || undefined}
        setSelectedPaymentSource={onChange}
        amountToCharge={amountToCharge}
        creditAmount={creditAmount}
        creditsOnly={creditsOnly}
        renewablePaymentSourceRequired={renewablePaymentSourceRequired}
        setErrorMessage={setErrorMessage}
        // disallowAddingType={
        //   isInternational
        //     ? new Set<PaymentSourceType>([PaymentSourceType.ACH_DEBIT])
        //     : undefined
        // }
        isGiftCardPurchase={isGiftCardPurchase}
        firstName={firstName}
        lastName={lastName}
        email={email}
        clearFormError={clearFormError}
      />
    </InputContainer>
  );
};
