import type { PersonalUserResponse, UserResponse } from "../codecs/entities";

export const UNKNOWN_NAME = "Anonymous user";

/**
 * Takes a string and makes it into username containing only valid characters
 * (alphanumeric + period + underscore)
 *
 * Does not guarantee a valid username (e.g. length may be invalid, symbols
 * present)
 */
export function getUsernameWithValidCharacters(username: string): string {
  return username
    .toLowerCase()
    .replace(/\s+/g, ".") // Replace spaces with .
    .replace(/[^a-z0-9._]+/g, "") // Remove not accepted characters
    .replace(/^\.+/g, "") // Remove prepending .
    .replace(/\.+$/g, ""); // Remove trailing .
}

/**
 * @returns appropriately combined first and last name, or "" if not present
 */
export function getUserFullName(
  user?: Pick<UserResponse, "firstName" | "lastName"> | null
): string | null {
  if (!user) {
    return null;
  }
  return (
    [user.firstName, user.lastName]
      .map((p) => p && p.trim())
      .filter((part): part is string => !!part)
      .join(" ") || null
  );
}

/**
 * @returns the legal name, full name, first name, or empty string
 */
export function getUserLegalName(
  userIdentifiable: Pick<
    PersonalUserResponse,
    "firstName" | "lastName" | "legalName"
  >
): string {
  return (
    userIdentifiable.legalName ??
    getUserFullName(userIdentifiable) ??
    // Should not happen for a receipt, but at least have an identifying name
    userIdentifiable.firstName ??
    ""
  );
}

/**
 * Returns "Unknown" if the user doesn't have a name.
 */
export function getUserFullNameOrPlaceholder(
  user: Pick<UserResponse, "firstName" | "lastName">
) {
  return getUserFullName(user) || UNKNOWN_NAME;
}
