import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import { TextSize } from "@every.org/common/src/display/text";
import { FGC_CAMPAIGN_CODE } from "@every.org/common/src/entity/constants";
import {
  DonationFrequencyToUserFacingTextMap,
  DonationFrequency,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import { MAX_CRYPTO_DECIMALS_FOR_DISPLAY } from "@every.org/common/src/helpers/cryptoCurrency";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  verticalStackCss,
  spacing,
} from "src/theme/spacing";
import { textSizeCss, FontWeight } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";
import {
  displayCryptoValueInUserLocale,
  displayCurrencyValueInUserLocale,
} from "src/utility/currency";

const TipSummaryCopyExperiment = () => {
  const summary = useStatSigLayer("tip_copy_layer")?.get(
    "summary",
    "Donation for Every.org"
  );
  return <div>{summary}</div>;
};

const LegendTitle = styled.legend`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

const SummaryWrapper = styled.div`
  color: var(${colorCssVars.text.secondary});
  ${textSizeCss[TextSize.s]};
  ${verticalStackCss.xs};
`;

const TipRow = styled.div<{ isCryptoDonation: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.xs};

  ${({ isCryptoDonation }) =>
    isCryptoDonation &&
    cssForMediaSize({
      max: MediaSize.SMALL,
      css: css`
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      `,
    })}
`;

const TipDivider = styled.div<{ isCryptoDonation: boolean }>`
  border-left: 1px solid #dadddd;
  height: 100%;

  ${({ isCryptoDonation }) =>
    isCryptoDonation &&
    cssForMediaSize({
      max: MediaSize.SMALL,
      css: css`
        display: none;
      `,
    })}
`;

const SummaryList = styled.ul`
  li {
    ${horizontalStackCss.none};
    justify-content: space-between;
    padding: ${spacing.s} ${spacing.none};
    box-shadow: 0px 1px 0px #eaeded;
    > span {
      color: var(${colorCssVars.text.body});
    }

    button {
      color: var(${colorCssVars.text.secondary}) !important;
      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      color: var(${colorCssVars.accent.large});
    }
  }
`;

export interface DonationSummaryProps {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  paymentOption: DonationFlowPaymentOption;
  onTipEdit?: () => void;
}

export const DonationSummary = ({
  form,
  formContext,
  nonprofit,
  paymentOption,
  onTipEdit,
}: DonationSummaryProps) => {
  const {
    creditAmount,
    amountBig: donationAmount,
    matchingAmount,
  } = formContext;
  const { watch } = form;

  const frequency = watch("frequency");
  const currency = watch("currency");
  const tipAmount = watch("amountToEveryOrg") || 0;
  const cryptoCurrency = watch("cryptoCurrency");
  const cryptoPledgeAmount = watch("cryptoPledgeAmount");
  const cryptoPledgeAmountTotal = formContext.totalCryptoAmount;
  const totalStockAmount = formContext.totalStockAmount;
  const totalGift = donationAmount.add(matchingAmount);
  const totalAmount = donationAmount.add(tipAmount).minus(creditAmount);
  const stockAmount = watch("stockAmount");
  const stockSymbol = watch("stockSymbol");

  const isCryptoDonation = paymentOption === DonationFlowPaymentOption.CRYPTO;
  const isDAFDonation = paymentOption === DonationFlowPaymentOption.DAF;
  const isStockDonation = paymentOption === DonationFlowPaymentOption.STOCKS;

  if (isStockDonation) {
    return (
      <SummaryWrapper>
        <LegendTitle>Your donation</LegendTitle>
        <SummaryList>
          <li>
            <div>Stock symbol</div>
            <span>{stockSymbol}</span>
          </li>
          <li>
            <div>Number of shares</div>
            <span>{stockAmount}</span>
          </li>
          <li>
            <div css={horizontalStackCss.xs}>
              <span>Donation for Every.org (Optional)</span>
              {onTipEdit && (
                <React.Fragment>
                  <TipDivider isCryptoDonation={isCryptoDonation} />
                  <Button
                    data-tname="donationSummaryTipEdit"
                    role={ButtonRole.TEXT_SECONDARY}
                    onClick={{
                      kind: ButtonTargetKind.FUNCTION,
                      action: onTipEdit,
                    }}
                  >
                    Edit
                  </Button>
                </React.Fragment>
              )}
            </div>
            <span>{tipAmount}</span>
          </li>
          <li>
            <div>Total</div>
            <span>{totalStockAmount?.toString()}</span>
          </li>
        </SummaryList>
      </SummaryWrapper>
    );
  }

  return (
    <SummaryWrapper>
      <LegendTitle>Your donation</LegendTitle>
      <SummaryList>
        {!isCryptoDonation && (
          <li>
            <div>Frequency</div>
            <span>{DonationFrequencyToUserFacingTextMap[frequency]}</span>
          </li>
        )}
        <li>
          <div>Donation for {nonprofit.name}</div>
          {cryptoCurrency && cryptoPledgeAmount && isCryptoDonation ? (
            <span>
              {displayCryptoValueInUserLocale({
                cryptoCurrency: cryptoCurrency,
                amount: new Big(cryptoPledgeAmount),
                maximumSignificantDigits: MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
              })}
            </span>
          ) : (
            <span>
              {displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency,
                  amount: new Big(donationAmount),
                },
              })}
            </span>
          )}
        </li>
        {matchingAmount.gt(0) && (
          <li>
            <div>
              Match amount{frequency !== DonationFrequency.ONCE ? "*" : ""}
            </div>
            <span>
              <strong>+</strong>{" "}
              {displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency,
                  amount: matchingAmount,
                },
              })}
            </span>
          </li>
        )}
        {matchingAmount.gt(0) && (
          <li>
            <div>Total gift</div>
            <span>
              {displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency,
                  amount: new Big(totalGift),
                },
              })}
            </span>
          </li>
        )}
        {formContext.enableTipping && (
          <li>
            <TipRow isCryptoDonation={isCryptoDonation}>
              <TipSummaryCopyExperiment />
              {onTipEdit && (
                <React.Fragment>
                  <TipDivider isCryptoDonation={isCryptoDonation} />
                  <Button
                    data-tname="donationSummaryTipEdit"
                    role={ButtonRole.TEXT_SECONDARY}
                    onClick={{
                      kind: ButtonTargetKind.FUNCTION,
                      action: onTipEdit,
                    }}
                  >
                    Edit
                  </Button>
                </React.Fragment>
              )}
            </TipRow>
            <span>
              {isCryptoDonation && cryptoCurrency
                ? displayCryptoValueInUserLocale({
                    cryptoCurrency: cryptoCurrency,
                    amount: new Big(tipAmount),
                    maximumSignificantDigits: MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
                  })
                : displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency,
                      amount: new Big(tipAmount),
                    },
                  })}
            </span>
          </li>
        )}
        {!isDAFDonation && creditAmount.gt(0) && (
          <li>
            <div>Donation credits used</div>
            <span>
              {displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency,
                  amount: creditAmount,
                },
              })}
            </span>
          </li>
        )}
        {formContext.enableTipping && (
          <li>
            <div>
              {isCryptoDonation
                ? "Total"
                : frequency === DonationFrequency.ONCE
                ? "Total charge"
                : `Total monthly charge ${
                    creditAmount.gt(0)
                      ? matchingAmount.gt(0)
                        ? "**"
                        : "*"
                      : ""
                  }`}
            </div>
            <span>
              {isCryptoDonation && cryptoCurrency && cryptoPledgeAmountTotal
                ? displayCryptoValueInUserLocale({
                    cryptoCurrency: cryptoCurrency,
                    amount: cryptoPledgeAmountTotal,
                    maximumSignificantDigits: MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
                  })
                : displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency,
                      amount: totalAmount,
                    },
                  })}
            </span>
          </li>
        )}
        {/* {form.getValues().lastName && form.getValues().firstName && (
          <li>
            <div>Dedicated to</div>
            <span>{`${form.getValues().firstName} ${
              form.getValues().lastName
            }`}</span>
          </li>
        )} */}
      </SummaryList>
      {frequency === DonationFrequency.MONTHLY && (
        <React.Fragment>
          {matchingAmount.gt(0) && (
            <p>
              * Monthly gifts are matched for the first{" "}
              {formContext.nonprofitMatchCampaign?.code === FGC_CAMPAIGN_CODE
                ? "two months"
                : "month"}
              .
            </p>
          )}
          {creditAmount.gt(0) && (
            <p>
              {matchingAmount.gt(0) ? "**" : "*"} The monthly charge when no
              donation credits are available will be{" "}
              {displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency,
                  amount: totalAmount.plus(creditAmount),
                },
              })}
            </p>
          )}
        </React.Fragment>
      )}
    </SummaryWrapper>
  );
};
