import { DefaultTabNavigation, TabHeader } from "@components/TabHeader";
import React from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

interface TabNavigationProps {
  fund: ContextNonprofit;
  selectedTab?: FundPageTabs;
  className?: string;
  nonprofitsCount?: number;
  donationsCount?: number;
}

export enum FundPageTabs {
  NONPROFITS = "NONPROFITS",
  GIFTS = "GIFTS",
  LEARN_MORE = "LEARN_MORE",
}

export const TabNavigation: React.FCC<TabNavigationProps> = ({
  fund,
  selectedTab = FundPageTabs.NONPROFITS,
  className,
  donationsCount,
}) => {
  const hasEndorsedNonprofits = (fund?.endorsedNonprofitIds?.length || 0) > 0;
  const hasDonations = donationsCount && donationsCount > 0;
  return (
    <DefaultTabNavigation
      role="navigation"
      aria-label="Fund page tabs"
      className={className}
    >
      {hasEndorsedNonprofits ? (
        <li>
          <TabHeader
            data-tname={"fund-nonprofits-tab"}
            to={getRoutePath({
              name: ClientRouteName.FUND_OR_TAG_NONPROFITS,
              format: URLFormat.RELATIVE,
              tokens: { nonprofitSlug: fund.primarySlug },
            })}
            currentTab={FundPageTabs.NONPROFITS}
            selectedTab={selectedTab}
          >
            Now supporting
          </TabHeader>
        </li>
      ) : null}
      {hasDonations ? (
        <li>
          <TabHeader
            data-tname={"fund-gifts-tab"}
            to={getRoutePath({
              name: ClientRouteName.FUND_OR_TAG_GIFTS,
              format: URLFormat.RELATIVE,
              tokens: { nonprofitSlug: fund.primarySlug },
            })}
            currentTab={FundPageTabs.GIFTS}
            selectedTab={selectedTab}
            count={donationsCount}
          >
            Gifts
          </TabHeader>
        </li>
      ) : null}
      {fund.descriptionLong ? (
        <li>
          <TabHeader
            data-tname={"fund-learn-more-tab"}
            to={getRoutePath({
              name: ClientRouteName.FUND_LEARN_MORE,
              format: URLFormat.RELATIVE,
              tokens: { nonprofitSlug: fund.primarySlug },
            })}
            currentTab={FundPageTabs.LEARN_MORE}
            selectedTab={selectedTab}
          >
            Learn more
          </TabHeader>
        </li>
      ) : null}
    </DefaultTabNavigation>
  );
};
