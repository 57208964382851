import { Checkbox, CheckboxSize } from "@components/Checkbox";
import { IconSize } from "@components/Icon";
import { PopoverInfoButton } from "@components/PopoverInfoButton";
import { Theme } from "@emotion/react";
import styled, { Interpolation } from "@emotion/styled";
import React from "react";

import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export enum ShareCheckboxType {
  FUNDRAISER_CREATOR = "FUNDRAISER_CREATOR",
  EMAIL = "EMAIL",
  PARTNER = "PARTNER",
  IS_PUBLIC = "IS_PUBLIC",
  OPT_IN = "OPT_IN",
}

const ShareInfoContainer = styled.div`
  ${horizontalStackCss.xs};
`;

const StyledLabel = styled.label`
  ${textSizeCss.s};
`;

const StyledPopoverInfoButton = styled(PopoverInfoButton)`
  margin-left: ${spacing.xxs};
  display: inline-flex;
  vertical-align: middle;
`;

const TextWithInfoWrapper = styled.span`
  display: -webkit-inline-box;
  -webkit-box-pack: center;
  -webkit-box-align: middle;
`;

/**
 * For both guest and private users, this checkbox has the effect of sharing
 * everything with the nonprofit/fundraiser creator. For public users, it only
 * changes the sharing of the email.
 */
export function getShareEmailText(
  loggedInOrGuestUser: ReturnType<typeof useLoggedInOrGuestUserOrUndefined>,
  entityName: string
) {
  const userGuestOrPrivate =
    !loggedInOrGuestUser ||
    (loggedInOrGuestUser && loggedInOrGuestUser.isPrivate);
  return userGuestOrPrivate
    ? `Share with ${entityName} that I contributed`
    : `Share my email with ${entityName}`;
}

export function getSharePopoverText(
  type: ShareCheckboxType,
  receiverName: string,
  required?: boolean
) {
  switch (type) {
    case ShareCheckboxType.FUNDRAISER_CREATOR:
      return `If you check this box, then ${receiverName} will get your name, donation amount, note and email when you donate.`;
    case ShareCheckboxType.EMAIL:
      return `${
        required ? "" : "If checked, "
      }${receiverName} will receive your name and email address.`;
    case ShareCheckboxType.IS_PUBLIC:
      return required
        ? `Because you wrote a public testimony, your name will appear on ${receiverName}'s public list of supporters (but your donation amount will not). If you would like to change this, tap Back and remove your public testimony.`
        : `If checked, your name will appear on ${receiverName}'s public list of supporters to encourage others (but your donation amount will not).`;
    default:
      return undefined;
  }
}

export const ShareInfoCheckbox = ({
  dataTname,
  checked,
  name,
  id,
  disabled,
  onClick,
  labelCss,
  checkboxCss,
  labelText,
  infoText,
}: {
  dataTname: string;
  checked?: boolean;
  name: string;
  id?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
  labelCss?: Interpolation<Theme>;
  checkboxCss?: Interpolation<Theme>;
  labelText?: string;
  infoText?: string;
}) => {
  return (
    <ShareInfoContainer>
      {!disabled && (
        <Checkbox
          data-tname={dataTname}
          checked={checked}
          onClick={onClick}
          name={name}
          id={id}
          disabled={disabled}
          size={CheckboxSize.LARGE}
          css={checkboxCss}
        />
      )}
      <TextWithInfoWrapper>
        <StyledLabel css={labelCss} htmlFor={name}>
          {labelText}
        </StyledLabel>
        {infoText && (
          <StyledPopoverInfoButton imgSize={IconSize.MEDIUM} text={infoText} />
        )}
      </TextWithInfoWrapper>
    </ShareInfoContainer>
  );
};
