import { css } from "@emotion/react";
import { createContext } from "react";

import { memoize } from "@every.org/common/src/helpers/functions";

/**
 * Allows setting and passing the current zIndex of the parent
 *
 * Done as Javascript instead of relying on CSS variables, because with
 * React Portals, children of an element may not actually be inside that element
 * which is a common occurrence when dealing with stacking values (modals,
 * popovers, dropdowns all use portals)
 */
export const PageStackingContext = createContext<null | number>(null);

const zIndexCssVar = "--zIndex";
export const zIndexCss = memoize({
  fn: (zIndex: number) => {
    return css`
      ${zIndexCssVar}: ${zIndex};
      z-index: var(${zIndexCssVar});
    `;
  },
});

/**
 * Z-indexes of various parts of the layout
 *
 * Helpful for managing stacking elements like Modals and Popovers with respect
 * to page content
 */
export const LAYOUT_Z_INDEXES = {
  toast: 101,
  modal: 100,
  noExitOverlay: 99,
  signUpOverlay: 98,
  nav: 30,
  main: 20,
  header: 10,
  footer: 0,
};
