/**
 * Component that renders an button that opens a modal.
 */

import { Button, ButtonTargetKind, ButtonProps } from "@components/Button";
import { Modal } from "@components/Modal";
import React, { useState, ReactNode } from "react";

type RenderModalContent = (
  setIsModalOpen: (open: boolean) => void
) => ReactNode;

export interface ModalButtonProps {
  className?: string;
  buttonContent: ReactNode;
  buttonProps: Omit<ButtonProps, "onClick" | "children">;
  modalHeader: ReactNode;
  modalContent: ReactNode | RenderModalContent;
  modalContentLabel: string;
  isShareModal?: boolean;
}

function isFunction(
  modalContent: ReactNode | RenderModalContent
): modalContent is RenderModalContent {
  return typeof modalContent === "function";
}

/**
 * Button that opens a modal.
 */
export const ModalButton: React.FCC<ModalButtonProps> = React.memo(
  function ModalButtonImpl({
    buttonContent,
    buttonProps,
    modalHeader,
    modalContent,
    modalContentLabel,
    isShareModal,
    className,
  }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
      <React.Fragment>
        <Button
          {...buttonProps}
          className={className}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              setIsModalOpen(true);
            },
          }}
        >
          {buttonContent}
        </Button>
        <Modal
          headerText={modalHeader}
          showHeader
          isOpen={isModalOpen}
          contentLabel={modalContentLabel}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          isShareModal={isShareModal}
        >
          {isFunction(modalContent)
            ? modalContent(setIsModalOpen)
            : modalContent}
        </Modal>
      </React.Fragment>
    );
  }
);
