import { encodeFrequencyOptionsParam } from "@components/donate/DonateRouteModal";
import { DonateFormProps } from "@components/donate/DonateV3/types";
import { UUID } from "io-ts-types/UUID";
import React, { useMemo } from "react";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import { TagResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationFrequency,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
  DonateModalUrlParams,
  getDonateRoutePath,
} from "@every.org/common/src/helpers/clientRoutes";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";

import {
  Button,
  ButtonTargetKind,
  ButtonRole,
  ButtonSize,
  ButtonProps,
  OnClick,
} from "src/components/Button";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { EdoRouter, useEdoRouter } from "src/hooks/useEdoRouter";
import { ClickAction } from "src/utility/analytics";
import { buildUrlPath } from "src/utility/url";

function generateModalLinkParams({
  primarySlug,
  donationToJoinId,
  userToJoinId,
  matchCampaign,
  donationHeaderText,
  redirectUrl,
  fromFundraiserId,
  minDonationValue,
  frequencyOptions,
  frequency,
  shareInfo,
  successUrl,
  requireShareInfo,
  giftCards,
  method,
}: Omit<ModalLinkParams, "isDisbursable">) {
  const campaignParam = matchCampaign
    ? JSON.stringify(matchCampaign)
    : undefined;

  const params = new URLSearchParams(
    removeUndefinedValues({
      [DonateModalUrlParams.DONATE_NONPROFIT_ID]: primarySlug,
      [DonateModalUrlParams.JOIN_DONATION_ID]: donationToJoinId,
      [DonateModalUrlParams.JOIN_DONATION_USER_ID]: userToJoinId,
      [DonateModalUrlParams.MATCH_CAMPAIGN]: campaignParam,
      [DonateModalUrlParams.DONATION_HEADER_TEXT]: donationHeaderText,
      [DonateModalUrlParams.REDIRECT_URL]: redirectUrl,
      [DonateModalUrlParams.FUNDRAISER_ID]: fromFundraiserId,
      [DonateModalUrlParams.MIN_VALUE]: minDonationValue?.amount.toString(),
      [DonateModalUrlParams.FREQUENCY]: frequency,
      [DonateModalUrlParams.SHARE_INFO]: shareInfo
        ? "true"
        : shareInfo === false
        ? "false"
        : undefined,
      [DonateModalUrlParams.SUCCESS_URL]: successUrl,
      [DonateModalUrlParams.FREQUENCY_OPTIONS]: frequencyOptions
        ? encodeFrequencyOptionsParam(frequencyOptions)
        : undefined,
      [DonateModalUrlParams.REQUIRE_SHARE_INFO]: requireShareInfo
        ? "true"
        : undefined,
      [DonateModalUrlParams.GIFT_CARD_AMOUNT]:
        giftCards?.amount.amount.toString(),
      [DonateModalUrlParams.GIFT_CARD_QUANTITY]: giftCards?.quantity.toString(),
      [DonateModalUrlParams.GIFT_CARD_NONPROFIT_ID]: giftCards?.nonprofitId,
      [DonateModalUrlParams.GIFT_CARD_TAG_ID]: giftCards?.tagId,
      [DonateModalUrlParams.METHOD]: method,
    })
  );

  return params;
}

export function generateModalLink({
  primarySlug,
  isDisbursable,
  router,
  ...rest
}: ModalLinkParams & {
  router: EdoRouter;
}) {
  const params = generateModalLinkParams({ ...rest, primarySlug });

  return isDisbursable
    ? buildUrlPath({
        pathname: router.pathname,
        search: [router.search, params],
      })
    : getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: primarySlug },
      });
}

type BaseLinkParams = Pick<ContextNonprofit, "primarySlug" | "isDisbursable">;

type BaseDonateButtonProps = Omit<ButtonProps, "onClick" | "role"> & {
  /**
   * @default PRIMARY
   */
  role?: ButtonRole;
};

type ToNonprofitPageDonateButtonProps = BaseDonateButtonProps & {
  toNonprofitPage: true;
} & BaseLinkParams;

type ToFundraiserPageDonateButtonProps = BaseDonateButtonProps & {
  nonprofitSlug: string;
  fundraiserSlug: string;
};

type ModalLinkParams = {
  redirectUrl?: string;
  fromFundraiserId?: UUID;
  minDonationValue?: CurrencyValue;
  frequencyOptions?: DonationFrequency[];
  frequency?: DonationFrequency;
  shareInfo?: boolean;
  successUrl?: string;
  requireShareInfo?: boolean;
  giftCards?: {
    amount: CurrencyValue;
    quantity: number;
    nonprofitId?: ContextNonprofit["id"];
    tagId?: TagResponse["id"];
  };
  method?: PaymentMethod.CRYPTO;
} & Pick<
  DonateFormProps,
  "donationToJoinId" | "userToJoinId" | "matchCampaign" | "donationHeaderText"
> &
  BaseLinkParams;

type DonateModalButtonProps = BaseDonateButtonProps & {
  toNonprofitPage?: false;
} & ModalLinkParams;

type DonateButtonProps =
  | ToNonprofitPageDonateButtonProps
  | DonateModalButtonProps;

/**
 * Button to donate to a nonprofit.
 *
 * Use the toNonprofitPage prop to control whether the button navigates the user to the
 * <nonprofit>/donate page or opens a donation modal where they are.
 */
export const DonateButton: React.FCC<
  DonateButtonProps & Omit<ButtonProps, "onClick" | "role">
> = React.memo(function DonateButtonImpl(props) {
  if (props.toNonprofitPage) {
    return <ToNonprofitPageButton {...props} />;
  }
  // Uncomment after #12744 fix
  // return <DonateModalButton {...props} />;
  return (
    <ToNonprofitPageButton
      {...props}
      size={props.size ? props.size : ButtonSize.SMALL}
      toNonprofitPage
    />
  );
});

// Uncomment after #12744 fix
// eslint-disable-next-line unused-imports/no-unused-vars
export function DonateModalButton({
  primarySlug,
  isDisbursable,
  donationToJoinId,
  userToJoinId,
  matchCampaign,
  children = "Donate",
  role = ButtonRole.PRIMARY,
  donationHeaderText,
  redirectUrl,
  fromFundraiserId,
  minDonationValue,
  frequencyOptions,
  frequency,
  shareInfo,
  successUrl,
  requireShareInfo,
  giftCards,
  method,
  ...rest
}: DonateModalButtonProps) {
  const router = useEdoRouter();

  const onClick = useMemo<OnClick>(() => {
    const to = generateModalLink({
      router,
      primarySlug,
      isDisbursable,
      donationToJoinId,
      userToJoinId,
      matchCampaign,
      donationHeaderText,
      redirectUrl,
      fromFundraiserId,
      minDonationValue,
      frequencyOptions,
      frequency,
      shareInfo,
      successUrl,
      requireShareInfo,
      giftCards,
      method,
    });
    return {
      kind: ButtonTargetKind.LINK,
      to,
      rel: "nofollow", // Don't want SEO to crawl pages with the donate modal open
    };
  }, [
    donationHeaderText,
    donationToJoinId,
    frequency,
    frequencyOptions,
    fromFundraiserId,
    giftCards,
    isDisbursable,
    router,
    matchCampaign,
    minDonationValue,
    primarySlug,
    redirectUrl,
    requireShareInfo,
    shareInfo,
    successUrl,
    userToJoinId,
    method,
  ]);
  return (
    <Button
      size={ButtonSize.SMALL}
      role={role}
      data-action={ClickAction.DONATE}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

function ToNonprofitPageButton({
  primarySlug,
  isDisbursable,
  children = "Donate",
  role = ButtonRole.PRIMARY,
  ...rest
}: ToNonprofitPageDonateButtonProps) {
  // keep modal params
  const params = generateModalLinkParams({ ...rest, primarySlug });
  const { search } = useEdoRouter();
  const onClick = useMemo<OnClick>(() => {
    const query = {
      ...Object.fromEntries(new URLSearchParams(search)),
      ...Object.fromEntries(params),
    };
    return {
      kind: ButtonTargetKind.LINK,
      to: getDonateRoutePath({
        nonprofitSlug: primarySlug,
        format: URLFormat.RELATIVE,
        query,
      }),
    };
  }, [primarySlug, search, params]);
  return (
    <Button
      role={role}
      data-action={ClickAction.DONATE}
      onClick={onClick}
      disabled={!isDisbursable}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function ToFundraiserPageDonateButton({
  nonprofitSlug,
  fundraiserSlug,
  children = "Donate",
  role = ButtonRole.PRIMARY,
  ...rest
}: ToFundraiserPageDonateButtonProps) {
  const { search } = useEdoRouter();
  const onClick = useMemo<OnClick>(() => {
    const query = Object.fromEntries(new URLSearchParams(search));
    return {
      kind: ButtonTargetKind.LINK,
      to: getDonateRoutePath({
        nonprofitSlug,
        fundraiserSlug,
        format: URLFormat.RELATIVE,
        query,
      }),
    };
  }, [nonprofitSlug, fundraiserSlug, search]);
  return (
    <Button
      role={role}
      data-action={ClickAction.DONATE}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}
