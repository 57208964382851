import { NextPageContext } from "next";

const MINUTE = 60;
const WEEK = 7 * 24 * 60 * MINUTE;

type CacheValues = {
  "max-age": number;
  "stale-while-revalidate": number;
};

// Do not set the s-maxage, so it will match the max-age
const defaults: CacheValues = {
  "max-age": 1, // Browser will cache for 1 second before requesting a fresh copy
  // CDN will serve stale copy while updating its cache in the background for the next request.
  // If the stale copy is more that 1 week old it will not be used.
  "stale-while-revalidate": 1 * WEEK,
};

/**
 * Anytime we do this, we run the risk of someone seeing the cached page
 * when logged out, then logging in, then getting the cache when they should not.
 * We minimize this risk by settig the default max age to be very short, though
 * because we have stale-while-revalidate it's still quite possible.
 */
export const addCacheControl = (
  ctx: NextPageContext,
  values: Partial<CacheValues> = {}
) => {
  const finalValues = { ...defaults, ...values };
  const kv = Object.entries(finalValues).map(
    ([key, value]) => `${key}=${value}`
  );
  ctx.res?.setHeader("Cache-Control", ["public", ...kv].join(", "));
};
