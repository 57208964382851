import { CurrencyValue } from "../codecs/currency";
import { coerceToSafeIntOrThrow, SafeInt } from "../codecs/number";
import { Currency } from "../entity/types";

import { mapValues } from "./objectUtilities";

/**
 * For each currency supported in the app, sets minimum and maximum bounds for
 * a currency amount for that value, in that currency's minimum denomination.
 * Bounds are inclusive.
 */
export const DEFAULT_BOUNDS_FOR_DONATION_VALUE: {
  [key in Currency]: { min: SafeInt; max: SafeInt };
} = mapValues({
  obj: {
    // See https://stripe.com/docs/currencies
    [Currency.USD]: {
      min: 1000, // Minimum allowed donation of $10
      max: 1e8 - 1, // Maximum allowed donation of just under $1M
    },
    [Currency.EUR]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.GBP]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.AUD]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.HKD]: {
      min: 5000,
      max: 1e8 - 1,
    },
    [Currency.CAD]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.JPY]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.CHF]: {
      min: 1000,
      max: 1e8 - 1,
    },
    [Currency.NZD]: {
      min: 1000,
      max: 1e8 - 1,
    },
  },
  mapper: (values) =>
    mapValues({
      obj: values,
      mapper: (num) => coerceToSafeIntOrThrow({ num }),
    }),
});

/**
 * Potential reasons why a donation value would be invalid
 */
export enum DonationValueErrorCode {
  TOO_LARGE = "TOO_LARGE",
  TOO_SMALL = "TOO_SMALL",
  CURRENCY_MISMATCH = "CURRENCY_MISMATCH",
}

/**
 * Check that a donation value is valid
 *
 * @returns A reason for why the donation value was invalid, or `undefined` if
 * valid
 */
export function donationValueIsInvalid(params: {
  value: CurrencyValue;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
}): DonationValueErrorCode | undefined {
  const { value, minValue, maxValue } = params;

  if (
    value.currency !== minValue.currency ||
    value.currency !== maxValue.currency
  ) {
    return DonationValueErrorCode.CURRENCY_MISMATCH;
  }

  if (value.amount.lt(minValue.amount)) {
    return DonationValueErrorCode.TOO_SMALL;
  }

  if (value.amount.gt(maxValue.amount)) {
    return DonationValueErrorCode.TOO_LARGE;
  }

  return undefined;
}

export function validDonationValueOrUndefined(params: {
  value: CurrencyValue;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
}): CurrencyValue | undefined {
  const error = donationValueIsInvalid(params);

  if (error) {
    return undefined;
  }

  return params.value;
}
