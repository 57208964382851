import { useStatSigLayer } from "src/utility/abtesting";

interface FrequencySelectorCopy {
  heading: string;
  option_monthly: string;
  option_once: string;
  supporting_copy_monthly: string;
  supporting_copy_once: string;
  error_alert: string;
}

const FrequencySelectorCopyDefaults: FrequencySelectorCopy = {
  heading: "Frequency",
  option_monthly: "Give Monthly",
  option_once: "Once",
  supporting_copy_monthly:
    "Monthly donations help nonprofits focus on mission and long-term impact, not fundraising. Cancel anytime.",
  supporting_copy_once: "Make a one-time difference today!",
  error_alert: "Please choose a donation frequency",
};

const getCopy = (
  layer: ReturnType<typeof useStatSigLayer>,
  key: keyof FrequencySelectorCopy
) => {
  const defaultValue = FrequencySelectorCopyDefaults[key];
  return layer?.get(key, defaultValue) ?? defaultValue;
};

export const useFrequencySelectorLayer = (skip: boolean) => {
  const layer = useStatSigLayer("frequency_selector_copy", skip);
  return {
    heading: getCopy(layer, "heading"),
    option_monthly: getCopy(layer, "option_monthly"),
    option_once: getCopy(layer, "option_once"),
    supporting_copy_monthly: getCopy(layer, "supporting_copy_monthly"),
    supporting_copy_once: getCopy(layer, "supporting_copy_once"),
    error_alert: getCopy(layer, "error_alert"),
  };
};
