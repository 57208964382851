import {
  clientRouteMetas,
  ClientRouteName,
  RouteRestrictions,
} from "@every.org/common/src/helpers/clientRoutes";

const ONBOARDING_REQUIRED_RESTRICTIONS = new Set([
  RouteRestrictions.ONBOARDING_REQUIRED,
  RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
  RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED,
]);

type GetRedirectionProps = {
  deactivated: boolean;
  profileIncomplete: boolean;
  emailVerified: boolean;
  routeName: ClientRouteName;
  restrictions: RouteRestrictions;
};

export const getRedirection = ({
  deactivated,
  profileIncomplete,
  emailVerified,
  routeName,
  restrictions,
}: GetRedirectionProps) => {
  if (deactivated && routeName !== ClientRouteName.REACTIVATE_ACCOUNT) {
    return ClientRouteName.REACTIVATE_ACCOUNT;
  }

  if (profileIncomplete && ONBOARDING_REQUIRED_RESTRICTIONS.has(restrictions)) {
    return ClientRouteName.BUILD_PROFILE;
  }

  if (
    !emailVerified &&
    restrictions === RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED
  ) {
    return ClientRouteName.VERIFICATION_REQUIRED;
  }

  return null;
};

export const getRestrictions = (routeName: ClientRouteName | undefined) => {
  // Not a route we know the restrictions for
  if (routeName === undefined) {
    return null;
  }

  const restrictions = clientRouteMetas[routeName].routeRestrictions;

  // No restrictions
  if (!restrictions || restrictions === RouteRestrictions.NONE) {
    return null;
  }

  return restrictions;
};
