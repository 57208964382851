import { useStripePromise } from "@components/donate/DonateV3/PaymentProcess/components/AddCreditCard";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import type { PaymentRequest, Stripe } from "@stripe/stripe-js";
import React, { createContext, useCallback, useEffect, useState } from "react";

import { Country, Currency } from "@every.org/common/src/entity/types";

import { useIsLoggedIn } from "src/context/AuthContext/hooks";
import { getWindow } from "src/utility/window";

export enum PaymentRequestReadyStatus {
  /**
   * Hasn't been set up for the first time yet
   */
  UNINITIALIZED,
  /**
   * Ready for paymnets
   */
  READY,
  /**
   * Device doesn't support payments
   */
  UNABLE,
}

export interface PaymentRequestInitializer {
  readyStatus: PaymentRequestReadyStatus;
  canMakePaymentRequest: boolean | undefined;
  isApplePay: boolean;
  initializePaymentRequest: (
    stripe: Stripe
  ) => Promise<PaymentRequest | undefined>;
}

const initialState: PaymentRequestInitializer = {
  readyStatus: PaymentRequestReadyStatus.UNINITIALIZED,
  canMakePaymentRequest: false,
  isApplePay: false,
  initializePaymentRequest: () => Promise.resolve(undefined),
};

export const PaymentRequestContext =
  createContext<PaymentRequestInitializer>(initialState);

export const PaymentRequestProvider: React.FCC = ({ children }) => {
  const stripePromise = useStripePromise();

  return (
    <Elements stripe={stripePromise || null}>
      <PaymentRequestInitializerProvider>
        {children}
      </PaymentRequestInitializerProvider>
    </Elements>
  );
};

export const PaymentRequestInitializerProvider: React.FCC = ({ children }) => {
  const requestContactInformation = !useIsLoggedIn();
  const stripe = useStripe();
  const [readyStatus, setReadyStatus] = useState<PaymentRequestReadyStatus>(
    PaymentRequestReadyStatus.UNINITIALIZED
  );
  // Starts as undefined because at first we do not know if the browser supports
  // payment request (aka Apple/Google pay) and then switch to true/false when known.
  const [canMakePaymentRequest, setCanMakePaymentRequest] = useState<boolean>();
  const initializePaymentRequest = useCallback(
    async (stripe: Stripe) => {
      const pr = stripe.paymentRequest({
        country: Country.US,
        currency: Currency.USD.toLowerCase(),
        total: { label: "test", amount: 0 },
        requestPayerName: requestContactInformation,
        requestPayerEmail: requestContactInformation,
      });
      const canMakePayment = !!(await pr.canMakePayment());
      return canMakePayment ? pr : undefined;
    },
    [requestContactInformation]
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }
    initializePaymentRequest(stripe).then((pr) => {
      setReadyStatus(
        pr ? PaymentRequestReadyStatus.READY : PaymentRequestReadyStatus.UNABLE
      );
      setCanMakePaymentRequest(!!pr);
    });
  }, [initializePaymentRequest, setCanMakePaymentRequest, stripe]);

  const isApplePay = !!getWindow()?.ApplePaySession;

  return (
    <PaymentRequestContext.Provider
      value={{
        readyStatus,
        canMakePaymentRequest,
        isApplePay,
        initializePaymentRequest,
      }}
    >
      {children}
    </PaymentRequestContext.Provider>
  );
};
