import { NextRequest } from "next/server";
import { match } from "path-to-regexp";

import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import {
  clientRouteMetas,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

export const hasAuth = (cookies: NextRequest["cookies"]) => {
  // SESSION_EXISTS is sometimes set even when there is no session so
  // we need to check for SESSION_KEY too to verify that there is a session
  // When you log out SESSION_EXISTS is cleared but SESSION_KEY is
  // not so we need to check if either is missing to verify there is no session
  return Boolean(
    cookies.get(CookieKey.SESSION_EXISTS) && cookies.get(CookieKey.SESSION_KEY)
  );
};

export function skipStaticPath(pathname: string) {
  return (
    pathname.startsWith("/fonts") ||
    pathname.startsWith("/static") ||
    pathname.startsWith("/scripts") ||
    pathname.startsWith("/_next") ||
    pathname.endsWith(".png") ||
    pathname.endsWith(".json") ||
    pathname.endsWith(".ico")
  );
}

// Hack for Vercel bug - https://github.com/vercel/vercel/issues/7452
export const getRouteNameForPathName = (
  pathName: string
): ClientRouteName | undefined => {
  const route = Object.entries(clientRouteMetas).find(([_, routeMeta]) =>
    match(routeMeta.path)(pathName)
  );
  if (route) {
    return route[0] as ClientRouteName;
  }
  return;
};
