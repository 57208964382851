import {
  ButtonTargetKind,
  Button,
  ButtonProps,
  fullWidthButtonCss,
} from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { ModalButton } from "@components/ModalButton";
import { Popover } from "@components/Popover";
import { PopoverProps } from "@components/Popover/types";
import { BrowserShareData, ShareMeta } from "@components/ShareButton/types";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useRef } from "react";

import { ShareMedium } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { ConstructCloudinaryUrlParams } from "@every.org/common/src/helpers/cloudinary";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import {
  getShareText,
  getTwitterShareText,
} from "@every.org/common/src/helpers/share";
import { smartTruncate } from "@every.org/common/src/helpers/string";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  ButtonRole,
  ButtonSize,
  BUTTON_CONTENT_COLOR_CSS_VAR,
} from "src/styles/button";
import { useMatchesScreenSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  rawSpacing,
  verticalStackCss,
} from "src/theme/spacing";
import { trackShareButtonClick } from "src/utility/analytics";
import { mailToLink } from "src/utility/helpers";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export function getSupportNonprofitShareData({
  medium,
  nonprofit,
  body,
  url,
  postscript,
  isMyDonation = false,
}: {
  medium: ShareMedium;
  nonprofit?: ContextNonprofit;
  body?: string;
  url: string;
  postscript?: string;
  isMyDonation?: boolean;
}): BrowserShareData {
  const shareArgs = {
    nonprofitName: nonprofit?.name,
    isMyDonation,
    bodyText: body,
    postscript,
  };

  const text =
    medium === ShareMedium.TWITTER
      ? getTwitterShareText({
          nonprofitTwitterHandle: nonprofit?.twitterHandle,
          ...shareArgs,
        })
      : getShareText(shareArgs);

  return {
    url,
    text,
  };
}

interface ShareNavigator extends Navigator {
  share: (data?: BrowserShareData) => Promise<void>;
}

export interface ShareButtonProps
  extends Omit<ButtonProps, "onClick" | "icon"> {
  /**
   * Where hitting the share button should send you to, as per the
   * `navigator.share` browser API. Note that we do not use the "title"
   * parameter as most platforms including iOS and Android do not make use of it.
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
   */
  shareData: BrowserShareData | ((medium: ShareMedium) => BrowserShareData);

  /**
   * Whether or not to hide the text in the button.
   * Ignored when children is provided
   *
   * @default false
   */
  hideText?: boolean;

  /**
   * Whether or not to hide the icon in the button
   *
   * @default false
   */
  hideIcon?: boolean;

  /**
   * On mobile, square image displayed for shareability with image-based social networks like Instagram
   */
  instagramImageCloudinaryParams: Omit<
    ConstructCloudinaryUrlParams,
    "dimensions"
  >;

  /**
   * Override the popover's props as necessary
   */
  popoverProps?: Partial<
    Omit<PopoverProps, "visible" | "content" | "children">
  >;
  className?: string;
  buttonCss?: CSSInterpolation;
  isFeedCardShare?: boolean;
  nonprofitSlug?: string;
}

export const createShareLink = (
  medium: ShareMedium,
  shareData: BrowserShareData | ((medium: ShareMedium) => BrowserShareData)
): string => {
  const linkShareData =
    typeof shareData === "function" ? shareData(medium) : shareData;
  const { url, text } = linkShareData;

  switch (medium) {
    case ShareMedium.FACEBOOK:
      return `https://www.facebook.com/dialog/share?${new URLSearchParams({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        app_id: assertEnvPresent(
          process.env.NEXT_PUBLIC_FB_ID || process.env.REACT_APP_FB_ID,
          "FB_ID"
        ),
        display: "page",
        href: url,
        quote: text,
      }).toString()}`;
    case ShareMedium.TWITTER:
      return `https://twitter.com/intent/tweet?${new URLSearchParams({
        text,
        url,
        via: "everydotorg",
      }).toString()}`;
    case ShareMedium.WHATSAPP:
      return `https://wa.me/?${new URLSearchParams({
        text: `${text} ${url}`,
      }).toString()}`;
    case ShareMedium.REDDIT:
      return `https://www.reddit.com/submit?${new URLSearchParams({
        title: text,
        url,
      }).toString()}`;
    case ShareMedium.LINKEDIN:
      return `https://www.linkedin.com/shareArticle?${new URLSearchParams({
        url,
      }).toString()}`;
    case ShareMedium.EMAIL:
      return mailToLink({
        address: undefined,
        subject: smartTruncate(text, 70),
        body: `${text} \n\n${url}`,
      });
    case ShareMedium.LINK:
    default:
      return url;
  }
};

/**
 * @returns true if the native share dialog is present and ought to be used
 *
 * Desktop safari's treatment as of June 2020 is very lackluster so we
 * explicitly don't support it there)
 */
export function shouldUseNativeShare(nav: Navigator): nav is ShareNavigator {
  const window = getWindow();
  // Not generalizing this since we should generally be using feature
  // detection, not user agent string parsing, to determine what to render, but
  // this case is an exception - Desktop Safari's native share UI at the moment
  // is butt-ugly.
  // check safari: https://stackoverflow.com/a/42189492/1105281
  // just in case, exclude mobile browsers: https://stackoverflow.com/a/24600597/1105281
  const isDesktopSafari =
    window &&
    (window as { safari?: unknown }).safari &&
    !window.navigator.userAgent.includes("Mobi");
  return !!(nav as ShareNavigator).share && !isDesktopSafari;
}

/**
 * Button that triggers a share dialog on click
 */
export const ShareButton: React.FCC<ShareButtonProps> = React.memo(
  function ShareButtonImpl({
    shareData,
    hideText = false,
    hideIcon = false,
    popoverProps,
    instagramImageCloudinaryParams,
    children,
    className,
    buttonCss,
    isFeedCardShare = false,
    nonprofitSlug,
    ...rest
  }) {
    const buttonRef = useRef<HTMLElement>(null);

    const buttonProps: Omit<ButtonProps, "onClick"> = {
      ...rest,
      icon: !hideIcon && (
        <Icon
          iconImport={() => import("@components/Icon/icons/ShareIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.CURRENT_COLOR}
        />
      ),
    };

    const buttonChildren = children ? children : !hideText && "Share";

    const feedCardPopoverProps = {
      css: css`
        [data-tippy-root] {
          // full width of feed card - 2 * feed card paddidng
          // requires feed card to be nearest parent with 'position: absolute'
          width: calc(100% - 2 * ${spacing.m});
        }
        [data-popper-arrow] {
          margin-left: -4px;
        }
      `,
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20],
            },
          },
        ],
      },
    };

    // eslint-disable-next-line no-restricted-syntax
    const showModal = !useMatchesScreenSize({ min: MediaSize.MEDIUM }) ?? false;

    return showModal ? (
      <ModalButton
        buttonProps={buttonProps}
        buttonContent={buttonChildren}
        modalHeader={"Share"}
        modalContentLabel={"Share ui"}
        modalContent={
          <ShareContent shareData={shareData} nonprofitSlug={nonprofitSlug} />
        }
        isShareModal
      />
    ) : (
      <Popover
        interactive
        interactiveBorder={rawSpacing.xxl}
        interactiveDebounce={100}
        hideOnClick="toggle"
        onClickOutside={(instance) => instance.hide()}
        onCreate={(instance) => {
          // it necessary for keyboard accessibility,
          // causes a popper to hide if no elements within it are in focus.
          instance.popper.addEventListener("focusout", (event) => {
            if (
              !(
                event.currentTarget &&
                instance.popper.contains(event.relatedTarget as HTMLElement)
              )
            ) {
              instance.hide();
            }
          });
        }}
        className={className}
        contentCss={css`
          [data-popper-content] {
            padding: ${spacing.l};
          }
        `}
        reference={buttonRef}
        content={
          <ShareContent shareData={shareData} nonprofitSlug={nonprofitSlug} />
        }
        trigger={
          [ButtonRole.PRIMARY, ButtonRole.SECONDARY].includes(buttonProps.role)
            ? "click"
            : undefined
        }
        placement={isFeedCardShare ? "top-start" : "bottom-start"}
        {...(isFeedCardShare ? feedCardPopoverProps : {})}
      >
        <Button
          {...buttonProps}
          css={{ display: "block", width: "100%" }}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              // do nothing, popover will triggered by reference
            },
          }}
          ref={buttonRef}
        >
          {buttonChildren}
        </Button>
      </Popover>
    );
  }
);

export const XButtonElem = styled(Button)`
  ${BUTTON_CONTENT_COLOR_CSS_VAR}: #0f141a;
  border-color: #0f141a;

  &:hover:not(:disabled) {
    ${BUTTON_CONTENT_COLOR_CSS_VAR}: #0f141a;
    background-color: rgba(15, 20, 26, 0.1);
  }
`;

const ShareContent: React.FCC<{
  shareData: ShareButtonProps["shareData"];
  nonprofitSlug?: string;
}> = ({ shareData, nonprofitSlug }) => {
  const shareItems: Exclude<
    ShareMedium,
    ShareMedium.NATIVE | ShareMedium.COPY
  >[] = [
    ShareMedium.EMAIL,
    ShareMedium.FACEBOOK,
    ShareMedium.TWITTER,
    ShareMedium.WHATSAPP,
    ShareMedium.LINKEDIN,
    ShareMedium.REDDIT,
  ];

  const shareLink = createShareLink(ShareMedium.COPY, shareData);

  const [showCopied, setShowCopied] = useState<boolean>(false);

  const onCopied = () => {
    trackShareButtonClick({ medium: ShareMedium.COPY, shareData });
    setShowCopied(true);
  };

  const onTimerEnd = () => {
    setShowCopied(false);
  };

  const window = getWindow();
  const showNativeShare = window && shouldUseNativeShare(window.navigator);

  async function openShareDialog() {
    const useNativeShare = window && shouldUseNativeShare(window.navigator);
    if (window && useNativeShare && window.navigator) {
      try {
        await window.navigator.share(
          typeof shareData === "function"
            ? shareData(ShareMedium.NATIVE)
            : shareData
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error && "name" in error && error.name !== "AbortError") {
          logger.warn({
            message: "Error sharing native donation",
            data: { shareError: error },
          });
        }
      }
    }
  }

  return (
    <div css={[verticalStackCss.m]}>
      <ul
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: ${spacing.m};
          grid-row-gap: ${spacing.xs};
        `}
      >
        {shareItems.map((medium) => (
          <li key={medium} css={{ display: "flex" }}>
            <ShareListItem shareData={shareData} medium={medium} />
          </li>
        ))}
      </ul>
      <div css={verticalStackCss.xs}>
        <CopyToClipboardButton
          textToCopy={shareLink}
          onCopied={onCopied}
          onTimerEnd={onTimerEnd}
          role={ButtonRole.SECONDARY}
          size={ButtonSize.SMALL}
          data-tname="shareButton--copy"
          css={fullWidthButtonCss}
          contentCss={[
            horizontalStackCss.xs,
            css`
              align-items: center;
              justify-content: center;
            `,
          ]}
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/LinkIcon")}
            display={IconDisplay.CURRENT_COLOR}
            size={IconSize.SMALL}
          />
          <span>{showCopied ? "Copied!" : "Copy link"}</span>
        </CopyToClipboardButton>
        {nonprofitSlug && (
          <Button
            data-tname="shareButton--create"
            role={ButtonRole.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={{
              kind: ButtonTargetKind.LINK,
              to: getRoutePath({
                name: ClientRouteName.CONFIGURE_BUTTON,
                tokens: {
                  nonprofitSlug: nonprofitSlug,
                },
                format: URLFormat.RELATIVE,
              }),
            }}
            contentCss={[
              horizontalStackCss.xs,
              css`
                align-items: center;
                justify-content: center;
              `,
            ]}
          >
            <Icon
              iconImport={() =>
                import("@components/Icon/icons/CreateButtonIcon")
              }
              display={IconDisplay.CURRENT_COLOR}
              size={IconSize.SMALL}
            />
            <span>Create button</span>
          </Button>
        )}
        {showNativeShare && (
          <Button
            data-tname="shareButton--more"
            role={ButtonRole.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: openShareDialog,
            }}
            contentCss={[
              horizontalStackCss.xs,
              css`
                align-items: center;
                justify-content: center;
              `,
            ]}
          >
            <Icon
              iconImport={() => import("@components/Icon/icons/MoreIcon")}
              display={IconDisplay.CURRENT_COLOR}
              size={IconSize.SMALL}
              css={{ transform: "rotate(90deg)" }}
            />
            <span>More</span>
          </Button>
        )}
      </div>
    </div>
  );
};

const ShareListItem: React.FCC<{
  shareData: ShareButtonProps["shareData"];
  medium: Exclude<ShareMedium, ShareMedium.NATIVE | ShareMedium.COPY>;
}> = ({ shareData, medium }) => {
  const window = getWindow();

  const { color, name, iconImport } = ShareMeta[medium];

  const shareLink = createShareLink(medium, shareData);

  const action = () => {
    trackShareButtonClick({ medium, shareData });
    window?.open(shareLink, "_blank");
  };

  const buttonContent = (
    <React.Fragment>
      <Icon
        css={{ color }}
        iconImport={iconImport}
        display={IconDisplay.CURRENT_COLOR}
        size={IconSize.MEDIUM}
      />
      <span css={{ paddingRight: spacing.xxs }}>{name}</span>
    </React.Fragment>
  );

  const buttonProps = {
    "data-tname": `shareLink--${medium}`,
    css: css`
      width: 100%;
      padding-right: ${spacing.l};
    `,
    contentCss: css`
      ${horizontalStackCss.xs};
      align-items: center;
    `,
    role: ButtonRole.TEXT_SECONDARY,
    size: ButtonSize.SMALL,
    children: buttonContent,
  };

  return (
    <Button
      {...buttonProps}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action,
      }}
    />
  );
};
