import {
  DonationHeaderCard,
  DonationHeaderCardProps,
} from "@components/DonationCardHeader";
import { EditableText } from "@components/EditableText";
import { NonprofitCard as NewNonprofitCard } from "@components/feed/NewNonprofitCard";
import { FULL_SIZE_LINK_CLASS } from "@components/feed/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types/UUID";
import React, { useCallback, useContext } from "react";

import { DonationBoostResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationFrequency,
  FeedItemType,
  FeedPage,
} from "@every.org/common/src/entity/types";

import { Link } from "src/components/Link";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  ViewDonationContext,
  ViewDonationData,
  ViewDonationProvider,
} from "src/context/ViewDonationContext";
import { EditableTextInterface } from "src/hooks/useEditableText";
import { LinkAppearance } from "src/styles/link";
import { verticalStackCss } from "src/theme/spacing";
import { compositionTextCss } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";
import { commentTextToDisplay } from "src/utility/comment";

const Comment = styled.article`
  ${compositionTextCss};
  overflow-wrap: break-word;
`;

// Used to make elements clickable if there is an absolutely positioned link on top of them
export const clickableCss = css`
  position: relative;
  z-index: 1;
`;

export const ScrollableComment = styled(Comment)`
  overflow-y: auto;
`;

export interface DonationCardCommentBodyProps {
  /**
   * Page the DonationCard is being rendered on, to control different data that
   * gets shown
   */
  page?: FeedPage;
  /**
   * When editing a comment, saves the comment when the comment input loses
   * focus if true
   */
  saveOnBlur?: boolean;
  /**
   * If true, focuses on the comment text box on render automatically
   */
  autoFocus?: boolean;
  /**
   * If true, clicking the comment will not allow you to edit its comment
   */
  commentEditDisabled?: boolean;
  commentInterface: EditableTextInterface;
  initiallyEditingComment?: boolean;
  /**
   * Location to use as link to full donation page, accessed by clicking the
   * Read More link
   *
   * If not present, Read More link will not be rendered
   */
  donationPageLinkInfo: {
    url: string;
    data: ViewDonationData | null;
  } | null;
  shortDescription?: boolean;
}

type DonationCardProps = {
  nonprofit: ContextNonprofit;
  frequency?: DonationFrequency;
  donationId?: UUID;
  feedId?: UUID;
  /**
   * Show the nonprofit as a chip instead of as a card with header image.
   */
  useNonprofitChip?: boolean;
  createdAt: Date | null;
  isPending?: boolean;
  /**
   * If present, renders an element below the contents of the donation card,
   * like actions
   */
  footer?: React.ReactNode;
  hideNonprofitDescription?: boolean;
  boost?: DonationBoostResponse;
  donationToJoinId?: string;
  userToJoinId?: string;
  timesCharged?: number;
  className?: string;
  shortDescription?: boolean;
} & DonationCardCommentBodyProps &
  DonationHeaderCardProps;

const CommentContainer = styled.section`
  position: relative;
`;

const readMoreOverlayLinkCss = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

export const DonationCardCommentBody: React.FCC<
  DonationCardCommentBodyProps
> = ({
  commentInterface,
  commentEditDisabled,
  autoFocus,
  initiallyEditingComment,
  saveOnBlur,
  page,
  donationPageLinkInfo,
  shortDescription = false,
}) => {
  const { text } = commentInterface;
  const { setDonationData = null } = useContext(ViewDonationContext) || {};
  const textToDisplay = commentTextToDisplay(text);
  const isTextCutoff = text !== textToDisplay;

  const showReadMore =
    isTextCutoff && donationPageLinkInfo && page !== FeedPage.LANDING;

  const storeDonationDataInContext = useCallback(() => {
    if (!setDonationData || !donationPageLinkInfo?.data) {
      return;
    }
    setDonationData(donationPageLinkInfo.data);
  }, [donationPageLinkInfo?.data, setDonationData]);

  if (!text && commentEditDisabled) {
    return null;
  }

  return (
    <CommentContainer>
      {commentEditDisabled && // do not add link wrapper if comment should be editable
        donationPageLinkInfo && (
          <Link
            title={`Link to donation ${donationPageLinkInfo.url}`}
            css={readMoreOverlayLinkCss}
            appearance={LinkAppearance.UNSTYLED}
            data-tname="DonationCard--ReadMoreOverlay"
            data-action={ClickAction.DONATION}
            to={donationPageLinkInfo.url}
            onClick={storeDonationDataInContext}
            // disallow focus on this, user can still get here through the "Read more" link below
            tabIndex={-1}
          />
        )}
      <EditableText
        data-tname="DonationCard--EditableText"
        textToDisplay={`${textToDisplay}${isTextCutoff ? "..." : ""}`}
        editCommentCss={clickableCss}
        viewCommentCss={
          showReadMore
            ? css`
                /* Make links inside comment text clickable from under ReadMoreOverlayLink*/
                a {
                  ${clickableCss}
                }
              `
            : undefined
        }
        initiallyEditing={initiallyEditingComment}
        disabled={commentEditDisabled}
        editableTextInterface={commentInterface}
        // let the caller decide if autofocus is acceptable
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        saveOnBlur={saveOnBlur}
        textEnding={
          showReadMore &&
          donationPageLinkInfo && (
            <React.Fragment>
              {" "}
              <Link
                appearance={LinkAppearance.HYPERLINK}
                data-tname="DonationCard--ReadMore"
                data-action={ClickAction.DONATION}
                /* Make 'Read more' clickable from under ReadMoreOverlayLink*/
                css={[
                  clickableCss,
                  css`
                    display: inline;
                    white-space: nowrap;
                  `,
                ]}
                to={donationPageLinkInfo.url}
                onClick={storeDonationDataInContext}
              >
                Read more
              </Link>
            </React.Fragment>
          )
        }
        shortDescription={shortDescription}
      />
    </CommentContainer>
  );
};

/**
 * Card that displays a donation.
 */

export const DonationCard = (props: DonationCardProps) => {
  return (
    <ViewDonationProvider>
      <DonationCardImp {...props} />
    </ViewDonationProvider>
  );
};

const DonationCardImp: React.FCC<DonationCardProps> = (props) => {
  const {
    frequency,
    footer,
    hideNonprofitDescription = false,
    removeTimestamp,
    createdAt,
    isPending,
    feedId,
    donationId,
    donationToJoinId,
    userToJoinId,
    donationPageLinkInfo,
    className,
    ...rest
  } = props;

  const { setDonationData = null } = useContext(ViewDonationContext) || {};
  const storeDonationDataInContext = useCallback(() => {
    if (!setDonationData || !donationPageLinkInfo?.data) {
      return;
    }
    setDonationData(donationPageLinkInfo.data);
  }, [donationPageLinkInfo?.data, setDonationData]);

  return (
    <NewNonprofitCard
      causeCategoryBackgroundColor
      data-feed-feedid={feedId}
      data-feed-itemid={donationId}
      data-feed-itemtype={FeedItemType.USER_DONATION}
      nonprofit={rest.nonprofit}
      shorten={hideNonprofitDescription}
      className={className}
      userToJoinId={userToJoinId}
      customHeader={
        <div css={verticalStackCss.m}>
          <DonationHeaderCard
            {...rest}
            donationData={donationPageLinkInfo?.data}
            frequency={frequency}
            removeTimestamp={removeTimestamp}
            createdAt={createdAt}
            isPending={isPending}
          />
          <DonationCardCommentBody
            donationPageLinkInfo={donationPageLinkInfo}
            {...rest}
          />
        </div>
      }
      customLink={
        rest.commentInterface.text &&
        donationPageLinkInfo && (
          <Link
            title={`Feed donation card link to ${donationPageLinkInfo.url}`}
            className={FULL_SIZE_LINK_CLASS}
            data-tname={"feedDonationCardLink"}
            to={donationPageLinkInfo.url}
            onClick={storeDonationDataInContext}
          >
            Feed donation card link to ${donationPageLinkInfo.url}
          </Link>
        )
      }
      customFooter={footer}
    />
  );
};
