import * as t from "io-ts";

import {
  feedItemResponseCodec,
  nonprofitResponseCodec,
} from "../codecs/entities";
import { boundedIntFromString } from "../codecs/number";
import { HttpMethod } from "../helpers/http";

import { CAUSE_FEED_MAX_PAGE_NUM } from "./publicCached";

import { makeRouteSpec } from ".";

export const getNteeCategoryFeedRouteSpec = makeRouteSpec({
  path: "/ntee_category",
  method: HttpMethod.GET,
  authenticated: false,
  publicRoute: { publicCacheLengthMinutes: 1 },
  tokensCodec: t.type({}),
  paramsCodec: t.type({
    nteeCode: t.string,
    pageNum: boundedIntFromString(0, CAUSE_FEED_MAX_PAGE_NUM),
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    items: t.array(feedItemResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
  }),
});
