import React, { useReducer, createContext } from "react";

import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import { LikeableType } from "@every.org/common/src/entity/types";
import { normalizeSlug } from "@every.org/common/src/helpers/slugs";

import { dispatchLikesAction, LikesActionType } from "src/context/LikesContext";
import {
  getNonprofit,
  identifierToNormalizedString,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import {
  NonprofitActionType,
  NonprofitsAction,
  ContextNonprofit,
  NonprofitIdentifier,
  NonprofitSlug,
  NonprofitsState,
  NonprofitFetchStatus,
} from "src/context/NonprofitsContext/types";
import { logger } from "src/utility/logger";

const INITIAL_NONPROFIT_STATE: NonprofitsState = {
  nonprofitsById: new Map(),
  slugsToIds: new Map(),
  nonprofitFetchStatus: new Map(),
};

export let dispatchNonprofitsAction: React.Dispatch<NonprofitsAction>;

/**
 * Sets the status of a nonprofit fetch on the provided `nonprofitFetchStatus`
 * map; doesn't actually update the context, just does the operation to ensure
 * that `nonprofitFetchStatus` is used consistently.
 *
 * @returns The `nonprofitFetchStatus` parameter, mutated to include the change
 */
function setNonprofitFetchStatus(params: {
  nonprofitFetchStatus: NonprofitsState["nonprofitFetchStatus"];
  identifier: NonprofitIdentifier;
  status: NonprofitFetchStatus;
}): void {
  const { nonprofitFetchStatus, identifier, status } = params;
  nonprofitFetchStatus.set(
    identifierToNormalizedString({ identifier }),
    status
  );
}

/**
 * Add a slug to nonprofit ID mapping to the state. Doesn't actually update the
 * state directly, just modifies the provided `slugsToIds` object to ensure
 * consistency in usage.
 *
 * Mutates `slugsToIds` with the new mapping.
 */
function setSlugToNonprofitId(params: {
  slugsToIds: NonprofitsState["slugsToIds"];
  slug: string;
  nonprofitId: NonprofitResponse["id"];
}): void {
  params.slugsToIds.set(normalizeSlug(params), params.nonprofitId);
}

/**
 * Adds a nonprofit to the store of nonprofits, when one has been successfully
 * fetched.
 */
function updateStateWithNonprofit(params: {
  state: NonprofitsState;
  nonprofit: ContextNonprofit;
}): NonprofitsState {
  const { state, nonprofit } = params;
  const oldNonprofit = state.nonprofitsById.get(nonprofit.id);

  // Not all endpoints return donation/donor counts or fundraiser events, do not
  // lose this information if the client already had it.
  const nonprofitsById = new Map(state.nonprofitsById).set(nonprofit.id, {
    ...oldNonprofit,
    ...nonprofit,
    supporterCount: nonprofit.supporterCount || oldNonprofit?.supporterCount,
    endorsedNonprofitIds:
      nonprofit.endorsedNonprofitIds ||
      oldNonprofit?.endorsedNonprofitIds ||
      null,
    createdFundIds:
      nonprofit.createdFundIds || oldNonprofit?.createdFundIds || null,
    endorserNonprofitIds:
      nonprofit.endorserNonprofitIds ||
      oldNonprofit?.endorserNonprofitIds ||
      null,
    memberCount: nonprofit.memberCount ?? (oldNonprofit?.memberCount || null),
    loggedInUserMembership:
      nonprofit.loggedInUserMembership ??
      (oldNonprofit?.loggedInUserMembership || null),
    giftCardCampaign:
      nonprofit.giftCardCampaign || oldNonprofit?.giftCardCampaign,
    donationCount: nonprofit.donationCount || oldNonprofit?.donationCount,
  });

  const slugsToIds = new Map(state.slugsToIds);
  setSlugToNonprofitId({
    slugsToIds,
    slug: nonprofit.primarySlug,
    nonprofitId: nonprofit.id,
  });

  const nonprofitFetchStatus = new Map(state.nonprofitFetchStatus);
  setNonprofitFetchStatus({
    nonprofitFetchStatus,
    identifier: { id: nonprofit.id },
    status: NonprofitFetchStatus.FOUND,
  });
  setNonprofitFetchStatus({
    nonprofitFetchStatus,
    identifier: { slug: nonprofit.primarySlug },
    status: NonprofitFetchStatus.FOUND,
  });

  return { nonprofitsById, slugsToIds, nonprofitFetchStatus };
}

function registerSlugAlias(params: {
  state: NonprofitsState;
  slug: NonprofitSlug;
  nonprofitId: NonprofitResponse["id"];
}): NonprofitsState {
  const {
    slug,
    nonprofitId,
    state: {
      slugsToIds: oldSlugsToIds,
      nonprofitFetchStatus: prevNonprofitFetchStatus,
      ...restOfState
    },
  } = params;
  const slugsToIds = new Map(oldSlugsToIds).set(
    normalizeSlug(slug),
    nonprofitId
  );
  const nonprofitFetchStatus = new Map(prevNonprofitFetchStatus);
  setNonprofitFetchStatus({
    nonprofitFetchStatus,
    identifier: slug,
    status: NonprofitFetchStatus.FOUND,
  });
  return { ...restOfState, nonprofitFetchStatus, slugsToIds };
}

/**
 * Update fetched status of a nonprofit currently being fetched.
 */
function updateStateWithStatus(
  state: NonprofitsState,
  identifier: NonprofitIdentifier,
  status: NonprofitFetchStatus
): NonprofitsState {
  const { nonprofitFetchStatus: prevNonprofitFetchStatus, ...rest } = state;
  const nonprofit = nonprofitOrUndefined(getNonprofit(state, identifier));
  if (nonprofit) {
    return state;
  }
  const nonprofitFetchStatus = new Map(prevNonprofitFetchStatus);
  setNonprofitFetchStatus({
    nonprofitFetchStatus,
    identifier,
    status,
  });
  return {
    ...rest,
    nonprofitFetchStatus,
  };
}

/**
 * Add nonprofits data to the context.
 */
export function addNonprofits(nonprofits: ContextNonprofit[]) {
  if (!dispatchNonprofitsAction) {
    logger.warn({ message: "Dispatch is undefined for NonprofitsContext." });
  }
  dispatchNonprofitsAction({
    type: NonprofitActionType.ADD_NONPROFITS,
    data: nonprofits,
  });

  nonprofits.forEach((nonprofit) => {
    dispatchLikesAction({
      type: LikesActionType.FORCE_UPDATE,
      data: {
        type: LikeableType.NONPROFIT,
        id: nonprofit.id,
        loggedInUserLikes: nonprofit.likesInfo?.hasLoggedInUserLiked || false,
        likeCount: nonprofit.likesInfo?.count || 0,
      },
    });
  });
}

function addManyNonprofits(
  state: NonprofitsState,
  nonprofits: ContextNonprofit[]
) {
  return nonprofits.reduce(
    (curState, curNonprofit) =>
      updateStateWithNonprofit({
        state: curState,
        nonprofit: curNonprofit,
      }),
    state
  );
}

function nonprofitsReducer(
  state: NonprofitsState,
  action: NonprofitsAction
): NonprofitsState {
  switch (action.type) {
    case NonprofitActionType.FETCHING_NONPROFIT:
      return updateStateWithStatus(
        state,
        action.data,
        NonprofitFetchStatus.FETCHING_NONPROFIT
      );
    case NonprofitActionType.NONPROFIT_NOT_FOUND:
      return updateStateWithStatus(
        state,
        action.data,
        NonprofitFetchStatus.NONPROFIT_NOT_FOUND
      );
    case NonprofitActionType.ADD_NONPROFIT: {
      const nonprofit = action.data;
      return updateStateWithNonprofit({ state: state, nonprofit });
    }
    case NonprofitActionType.REGISTER_SLUG_ALIAS: {
      const { nonprofitId, slug } = action.data;
      return registerSlugAlias({ state, nonprofitId, slug });
    }
    case NonprofitActionType.ADD_NONPROFITS:
      return action.data.reduce(
        (curState, curNonprofit) =>
          updateStateWithNonprofit({
            state: curState,
            nonprofit: curNonprofit,
          }),
        state
      );
    default:
      throw new Error(`Nonprofit action with unknown type: ${action}`);
  }
}

export const NonprofitsContext = createContext<NonprofitsState>(
  INITIAL_NONPROFIT_STATE
);

export const NonprofitsProvider: React.FCC<{
  initialData?: ContextNonprofit[];
}> = ({ initialData, children }) => {
  const [nonprofitsState, nonprofitsDispatcher] = useReducer(
    nonprofitsReducer,
    initialData
      ? addManyNonprofits(INITIAL_NONPROFIT_STATE, initialData)
      : INITIAL_NONPROFIT_STATE
  );

  dispatchNonprofitsAction = nonprofitsDispatcher;

  return (
    <NonprofitsContext.Provider value={nonprofitsState}>
      {children}
    </NonprofitsContext.Provider>
  );
};
