import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
  ConfigVariableKey,
  ConfigVariableSpec,
} from "@every.org/common/src/entity/types/configVariable";
import { getConfigVariablesRouteSpec } from "@every.org/common/src/routes/publicCached";

import { queryApi } from "src/utility/apiClient";

type ConfigVariableState = { [key in ConfigVariableKey]?: unknown };
interface ConfigVariableContextData {
  configVariablesMap: ConfigVariableState;
  isLoading: boolean;
}

const ConfigVariableContext = createContext<ConfigVariableContextData>({
  configVariablesMap: {},
  isLoading: true,
});

export const ConfigVariableProvider: React.FCC = ({ children }) => {
  const [configVariablesMap, setConfigVariablesMap] =
    useState<ConfigVariableState>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      const result = await queryApi(getConfigVariablesRouteSpec, {
        routeTokens: {},
        queryParams: {},
        body: {},
      });

      const validConfigKeys = new Set<ConfigVariableKey>(
        Object.values(ConfigVariableKey)
      );
      const configVariables = Object.fromEntries(
        result.configVariables
          .filter(({ configKey }) => validConfigKeys.has(configKey))
          .map(({ configKey, configValue }) => [configKey, configValue])
      ) as ConfigVariableState;
      setConfigVariablesMap(configVariables);
      setIsLoading(false);
    }
    load();
  }, []);

  return (
    <ConfigVariableContext.Provider value={{ configVariablesMap, isLoading }}>
      {children}
    </ConfigVariableContext.Provider>
  );
};

export enum UseConfigVariableStatus {
  LOADING,
  SUCCESS,
  MISSING,
}
interface UseConfigVariableSuccess<ValueCodec extends t.Any> {
  status: UseConfigVariableStatus.SUCCESS;
  value: t.TypeOf<ValueCodec>;
}
interface UseConfigVariableNotReady {
  status: Exclude<UseConfigVariableStatus, UseConfigVariableStatus.SUCCESS>;
}

export function useConfigVariable<ValueCodec extends t.Any>(
  spec: ConfigVariableSpec<ValueCodec>
): UseConfigVariableSuccess<ValueCodec> | UseConfigVariableNotReady {
  const { configVariablesMap, isLoading } = useContext(ConfigVariableContext);
  if (isLoading) {
    return { status: UseConfigVariableStatus.LOADING };
  }
  const value = configVariablesMap[spec.configKey];
  if (value === undefined) {
    return { status: UseConfigVariableStatus.MISSING };
  }
  const decoded = spec.valueCodec.decode(value);
  if (isLeft(decoded)) {
    // log here there was a problem
    return { status: UseConfigVariableStatus.MISSING };
  }
  return { status: UseConfigVariableStatus.SUCCESS, value: decoded.right };
}
