import BezierEasing from "bezier-easing";
import { useEffect, useState } from "react";
import scrollLib from "scroll";

import { useComponentSize } from "src/hooks/useComponentSize";
import { MediaSize, useMatchesScreenSize } from "src/theme/mediaQueries";

/**
 * Automatic scroll speed in pixels per second
 */
const SCROLL_SPEED = 50;

/**
 * When reached the end, scroll time back to the top in seconds
 */
const SCROLL_TO_TOP_DURATION = 4;

/**
 * Timeout in seconds before auto-scrolling initially starts
 */
const TIME_BEFORE_INITIAL_SCROLL = 0;

/**
 * Timeout in seconds to pause at the end before scrolling back to the start
 */
const TIME_TO_PAUSE_AT_END = 3;

/**
 * Timeout in seconds of inactivity before auto-scrolling restarts
 */
const USER_INTERACTION_TIMEOUT = 7;

/**
 * Hook that handles animating the feed scrolling container automatically
 */
export const useInfiniteScrollFeed = () => {
  // disable no-restricted-syntax - this hook is only relivant for client-side renders
  // eslint-disable-next-line no-restricted-syntax
  const disabled = useMatchesScreenSize({ max: MediaSize.SMALL });
  const {
    ref: containerRef,
    element: containerElem,
    size: containerSize,
    // eslint-disable-next-line no-restricted-syntax
  } = useComponentSize();
  const containerHeight = containerSize?.height || null;
  const {
    ref: contentRef,
    element: contentElem,
    size: contentSize,
    // eslint-disable-next-line no-restricted-syntax
  } = useComponentSize();
  const contentHeight = contentSize?.height || null;
  /**
   * Scroll cancellation function direct from library, acting as a handle to the
   * particular scroll trigger
   */
  const [cancelScrollFn, setCancelScrollFn] = useState<() => void>();

  /**
   * Boolean enabling a delay at the start before auto scrolling
   */
  const [initialScrollTriggered, setInitialScrollTriggered] = useState(false);

  /**
   * If the user scrolled, this is set to true
   */
  const [userInteractionTimeout, setUserInteractionTimeout] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if ((disabled || userInteractionTimeout) && cancelScrollFn) {
      cancelScrollFn();
      setCancelScrollFn(undefined);
    }
  }, [disabled, userInteractionTimeout, cancelScrollFn]);

  useEffect(() => {
    if (
      disabled ||
      userInteractionTimeout ||
      !containerElem ||
      !contentHeight ||
      !containerHeight ||
      contentHeight < containerHeight // no need to scroll
    ) {
      return;
    }

    if (!initialScrollTriggered) {
      setTimeout(
        () => setInitialScrollTriggered(true),
        TIME_BEFORE_INITIAL_SCROLL * 1000
      );
      return;
    }

    const { scrollHeight: scrollableHeight, scrollTop: curScrollPos } =
      containerElem;

    if (scrollableHeight <= containerHeight) {
      // no need to scroll, can fit in box
      return;
    }

    const endPosition = scrollableHeight - containerHeight;
    // subtract container height because doesn't need to go further to display
    // all content on screen
    const duration = (endPosition - curScrollPos) / (SCROLL_SPEED / 1000);

    const cancel = scrollLib.top(
      containerElem,
      endPosition,
      { duration, ease: BezierEasing(0.05, 0, 0.95, 1) },
      (err, scrollTop) => {
        containerElem.removeEventListener("wheel", userScrollHandler);
        containerElem.removeEventListener("touchmove", userScrollHandler);
        if (!err) {
          // if it really reached the end of the animation
          setTimeout(() => {
            scrollLib.top(
              containerElem,
              0,
              { duration: SCROLL_TO_TOP_DURATION * 1000 },
              () => {
                // set the initial timeout again to trigger animation to repeat
                setInitialScrollTriggered(false);
              }
            );
          }, TIME_TO_PAUSE_AT_END * 1000);
        }
      }
    );
    setCancelScrollFn((prevCancel) => {
      if (prevCancel) {
        prevCancel();
      }
      return cancel;
    });

    function userScrollHandler() {
      // scroll cancel happens when this effect is retriggered on the state
      // change
      setUserInteractionTimeout(
        setTimeout(() => {
          setUserInteractionTimeout(null);
        }, USER_INTERACTION_TIMEOUT * 1000)
      );
    }
    containerElem.addEventListener("wheel", userScrollHandler, {
      passive: true,
    });
    containerElem.addEventListener("touchmove", userScrollHandler, {
      passive: true,
    });
    return () => {
      containerElem.removeEventListener("wheel", userScrollHandler);
      containerElem.removeEventListener("touchmove", userScrollHandler);
    };
  }, [
    containerElem,
    containerHeight,
    contentHeight,
    disabled,
    initialScrollTriggered,
    userInteractionTimeout,
  ]);
  return { containerRef, containerElem, contentRef, contentElem };
};
