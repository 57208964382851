import Script from "next/script";
import { Fragment } from "react";

import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export const FacebookPixel = () => (
  <Fragment>
    <Script id="facebook-pixel" strategy="worker">
      {`
      if (typeof fbq === "undefined") {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.defer = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "${assertEnvPresent(
          process.env.NEXT_PUBLIC_FB_PIXEL_ID,
          "NEXT_PUBLIC_FB_PIXEL_ID"
        )}");
        fbq("track", "PageView");
      }`}
    </Script>
    <noscript>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        height="1"
        width="1"
        style={{ display: "none" }}
        src={`https://www.facebook.com/tr?id=${assertEnvPresent(
          process.env.NEXT_PUBLIC_FB_PIXEL_ID,
          "NEXT_PUBLIC_FB_PIXEL_ID"
        )}&ev=PageView&noscript=1"`}
      />
    </noscript>
  </Fragment>
);
