import { Loading } from "@components/LoadingIndicator";
import { DonationCardActionsProps } from "@components/feed/DonationCardActions";
import { FeedDonationCard } from "@components/feed/FeedDonationCard";
import { FeedFundraiserCard } from "@components/feed/FeedFundraiserCard";
import { FeedNonprofitCard } from "@components/feed/FeedNonprofitCard";
import {
  FeedNonprofitRecommendationsCard,
  RecommendationsCardType,
} from "@components/feed/FeedNonprofitRecommendationCard";
import { FundFeedDonationCard } from "@components/feed/FundFeedDonationCard";
import { CauseFeedNonprofitCard as NewCauseFeedNonprofitCard } from "@components/feed/NewCauseFeedNonprofitCard";
import { FeedCreatedFundCard } from "@components/feed/funds/FeedCreatedFundCard";
import { getFeedItemId } from "@components/feed/shared";
import { FeedResponse } from "@components/feed/types";
import {
  MasonryList,
  MasonryListProps,
  MasonryListItem,
} from "@components/layout/MasonryList";
import { SkeletonDonationCard } from "@components/skeleton/SkeletonDonationCard";
import { css } from "@emotion/react";
import composeRefs from "@seznam/compose-react-refs";
import { useRouter } from "next/router";
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useMemo,
  useContext,
} from "react";
import { MasonryProps } from "react-masonry-css";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import {
  FeedItemType,
  FeedItemRecommendationReason,
  FeedPage,
} from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";
import {
  ListParams,
  SkipInt,
  TakeInt,
  LIST_PARAMS_TAKE_MAX,
  LIST_PARAMS_SKIP_MAX,
} from "@every.org/common/src/routes/index";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { UsersContext } from "src/context/UsersContext";
import { FETCHING_USER, USER_NOT_FOUND } from "src/context/UsersContext/types";
import { ApiError } from "src/errors/ApiError";
import { MediaSize, useMatchesScreenSize } from "src/theme/mediaQueries";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

/**
 * Because the homefeed is not designed to be deterministic, the same item can
 * result in different rankings between fetches. Overlap the items fetched and
 * dedupe later to improve likelihood that high-ranking results don't get lost
 */
const NUM_EARLIER_RESULTS_TO_CHECK = 3;

/**
 * Let's keep a number in between for mobile and desktop
 */
const NUM_FETCH = 20;

interface CustomFeedItem {
  index: number;
  content: React.ReactNode;
}

/**
 * This is a short-term hack so that we feed nice information to Aesthetic
 * so they can create beautiful social posts for user profiles.
 *
 * Here is an example of what gets appended to the html <head> section, note
 * that it's not actually modifying the current component.
 * <script type="application/ld+json">
 * {
 *   "@context": "https://schema.org/",
 *   "@type": "Person",
 *   "@id": "https://www.every.org/@timferriss",
 *   "image": "https://res.cloudinary.com/everydotorg/image/upload/foo0.jpg",
 *   "callSign": "@timferriss",
 *   "name": "Tim Ferriss",
 *   "funder": [
 *     {
 *       "@type": "Organization",
 *       "@id": "https://www.every.org/MAPS",
 *       "name": "MAPS",
 *       "image": "https://res.cloudinary.com/everydotorg/image/upload/foo1.jpg"
 *     },
 *     {
 *       "@type": "Organization",
 *       "@id": "https://www.every.org/amazonteam",
 *       "name": "Amazon Conservation Team",
 *       "image": "https://res.cloudinary.com/everydotorg/image/upload/foo2.jpg"
 *     }
 *   ]
 * }
 * </script>
 *
 * As we only do it if the user agent includes Aesthetic risk is low.
 * Would be nice to have better code that is handled by NextJS on
 * the server-side and at a higher level, quite bizarre to handle
 * this inside of a feed component. Should use some proper library like react-head
 * same as we do for other metadata, though looks like perhaps we have to change
 * libraries to something like react-helmet-async.
 * Then we can always include it with confidence :)
 */
function useJsonLinkedData(feedPage: FeedPage, items: FeedItemResponse[]) {
  const usersState = useContext(UsersContext);
  const nonprofitsState = useContext(NonprofitsContext);
  useEffect(() => {
    const window = getWindow();
    if (!window || feedPage !== FeedPage.USER_PROFILE || !items?.length) {
      return;
    }
    const { document, navigator } = window;
    // Once code is way better, can consider always including.
    if (!navigator.userAgent.includes("Aesthetic")) {
      return;
    }
    const example = items[0];
    if (example.type !== FeedItemType.USER_DONATION) {
      return;
    }
    const user = usersState.usersById.get(example.donationCharge.fromUserId);
    if (
      !user ||
      user === FETCHING_USER ||
      user === USER_NOT_FOUND ||
      !user.username ||
      !user.profileImageCloudinaryId
    ) {
      return;
    }
    // I don't think we are actually using this correct, "funder" is probably
    // supposed to mean the entity providing funding, not the one being funded.
    // But suggested by Aesthetic which is only party relying on this.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const funder: any[] = [];
    for (const item of items) {
      if (item.type !== FeedItemType.USER_DONATION) {
        return;
      }
      const nonprofit = nonprofitsState.nonprofitsById.get(
        item.donationCharge.toNonprofitId
      );
      if (!nonprofit) {
        return;
      }
      if (!nonprofit.logoCloudinaryId) {
        continue;
      }
      funder.push({
        "@type": "Organization",
        "@id": getRoutePath({
          name: ClientRouteName.NONPROFIT_OR_CAUSE,
          tokens: {
            nonprofitSlug: nonprofit.primarySlug,
          },
          format: URLFormat.ABSOLUTE,
        }),
        name: nonprofit.name,
        image: constructCloudinaryUrl({
          cloudinaryId: nonprofit.logoCloudinaryId,
          skipTransforms: true,
          dimensions: { width: 0 },
        }),
      });
    }
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Person",
      "@id": getRoutePath({
        name: ClientRouteName.USER,
        tokens: {
          username: user.username,
        },
        format: URLFormat.ABSOLUTE,
      }),
      image: constructCloudinaryUrl({
        cloudinaryId: user.profileImageCloudinaryId,
        skipTransforms: true,
        dimensions: { width: 0 },
      }),
      callSign: user.username,
      name: getUserFullNameOrPlaceholder(user),
      funder,
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [feedPage, items, nonprofitsState.nonprofitsById, usersState.usersById]);
}

function cardHeaderTextForFeed(
  feedPage: FeedPage,
  item: FeedItemResponse,
  nonprofit?: ContextNonprofit
) {
  switch (feedPage) {
    case FeedPage.USER_LIKES:
      return "Liked in";
    case FeedPage.HOME:
      return "Picked for you in";
    case FeedPage.RELATED_NONPROFITS:
      switch (item.reason) {
        case FeedItemRecommendationReason.NONPROFIT_DESCRIPTION:
          return "Related mission";
        case FeedItemRecommendationReason.NONPROFIT_SHARED_SUPPORTERS:
          return "People also support";
        case FeedItemRecommendationReason.NONPROFIT_SIMILAR_DONATIONS:
          // More accurate would be to say that they have a similar
          // supporters base according to collaborative filtering, saying
          // this hopefully gets at the right idea.
          return "Similar people like";
        case FeedItemRecommendationReason.NONPROFIT_ENDORSED:
          return `${nonprofit ? nonprofit.name : "This nonprofit"} recommends`;
      }
    // Allow fall through, use the "Found in" default if unrecognized reason
    // eslint-disable-next-line no-fallthrough
    default:
      return "Found in";
  }
}

/**
 * Renders a feed item as a card in the feed
 */
const FeedItemContent: React.FCC<{
  item: FeedItemResponse;
  hideActions?: boolean;
  page: FeedPage;
  onDonationArchived: DonationCardActionsProps["onDonationArchived"];
  feedOwnerNonprofit?: ContextNonprofit;
}> = ({ item, hideActions, page, onDonationArchived, feedOwnerNonprofit }) => {
  const nonprofitsState = useContext(NonprofitsContext);

  switch (item.type) {
    case FeedItemType.USER_DONATION:
      if (page === FeedPage.FUND) {
        return <FundFeedDonationCard feedId={item.feedId} item={item} />;
      }
      return (
        <FeedDonationCard
          feedId={item.feedId}
          item={item}
          hideActions={hideActions}
          page={page}
          onDonationArchived={onDonationArchived}
        />
      );
    case FeedItemType.NONPROFIT_RECOMMENDATION:
      if (page === FeedPage.TAG_NONPROFITS) {
        return <NewCauseFeedNonprofitCard feedId={item.feedId} item={item} />;
      }
      if (page === FeedPage.HOME) {
        const nonprofit = nonprofitOrUndefined(
          getNonprofit(nonprofitsState, {
            id: item.nonprofitId,
          })
        );
        if (
          nonprofit?.supporterInfo?.loggedInUserSupported ||
          nonprofit?.likesInfo?.hasLoggedInUserLiked
        ) {
          return (
            <FeedNonprofitRecommendationsCard
              feedId={item.feedId}
              nonprofit={nonprofit}
              headerText={cardHeaderTextForFeed(page, item)}
              type={
                nonprofit?.supporterInfo?.loggedInUserSupported
                  ? RecommendationsCardType.SUPPORTED
                  : RecommendationsCardType.LIKED
              }
            />
          );
        }
        return (
          <FeedNonprofitCard
            feedId={item.feedId}
            headerText={cardHeaderTextForFeed(page, item)}
            nonprofitId={item.nonprofitId}
          />
        );
      }

      return (
        <FeedNonprofitCard
          feedId={item.feedId}
          headerText={cardHeaderTextForFeed(page, item, feedOwnerNonprofit)}
          hideCauseText={page === FeedPage.RELATED_NONPROFITS}
          nonprofitId={item.nonprofitId}
        />
      );
    case FeedItemType.CREATED_FUND:
      return <FeedCreatedFundCard feedId={item.feedId} item={item} />;
    case FeedItemType.FUNDRAISER:
      return (
        <FeedFundraiserCard feedId={item.feedId} fundraiser={item.fundraiser} />
      );
  }
};

/**
 * Offset in px before hitting bottom of feed to trigger items to load
 */
const SCROLL_OFFSET_FOR_LOAD = 2000;

export type TransformMasonryItemsFn = (params: {
  items: MasonryListProps["items"];
}) => MasonryListProps["items"];

export interface FeedDisplayProps
  extends Pick<
    FeedProps,
    | "placeholder"
    | "noItemsDisplay"
    | "feedHeader"
    | "feedAfterItemsContent"
    | "page"
    | "className"
    | "transformMasonryItems"
    | "hideFeedItemActions"
    | "customItems"
    | "showSkeleton"
    | "excludeDonationIds"
    | "nonprofit"
    | "sortChronologically"
  > {
  feedItems: FeedItemResponse[];
  moreItemsToFetch: boolean;
  fetchMoreItems: () => void;
  fetching: boolean;
  transformMasonryItems?: TransformMasonryItemsFn | TransformMasonryItemsFn[];
  hideFeedItemActions?: boolean;
  onDonationArchived: DonationCardActionsProps["onDonationArchived"];
  customItems?: CustomFeedItem[];
  sortChronologically?: boolean;
  numColumns?: MasonryProps["breakpointCols"];
  isScrollFeed?: boolean;
}

/**
 * Merges fetched feed items with any custom items passed to the feed.
 * @param items
 * @param customItems
 */
function injectCustomItems(
  items: MasonryListItem[],
  fetching: boolean,
  customItems?: CustomFeedItem[]
): MasonryListItem[] {
  if (!customItems) {
    return items;
  }

  // A little bit hacky, but this stops custom items from showing up before the
  // rest of the feed has loaded.
  if (items.length === 0 && fetching) {
    return items;
  }

  customItems.forEach(({ index, content: elem }) => {
    index < items.length &&
      items.splice(index, 0, { key: `custom-${index}`, elem });
  });

  return items;
}

/**
 * Encapsulates display logic for the feed, with business logic residing in the
 * main `Feed` component
 */
const FeedDisplay = forwardRef<HTMLDivElement, FeedDisplayProps>(
  function FeedDisplayWithRef(
    {
      className,
      feedItems,
      fetchMoreItems,
      fetching,
      moreItemsToFetch,
      placeholder,
      noItemsDisplay,
      feedHeader,
      feedAfterItemsContent,
      transformMasonryItems,
      hideFeedItemActions,
      onDonationArchived,
      page,
      nonprofit: feedOwnerNonprofit,
      customItems,
      showSkeleton = true,
      numColumns,
      isScrollFeed,
    },
    ref
  ) {
    const isLargeScreen =
      // eslint-disable-next-line no-restricted-syntax
      useMatchesScreenSize({ min: MediaSize.MEDIUM }) ?? false;
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const wrapperElem = wrapperRef.current;
      const window = getWindow();
      if (!window || !wrapperElem) {
        return;
      }
      const scrollEventListener = () => {
        if (
          !fetching &&
          moreItemsToFetch &&
          wrapperElem &&
          window.scrollY + window.innerHeight + SCROLL_OFFSET_FOR_LOAD >=
            wrapperElem.clientHeight + wrapperElem.offsetTop
        ) {
          fetchMoreItems();
        }
      };
      isScrollFeed && isLargeScreen
        ? wrapperElem.addEventListener("scroll", scrollEventListener)
        : window.addEventListener("scroll", scrollEventListener);

      return () => {
        isScrollFeed && isLargeScreen
          ? wrapperElem.removeEventListener("scroll", scrollEventListener)
          : window.removeEventListener("scroll", scrollEventListener);
      };
    }, [
      fetchMoreItems,
      fetching,
      wrapperRef,
      moreItemsToFetch,
      isScrollFeed,
      isLargeScreen,
    ]);

    const items = useMemo(() => {
      const transformFns = transformMasonryItems
        ? transformMasonryItems instanceof Array
          ? transformMasonryItems
          : [transformMasonryItems]
        : [];
      const masonryItems = injectCustomItems(
        feedItems.map((item) => ({
          key: getFeedItemId(item),
          elem: (
            <FeedItemContent
              feedOwnerNonprofit={feedOwnerNonprofit}
              onDonationArchived={onDonationArchived}
              item={item}
              hideActions={hideFeedItemActions}
              page={page}
            />
          ),
        })),
        fetching,
        customItems
      );
      return transformFns.reduce(
        (items, transform) => transform({ items }),
        masonryItems
      );
    }, [
      customItems,
      feedItems,
      fetching,
      hideFeedItemActions,
      onDonationArchived,
      feedOwnerNonprofit,
      page,
      transformMasonryItems,
    ]);
    useJsonLinkedData(page, feedItems);
    const fetchingItemsDisplay = useMemo(() => {
      const placeholderItems = new Array(10).fill(null).map((_, index) => ({
        key: `${index}`,
        elem: <SkeletonDonationCard />,
      }));

      return [...items, ...placeholderItems];
    }, [items]);

    const loggedInUserId = useLoggedInUserOrUndefined()?.id;
    if (!fetching && items.length === 0) {
      return moreItemsToFetch ? placeholder || null : noItemsDisplay || null;
    }

    return (
      <div
        data-feed-page={page}
        data-feed-user={loggedInUserId}
        css={[
          { width: "100%" },
          isScrollFeed &&
            css`
              overflow-y: scroll;
              scrollbar-width: none; /* firefox */
              &::-webkit-scrollbar {
                /* safari, chrome, opera */
                width: 0;
                height: 0;
              }
            `,
        ]}
        className={className}
        ref={composeRefs(ref, wrapperRef)}
      >
        {feedHeader}
        <MasonryList
          items={fetching && showSkeleton ? fetchingItemsDisplay : items}
          numColumns={numColumns}
        />
        {fetching && <Loading />}
        {!fetching &&
          !showSkeleton &&
          items.length > 0 &&
          feedAfterItemsContent}
      </div>
    );
  }
);

const optimizeFeedItemsOnFirstRender = (
  feedItems: FeedItemResponse[],
  fetchedPageNum: number | undefined,
  hasMore: boolean,
  firstRenderItemsAmount: number
) => {
  // If we don't have more items to fetch always return everything
  if (!hasMore) {
    return feedItems;
  }

  // If it's the first page of result only show the first 6
  // When the user scroll down will fetch another page and we will show all the results
  if (fetchedPageNum === 0) {
    return feedItems.slice(0, firstRenderItemsAmount);
  }

  return feedItems;
};

export type NoMoreFeedItemsCallbackProp = {
  onNoMoreItems?: () => void;
};

export type FeedProps = NoMoreFeedItemsCallbackProp & {
  className?: string;
  initialItems?: FeedItemResponse[];
  initialHasMore?: boolean;
  /**
   * Function to load more items into the feed
   */
  loadMoreItems: (params: ListParams) => Promise<FeedResponse>;
  /**
   * Number of items to take with each fetch. This number will be added to
   * NUM_EARLIER_RESULTS_TO_CHECK when actually fetching more data.
   */
  take?: number;
  /**
   * Placeholder to display while data isn't loaded yet
   */
  placeholder?: React.ReactElement;
  /**
   * If there are no items, display this instead of nothing
   */
  noItemsDisplay?: React.ReactElement;
  /**
   * Power feature to allow replacing the component that actually displays the
   * feed with a custom one, turning this Feed component into an entirely
   * business logic one
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FeedDisplayComponent?: typeof FeedDisplay;
  /**
   * Power feature to allow transforming the list of items to be fed to the
   * underlying `MasonryList`, like inserting elements or modifying how they're
   * displayed
   */
  transformMasonryItems?: FeedDisplayProps["transformMasonryItems"];

  /**
   * Hides actions available on feed items if true
   */
  hideFeedItemActions?: boolean;

  /**
   * The page that this feed is appearing on. Affects card style.
   */
  page: FeedPage;

  /**
   * Custom items that will be injected into the feed at the index they
   * specify.
   */
  customItems?: CustomFeedItem[];

  showSkeleton?: boolean;

  /**
   * Optional Set of donation IDs to exclude.
   */
  excludeDonationIds?: Set<string>;

  /**
   * If true, only loads the feed once and doesn't load more pages of results
   * @default false
   */
  disableLoadMoreItems?: boolean;

  feedHeader?: React.ReactNode;
  /**
   * Content to show after the feed, but only if feed items are present
   */
  feedAfterItemsContent?: React.ReactNode;
  /*
   * Flag to tell the custom feed display component to show the feed in
   * chronological order
   */
  sortChronologically?: boolean;
  /**
   * Override number of columns to display
   */
  numColumns?: MasonryProps["breakpointCols"];
  /**
   * Number of items to show on the first render
   */
  firstRenderItemsAmount?: number;
  isScrollFeed?: boolean;
} & (
    | {
        /**
         * The page that this feed is appearing on. Affects card style.
         */
        page: Exclude<
          FeedPage,
          FeedPage.RECENT_CONTRIBUTORS | FeedPage.RELATED_NONPROFITS
        >;
        nonprofit?: ContextNonprofit;
      }
    | {
        page: FeedPage.RECENT_CONTRIBUTORS | FeedPage.RELATED_NONPROFITS;
        nonprofit: ContextNonprofit;
      }
  );

export const Feed = forwardRef<HTMLDivElement, FeedProps>(function FeedWithRef(
  {
    initialItems = [],
    initialHasMore,
    loadMoreItems,
    take,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FeedDisplayComponent: OverrideFeedDisplay,
    onNoMoreItems,
    disableLoadMoreItems,
    numColumns: numColumnsProp,
    firstRenderItemsAmount = 6,
    isScrollFeed,
    ...rest
  },
  ref
) {
  const isFirstPageFetchedFromSSR = Boolean(initialItems.length);
  const [feedItems, setFeedItems] = useState<FeedItemResponse[]>(initialItems);
  const [moreItemsToFetch, setMoreItemsToFetch] = useState(
    initialHasMore !== undefined ? initialHasMore : true
  );
  const [pageNum, setPageNum] = useState(isFirstPageFetchedFromSSR ? 1 : 0);
  const [fetching, setFetching] = useState(false);
  const pageSize = take ?? NUM_FETCH;
  // If we have initialItems then we are already on a certain page of results
  const initialPageNum = isFirstPageFetchedFromSSR ? 0 : undefined;
  const [fetchedPageNum, setFetchedPageNum] = useState(initialPageNum);
  const FeedDisplayComponent = OverrideFeedDisplay || FeedDisplay;

  const { query } = useRouter();
  const numColumns = numColumnsProp
    ? numColumnsProp
    : query.viewport === "mobile"
    ? 1
    : query.viewport === "tablet"
    ? 2
    : undefined;

  useEffect(() => {
    setFetchedPageNum(initialPageNum); // reset if new load more fn
  }, [loadMoreItems, initialPageNum]);

  const onDonationArchived: DonationCardActionsProps["onDonationArchived"] =
    useCallback((donationCharge) => {
      setFeedItems((curFeedItems) =>
        curFeedItems.filter(
          (i) =>
            i.type !== FeedItemType.USER_DONATION ||
            donationCharge.donation.id !== i.donationCharge.donation.id
        )
      );
    }, []);

  useEffect(() => {
    if (!moreItemsToFetch) {
      onNoMoreItems && onNoMoreItems();
    }
  }, [moreItemsToFetch, onNoMoreItems]);

  const loadMore = useCallback(
    async ({
      feedItems,
      pageNum,
    }: {
      feedItems: FeedItemResponse[];
      pageNum: number;
    }) => {
      if (
        !pageSize ||
        (disableLoadMoreItems && fetchedPageNum !== undefined) ||
        (fetchedPageNum && fetchedPageNum >= pageNum)
      ) {
        return;
      }
      try {
        setFetching(true);
        setFeedItems(feedItems);
        const startIndex = Math.max(
          feedItems.length - NUM_EARLIER_RESULTS_TO_CHECK,
          0
        );
        const { items, hasMore } = await loadMoreItems({
          skip: Math.min(startIndex, LIST_PARAMS_SKIP_MAX) as SkipInt,
          // Because our feeds are nondeterministic, always grab enough to be
          // at where you *wish* you were, so that as more duplicate items are
          // returned we take more.
          take: Math.min(
            (pageNum + 1) * pageSize - startIndex,
            LIST_PARAMS_TAKE_MAX
          ) as TakeInt,
        });
        const feedItemIds = new Set(feedItems.map(getFeedItemId));
        const mergedFeedItems = [
          ...feedItems,
          ...items.filter((i) => !feedItemIds.has(getFeedItemId(i))),
        ];
        setPageNum(pageNum + 1);
        setFeedItems(mergedFeedItems);
        setMoreItemsToFetch(hasMore);
        setFetchedPageNum((prevFetchedPageNum) =>
          Math.max(prevFetchedPageNum ?? -1, pageNum)
        );
      } catch (error) {
        setMoreItemsToFetch(false); // stops retries
        if (
          !(error instanceof ApiError) ||
          error.httpStatus !== HttpStatus.FORBIDDEN
        ) {
          logger.error({
            message: "An error occurred fetching items for the feed.",
            error,
          });
        }
      } finally {
        setFetching(false);
      }
    },
    [fetchedPageNum, loadMoreItems, disableLoadMoreItems, pageSize]
  );

  // initialize feed
  useEffect(() => {
    if (fetchedPageNum === undefined) {
      loadMore({ feedItems: [], pageNum: 0 });
    }
  }, [fetchedPageNum, loadMore]);

  const fetchMoreItems = useCallback(() => {
    loadMore({ feedItems, pageNum });
  }, [feedItems, loadMore, pageNum]);

  return (
    <FeedDisplayComponent
      {...rest}
      feedItems={optimizeFeedItemsOnFirstRender(
        feedItems,
        fetchedPageNum,
        moreItemsToFetch,
        firstRenderItemsAmount
      )}
      moreItemsToFetch={moreItemsToFetch}
      fetchMoreItems={fetchMoreItems}
      fetching={fetching}
      onDonationArchived={onDonationArchived}
      ref={ref}
      numColumns={numColumns}
      isScrollFeed={isScrollFeed}
    />
  );
});
