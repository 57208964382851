export const CAUSES_SEPARATOR = "+";

// URLSearchParams encodes "+" as "%2B"
export function encodeCausesUrlQuery(url: string): string {
  return url.replace(/%2B/g, CAUSES_SEPARATOR);
}

// URLSearchParams decodes "+" as space symbol
export function decodeCausesUrlQuery(encodedString?: string | null) {
  return (
    encodedString
      ?.replace(/\s/g, CAUSES_SEPARATOR)
      // backwards compatibility
      .replace(/,/g, CAUSES_SEPARATOR)
  );
}

export function splitCauses(causes?: string): string[] | undefined {
  return causes?.split(CAUSES_SEPARATOR);
}

export function joinCauses(causes?: string[]): string | undefined {
  return causes?.join(CAUSES_SEPARATOR);
}
