/**
 * Card that displays a fundraiser in a feed.
 */
import { FundraiserCard as NewFundraiserCard } from "@components/NewFundraiserCard";
import { UUID } from "io-ts-types/UUID";
import React, { useContext } from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { logger } from "src/utility/logger";

interface FeedFundraiserCardProps {
  fundraiser: FundraiserResponse;
  feedId?: UUID;
}

/**
 * "Suggested nonprofit" card in a feed.
 */
export const FeedFundraiserCard: React.FCC<FeedFundraiserCardProps> =
  React.memo(function FeedFundraiserCardImpl({ fundraiser, feedId }) {
    const nonprofitsState = useContext(NonprofitsContext);
    const nonprofit = nonprofitOrUndefined(
      getNonprofit(nonprofitsState, {
        id: fundraiser.nonprofitId,
      })
    );

    if (!nonprofit) {
      logger.error({
        message: "Nonprofit context missing nonprofit in FeedFundraiserCard",
        data: { fundraiserId: fundraiser.id },
      });
      return <React.Fragment />;
    }

    return (
      <NewFundraiserCard
        fundraiser={fundraiser}
        nonprofit={nonprofit}
        data-feed-feedid={feedId}
        data-feed-itemid={fundraiser.id}
        data-feed-itemtype={FeedItemType.FUNDRAISER}
      />
    );
  });
