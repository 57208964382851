import { useEffect } from "react";

import {
  ClientRouteName,
  getRoutePath,
  GetRoutePathParams,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { useEdoRouter } from "src/hooks/useEdoRouter";
import { buildUrlPath } from "src/utility/url";

/**
 * Get route path of login or signup page depending on presence of UTM medium
 * parameter
 *
 * TODO: should be more thoughtful about this. If someone has a
 * $initial_referrer (search MixPanel docs) url with a utm_medium parameter,
 * assume someone is hitting a link from email or notification and is an
 * existing user so should go to log in. If someone has a cookie and thinks they
 * should be logged in, then we should redirect them to log in (see #2337 client
 * side auth context).
 *
 * This only works if first page they land on requires auth, we should be
 * looking at the initial referrer link.
 *
 * We just switched it to LOGIN for all
 *  name: new URLSearchParams(router.search).get(UtmQueryParam.UTM_MEDIUM)
 *    ? ClientRouteName.LOGIN
 *    : ClientRouteName.SIGNUP,
 */
export function useLoginOrSignUpPath({
  message,
  condensed,
  ...rest
}: Omit<GetRoutePathParams, "name" | "query"> & {
  message?: string;
  condensed?: boolean;
}) {
  const router = useEdoRouter();
  return getRoutePath({
    ...rest,
    name: ClientRouteName.LOGIN,
    query: {
      redirectUrl: buildUrlPath(router),
      ...(message ? { message } : {}),
      ...(condensed === undefined ? {} : { condensed: condensed.toString() }),
    },
  });
}

interface RedirectProps {
  /**
   * Where to redirect to
   */
  to: string;
  /**
   * If true, creates a new entry in history instead of just replacing the
   * current one
   */
  push?: boolean;
}

/**
 * Platform independent component that redirects to a given url.
 *
 * Does not cause nextJS to generate an actual 301/302 redirect; for that:
 *
 * - for build-time redirects, use `next.config.js`: https://nextjs.org/docs/api-reference/next.config.js/redirects
 * - for runtime redirects, use the nextjsRedirect function in
 *   `getServerSideProps` in the website-next package.
 */
export function Redirect({ to, push = false }: RedirectProps) {
  const router = useEdoRouter();
  useEffect(() => {
    (push ? router.push : router.replace)(to);
  }, [push, router.push, router.replace, to]);
  return null;
}

/**
 * By default, many people coming to site will not have an account so take
 * to sign up (or log in if we are confident they have account).
 */
export function LogInOrSignUpRedirect() {
  const url = useLoginOrSignUpPath({ format: URLFormat.RELATIVE });
  return <Redirect to={url} />;
}
