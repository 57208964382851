import { PersonalDonationResponse } from "@every.org/common/src/codecs/entities";

export enum MyDonationsActionType {
  ADD_DONATION = "ADD_RECURRING_DONATION",
  ADD_DONATIONS = "ADD_RECURRING_DONATIONS",
  UPDATE_DONATION = "UPDATE_RECURRING_DONATION",
  CANCEL_DONATION = "CANCEL_RECURRING_DONATION",
  SET_LOADED = "SET_LOADED",
}

interface AddDonationAction {
  type: MyDonationsActionType.ADD_DONATION;
  data: PersonalDonationResponse;
}
interface AddDonationsAction {
  type: MyDonationsActionType.ADD_DONATIONS;
  data: PersonalDonationResponse[];
}
interface SetDonationAction {
  type: MyDonationsActionType.UPDATE_DONATION;
  data: PersonalDonationResponse;
}
interface CancelDonationAction {
  type: MyDonationsActionType.CANCEL_DONATION;
  data: PersonalDonationResponse;
}
interface SetLoadedAction {
  type: MyDonationsActionType.SET_LOADED;
}
export type MyDonationsAction =
  | AddDonationAction
  | AddDonationsAction
  | SetDonationAction
  | CancelDonationAction
  | SetLoadedAction;
