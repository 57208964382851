import { isLeft } from "fp-ts/Either";
import { TypeOf } from "io-ts";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import Cookies from "js-cookie";
import * as pathToRegexp from "path-to-regexp";
import { useState, useCallback, DependencyList, useMemo } from "react";

import { decodeOrThrow } from "@every.org/common/src/codecs/index";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import { HttpMethod } from "@every.org/common/src/helpers/http";
import { mapValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  CSRF_TOKEN_HEADER,
  ApiRouteSpec,
  StaticApiRouteSpec,
  PUBLIC_PATH_PREFIX,
  SESSION_EXPIRED_HEADER,
} from "@every.org/common/src/routes/index";
import { getCsrfRouteSpec } from "@every.org/common/src/routes/me";

import { addToast, ToastType } from "src/components/ToastManager/Toasts";
import { authStatus } from "src/context/AuthContext";
// this is a hard cyclical dependency to resolve - but it isn't causing errors
// for now, so living with it
// eslint-disable-next-line import/no-cycle
import {
  getLogoutApiEndpointUrl,
  logoutAuthState,
} from "src/context/AuthContext/actions";
import { AuthStatus } from "src/context/AuthContext/types";
import {
  addSearchErrorMessage,
  removeSearchErrorMessage,
} from "src/context/SearchErrorMessageContext";
import { ApiError } from "src/errors/ApiError";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { trackEvent } from "src/utility/analytics";
import {
  ApiStatus,
  apiResponseCodec,
  ApiRequestOptions,
} from "src/utility/apiClient/types";
import { getAPIOrigin } from "src/utility/apiClient/utils";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";
import {
  getRuntimeEnvironment,
  RuntimeEnvironment,
} from "src/utility/runtimeEnvironment";
import { getWindow } from "src/utility/window";

export const INVALID_CSRF_MSG = "Invalid CSRF token";
type CsrfToken = TypeOf<typeof NonEmptyString>;

/**
 * This CSRF token is set by `makeRequest`, but may not actually be correct, so
 * use `getCsrfToken()` to get the proper CSRF token.
 *
 * @see ${getCsrfToken} for details on why
 */
let latestApiRequestCsrfToken: CsrfToken | null = null;

/**
 * Class encapsulating algorithm to reliably get a usable CSRF token
 *
 * We use CSRF tokens to protect our API from certain attacks. Our API creates
 * a CSRF token for each session, which it expects our frontend to send back
 * whenever we make mutating API calls. Sessions are identified by a session
 * cookie that the browser automatically sends along with requests to the API.
 *
 * The frontend gets the relevant CSRF token for the request's session from the
 * API by reading a header in the API response. Logged in users always have one
 * session; but if you visit our site without an active session, the API
 * creates a session on the spot for that request.
 *
 * Unfortunately, it's possible to end up creating multiple API sessions for a
 * given browser session the first time you visit the site. If you visit the
 * site with no session cookie present (first time visitor or freshly logged
 * out, never refreshed the page), you will not have a session cookie to begin
 * with.
 *
 * To render most landing pages, we do multiple parallel requests to retrieve
 * data necessary for the given page - each of which are sent without a session
 * cookie, and therefore end up creating different API sessions! The API sends
 * back a `Set-Cookie` header commanding the browser to store the new session,
 * but depending on the order that the responses come back, an unpredictable
 * session ends up "winning" and becoming the user's actual session.
 *
 * Since the session cookie is HttpOnly, we can't just store the proper CSRF
 * token in your session cookie, because JS can't read the cookie at all. Thus,
 * even if we save the CSRF cookie from the API response headers, we can't be
 * sure it corresponds to your session.
 *
 * There are two ways we can be more confident the CSRF token matches the
 * currently set session:
 *
 * - if you're logged in - that is, if you already had a session present at
 * page load - no new session gets created, so this race condition won't
 * happen. - if not, right before we do a mutating API request, we can call a
 * no-op API endpoint **with your current session cookie** to get a
 * definitively matching CSRF token.
 *
 * This function executes that logic.
 *
 * Note: It still remains possible that in the time between the GET_CSRF
 * endpoint returning and the actual mutating API request getting fetched, some
 * unautheenticated endpoint returns and the CSRF token once again is
 * incorrect. However, that's unlikely since mutating endpoints tend not to
 * occur immediately on page load, and the GET_CSRF fetch occurs immediately
 * before the mutating API request, making the chance of a perfectly timed
 * response messing up the session unlikely.
 *
 * Note 2: This algorithm doesn't take into account the CSRF token changing
 * when you log out, since we currently always trigger a page refresh on logout
 * (navigate to /auth/logout)
 *
 * We encapsulate the algorithm in this class to protect its state from being
 * accessed externally
 */
class CsrfTokenAccess {
  /**
   * True if we're confident that the stored CSRF token will match the session cookie
   */
  private csrfTokenConfirmed = !!Cookies.get(CookieKey.SESSION_EXISTS);
  /**
   * Singleton promise so that future calls to getCsrfToken() dont unnecessarily
   * refetch the GET_CSRF endpoint
   */
  private tokenPromise: Promise<CsrfToken | null> | null = null;

  private async fetchCsrfToken() {
    if (getRuntimeEnvironment() === RuntimeEnvironment.DEV) {
      // TODO: use CSRF on dev too
      return null;
    }

    if (this.csrfTokenConfirmed && latestApiRequestCsrfToken) {
      // no need to query CSRF token endpoint if the user is already logged in
      return latestApiRequestCsrfToken;
    }

    try {
      await queryApi(getCsrfRouteSpec, {
        routeTokens: {},
        queryParams: {},
        body: {},
      });
      // makeRequest will store the csrf token for us, so calling it is sufficient
    } catch (error) {
      logger.warn({
        message:
          "Unable to get CSRF token from CSRF endpoint, chance that following request may fail but trying anyway",
        error,
      });
    }
    this.csrfTokenConfirmed = true;
    return latestApiRequestCsrfToken;
  }

  public async getCsrfToken() {
    if (this.tokenPromise) {
      return this.tokenPromise;
    }
    this.tokenPromise = this.fetchCsrfToken();
    return this.tokenPromise;
  }
}

const csrfTokenAccess = new CsrfTokenAccess();
/**
 * Gets a CSRF token usable for mutating API requests
 *
 * If a valid token has been identified, it returns it immediately; if not, it
 * fetches the CSRF token from our API
 *
 * @see CsrfTokenAccess for details on how this works
 */
export const getCsrfToken = csrfTokenAccess.getCsrfToken.bind(csrfTokenAccess);

function getUri<
  R extends Record<string, string>,
  P extends Record<string, string | number>
>(uri: string, routeTokens: R, queryParams: P, serverside = false) {
  const path = pathToRegexp.compile(uri)(routeTokens);
  const queryString = new URLSearchParams(
    mapValues({
      obj: queryParams,
      mapper: (v) => v.toString(),
    })
  ).toString();
  const origin = getAPIOrigin();
  return `${origin}/api${path}${queryString && `?${queryString}`}`;
}

function responseShouldHaveCsrfToken(response: Response) {
  if (
    [RuntimeEnvironment.DEV, RuntimeEnvironment.TEST].includes(
      getRuntimeEnvironment()
    )
  ) {
    return false;
  }
  // NOTE: if this condition changes, please also change it in
  // `packages/api/src/configureAuth`
  return response.headers.get("cache-control")?.includes("no-cache");
}

function setCsrfTokenFromResponse(request: Request, response: Response) {
  if (!responseShouldHaveCsrfToken(response)) {
    return;
  }
  const decodedCsrfToken = NonEmptyString.decode(
    response.headers.get(CSRF_TOKEN_HEADER)
  );
  if (decodedCsrfToken._tag === "Left") {
    logger.warn({
      message: "CSRF token header was missing from API response",
      data: { method: request.method, url: request.url },
    });
  } else {
    latestApiRequestCsrfToken = decodedCsrfToken.right;
  }
}
async function makeRequest(
  request: Request,
  { disableUnauthenticatedToast = false }: ApiRequestOptions = {}
) {
  let response;
  removeSearchErrorMessage();
  try {
    response = await fetch(request);
  } catch (error) {
    const window = getWindow();

    if (error.name === "AbortError") {
      throw error;
    }

    if (window?.navigator.onLine) {
      logger.error({
        error,
        message: "The request was not properly fetched",
        data: { url: request.url },
      });
      addToast(ToastType.API_DOWN);
    } else {
      addToast(ToastType.NO_INTERNET);
    }
    throw error;
  }

  if (response.status === 429) {
    // you've been rate limited by cloudflare
    addToast(ToastType.RATE_LIMITED);
    addSearchErrorMessage(
      "Sorry, our site is experiencing too much search traffic right now. Please use the realtime search bar, or you can try refreshing this page in a minute"
    );
  }

  setCsrfTokenFromResponse(request, response);
  let responseJson;
  const responseText = await response.text();
  try {
    responseJson = JSON.parse(responseText);
  } catch (error) {
    if (
      response.status !== HttpStatus.INTERNAL_SERVER_ERROR &&
      responseText !== "Internal Server Error"
    ) {
      logger.error({
        error,
        message: "Response was not valid JSON",
        data: { url: request.url, responseText },
      });
    }
    if (responseText === INVALID_CSRF_MSG) {
      throw new Error(INVALID_CSRF_MSG);
    }
    addToast(ToastType.API_DOWN);
    throw new Error("Something went wrong... please try again!");
  }

  const decodedResponse = apiResponseCodec.decode(responseJson);
  if (isLeft(decodedResponse)) {
    logger.error({
      message: "Response json did not comply with the api response codec",
      data: {
        url: request.url,
        responseJson,
        validationErrors: decodedResponse.left,
      },
    });
    addToast(ToastType.API_DOWN);
    throw new Error("Something went wrong... please try again!");
  }
  const responseData = decodedResponse.right;

  if (response.status >= 200 && response.status < 300) {
    return responseData;
  }

  if ([504, 503].includes(response.status)) {
    addToast(ToastType.API_DOWN);
  }

  if (response.status === HttpStatus.UNAUTHENTICATED) {
    // user was expected to be logged in but wasn't, logging out

    if (!disableUnauthenticatedToast) {
      addToast(ToastType.MUST_BE_LOGGED_IN);
    }
    logoutAuthState();
    if (response.headers.get(SESSION_EXPIRED_HEADER)) {
      const window = getWindow();
      if (window) {
        window.location.href = getLogoutApiEndpointUrl();
      }
    }
  }

  throw new ApiError({
    message: responseData.message,
    httpStatus: response.status,
    data: responseData,
  });
}

// At some point for greater security, we may want to consider
// opting fields in to tracking instead of opting out,
// or removing this generic track API call entirely
const DO_NOT_TRACK = new Set([
  "password",
  "randomSecret",
  "paymentSourceId",
  "paymentSource",
  "userEmail",
  "newEmail",
  "email",
  "inviteeIdentifier",
  "emailAddress",
]);

export function removeSecrets<Obj extends object>(obj: Obj): Partial<Obj> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, v]) => !DO_NOT_TRACK.has(key))
  ) as Partial<Obj>;
}

export type UseApiResponse<RouteSpec extends ApiRouteSpec<HttpMethod.GET>> =
  | { status: ApiStatus.FETCHING }
  | {
      status: ApiStatus.SUCCESS;
      response: TypeOf<RouteSpec["responseBodyCodec"]>;
    }
  | { status: ApiStatus.ERROR; error: Error };
/**
 * Hook to fetch data from our API
 *
 * - Request is refetched if any of the items in deps change (by strict
 *   equality, same semantics as `useMemo()`)
 * - Restricted to non-mutating requests only (i.e. only GET requests allowed)
 *
 * @param getRequestData Function that returns info about the request you want
 * to make; the result will be memoized by the `deps` array
 * @param deps If anything in this array changes, triggers the data to be
 * refetched
 *
 * @example
 * const nonprofitResponse = useApi(
 *   () => ({
 *     routeSpec: getNonprofitRouteSpec,
 *     queryParams: { },
 *     routeTokens: { identifier: nonprofitId },
 *     body: {},
 *   }),
 *   [nonprofitId]
 * );
 * switch (nonprofitResponse.status) {
 *   case ApiStatus.FETCHING:
 *     return <LoadingIcon />;
 *   case ApiStatus.ERROR:
 *     return <ErrorMessage text="Could not load nonprofit">;
 *   case ApiStatus.SUCCESS:
 *     return <NonprofitCard nonprofit={nonprofitResponse.response} />
 * }
 */
export function useApi<RouteSpec extends ApiRouteSpec<HttpMethod.GET>>(
  getRequestData: () => {
    routeSpec: RouteSpec;
    routeTokens: TypeOf<RouteSpec["tokensCodec"]>;
    queryParams: TypeOf<RouteSpec["paramsCodec"]>;
    body: TypeOf<RouteSpec["bodyCodec"]>;
  } | null,
  deps: DependencyList
): UseApiResponse<RouteSpec> | null {
  const requestData = useMemo(
    () => getRequestData(),
    // dont need exhaustive-deps here because we already apply it to this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
  type ResponseBody = TypeOf<RouteSpec["responseBodyCodec"]>;
  const [result, setResult] = useState<UseApiResponse<RouteSpec> | null>({
    status: ApiStatus.FETCHING,
  });
  const asyncOperation = useCallback(() => {
    if (!requestData) {
      return Promise.resolve(null);
    }
    const { routeSpec, routeTokens, queryParams, body } = requestData;
    setResult({ status: ApiStatus.FETCHING });
    return queryApi(routeSpec, { routeTokens, queryParams, body });
  }, [requestData]);

  useAsyncEffect({
    asyncOperation,
    handleResponse: useCallback((response: ResponseBody | null) => {
      if (!response) {
        setResult(null);
        return;
      }
      setResult({ status: ApiStatus.SUCCESS, response });
    }, []),
    handleError: useCallback((error: Error) => {
      setResult({ status: ApiStatus.ERROR, error });
    }, []),
  });
  return result;
}

export async function queryApi<RouteSpec extends ApiRouteSpec>(
  routeSpec: RouteSpec,
  {
    routeTokens,
    queryParams,
    body,
  }: {
    routeTokens: TypeOf<RouteSpec["tokensCodec"]>;
    queryParams: TypeOf<RouteSpec["paramsCodec"]>;
    body: TypeOf<RouteSpec["bodyCodec"]>;
  },
  requestOptions?: ApiRequestOptions
): Promise<TypeOf<RouteSpec["responseBodyCodec"]>> {
  const startMs = performance.now();
  const path =
    !routeSpec.authenticated &&
    routeSpec["publicRoute"] &&
    authStatus !== AuthStatus.LOGGED_IN
      ? `${PUBLIC_PATH_PREFIX}${routeSpec.path}`
      : routeSpec.path;
  const uri = getUri(path, routeTokens, queryParams);
  let res;
  const { responseBodyCodec } = routeSpec;
  switch (routeSpec.method) {
    case HttpMethod.GET: {
      const responseData = await makeRequest(
        // credentials necessary to set cookies cross-origin (api is on subdomain)
        new Request(uri, {
          credentials: "include",
          signal: requestOptions?.signal,
        }),
        requestOptions
      );
      res = decodeOrThrow(responseBodyCodec, responseData.data);
      break;
    }
    case HttpMethod.POST:
    case HttpMethod.PUT:
    case HttpMethod.PATCH:
    case HttpMethod.DELETE: {
      const csrfToken = await getCsrfToken();
      if (getRuntimeEnvironment() !== RuntimeEnvironment.DEV && !csrfToken) {
        throw new Error("CSRF token missing when making mutating request");
      }
      const response = await makeRequest(
        new Request(uri, {
          method: routeSpec.method,
          body: JSON.stringify(routeSpec.bodyCodec.encode(body)),
          // this includes cookies in requests; necessary since API is on
          // separate subdomain than website, default only sends for same domain
          credentials: "include",
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "Content-Type": "application/json",
            ...(csrfToken ? { [CSRF_TOKEN_HEADER]: csrfToken } : {}),
          },
          signal: requestOptions?.signal,
        }),
        requestOptions
      );
      res = decodeOrThrow(responseBodyCodec, response.data);
      break;
    }
    default:
      throw new Error(`Unknown route method: ${routeSpec.method}.`);
  }
  queryParams = removeSecrets(queryParams);
  body = removeSecrets(body);
  trackEvent("API Call Finish", {
    route: routeSpec.path + "-" + routeSpec.method,
    routeTokens,
    queryParams,
    body,
    durationMs: performance.now() - startMs,
  });
  return res;
}

export async function serversideStaticQueryApi<
  RouteSpec extends StaticApiRouteSpec
>(
  routeSpec: RouteSpec,
  {
    routeTokens,
    queryParams,
  }: {
    routeTokens: TypeOf<RouteSpec["tokensCodec"]>;
    queryParams: TypeOf<RouteSpec["paramsCodec"]>;
  },
  requestOptions?: ApiRequestOptions
): Promise<TypeOf<RouteSpec["responseBodyCodec"]>> {
  const path = routeSpec["publicRoute"]
    ? `${PUBLIC_PATH_PREFIX}${routeSpec.path}`
    : routeSpec.path;
  const uri = getUri(path, routeTokens, queryParams, true);

  const responseData = await makeRequest(new Request(uri), requestOptions);
  const res = decodeOrThrow(routeSpec.responseBodyCodec, responseData.data);

  return res;
}
