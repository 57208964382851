import {
  UsersState,
  UserContextValue,
  FETCHING_USER,
  USER_NOT_FOUND,
  UserIdentifier,
} from "src/context/UsersContext/types";

export function getUser(state: UsersState, identifier: UserIdentifier) {
  if ("username" in identifier) {
    return state.usersByUsername.get(identifier.username);
  }
  return state.usersById.get(identifier.id);
}

/**
 * Returns `undefined` if a user is in the fetching or not_found state.
 * Useful for when you only care about the user if we actually have the
 * data, not if they're in an intermediate state.
 */
export function userOrUndefined(user: UserContextValue | undefined) {
  if (user && user !== USER_NOT_FOUND && user !== FETCHING_USER) {
    return user;
  }
  return undefined;
}
