import * as t from "io-ts";

import { boundedIntFromString } from "../codecs/number";
import { HttpMethod } from "../helpers/http";

/**
 * Header we use to store CSRF token; one of hardcoded values accepted by
 * koa-csrf
 *
 * @see {@link https://github.com/koajs/csrf/blob/master/src/index.js#L59}
 */
export const CSRF_TOKEN_HEADER = "x-csrf-token";
export const PUBLIC_PATH_PREFIX = "/public";

export const LIST_PARAMS_TAKE_MAX = 150; // Update in next.config.js too
const takeCodec = boundedIntFromString(0, LIST_PARAMS_TAKE_MAX);
export type TakeInt = t.TypeOf<typeof takeCodec>;

export const LIST_PARAMS_SKIP_MAX = 1200;
const skipCodec = boundedIntFromString(0, LIST_PARAMS_SKIP_MAX);
export type SkipInt = t.TypeOf<typeof skipCodec>;
/**
 * Codec for parameters related to listing items.
 */
export const listParamsCodec = t.type({
  take: takeCodec,
  skip: skipCodec,
});

/**
 * Codec for response related to listing items.
 */
export const listResponseCodec = t.type({
  hasMore: t.boolean,
});

export type ListParams = t.TypeOf<typeof listParamsCodec>;

/**
 * A specification of how to call an API route and how it will respond.
 */
export type ApiRouteSpec<
  Method extends HttpMethod = HttpMethod,
  TokensCodec extends t.Any = t.Any,
  ParamsCodec extends t.Any = t.Any,
  BodyCodec extends t.Any = t.Any,
  ResponseBodyCodec extends t.Any = t.Any
> = {
  /**
   * The path component from the API root.
   */
  path: string;

  /**
   * The HTTP verb that this route accepts.
   */
  method: Method;

  /**
   * An `io-ts` codec that we will use to validate the URL tokens supplied by the
   * user with their request.
   */
  tokensCodec: TokensCodec;

  /**
   * An `io-ts` codec that we will use to validate the params supplied by the
   * user with their request.
   */
  paramsCodec: ParamsCodec;

  /**
   * An `io-ts` codec that we will use to validate the body properties supplied
   * by the user with their request.
   */
  bodyCodec: BodyCodec;

  /**
   * An `io-ts` codec that the API will use to encode the response body over the
   * network, and clients will use to decode responses from the network.
   */
  responseBodyCodec: ResponseBodyCodec;
} & (
  | {
      /**
       * Whether or not the user must be authenticated to hit this route.
       * A user who is not authenticated will receive an `UnauthenticatedError`.
       */
      authenticated: true;
      /**
       * If this property is set, then we check basic auth for partner API
       * credentials. The username should correspond to an ApiKey and the
       * password should correspond to an ApiSecret that both belong to the same
       * user. If not, we'll throw a 403.
       */
      partnerApiRoute?: true;
    }
  | {
      authenticated: false;
      /**
       * If this property is set, then we create two routes for this spec --
       * one with the standard route path and another at /public/<path>. The
       * /public version will be called if a logged-out user queries the
       * endpoint, allowing us to do things like cache the non-personalized
       * results returned at that endpoint.
       *
       * Since this public route doesn't include any version info, it's
       * recommended that you keep the cache length pretty low so that clients
       * won't be served stale data that they don't know how to parse.
       */
      publicRoute?: {
        publicCacheLengthMinutes: number;
        /**
         * If true, even if you're logged in it will cache the endpoint
         * @default false
         */
        alsoCacheIfAuthenticated?: boolean;
      };
    }
);

/**
 * A specification of how to call a public cached API route and how it will respond.
 */
export interface CachedApiRouteSpec<
  Method extends HttpMethod,
  TokensCodec extends t.Any,
  ParamsCodec extends t.Any,
  BodyCodec extends t.Any,
  ResponseBodyCodec extends t.Any
> {
  /**
   * The path component from the API root.
   */
  path: string;

  /**
   * The HTTP verb that this route accepts.
   */
  method: Method;

  /**
   * Which version to return; bump this whenever the params, body, or
   * response codec changes so that stale responses are not returned.
   */
  version: number;

  /**
   * An `io-ts` codec that we will use to validate the URL tokens supplied by the
   * user with their request.
   */
  tokensCodec: TokensCodec;

  /**
   * An `io-ts` codec that we will use to validate the params supplied by the
   * user with their request.
   */
  paramsCodec: ParamsCodec;

  /**
   * An `io-ts` codec that we will use to validate the body properties supplied
   * by the user with their request.
   */
  bodyCodec: BodyCodec;

  /**
   * An `io-ts` codec that the API will use to encode the response body over the
   * network, and clients will use to decode responses from the network.
   */
  responseBodyCodec: ResponseBodyCodec;
}

/**
 * A specification an API Route that can be used in SSG - no auth, only GET
 */
export type StaticApiRouteSpec = ApiRouteSpec & {
  authenticated: false;
  method: HttpMethod.GET;
};

/**
 * Identity function; just useful so that we don't have to write out type
 * signatures for every route, TypeScript will infer it from values this way.
 */
export function makeRouteSpec<
  Method extends HttpMethod,
  TokensCodec extends t.Any,
  ParamsCodec extends t.Any,
  BodyCodec extends t.Any,
  ResponseBodyCodec extends t.Any
>(
  spec: ApiRouteSpec<
    Method,
    TokensCodec,
    ParamsCodec,
    BodyCodec,
    ResponseBodyCodec
  >
): typeof spec {
  return spec;
}

/**
 * Useful for making public cached routes that have version numbers included
 * in the path. The version number should increase whenever the API params,
 * body, or response codec changes.
 *
 * Note that cached routes served by the publicApiRouter from app.ts are by
 * default cached for 15 minutes.
 */
export function makeCachedRouteSpec<
  Method extends HttpMethod,
  TokensCodec extends t.Any,
  ParamsCodec extends t.Any,
  BodyCodec extends t.Any,
  ResponseBodyCodec extends t.Any
>(
  spec: CachedApiRouteSpec<
    Method,
    TokensCodec,
    ParamsCodec,
    BodyCodec,
    ResponseBodyCodec
  >
) {
  const { path, version, ...rest } = spec;
  return {
    path: `${path}_v${version}`,
    authenticated: false,
    ...rest,
  } as const;
}

export const SESSION_EXPIRED_HEADER = "session-expired";
