import {
  withScope as withSentryScope,
  captureException as captureSentryException,
  captureMessage as captureSentryMessage,
} from "@sentry/react";
//Remove this package after @sentry/react add export for fromString
import { severityFromString as fromString } from "@sentry/utils";
import pino from "pino";

import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import {
  createLogger,
  LogLevel,
  LogParams,
} from "@every.org/common/src/helpers/logger";

import type { ApiError } from "src/errors/ApiError";
import { Globals } from "src/utility/globals";
import { isDevOrTest } from "src/utility/runtimeEnvironment";
import { getWindow } from "src/utility/window";

let sumoLogger: import("sumo-logger");
const awsUrl = process.env["NEXT_PUBLIC_AWS_LOGS_URL"];

async function logToSumoLogger<Data extends Record<string, unknown>>(
  logMessage: LogParams<Data>
) {
  if (!sumoLogger) {
    const SumoLogger = await import("sumo-logger");
    if (!sumoLogger) {
      // We check again if another call may have executed
      // while awaiting the import. It's fine to reassign
      // immediately after checking as we are single-threaded.
      // eslint-disable-next-line require-atomic-updates
      sumoLogger = new SumoLogger.default({
        endpoint: assertEnvPresent(
          process.env.NEXT_PUBLIC_SUMOLOGIC_WEB_APP_COLLECTOR ||
            process.env.REACT_APP_SUMOLOGIC_WEB_APP_COLLECTOR,
          "SUMOLOGIC_WEB_APP_COLLECTOR"
        ),
        interval: 10000,
        // we're not doing anything in response to sumologger by default
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSuccess: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: () => {},
      });
    }
  }
  try {
    sumoLogger.log({ ...logMessage });
  } catch (error) {
    logger.warn({
      message: "Failed to send log to sumologic",
      error,
      excludeLogServices: [LogServices.SUMOLOGIC],
    });
  }
}

function logToAWS<Data extends Record<string, unknown>>(
  logMessage: LogParams<Data>
) {
  if (awsUrl) {
    (async () => {
      try {
        await fetch(awsUrl, {
          method: "POST",
          mode: "no-cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify(logMessage),
        });
      } catch (error) {
        logger.warn({
          message: "failed to send log to aws",
          error,
          excludeLogServices: [LogServices.AWS],
        });
      }
    })();
  }
}

const SENTRY_ERROR_LEVELS = new Set([
  LogLevel.WARN,
  LogLevel.ERROR,
  LogLevel.FATAL,
]);
function logToSentry<Data extends Record<string, unknown>>(
  level: LogLevel,
  { error, message, data }: LogParams<Data>
) {
  if (!SENTRY_ERROR_LEVELS.has(level)) {
    // dont bother sending info logs to sentry, sumologic is better for that
    return;
  }

  withSentryScope((scope) => {
    scope.setLevel(fromString(level)); // our log levels match
    if (data) {
      // we should be careful not to make extra context too big - 200kb is max
      scope.setContext("data", data);
    }
    if (error) {
      scope.setContext("message", { message });
      if (error instanceof Error && "data" in error) {
        scope.setContext("errorData", (error as ApiError).data);
      }
      captureSentryException(error);
    } else {
      captureSentryMessage(message);
    }
  });
}

export enum LogServices {
  AWS,
  SUMOLOGIC,
  SENTRY,
}
interface WebsiteLogParams<Data extends Record<string, unknown>>
  extends LogParams<Data> {
  /**
   * Log services to not send the particular log to
   */
  excludeLogServices?: LogServices[];
}

const logFns = Object.fromEntries(
  Object.values(LogLevel).map((level) => [
    level,
    <Data extends Record<string, unknown>>({
      data: origData,
      excludeLogServices = [],
      ...rest
    }: WebsiteLogParams<Data>) => {
      const window = getWindow();
      const data = {
        url: window?.location.href,
        userAgent: window?.navigator.userAgent,
        loggedInUserId: Globals.everydotorgLoggedInUserId,
        ...origData,
      };
      const params = { ...rest, data };
      if (!excludeLogServices.includes(LogServices.AWS)) {
        logToAWS(params);
      }
      if (!excludeLogServices.includes(LogServices.SUMOLOGIC)) {
        logToSumoLogger(params);
      }
      if (!excludeLogServices.includes(LogServices.SENTRY)) {
        logToSentry(level, params);
      }
    },
  ])
);

export const logger = createLogger({
  // ts-expect-error: Revisit the types of the logger
  pinoLogger: isDevOrTest
    ? pino()
    : pino({
        browser: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          write: logFns as any,
        },
      }),
}) as {
  [key in LogLevel]: <Data extends Record<string, unknown>>(
    params: WebsiteLogParams<Data>
  ) => void;
};
