/**
 * An accessible styled checkbox that can be seen by screen readers.
 * Adapted from: https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
 */

import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";

import { colorCssVars } from "src/theme/color";

export enum CheckboxSize {
  DEFAULT = "DEFAULT",
  LARGE = "LARGE",
}

const checkboxSizeNum = {
  [CheckboxSize.DEFAULT]: 18,
  [CheckboxSize.LARGE]: 24,
};
const HiddenCheckbox = styled.input`
  /* Hide checkbox visually but remain accessible to screen readers.
  Source: https://polished.js.org/docs/#hidevisually */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const checkedStyles = (checked?: boolean, disabled?: boolean) => css`
  background: ${checked
    ? disabled
      ? `var(${colorCssVars.text.secondary})`
      : `var(${colorCssVars.accent.small})`
    : "transparent"};
  border: 1.5px solid
    ${checked
      ? `var(${colorCssVars.accent.small}})`
      : SHARED_PALETTE.inputBorder};
`;

interface StyledCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  size: CheckboxSize;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  ${({ size }) => css`
    width: ${checkboxSizeNum[size]}px;
    min-width: ${checkboxSizeNum[size]}px;
    height: ${checkboxSizeNum[size]}px;
  `}
  display: flex;

  border-radius: 4px;
  justify-content: center;
  align-items: center;
  ${({ disabled }) => (!disabled ? "cursor: pointer;" : "")};
  ${({ checked, disabled }) => checkedStyles(checked, disabled)};

  ${HiddenCheckbox}[data-focus-visible-added]:focus + & {
    border: 2px solid ${SHARED_PALETTE.focus};
  }
`;

const checkmarkIconCss = css`
  color: white;
  & > path {
    stroke-width: 2;
  }
`;

const checkboxWrapperCss = {
  [CheckboxSize.DEFAULT]: css`
    min-width: 24px;
    width: 32px;
    height: 24px;
    padding: 3px 6px 3px 6px;
  `,
  [CheckboxSize.LARGE]: css`
    min-width: 24px;
    height: 24px;
  `,
};

const CheckboxLabelText = styled.span`
  cursor: pointer;
`;

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "as" | "size"> {
  "data-tname": string;
  selectableContainerStyle?: React.CSSProperties;
  size?: CheckboxSize;
}

const defaultProps = {
  color: `${SHARED_PALETTE.correct}`,
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function CheckboxWithRef(origProps: CheckboxProps, ref) {
    const {
      className,
      children,
      color,
      id,
      checked,
      style,
      selectableContainerStyle,
      disabled,
      size = CheckboxSize.DEFAULT,
      ...rest
    } = {
      ...defaultProps,
      ...origProps,
    };
    return (
      <label className={className} style={selectableContainerStyle}>
        <div style={style}>
          <div
            css={[checkboxWrapperCss[size], !disabled && { cursor: "pointer" }]}
          >
            <HiddenCheckbox
              type="checkbox"
              defaultChecked={checked}
              disabled={disabled}
              id={id}
              {...rest}
              role="checkbox"
              ref={ref}
            />
            <StyledCheckbox
              color={color}
              checked={checked}
              disabled={disabled}
              size={size}
              data-tname={origProps["data-tname"]}
            >
              {checked && (
                <Icon
                  css={checkmarkIconCss}
                  iconImport={() =>
                    import("@components/Icon/icons/CheckMarkIcon")
                  }
                  size={IconSize.X_SMALL}
                  display={IconDisplay.CURRENT_COLOR}
                />
              )}
            </StyledCheckbox>
          </div>
        </div>
        <CheckboxLabelText>{children}</CheckboxLabelText>
      </label>
    );
  }
);
