import { AvatarSize } from "@components/Avatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import { InviterPopover } from "@components/DonationCardHeader/InviterPopover";
import { JoinsStreakPopover } from "@components/DonationCardHeader/JoinsStreakPopover";
import { TimesChargedAndTimePopover } from "@components/DonationCardHeader/TimesChargedAndTimePopover";
import {
  Header,
  HeaderClickablePopover,
  secondaryTextCss,
  StyledSection,
} from "@components/DonationCardHeader/styles";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { UserNameLink } from "@components/UserLink";
import { UUID } from "io-ts-types/UUID";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import {
  DonationBoostResponse,
  DonationResponse,
} from "@every.org/common/src/codecs/entities";
import {
  isValidUsername,
  Username,
} from "@every.org/common/src/codecs/username";
import {
  DonationFrequency,
  FeedPage,
  VerifiedStatus,
} from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { getUserDonationShareUrl } from "@every.org/common/src/helpers/share";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { Link } from "src/components/Link";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { NonprofitFetchStatus } from "src/context/NonprofitsContext/types";
import { useUser } from "src/context/UsersContext/hooks";
import {
  USER_NOT_FOUND,
  FETCHING_USER,
  UserIdentifier,
} from "src/context/UsersContext/types";
import { ViewDonationData } from "src/context/ViewDonationContext";
import { LinkAppearance } from "src/styles/link";
import { ClickAction } from "src/utility/analytics";
import { logger } from "src/utility/logger";

type JoinedSupportedTextProps = DonationHeaderCardProps & {
  pageOwnerUserId?: UUID;
  parentDonation: DonationResponse;
  grandparentDonations: DonationResponse[] | null;
};

// joined {other name / n others}
function JoinedText(props: JoinedSupportedTextProps) {
  const {
    username,
    parentDonation,
    grandparentDonations,
    page,
    pageOwnerUserId,
  } = props;
  const parentDonationUser = useUser({ id: parentDonation.fromUserId });
  const nonprofit = useNonprofit({ id: parentDonation.toNonprofitId });
  const slicedStreakUserIdentifiers = useMemo<
    UserIdentifier[] | undefined
  >(() => {
    if (
      parentDonationUser &&
      parentDonationUser !== USER_NOT_FOUND &&
      parentDonationUser !== FETCHING_USER &&
      grandparentDonations &&
      username
    ) {
      const streakUserIdentifiers = [
        { username },
        { id: parentDonation.fromUserId },
        ...[
          ...grandparentDonations,
          parentDonation,
          parentDonation,
          parentDonation,
          parentDonation,
        ]
          .map(({ fromUserId }) => ({
            id: fromUserId,
          }))
          .reverse(),
      ];

      if (!pageOwnerUserId) {
        // When not viewing a specific user's page, show the whole chain
        return streakUserIdentifiers;
      }

      const indexOfPageOwnerId = streakUserIdentifiers.findIndex(
        (indetifier) => indetifier["id"] === pageOwnerUserId
      );
      // Joins chain should stop at page owner identifier
      return streakUserIdentifiers.slice(0, indexOfPageOwnerId + 1);
    }
    return;
  }, [
    grandparentDonations,
    pageOwnerUserId,
    parentDonation,
    parentDonationUser,
    username,
  ]);

  if (parentDonationUser === USER_NOT_FOUND) {
    logger.warn({
      message: "Parent donation's user not found.",
      data: { parentDonationId: parentDonation.id },
    });
    return <DonatedText {...props} />;
  }

  if (parentDonationUser === FETCHING_USER) {
    return <DonatedText {...props} />;
  }

  if (!parentDonationUser.firstName || !parentDonationUser.username) {
    logger.warn({
      message:
        "Parent donation's user not in context or missing required metadata.",
      data: { parentDonationId: parentDonation.id },
    });
    // Fallback
    return <DonatedText {...props} />;
  }

  const fullName = getUserFullNameOrPlaceholder(parentDonationUser);
  // 17 is the largest number of characters that can fit in one row
  const displayName =
    fullName.length < 17 ? fullName : parentDonationUser.firstName;

  const joinedToUserLinkUrl =
    nonprofit !== NonprofitFetchStatus.FETCHING_NONPROFIT &&
    nonprofit !== NonprofitFetchStatus.NONPROFIT_NOT_FOUND
      ? getUserDonationShareUrl({
          nonprofitSlug: nonprofit.primarySlug,
          donationId: parentDonation.id,
          username: parentDonationUser.username,
          format: URLFormat.RELATIVE,
        })
      : getRoutePath({
          name: ClientRouteName.USER,
          tokens: { username: parentDonationUser.username },
          format: URLFormat.RELATIVE,
        });

  return (
    <React.Fragment>
      <span css={secondaryTextCss}>joined</span>
      {page === FeedPage.USER_JOINS &&
      slicedStreakUserIdentifiers &&
      slicedStreakUserIdentifiers.length > 2 ? (
        <JoinsStreakPopover userIdentifiers={slicedStreakUserIdentifiers} />
      ) : (
        <Link
          data-tname="joinedDonationLink"
          data-action={ClickAction.PARENT_DONATION}
          to={joinedToUserLinkUrl}
          appearance={LinkAppearance.HYPERLINK_UNCOLORED}
        >
          {displayName}
        </Link>
      )}
    </React.Fragment>
  );
}

// donated [i]
const DonatedText: React.FCC<DonationHeaderCardProps> = ({
  name,
  username,
  verifiedStatus,
  removeTimestamp,
  createdAt,
  isPending,
  frequency,
  page,
  inviter,
  profileImageCloudinaryId,
  donationData,
  timesCharged,
  ...rest
}) => {
  const invitePopover =
    (page === FeedPage.USER_JOINS && !!inviter && !!username && (
      <InviterPopover inviter={inviter} name={name} />
    )) ||
    null;

  return (
    <React.Fragment>
      <span css={secondaryTextCss}>donated</span>
      {invitePopover}
    </React.Fragment>
  );
};

export type DonationHeaderCardProps = {
  name: string;
  /**
   * If provided, the user's name will link to their profile.
   */
  username?: Username;
  verifiedStatus?: VerifiedStatus;
  frequency?: DonationFrequency;
  timesCharged?: number;
  profileImageCloudinaryId?: string;
  /**
   * Boost metadata, such as whether this donation is in response to another or
   * # of people who joined this donation.
   */
  boost?: DonationBoostResponse;
  removeTimestamp?: boolean;
  createdAt: Date | null;
  isPending?: boolean;
  /**
   * Hides the cause or nonprofit icon.
   */
  hideIcon?: boolean;
  page?: FeedPage;
  inviter?: UUID;
  donationData?: ViewDonationData | null;
};

/**
 * Component that shows the "{user} supported {cause}" header within a card.
 */
export const DonationHeaderCard: React.FCC<DonationHeaderCardProps> = ({
  profileImageCloudinaryId,
  hideIcon,
  username,
  boost,
  verifiedStatus,
  donationData,
  ...rest
}) => {
  const pageOwnerUserId = useUserIdFromRouteMatch();

  return (
    <Header>
      <UserAvatar
        user={{ profileImageCloudinaryId, username }}
        size={AvatarSize.XX_SMALL}
      />
      <StyledSection>
        <UserNameLink
          {...rest}
          username={username}
          tname="donationCardSupporterLink"
          data-action={ClickAction.USER}
          appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          verifiedStatus={verifiedStatus}
        />
        <StyledSection>
          {boost?.parentDonation &&
          !(
            rest.page === FeedPage.USER_JOINS &&
            rest.inviter === pageOwnerUserId
          ) ? (
            <JoinedText
              pageOwnerUserId={pageOwnerUserId}
              username={username}
              parentDonation={boost.parentDonation}
              grandparentDonations={boost.grandparentDonations}
              verifiedStatus={verifiedStatus}
              {...rest}
            />
          ) : (
            <DonatedText
              username={username}
              verifiedStatus={verifiedStatus}
              donationData={donationData}
              {...rest}
            />
          )}
          <TimesChargedAndTimePopover donationData={donationData} {...rest} />
        </StyledSection>
      </StyledSection>
      {rest.isPending && (
        <HeaderClickablePopover
          placement={"bottom"}
          css={{ marginLeft: "auto" }}
          contentCss={{ maxWidth: "200px" }}
          content="Your donation is still being processed. It will not be visible to others until it has been confirmed."
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/ClockIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
        </HeaderClickablePopover>
      )}
    </Header>
  );
};

function useUserIdFromRouteMatch() {
  const { query } = useRouter();
  const username = Array.isArray(query.username)
    ? query.username[0]
    : query.username;

  const user = useUser(isValidUsername(username) ? { username } : undefined);

  if (user !== FETCHING_USER && user !== USER_NOT_FOUND) {
    return user.id;
  }
  return undefined;
}
