import { getInitialSliderSettingsForCrypto } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/CryptoTipSlider";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import { CryptoAmountInput } from "@components/donate/DonateV3/PaymentProcess/pages/CryptoV2/AmountInput";
import Big from "big.js";
import React, { useEffect } from "react";

export const CryptoTipCustom = ({
  value,
  onChange,
  form,
  formContext,
  amountBig,
}: AddTipProps) => {
  const cryptoCurrency = form.watch("cryptoCurrency");
  const cryptoPledgeAmount = form.watch("cryptoPledgeAmount");

  useEffect(() => {
    const amountInUSD =
      amountBig && formContext.cryptoTokenRate?.rate
        ? amountBig.times(formContext.cryptoTokenRate?.rate).round(2)
        : undefined;
    const percentage = getInitialSliderSettingsForCrypto(amountInUSD).initial;

    onChange(
      new Big(cryptoPledgeAmount ?? 0)?.div(100).mul(percentage).toString()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!cryptoCurrency) {
    return null;
  }

  return (
    <CryptoAmountInput
      minDonationValue={formContext.minValue}
      maxDonationValue={formContext.maxValue}
      cryptoCurrency={cryptoCurrency}
      cryptoAmountValue={value}
      setCryptoAmountValue={onChange}
      cryptoTokenRate={formContext.cryptoTokenRate?.rate}
    />
  );
};
