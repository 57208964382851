import { css } from "@emotion/react";
import { Big } from "big.js";

import { colorPalette } from "@every.org/common/src/display/palette";
import { Currency } from "@every.org/common/src/entity/types";

interface ThermometerPropsBase {
  className?: string;
  currency: Currency;
  goalLabel?: string;
  progressLabel?: string;
}
interface ThermometerPropsNoData extends ThermometerPropsBase {
  dataMissing: true;
}
interface ThermometerPropsData extends ThermometerPropsBase {
  dataMissing?: false;
  goalAmount: Big;
  currentAmount: Big;
  monthly: Big | null;
  raisedOffline?: Big | null;
  raisedMatches?: Big | null;
}

const progressBarBaseCss = css`
  height: 100%;
  position: absolute;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

function getDisplayPercent(num: Big): Big {
  return num.cmp(100) < 0 ? num : new Big(100);
}

export type ThermometerProps = (
  | ThermometerPropsNoData
  | ThermometerPropsData
) & {
  labelPosition?: "top" | "bottom";
};

const Thermometer = (props: ThermometerProps) => {
  const { currency } = props;

  const monthlyAnnualTotal =
    !props.dataMissing && typeof props.monthly?.times === "function"
      ? { currency, amount: props.monthly.times(12) }
      : undefined;

  const displayPercent = getDisplayPercent(
    props.dataMissing
      ? new Big(0)
      : new Big(props.currentAmount)
          .add(props.raisedOffline ? props.raisedOffline : 0)
          .div(props.goalAmount)
          .times(100)
  );

  const displayMonthlyPercent =
    monthlyAnnualTotal && !props.dataMissing
      ? getDisplayPercent(
          monthlyAnnualTotal.amount
            .add(new Big(props.currentAmount))
            .div(props.goalAmount)
            .times(100)
        )
      : undefined;

  return (
    <div
      css={css`
        width: 100%;
        height: 8px;
        border-radius: 8px;
        position: relative;
        background: #f3f7f7;
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
        overflow: hidden;
      `}
    >
      <div
        css={[
          progressBarBaseCss,
          css`
            z-index: 1;
            width: ${displayMonthlyPercent?.toFixed(0) || 0}%;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 1),
              rgba(0, 163, 127, 0.4)
            );
          `,
        ]}
      />
      <div
        css={[
          progressBarBaseCss,
          css`
            z-index: 2;
            width: ${displayPercent.toFixed(0)}%;
            background: ${colorPalette.teal};
          `,
        ]}
      />
    </div>
  );
};

export default Thermometer;
