import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export const Container = styled.div`
  ${verticalStackCss.m};
  max-width: 530px;
`;

export const CommentContainer = styled.div`
  ${verticalStackCss.m};
`;

export const IconsContainer = styled.div`
  ${horizontalStackCss.m};

  ${cssForMediaSize({
    max: MediaSize.MEDIUM_SMALL,
    css: css`
      display: grid;
      grid-template-columns: repeat(3, 48px);
      grid-column-gap: ${spacing.m};
      grid-row-gap: ${spacing.l};
      margin: 0 auto;

      & > div:last-child {
        grid-column: 2 / 3;
      }
    `,
  })}

  // hidden input for copy to clipboard
  // causes horizontal scroll in modal
  input[aria-hidden="true"] {
    left: -9999px;
  }
`;

export const shareIconButtonCss = css`
  width: 48px;
  height: 48px;
`;

export const Headline = styled.h1`
  ${textSizeCss.m};

  & > span {
    color: var(${colorCssVars.accent.large});
  }
  ${cssForMediaSize({ min: MediaSize.MEDIUM, css: textSizeCss.l })}
`;
