import { useEffect, useRef } from "react";

import { getWindow } from "src/utility/window";

/**
 * Hook to call a callback when a click is detected outside the element referred
 * to by the passed in `ref`.
 *
 * Based on the solution suggested here:
 * https://stackoverflow.com/a/42234988/1105281
 *
 * @param ref Ref to the element of whom clicks outside will trigger the
 * callback. Use the `useRef()` hook to generate a ref, place it in the `ref`
 * field of a React element, and pass it to this function.
 * @param handleClickOutside handler to be called when an outside click is
 * detected.
 *
 * @example
 *
 * const SayHiOnClickOutside: React.FCC = () => {
 *   const ref = useOnClickOutside({ ref, handleClickOutside: () => console.log("hi") });
 *
 *   return <div ref={ref}>This component says hi when you click outside.</div>
 * }
 */
export function useOnClickOutside<ElemType extends HTMLElement>({
  handleClickOutside,
}: {
  handleClickOutside: () => void;
}) {
  const ref = useRef<ElemType | null>(null);

  useEffect(() => {
    const document = getWindow()?.document;
    if (!document) {
      return;
    }
    function handler(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handler);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handler);
    };
  }, [handleClickOutside]);
  return ref;
}
