import { ShareIconButton } from "@components/ShareIconButton";
import React from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { ShareMedium } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import {
  getFundraiserShareText,
  ShareFundraiserArgs,
} from "@every.org/common/src/helpers/share";

import { ButtonRole, ButtonTargetKind } from "src/components/Button";
import { Card } from "src/components/Card";
import { Icon, IconSize, IconDisplay } from "src/components/Icon";
import { Modal, ModalProps } from "src/components/Modal";
import { ShareButtonProps } from "src/components/ShareButton";
import { Cell, Grid } from "src/components/layout/GridSystem";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  iconsCellCss,
  IconsContainer,
  ModalContent,
  ModalHeadline,
  shareIconButtonCss,
  StyledButton,
  StyledIcon,
  StyledTextContainer,
} from "src/pages/Fundraiser/EditForm/ShareCard/style";
import { verticalStackCss } from "src/theme/spacing";

function Text() {
  return (
    <StyledTextContainer>
      <p>
        Sharing with your friends and followers is the best way to inspire
        generosity!
      </p>
      <ul>
        <li>
          <StyledIcon
            iconImport={() => import("src/components/Icon/icons/Users")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
          <p>
            Share with your friends online (this is social networking at its
            best)
          </p>
        </li>
        <li>
          <StyledIcon
            iconImport={() => import("src/components/Icon/icons/LinkIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
          <p>Email or text your friends a link</p>
        </li>
        <li>
          <StyledIcon
            iconImport={() => import("src/components/Icon/icons/RocketIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
          <p>
            Even if not all of your friends can donate, they can help just by
            spreading the word! Encourage them to repost, forward, and share
            with their friends.
          </p>
        </li>
      </ul>
    </StyledTextContainer>
  );
}

function ShareButtons({
  shareData,
}: {
  shareData: ShareButtonProps["shareData"];
}) {
  const shareItems: Exclude<ShareMedium, ShareMedium.NATIVE>[] = [
    ShareMedium.FACEBOOK,
    ShareMedium.TWITTER,
    ShareMedium.WHATSAPP,
    ShareMedium.LINKEDIN,
    ShareMedium.REDDIT,
    ShareMedium.LINK,
  ];

  return (
    <div css={verticalStackCss.s}>
      <h4>Where would you like to share?</h4>
      <IconsContainer>
        {shareItems.map((medium) => (
          <ShareIconButton
            css={shareIconButtonCss}
            key={medium}
            medium={medium}
            shareData={shareData}
            size={IconSize.X_LARGE}
          />
        ))}
      </IconsContainer>
    </div>
  );
}

export function ShareCard({
  fundraiser,
  nonprofit,
  ...rest
}: {
  fundraiser: FundraiserResponse;
  nonprofit: ContextNonprofit;
} & Omit<ShareFundraiserArgs, "fundraiserName" | "nonprofitName">) {
  const shareData = {
    text: getFundraiserShareText({
      fundraiserName: fundraiser.title,
      ...rest,
    }),
    url: getRoutePath({
      name: ClientRouteName.FUNDRAISER,
      tokens: {
        nonprofitSlug: nonprofit.primarySlug,
        fundraiserSlug: fundraiser.slug,
      },
      format: URLFormat.ABSOLUTE,
    }),
  };

  return (
    <Card css={verticalStackCss.m}>
      <h3>Share your fundraiser</h3>
      <Grid>
        <Cell numCols={6} numColsLarge={12} numColsXLarge={6}>
          <Text />
        </Cell>
        <Cell
          numCols={6}
          numColsLarge={12}
          numColsXLarge={6}
          css={iconsCellCss}
        >
          <ShareButtons shareData={shareData} />
        </Cell>
      </Grid>
    </Card>
  );
}

export function ShareModal({
  fundraiser,
  nonprofit,
  isMyFundraiser,
  headlineText,
  ...rest
}: {
  fundraiser: FundraiserResponse;
  nonprofit: ContextNonprofit;
  headlineText: React.ReactNode;
} & Omit<ShareFundraiserArgs, "fundraiserName" | "nonprofitName"> &
  Omit<ModalProps, "children">) {
  const shareData = {
    text: getFundraiserShareText({
      fundraiserName: fundraiser.title,
      isMyFundraiser,
    }),
    url: getRoutePath({
      name: ClientRouteName.FUNDRAISER,
      tokens: {
        nonprofitSlug: nonprofit.primarySlug,
        fundraiserSlug: fundraiser.slug,
      },
      format: URLFormat.ABSOLUTE,
    }),
  };

  return (
    <Modal {...rest}>
      <ModalContent css={verticalStackCss.l}>
        <ModalHeadline>{headlineText}</ModalHeadline>
        <Text />
        <ShareButtons shareData={shareData} />
        <StyledButton
          data-tname={"FundraiserShareModal__Close"}
          role={ButtonRole.TEXT_ONLY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: (e) => rest.onRequestClose && rest.onRequestClose(e),
          }}
          icon={
            <Icon
              iconImport={() => import("src/components/Icon/icons/XIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.ACCENT}
            />
          }
        />
      </ModalContent>
    </Modal>
  );
}
