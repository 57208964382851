import { Big } from "big.js";

import {
  FundraiserResponse,
  FundraiserRaisedResponse,
} from "@every.org/common/src/codecs/entities";
import { Currency } from "@every.org/common/src/entity/types";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { roundToMilestone } from "@every.org/common/src/helpers/fundraisers";

import { fundraiserCurrentAmount } from "src/pages/Fundraiser";

export function preformRaisedAmounts({
  fundraiser,
  raisedData,
}: {
  fundraiser: FundraiserResponse;
  raisedData: FundraiserRaisedResponse;
}) {
  const {
    raisedMonthly,
    raisedOffline,
    currency: raisedCurrency,
    raisedMatches,
  } = raisedData;

  const currentAmount = fundraiserCurrentAmount(raisedData);

  const raisedOfflineAmount = raisedOffline
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedOffline,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  const raisedMatchesAmount = raisedMatches
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedMatches,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  const totalAmount = new Big(currentAmount).add(raisedOfflineAmount);
  const totalAmountInMinDenom = new Big(
    raisedData.raised + raisedData.raisedMatches + raisedOffline
  );

  const goalAmount =
    fundraiser.goalAmount && fundraiser.goalCurrency
      ? minimumDenominationAmountToCurrencyValue({
          currency: fundraiser.goalCurrency,
          amountInMinDenom: fundraiser.goalAmount,
        }).amount
      : raisedData?.goalAmount && raisedData?.currency
      ? minimumDenominationAmountToCurrencyValue({
          currency: raisedData.currency,
          amountInMinDenom: raisedData.goalAmount,
        }).amount
      : fundraiser.raisedData?.goalAmount && fundraiser.raisedData?.currency
      ? minimumDenominationAmountToCurrencyValue({
          currency: fundraiser.raisedData.currency,
          amountInMinDenom: fundraiser.raisedData.goalAmount,
        }).amount
      : minimumDenominationAmountToCurrencyValue({
          currency: Currency.USD,
          amountInMinDenom: roundToMilestone(totalAmountInMinDenom),
        }).amount;

  const raisedMounthly = raisedMonthly
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedMonthly,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  return {
    currentAmount,
    goalAmount,
    raisedMounthly,
    raisedOfflineAmount,
    totalAmount,
    raisedMatchesAmount,
  };
}
