/**
 * A base class for any known error that occurs in our application.
 */
export abstract class ApplicationError<ErrorData = unknown> extends Error {
  public declare readonly data: ErrorData;

  public constructor(message: string, data: ErrorData) {
    super(message);
    this.data = data;
  }
}
