import { FeedbackLink } from "@components/FeedbackLink";
import { popoverMenu, ListSection } from "@components/popoverMenu";
import { Fragment } from "react";

import {
  ClientRouteName,
  getDonateRoutePath,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { getLogoutApiEndpointUrl } from "src/context/AuthContext/actions";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { LinkAppearance } from "src/styles/link";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";
import { isMissingRequiredUserFields } from "src/utility/user";

const HeaderPopoverMenuItems = ({
  setVisible,
  isAdmin,
  manageNonprofitsLink,
}: {
  setVisible: (visible: boolean) => void;
  isAdmin?: boolean;
  manageNonprofitsLink?: string;
}) => {
  const loggedInUser = useLoggedInUserOrUndefined();
  const onboarded = loggedInUser && !isMissingRequiredUserFields(loggedInUser);

  return (
    <Fragment>
      {isAdmin && (
        <ListSection data-tname="popovermenu-admin">
          {manageNonprofitsLink && (
            <li>
              <Link
                title="Navigate to nonprofit admin page"
                data-tname="nonprofitAdmin"
                to={manageNonprofitsLink}
              >
                Nonprofit dashboard
              </Link>
            </li>
          )}
          <li>
            <Link
              title="Navigate to FAQ and support"
              data-tname="faq"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.SUPPORT,
              })}
            >
              FAQ & support
            </Link>
          </li>
        </ListSection>
      )}
      <ListSection data-tname="popovermenu-top">
        {onboarded && (
          <Link
            title="Navigate to profile page"
            data-tname="myDonorProfile"
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.ME,
            })}
          >
            My donor profile
          </Link>
        )}
        {onboarded && (
          <li>
            <Link
              title="Navigate to my giving"
              data-tname="myGiving"
              to={getRoutePath({
                name: ClientRouteName.MY_GIVING,
                format: URLFormat.RELATIVE,
              })}
            >
              My giving
            </Link>
          </li>
        )}
        {!isAdmin && (
          <li>
            <Link
              title="Navigate to FAQ and support"
              data-tname="faq"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.SUPPORT,
              })}
            >
              FAQ & support
            </Link>
          </li>
        )}
        {onboarded && (
          <li>
            <Link
              title="Navigate to settings"
              data-tname="settings"
              to={getRoutePath({
                name: ClientRouteName.SETTINGS,
                format: URLFormat.RELATIVE,
              })}
              forceExternal
            >
              Settings
            </Link>
          </li>
        )}
        <li>
          <Link
            title="Navigate to gift cards"
            data-tname="giftCards"
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.GIFT_CARD_PURCHASE,
            })}
          >
            Charity gift cards
          </Link>
        </li>
        {onboarded && (
          <li>
            <Link
              title="Navigate to invite friends"
              data-tname="invite"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.INVITE_USERS,
              })}
            >
              Invite friends
            </Link>
          </li>
        )}
        {!isAdmin && onboarded && (
          <li>
            <Link
              title="Navigate to payment page"
              data-tname="payment"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.MY_GIVING_PAYMENT,
              })}
            >
              Fund my account
            </Link>
          </li>
        )}
        <li>
          <Link
            title="Navigate to donate to every.org"
            data-tname="donate"
            to={getDonateRoutePath({
              format: URLFormat.RELATIVE,
              nonprofitSlug: "everydotorg",
            })}
          >
            Donate to Every.org
          </Link>
        </li>
      </ListSection>
      <ListSection data-tname="popovermenu-middle">
        <li>
          <Link
            title="Navigate to about page"
            data-tname="navAbout"
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.ABOUT,
            })}
            appearance={LinkAppearance.HYPERLINK}
          >
            About Every.org
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to for nonprofits"
            data-tname="nonprofits"
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.NONPROFITS_LANDING,
            })}
          >
            For nonprofits
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to for giver"
            data-tname="givers"
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.GIVERS_LANDING,
            })}
          >
            For donors
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to for developers"
            data-tname="developers"
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.API_LANDING,
            })}
          >
            For developers
          </Link>
        </li>
      </ListSection>
      <ListSection
        css={[
          verticalStackCss.xxs,
          horizontalStackCss.xs,
          textSizeCss.xs,
          { flexWrap: "wrap" },
        ]}
        data-tname="popovermenu-bottom"
      >
        <li>
          <Link
            title="Navigate to blog page"
            data-tname="blog"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.BLOG,
            })}
            forceExternal
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to about page"
            data-tname="navAbout"
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.ABOUT,
            })}
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to press page"
            data-tname="press"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            to={getRoutePath({
              format: URLFormat.ABSOLUTE,
              name: ClientRouteName.PRESS,
            })}
            forceExternal
          >
            Press
          </Link>
        </li>
        <li>
          <FeedbackLink
            title="Open feedback widget"
            data-tname="feedback"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            onClick={() => {
              setVisible(false);
            }}
          >
            <span>Feedback</span>
          </FeedbackLink>
        </li>
        <li>
          <Link
            title="Navigate to careers page"
            data-tname="careers"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            to={getRoutePath({
              name: ClientRouteName.CAREERS,
              format: URLFormat.ABSOLUTE,
            })}
          >
            Careers
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to terms page"
            data-tname="terms"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.TERMS_OF_SERVICE,
            })}
          >
            Terms
          </Link>
        </li>
        <li>
          <Link
            title="Navigate to privacy page"
            data-tname="privacy"
            appearance={LinkAppearance.HYPERLINK_SECONDARY}
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.PRIVACY_POLICY,
            })}
          >
            Privacy
          </Link>
        </li>
      </ListSection>
      {loggedInUser && (
        <ListSection data-tname="popovermenu-logout" css={textSizeCss.xs}>
          <li>
            <Link
              title="Log out"
              data-tname="logOut"
              appearance={LinkAppearance.HYPERLINK_SECONDARY}
              to={getLogoutApiEndpointUrl()}
              forceExternal
              css={truncatedTextCss({ numLines: 1 })}
            >
              Logout&nbsp;@{loggedInUser.username}
            </Link>
          </li>
        </ListSection>
      )}
    </Fragment>
  );
};

export function headerPopoverMenu() {
  return popoverMenu(HeaderPopoverMenuItems);
}
