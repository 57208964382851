import {
  ButtonRole,
  ButtonTargetKind,
  Button,
  ButtonSize,
} from "@components/Button";
import { RadioInput } from "@components/RadioInput";
import { SecondaryText } from "@components/textHelpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";

import { colorCssVars } from "src/theme/color";
import { roundedBorder } from "src/theme/common";
import { spacing } from "src/theme/spacing";

function visibilityExplanation(visibilityType: VisibilityTypes): string {
  switch (visibilityType) {
    case VisibilityTypes.PRIVATE:
      return "People can find and request to follow you, but only followers you accept can view your profile page activity.";
    case VisibilityTypes.PUBLIC:
      return "People can see which organizations you supported on your profile page, but not how much you gave.";
    case VisibilityTypes.TRANSPARENT:
      return "Be a public supporter and also make the exact amounts and currency of all your donations public to the entire world.";
  }
}

enum VisibilityTypes {
  PRIVATE = "Private",
  PUBLIC = "Public",
  TRANSPARENT = "Transparent",
}

function checkVisibilityType(
  isPrivate: boolean,
  publicDonationAmount: boolean
) {
  return isPrivate
    ? VisibilityTypes.PRIVATE
    : publicDonationAmount
    ? VisibilityTypes.TRANSPARENT
    : VisibilityTypes.PUBLIC;
}

const PrivacyOptions = styled.ul<{
  editing: boolean;
}>`
  > *:not(:last-child) {
    margin-bottom: ${spacing.oldM};
  }

  > li {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: ${spacing.xs};
    }

    ${(props) =>
      props.editing
        ? css`
            cursor: pointer;
            padding: ${spacing.oldM};
          `
        : ""};
    input[type="radio"] {
    }
  }
`;

const PrivacyOption = styled.li`
  ${roundedBorder};
  background: var(${colorCssVars.input.background.default});
  padding: ${spacing.oldM};
`;

const ProfileVisibilityDescription = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  h3 > *:not(:last-child) {
    margin-right: ${spacing.xs};
  }
  > *:not(:last-child) {
    margin-bottom: ${spacing.xs};
  }
`;

interface SelectProfileVisibilityProps {
  /**
   * Whether the current profile privacy is set to "private".
   */
  isPrivate: boolean;

  /**
   * Whether the current profile privacy is set to show donation amounts publicly.
   */
  publicDonationAmount: boolean;

  /**
   * Sets the profile privacy.
   */
  setPrivate: (isPrivate: boolean) => void;

  /**
   * Sets the publicity of the profile's donation amounts.
   */
  setPublicDonationAmount: (publicDonationAmount: boolean) => void;
}

/**
 * Component to activate toggling profile visibility.
 */
export const SelectProfileVisibility: React.FCC<
  SelectProfileVisibilityProps
> = ({
  isPrivate,
  setPrivate,
  publicDonationAmount,
  setPublicDonationAmount,
}) => {
  const [editing, setEditing] = useState(false);
  const currentVisibilityType = checkVisibilityType(
    isPrivate,
    publicDonationAmount
  );
  function setPrivacyAndVisibility(visibilityType: VisibilityTypes) {
    visibilityType === VisibilityTypes.PRIVATE
      ? setPrivate(true)
      : setPrivate(false);
    visibilityType === VisibilityTypes.TRANSPARENT
      ? setPublicDonationAmount(true)
      : setPublicDonationAmount(false);
  }
  return (
    <PrivacyOptions editing={editing}>
      {editing ? (
        Object.values(VisibilityTypes).map((visibilityType) => (
          <PrivacyOption
            key={visibilityType.toString()}
            onClick={() => {
              if (!editing) {
                return;
              }
              setPrivacyAndVisibility(visibilityType);
              setEditing(false);
            }}
          >
            <ProfileVisibilityDescription>
              <h3>
                <span>
                  {visibilityType === VisibilityTypes.PRIVATE
                    ? "Private Supporter"
                    : visibilityType === VisibilityTypes.TRANSPARENT
                    ? "Transparent Supporter"
                    : "Public Supporter"}
                </span>
                {visibilityType === VisibilityTypes.PUBLIC && (
                  <SecondaryText>(Recommended)</SecondaryText>
                )}
              </h3>
              <p>{visibilityExplanation(visibilityType)}</p>
            </ProfileVisibilityDescription>
            <RadioInput
              data-tname={"privacyRadio-" + visibilityType}
              name="privacyOption"
              value={`${visibilityType}`}
              checked={currentVisibilityType === visibilityType}
              onChange={() => {
                setPrivacyAndVisibility(visibilityType);
                setEditing(false);
              }}
            />
          </PrivacyOption>
        ))
      ) : (
        <PrivacyOption
          key={checkVisibilityType(isPrivate, publicDonationAmount)}
        >
          <ProfileVisibilityDescription>
            <h3>
              <span>
                {`${checkVisibilityType(
                  isPrivate,
                  publicDonationAmount
                )} Supporter`}
              </span>
            </h3>
            <p>
              {visibilityExplanation(
                checkVisibilityType(isPrivate, publicDonationAmount)
              )}
            </p>
          </ProfileVisibilityDescription>
          <Button
            data-tname="signUpEditPrivacy"
            size={ButtonSize.SMALL}
            role={ButtonRole.SECONDARY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => setEditing(true),
            }}
          >
            Edit
          </Button>
        </PrivacyOption>
      )}
    </PrivacyOptions>
  );
};
