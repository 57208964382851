import { either, isLeft } from "fp-ts/Either";
import * as t from "io-ts";

import { IotsCodecError } from "../errors/IotsCodecError";
import { MinTwoElemsArray } from "../helpers/types";

/**
 * Convenience function to decode a value with an `io-ts` codec and return it,
 * or immediately throw an error on failure
 *
 * If you wish to actually handle the error explicitly in your code (vs handling
 * it in a top-level catch statement at the project root), don't use this
 * function! Instead, prefer using the `isLeft` function from `fp-ts` on the
 * codec directly, as documented in the `io-ts`'s README (as opposed to a
 * try/catch statement with this function). It's better because TypeScript
 * doesn't support `throw`s in a function's type signature, so it's hard to keep
 * track if you've actually handled the error or not.
 */
export function decodeOrThrow<Codec extends t.Any>(
  codec: Codec,
  input: t.InputOf<Codec>
): t.TypeOf<Codec> {
  const result = codec.decode(input);
  if (isLeft(result)) {
    throw new IotsCodecError(result);
  }
  return result.right;
}

/**
 * Convenience function to decode a value with an `io-ts` codec and return it,
 * or return undefined.
 */
export function decodeOrUndefined<Codec extends t.Any>(
  codec: Codec,
  input: t.InputOf<Codec>
): t.TypeOf<Codec> {
  const result = codec.decode(input);
  if (isLeft(result)) {
    return undefined;
  }
  return result.right;
}

export function stringEnumValues<Enum extends Record<keyof Enum, string>>({
  enumObject,
}: {
  enumObject: Enum;
}): Enum[keyof Enum][] {
  return Object.values(enumObject);
}

/**
 * A custom `io-ts` type for any string enum type, i.e. an enum whose values are
 * all strings.
 *
 * NOTE: This does not work for numeric enums!
 */
export function stringEnumCodec<Enum extends Record<keyof Enum, string>>({
  name,
  enumObject,
}: {
  name: string;
  enumObject: Enum;
}) {
  return new t.Type<Enum[keyof Enum], string, unknown>(
    name,
    (unknownValue): unknownValue is Enum[keyof Enum] =>
      typeof unknownValue === "string" &&
      Object.values(enumObject).includes(unknownValue),
    (unknownValue, context) =>
      either.chain(t.string.validate(unknownValue, context), (stringValue) => {
        const enumEntry = Object.entries(enumObject).find(
          (entry) => entry[1] === stringValue
        );
        return enumEntry
          ? t.success(enumObject[enumEntry[0]])
          : t.failure(unknownValue, context);
      }),
    (enumValue) => enumValue
  );
}

/**
 * Enforces that object of a given shape is not empty
 */
export function nonEmptyObjectCodec<T extends t.Mixed>({
  codec,
}: {
  codec: T;
}) {
  return new t.Type<t.TypeOf<T>, t.OutputOf<T>, t.InputOf<T>>(
    "Non-empty object",
    (u): u is t.TypeOf<typeof codec> =>
      t.type({}).is(u) && Object.keys(u).length > 0 && codec.is(u),
    (u, ctx) =>
      !t.type({}).is(u)
        ? t.failure(u, ctx, "Input must be an object")
        : Object.keys(u).length === 0
        ? t.failure(u, ctx, "Empty object not allowed")
        : codec.validate(u, ctx),
    (val) => codec.encode(val)
  );
}

export function oneOfLiteralCodec<T extends string | number | boolean>(
  literals: [T, T, ...T[]]
) {
  return t.union(
    literals.map((value) => t.literal(value)) as MinTwoElemsArray<t.LiteralC<T>>
  );
}
