import { CauseFeedNonprofitCard } from "@components/feed/CauseFeedNonprofitCard";
import { MasonryList } from "@components/layout/MasonryList";

import { FeedItemType } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export const NonprofitFeed = ({
  nonprofitIds,
  className,
}: {
  nonprofitIds: ContextNonprofit["id"][];
  className?: string;
}) => {
  const listItems = nonprofitIds.map((fundId) => ({
    key: fundId,
    elem: (
      <CauseFeedNonprofitCard
        item={{
          type: FeedItemType.NONPROFIT_RECOMMENDATION,
          nonprofitId: fundId,
        }}
      />
    ),
  }));

  if (listItems.length === 0) {
    return null;
  }

  return (
    <MasonryList
      numColumns={{ default: 2, 950: 1 }}
      className={className}
      items={listItems}
    />
  );
};
