import { Checkbox, CheckboxSize } from "@components/Checkbox";
import { TextArea } from "@components/TextInput";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { useFundraiserCreatorName } from "@components/donate/hooks";
import styled from "@emotion/styled";
import React from "react";
import { UseFormReturn, Controller } from "react-hook-form";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { isOfficialFundraiser } from "@every.org/common/src/helpers/fundraisers";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

const StyledCheckbox = styled(Checkbox)`
  ${horizontalStackCss.xs};
  ${textSizeCss.s};
  align-items: center;
`;

const StyledLabel = styled.label`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

interface PrivateNoteInputProps {
  nonprofit: ContextNonprofit;
  form: UseFormReturn<DonateFormType>;
  fundraiser?: FundraiserResponse | null;
}

export const PrivateNoteInput = ({
  nonprofit,
  form,
  fundraiser,
}: PrivateNoteInputProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  const enablePrivateNoteField =
    nonprofit.hasAdmin && !nonprofit.metadata?.disablePrivateNotes;

  const includePrivateNote = form.watch("includePrivateNote");

  const fundraiserCreatorName = useFundraiserCreatorName(fundraiser);

  if (!enablePrivateNoteField) {
    return null;
  }

  return (
    <div css={verticalStackCss.l}>
      <Controller
        control={control}
        key="includePrivateNote"
        name="includePrivateNote"
        render={({ field: { onChange, value } }) => (
          <StyledCheckbox
            data-tname="includePrivateNote__Chekbox"
            checked={value}
            size={CheckboxSize.LARGE}
            onClick={() => {
              onChange(!value);
            }}
            css={{
              color: `var(${
                value ? colorCssVars.text.body : colorCssVars.text.secondary
              })`,
            }}
          >
            {fundraiser && !isOfficialFundraiser(fundraiser)
              ? "Add private note"
              : `Add a note for ${nonprofit.name}`}
          </StyledCheckbox>
        )}
      />
      {includePrivateNote && (
        <Controller
          control={control}
          key="privateNote"
          name="privateNote"
          render={({ field: { onChange, value } }) => (
            <div css={verticalStackCss.xs}>
              <StyledLabel htmlFor="privateNote">
                {fundraiser && !isOfficialFundraiser(fundraiser)
                  ? `Write a note for ${fundraiserCreatorName} and the ${nonprofit.name} team`
                  : "Private note"}
              </StyledLabel>
              <TextArea
                rows={3}
                label="Note"
                data-tname="privateNote"
                id="privateNote"
                value={value}
                onChange={onChange}
                collapseDescriptionSpace
                validationStatus={
                  errors.privateNote
                    ? {
                        success: false,
                        message: errors.privateNote.message,
                      }
                    : undefined
                }
              />
            </div>
          )}
        />
      )}
    </div>
  );
};
