import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { NotificationResponse } from "@every.org/common/src/codecs/entities";
import { AccountStatus } from "@every.org/common/src/entity/types";
import { NotificationMedium } from "@every.org/common/src/entity/types/notifications";
import {
  updateMyNotificationsRouteSpec,
  getMyNotificationsRouteSpec,
  UpdateMyNotificationsRequestBody,
} from "@every.org/common/src/routes/me";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { dispatchNonprofitsAction } from "src/context/NonprofitsContext";
import { NonprofitActionType } from "src/context/NonprofitsContext/types";
import { dispatchUsersAction } from "src/context/UsersContext";
import { UsersActionType } from "src/context/UsersContext/types";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

// updater stored as module variable so that fetchNotifications can access it
// and call it outside of the hook, therefore outside of the component
let setNotifications: Dispatch<
  SetStateAction<NotificationResponse[] | undefined>
> = () => {
  logger.warn({ message: "setNotifications called before being initialized." });
};

export const fetchNotifications = async () => {
  const { notifications, users, nonprofits } = await queryApi(
    getMyNotificationsRouteSpec,
    {
      body: {},
      queryParams: { medium: NotificationMedium.IN_WEB_APP },
      routeTokens: {},
    }
  );

  dispatchUsersAction({
    type: UsersActionType.ADD_USERS,
    data: users,
  });
  dispatchNonprofitsAction({
    type: NonprofitActionType.ADD_NONPROFITS,
    data: nonprofits,
  });

  setNotifications(notifications);
};

const updateNotificationsState = (
  state: NotificationResponse[] = [],
  updates: UpdateMyNotificationsRequestBody["toUpdate"]
): NotificationResponse[] =>
  state
    .map((notif) => {
      if (!(notif.id in updates)) {
        return notif;
      }
      const { setViewedAt, setInteractedWithAt, clear } = updates[notif.id];
      if (clear) {
        return undefined;
      }

      const update: Partial<NotificationResponse>[] = [
        setViewedAt ? { viewedInAppAt: setViewedAt } : {},
        setInteractedWithAt
          ? { interactedWithInAppAt: setInteractedWithAt }
          : {},
      ];
      return update.reduce((memo, update) => ({ ...memo, ...update }), notif);
    })
    .filter((notif): notif is NotificationResponse => !!notif);

const updateNotifications = async (
  toUpdate: UpdateMyNotificationsRequestBody["toUpdate"]
): Promise<void> => {
  // do nothing for empty updates
  if (!Object.keys(toUpdate).length) {
    return;
  }

  await queryApi(updateMyNotificationsRouteSpec, {
    body: { toUpdate },
    queryParams: {},
    routeTokens: {},
  });

  setNotifications((state) => updateNotificationsState(state, toUpdate));
};

/**
 * Set notifications as viewed at a given date
 */
export const setNotificationsViewed = async (
  notificationIds: Set<string>,
  date: Date
): Promise<void> => {
  const toUpdate = Object.fromEntries(
    [...notificationIds].map((id) => [id, { setViewedAt: date }])
  );
  await updateNotifications(toUpdate);
};

/**
 * Set notifications as interacted with at a given date
 */
export const setNotificationsInteractedWith = async (params: {
  notificationIds: Set<string>;
  date: Date;
}): Promise<void> => {
  const toUpdate = Object.fromEntries(
    [...params.notificationIds].map((id) => [
      id,
      { setInteractedWithAt: params.date },
    ])
  );
  await updateNotifications(toUpdate);
};

/**
 * Makes an API request to clear the specified notifications
 */
export const clearNotifications = async (params: {
  notificationIds: Set<string>;
}) => {
  const toUpdate = Object.fromEntries(
    [...params.notificationIds].map((id) => [id, { clear: true as const }])
  );

  await updateNotifications(toUpdate);
};

export function useNotifications() {
  const [notifications, _set] = useState<NotificationResponse[] | undefined>();
  setNotifications = _set;

  const authState = useContext(AuthContext);
  const accountStatus = authState.user?.accountStatus;

  useEffect(
    function initializeNotifications() {
      if (
        authState.status === AuthStatus.LOGGED_IN &&
        accountStatus === AccountStatus.ACTIVE
      ) {
        fetchNotifications();
      }
    },
    [authState.status, accountStatus]
  );

  return notifications;
}
