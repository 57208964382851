import { AvatarSize, avatarSizeToPx } from "@components/Avatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import {
  ButtonSize,
  Button,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import { EditableText } from "@components/EditableText";
import { IconSize } from "@components/Icon";
import { VerifiedStatusIcon } from "@components/VerifiedStatusIcon";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types/UUID";
import React, { useMemo, useContext, useState } from "react";

import {
  DonationResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { FeedItemType, LikeableType } from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { Link } from "src/components/Link";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  nonprofitOrUndefined,
  getNonprofit,
} from "src/context/NonprofitsContext/selectors";
import { useUser } from "src/context/UsersContext/hooks";
import { userOrUndefined } from "src/context/UsersContext/selectors";
import {
  getSaveCommentCallback,
  useEditableText,
} from "src/hooks/useEditableText";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  spacing,
  verticalStackCss,
  horizontalStackCss,
} from "src/theme/spacing";
import { textSizeCss, FontWeight } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";
import { commentTextToDisplay } from "src/utility/comment";

const ContributionContent = styled.div`
  width: 100%;
`;

const userAvatarCss = cssForMediaSize({
  min: MediaSize.MEDIUM,
  css: css`
    height: ${avatarSizeToPx[AvatarSize.SMALL]}px;
    width: ${avatarSizeToPx[AvatarSize.SMALL]}px;
    min-width: ${avatarSizeToPx[AvatarSize.SMALL]}px; ;
  `,
});

export const NonprofitPageV3RecentContributor: React.FCC<{
  donation: DonationResponse | PersonalDonationResponse;
  feedId?: UUID;
}> = React.memo(function RecentContributorImpl({ donation, feedId }) {
  const nonprofitsState = useContext(NonprofitsContext);

  const {
    toNonprofitId,
    fromUserId,
    commentText: initialCommentText,
    id,
  } = donation;

  const nonprofit = nonprofitOrUndefined(
    getNonprofit(nonprofitsState, {
      id: toNonprofitId,
    })
  );
  const user = userOrUndefined(useUser({ id: fromUserId }));

  const loggedInUser = useLoggedInUserOrUndefined();
  const isOwnDonation = loggedInUser?.id === fromUserId;

  const saveAction = useMemo(() => getSaveCommentCallback(id), [id]);
  const commentInterface = useEditableText({
    initialText: initialCommentText,
    saveAction,
    logTag: "UserDonationPage-Comment",
  });

  const commentText = commentInterface.text;
  const shortenedText = commentTextToDisplay(commentText);
  const [expanded, setExpanded] = useState(false);
  const isTextCutoff = useMemo(
    () => !expanded && shortenedText !== commentText,
    [expanded, shortenedText, commentText]
  );

  if (!nonprofit || !user) {
    return <React.Fragment />;
  }

  const name = getUserFullNameOrPlaceholder(user);

  return (
    <li
      data-feed-feedid={feedId}
      data-feed-itemid={donation.id}
      data-feed-itemtype={FeedItemType.USER_DONATION}
      css={css`
        padding-top: ${spacing.m};
        &:first-of-type {
          padding-top: 0;
        }
        /* make border only show under content, not avatar*/
        &:not(:last-child) {
          ${ContributionContent} {
            padding-bottom: ${spacing.m};
            border-bottom: 1px solid var(${colorCssVars.dividerSoft});
          }
        }
      `}
    >
      <article css={horizontalStackCss.m}>
        <ContributionContent css={verticalStackCss.s}>
          <section
            css={[
              verticalStackCss.xs,
              css`
                align-items: flex-start;
              `,
            ]}
          >
            <span
              css={[
                css`
                  font-weight: ${FontWeight.MEDIUM};
                  align-items: center;
                  ${textSizeCss.s}
                `,
                horizontalStackCss.xs,
              ]}
            >
              <UserAvatar
                size={AvatarSize.XX_SMALL}
                drawBorder
                user={user}
                customCSS={userAvatarCss}
              />
              {user.username ? (
                <Link
                  data-tname="recentContributorName"
                  data-action={ClickAction.USER}
                  to={getRoutePath({
                    format: URLFormat.RELATIVE,
                    name: ClientRouteName.USER,
                    tokens: { username: user.username },
                  })}
                  css={textSizeCss.s}
                  appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                >
                  {name}
                </Link>
              ) : (
                <span>{name}</span>
              )}
              {user.verifiedStatus && (
                <VerifiedStatusIcon
                  verifiedStatus={user.verifiedStatus}
                  size={IconSize.X_SMALL}
                />
              )}
            </span>
            <div css={{ width: "100%" }}>
              {(isOwnDonation || initialCommentText) && (
                <EditableText
                  data-tname="commentText"
                  initialText={initialCommentText}
                  editCommentCss={{ maxWidth: "100%" }}
                  textToDisplay={expanded ? commentText : shortenedText}
                  disabled={!isOwnDonation}
                  editableTextInterface={commentInterface}
                  textEnding={isTextCutoff && "..."}
                  saveOnBlur
                />
              )}
              {isTextCutoff && (
                <Button
                  data-tname="recentContributorReadMoreButton"
                  role={ButtonRole.TEXT_ONLY}
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: () => setExpanded(true),
                  }}
                >
                  Read More
                </Button>
              )}
            </div>
            <LikeButtonWithCounter
              aria-label="Like contribution"
              data-tname="likeButton"
              data-action={ClickAction.LIKE}
              role={ButtonRole.TEXT_ONLY}
              size={ButtonSize.SMALL}
              type={LikeableType.DONATION}
              id={donation.id}
              loggedInUserLikes={donation.likesInfo.hasLoggedInUserLiked}
              likeCount={donation.likesInfo.count}
              css={{ padding: 0, paddingRight: spacing.xxs }}
            />
          </section>
        </ContributionContent>
      </article>
    </li>
  );
});
