import { memoize } from "@every.org/common/src/helpers/functions";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export const getWebAuth = memoize({
  fn: async () => {
    return new (await import("auth0-js")).WebAuth({
      domain: assertEnvPresent(
        process.env.NEXT_PUBLIC_AUTH0_DOMAIN ||
          process.env.REACT_APP_AUTH0_DOMAIN,
        "AUTH0_DOMAIN"
      ),
      clientID: assertEnvPresent(
        process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ||
          process.env.REACT_APP_AUTH0_CLIENT_ID,
        "AUTH0_CLIENT_ID"
      ),
    });
  },
});
