import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  DonateFormType,
  DONATE_FORM_ERROR,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { spacing } from "@every.org/common/src/display/spacing";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";

const BackButtonContainer = styled.div`
  padding: 20px;
  ${cssForMediaSize({
    max: MediaSize.MEDIUM_LARGE,
    css: css`
      padding: 0;
      margin-bottom: ${spacing.l};
    `,
  })};
`;

interface GoBackButtonProps {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
}

export function GoBackButton({ form, formContext }: GoBackButtonProps) {
  const {
    formState: { errors },
  } = form;

  const navigate = useNavigate();

  const clearFormError = useCallback(async () => {
    if (errors.DONATE_FORM_ERROR) {
      form.clearErrors(DONATE_FORM_ERROR);
      await formContext.refreshNonce();
    }
  }, [form, formContext, errors]);

  const goBack = useCallback(() => {
    clearFormError();
    navigate(-1);
  }, [navigate, clearFormError]);

  return (
    <BackButtonContainer>
      <Button
        role={ButtonRole.TEXT_ONLY}
        onClick={{ kind: ButtonTargetKind.FUNCTION, action: goBack }}
        data-tname={`donateClickBack__${DonationFlowPaymentOption.DAF}`}
        contentCss={horizontalStackCss.xxs}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.ACCENT}
        />
        <span>Back</span>
      </Button>
    </BackButtonContainer>
  );
}
