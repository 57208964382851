import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

export const sharedDonationCodec = t.type({
  userId: uuidCodec,
  nonprofitSlug: t.string,
  donationChargeId: uuidCodec,
});
export type SharedDonationData = t.TypeOf<typeof sharedDonationCodec>;

export const initialReferringPageCodec = t.type({
  referrer: t.string,
});

export const landingPageCodec = t.type({
  landingPage: t.string,
});

export const signupInviteDataCodec = t.intersection([
  t.type({ inviteCode: t.string }),
  t.partial({ invitedByUserId: uuidCodec }),
]);
export type InviteData = t.TypeOf<typeof signupInviteDataCodec>;
