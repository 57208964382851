import { CloudinaryImg, CloudinaryImgProps } from "@components/CloudinaryImg";
import { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import memoize from "lodash.memoize";
import React, { useState, useEffect } from "react";

import { objectFromEnumValues } from "@every.org/common/src/helpers/objectUtilities";

import { colorCssVars } from "src/theme/color";

/**
 * Standard Avatar sizes, expressed as number of px
 */
export enum AvatarSize {
  XX_SMALL = "XXS",
  X_SMALL = "XS",
  SMALL = "S",
  MEDIUM_SMALL = "MS",
  MEDIUM = "M",
  LARGE = "L",
  X_LARGE = "XL",
  XX_LARGE = "XXL",
  XXX_LARGE = "XXXL", // Used during sign up flow
}

export const avatarSizeToPx: { [key in AvatarSize]: number } = {
  [AvatarSize.XX_SMALL]: 24,
  [AvatarSize.X_SMALL]: 32,
  [AvatarSize.SMALL]: 40,
  [AvatarSize.MEDIUM_SMALL]: 56,
  [AvatarSize.MEDIUM]: 64,
  [AvatarSize.LARGE]: 80,
  [AvatarSize.X_LARGE]: 96,
  [AvatarSize.XX_LARGE]: 120,
  [AvatarSize.XXX_LARGE]: 184,
};

interface AvatarImageProps {
  size: AvatarSize;
  drawBorder: boolean;
}

// This container is needed because the inner shadow is not applicable to img
const shadowContainerCss = memoize(
  ({ drawBorder }: Pick<AvatarImageProps, "drawBorder">) => {
    return [
      css`
        position: relative;
        border-radius: 50%;
      `,
      drawBorder &&
        css`
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 0px 1px rgba(150, 150, 150, 0.15) inset;
            border-radius: 50%;
            overflow: hidden;
          }
        `,
    ];
  }
);
export const avatarCssBySize = objectFromEnumValues({
  enum: AvatarSize,
  mapFn: (size) => css`
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    height: ${avatarSizeToPx[size]}px;
    width: ${avatarSizeToPx[size]}px;
    /*
     * Not sure why both of these are necessary, but avatar gets squished if
     * min-width isn't set. See #1834.
     */
    min-width: ${avatarSizeToPx[size]}px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});

const placeholderCss = memoize(
  (params: { size: AvatarSize; hasPlaceholder: boolean }) => [
    css`
      display: inline-block;
    `,
    avatarCssBySize[params.size],
    params.hasPlaceholder &&
      css`
        background: var(${colorCssVars.background.faded});
      `,
  ]
);

export interface RawAvatarProps
  extends Omit<
    CloudinaryImgProps,
    "cloudinaryId" | "dimensions" | "onError" | "placeholder" | "alt"
  > {
  size: AvatarSize;
  cloudinaryId?: string | null;
  /**
   * Element to render instead of Avatar if not present
   */
  placeholder?: React.ReactNode | string;
  /**
   * If true return null for no image or broken image
   */
  disablePlaceholder?: boolean;
  /**
   * If true draws a border around the image; doesn't draw around placeholder
   */
  drawBorder?: boolean;
  className?: string;
  alt?: string;
  customCSS?: CSSInterpolation;
}

export const RawAvatar: React.FCC<RawAvatarProps> = ({
  className,
  size,
  cloudinaryId,
  drawBorder = false,
  disablePlaceholder = false,
  placeholder,
  alt,
  customCSS = null,
  ...rest
}) => {
  const [brokenImage, setBrokenImage] = useState(false);

  useEffect(() => {
    setBrokenImage(false);
  }, [cloudinaryId]);

  if ((brokenImage || !cloudinaryId) && disablePlaceholder) {
    return null;
  }

  return cloudinaryId && !brokenImage ? (
    <div
      css={[
        avatarCssBySize[size],
        customCSS,
        shadowContainerCss({ drawBorder }),
      ]}
    >
      <CloudinaryImg
        {...rest}
        className={className}
        cloudinaryId={cloudinaryId}
        onError={() => setBrokenImage(true)}
        dimensions={{
          /**
           * Doubling the resolution size of the image we are uploading to improve its quality.
           */
          width: avatarSizeToPx[size] * 2,
          height: avatarSizeToPx[size] * 2,
        }}
        layout={"intrinsic"}
        alt={alt || "User or nonprofit avatar"} // TODO: more specific alt text
      />
    </div>
  ) : (
    <div
      css={[
        customCSS,
        placeholderCss({ size, hasPlaceholder: !!placeholder }),
        shadowContainerCss({ drawBorder }),
      ]}
      className={className}
    >
      {placeholder}
    </div>
  );
};
