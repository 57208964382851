/**
 * This file defines some common ways to apply our color styles onto an SVG,
 * that can be used as shortcuts when styling our actual SVG icons.
 */
import { css } from "@emotion/react";
import memoize from "lodash.memoize";
import dynamic from "next/dynamic";

import { objectFromEnumValues } from "@every.org/common/src/helpers/objectUtilities";

import { colorCssVars } from "src/theme/color";

export enum IconDisplay {
  SECONDARY = "SECONDARY",
  CURRENT_COLOR = "CURRENT_COLOR",
  ACCENT = "ACCENT",
  ERROR = "ERROR",
  TEXT = "TEXT",
}

export enum IconSize {
  XX_SMALL = "XX_SMALL",
  X_SMALL = "X_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  X_LARGE = "X_LARGE",
  XX_LARGE = "XX_LARGE",
  XXX_LARGE = "XXX_LARGE",
}

/**
 * Mapping from icon size enum value to resulting icon height in number of px
 */
export const heightForIconSize: {
  readonly [key in IconSize]: number;
} = {
  [IconSize.XX_SMALL]: 12,
  [IconSize.X_SMALL]: 16,
  [IconSize.SMALL]: 20,
  [IconSize.MEDIUM]: 24,
  [IconSize.LARGE]: 32,
  [IconSize.X_LARGE]: 40,
  [IconSize.XX_LARGE]: 56,
  [IconSize.XXX_LARGE]: 80,
};

/**
 * For icons that are defined as strokes (not fills), mapping from icon size
 * enum value to resulting stroke width in number of px
 */
const strokeWidthForIconSize: {
  readonly [key in IconSize]: number;
} = {
  [IconSize.XX_SMALL]: 1,
  [IconSize.X_SMALL]: 1.2,
  [IconSize.SMALL]: 1.5,
  [IconSize.MEDIUM]: 2,
  [IconSize.LARGE]: 2,
  [IconSize.X_LARGE]: 2.1,
  [IconSize.XX_LARGE]: 2.2,
  [IconSize.XXX_LARGE]: 3,
};

export interface BaseIconProps {
  className?: string;
  display: IconDisplay;
  size: IconSize;
}

export const colorForDisplay: { readonly [key in IconDisplay]: string } = {
  [IconDisplay.CURRENT_COLOR]: "currentcolor",
  [IconDisplay.SECONDARY]: `var(${colorCssVars.text.secondary})`,
  [IconDisplay.ACCENT]: `var(${colorCssVars.accent.large})`,
  [IconDisplay.TEXT]: `var(${colorCssVars.text.body})`,
  [IconDisplay.ERROR]: `var(${colorCssVars.input.border.error})`,
};

export const baseIconCssBySize = objectFromEnumValues({
  enum: IconSize,
  mapFn: (size) => css`
    display: block; /* for predictable alignment; style as inline if using with text */
    width: ${heightForIconSize[size]}px;
    height: ${heightForIconSize[size]}px;
  `,
});

/**
 * CSS Variable used to dictate icon color
 *
 * If you want to make an exception to the allowed `IconDisplay` values, then
 * you can edit this variable in your component; however if you anticipate this
 * to be useful in many places, it may be a worthy addition of an `IconDisplay`
 * value.
 *
 * @example
 * const MyIcon = styled(QuestionIcon)`
 *   &:hover {
 *     ${iconColorCssVariable}: tomato;
 *   }
 * `;
 */
export const iconColorCssVariable = "--iconColor";
/**
 * Shortcut for defining an Icon from an SVG, where color is provided by setting
 * stroke on child elements, and stroke size may change based on display size
 */
export const strokeIconCss = memoize(
  (props: BaseIconProps) => [
    baseIconCssBySize[props.size],
    css`
      ${iconColorCssVariable}: ${colorForDisplay[props.display]};
      * {
        vector-effect: non-scaling-stroke;
        stroke: var(${iconColorCssVariable});
        stroke-width: ${strokeWidthForIconSize[props.size]};
      }
    `,
  ],
  JSON.stringify
);

/**
 * Shortcut for defining an Icon from an SVG, where color is provided by setting
 * fill on child elements
 */
export const fillIconCss = memoize(
  (props: BaseIconProps) => [
    baseIconCssBySize[props.size],
    css`
      ${iconColorCssVariable}: ${colorForDisplay[props.display]};
      * {
        fill: var(${iconColorCssVariable});
      }
    `,
  ],
  JSON.stringify
);

interface IconFallbackProps extends Pick<BaseIconProps, "size"> {
  className?: string;
}
function IconFallback({ size, className }: IconFallbackProps) {
  return <div className={className} css={baseIconCssBySize[size]} />;
}

type DefaultComponentExport<Props> = () => Promise<{
  default: (props: Props) => React.ReactNode | null;
}>;

export interface IconProps extends BaseIconProps {
  iconImport: DefaultComponentExport<BaseIconProps>;
}

/**
 * Icon component that can be used throughout the app. Loads the icons lazily so
 * that it doesn't block the initial load.
 */
export function Icon({ iconImport, ...rest }: IconProps) {
  const Component = dynamic(iconImport, {
    loading: () => <IconFallback {...rest} />,
  });
  return <Component {...rest} />;
}
