import {
  ButtonRole,
  ButtonSize,
  Button,
  ButtonTargetKind,
} from "@components/Button";
import { DonationApproveButton } from "@components/DonationApproveButton";
import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { Modal, DESKTOP_MODAL_MIN_WIDTH } from "@components/Modal";
import { DonationMoreButton } from "@components/feed/DonationMoreButton";
import { DonationShareButton } from "@components/feed/DonationShareButton";
import { JoinedUserList } from "@components/feed/JoinedUserList";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types/UUID";
import React, { useState } from "react";

import {
  PersonalDonationChargeResponse,
  DonationChargeResponse,
  DonationResponse,
} from "@every.org/common/src/codecs/entities";
import {
  DonationFrequency,
  LikeableType,
  FeedPage,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";

export const FeedCardActionsContainer = styled.div`
  align-items: center;
  ${horizontalStackCss.xs};
`;

export const ShareAndLikeContainer = styled.div`
  ${horizontalStackCss.xxxs};
  align-items: center;
`;

export const PrimaryActionsContainer = styled.div`
  margin-left: auto;
  ${horizontalStackCss.xs};
  align-items: center;
`;

const ManageButton = (props: { donationId: DonationResponse["id"] }) => (
  <Button
    data-tname={"feedManageButton"}
    role={ButtonRole.TEXT_ONLY}
    size={ButtonSize.MEDIUM}
    onClick={{
      kind: ButtonTargetKind.LINK,
      to: getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.MY_GIVING_RECURRING_UPDATE,
        tokens: { donationId: props.donationId },
      }),
    }}
    css={css`
      /* simulate no text only button height */
      padding: ${spacing.xs} 0;
    `}
    contentCss={css`
      ${horizontalStackCss.xs};
      align-items: center;
    `}
  >
    <Icon
      iconImport={() => import("@components/Icon/icons/CalendarIcon")}
      size={IconSize.MEDIUM}
      display={IconDisplay.CURRENT_COLOR}
    />
    <span>Manage</span>
  </Button>
);

export interface DonationCardActionsProps {
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
  nonprofit: ContextNonprofit;
  page: FeedPage;
  onDonationArchived: (
    donationCharge: DonationChargeResponse | PersonalDonationChargeResponse
  ) => void;
  numJoined?: number;
}

function CtaButton({
  nonprofit,
  donationCharge,
  loggedInUserId,
}: {
  nonprofit: ContextNonprofit;
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
  loggedInUserId: UUID | undefined;
}) {
  const isOwnDonation = loggedInUserId === donationCharge.fromUserId;
  const isOneTimeDonation =
    donationCharge.donation.frequency === DonationFrequency.ONCE;
  if (isOwnDonation) {
    if (isOneTimeDonation) {
      return (
        <DonateButton
          role={ButtonRole.SECONDARY}
          data-tname={"feedMakeMonthlyButton"}
          data-action={ClickAction.DONATE}
          isDisbursable={nonprofit.isDisbursable}
          primarySlug={nonprofit.primarySlug}
        >
          Give again
        </DonateButton>
      );
    }

    return <ManageButton donationId={donationCharge.donation.id} />;
  }
  return (
    <DonateButton
      role={ButtonRole.PRIMARY}
      data-tname={"feedDonateButton"}
      data-action={ClickAction.DONATE}
      isDisbursable={nonprofit.isDisbursable}
      primarySlug={nonprofit.primarySlug}
      donationToJoinId={donationCharge.donation.id}
      userToJoinId={donationCharge.donation.fromUserId}
    >
      Donate
    </DonateButton>
  );
}

export const DonationCardActions: React.FCC<DonationCardActionsProps> = ({
  donationCharge,
  numJoined = 0,
  nonprofit,
  page,
  onDonationArchived,
}) => {
  const loggedInUserId = useLoggedInUserOrUndefined()?.id;
  const isOwnDonation = loggedInUserId === donationCharge.fromUserId;
  const isOneTimeDonation =
    donationCharge.donation.frequency === DonationFrequency.ONCE;
  const isOwnOneTimeDonation = isOwnDonation && isOneTimeDonation;

  if (page === FeedPage.ADMIN) {
    return <DonationApproveButton donationCharge={donationCharge} />;
  }

  return (
    <FeedCardActionsContainer>
      <ShareAndLikeContainer>
        <DonationShareButton
          donation={donationCharge.donation}
          data-action={ClickAction.SHARE}
          buttonCss={{ padding: spacing.xxs }}
        />
        <LikeButtonWithCounter
          aria-label="Like this donation"
          data-tname="likeButton"
          data-action={ClickAction.LIKE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          type={LikeableType.DONATION}
          id={donationCharge.donation.id}
          loggedInUserLikes={
            donationCharge.donation.likesInfo.hasLoggedInUserLiked
          }
          likeCount={donationCharge.donation.likesInfo.count}
        />
      </ShareAndLikeContainer>
      <PrimaryActionsContainer>
        {[FeedPage.USER_PROFILE, FeedPage.DONATION_MODAL].includes(page) &&
          isOwnDonation && (
            <DonationMoreButton
              nonprofit={nonprofit}
              donationCharge={donationCharge}
              onDonationArchived={onDonationArchived}
            />
          )}
        {/* Don't show num joined if the button is long */}
        {!isOwnOneTimeDonation && numJoined > 0 ? (
          <NumJoinedButton
            data-tname="feedNumJoined"
            data-action={ClickAction.JOINED}
            numJoined={numJoined}
            joinedDonationId={donationCharge.donation.id}
          />
        ) : undefined}
        <CtaButton
          nonprofit={nonprofit}
          donationCharge={donationCharge}
          loggedInUserId={loggedInUserId}
        />
      </PrimaryActionsContainer>
    </FeedCardActionsContainer>
  );
};

export const NumJoinedButton: React.FCC<{
  "data-tname": string;
  numJoined: number;
  joinedDonationId: DonationChargeResponse["id"];
}> = ({ numJoined, joinedDonationId, "data-tname": dataTname }) => {
  const [showJoinedModal, setShowJoinedModal] = useState(false);
  return (
    <React.Fragment>
      <Button
        data-tname={dataTname}
        role={ButtonRole.TEXT_ONLY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => setShowJoinedModal(true),
        }}
      >
        <span css={textSizeCss.xs}>{numJoined} joined</span>
      </Button>
      <Modal
        isOpen={showJoinedModal}
        headerText="Joined by"
        showHeader
        onRequestClose={() => setShowJoinedModal(false)}
      >
        <JoinedUserList
          css={css`
            /* Do not let it be so wide */
            max-width: ${DESKTOP_MODAL_MIN_WIDTH};
          `}
          joinedDonationId={joinedDonationId}
        />
      </Modal>
    </React.Fragment>
  );
};
