import { Card } from "@components/Card";
import { MarkdownText } from "@components/MarkdownText";
import { css } from "@emotion/react";

import { spacing } from "@every.org/common/src/display/spacing";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { TextSize, textSizeCss, compositionTextCss } from "src/theme/text";

export const LearnMore = ({
  content,
  className,
}: {
  className?: string;
  content: string;
}) => {
  return (
    <Card
      className={className}
      css={[
        textSizeCss[TextSize.m],
        css`
          width: 100%;
        `,
        cssForMediaSize({
          min: MediaSize.LARGE,
          css: css`
            padding: ${spacing.xxxxl};
          `,
        }),
        cssForMediaSize({
          max: MediaSize.MEDIUM,
          css: css`
            padding: ${spacing.xl};
          `,
        }),
      ]}
    >
      <div css={compositionTextCss}>
        <MarkdownText content={content} />
      </div>
    </Card>
  );
};
