import { Big } from "big.js";

export function max(a: Big, b: Big) {
  return a.gt(b) ? a : b;
}

export function min(a: Big, b: Big) {
  return a.lt(b) ? a : b;
}

/**
 * Function to check if an unknownValue is a Big instance.
 *
 * @param unknownValue
 * @returns
 *   boolean value indicating is the variable is a Big
 *
 * When the `context` changes because it was passed along functions, `instanceof` can return unexpected results.
 * Particularly we have seen this happening with `instanceof Big` inside io-ts's decode functions in nextjs (it returns a false false).
 * That's why we have the second check, which is not safe because any object that has all 3 expected functions
 * would pass, and if Big.js updates and uses different properties then it will suddenly not pass.
 *
 * Context changes: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof#instanceof_and_multiple_context_e.g._frames_or_windows
 */
export function isBig(unknownValue: unknown): unknownValue is Big {
  return (
    unknownValue instanceof Big ||
    (unknownValue instanceof Object &&
      unknownValue.constructor.prototype["toFixed"] instanceof Function &&
      unknownValue.constructor.prototype["gt"] instanceof Function &&
      unknownValue.constructor.prototype["toPrecision"] instanceof Function)
  );
}
