import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  DonateFormType,
  paymentFlowOptionToPaymentMethodMap,
} from "@components/donate/DonateV3/types";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

interface UpdatePaymentMethodArgs {
  paymentOption: DonationFlowPaymentOption;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
}

export function useSyncPaymentMethod({
  paymentOption,
  form,
  formContext,
}: UpdatePaymentMethodArgs): void {
  useEffect(() => {
    form.setValue(
      "paymentMethod",
      paymentFlowOptionToPaymentMethodMap[paymentOption]
    );
    formContext.setCurrentPaymentOption(paymentOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOption]);
  return;
}
