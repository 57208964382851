import { FundraiserValidationErrors } from "src/pages/Fundraiser/EditForm/types";

export default class FundraiserValidationError extends Error {
  public validation: FundraiserValidationErrors;

  public constructor(params: {
    message: string;
    validation: FundraiserValidationErrors;
  }) {
    super(params.message);
    this.validation = params.validation;
  }
}
