import { AvatarSize } from "@components/Avatar";
import { Card } from "@components/Card";
import { CardCover } from "@components/CardCover";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Link, LinkAppearance } from "@components/Link";
import { Popover } from "@components/Popover";
import { editorTextCss } from "@components/RichTextEditor/styles";
import {
  TagLink,
  TagIllustrationLabel,
} from "@components/TagIllustrationLabel";
import { TextWithReadMore } from "@components/TextWithReadMore";
import { WebsiteLink } from "@components/WebsiteLink";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import { spacing } from "@every.org/common/src/display/spacing";
import { PROFILE_IMG_PLACEHOLDER_ID } from "@every.org/common/src/entity/constants";
import {
  Currency,
  NonprofitAdminStatus,
  NonprofitType,
} from "@every.org/common/src/entity/types";
import {
  isValidEin,
  formatEinWithDash,
} from "@every.org/common/src/entity/types/ein";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { getRevenue } from "@every.org/common/src/helpers/nonprofit";
import { displayNonprofitLocation } from "@every.org/common/src/helpers/nonprofitLocation";
import {
  cleanSocialNetworkHandle,
  SocialHandleKey,
  youtubeProfileHandle,
} from "@every.org/common/src/helpers/socialNetworks";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useNonprofitAdminData } from "src/hooks/useNonprofitAdminData";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { mailToLink } from "src/utility/helpers";

const StyledCard = styled(Card)`
  padding: 0;
  overflow: hidden;
`;

const textContentCss = css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};
  padding: ${spacing.l};
`;

interface NonprofitPageV3AboutCardProps {
  nonprofit: ContextNonprofit;
}

export const NonprofitPageV3AboutCard = ({
  nonprofit,
}: NonprofitPageV3AboutCardProps) => {
  const {
    websiteUrl,
    descriptionLong,
    locationAddress,
    revenueAmt,
    nonprofitTags,
    twitterHandle,
    facebookHandle,
    instagramHandle,
    linkedInHandle,
    youtubeHandle,
    coverImageCloudinaryId,
    isDisbursable,
  } = nonprofit;

  const nonprofitAdmin = useNonprofitAdminData(nonprofit.id);
  const isNonprofitAdmin =
    nonprofitAdmin?.status === NonprofitAdminStatus.CONFIRMED;

  return (
    <StyledCard>
      <CardCover
        coverImageCloudinaryId={
          coverImageCloudinaryId || PROFILE_IMG_PLACEHOLDER_ID
        }
        alt={nonprofit.metadata?.coverImageAltText}
        height={160}
        largeScreenHeight={320}
        editable={false}
        priority
        isLargeCard
      />
      <div css={textContentCss}>
        {!isDisbursable && (
          <React.Fragment>
            <DonationDisabledNotice nonprofit={nonprofit} />
            <Divider />
          </React.Fragment>
        )}
        {!!nonprofitTags?.length && (
          <React.Fragment>
            <AboutCardTagsList nonprofitTags={nonprofitTags} />
            <Divider />
          </React.Fragment>
        )}
        {descriptionLong && (
          <React.Fragment>
            <span css={editorTextCss}>
              <TextWithReadMore text={descriptionLong} numLines={8} markdown />
            </span>
            <Divider />
          </React.Fragment>
        )}
        <div
          css={[
            verticalStackCss.m,
            { color: `var(${colorCssVars.text.secondary})` },
          ]}
        >
          {locationAddress && (
            <AboutCardLocation locationAddress={locationAddress} />
          )}
          {revenueAmt && (
            <RevenueIndicator
              revenueAmt={revenueAmt}
              iconSize={IconSize.MEDIUM}
            />
          )}
          {websiteUrl && (
            <WebsiteLink
              itemProp="sameAs"
              showFullUrl
              hideScheme
              websiteUrl={websiteUrl}
              iconDisplay={IconDisplay.CURRENT_COLOR}
              iconSize={IconSize.MEDIUM}
            />
          )}
          <AboutCardSocialMediaList
            twitterHandle={twitterHandle}
            facebookHandle={facebookHandle}
            instagramHandle={instagramHandle}
            linkedInHandle={linkedInHandle}
            youtubeHandle={youtubeHandle}
          />
          <RegisteredNonprofit nonprofit={nonprofit} />
          {isNonprofitAdmin && (
            <AboutCardEditButton nonprofit={nonprofit} isNonprofitAdmin />
          )}
        </div>
      </div>
    </StyledCard>
  );
};

const AboutCardEditButton = ({
  nonprofit,
  isNonprofitAdmin,
}: {
  nonprofit: ContextNonprofit;
  isNonprofitAdmin?: boolean;
}) => {
  return (
    <Link
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: isNonprofitAdmin
          ? ClientRouteName.NONPROFIT_ADMIN_EDIT
          : ClientRouteName.EDIT_NONPROFIT,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })}
      data-tname="nonprofitProfileRequestEdit"
      appearance={LinkAppearance.HYPERLINK}
    >
      {isNonprofitAdmin ? "Edit profile" : "Suggest a profile edit"}
    </Link>
  );
};

const Divider = () => {
  return (
    <div
      css={{
        width: "100%",
        borderBottom: `1px solid var(${colorCssVars.dividerSoft});`,
      }}
    />
  );
};

export const DonationDisabledNotice = ({
  nonprofit,
}: {
  nonprofit: ContextNonprofit;
}) => {
  return (
    <p
      css={css`
        color: ${SHARED_PALETTE.error};

        > a {
          color: ${SHARED_PALETTE.error};
        }
      `}
    >
      We don&rsquo;t currently support donations to this nonprofit. To request
      we add support{" "}
      <Link
        forceExternal
        target="requestNonprofit"
        appearance={LinkAppearance.HYPERLINK}
        to={mailToLink({
          address: "requestNonprofit@every.org",
          subject: `Please allow donations supporting ${nonprofit.name} (EIN ${nonprofit.ein})`,
        })}
        data-tname="nonprofitProfileRequestDonate"
      >
        email us
      </Link>
      .
    </p>
  );
};

export const IconRow = styled.div`
  ${horizontalStackCss.xs};
  align-items: center;
`;

export function RevenueIndicator({
  revenueAmt,
  iconSize = IconSize.X_SMALL,
  iconDisplay = IconDisplay.CURRENT_COLOR,
}: {
  revenueAmt: Exclude<ContextNonprofit["revenueAmt"], null>;
  iconSize?: IconSize;
  iconDisplay?: IconDisplay;
}) {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <Popover
        placement={"bottom"}
        content={`Revenue: ${displayCurrencyValueInUserLocale({
          minDenomCurrencyValue: {
            amountInMinDenom: revenueAmt,
            // TODO #8478: assuming this will be in usd for everybody or currency doesn't matter
            currency: Currency.USD,
          },
        })}`}
      >
        <IconRow
          css={css`
            cursor: help;
            color: var(${colorCssVars.text.secondary});
          `}
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/OrganizationIcon")}
            display={iconDisplay}
            size={iconSize}
          />
          <span>{getRevenue(revenueAmt)} organization</span>
        </IconRow>
      </Popover>
    </div>
  );
}

export const AboutCardTagsList = ({
  nonprofitTags,
  className,
}: {
  nonprofitTags?: TagResponse[];
  className?: string;
}) => {
  if (!nonprofitTags?.length || !nonprofitTags) {
    return null;
  }

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing.s};
      `}
    >
      {nonprofitTags.map((tag) => {
        return (
          <TagLink
            key={tag.tagName}
            appearance={LinkAppearance.UNSTYLED}
            data-tname={`nonprofitTag-${tag.tagName}`}
            tagName={tag.tagName}
          >
            <TagIllustrationLabel size={AvatarSize.XX_SMALL} tag={tag} />
          </TagLink>
        );
      })}
    </div>
  );
};

export const AboutCardSocialMediaList = ({
  twitterHandle,
  facebookHandle,
  instagramHandle,
  linkedInHandle,
  youtubeHandle,
}: {
  twitterHandle?: string | null;
  facebookHandle?: string | null;
  instagramHandle?: string | null;
  linkedInHandle?: string | null;
  youtubeHandle?: string | null;
}) => {
  const socialMedia = useMemo(
    () =>
      [
        {
          name: "Twitter",
          urlString: twitterHandle
            ? `https://twitter.com/${cleanSocialNetworkHandle(twitterHandle)}`
            : undefined,
          iconImport: () => import("@components/Icon/icons/XLogoCircleIcon"),
        },
        {
          name: "Facebook",
          urlString: facebookHandle
            ? `https://facebook.com/${cleanSocialNetworkHandle(facebookHandle)}`
            : undefined,
          iconImport: () => import("@components/Icon/icons/FacebookIcon"),
        },
        {
          name: "Instagram",
          urlString: instagramHandle
            ? `https://instagram.com/${cleanSocialNetworkHandle(
                instagramHandle
              )}`
            : undefined,
          iconImport: () =>
            import("@components/Icon/icons/InstagramLogoCircleIcon"),
        },
        {
          name: "LinkedIn",
          urlString: linkedInHandle
            ? `https://linkedin.com/${cleanSocialNetworkHandle(
                linkedInHandle,
                SocialHandleKey.LINKEDIN
              )}`
            : undefined,
          iconImport: () =>
            import("@components/Icon/icons/LinkedinLogoCircleIcon"),
        },
        {
          name: "YouTube",
          urlString: youtubeHandle
            ? `https://youtube.com/${youtubeProfileHandle(youtubeHandle)}`
            : undefined,
          iconImport: () =>
            import("@components/Icon/icons/YoutubeLogoCircleIcon"),
        },
      ].filter(({ urlString }) => urlString),
    [
      facebookHandle,
      instagramHandle,
      linkedInHandle,
      twitterHandle,
      youtubeHandle,
    ]
  );

  if (!socialMedia.length) {
    return null;
  }

  return (
    <div css={horizontalStackCss.xs}>
      {socialMedia.map(({ name, urlString, iconImport }) => {
        return (
          urlString && (
            <Link
              title={`Link to ${name} page`}
              key={name}
              to={urlString}
              data-tname={`nonprofit${name}`}
            >
              <Icon
                iconImport={iconImport}
                display={IconDisplay.ACCENT}
                size={IconSize.MEDIUM}
              />
            </Link>
          )
        );
      })}
    </div>
  );
};

export const AboutCardLocation = ({
  locationAddress,
}: {
  locationAddress: string;
}) => {
  const locationToDisplay = displayNonprofitLocation({ locationAddress });

  if (!locationToDisplay) {
    return null;
  }

  return (
    <IconRow>
      <Icon
        iconImport={() => import("@components/Icon/icons/LocationIcon")}
        display={IconDisplay.CURRENT_COLOR}
        size={IconSize.MEDIUM}
      />
      <span>{locationToDisplay}</span>
    </IconRow>
  );
};

export const RegisteredNonprofit = ({
  nonprofit,
}: {
  nonprofit: ContextNonprofit;
}) => {
  if (
    nonprofit.type !== NonprofitType.NONPROFIT ||
    !nonprofit.ein ||
    !isValidEin(nonprofit.ein)
  ) {
    return null;
  }
  return (
    <span
      css={[
        textSizeCss.xs,
        css`
          color: var(${colorCssVars.text.secondary});
        `,
      ]}
    >
      A 501(c)(3) nonprofit, EIN {formatEinWithDash(nonprofit.ein)}
    </span>
  );
};
