import { Button, ButtonRole, ButtonSize, OnClick } from "@components/Button";
import { Icon, IconProps, IconDisplay, IconSize } from "@components/Icon";
import { css } from "@emotion/react";
import React from "react";

import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";

const popoverButtonContentCss = css`
  ${horizontalStackCss.xs};
  align-items: center;
`;

export interface PopoverItemButtonProps {
  iconImport?: IconProps["iconImport"];
  label: string;
  onClick: OnClick;
  "data-tname": string;
  hideItem?: boolean;
}

const PopoverItemButton: React.FCC<PopoverItemButtonProps> = ({
  iconImport,
  label,
  ...rest
}) => {
  return (
    <Button
      css={{ width: "100%" }}
      contentCss={popoverButtonContentCss}
      role={ButtonRole.TEXT_SECONDARY}
      size={ButtonSize.SMALL}
      {...rest}
    >
      {iconImport && (
        <Icon
          iconImport={iconImport}
          display={IconDisplay.SECONDARY}
          size={IconSize.X_SMALL}
        />
      )}
      <span css={{ paddingRight: spacing.xxs }}>{label}</span>
    </Button>
  );
};

interface PopoverListProps {
  items: PopoverItemButtonProps[];
}

export const PopoverList = React.forwardRef<HTMLUListElement, PopoverListProps>(
  function PopoverListImpl({ items }, ref) {
    return (
      <ul ref={ref} css={[verticalStackCss.s, { lineHeight: 1 }]}>
        {items.map(
          (buttonProps) =>
            !buttonProps.hideItem && (
              // if data-tname is not a good key prop here, edit to make user-settable
              <li key={buttonProps["data-tname"]}>
                <PopoverItemButton {...buttonProps} />
              </li>
            )
        )}
      </ul>
    );
  }
);
