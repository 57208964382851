import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { CauseCategory } from "@every.org/common/src/entity/types";
import { objectFromEnumValues } from "@every.org/common/src/helpers/objectUtilities";

import {
  colorCssVars,
  darkBgThemeNoBgCss,
  lightBgThemeNoBgCss,
} from "src/theme/color";
import { horizontalStackCss, spacing } from "src/theme/spacing";

const CAUSES_LIST_MAX_HEIGHT = 32;
const HIGHLIGHTED_CAUSES_LIST_MAX_HEIGHT = 44;

export const StyledList = styled.ul<{ highlighted?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  ${({ highlighted }) => css`
    max-height: ${highlighted
      ? HIGHLIGHTED_CAUSES_LIST_MAX_HEIGHT
      : CAUSES_LIST_MAX_HEIGHT}px;
  `}
`;
export const PopoverItemsContainer = styled.div`
  ${horizontalStackCss.none}
  margin-right: ${spacing.xs};
`;

export const StyledListItem = styled.li`
  position: relative;
  &:not(:first-of-type) {
    margin-left: -${spacing.xxs};
  }
`;

export const tagLabelCss = objectFromEnumValues({
  enum: CauseCategory,
  mapFn: (causeCategory) =>
    css`
      ${darkBgThemeNoBgCss};
      color: var(${colorCssVars.causeCategory[causeCategory].largeHighlight});

      &:hover {
        ${lightBgThemeNoBgCss};
        color: var(${colorCssVars.causeCategory[causeCategory].small});
      }
    `,
});

export const HighlightListItem = styled(StyledListItem)<{
  causeCategory: CauseCategory;
}>`
  border-radius: 100%;
  padding: ${spacing.xxxs};
  background-color: var(
    ${({ causeCategory }) => colorCssVars.causeCategory[causeCategory].large}
  );
  &:hover {
    background-color: var(
      ${({ causeCategory }) => colorCssVars.causeCategory[causeCategory].small}
    );
  }
`;
