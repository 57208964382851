const STOCK_BROKERAGE = {
  institution: "AlpacaSecurities LLC",
  dtc: "3021",
  account: "456981656",
  title: "Every.org",
};

/**
 * Note that "National Financial Services LLC" is technically the
 * subsidiary of Fidelity Investments Inc that the shares are going to.
 * On the phone Fidellity told me while "National Financial Services LLC"
 * is more technically correct and we should tell people that if they run
 * into any issues, if someone puts Fidelity that should work fine.
 */
const MUTUAL_FUND_BROKERAGE = {
  institution: "Fidelity",
  dtc: "0226",
  account: "Z28716200",
  title: "Every.org",
};

/**
 * Use Alpaca by default, for all Stocks and ETFs.
 * Alpaca does not support mutual funds where the
 * ticker symbol ends in X, so then use FRB.
 */
export function getBrokerageDtcInfo(tickerSymbol?: string) {
  if (tickerSymbol?.toUpperCase()?.endsWith("X")) {
    return MUTUAL_FUND_BROKERAGE;
  }
  return STOCK_BROKERAGE;
}
