import { ButtonRole } from "@components/Button";
import { Card } from "@components/Card";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

type StringPairOrTrio = [string, string] | [string, string, string];
export type DonateInstructionsType = StringPairOrTrio[]; // [key, value, footnote]

interface DonateInstructionsProps {
  instructions: DonateInstructionsType;
}

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #cccccc;
`;

const StyledCard = styled(Card)`
  ${verticalStackCss.l};
  width: 100%;
  border: 2px solid var(${colorCssVars.accent.large});
`;

function getTextToCopy(instructions: DonateInstructionsType): string {
  return instructions.map(([key, value]) => `${key}: ${value}`).join("\n");
}

export function DonateInstructions({ instructions }: DonateInstructionsProps) {
  const instructionsList = useMemo(() => {
    const instructionsNodes: React.ReactNode[] = [];
    const instructionsFootnotes: React.ReactNode[] = [];
    instructions.forEach(([key, value, footnote]) => {
      instructionsNodes.push(
        <p key={key}>
          <strong>
            {key}
            {footnote && <sup>{instructionsFootnotes.length + 1}</sup>}:
          </strong>{" "}
          {value}
        </p>
      );
      if (footnote) {
        instructionsFootnotes.push(
          <p
            key={key + "footnote"}
            css={{ color: `var(${colorCssVars.text.secondary})` }}
          >
            <sup>{instructionsFootnotes.length + 1}</sup>
            {footnote}
          </p>
        );
      }
    });
    return (
      <React.Fragment>
        {instructionsNodes}
        {instructionsFootnotes}
      </React.Fragment>
    );
  }, [instructions]);

  return (
    <StyledCard>
      <div css={verticalStackCss.xs}>
        <Icon
          size={IconSize.LARGE}
          display={IconDisplay.ACCENT}
          iconImport={() => import("@components/Icon/icons/FileIcon")}
        />
        <h3>Complete your gift with these instructions</h3>
        <p>
          Please send the following information to your provider. A copy of
          these instructions has also been emailed to you:
        </p>
      </div>
      <Divider />
      <div
        css={[
          verticalStackCss.xxs,
          css`
            strong {
              font-weight: ${FontWeight.BOLD};
            }
            sup {
              ${textSizeCss.xxs}
            }
          `,
        ]}
      >
        {instructionsList}
      </div>
      <CopyToClipboardButton
        data-tname="instructions-copy-to-clipboard"
        css={{ alignSelf: "flex-start" }}
        role={ButtonRole.TEXT_ONLY}
        textToCopy={getTextToCopy(instructions)}
        beforeCopiedText="Copy to clipboard"
      />
    </StyledCard>
  );
}
