import { css } from "@emotion/react";

import { memoize } from "@every.org/common/src/helpers/functions";

import { cssForMediaSize, MediaQueryConstraint } from "src/theme/mediaQueries";
import { TextCssVar } from "src/theme/text";

/**
 * CSS mixin to truncate and ellipsize long text for a given element
 *
 * @param numLines How many lines the span should take up.
 * @param fixHeight Optional; if true, will always take up the number of lines,
 * even with empty content.
 */
export const truncatedTextCss = memoize({
  fn: (params: {
    numLines: number;
    fixHeight?: boolean;
    mediaQuery?: {
      numLines: number;
    } & MediaQueryConstraint;
  }) => css`
    overflow: hidden;
    overflow-wrap: anywhere;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${params.numLines};
    align-items: start;
    ${params.fixHeight ? "height" : "max-height"}: calc(
      var(${TextCssVar.LINE_HEIGHT}) * ${params.numLines}
    );
    ${params.mediaQuery &&
    cssForMediaSize({
      ...(params.mediaQuery.min
        ? { min: params.mediaQuery.min }
        : { max: params.mediaQuery.max }),
      css: css`
        -webkit-line-clamp: ${params.mediaQuery.numLines};
        ${params.fixHeight ? "height" : "max-height"}: calc(
      var(${TextCssVar.LINE_HEIGHT}) * ${params.mediaQuery.numLines}
    );
      `,
    })}
  `,
  inputToCacheKey: (params) => Object.values(params).map(String).join("-"),
});
