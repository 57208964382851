import { getWindow } from "src/utility/window";

// Adopted from https://github.com/atomantic/is-ua-webview/blob/main/data/rules.js
const WEBVIEW_REGEXES = [
  // if it says it's a webview, let's go with that
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
  // old chrome android webview agent
  "Linux; U; Android",
];

const webviewRegExp = new RegExp("(" + WEBVIEW_REGEXES.join("|") + ")", "ig");

export function isWebview() {
  const window = getWindow();
  const userAgent = window?.navigator.userAgent || "";

  return !!userAgent.match(webviewRegExp);
}
