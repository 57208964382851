import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { createShareLink, ShareButtonProps } from "@components/ShareButton";
import { ShareMeta } from "@components/ShareButton/types";
import { css } from "@emotion/react";
import React, { useState, useMemo } from "react";

import { DonationResponse } from "@every.org/common/src/codecs/entities";
import { ShareMedium } from "@every.org/common/src/entity/types";

import { BUTTON_CONTENT_COLOR_CSS_VAR } from "src/styles/button";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";
import { trackShareButtonClick } from "src/utility/analytics";
import { getWindow } from "src/utility/window";

interface ShareIconButtonProps {
  shareData: ShareButtonProps["shareData"];
  medium: Exclude<ShareMedium, ShareMedium.NATIVE>;
  onClick?: (medium: ShareMedium) => Promise<void | boolean> | void | boolean;
  size?: IconSize;
  className?: string;
  /**
   * Optional used to track when a donation is shared.
   */
  donationId?: DonationResponse["id"];
}

export const ShareIconButton: React.FCC<ShareIconButtonProps> = ({
  shareData,
  medium,
  onClick,
  size = IconSize.XX_LARGE,
  className,
}) => {
  const window = getWindow();
  const [clicked, setClicked] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  const { color, name, iconImport } = ShareMeta[medium];

  const shareLink = createShareLink(medium, shareData);

  const afterClickCallback = async () => {
    trackShareButtonClick({ medium, shareData });
    setClicked(true);
    if (onClick) {
      await onClick(medium);
    }
  };

  const buttonContent = useMemo(
    () => (
      <React.Fragment>
        <Icon
          iconImport={iconImport}
          size={size}
          className={className}
          css={{ color: `var(${BUTTON_CONTENT_COLOR_CSS_VAR})` }}
          display={IconDisplay.CURRENT_COLOR}
        />
        {(hover || clicked) && (
          <span
            css={css`
              position: absolute;
              bottom: -${spacing.l};
              left: 50%;
              transform: translateX(-50%);
              width: max-content;
              color: var(${colorCssVars.text.secondary});
              ${textSizeCss.xs};
            `}
          >
            {clicked ? (
              <Icon
                iconImport={() =>
                  import("@components/Icon/icons/CheckMarkIcon")
                }
                size={IconSize.MEDIUM}
                display={IconDisplay.ACCENT}
              />
            ) : (
              name
            )}
          </span>
        )}
      </React.Fragment>
    ),
    [className, clicked, hover, iconImport, name, size]
  );

  const buttonProps = {
    "data-tname": `shareLink--${medium}`,
    role: ButtonRole.UNSTYLED,
    size: ButtonSize.LARGE,
    css: css`
      position: relative;
      ${BUTTON_CONTENT_COLOR_CSS_VAR}: ${color};
    `,
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {medium === ShareMedium.LINK ? (
        <CopyToClipboardButton
          {...buttonProps}
          textToCopy={shareLink}
          onCopied={afterClickCallback}
        >
          {buttonContent}
        </CopyToClipboardButton>
      ) : (
        <Button
          {...buttonProps}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: async () => {
              window?.open(shareLink, "_blank");
              await afterClickCallback();
            },
          }}
        >
          {buttonContent}
        </Button>
      )}
    </div>
  );
};
