export function EdoErrorPage() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arial; sans-serif",
        fontWeight: "normal",
      }}
    >
      <div style={{ maxWidth: "600px", textAlign: "center" }}>
        <h3 style={{ fontWeight: "normal" }}>
          Please refresh, sorry about this! The website appears to have
          encountered an error and crashed. If you continue having problems,
          please{" "}
          <a href="https://support.every.org/hc/en-us/requests/new">
            contact us
          </a>
        </h3>
      </div>
    </div>
  );
}
