import { AvatarSize } from "@components/Avatar";
import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Loading } from "@components/LoadingIndicator";
import { TagIllustrationOld } from "@components/TagIllustration";
import { TagLabel } from "@components/TagIllustrationLabel";
import { mapTagsByCauseCategory } from "@components/Tags/TagSelector";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, useContext, useState } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { CAUSE_PASTEL_BG_PALETTE } from "@every.org/common/src/display/palette";
import {
  CauseCategory,
  CauseMetadata,
} from "@every.org/common/src/entity/types";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import { listTagsRouteSpec } from "@every.org/common/src/routes/publicCached";
import {
  searchTagRouteSpec,
  TagListResponse,
} from "@every.org/common/src/routes/search";

import { useSearchOptionsFromUrl } from "src/context/SearchContext/helpers";
import { TagsContext } from "src/context/TagContext";
import { TagActionType } from "src/context/TagContext/types";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import {
  colorCssVars,
  darkBgThemeNoBgCss,
  lightBgThemeNoBgCss,
} from "src/theme/color";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

interface CauseCategoryGroupContainerProps {
  causeCategory: CauseCategory;
}

export const CauseCategoryGroupContainer = styled.li<CauseCategoryGroupContainerProps>`
  &:not(:empty) {
    position: relative;
    padding-top: ${spacing.xxs};
    padding-bottom: ${spacing.xxs};
    ${verticalStackCss.xxxs};
  }
`;

export const CausesListGroup = styled.div`
  ${verticalStackCss.xs};

  h5 {
    color: var(${colorCssVars.text.secondary});
    font-weight: ${FontWeight.REGULAR};
    ${textSizeCss.xs};
    padding: 0 ${spacing.l};
  }
`;

const StyledXButton = styled(Button)`
  margin-left: auto;
`;

const Counter = styled.span`
  margin-left: auto;
  ${lightBgThemeNoBgCss};

  color: var(${colorCssVars.text.secondary});
`;

const OnlyButton = styled(Button)<{ selected: boolean }>`
  display: none;
  font-weight: ${FontWeight.MEDIUM};
  z-index: 2;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

interface CauseItemContainerProps {
  causeCategory: CauseCategory;
  selected: boolean;
}

const CauseItemContainer = styled.div<CauseItemContainerProps>`
  ${horizontalStackCss.xs};
  z-index: 1;
  display: inline-flex;
  align-items: center;
  padding: ${spacing.xxs};
  padding-right: ${spacing.s};
  border-radius: 80px;
  cursor: pointer;
  width: 100%;

  &:hover {
    ${OnlyButton} {
      display: block;
    }
  }

  ${({ selected, causeCategory }) => css`
    ${darkBgThemeNoBgCss};
    ${selected &&
    `background: var(${colorCssVars.causeCategory[causeCategory].small});`}
    color: var(${selected
      ? colorCssVars.text.body
      : colorCssVars.causeCategory[causeCategory].largeHighlight});

    &:hover {
      ${lightBgThemeNoBgCss};
      background: ${CAUSE_PASTEL_BG_PALETTE[causeCategory].pastel40};
      color: var(${colorCssVars.causeCategory[causeCategory].small});
    }
  `};
`;

export const CausesSelector = ({
  causes,
  counts,
  onCausesChange,
  showSuggestedCuases = true,
}: {
  causes: string[];
  counts?: Map<string, number>;
  onCausesChange: (causes?: string[]) => void;
  showSuggestedCuases?: boolean;
}) => {
  const { dispatchTagsAction } = useContext(TagsContext);
  const [allCauses, setAllCauses] = useState<Map<CauseCategory, TagResponse[]>>(
    new Map()
  );
  const [suggestedCauses, setSuggestedCauses] = useState<
    Map<CauseCategory, TagResponse[]>
  >(new Map());
  const urlSearchOptions = useSearchOptionsFromUrl();

  // fetch all causes
  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      const { causes } = await queryApi(listTagsRouteSpec, {
        routeTokens: {},
        queryParams: { take: 100 as TakeInt, skip: 0 as SkipInt },
        body: {},
      });
      return causes;
    }, []),
    handleResponse: useCallback(
      (causes: TagListResponse["causes"]) => {
        setAllCauses(mapTagsByCauseCategory(causes));
        dispatchTagsAction({
          type: TagActionType.ADD_TAGS,
          data: causes,
        });
      },
      [dispatchTagsAction]
    ),
    handleError: useCallback((error) => {
      logger.error({
        error,
        message: "Error while fetching all causes on serch page",
      });
    }, []),
  });

  // fetch suggested causes
  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      if (!urlSearchOptions?.query || !showSuggestedCuases) {
        return [];
      }
      const { causes } = await queryApi(searchTagRouteSpec, {
        routeTokens: {},
        queryParams: {
          query: urlSearchOptions?.query,
          take: 3 as TakeInt,
          skip: 0 as SkipInt,
        },
        body: {},
      });
      return causes;
    }, [urlSearchOptions, showSuggestedCuases]),
    handleResponse: useCallback((causes: TagListResponse["causes"]) => {
      setSuggestedCauses(mapTagsByCauseCategory(causes));
    }, []),
    handleError: useCallback(
      (error) => {
        logger.error({
          error,
          message: "Error while fetching suggested causes on serch page",
          data: { query: urlSearchOptions?.query },
        });
      },
      [urlSearchOptions]
    ),
  });

  const onPick = useCallback(
    (cause: string) => {
      const newCauses = causes.includes(cause)
        ? causes.filter((name) => cause !== name)
        : [...causes, cause];

      onCausesChange(newCauses.length ? newCauses : undefined);
    },
    [causes, onCausesChange]
  );

  const causesSet = new Set(causes);

  const [suggestedGroupElem, allGroupElem] = [
    { title: "Suggested", causes: suggestedCauses, showLoading: false },
    { title: "All", causes: allCauses, showLoading: true },
  ].map(({ title, causes, showLoading }) =>
    causes.size ? (
      <CausesListGroup>
        {!!suggestedCauses.size && <h5>{title}</h5>}
        <ul>
          {[...causes]
            .sort((a, b) =>
              CauseMetadata[a[0]].title > CauseMetadata[b[0]].title ? 1 : -1
            )
            .map(([category, causes]) => {
              return (
                <CauseCategoryGroupContainer
                  causeCategory={category}
                  key={category}
                >
                  {causes.map((cause) => {
                    const count = counts?.get(cause.tagName);

                    if ((counts && !count) || !cause.showInFilters) {
                      return null;
                    }
                    return (
                      <CauseItemContainer
                        key={cause.tagName}
                        causeCategory={cause.causeCategory}
                        selected={causesSet.has(cause.tagName)}
                        onClick={() => {
                          onPick(cause.tagName);
                        }}
                      >
                        <Button
                          aria-label={`Select filter cause ${cause.tagName}`}
                          data-tname={"CausesListItem--" + cause.tagName}
                          role={ButtonRole.UNSTYLED}
                          contentCss={[
                            horizontalStackCss.xs,
                            { alignItems: "center" },
                          ]}
                          onClick={{
                            kind: ButtonTargetKind.FUNCTION,
                            action: (e) => {
                              e.stopPropagation();
                              onPick(cause.tagName);
                            },
                          }}
                        >
                          <TagIllustrationOld
                            tagName={cause.tagName}
                            tagImageCloudinaryId={cause.tagImageCloudinaryId}
                            causeCategory={cause.causeCategory}
                            size={AvatarSize.SMALL}
                            disableLink
                          />
                          <TagLabel tag={cause} css={textSizeCss.s} uncolored />
                        </Button>
                        <OnlyButton
                          aria-label={`Set ${cause.tagName} as only filter`}
                          selected={causesSet.has(cause.tagName)}
                          data-tname={
                            "CausesListItem--" + cause.tagName + "--Only"
                          }
                          role={ButtonRole.UNSTYLED}
                          onClick={{
                            kind: ButtonTargetKind.FUNCTION,
                            action: (e) => {
                              e.stopPropagation();
                              onCausesChange([cause.tagName]);
                            },
                          }}
                        >
                          only
                        </OnlyButton>
                        {causesSet.has(cause.tagName) ? (
                          <StyledXButton
                            aria-label={`Remove filter cause ${cause.tagName}`}
                            data-tname={"CausesListItem--" + cause.tagName}
                            role={ButtonRole.UNSTYLED}
                            onClick={{
                              kind: ButtonTargetKind.FUNCTION,
                              action: () => {
                                onPick(cause.tagName);
                              },
                            }}
                            icon={
                              <Icon
                                iconImport={() => import("../Icon/icons/XIcon")}
                                display={IconDisplay.CURRENT_COLOR}
                                size={IconSize.X_SMALL}
                              />
                            }
                          />
                        ) : (
                          <Counter>{count}</Counter>
                        )}
                      </CauseItemContainer>
                    );
                  })}
                </CauseCategoryGroupContainer>
              );
            })}
        </ul>
      </CausesListGroup>
    ) : (
      showLoading && <Loading />
    )
  );

  return (
    <div css={verticalStackCss.xxs}>
      <h4 css={textSizeCss.s}>Causes</h4>
      {suggestedGroupElem}
      {allGroupElem}
    </div>
  );
};
