import { ToggleSelector } from "@components/ToggleSelector";
import { LegendTitle } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { useFrequencySelectorLayer } from "@components/donate/DonateV3/PaymentProcess/useFrequencySelectorLayer";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";

import { MatchingCampaignResponse } from "@every.org/common/src/codecs/entities";
import { SafeInt } from "@every.org/common/src/codecs/number";
import { DonationFrequency } from "@every.org/common/src/entity/types";
import { CustomEvent } from "@every.org/common/src/helpers/analytics";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";

import { ErrorMessage } from "src/components/donate/DonateV3/PaymentProcess/pages/Donate";
import { verticalStackCss } from "src/theme/spacing";
import { trackEvent } from "src/utility/analytics";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

/**
 * Let's the user select donation frequency.
 */
export const FrequencySelector = ({
  frequency,
  onChange,
  monthlyDescriptionOverride,
  allowedFrequencies,
  fieldError,
  setFrequencyErrorMessage,
  frequencyErrorMessage,
  donateAction,
  matchingCampaign,
}: {
  frequency: DonationFrequency;
  onChange: (frequency: string) => void;
  monthlyDescriptionOverride?: string;
  allowedFrequencies?: DonationFrequency[];
  fieldError?: FieldError;
  setFrequencyErrorMessage: (message: string | undefined) => void;
  frequencyErrorMessage: string | undefined;
  donateAction: DonateModalAction;
  matchingCampaign?: MatchingCampaignResponse;
}) => {
  const [initFrequency] = useState(frequency);

  const monthlyAllowed = allowedFrequencies
    ? allowedFrequencies.includes(DonationFrequency.MONTHLY)
    : true;

  const disallowOnce =
    donateAction === DonateModalAction.UPDATE &&
    initFrequency === DonationFrequency.MONTHLY;

  useEffect(() => {
    setFrequencyErrorMessage(fieldError ? fieldError.message : undefined);
    fieldError && trackEvent(CustomEvent.FREQUENCY_ERROR, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldError]);

  const [
    campaignCurrency,
    monthlyMaxMatching,
    oneTimeMaxMatching,
    recurringMultiplier,
    onetimeMultiplier,
  ] =
    matchingCampaign && "currency" in matchingCampaign
      ? [
          matchingCampaign.currency,

          Math.min(
            matchingCampaign.availableForMatching,
            matchingCampaign.maxPerUserMatchAmount,
            matchingCampaign.maxMatchPerDonationRecurringAmount ||
              matchingCampaign.maxPerUserMatchAmount
          ),

          Math.min(
            matchingCampaign.availableForMatching,
            matchingCampaign.maxPerUserMatchAmount,
            matchingCampaign.maxMatchPerDonationOneTimeAmount ||
              matchingCampaign.maxPerUserMatchAmount
          ),
          matchingCampaign.matchMultiplierRecurring,
          matchingCampaign.matchMultiplierOneTime,
        ]
      : [undefined, undefined, undefined, undefined, undefined];

  const skipLayer = Boolean(
    monthlyDescriptionOverride ||
      (oneTimeMaxMatching && campaignCurrency) ||
      (allowedFrequencies?.length &&
        (!allowedFrequencies?.includes(DonationFrequency.MONTHLY) ||
          !allowedFrequencies?.includes(DonationFrequency.ONCE)))
  );

  const {
    heading,
    option_monthly,
    option_once,
    supporting_copy_monthly,
    supporting_copy_once,
    error_alert,
  } = useFrequencySelectorLayer(skipLayer);

  const monthlyOption = {
    value: DonationFrequency.MONTHLY,
    title: <span>{option_monthly}</span>,
    description: (
      <p>
        {monthlyDescriptionOverride ??
          (monthlyMaxMatching && campaignCurrency
            ? `Give monthly up to ${displayCurrencyValueInUserLocale({
                currencyValue: {
                  currency: campaignCurrency,
                  amount: minimumDenominationAmountToCurrencyValue({
                    currency: campaignCurrency,
                    amount: Math.floor(
                      monthlyMaxMatching / recurringMultiplier
                    ) as SafeInt,
                  }).amount,
                },
              })} for long-term, systemic impact, and get matched ${recurringMultiplier}:1 up to ${displayCurrencyValueInUserLocale(
                {
                  currencyValue: {
                    currency: campaignCurrency,
                    amount: minimumDenominationAmountToCurrencyValue({
                      currency: campaignCurrency,
                      amount: monthlyMaxMatching as SafeInt,
                    }).amount,
                  },
                }
              )} today!`
            : supporting_copy_monthly)}
      </p>
    ),
  };

  const oneTimeOption = {
    value: DonationFrequency.ONCE,
    title: <span>{option_once}</span>,
    description: (
      <p>
        {oneTimeMaxMatching && campaignCurrency
          ? `Give one-time and get
        matched ${onetimeMultiplier}:1 up to ${displayCurrencyValueInUserLocale(
              {
                currencyValue: {
                  currency: campaignCurrency,
                  amount: minimumDenominationAmountToCurrencyValue({
                    currency: campaignCurrency,
                    amount: oneTimeMaxMatching as SafeInt,
                  }).amount,
                },
              }
            )}
        today!`
          : supporting_copy_once}
      </p>
    ),
  };

  const frequencyOptions: {
    value: DonationFrequency;
    title: React.ReactNode;
    description: React.ReactNode;
  }[] = [monthlyOption, oneTimeOption].filter((frequency) =>
    allowedFrequencies ? allowedFrequencies.includes(frequency.value) : true
  );

  const chosenFrequency = frequencyOptions.find(
    (item) => item.value === frequency
  );

  return (
    <fieldset css={verticalStackCss.s}>
      <LegendTitle>{heading}</LegendTitle>
      <ToggleSelector
        onChange={onChange}
        selectorOptions={frequencyOptions}
        option={frequency}
        disabled={disallowOnce}
      />
      {frequencyErrorMessage && <ErrorMessage>{error_alert}</ErrorMessage>}
      {disallowOnce ? (
        <p>You are updating a monthly donation</p>
      ) : chosenFrequency ? (
        chosenFrequency.description
      ) : (
        monthlyAllowed && monthlyOption.description
      )}
    </fieldset>
  );
};
