import { useStatsigUser } from "@statsig/react-bindings";
import React, { createContext, useEffect, useReducer } from "react";

export enum AbTestActionType {
  ADD_EXPOSURE = "ADD_EXPOSURE",
  SET_NONPROFIT_ID = "SET_NONPROFIT_ID",
}

interface AddExposure {
  type: AbTestActionType.ADD_EXPOSURE;
  layer: string;
}

interface SetNonprofitId {
  type: AbTestActionType.SET_NONPROFIT_ID;
  nonprofitId: string;
}

type AbTestAction = AddExposure | SetNonprofitId;

type AbTestState = {
  exposures: Set<string>;
  nonprofitId?: string;
};
//#endregion

const initialState: AbTestState = {
  exposures: new Set(),
  nonprofitId: undefined,
};

function abTestReducer(state: AbTestState, action: AbTestAction) {
  const newState = state;
  const { type } = action;
  switch (type) {
    case AbTestActionType.ADD_EXPOSURE:
      newState.exposures.add(action.layer);
      break;
    case AbTestActionType.SET_NONPROFIT_ID:
      newState.nonprofitId = action.nonprofitId;
      break;
  }
  return newState;
}

export const AbTestContext = createContext<AbTestState>(initialState);

export let dispatchAbTestAction: React.Dispatch<AbTestAction>;

/**
 * A simple context that tracks the ab-tests that a user has been exposed to.
 * This allows us to do things like record all the ab-tests associated with a
 * user when they make a donation.
 */
export const AbTestProvider: React.FCC = ({ children }) => {
  const [abTestState, abTestDispatcher] = useReducer(
    abTestReducer,
    initialState
  );
  dispatchAbTestAction = abTestDispatcher;

  const { user } = useStatsigUser();
  useEffect(() => {
    if (user?.customIDs?.nonprofitId) {
      dispatchAbTestAction({
        type: AbTestActionType.SET_NONPROFIT_ID,
        nonprofitId: user.customIDs.nonprofitId,
      });
    }
  }, [user.customIDs?.nonprofitId]);

  return (
    <AbTestContext.Provider value={abTestState}>
      {children}
    </AbTestContext.Provider>
  );
};
