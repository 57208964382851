import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Button } from "src/components/Button";
import { Icon } from "src/components/Icon";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";

export const IconsContainer = styled.div`
  ${horizontalStackCss.m};

  ${cssForMediaSize({
    max: MediaSize.MEDIUM_SMALL,
    css: css`
      display: grid;
      grid-template-columns: repeat(3, 48px);
      grid-column-gap: ${spacing.m};
      grid-row-gap: ${spacing.l};
      margin: 0 auto;

      & > div:last-child {
        grid-column: 2 / 3;
      }
    `,
  })}

  // hidden input for copy to clipboard
  // causes horizontal scroll in modal
  input[aria-hidden="true"] {
    left: -9999px;
  }
`;

export const StyledTextContainer = styled.div`
  ${verticalStackCss.m};

  & > ul {
    ${verticalStackCss.s};

    & > li {
      ${horizontalStackCss.s};
    }
  }
`;

export const shareIconButtonCss = css`
  width: 48px;
  height: 48px;
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

export const iconsCellCss = cssForMediaSize({
  min: MediaSize.XX_LARGE,
  css: css`
    display: flex;
    justify-content: center;
  `,
});

export const ModalContent = styled.div`
  ${verticalStackCss.m};
  position: relative;
  max-width: 620px;
`;

export const ModalHeadline = styled.h2`
  padding-right: ${spacing.xl};
  span {
    color: var(${colorCssVars.accent.large});
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: -${spacing.s};
  top: -${spacing.s};
`;
