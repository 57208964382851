/**
 * Link component prompt to the login page for existing users.
 */
import { SecondaryText } from "@components/textHelpers";
import { CSSInterpolation } from "@emotion/css";
import React from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";

interface ExistingUserLoginLinkProps {
  "data-tname": string;
  question: string;
  answer: string;
  routeName: ClientRouteName;
  routeQuery?: { [key: string]: string };
  contentCss?: CSSInterpolation;
}
export const QuestionAnswerLink: React.FCC<ExistingUserLoginLinkProps> = (
  props
) => {
  const { routeName, question, answer, routeQuery, contentCss } = props;

  return (
    <SecondaryText css={contentCss}>
      {question}{" "}
      <Link
        data-tname={props["data-tname"]}
        appearance={LinkAppearance.HYPERLINK}
        to={getRoutePath({
          format: URLFormat.RELATIVE,
          name: routeName,
          query: routeQuery,
        })}
      >
        {answer}
      </Link>
    </SecondaryText>
  );
};

export const ExistingUserLoginLink: React.FCC<{ redirectUrl?: string }> = ({
  redirectUrl,
}) => {
  return (
    <QuestionAnswerLink
      question="Already have an account?"
      answer="Log in"
      data-tname="loginPrompt"
      routeName={ClientRouteName.LOGIN}
      routeQuery={redirectUrl ? { redirectUrl } : {}}
    />
  );
};
