import dynamic from "next/dynamic";
import { createContext, forwardRef, useContext } from "react";

import { compositionTextCss } from "src/theme/text";

const TextContext = createContext<string>("");
const LinkifyFallback = () => <span>{useContext(TextContext)}</span>;
const DynamicLinkfy = dynamic(() => import("./Linkify"), {
  loading: LinkifyFallback,
});
const Linkify = ({
  original,
  text,
  target,
}: {
  original: string;
  text: string;
  target?: string;
}) => (
  // text in provider so that fallback can access it
  <TextContext.Provider value={text}>
    <DynamicLinkfy text={text} original={original} target={target} />
  </TextContext.Provider>
);

/**
 * Creates a new paragraph for every break of two newlines and adds a
 * line-break wherever there is a single newline.
 */
export const NewlineAwareText = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    content: string;
    originalText?: string;
    textEnding?: string | React.ReactNode;
    linkTarget?: string;
  }
>(function NewlineAwareTextComponent(
  { className, content, textEnding, originalText, linkTarget },
  ref
) {
  const newlineSplitText = (originalText || content)
    .split("\n\n")
    .map((text) => text.split("\n"));

  return (
    <div className={className} css={compositionTextCss} ref={ref}>
      {content.split("\n\n").map((text, textIndex, textArray) => (
        <p key={textIndex}>
          {text.split("\n").map((line, lineIndex, lineArray) => (
            <span key={lineIndex}>
              <Linkify
                original={newlineSplitText[textIndex][lineIndex] || line}
                text={line}
                target={linkTarget}
              />
              {textEnding &&
              lineIndex === lineArray.length - 1 &&
              textIndex === textArray.length - 1 ? (
                textEnding
              ) : (
                <br />
              )}
            </span>
          ))}
        </p>
      ))}
    </div>
  );
});
