import { BackToTopButton } from "@components/BackToTopButton";
import { ToastManager } from "@components/ToastManager";
import { DonateRouteModal } from "@components/donate/DonateRouteModal";
import { Footer } from "@components/layout/Footer";
import { HeaderNav } from "@components/layout/HeaderNav";
import type { CSSInterpolation } from "@emotion/css"; // necessary for css prop to work
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { default as NextHead } from "next/head";
import { match } from "path-to-regexp";
import React, { Fragment, HTMLAttributes, ReactNode, useMemo } from "react";

import {
  clientRouteMetas,
  ClientRouteName,
  DonateModalUrlParams,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import { searchParamToBool } from "@every.org/common/src/helpers/string";

import { Loading, PageLoadingIndicator } from "src/components/LoadingIndicator";
import { modalGradientCss } from "src/components/Modal";
import {
  LAYOUT_Z_INDEXES,
  PageStackingContext,
  zIndexCss,
} from "src/context/PageStackingContext";
import {
  EdoTheme,
  EdoThemeContextProvider,
  useEdoTheme,
} from "src/context/ThemeContext";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import {
  ScrollDirection,
  useScrollDirection,
} from "src/hooks/useScrollDirection";
import { useScrolledDown } from "src/hooks/useScrolledDown";
import { darkBgThemeCss, lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { logger } from "src/utility/logger";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  OPENGRAPH_DIMENSIONS,
} from "src/utility/opengraph";
import {
  getRuntimeEnvironment,
  RuntimeEnvironment,
} from "src/utility/runtimeEnvironment";

export enum DefaultPageLayoutQueryParam {
  SHOW_WELCOME_MODAL = "start",
}

// page always full screen width, minimum full screen height.
const PageContainer = styled.div<{
  headerOverlapHeight: number;
}>`
  /* minimum size, full screen page */
  min-height: 100vh;

  display: grid;
  grid-template-columns: [single-column] 100%;
  grid-template-rows:
    [nav-start] auto
    [header-start nav-end] auto
    [main-start] ${(props) => props.headerOverlapHeight}px
    [header-end] 1fr auto
    [main-end toast-start] auto
    [toast-end footer-start] auto [footer-end];

  position: relative; /* establish stacking context */
`;

// ensure positioning of content of page stacks as follows:
// top
// ------
// header nav
// content
// header content
// footer
// ------
// bottom

type MetaProperties =
  | "description"
  | "og:url"
  | "og:title"
  | "og:description"
  | "og:image"
  | "og:image:height"
  | "og:image:width";
/**
 * All the meta tags we want to have set on all pages
 *
 * Currently assumes no duplicate meta tags, which is technically allowed but
 * we're not using at the moment
 */
export type MetaTags = { [key in MetaProperties]: string };

const DEFAULT_META_TAGS = {
  "og:description":
    "Every.org helps nonprofits fundraise and people donate. Explore over 1.2 million nonprofits, donate, and create fundraisers. Built by a nonprofit. Free for everyone.",
  "og:image": constructCloudinaryUrl({
    ...DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
    dimensions: OPENGRAPH_DIMENSIONS,
  }),
  "og:image:width": String(OPENGRAPH_DIMENSIONS.width),
  "og:image:height": String(OPENGRAPH_DIMENSIONS.height),
};

const NAV_CSS = [
  zIndexCss(LAYOUT_Z_INDEXES.nav),
  css`
    position: sticky;
    top: 0;
    width: 100%;
  `,
];

const MOBILE_THRESHOLD_WHITESPACE = "72px";
const DESKTOP_THRESHOLD_WHITESPACE = "88px";

export interface DefaultPageLayoutProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * CSS to style the main content area of the page, excluding the header and footer
   */
  pageContentCss?: CSSInterpolation;
  /**
   * CSS to style the entire header, including the overlapping area
   */
  headerCss?: CSSInterpolation;
  /**
   * CSS to style the entire navigation
   */
  headerNavCss?: CSSInterpolation;
  /**
   * CSS to style the entire footer
   */
  footerCss?: CSSInterpolation;
  /**
   * Contents to be shown in the header, above the overlap area if present
   */
  headerContent?: ReactNode;
  /**
   * CSS to apply to just the content area of the header, but not the overlap
   */
  headerContentCss?: CSSInterpolation;
  /**
   * Title of the page to show up in the url bar and on opengraph tags
   */
  pageTitle?: string;
  /**
   * Overrides default generic meta tags
   */
  metas?: Partial<Omit<MetaTags, "og:title">>;
  /**
   * If true, hides " - Every.org" suffix;
   * @default false
   */
  omitPageTitleSuffix?: boolean;
  philanthropyforEveryoneSuffix?: boolean;
  /**
   * If present, makes page content overlap with header the px amount specified
   */
  headerOverlapHeight?: number;
  /**
   * If true, hides the searchbar in the header
   */
  hideSearchbar?: boolean;
  /**
   * If true, hides the footer
   * @default false
   */
  hideFooter?: boolean;
  /**
   * Canonical link - https://support.google.com/webmasters/answer/139066?hl=en
   */
  canonical?: string;
  /**
   * If true, shows BackToTopButton after scrolled down
   * @default false
   */
  showBackToTopButton?: boolean;
  /**
   * @default false
   */
  disableFooterMargin?: boolean;
  /**
   * Full-page header width.
   */
  fullWidthHeader?: boolean;
  /**
   * If true, shows full page instead shorten with full screen overlay
   * @default false
   */
  ignoreNoExit?: boolean;

  /**
   * Image component to render as a background
   */
  headerBackgroundImage?: React.ReactNode;

  /**
   * Overrides default theme
   */
  themeOverride?: EdoTheme;

  /**
   * If true, hides the signup button
   * @default false
   */
  hideSignup?: boolean;

  /**
   * If true, shows the get started button
   * @default false
   */
  showGetStarted?: boolean;
}

/**
 * Default layout component for a page on the website
 *
 * @param pageContentCss To pass an emotion CSS object to style the parent
 * element of the page content
 */
export const DefaultPageLayout = ({
  children,
  pageContentCss: inputMainCss,
  headerCss: inputHeaderCss,
  headerContent,
  headerContentCss: inputHeaderContentCss,
  headerNavCss: inputHeaderNavCss,
  footerCss: inputFooterCss,
  hideSearchbar,
  metas,
  headerOverlapHeight = 0,
  pageTitle,
  omitPageTitleSuffix = false,
  philanthropyforEveryoneSuffix = false,
  hideFooter = false,
  canonical,
  showBackToTopButton = false,
  disableFooterMargin = false,
  fullWidthHeader = false,
  ignoreNoExit = false,
  headerBackgroundImage,
  hideSignup = false,
  showGetStarted = false,
  themeOverride,
  ...rest
}: DefaultPageLayoutProps) => {
  const { isLight } = useEdoTheme(themeOverride);

  const router = useEdoRouter();
  const searchParams = new URLSearchParams(router.search.substr(1));

  const nonprofitSlug = searchParams.get(
    DonateModalUrlParams.DONATE_NONPROFIT_ID
  );

  const scrollDirection = useScrollDirection({ threshold: 10 });

  const isScrolledDown = useScrolledDown({ minY: 50 });
  const minimize =
    isScrolledDown &&
    (scrollDirection || ScrollDirection.UP) === ScrollDirection.DOWN;

  // the following styles use `useMemo()` because they dont change frequently
  // but this component rerenders often
  const headerNavCss = useMemo(() => {
    return [
      css`
        ${isLight ? lightBgThemeCss : darkBgThemeCss};
        grid-column: single-column;
        grid-row: nav-start / nav-end;

        &,
        > * {
          /* avoid transiton flickering on safari
          https://stackoverflow.com/questions/25010353/safari-css-transition-flickering */

          backface-visibility: hidden;
          transform-style: preserve-3d;
          transition: transform 0.4s ease;
          transition: -webkit-transform 0.4s ease;
        }
      `,
      cssForMediaSize({
        min: MediaSize.MEDIUM,
        css: [
          minimize
            ? css`
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
                transform: translate3d(0px, -${spacing.l}, 0px);
                > * {
                  transform: translate3d(0px, calc(${spacing.l} / 2), 0px);
                }
              `
            : css`
                transform: translate3d(0px, 0px, 0px);
              `,
        ],
      }),
      cssForMediaSize({
        max: MediaSize.MEDIUM_SMALL,
        css: minimize
          ? css`
              /* use  translate3d for smoother transition*/
              transform: translate3d(0px, -100%, 0px);
            `
          : ``,
      }),
      inputHeaderNavCss,
    ];
  }, [inputHeaderNavCss, minimize, isLight]);

  const mainCss = useMemo(
    () => [
      css`
        grid-column: single-column;
        grid-row: main-start / main-end;
        min-height: 0px; /* allows height: 100% in parent to restrict height to screen size */
      `,
      zIndexCss(LAYOUT_Z_INDEXES.main),
      inputMainCss,
    ],
    [inputMainCss]
  );

  const toastsCss = useMemo(
    () => [
      css`
        grid-column: single-column;
        grid-row: toast-start / toast-end;
      `,
      zIndexCss(LAYOUT_Z_INDEXES.toast),
    ],
    []
  );

  const headerCss = useMemo(
    () => [
      css`
        ${isLight ? lightBgThemeCss : darkBgThemeCss};
        grid-column: single-column;
        grid-row: header-start / header-end;
        padding-bottom: ${headerOverlapHeight}px;
        position: relative;
        padding-top: ${MOBILE_THRESHOLD_WHITESPACE};
        margin-top: -${MOBILE_THRESHOLD_WHITESPACE};
      `,
      cssForMediaSize({
        min: MediaSize.MEDIUM,
        css: css`
          padding-top: ${DESKTOP_THRESHOLD_WHITESPACE};
          margin-top: -${DESKTOP_THRESHOLD_WHITESPACE};
        `,
      }),
      zIndexCss(LAYOUT_Z_INDEXES.header),
      inputHeaderCss,
    ],
    [headerOverlapHeight, inputHeaderCss, isLight]
  );

  const footerCss = useMemo(
    () => [
      !(disableFooterMargin || showBackToTopButton) &&
        css`
          margin-top: ${spacing.xxxl};
        `,
      css`
        grid-column: single-column;
        grid-row: footer-start / footer-end;
      `,
      zIndexCss(LAYOUT_Z_INDEXES.footer),
      inputFooterCss,
    ],
    [inputFooterCss, disableFooterMargin, showBackToTopButton]
  );

  const noExitParam = searchParamToBool(
    new URLSearchParams(router.search).get(DonateModalUrlParams.NO_EXIT)
  );

  if (!ignoreNoExit && noExitParam) {
    return (
      <Fragment>
        <Head
          {...{
            canonical,
            pageTitle,
            omitPageTitleSuffix,
            philanthropyforEveryoneSuffix,
            metas,
          }}
        />
        <EdoThemeContextProvider override={themeOverride}>
          <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.main}>
            {children}
          </PageStackingContext.Provider>
          <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.noExitOverlay}>
            <div
              css={css`
                position: absolute;
                top: 0;
                width: 100vw;
                height: 100vh;
                ${modalGradientCss};
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Loading height={20} />
            </div>
          </PageStackingContext.Provider>
        </EdoThemeContextProvider>
      </Fragment>
    );
  }

  return (
    <EdoThemeContextProvider override={themeOverride}>
      <PageContainer {...rest} headerOverlapHeight={headerOverlapHeight}>
        <Head
          {...{
            canonical,
            pageTitle,
            omitPageTitleSuffix,
            metas,
          }}
        />
        <div id="loading-overlay">
          <PageLoadingIndicator />
        </div>
        <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.nav}>
          <div
            // do not set position property to HeaderNav itself,
            // because position + transition makes animation flickering on safari
            css={NAV_CSS}
            role="banner"
          >
            <HeaderNav
              css={headerNavCss}
              hideSearchbar={hideSearchbar}
              fullWidth={fullWidthHeader}
              hideSignup={hideSignup}
              showGetStarted={showGetStarted}
            />
          </div>
        </PageStackingContext.Provider>
        <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.header}>
          <header css={headerCss}>
            {headerBackgroundImage}
            <div css={inputHeaderContentCss}>{headerContent}</div>
          </header>
        </PageStackingContext.Provider>

        <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.main}>
          <main css={mainCss} role="main">
            {children}
            {showBackToTopButton && <BackToTopButton />}
          </main>
        </PageStackingContext.Provider>
        <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.toast}>
          <ToastManager css={toastsCss} />
        </PageStackingContext.Provider>
        {!hideFooter && (
          <PageStackingContext.Provider value={LAYOUT_Z_INDEXES.footer}>
            <Footer css={footerCss} />
          </PageStackingContext.Provider>
        )}
        {nonprofitSlug && <DonateRouteModal nonprofitSlug={nonprofitSlug} />}
      </PageContainer>
    </EdoThemeContextProvider>
  );
};

const NO_ROBOTS_ROUTES = Object.entries(clientRouteMetas)
  .filter(([, { allowRobots }]) => !allowRobots)
  .map(([name]) => name) as ClientRouteName[];

type HeadProps = Pick<
  DefaultPageLayoutProps,
  | "pageTitle"
  | "omitPageTitleSuffix"
  | "philanthropyforEveryoneSuffix"
  | "metas"
  | "canonical"
>;
export const Head = React.memo(function HeadImpl({
  pageTitle,
  metas,
  omitPageTitleSuffix,
  philanthropyforEveryoneSuffix,
  canonical,
}: HeadProps) {
  const router = useEdoRouter();
  const searchParams = new URLSearchParams(router.search.substr(1));
  const fullPageTitle = pageTitle
    ? `${pageTitle}${omitPageTitleSuffix ? "" : " | Every.org"}${
        philanthropyforEveryoneSuffix ? " - Philanthropy for everyone" : ""
      }`
    : "Every.org";

  // Do not include query parameters or hash, unless the param refers to a
  // donation
  const url =
    canonical ??
    assertEnvPresent(process.env.NEXT_PUBLIC_WEBSITE_ORIGIN, "WEBSITE_ORIGIN") +
      router.pathname;

  const mergedMetas: Omit<MetaTags, "description"> = {
    ...DEFAULT_META_TAGS,
    "og:url": url,
    ...(metas || {}),
    "og:title": fullPageTitle,
  };

  const metaToInsert: MetaTags = {
    ...mergedMetas,
    // description is copied from the value for og:description
    description: mergedMetas["og:description"],
  };

  const noRobots =
    getRuntimeEnvironment() !== RuntimeEnvironment.PROD ||
    // don't index any pages with donate modal
    // other query params pages are handled by the canonical tag
    !!searchParams.get(DonateModalUrlParams.DONATE_NONPROFIT_ID) ||
    !!NO_ROBOTS_ROUTES.find(
      (route) => !!match(clientRouteMetas[route].path)(router.pathname)
    );
  try {
    if (noRobots) {
      metaToInsert["robots"] = "none";
    }
  } catch (error) {
    logger.warn({ message: "Error getting environment name", error });
  }

  return (
    <NextHead>
      <title>{fullPageTitle}</title>
      {!noRobots && <link rel="canonical" href={url} />}
      {Object.entries(metaToInsert).map(([key, value]) =>
        key.startsWith("og:") ? (
          <meta property={key} content={value} key={key} />
        ) : (
          <meta name={key} content={value} key={key} />
        )
      )}
    </NextHead>
  );
});
