import { FGCBanner } from "@components/FGC/FGCBanner";
import { FaqComponent } from "@components/donate/DonateV3/FAQ";
import { Footer } from "@components/donate/DonateV3/Footer";
import { FundraiserLink } from "@components/donate/DonateV3/FundraiserLink";
import { GiftCardLink } from "@components/donate/DonateV3/GiftCardLink";
import { Header } from "@components/donate/DonateV3/Header";
import {
  NonprofitInfo,
  NonprofitInfoHeaderSmallScreen,
} from "@components/donate/DonateV3/NonprofitInfo";
import PaymentProcessWrapper from "@components/donate/DonateV3/PaymentProcess";
import { useStripePromise } from "@components/donate/DonateV3/PaymentProcess/components/AddCreditCard";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { useDonateForm } from "@components/donate/DonateV3/PaymentProcess/useDonateForm";
import { useDonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useValidValuesFromSearchParams } from "@components/donate/DonateV3/PaymentProcess/validators";
import { PromoBanner } from "@components/donate/DonateV3/PromoBanner";
import {
  DonateV3Layout,
  DonateV3PageProps,
} from "@components/donate/DonateV3/types";
import { useDonateFormSearchParams } from "@components/donate/DonateV3/useDonateFormSearchParams";
import { useGridCardsDisplayController } from "@components/donate/DonateV3/useGridCardsDisplayController";
import { css } from "@emotion/react";
import { Elements } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import {
  HashRouter,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { CustomEvent } from "@every.org/common/src/helpers/analytics";
import {
  DonateModalUrlParams,
  DONATE_HASH,
} from "@every.org/common/src/helpers/clientRoutes";
import { searchParamToBool } from "@every.org/common/src/helpers/string";

import { renderTurnstile } from "src/context/TurnstileContext/useTurnstile";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useFallGivingChallengeActive } from "src/hooks/useFallGivingChallengeActive";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { Grid } from "src/pages/DonateV3/styles";
import {
  fetchFundraiser,
  FundraiserFetchStatus,
} from "src/pages/Fundraiser/fetchFundraiser";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { useStatSigLayer } from "src/utility/abtesting";
import { trackEvent } from "src/utility/analytics";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export const HEX_COLOR_REGEX = /^([0-9a-f]{3}){1,2}$/i;

let hasTrackedDonationModal = false;
// Here to only track the type once instead of on every render, but it
// also means that if you open many different modals in a single session
// you will not see this again. Reconsider after launching donate v3
function trackDonationModal(layout: DonateV3Layout) {
  if (!hasTrackedDonationModal) {
    // TODO consider adding type DonationModalType like old flow had
    trackEvent(CustomEvent.DONATE_SHOW_MODAL, { version: "V3", layout });
    hasTrackedDonationModal = true;
  }
}

const DonateV3 = ({
  layout,
  ...props
}: DonateV3PageProps & {
  layout: DonateV3Layout;
}) => {
  const edoRouter = useEdoRouter();

  const searchParams = new URLSearchParams(edoRouter.search);

  const noExit = searchParamToBool(
    searchParams.get(DonateModalUrlParams.NO_EXIT)
  );

  const turnstileDiv = <div id="turnstile" style={{ display: "none" }} />;
  useEffect(() => renderTurnstile("turnstile"), []);

  useEffect(() => trackDonationModal(layout), [layout]);

  const { nonprofit, fromFundraiserId } = props;

  const initialValuesFromSearchParams = useValidValuesFromSearchParams(
    useDonateFormSearchParams(),
    {
      minDonationValue: props.minDonationValue,
    }
  );

  const fundraiserId =
    fromFundraiserId || initialValuesFromSearchParams.fundraiserId;
  const [fundraiser, setFundraiser] = useState<FundraiserResponse>();
  useAsyncEffect({
    asyncOperation: useCallback(() => {
      return fundraiserId
        ? fetchFundraiser(fundraiserId, nonprofit.id)
        : Promise.resolve(undefined);
    }, [fundraiserId, nonprofit.id]),
    handleResponse: useCallback(
      (
        result?: FundraiserResponse | FundraiserFetchStatus.FUNDRAISER_NOT_FOUND
      ) => {
        if (result && result !== FundraiserFetchStatus.FUNDRAISER_NOT_FOUND) {
          setFundraiser(result);
        }
      },
      []
    ),
    handleError: useCallback((e: Error) => {
      logger.error({
        message: "An error occurred fetching fundraiser for donate modal.",
        error: e,
      });
    }, []),
  });

  const form = useDonateForm({ ...props, initialValuesFromSearchParams });
  const formContext = useDonateFormContext({
    modalProps: props,
    form,
    initialValuesFromSearchParams,
  });

  const { showGiftCardLink, showFundraiserLink } =
    useGridCardsDisplayController({ nonprofit, initialValuesFromSearchParams });

  const showPromoBanners = !!useNonprofitAnnouncement(nonprofit);
  const navigate = useNavigate();

  // If the hash-route does not start with a "/", then replace with the
  // hash-prefixed version.
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.startsWith("/")) {
      navigate(`/${location.pathname}${location.search}${location.hash}`, {
        replace: true,
      });
    }
  }, [location.pathname, location.search, location.hash, navigate]);

  const isThankYouPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
  );

  const { displayFGC, FGCDepleted } = useFallGivingChallengeActive(
    props.nonprofit.id
  );

  const showFGCBanner = displayFGC && !isThankYouPage;

  useStatSigLayer("a_a_test");

  return (
    <Grid
      layout={layout}
      css={[
        formContext.themeColor &&
          css`
            * {
              ${{
                [colorCssVars.accent.large]: formContext.themeColor,
                [colorCssVars.accent.largeHighlight]:
                  formContext.themeColorHighlight || formContext.themeColor,
                [colorCssVars.accent.smallHighlight]:
                  formContext.themeColorHighlight || formContext.themeColor,
                [colorCssVars.accent.small]: formContext.themeColor,
                [colorCssVars.input.border.focus]: formContext.themeColor,
                [colorCssVars.input.caret]: formContext.themeColor,
                [colorCssVars.input.outline.focus]:
                  formContext.themeColorHighlight || formContext.themeColor,
              }}
            }
          `,
        noExit &&
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: { paddingTop: spacing.l },
          }),
      ]}
    >
      {turnstileDiv}
      <div
        css={[
          { gridArea: "header" },
          cssForMediaSize({
            max: MediaSize.SMALL,
            css: { display: "flex", justifyContent: "space-between" },
          }),
        ]}
      >
        <NonprofitInfoHeaderSmallScreen
          nonprofit={nonprofit}
          fundraiser={fundraiser}
          formContext={formContext}
          customNonprofitDescription={
            initialValuesFromSearchParams.customNonprofitDescription
          }
        />
        {!noExit && <Header {...props} form={form} formContext={formContext} />}
      </div>
      {(showPromoBanners || showFGCBanner) && (
        <div css={[{ gridArea: "promoBanners" }, verticalStackCss.m]}>
          {showFGCBanner && <FGCBanner depleted={FGCDepleted} />}
          {showPromoBanners && <PromoBanner nonprofit={nonprofit} />}
        </div>
      )}
      <div
        css={[
          { gridArea: "nonprofitInfo" },
          isThankYouPage &&
            cssForMediaSize({
              max: MediaSize.MEDIUM_LARGE,
              css: { display: "none" },
            }),
          cssForMediaSize({
            max: MediaSize.SMALL,
            css: { display: "none" },
          }),
        ]}
      >
        <NonprofitInfo
          nonprofit={nonprofit}
          fundraiser={fundraiser}
          formContext={formContext}
          customNonprofitDescription={
            initialValuesFromSearchParams.customNonprofitDescription
          }
        />
      </div>
      <div css={{ gridArea: "paymentProcess" }}>
        <PaymentProcessWrapper
          {...props}
          form={form}
          formContext={formContext}
          fundraiser={fundraiser}
        />
      </div>
      <div
        css={[
          { gridArea: "fundraiserLink" },
          cssForMediaSize({
            max: MediaSize.MEDIUM_LARGE,
            css: "display: none",
          }),
        ]}
      >
        {showFundraiserLink && <FundraiserLink nonprofit={nonprofit} />}
      </div>
      <div css={{ gridArea: "giftCard" }}>
        {showGiftCardLink && <GiftCardLink />}
      </div>
      <div css={{ gridArea: "faq" }}>
        <FaqComponent
          nonprofit={nonprofit}
          fundraiser={fundraiser}
          paymentOption={formContext.currentPaymentOption}
          showStartFundraiserLink={showFundraiserLink}
        />
      </div>
      <div css={{ gridArea: "footer" }}>
        <Footer nonprofit={nonprofit} fundraiser={fundraiser} />
      </div>
    </Grid>
  );
};

const DonateV3Wrapper = (
  props: DonateV3PageProps & { layout: DonateV3Layout }
) => {
  const router = useEdoRouter();
  const stripePromise = useStripePromise();

  // Likely unnecessary since this component shouldn't render if the proper hash
  // isn't set, but doesn't hurt to have it.
  const window = getWindow();
  useEffect(() => {
    if (window && !router.hash) {
      window.location.hash = DONATE_HASH;
      window.document.getElementById("loading-overlay")?.remove();
    }
  }, [router.hash, window]);

  return (
    <HashRouter>
      <Elements stripe={stripePromise || null}>
        <DonateV3 {...props} />
      </Elements>
    </HashRouter>
  );
};

export default DonateV3Wrapper;
