import { donateFormFields } from "@components/donate/DonateV3/types";
import { UUID } from "io-ts-types/UUID";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  CurrencyValue,
  currencyValueCodec,
} from "@every.org/common/src/codecs/currency";
import { decodeOrUndefined } from "@every.org/common/src/codecs/index";
import {
  Currency,
  DonationFrequency,
} from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import { DonateModalUrlParams } from "@every.org/common/src/helpers/clientRoutes";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  searchParamToBool,
  searchParamToBoolOrNull,
} from "@every.org/common/src/helpers/string";
import {
  DonationMatchCampaignIdentifier,
  donationMatchCampaignIdentifierCodec,
} from "@every.org/common/src/routes/donate";

import { useEdoRouter } from "src/hooks/useEdoRouter";

export type DonateFormSearchValues = Partial<{
  amount: number;
  frequency: DonationFrequency;
  minValue: CurrencyValue;
  firstName: string;
  lastName: string;
  email: string;
  shareInfo: boolean;
  partnerDonationId: string;
  partnerWebhookToken: string;
  partnerMetadata: string;
  toNonprofits: string[];
  toNonprofitWeights: number[];
  giftCardAmount: string;
  giftCardQuantity: string;
  giftCardNonprofitId: string;
  giftCardTagId: string;
  requireShareInfo: boolean;
  customNonprofitDescription: string;
  successUrl: string;
  redirectUrl: string;
  exitUrl: string;
  donationToJoinId: UUID;
  userToJoinId: UUID;
  method: string;
  fundraiserId: UUID;
  matchCampaign: DonationMatchCampaignIdentifier;
  cryptoCurrency: CryptoCurrency;
  cryptoAmount: number;
  stockSymbol: string;
  stockAmount: number;
  monthlyTitle: string;
  suggestedAmounts: number[];
  designation: string;
  privateNote: string;
  themeColor: string;
  themeColorHighlight: string;
  searchMeta: { [key: string]: string };
  giftCardCode: string;
}>;

/**
 * Parse initial values for the donation form out of the url params.
 * @returns
 */
export function useDonateFormSearchParams() {
  const { search: edoRouterSearch } = useEdoRouter();
  const donateRouterSearch = useLocation().search;

  return useMemo(() => {
    const searchParams = new URLSearchParams({
      ...Object.fromEntries(new URLSearchParams(edoRouterSearch).entries()),
      ...Object.fromEntries(new URLSearchParams(donateRouterSearch).entries()),
    });

    return removeUndefinedOrNullValues({
      amount: decodeOrUndefined(
        donateFormFields.amount,
        searchParams.get(DonateModalUrlParams.AMOUNT)
      ),
      frequency: decodeOrUndefined(
        donateFormFields.frequency,
        searchParams.get(DonateModalUrlParams.FREQUENCY)?.toUpperCase()
      ),
      minValue: decodeOrUndefined(currencyValueCodec, {
        amount: searchParams.get(DonateModalUrlParams.MIN_VALUE),
        currency: Currency.USD,
      }),
      firstName: decodeOrUndefined(
        donateFormFields.firstName,
        searchParams.get(DonateModalUrlParams.DONOR_FIRST_NAME)
      ),
      lastName: decodeOrUndefined(
        donateFormFields.lastName,
        searchParams.get(DonateModalUrlParams.DONOR_LAST_NAME)
      ),
      email: decodeOrUndefined(
        donateFormFields.email,
        searchParams.get(DonateModalUrlParams.DONOR_EMAIL)
      ),
      shareInfo: searchParamToBoolOrNull(
        searchParams.get(DonateModalUrlParams.SHARE_INFO)
      ),
      partnerDonationId: decodeOrUndefined(
        donateFormFields.partnerDonationId,
        searchParams.get(DonateModalUrlParams.PARTNER_DONATION_ID)
      ),
      partnerWebhookToken: decodeOrUndefined(
        donateFormFields.partnerWebhookToken,
        searchParams.get(DonateModalUrlParams.PARTNER_WEBHOOK_TOKEN)
      ),
      partnerMetadata: decodeOrUndefined(
        donateFormFields.partnerMetadata,
        searchParams.get(DonateModalUrlParams.PARTNER_METADATA)
      ),
      toNonprofits:
        searchParams.get(DonateModalUrlParams.TO_NONPROFITS)?.split(",") ||
        undefined,
      toNonprofitWeights:
        searchParams
          .get(DonateModalUrlParams.TO_NONPROFIT_WEIGHTS)
          ?.split(",")
          .map(parseFloat) || undefined,
      toNonprofitMatchings:
        searchParams
          .get(DonateModalUrlParams.TO_NONPROFIT_MATCHINGS)
          ?.split(",")
          .map(parseFloat) || undefined,
      recurringMatches:
        searchParams
          .get(DonateModalUrlParams.RECURRING_MATCHES)
          ?.split(",")
          .map((x) => parseInt(x)?.toString())[0] || undefined,
      giftCardAmount: searchParams.get(DonateModalUrlParams.GIFT_CARD_AMOUNT),
      giftCardQuantity: searchParams.get(
        DonateModalUrlParams.GIFT_CARD_QUANTITY
      ),
      giftCardNonprofitId: searchParams.get(
        DonateModalUrlParams.GIFT_CARD_NONPROFIT_ID
      ),
      giftCardTagId: searchParams.get(DonateModalUrlParams.GIFT_CARD_TAG_ID),
      requireShareInfo: searchParamToBool(
        searchParams.get(DonateModalUrlParams.REQUIRE_SHARE_INFO)
      ),
      customNonprofitDescription: searchParams.get(
        DonateModalUrlParams.DESCRIPTION
      ),
      successUrl: searchParams.get(DonateModalUrlParams.SUCCESS_URL),
      redirectUrl: searchParams.get(DonateModalUrlParams.REDIRECT_URL),
      exitUrl: searchParams.get(DonateModalUrlParams.EXIT_URL),
      donationToJoinId: decodeOrUndefined(
        UUID,
        searchParams.get(DonateModalUrlParams.JOIN_DONATION_ID)
      ),
      userToJoinId: decodeOrUndefined(
        UUID,
        searchParams.get(DonateModalUrlParams.JOIN_DONATION_USER_ID)
      ),
      method: searchParams.get(DonateModalUrlParams.METHOD),
      fundraiserId: decodeOrUndefined(
        UUID,
        searchParams.get(DonateModalUrlParams.FUNDRAISER_ID)
      ),
      matchCampaign: decodeOrUndefined(
        donationMatchCampaignIdentifierCodec,
        searchParams.get(DonateModalUrlParams.MATCH_CAMPAIGN)
      ),
      cryptoCurrency: decodeOrUndefined(
        donateFormFields.cryptoCurrency,
        searchParams.get(DonateModalUrlParams.CRYPTO_CURRENCY)
      ),
      cryptoAmount: decodeOrUndefined(
        donateFormFields.cryptoPledgeAmount,
        searchParams.get(DonateModalUrlParams.CRYPTO_AMOUNT)
      ),
      stockSymbol: decodeOrUndefined(
        donateFormFields.stockSymbol,
        searchParams.get(DonateModalUrlParams.STOCK_SYMBOL)
      ),
      stockAmount: decodeOrUndefined(
        donateFormFields.stockAmount,
        parseInt(searchParams.get(DonateModalUrlParams.STOCK_AMOUNT) || "")
      ),
      monthlyTitle: searchParams.get(DonateModalUrlParams.MONTHLY_TITLE),
      suggestedAmounts:
        searchParams
          .get(DonateModalUrlParams.SUGGESTED_AMOUNTS)
          ?.split(",")
          .map(parseFloat)
          .filter((n) => !!n)
          .slice(0, 5) || undefined,
      designation: searchParams.get(DonateModalUrlParams.DESIGNATION),
      privateNote: decodeOrUndefined(
        donateFormFields.privateNote,
        searchParams.get(DonateModalUrlParams.PRIVATE_NOTE)
      ),
      commentText: decodeOrUndefined(
        donateFormFields.commentText,
        searchParams.get(DonateModalUrlParams.PUBLIC_TESTIMONY)
      ),
      themeColor: searchParams.get(DonateModalUrlParams.THEME_COLOR),
      themeColorHighlight: searchParams.get(
        DonateModalUrlParams.THEME_COLOR_HIGHLIGHT
      ),
      searchMeta: ((): { [key: string]: string } | undefined => {
        const meta = searchParams.get(DonateModalUrlParams.SEARCH_META);

        if (!meta) {
          return undefined;
        }

        try {
          return JSON.parse(meta);
        } catch {
          return undefined;
        }
      })(),
      giftCardCode: searchParams.get(DonateModalUrlParams.GIFT_CARD_CODE),
    });
  }, [edoRouterSearch, donateRouterSearch]);
}
