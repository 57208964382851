import { Link, LinkProps } from "@components/Link";

import { HOTJAR_FEEDBACK_EVENT_NAME } from "@every.org/common/src/entity/constants";

import { getWindow } from "src/utility/window";

/**
 * hotjar embedded code loads an additional script that adds "hjSiteSettings" to "window".
 * "hj" is always present in window, but "hjSiteSettings" only after successful loading
 */
const getHotjar = () => {
  const w = getWindow();
  if (w && w.hj && w.hjSiteSettings) {
    return w.hj;
  }
  return;
};

export const FeedbackLink = ({ onClick, ...rest }: Omit<LinkProps, "to">) => {
  return (
    <Link
      {...rest}
      to="https://support.every.org/hc/en-us/requests/new"
      onClick={(e) => {
        const hotjar = getHotjar();
        if (hotjar) {
          e.preventDefault();
          hotjar("event", HOTJAR_FEEDBACK_EVENT_NAME);
        }
        onClick && onClick(e);
      }}
    />
  );
};
