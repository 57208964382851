type SearchParam = string | URLSearchParams | Record<string, string>;
interface BuildUrlPathParams {
  /**
   * Pathname of the URL; tolerant of leading `/` character not present (adds it
   * if missing)
   */
  pathname: string;
  /**
   * Search params to include; if array, then the latest entries will override
   * the previous ones; tolerant if `?` not present (adds it if missing)
   */
  search?: SearchParam | SearchParam[];
  /**
   * hash to include in the URL; tolerant of `#` character not present (adds it
   * if missing)
   */
  hash?: string;
}
export function buildUrlPath({ pathname, search, hash }: BuildUrlPathParams) {
  const searchPart =
    search && !(search instanceof Array && search.length === 0)
      ? `?${new URLSearchParams(
          (search instanceof Array ? search : [search]).reduce<
            Record<string, string>
          >(
            (memo, cur) => ({
              ...memo,
              ...Object.fromEntries(new URLSearchParams(cur).entries()),
            }),
            {}
          )
        ).toString()}`
      : undefined;
  return [
    pathname.startsWith("/") ? pathname : `/${pathname}`,
    searchPart,
    hash ? `#${hash.replace(/^#/, "")}` : undefined,
  ]
    .filter(Boolean)
    .join("");
}

/**
 * Extract specific URL parameters from a url string.
 * The url can be absolute or relative.
 *
 * @url a string, it doesn't need to be an actual url.
 *   The parameters can be delimited by a ? or by the escaped version %3F.
 * @paramNames a list of params with want to extract from the url string.
 * @returns the url with the parameters removed if it had it and an object
 * with the extracted parameters
 */
export function extractParametersFromUrl({
  url,
  paramNames,
}: {
  url: string | undefined;
  paramNames: string[];
}) {
  if (!url) {
    return { url: undefined, params: {} };
  }
  const [baseURL, ...rest] = url.split(/(?:\?)|(?:%3F)/);
  const baseParams = rest.join("");
  const paramSeparator = url.indexOf("?") >= 0 ? "?" : "%3F";
  const urlParams = new URLSearchParams(baseParams);
  const params = Object.fromEntries(
    paramNames
      .map((param) => [param, urlParams.get(param)])
      .filter(([key, value]) => !!value)
  );
  for (const [key] of Object.entries(params)) {
    urlParams.delete(key);
  }
  const urlParamsString = urlParams.toString();
  const newUrl = `${baseURL}${
    urlParamsString ? `${paramSeparator}${urlParamsString}` : ""
  }`;
  return { url: newUrl, params };
}

/**
 * Adds parameters to an URL.
 * The url can be absolute or relative.
 *
 * @url a string, it doesn't need to be an actual url.
 *   It can have parameters delimited by a ? or by the escaped version %3F.
 * @params an object with the parameters and values to add the url
 * @returns the url with the parameters added
 */
function addParametersToUrl({
  url,
  params,
}: {
  url: string | undefined | null;
  params: Record<string, string>;
}) {
  if (!url) {
    return undefined;
  }
  const [baseURL, ...rest] = url.split(/(?:\?)|(?:%3F)/);
  // Join the rest in case there was a redirectUrl param which has a ? or %3F itself
  const baseParams = rest.join("");
  const paramSeparator =
    url.indexOf("?") >= 0 ? "?" : url.indexOf("%3F") ? "%3F" : "?";
  const urlParams = new URLSearchParams(baseParams);
  for (const [key, value] of Object.entries(params)) {
    urlParams.set(key, value);
  }
  const urlParamsString = urlParams.toString();
  const newUrl = `${baseURL}${
    urlParamsString ? `${paramSeparator}${urlParamsString}` : ""
  }`;
  return newUrl;
}

/**
 * Extract specific URL parameters from a url string and add them to another one.
 * The urls can be absolute or relative.
 * Relative urls is why strings are concatenated and the URL package is not used.
 *
 * Example:
 *
 * fromUrl:    /admin?requested=true&inviteToken=1234&nonce=abcd&x=1
 * toUrl:      /loops?fan=true
 * paramNames: ["inviteToken", "nonce"]
 *
 * newFromUrl: /admin?requested=true&x=1
 * toFromUrl:  /loops?fan=true&inviteToken=1234&nonce=abcd
 * params:     {"inviteToken": "1234", "nonce": "abcd"}
 *
 * @fromUrl a string, it doesn't need to be an actual url.
 *   It can have parameters delimited by a ? or by the escaped version %3F.
 * @toUrl a string, it doesn't need to be an actual url.
 *   It can have parameters delimited by a ? or by the escaped version %3F.
 * @paramNames a list of params with want to extract from the url string.
 * @returns
 *   The fromUrl with the parameters removed if it had them
 *   The toUrl with the parameters added if the fromUrl had them
 *   params: an object with the key value extracted parameters
 */
export function moveUrlParameters({
  fromUrl,
  toUrl,
  paramNames,
}: {
  fromUrl: string;
  toUrl: string | undefined | null;
  paramNames: string[];
}) {
  const { url: newFromUrl, params } = extractParametersFromUrl({
    url: fromUrl,
    paramNames,
  });
  const newToUrl = addParametersToUrl({
    url: toUrl,
    params,
  });
  return { newFromUrl, newToUrl, params };
}
