import dynamic from "next/dynamic";
import React from "react";

import { PersonalDonationResponse } from "@every.org/common/src/codecs/entities";
import { DonationFrequency } from "@every.org/common/src/entity/types";

import { logger } from "src/utility/logger";
import { isDevOrTest } from "src/utility/runtimeEnvironment";
import { getWindow } from "src/utility/window";

const PAYMENT_CAPTURE_ERROR_MESSAGE =
  "Error processing PayPal payment. Please try again or use a different payment method.";

const paypalClientId =
  process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ||
  process.env.REACT_APP_PAYPAL_CLIENT_ID;
const paypalSubscriptionPlanId =
  process.env.NEXT_PUBLIC_PAYPAL_SUBSCRIPTION_PLAN_ID ||
  process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID;
if (!paypalClientId || !paypalSubscriptionPlanId) {
  (isDevOrTest ? logger.warn : logger.fatal)({
    message: "Missing Paypal environment variables, Paypal flow will not work",
  });
}
const PayPalButton = dynamic(() => import("./PayPalButton"));
const disableFunding = "card,credit";

function handleError({
  amount,
  cancelCallback,
  donationId,
  error,
  frequency,
  onApprove,
}: {
  amount: string;
  cancelCallback: ((errorMessage?: string) => void) | undefined;
  donationId: PersonalDonationResponse["id"];
  error: unknown;
  frequency: DonationFrequency;
  onApprove: boolean;
}) {
  const errorMessage =
    typeof error === "string"
      ? error
      : error instanceof Error
      ? error.message
      : undefined;
  const message = onApprove
    ? "Error on PayPal processing approval"
    : frequency === DonationFrequency.ONCE
    ? "Error capturing PayPal payment"
    : "Error capturing PayPal subscription";
  const typedError = error instanceof Error ? error : undefined;
  logger.error({
    message,
    error: typedError,
    data: {
      origError: error,
      donationChargeId: donationId,
      amount,
      errorMessage,
      frequency,
    },
  });
  if (cancelCallback) {
    cancelCallback(PAYMENT_CAPTURE_ERROR_MESSAGE);
  }
}

interface OurPaypalButtonProps {
  amount: string;
  frequency: DonationFrequency;
  donationId: PersonalDonationResponse["id"];
  finishCallback?: () => void;
  cancelCallback?: (errorMessage?: string) => void;
  enableVenmo?: boolean;
}
export const OurPaypalButton: React.FCC<OurPaypalButtonProps> = ({
  amount,
  frequency,
  donationId,
  finishCallback,
  cancelCallback,
  enableVenmo,
}) => {
  // Delete loaded Paypal SDK as it is different for one time and recurring buttons
  // https://github.com/Luehang/react-paypal-button-v2/issues/30
  try {
    const window = getWindow();
    window &&
      Object.keys(window).forEach((key) => {
        if (/paypal|zoid|post_robot/.test(key)) {
          delete window[key];
        }
      });
    window?.document
      .querySelectorAll('script[src*="www.paypal.com/sdk"]')
      .forEach((node) => node.remove());
  } catch (error) {
    logger.warn({ message: "PayPal removing SDK failed", error: error });
  }
  // Build the button
  try {
    switch (frequency) {
      case DonationFrequency.ONCE: {
        return (
          <PayPalButton
            amount={amount}
            shippingPreference="NO_SHIPPING"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createOrder={(data: any, actions: any) => {
              return actions.order.create({
                /* eslint-disable @typescript-eslint/naming-convention */
                purchase_units: [
                  {
                    custom_id: donationId,
                    amount: {
                      currency_code: "USD",
                      value: amount,
                    },
                  },
                ],
                application_context: {
                  shipping_preference: "NO_SHIPPING",
                },
                /* eslint-disable @typescript-eslint/naming-convention */
              });
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onApprove={(data: any, actions: any) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return actions.order
                .capture()
                .then((details: any) => {
                  if (finishCallback) {
                    finishCallback();
                  }
                })
                .catch((error: Error) =>
                  handleError({
                    amount,
                    cancelCallback,
                    donationId,
                    error,
                    frequency,
                    onApprove: true,
                  })
                );
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onCancel={(data: any) => {
              if (cancelCallback) {
                cancelCallback();
              }
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError={(error: any) =>
              handleError({
                amount,
                cancelCallback,
                donationId,
                error,
                frequency,
                onApprove: true,
              })
            }
            options={{
              clientId: paypalClientId,
              currency: "USD",
              disableFunding,
              intent: "capture",
              ...(enableVenmo ? { enableFunding: "venmo" } : {}),
            }}
          />
        );
      }
      case DonationFrequency.MONTHLY: {
        return (
          <PayPalButton
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createSubscription={(data: any, actions: any) => {
              return actions.subscription.create({
                /* eslint-disable @typescript-eslint/naming-convention */
                plan_id: paypalSubscriptionPlanId,
                quantity: Math.trunc(100 * parseFloat(amount)).toString(),
                custom_id: donationId,
                application_context: {
                  shipping_preference: "NO_SHIPPING",
                },
                /* eslint-disable @typescript-eslint/naming-convention */
              });
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onApprove={(data: any, actions: any) => {
              if (finishCallback) {
                finishCallback();
              }
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onCancel={(data: any) => {
              if (cancelCallback) {
                cancelCallback();
              }
            }}
            onError={(error: unknown) => {
              handleError({
                amount,
                cancelCallback,
                donationId,
                error,
                frequency,
                onApprove: false,
              });
            }}
            options={{
              vault: true,
              intent: "subscription",
              clientId: paypalClientId,
              currency: "USD",
              disableFunding,
              ...(enableVenmo ? { enableFunding: "venmo" } : {}),
            }}
          />
        );
      }
    }
  } catch (error) {
    logger.error({ message: "PayPal button loading failed", error });
  }
  return null;
};
