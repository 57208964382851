import * as t from "io-ts";
import { UUID } from "io-ts-types/UUID";

import { MinTwoElemsArray } from "../../helpers/types";

export enum AchievementKey {
  FIRST_DONATION = "FIRST_DONATION",
  DONATE_WITH_BANK = "DONATE_WITH_BANK",
  FIRST_RECURRING = "FIRST_RECURRING",
  LIKED_DONATION = "LIKED_DONATION",
  FOLLOW_USER = "FOLLOW_USER",
  GIVEBLCK = "GIVEBLCK",
  // Deprecated -- no longer checked / awarded
  HOOP_FOR_ALL = "HOOP_FOR_ALL",
}

/**
 * Achievements to check for each donation charge
 */
const DONATION_CHARGE_ACHIEVEMENTS = [
  AchievementKey.FIRST_DONATION,
  AchievementKey.DONATE_WITH_BANK,
  AchievementKey.FIRST_RECURRING,
  AchievementKey.GIVEBLCK,
  AchievementKey.HOOP_FOR_ALL,
] as const;

export const achievementsForDonationCharge = new Set<AchievementKey>(
  DONATION_CHARGE_ACHIEVEMENTS
);
export type AchievementsForDonationCharge =
  typeof DONATION_CHARGE_ACHIEVEMENTS[number];

export const achievementJobDataCodec = t.intersection([
  t.type({
    userId: UUID,
  }),
  t.union([
    t.type({
      achievementKey: t.union(
        DONATION_CHARGE_ACHIEVEMENTS.map((key) =>
          t.literal(key)
        ) as MinTwoElemsArray<t.LiteralC<AchievementsForDonationCharge>>
      ),
      donationChargeId: UUID,
    }),
    t.type({
      achievementKey: t.literal(AchievementKey.FOLLOW_USER),
      userFollowId: UUID,
    }),
    t.type({
      achievementKey: t.literal(AchievementKey.LIKED_DONATION),
      userId: UUID,
      donationId: UUID,
    }),
  ]),
]);

export type AchievementJobData = t.TypeOf<typeof achievementJobDataCodec>;

// June 7th, 2021. Month is 0 indexed.
export const ACHIEVEMENT_SYSTEM_LAUNCH_DATE = new Date(2021, 5, 7);
