import { ErrorMessage } from "@components/ErrorMessage";
import { Loading } from "@components/LoadingIndicator";
import { RecentContributor } from "@components/RecentContributor";
import { SecondaryText } from "@components/textHelpers";
import styled from "@emotion/styled";
import React, { useCallback, useState } from "react";

import {
  DonationResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { spacing } from "@every.org/common/src/display/spacing";

import {
  fetchDonationBoosts,
  DonationBoostsResponse,
} from "src/context/DonationsContext/actions";
import { getMessageForError } from "src/errors";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";

export const JoinedUserList: React.FCC<{
  joinedDonationId: DonationResponse["id"];
}> = ({ joinedDonationId }) => {
  const [donations, setDonations] = useState<DonationResponse[]>();
  const [users, setUsers] = useState<UserResponse[]>();
  const [totalBoostingUsers, setTotalBoostingUsers] = useState<number>();
  const [error, setError] = useState<string>();

  const fetchBoosts = useCallback(() => {
    return fetchDonationBoosts(joinedDonationId);
  }, [joinedDonationId]);
  const handleDonationBoosts = useCallback(
    (boostsResponse: DonationBoostsResponse) => {
      const { donations, users, totalBoostingUsers } = boostsResponse;
      setDonations(donations);
      setUsers(users);
      setTotalBoostingUsers(totalBoostingUsers);
    },
    []
  );
  const handleError = useCallback((error: Error) => {
    setError(getMessageForError(error));
  }, []);

  useAsyncEffect({
    asyncOperation: fetchBoosts,
    handleResponse: handleDonationBoosts,
    handleError,
  });

  if (error) {
    return <ErrorMessage text={error} />;
  }

  // We only show one entry in the list per user who boosted, because the number
  // of boosting users is also what we show on the FeedDonationCard itself. If
  // we wanted to show every donation, then we could map directly over the
  // boosting donations, but that would probably require modifying the
  // FeedDonationCard number too for consistency
  const userToMostRecentDonationMap = new Map<string, DonationResponse>();
  donations &&
    donations.forEach((donation) => {
      const existingRecentDonation = userToMostRecentDonationMap.get(
        donation.fromUserId
      );
      if (
        !existingRecentDonation ||
        donation.createdAt > existingRecentDonation.createdAt
      ) {
        userToMostRecentDonationMap.set(donation.fromUserId, donation);
      }
    });

  const privateBoostingUsers =
    totalBoostingUsers && users && totalBoostingUsers - users.length;

  return (
    <React.Fragment>
      {users === undefined ? (
        <Loading />
      ) : (
        <ul>
          {users.map((user) => {
            const donation = userToMostRecentDonationMap.get(user.id);
            return donation ? (
              <RecentContributor donation={donation} key={donation.id} />
            ) : null;
          })}
          {privateBoostingUsers ? (
            <JoinListItem>
              <SecondaryText>
                {users.length > 0 ? "+" : ""}
                {privateBoostingUsers} private user
                {privateBoostingUsers > 1 ? "s" : ""}
              </SecondaryText>
            </JoinListItem>
          ) : null}
        </ul>
      )}
    </React.Fragment>
  );
};

const JoinListItem = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid var(${colorCssVars.dividerSoft});
  }
  padding: ${spacing.m} 0;
  ${horizontalStackCss.xs};

  &:last-of-type {
    padding-bottom: ${spacing.s};
  }
`;
