import {
  ButtonTargetKind,
  Button,
  ButtonRole,
  ButtonSize,
} from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import { Loading } from "@components/LoadingIndicator";
import { createGuestUserIfNeeded } from "@components/donate/DonateV3/PaymentProcess/helpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
// Use loadStripe from @stripe/stripe-js/pure instead of @stripe/stripe-js as we do not
// need to load script https://js.stripe.com/v3/ immediately (that is already handled by index.html)
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useContext, useEffect, useState } from "react";

import { PaymentSourceResponse } from "@every.org/common/src/codecs/entities";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import { getUserFullName } from "@every.org/common/src/helpers/username";
import { attachPaymentSourceRouteSpec } from "@every.org/common/src/routes/donate";

import { TextInputLabelText } from "src/components/InputContainer";
import {
  useABTestingId,
  useLoggedInOrGuestUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { TurnstileContext } from "src/context/TurnstileContext";
import { resetTurnstile } from "src/context/TurnstileContext/useTurnstile";
import { getMessageForError } from "src/errors";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { InputWithLabelContainer } from "src/pages/Fundraiser/EditForm/styles";
import { colorCssVars } from "src/theme/color";
import { roundedBorder } from "src/theme/common";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  spacing,
  verticalStackCss,
  horizontalStackCss,
} from "src/theme/spacing";
import { TextSize, textSizeCss, TextCssVar } from "src/theme/text";
import { trackEvent } from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { API_VERSION } from "src/utility/stripe";

const StyledTextInputLabelText = styled(TextInputLabelText)`
  ${textSizeCss[TextSize.s]};
  ${cssForMediaSize({ min: MediaSize.MEDIUM, css: textSizeCss[TextSize.s] })}
`;

const AddCcContainer = styled.div`
  .cardInput {
    border: 1px solid var(${colorCssVars.dividerSoft});
    ${roundedBorder};
    background: var(${colorCssVars.input.background.default});
    padding: ${spacing.xs};
    ${textSizeCss[TextSize.s]};
    color: var(${colorCssVars.text.body});
    height: calc(
      var(${TextCssVar.SIZE}) * var(${TextCssVar.LINE_HEIGHT}) +
        (2 * ${spacing.xs})
    ); /* Stripe card element jumps in size if we don't set this and use base object style fontSize */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const styledInputCss = css`
  width: 392px;
  height: 52px;
  padding: 17px 16px 15px;
  background: #f3f6f6;
  border-radius: 8px;
  ${textSizeCss[TextSize.s]};
`;

interface PaymentSourceCallback {
  (paymentSource: PaymentSourceResponse): void;
}

export interface AddCreditCardProps {
  handleConnectBankAccount: () => void;
  onPaymentSourceAdded: PaymentSourceCallback;
  cancellable: boolean;
  onAddCancelled: () => void;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAddingCard?: boolean;
}

function AddCcForm({
  onPaymentSourceAdded,
  handleConnectBankAccount,
  cancellable,
  onAddCancelled,
  firstName,
  lastName,
  email,
  isAddingCard,
}: AddCreditCardProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [currentInput, setCurrentInput] = useState(0);
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();
  const abTestingId = useABTestingId();
  const router = useEdoRouter();
  const turnstileContext = useContext(TurnstileContext);

  useEffect(() => {
    trackEvent("Viewed add credit card form", {});
  });

  const onChange = (isComplete: boolean) => {
    if (!stripe || !elements) {
      logger.fatal({
        message:
          "Stripe or stripe-elements not injected, people cannot donate, investigate immediately",
        data: { stripe, elements },
      });
      return;
    }

    const elemCardExpiry = elements.getElement(CardExpiryElement);
    const elemCardCvs = elements.getElement(CardCvcElement);

    if (isComplete) {
      setCurrentInput(currentInput + 1);
      if (currentInput === 0) {
        elemCardExpiry?.focus();
      } else if (currentInput === 1) {
        elemCardCvs?.focus();
      }
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      logger.fatal({
        message:
          "Stripe or stripe-elements not injected, people cannot donate, investigate immediately",
        data: { stripe, elements },
      });
      return;
    }
    const elemCardNumber = elements.getElement(CardNumberElement);
    const elemCardExpiry = elements.getElement(CardExpiryElement);
    const elemCardCvs = elements.getElement(CardCvcElement);
    if (!elemCardNumber || !elemCardExpiry || !elemCardCvs) {
      logger.fatal({
        message:
          "Stripe elem not found, people cannot donate, investigate immediately",
        data: { stripe, elements },
      });
      return;
    }
    let cfTurnstileToken = turnstileContext.get("turnstile");
    setSubmitting(true);
    await createGuestUserIfNeeded({
      router,
      abTestingId,
      loggedInOrGuestUser,
      firstName,
      lastName,
      email,
      cfTurnstileToken,
    });
    if (elemCardNumber && elemCardExpiry && elemCardCvs) {
      // Create stripe source; Passing elemCardNumber actually includes the expiry and cvs
      const sourceResponse = await stripe.createSource(elemCardNumber, {
        type: "card",
        usage: "reusable",
        owner: {
          email,
          name: getUserFullName({ firstName, lastName }) || undefined,
        },
      });
      if (sourceResponse.error) {
        setError(sourceResponse.error.message);
        setSubmitting(false);
        return;
      }
      const { source } = sourceResponse;
      if (!source) {
        logger.error({
          message: "Got unexpected invalid payment source from Stripe",
          data: { source },
        });
        setError("Invalid payment source.");
        setSubmitting(false);
        return;
      }
      try {
        cfTurnstileToken = turnstileContext.get("turnstile");
        const paymentSource = await queryApi(attachPaymentSourceRouteSpec, {
          body: {
            paymentSourceId: source.id,
            cfTurnstileToken,
          },
          routeTokens: {},
          queryParams: {},
        });
        onPaymentSourceAdded(paymentSource);
        setSubmitting(false);
        resetTurnstile("turnstile");
      } catch (e) {
        if (e instanceof Error) {
          setError(getMessageForError(e));
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <AddCcContainer>
      <div css={[verticalStackCss.xxl]}>
        <div css={verticalStackCss.l}>
          <InputWithLabelContainer>
            <StyledTextInputLabelText>Card number</StyledTextInputLabelText>
            <CardNumberElement
              css={[
                styledInputCss,
                css`
                  width: 392px;
                  ${cssForMediaSize({
                    max: MediaSize.MEDIUM_SMALL,
                    css: css`
                      width: 100%;
                    `,
                  })}
                `,
              ]}
              options={{ showIcon: true }}
              onChange={(e) => onChange(e.complete)}
              onReady={(e) => {
                if (currentInput === 0) {
                  isAddingCard && loggedInOrGuestUser && e.focus();
                }
              }}
            />
          </InputWithLabelContainer>
          <div css={horizontalStackCss.l}>
            <InputWithLabelContainer>
              <StyledTextInputLabelText>
                Expiration date
              </StyledTextInputLabelText>
              <CardExpiryElement
                css={[styledInputCss, { width: "112px" }]}
                onChange={(e) => onChange(e.complete)}
              />
            </InputWithLabelContainer>
            <InputWithLabelContainer>
              <StyledTextInputLabelText>Security code</StyledTextInputLabelText>
              <CardCvcElement
                css={[styledInputCss, { width: "86px" }]}
                onChange={(e) => onChange(e.complete)}
              />
            </InputWithLabelContainer>
          </div>
        </div>
        {error && <ErrorMessage text={error} />}
        <ButtonContainer>
          {cancellable && (
            <Button
              role={ButtonRole.TEXT_ONLY}
              data-tname="cancelCardSubmit"
              size={ButtonSize.SMALL}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: onAddCancelled,
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            data-tname="addCardSubmit"
            role={ButtonRole.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={{ kind: ButtonTargetKind.FUNCTION, action: handleSubmit }}
            submitting={submitting}
            disabled={!stripe || submitting}
            css={css`
              margin-left: auto;
            `}
          >
            Add card
          </Button>
        </ButtonContainer>
      </div>
    </AddCcContainer>
  );
}

export function useStripePromise(): Promise<Stripe | null> | undefined {
  const [stripePromise, setStripe] = useState<Promise<Stripe | null>>();
  useEffect(() => {
    setStripe(
      // It doesn't recognize ReturnType<typeof loadStripe> as Promise<Stripe | null>
      // Should be fixed by: https://github.com/stripe/stripe-js/pull/565
      loadStripe(
        assertEnvPresent(
          process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY ||
            process.env.REACT_APP_STRIPE_PUBLIC_API_KEY,
          "STRIPE_PUBLIC_API_KEY"
        ),
        API_VERSION ? { apiVersion: API_VERSION } : undefined
      )
    );
  }, []);
  return stripePromise;
}

/**
 * Component to add a credit card using Stripe.
 */
export const AddCreditCard: React.FCC<AddCreditCardProps> = (props) => {
  const stripePromise = useStripePromise();

  if (!stripePromise) {
    return <Loading />;
  }

  return (
    <Elements stripe={stripePromise}>
      <AddCcForm {...props} />
    </Elements>
  );
};
