import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import { Loading } from "@components/LoadingIndicator";
import { Feed, FeedDisplayProps } from "@components/feed";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, forwardRef, useContext } from "react";

import {
  DonationChargeResponse,
  FeedItemResponse,
  FundraiserResponse,
  PersonalDonationChargeResponse,
} from "@every.org/common/src/codecs/entities";
import { FeedItemType, FeedPage } from "@every.org/common/src/entity/types";
import { dateSortComparisonBaseEntity } from "@every.org/common/src/helpers/date";
import { ListParams } from "@every.org/common/src/routes/index";

import RecentSupportersPlaceholder from "src/assets/illustrations/recent_supporters_placeholder_v3.svg";
import RecentSupportersPlaceholderSmall from "src/assets/illustrations/recent_supporters_placeholder_v3_small.svg";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { MyDonationsContext } from "src/context/DonationsContext";
import { fetchNonprofitFeed } from "src/context/DonationsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { NonprofitPageV3RecentContributor } from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3RecentContributor";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { textSizeCss, TextSize } from "src/theme/text";
import { logger } from "src/utility/logger";

const transformCreatedAtDate = <T extends { createdAt: Date | string }>(
  obj: T
) => ({
  ...obj,
  createdAt:
    obj.createdAt instanceof Date ? obj.createdAt : new Date(obj.createdAt),
});

const NonprofitPageV3RecentContributorsCardListDisplay = forwardRef<
  HTMLDivElement,
  FeedDisplayProps
>(function NonprofitPageV3RecentContributorsCardListWithRef(
  {
    className,
    feedItems,
    moreItemsToFetch,
    fetchMoreItems,
    fetching,
    placeholder,
    excludeDonationIds,
    nonprofit,
    sortChronologically,
  },
  ref
) {
  const myDonations = useContext(MyDonationsContext);
  const loggedInUserId = useLoggedInUserOrUndefined()?.id;
  if (!nonprofit) {
    return null;
  }
  const donationChargeFeedId = new Map();
  const filteredDonationsMap: Map<
    string,
    DonationChargeResponse | PersonalDonationChargeResponse
  > = new Map();

  for (const feedItem of feedItems) {
    switch (feedItem.type) {
      case FeedItemType.USER_DONATION: {
        if (
          !!feedItem.donationCharge.donation &&
          !excludeDonationIds?.has(feedItem.donationCharge.donation.id)
        ) {
          donationChargeFeedId.set(feedItem.donationCharge.id, feedItem.feedId);
          filteredDonationsMap.set(
            feedItem.donationCharge.id,
            feedItem.donationCharge
          );
        }
        break;
      }
      default:
        logger.warn({
          message: `Unexpected feed item type in nonprofit recent contributors feed: ${feedItem.type}`,
        });
    }
  }
  const filteredMyDonations = myDonations.filter(
    (i) =>
      i.toNonprofitId === nonprofit.id &&
      !excludeDonationIds?.has(i.donation.id)
  );
  for (const donationCharge of filteredMyDonations) {
    filteredDonationsMap.set(donationCharge.id, donationCharge);
  }

  const donationsArray = [...filteredDonationsMap.values()].map(
    transformCreatedAtDate
  );
  const finalDonations = sortChronologically
    ? donationsArray.sort(dateSortComparisonBaseEntity)
    : donationsArray;

  if (!fetching && finalDonations.length === 0) {
    return placeholder || null;
  }

  return (
    <Card css={verticalStackCss.l} className={className}>
      <Title>Supporters</Title>

      <div
        data-feed-page={FeedPage.NONPROFIT_PROFILE}
        data-feed-user={loggedInUserId}
        css={css`
          > :not(:last-child) {
            padding-bottom: ${spacing.l};
            border-bottom: 1px solid var(${colorCssVars.dividerSoft});
          }
          /*
         * Moves the loading indicator / View More button down into the padding
         * on the card.
         */
          margin-bottom: -${spacing.xs};
        `}
        className={className}
        ref={ref}
      >
        {finalDonations.length > 0 && (
          <ul
            css={css`
              /*
             * If the loading indicator / View More button are not present,
             * properly positions this element to respect card padding.
             */
              &:last-child {
                margin-bottom: ${spacing.xs};
              }
            `}
          >
            {finalDonations.map((donationCharge) => {
              if (donationCharge) {
                return (
                  <NonprofitPageV3RecentContributor
                    donation={donationCharge.donation}
                    feedId={donationChargeFeedId[donationCharge.id]}
                    key={donationCharge.id}
                  />
                );
              }
              return null;
            })}
          </ul>
        )}
        {fetching ? (
          <Loading
            css={css`
              margin-top: ${spacing.m};
            `}
          />
        ) : (
          moreItemsToFetch && (
            <Button
              css={css`
                margin-top: ${spacing.s};
              `}
              data-tname="RecentContributors--ViewMore"
              role={ButtonRole.TEXT_ONLY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: fetchMoreItems,
              }}
              disabled={fetching}
            >
              View more
            </Button>
          )
        )}
      </div>
    </Card>
  );
});

interface NonprofitPageV3RecentContributorsCardProps {
  className?: string;
  nonprofit: ContextNonprofit;
  /**
   * Optional Set of donation IDs to exclude.
   */
  excludeDonationIds?: Set<string>;
  fundraiserId?: FundraiserResponse["id"];
  sortChronologically?: boolean;
  nonprofitFeed?: FeedItemResponse[];
  hasMore?: boolean;
}

/**
 * Widget of recent supporters nonprofit.
 */
export const NonprofitPageV3RecentContributorsCard: React.FCC<
  NonprofitPageV3RecentContributorsCardProps
> = ({
  className,
  nonprofit,
  excludeDonationIds,
  fundraiserId,
  sortChronologically,
  hasMore,
  nonprofitFeed = [],
}) => {
  const fetchFeed = useCallback(
    ({ skip, take }: ListParams) => {
      return fetchNonprofitFeed({
        skip,
        take,
        nonprofitId: nonprofit.id,
        fundraiserId,
      });
    },
    [nonprofit.id, fundraiserId]
  );

  const hideFundraiseText = nonprofit.metadata?.hideFundraiseButton;

  const placeholder = <Placeholder hideFundraiseText={hideFundraiseText} />;

  return (
    <Feed
      initialItems={nonprofitFeed}
      initialHasMore={hasMore}
      page={FeedPage.RECENT_CONTRIBUTORS}
      loadMoreItems={fetchFeed}
      FeedDisplayComponent={NonprofitPageV3RecentContributorsCardListDisplay}
      take={12}
      placeholder={placeholder}
      nonprofit={nonprofit}
      excludeDonationIds={excludeDonationIds}
      sortChronologically={sortChronologically}
    />
  );
};

const Title = styled.h2`
  ${textSizeCss[TextSize.m]};
  margin-bottom: ${spacing.xs};
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 300px;
  padding: ${spacing.xl} ${spacing.l};

  display: flex;
  flex-direction: column;
  align-items: center;

  background: url(${RecentSupportersPlaceholderSmall}) 50% 100% no-repeat,
    linear-gradient(180deg, #c8e8e1 0%, rgba(200, 232, 225, 0.4) 100%), #ffffff;

  border-radius: 16px;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      height: 200px;
      align-items: flex-start;
      justify-content: center;
      background: url(${RecentSupportersPlaceholder}) 100% 100% no-repeat,
        linear-gradient(180deg, #c8e8e1 0%, rgba(200, 232, 225, 0.4) 100%),
        #ffffff;
      background-size: contain;
    `,
  })}
`;

const Placeholder = ({
  hideFundraiseText,
}: {
  hideFundraiseText?: boolean;
}) => {
  return (
    <PlaceholderContainer>
      <h3 css={textSizeCss.l}>Become a supporter!</h3>
      {!hideFundraiseText && <p>Donate or start a fundraiser</p>}
    </PlaceholderContainer>
  );
};
