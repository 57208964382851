import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useState } from "react";

import { getWindow } from "src/utility/window";

interface UseScrolledDownParams {
  /**
   * Minimum y position in pixels from top of screen to be considered scrolled
   * down
   */
  minY: number;
}

export function useScrolledDown({ minY }: UseScrolledDownParams) {
  const window = getWindow();
  const [scrolledDown, setScrolledDown] = useState(window?.scrollY ?? 0 > minY);
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (scrolledDown && -y <= minY) {
        setScrolledDown(false);
      }
      if (!scrolledDown && -y > minY) {
        setScrolledDown(true);
      }
    },
    [scrolledDown, setScrolledDown],
    undefined,
    undefined,
    50 // don't call so often
  );
  return scrolledDown;
}
