import { Button, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Modal } from "@components/Modal";
import {
  CauseCategoryGroupContainer,
  CausesListGroup,
  CausesSelector,
} from "@components/SearchResults/CausesSelector";
import { LocationSelector } from "@components/SearchResults/LocationSelector";
import { SearchHeader } from "@components/SearchResults/SearchHeader";
import { SizeSelector } from "@components/SearchResults/SizeSelector";
import { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";

import { Location } from "@every.org/common/src/codecs/location";
import { NonprofitRevenueSize } from "@every.org/common/src/entity/types";
import { joinCauses, splitCauses } from "@every.org/common/src/helpers/causes";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { searchParamToBool } from "@every.org/common/src/helpers/string";

import { submitSearchAction } from "src/context/SearchContext";
import {
  useSearchOptionsFromUrl,
  useSearchRoute,
  FILTER_MODAL_URL_PARAM,
} from "src/context/SearchContext/helpers";
import { ContextSearchParams } from "src/context/SearchContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { ButtonRole, ButtonSize } from "src/styles/button";
import { lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

// Styles
//#region
const MODAL_PADDING = spacing.l;

const fullWidthCausesListGroupCss = css`
  ${CauseCategoryGroupContainer} {
    padding-left: ${MODAL_PADDING};
    padding-right: ${MODAL_PADDING};
  }
  ${CausesListGroup} {
    margin: 0 -${MODAL_PADDING};
  }
`;

const StyledCard = styled(Card)`
  ${fullWidthCausesListGroupCss};
  ${verticalStackCss.m};
  padding: ${spacing.l};
`;

const StyledModalContent = styled.div`
  position: relative;
  ${fullWidthCausesListGroupCss};
  ${verticalStackCss.m};
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing.xs};

  position: sticky;
  bottom: 0;
  z-index: 1;

  padding: 0 ${MODAL_PADDING} ${spacing.m};
  margin: 0 -${MODAL_PADDING};

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );

  button {
    padding: ${spacing.s} ${spacing.s};
  }
`;

const modalHeaderCssOverwrite = css`
  box-shadow: 0px 0px 4px rgba(46, 52, 52, 0.1);

  span {
    ${textSizeCss.s}
    font-weight: ${FontWeight.MEDIUM};
  }
  h4 {
    max-height: unset;
  }
`;
//#endregion

export const SearchFilter = ({
  customStyle,
}: {
  customStyle?: CSSInterpolation;
}) => {
  const urlSearchOptions = useSearchOptionsFromUrl();
  const [location, setLocation] = useState(urlSearchOptions.address);
  const [size, setSize] = useState(urlSearchOptions.size);
  const [causes, setCauses] = useState(urlSearchOptions.causes);

  useEffect(() => {
    setLocation(urlSearchOptions.address);
    setSize(urlSearchOptions.size);
    setCauses(urlSearchOptions.causes);
  }, [urlSearchOptions]);

  return (
    <aside
      css={[
        css`
          padding: 0 ${spacing.xs} ${spacing.l};
        `,
        cssForMediaSize({
          min: MediaSize.LARGE,
          css: css`
            padding: 0 0 0 ${spacing.xl};
          `,
        }),
        customStyle,
      ]}
    >
      <StyledCard>
        <LocationSelector
          address={location}
          onLocationChanged={(location: Location | null) => {
            setLocation(location?.address);
            urlSearchOptions &&
              submitSearchAction &&
              submitSearchAction({
                ...urlSearchOptions,
                address: location?.address,
                lat: location?.lat,
                lng: location?.lng,
              });
          }}
        />
        <SizeSelector
          size={size}
          onSizeChange={(size) => {
            setSize(size);
            urlSearchOptions &&
              submitSearchAction &&
              submitSearchAction({ ...urlSearchOptions, size });
          }}
        />
        <CausesSelector
          causes={splitCauses(causes) || []}
          onCausesChange={(causes) => {
            setCauses(joinCauses(causes));
            urlSearchOptions &&
              submitSearchAction &&
              submitSearchAction({
                ...urlSearchOptions,
                causes: joinCauses(causes),
              });
          }}
        />
      </StyledCard>
    </aside>
  );
};

export const SearchFilterModalButton = ({
  hideText = true,
}: {
  hideText?: boolean;
}) => {
  const router = useEdoRouter();
  const urlSearchOptions = useSearchOptionsFromUrl();
  const searchRouteName = useSearchRoute().name;
  const isCausesPage = searchRouteName === ClientRouteName.CAUSES;

  const [showFilter, setShowFilter] = useState(isCausesPage);

  useEffect(() => {
    const showFilterFromUrl = searchParamToBool(
      new URLSearchParams(router.search).get(FILTER_MODAL_URL_PARAM)
    );

    if (showFilterFromUrl) {
      const searchParams = new URLSearchParams(router.search);
      searchParams.delete(FILTER_MODAL_URL_PARAM);

      router.replace(
        getRoutePath({
          name: searchRouteName,
          format: URLFormat.RELATIVE,
          query: Object.fromEntries(searchParams.entries()),
        })
      );
      setShowFilter(true);
    }
  }, [router, searchRouteName, setShowFilter]);
  const [changesToApply, setChangesToApply] =
    useState<ContextSearchParams>(urlSearchOptions);

  const onCloseModal = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    setChangesToApply(urlSearchOptions);
  }, [urlSearchOptions]);

  //#region Hendlers
  const onLocationChanged = useCallback((location: Location | null) => {
    setChangesToApply((prev) => ({
      ...prev,
      address: location?.address,
      lat: location?.lat,
      lng: location?.lng,
    }));
  }, []);

  const onSizeChange = useCallback((size?: NonprofitRevenueSize) => {
    setChangesToApply((prev) => ({ ...prev, size }));
  }, []);

  const onCauseChange = useCallback((causes?: string[]) => {
    setChangesToApply((prev) => ({ ...prev, causes: joinCauses(causes) }));
  }, []);

  const clearFilters = useCallback(() => {
    setChangesToApply(({ query }) => ({ query }));
  }, []);

  const onApplyChanges = useCallback(() => {
    submitSearchAction && submitSearchAction(changesToApply);
    onCloseModal();
  }, [changesToApply]);
  //#endregion

  return (
    <React.Fragment>
      <Button
        data-tname="ShowFilterButton"
        role={ButtonRole.TEXT_ONLY}
        size={ButtonSize.SMALL}
        contentCss={[horizontalStackCss.xxs]}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            setShowFilter(!showFilter);
          },
        }}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/FilterIcon")}
          display={IconDisplay.ACCENT}
          size={IconSize.MEDIUM}
        />
        {!hideText && <span>Filter</span>}
      </Button>
      <Modal
        isOpen={showFilter}
        onRequestClose={onCloseModal}
        showHeader
        modalHeaderCss={modalHeaderCssOverwrite}
        headerText={<SearchHeader urlSearchOptions={changesToApply} />}
        footerDecoration
      >
        <StyledModalContent>
          <LocationSelector
            address={urlSearchOptions.address}
            onLocationChanged={onLocationChanged}
          />
          <SizeSelector
            size={changesToApply.size}
            onSizeChange={onSizeChange}
          />
          <CausesSelector
            causes={splitCauses(changesToApply.causes) || []}
            onCausesChange={onCauseChange}
          />
          <ButtonsContainer>
            <Button
              data-tname="FilterClearButton"
              role={ButtonRole.SECONDARY}
              css={lightBgThemeCss}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: clearFilters,
              }}
            >
              Clear
            </Button>
            <Button
              data-tname="FilterApplyButton"
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: onApplyChanges,
              }}
            >
              Apply
            </Button>
          </ButtonsContainer>
        </StyledModalContent>
      </Modal>
    </React.Fragment>
  );
};
