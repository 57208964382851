import styled from "@emotion/styled";
import React from "react";

import { FontWeight } from "src/theme/text";

const Strong = styled.strong`
  font-weight: ${FontWeight.BOLD};
`;

interface TestimonialPromtProps {
  string: string;
  nonprofitName: string;
  className?: string;
}

/**
 * This component takes a string with placeholders and formatting markers,
 * replaces the placeholders with the provided nonprofit name, and applies
 * formatting to the text.
 *
 * Supported formatting:
 *
 * {nonprofitName} - placeholder for the nonprofit name
 * **text** - bold font
 * \n- new line
 * \u00A0 - non-breaking space
 *
 */
export const TestimonialPromt = ({
  string,
  nonprofitName,
  className,
}: TestimonialPromtProps) => {
  const parseString = (input: string) => {
    const replacedString = input.replace(/{nonprofitName}/g, nonprofitName);

    const lines = replacedString
      .replace(/\\n/g, "\n")
      .replace(/\\u00A0/g, "\u00A0")
      .split("\n")
      .map((line, index) => {
        const parts = line.split(/\*\*(.+?)\*\*/g);

        return (
          <span key={index}>
            {parts.map((part, idx) => {
              if (idx % 2 !== 0) {
                return <Strong key={idx}>{part}</Strong>;
              }
              return part;
            })}
          </span>
        );
      });

    return lines;
  };

  return <p className={className}>{parseString(string)}</p>;
};
