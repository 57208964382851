/**
 * Components that displays pieces of data/information in the header cards.
 */
import { ModalButton, ModalButtonProps } from "@components/ModalButton";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import React, { HTMLAttributes } from "react";

import { abbreviateNumber } from "@every.org/common/src/helpers/number";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { TextSize, FontWeight, textSizeCss } from "src/theme/text";

export enum LayoutDirection {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

const entryCss = css`
  display: flex;
  justify-content: center;

  dt {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss[TextSize.xs]}
  }
  dd {
    ${truncatedTextCss({ fixHeight: true, numLines: 1 })};
    font-weight: ${FontWeight.BOLD};
    ${textSizeCss[TextSize.s]};
    ${cssForMediaSize({ min: MediaSize.MEDIUM, css: textSizeCss[TextSize.m] })};
  }
`;

const verticalCss = css`
  flex-direction: column;
  align-items: flex-start;
`;

const horizontalCss = css`
  flex-direction: row;
  align-items: center;

  gap: ${spacing.xxs};
`;

interface HeaderCardMetadataProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  data?: number | string;
  label: string;
  labelFirst?: boolean;
  layoutDirection?: LayoutDirection;
  entryCssOverride?: CSSInterpolation;
  /**
   * Content schema to attach to the microdata for metadata props
   * @example
   * "https://schema.org/DonateAction"
   */
  microdataInteractionType?: string;
}

/**
 * Component providing some numerical information with a label to use within a
 * profile header card.
 *
 * @param data The numerical data. Undefined is allowed to allow for
 * asynchronous fetching of the data. data can also be a string.
 * @param label The label for the data.
 */
export const HeaderCardMetadataEntry: React.FCC<HeaderCardMetadataProps> = ({
  data,
  label,
  labelFirst = true,
  layoutDirection,
  entryCssOverride,
  microdataInteractionType,
  ...rest
}) => {
  const dtCss = labelFirst
    ? undefined
    : css`
        order: 1;
      `;
  return (
    <div {...rest}>
      <dl
        css={[
          entryCssOverride || entryCss,
          layoutDirection === LayoutDirection.VERTICAL
            ? verticalCss
            : horizontalCss,
        ]}
      >
        {microdataInteractionType && (
          <meta itemProp="interactionType" content={microdataInteractionType} />
        )}
        <dt css={dtCss}>{label}</dt>
        <dd itemProp="userInteractionCount">
          {data && typeof data === "number" ? abbreviateNumber(data, 1) : data}
        </dd>
      </dl>
    </div>
  );
};

interface HeaderCardMetadataModalButtonProps {
  headerCardMetadataProps: HeaderCardMetadataProps;
  modalButtonProps: Omit<ModalButtonProps, "buttonContent">;
}

/**
 * Clickable modal wrapper around header card's metadata.
 */
export const HeaderCardMetadataModalButton: React.FCC<
  HeaderCardMetadataModalButtonProps
> = ({ headerCardMetadataProps, modalButtonProps }) => {
  const content = (
    <HeaderCardMetadataEntry
      itemProp="interactionStatistic"
      itemScope
      itemType="https://schema.org/InteractionCounter"
      {...headerCardMetadataProps}
      layoutDirection={
        headerCardMetadataProps.layoutDirection || LayoutDirection.VERTICAL
      }
    />
  );
  return <ModalButton buttonContent={content} {...modalButtonProps} />;
};
