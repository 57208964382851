import { BaseIconProps, IconSize, heightForIconSize } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import HeartAnimationSvg from "src/assets/icons/heartAnimation.svg";

const defaultIconSize = heightForIconSize[IconSize.MEDIUM];

const IconContainer = styled.div`
  position: relative;
  width: ${defaultIconSize}px;
  height: ${defaultIconSize}px;
`;

const Icon = styled.div<{ liked: boolean; size: IconSize }>`
  width: 80px;
  height: 80px;
  pointer-events: none;
  position: absolute;
  right: 50%;
  bottom: 50%;
  background: url(${HeartAnimationSvg}) no-repeat;
  background-size: 2320px 80px;
  background-position: 0 0;
  cursor: pointer;

  ${({ size }) => {
    return css`
      transform: translate(50%, 50%)
        scale(${heightForIconSize[size] / defaultIconSize});
    `;
  }}

  ${({ liked }) => {
    return (
      liked &&
      css`
        background-position: -2240px 0;
        transition: background 1s steps(28);
      `
    );
  }}
`;

interface HeartAnimationBaseIconProps extends Omit<BaseIconProps, "display"> {
  liked: boolean;
}

export const HeartAnimationIcon: React.FCC<HeartAnimationBaseIconProps> = ({
  className,
  ...rest
}) => {
  return (
    <IconContainer className={className}>
      <Icon {...rest} />
    </IconContainer>
  );
};
