import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  DonateFormType,
  CreateOrUpdateDonationResult,
} from "@components/donate/DonateV3/types";
import { UseFormReturn } from "react-hook-form";

export interface ChariotProps {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  createOrUpdateDonationResult: CreateOrUpdateDonationResult;
}

export interface ChariotData {
  anonymous?: boolean;
  amount?: number; // In USD cents
  dedicated_on_behalf_of?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  frequency?: "ONE_TIME" | "MONTHLY"; // Default: ONE_TIME. MONTHLY is not available yet.
  phone?: string;
}

export enum ChariotResponseStatus {
  CHARIOT_SUCCESS = "CHARIOT_SUCCESS",
  CHARIOT_EXIT = "CHARIOT_EXIT",
  CHARIOT_ERROR = "CHARIOT_ERROR",
}

export type ChariotButtonHTMLElement = HTMLElement & {
  onDonationRequest: (handler: () => ChariotData | undefined) => void;
};

export type ChariotButtonHTMLAttributes = React.DetailedHTMLProps<
  React.HTMLAttributes<ChariotButtonHTMLElement>,
  HTMLElement
> & {
  cid: string;
};
