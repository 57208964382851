import { UserResponse, Uuid } from "@every.org/common/src/codecs/entities";
import { Username } from "@every.org/common/src/codecs/username";

export type UserIdentifier = { username: Username } | { id: Uuid };

export enum UsersActionType {
  ADD_USER = "ADD_USER",
  ADD_USERS = "ADD_USERS",
  FETCHING_USER = "FETCHING_USER",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}
interface AddUserAction {
  type: UsersActionType.ADD_USER;
  data: UserResponse;
}
interface AddUsersAction {
  type: UsersActionType.ADD_USERS;
  data: UserResponse[];
}
interface FetchingUserAction {
  type: UsersActionType.FETCHING_USER;
  data: UserIdentifier;
}
interface UserNotFoundAction {
  type: UsersActionType.USER_NOT_FOUND;
  data: UserIdentifier;
}
export type UsersAction =
  | AddUserAction
  | AddUsersAction
  | FetchingUserAction
  | UserNotFoundAction;

export const FETCHING_USER = Symbol("FETCHING_USER");
export const USER_NOT_FOUND = Symbol("USER_NOT_FOUND");

export type UserContextValue =
  | UserResponse
  | typeof FETCHING_USER
  | typeof USER_NOT_FOUND;

export interface UsersState {
  /**
   * Stores users by user ID.
   */
  usersById: Map<UserResponse["id"], UserContextValue>;

  /**
   * Stores users by their username. Note that deleted Users will not appear in
   * this map.
   */
  usersByUsername: Map<Required<UserResponse>["username"], UserContextValue>;
}
