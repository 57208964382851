import { ErrorMessage } from "@components/ErrorMessage";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { verticalStackCss, spacing } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

export const TextInputLabelText = styled.label`
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
  ${cssForMediaSize({ min: MediaSize.MEDIUM, css: textSizeCss[TextSize.m] })}
`;
interface ValidationStatusSuccess {
  success: true;
  message?: string;
}

interface ValidationStatusError {
  success: false;
  message?: string;
}

export type ValidationStatus = ValidationStatusSuccess | ValidationStatusError;

const Description = styled.span<{ validationSuccess?: boolean }>`
  color: ${({ validationSuccess }) =>
    validationSuccess === undefined
      ? `var(${colorCssVars.text.secondary})`
      : validationSuccess
      ? `var(${colorCssVars.input.border.focus})`
      : `var(${colorCssVars.input.border.error})`};
`;
export interface InputContainerProps {
  "data-tname": string;
  name?: string;

  /**
   * If present, labels the text input
   */
  labelText?: React.ReactNode;

  /**
   * Whether or not the component should leave space for the label, even if no
   * label is given.
   *
   * @default true
   */
  collapseLabelSpace?: boolean;

  /**
   * If present, renders a description of what the input is about
   */
  description?: React.ReactNode;

  /**
   * If present, indicates results from validating the input value
   */
  validationStatus?: ValidationStatus;

  /**
   * Whether or not the component should leave space for the description or
   * error field
   *
   * - This is useful to prevent TextInputs without descriptions from changing
   *   sizes when validation errors appear
   *
   * @default false
   */
  collapseDescriptionSpace?: boolean;
}

export const InputContainer: React.FCC<
  InputContainerProps & React.HTMLProps<HTMLDivElement>
> = ({
  className,
  name,
  labelText,
  description,
  validationStatus,
  collapseLabelSpace = true,
  collapseDescriptionSpace = false,
  children,
  "data-tname": dataTName,
  ...rest
}) => {
  const message = validationStatus?.message
    ? validationStatus.message
    : description;

  const decriptionTName = `${dataTName ? dataTName : "Input"}-Description`;

  return (
    <div
      css={[
        verticalStackCss.s,
        css`
          ${TextInputLabelText} {
            margin-bottom: ${spacing.xxs};
          }
        `,
      ]}
      {...rest}
      className={className}
    >
      {labelText ? (
        <TextInputLabelText htmlFor={name}>{labelText}</TextInputLabelText>
      ) : collapseLabelSpace ? null : (
        // Kind of hacky - ensures that this takes up a full line-height of
        // space even with no content
        <TextInputLabelText htmlFor={name}>&#8203;</TextInputLabelText>
      )}
      {children}
      {message ? (
        validationStatus &&
        !validationStatus.success &&
        validationStatus?.message ? (
          <ErrorMessage data-tname={`${decriptionTName}Error`}>
            {message}
          </ErrorMessage>
        ) : (
          <Description
            data-tname={decriptionTName}
            validationSuccess={validationStatus?.success}
          >
            {message}
          </Description>
        )
      ) : collapseDescriptionSpace ? null : (
        // Kind of hacky - ensures that this takes up a full line-height of
        // space even with no content
        <Description data-tname={decriptionTName}>&#8203;</Description>
      )}
    </div>
  );
};
