/**
 * A higher-order component that wraps the passed-in component in a popover menu
 * element with a rotating arrow (similar to what you see in the nav bar).
 *
 * It provides the wrapped-component with the setVisible trigger, so the
 * component can update the visibility of the menu itself.
 */
import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import {
  heightForIconSize,
  Icon,
  IconDisplay,
  IconSize,
} from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";

import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { colorCssVars } from "src/theme/color";
import { roundedBorder } from "src/theme/common";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";

export const appearAnimationCss = css`
  @keyframes appear {
    0% {
      transform: translateY(-${spacing.s});
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  animation: appear 0.25s ease-in-out;
`;

//
// Styles
//
//#region
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 500px;
  color: var(${colorCssVars.headerIcon.text.normal});
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding: ${spacing.xxxs};

      &:hover {
        color: var(${colorCssVars.headerIcon.text.hover});
        background-color: var(${colorCssVars.headerIcon.background.hover});
      }
    `,
  })}
`;

const StyledPopover = styled(Card)`
  ${roundedBorder};
  ${appearAnimationCss};
  position: absolute;
  top: calc(${spacing.l} + ${heightForIconSize[IconSize.MEDIUM]}px);
  right: 0;
  padding: ${spacing.l} 0 calc(${spacing.l} - ${spacing.xxs}) ${spacing.l};
  width: 230px;
  max-width: 230px;
  box-shadow: 0px 8px 32px rgba(46, 52, 52, 0.2);
  z-index: 4;
`;

export const ListSection = styled.div`
  ${verticalStackCss.xs};
  padding-right: ${spacing.l};
`;

const Container = styled.div`
  position: relative;
`;

const StyledList = styled.ul`
  ${verticalStackCss.m};
  & > ${ListSection}:not(:last-child) {
    border-bottom: 1px solid var(${colorCssVars.dividerSoft});
    padding-bottom: ${spacing.m};
  }
`;

const rotateCss = css`
  position: relative;
  top: -1px;
  transform: rotate(180deg);
`;

//#endregion

interface PopoverMenuChildProps {
  setVisible: (visible: boolean) => void;
}

export function popoverMenu<P extends PopoverMenuChildProps>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ChildComponent: React.ComponentType<P>
) {
  const WrappedComponent = (props: Omit<P, "setVisible">) => {
    const [visible, setVisible] = useState(false);

    const ref = useOnClickOutside<HTMLDivElement>({
      handleClickOutside: () => {
        setVisible(false);
      },
    });

    // Using "any" here because I was having a really tough time with the
    // interaction between Typescript's difficulty in reconciling that Omit<X,
    // y> & {y: X[y]} is equal to X
    // (https://stackoverflow.com/q/61265219/888970,
    // https://github.com/microsoft/TypeScript/issues/35858#issuecomment-573909154)
    // and the type that emotionjs creates for components when importing
    // emotion/react:
    // https://github.com/emotion-js/emotion/issues/2169#issuecomment-765813992.
    //
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any
    const newProps: any = { ...props, setVisible } as P;

    return (
      <Container ref={ref}>
        <StyledButton
          aria-label="Header popover menu button"
          data-tname="HeaderPopoverMenu_Button"
          role={ButtonRole.UNSTYLED}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              setVisible(!visible);
            },
          }}
        >
          <Icon
            css={[visible && rotateCss]}
            iconImport={() => import("@components/Icon/icons/SelectArrowIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.CURRENT_COLOR}
          />
        </StyledButton>
        {visible && (
          <StyledPopover>
            <StyledList data-tname="popover-dropdown">
              <ChildComponent {...newProps} />
            </StyledList>
          </StyledPopover>
        )}
      </Container>
    );
  };
  return WrappedComponent;
}
