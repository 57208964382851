import { AvatarSize } from "@components/Avatar";
import { Header, StyledSection } from "@components/DonationCardHeader/styles";
import { LinkAppearance } from "@components/Link";
import { TagIllustration } from "@components/TagIllustration";
import { TagLabel } from "@components/TagIllustrationLabel";
import { css } from "@emotion/react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { colorCssVars } from "src/theme/color";
import { ClickAction } from "src/utility/analytics";

interface SuggestedNonprofitCardHeaderProps {
  tag?: TagResponse;
  text: string;
  hideCause?: boolean;
}

export const SuggestedNonprofitCardHeader = ({
  tag,
  text,
  hideCause,
}: SuggestedNonprofitCardHeaderProps) => {
  if (!tag) {
    return null;
  }

  const linkProps = {
    title: `Navigate to cause ${tag.title}`,
    dataAction: ClickAction.CAUSE,
    to: getRoutePath({
      format: URLFormat.RELATIVE,
      name: ClientRouteName.NONPROFIT_OR_CAUSE,
      tokens: { nonprofitSlug: tag.tagName },
    }),
    appearance: LinkAppearance.UNSTYLED,
    css: { display: "inline-flex" },
  };

  return (
    <Header>
      <Link
        data-tname={`SuggestedNonprofit--tagIllustration--${tag.tagName}`}
        {...linkProps}
      >
        <TagIllustration tag={tag} size={AvatarSize.XX_SMALL} />
      </Link>
      <StyledSection>
        <span
          css={css`
            color: var(${colorCssVars.text.secondary});
          `}
        >
          {text}
        </span>
        {!hideCause && (
          <Link
            data-tname={`SuggestedNonprofit--tagLink--${tag.tagName}`}
            {...linkProps}
          >
            <TagLabel tag={tag} />
          </Link>
        )}
      </StyledSection>
    </Header>
  );
};
