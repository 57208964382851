/**
 * Standard spacing values expressed as number of pixels
 */
export const rawSpacing = {
  xxxxl: 80,
  xxxl: 64,
  xxl: 40,
  xl: 32,
  l: 24,
  oldM: 20, // TODO: remove all usages of this
  m: 16,
  s: 12,
  xs: 8,
  xxs: 4,
  xxxs: 2,
  none: 0,
};

/**
 * Standard spacing values expressed as pixel CSS strings
 */
export const spacing = Object.fromEntries(
  Object.entries(rawSpacing).map((value) => [value[0], `${value[1]}px`])
) as {
  [key in keyof typeof rawSpacing]: string;
};
