import {
  DefaultPageLayoutProps,
  DefaultPageLayout,
} from "@components/layout/DefaultPageLayout";
import { css } from "@emotion/react";
import Image, { ImageProps } from "next/image";
import React from "react";

import { DARK_THEME } from "@every.org/common/src/display/palette";

import { allWhiteThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";

interface ImageHeaderPageLayoutProps extends DefaultPageLayoutProps {
  /**
   * Source of image to display as background
   */
  imgCloudinaryId: string | null;

  /**
   * Background color if imgSrc is null. Defaults to dark theme background color.
   */
  fallbackBackgroundColor?: string;

  /**
   * Whether or not to dim the background to create greater contrast for
   * overlaid text
   *
   * @default false
   */
  dimBackground?: boolean;

  priority?: ImageProps["priority"];
}

const baseImageHeaderCss = css`
  ${allWhiteThemeCss};
  background-size: cover;
  background-position: center;
  position: relative;
`;
const dimBackgroundCss = css`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    background: black;
    z-index: 1;
  }
`;

export const IMG_HEADER_CONTENT_MIN_HEIGHT_NUM_PX = 120;
export const IMG_HEADER_CONTENT_MIN_HEIGHT_L_NUM_PX = 200;
export const IMG_HEADER_CONTENT_MIN_HEIGHT = `${IMG_HEADER_CONTENT_MIN_HEIGHT_NUM_PX}px`;
export const IMG_HEADER_CONTENT_MIN_HEIGHT_L = `${IMG_HEADER_CONTENT_MIN_HEIGHT_L_NUM_PX}px`;
/**
 * Layout component for a page on the website that has an image background in
 * the header.
 *
 * @param imgCloudinaryId Cloudinary ID of the background to render, or null (renders no background).
 */
export const ImageHeaderPageLayout: React.FCC<ImageHeaderPageLayoutProps> = ({
  imgCloudinaryId,
  fallbackBackgroundColor: backgroundColor = DARK_THEME.background.normal,
  dimBackground = false,
  headerCss,
  headerContentCss,
  priority,
  ...rest
}) => {
  return (
    <DefaultPageLayout
      {...rest}
      headerBackgroundImage={
        imgCloudinaryId ? (
          <Image
            alt=""
            src={imgCloudinaryId}
            quality={80}
            priority={priority ?? true}
            style={{ objectFit: "cover", objectPosition: "center" }}
            fill
          />
        ) : (
          <React.Fragment />
        )
      }
      headerCss={[
        baseImageHeaderCss,
        dimBackground ? dimBackgroundCss : undefined,
        css`
          background-color: ${backgroundColor};
        `,
        headerCss,
      ].flat(1)}
      headerContentCss={[
        css`
          min-height: ${IMG_HEADER_CONTENT_MIN_HEIGHT};
          position: relative;
          z-index: 2;
        `,
        cssForMediaSize({
          min: MediaSize.MEDIUM,
          css: css`
            min-height: ${IMG_HEADER_CONTENT_MIN_HEIGHT_L};
          `,
        }),
        headerContentCss,
      ]}
    />
  );
};
