import { Button, ButtonTargetKind } from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { TextArea } from "@components/TextInput";
import {
  ShareButtons,
  trackShare,
} from "@components/donate/DonateV3/PaymentProcess/components/ShareMatch/ShareButtons";
import {
  CommentContainer,
  Container,
  Headline,
} from "@components/donate/DonateV3/PaymentProcess/components/ShareMatch/styles";
import { trackCampaign } from "@components/feed/DonationShareButton";
import { css } from "@emotion/react";
import React, { useState, useMemo, useCallback } from "react";

import {
  NonEmptyMatchingCampaignResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { Currency, ShareMedium } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { ButtonRole, ButtonSize } from "src/styles/button";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

interface ShareMatchProps {
  nonprofit: ContextNonprofit;
  donation?: PersonalDonationResponse;
  postDonationAction: () => void;
  matchingCampaign?: NonEmptyMatchingCampaignResponse;
  overrideUrl?: string;
}

export function ShareMatch({
  donation,
  nonprofit,
  postDonationAction,
  matchingCampaign,
  overrideUrl,
}: ShareMatchProps) {
  const [shared, setShared] = useState(false);

  const nonprofitUrl = trackCampaign({
    url:
      overrideUrl ||
      getRoutePath({
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        format: URLFormat.ABSOLUTE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      }),
    campaign: matchingCampaign?.code,
  });

  const fixedShareAmount = useMemo(() => {
    return matchingCampaign?.matchShare && matchingCampaign?.shareMatchAmount
      ? minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: matchingCampaign.shareMatchAmount,
          currency: matchingCampaign.currency,
        }).amount
      : null;
  }, [matchingCampaign]);
  const shareDisplayAmount = fixedShareAmount
    ? displayCurrencyValueInUserLocale({
        currencyValue: {
          amount: fixedShareAmount,
          currency: matchingCampaign?.currency || Currency.USD,
        },
      })
    : null;
  const maxUserMatch = matchingCampaign?.maxPerUserMatchAmount
    ? displayCurrencyValueInUserLocale({
        minDenomCurrencyValue: {
          amountInMinDenom: matchingCampaign?.maxPerUserMatchAmount,
          currency: matchingCampaign?.currency || Currency.USD,
        },
      })
    : null;
  const overrideComment = maxUserMatch
    ? `Join me and help ${matchingCampaign?.title} - double your donation up to ${maxUserMatch} here:`
    : undefined;
  const [commentText, setCommentText] = useState(
    `I just donated to ${nonprofit.name}. ${overrideComment} ${nonprofitUrl}`
  );

  const trackCopyClick = useCallback(() => {
    if (donation) {
      setShared(true);
      trackShare(donation.id, ShareMedium.COPY);
    }
  }, [donation]);

  return (
    <Container>
      <div>
        <Headline
          css={
            shared &&
            shareDisplayAmount &&
            css`
              position: fixed;
              animation: 0.75s fadeOut;
              animation-fill-mode: forwards;
              animation-timing-function: ease;

              @keyframes fadeOut {
                0% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                  transform: scale(0.95);
                  display: none;
                }
              }
            `
          }
        >
          {shareDisplayAmount ? (
            <span>
              Unlock an additional {shareDisplayAmount} match by sharing
            </span>
          ) : (
            "Now share why you donated and turn this moment into a movement"
          )}
        </Headline>
        {shared && shareDisplayAmount && (
          <Headline
            css={[
              css`
                opacity: 0;

                animation: 0.75s fadeIn;
                animation-fill-mode: forwards;
                animation-timing-function: ease;
                animation-delay: 0.75s;

                @keyframes fadeIn {
                  0% {
                    opacity: 0;
                    transform: scale(0.95);
                  }
                  100% {
                    opacity: 1;
                  }
                }
              `,
            ]}
          >
            <span role="img" aria-label="checkmark-emoji">
              ✅
            </span>
            &nbsp;You unlocked an additional <span>{shareDisplayAmount}</span>{" "}
            match by sharing
          </Headline>
        )}
      </div>
      <p>
        Help {nonprofit.name} by spreading the word about this match. Tap any of
        the links below, and we’ll add another {shareDisplayAmount} to your
        donation.
      </p>
      {donation && (
        <ShareButtons
          donation={donation}
          skipUserDonationUrl={
            !donation.commentText || donation.commentText.length === 0
          }
          overrideComment={overrideComment}
          campaign={matchingCampaign?.code}
          overrideUrl={overrideUrl}
          onShared={() => setShared(true)}
        />
      )}
      <CommentContainer>
        <TextArea
          data-tname="ChallengeComment"
          value={commentText}
          onChange={(e) => setCommentText(e.currentTarget.value)}
          rows={4}
          description={
            "Copy the text and links above to share in other ways, like text message, email or Instagram."
          }
        />
        <CopyToClipboardButton
          data-tname="ChallengeCommentCopy"
          role={ButtonRole.SECONDARY}
          size={ButtonSize.SMALL}
          textToCopy={commentText}
          css={{ alignSelf: "flex-end" }}
          beforeCopiedText="Copy"
          afterCopiedText="Copied!"
          onCopied={() => {
            trackCopyClick();
          }}
        />
      </CommentContainer>
      <Button
        data-tname="ChallengeCommentCopySkip"
        role={ButtonRole.TEXT_SECONDARY}
        css={{ alignSelf: "center" }}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            postDonationAction();
          },
        }}
      >
        {shared ? "Continue" : "Skip"}
      </Button>
    </Container>
  );
}
