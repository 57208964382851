/**
 * UsersContext stores user state on the client side. For those familiar with
 * the redux world, it's like the Users store and includes both Users actions
 * and the Users reducer.
 */

import React, { createContext, useReducer } from "react";

import { UserResponse } from "@every.org/common/src/codecs/entities";

import { userOrUndefined, getUser } from "src/context/UsersContext/selectors";
import {
  UsersAction,
  UsersActionType,
  UserIdentifier,
  UsersState,
  USER_NOT_FOUND,
  FETCHING_USER,
} from "src/context/UsersContext/types";

function updateStateWithUser(
  state: UsersState,
  newUserData: UserResponse
): UsersState {
  const existingUser = state.usersById.get(newUserData.id);
  const updatedUser = {
    ...(typeof existingUser === "object" && existingUser),
    ...newUserData,
  };
  return {
    usersById: new Map(state.usersById).set(updatedUser.id, updatedUser),
    usersByUsername: updatedUser.username
      ? new Map(state.usersByUsername).set(updatedUser.username, updatedUser)
      : state.usersByUsername,
  };
}

function updateStateWithStatus(
  state: UsersState,
  identifier: UserIdentifier,
  status: typeof USER_NOT_FOUND | typeof FETCHING_USER
): UsersState {
  const { usersById, usersByUsername } = state;
  const user = userOrUndefined(getUser(state, identifier));
  if (user) {
    return state;
  }
  if ("username" in identifier) {
    if (usersByUsername.get(identifier.username) !== status) {
      return {
        usersById: new Map(usersById),
        usersByUsername: new Map(usersByUsername).set(
          identifier.username,
          status
        ),
      };
    }
    return state;
  }
  return {
    usersById: new Map(usersById).set(identifier.id, status),
    usersByUsername: new Map(usersByUsername),
  };
}

function updateStateWithManyUsers(state: UsersState, users: UserResponse[]) {
  return users.reduce(
    (curState, curUser) => updateStateWithUser(curState, curUser),
    state
  );
}

function usersReducer(state: UsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionType.FETCHING_USER:
      return updateStateWithStatus(state, action.data, FETCHING_USER);
    case UsersActionType.USER_NOT_FOUND:
      return updateStateWithStatus(state, action.data, USER_NOT_FOUND);
    case UsersActionType.ADD_USER:
      return updateStateWithUser(state, action.data);
    case UsersActionType.ADD_USERS:
      return updateStateWithManyUsers(state, action.data);
    default:
      throw new Error(`User action with unknown type: ${action}`);
  }
}

const INITIAL_USERS_STATE: UsersState = {
  usersById: new Map(),
  usersByUsername: new Map(),
};

export let dispatchUsersAction: React.Dispatch<UsersAction>;

export const UsersContext = createContext<UsersState>(INITIAL_USERS_STATE);

export const UsersProvider: React.FCC<{ initialData?: UserResponse[] }> = ({
  children,
  initialData,
}) => {
  const [usersState, usersDispatcher] = useReducer(
    usersReducer,
    initialData
      ? updateStateWithManyUsers(INITIAL_USERS_STATE, initialData)
      : INITIAL_USERS_STATE
  );

  dispatchUsersAction = usersDispatcher;

  return (
    <UsersContext.Provider value={usersState}>{children}</UsersContext.Provider>
  );
};
