import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { CardCover } from "@components/CardCover";
import {
  bottomLeftCss,
  bottomRightCss,
  CoverContainer,
  Gradient,
} from "@components/feed/NewNonprofitCard/Cover/styles";
import { MultiCausesPopover } from "@components/feed/NewNonprofitCard/MultiCausesPopover";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useLoadImageWithPriorityContext } from "src/hooks/useLoadImageWithPriorityContext";

interface CoverProps {
  nonprofit: ContextNonprofit;
  highlightedCauses?: string[];
  onError?: () => void;
  rounded?: boolean;
}

export function Cover({
  nonprofit,
  highlightedCauses,
  onError,
  rounded = true,
}: CoverProps) {
  const nonprofitWithImagePriority = useLoadImageWithPriorityContext();

  const showGradient =
    nonprofit.nonprofitTags?.length || nonprofit.logoCloudinaryId;
  return (
    <div css={{ position: "relative" }}>
      <CoverContainer rounded={rounded}>
        <CardCover
          coverImageCloudinaryId={nonprofit.coverImageCloudinaryId}
          alt={nonprofit.metadata?.coverImageAltText}
          onError={onError}
          priority={nonprofitWithImagePriority.includes(nonprofit.id)}
        />
      </CoverContainer>
      {showGradient && <Gradient />}
      <div css={bottomLeftCss}>
        <MultiCausesPopover
          nonprofitTags={nonprofit.nonprofitTags}
          highlightedCauses={highlightedCauses}
        />
      </div>
      {nonprofit.logoCloudinaryId && (
        <div css={bottomRightCss}>
          <NonprofitAvatar
            size={AvatarSize.MEDIUM}
            nonprofit={nonprofit}
            drawBorder
            disablePlaceholder
          />
        </div>
      )}
    </div>
  );
}
