import { useContext, useState, useEffect } from "react";

import { UsersContext } from "src/context/UsersContext/";
import { fetchUser } from "src/context/UsersContext/actions";
import { getUser } from "src/context/UsersContext/selectors";
import {
  UsersState,
  UserContextValue,
  FETCHING_USER,
  USER_NOT_FOUND,
  UserIdentifier,
} from "src/context/UsersContext/types";

/**
 * Synchronously get a user and kick off an async fetch if the user
 * is not in our state.
 */
export function getOrFetchUser(
  state: UsersState,
  identifier: UserIdentifier
): UserContextValue {
  const user = getUser(state, identifier);
  if (user === undefined) {
    fetchUser(identifier);
    return FETCHING_USER;
  }
  return user;
}
/**
 * Use this hook, not getOrFetchUser, to avoid warning
 * errors about cannot update a component while rendering
 * a different component.
 */
export function useUser(identifier?: UserIdentifier) {
  const usersState = useContext(UsersContext);
  // user might be prefetched by ssr
  const stateUser = identifier ? getUser(usersState, identifier) : undefined;
  const [user, setUser] = useState<UserContextValue>(
    stateUser ? stateUser : identifier ? FETCHING_USER : USER_NOT_FOUND
  );

  useEffect(() => {
    if (identifier) {
      setUser(getOrFetchUser(usersState, identifier));
    }
  }, [identifier, usersState]);

  return user;
}
