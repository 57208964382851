import {
  ShareCheckboxType,
  ShareInfoCheckbox,
  getShareEmailText,
  getSharePopoverText,
} from "@components/donate/DonateV3/PaymentProcess/components/ShareInfoCheckbox";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { useFundraiserCreatorName } from "@components/donate/hooks";
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationVisibility,
  NonprofitType,
} from "@every.org/common/src/entity/types";

import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { AuthContextUser } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { ApiStatus } from "src/utility/apiClient/types";

function getDefaultIsPublic(
  lastDonationVisibility: DonationVisibility | undefined,
  user: AuthContextUser | undefined
) {
  if (!lastDonationVisibility) {
    return !user?.isPrivate;
  }
  switch (lastDonationVisibility) {
    case DonationVisibility.DEFAULT:
      return !user?.isPrivate;
    case DonationVisibility.EVERYONE:
      return true;
    case DonationVisibility.NO_ONE:
      return false;
  }
}

export const ShareInfo = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
}) => {
  const { control, watch, setValue } = form;
  const { partnerWebhookDetailsResponse, requireShareInfo } = formContext;
  const toNonprofits = watch("toNonprofits");

  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();

  const isPublic = watch("isPublic");
  const commentText = watch("commentText");
  const includeCommentText = watch("includeCommentText");

  const publicRequired = Boolean(includeCommentText && commentText?.length);

  const lastDonationVisibility = loggedInOrGuestUser?.lastDonationVisibility;
  useEffect(() => {
    // If we have not already defined a value for isPublic
    if (publicRequired) {
      setValue("isPublic", true);
      return;
    }
    if (isPublic === undefined) {
      setValue(
        "isPublic",
        getDefaultIsPublic(lastDonationVisibility, loggedInOrGuestUser)
      );
    }
  }, [
    isPublic,
    setValue,
    lastDonationVisibility,
    loggedInOrGuestUser,
    publicRequired,
  ]);

  const numNonprofits =
    toNonprofits?.length ||
    (nonprofit.type === NonprofitType.LIST
      ? nonprofit.endorsedNonprofitIds?.length
      : undefined);
  const entityName = numNonprofits
    ? `${nonprofit.name} and the ${numNonprofits} nonprofits ${
        requireShareInfo ? "you're" : "I am"
      } supporting`
    : nonprofit.name;

  const partnerName =
    partnerWebhookDetailsResponse &&
    partnerWebhookDetailsResponse.status === ApiStatus.SUCCESS
      ? partnerWebhookDetailsResponse.response.partnerName
      : null;
  const fundraiserCreatorName = useFundraiserCreatorName(fundraiser);
  useEffect(() => {
    if (fundraiserCreatorName) {
      setValue("shareInfoWithFundraiserCreator", true);
      return;
    }
  }, [fundraiserCreatorName, setValue]);
  return (
    <React.Fragment>
      {fundraiserCreatorName && (
        <Controller
          control={control}
          name="shareInfoWithFundraiserCreator"
          render={({ field: { onChange, value } }) => (
            <ShareInfoCheckbox
              dataTname="toggleShareInfoWithFundraiserCreator"
              checked={value}
              onClick={() => {
                onChange(!value);
              }}
              name="shareInfoWithFundraiserCreator"
              id="shareInfoWithFundraiserCreator"
              labelCss={css`
                color: var(
                  ${value
                    ? colorCssVars.text.body
                    : colorCssVars.text.secondary}
                );
                ${css`
                  cursor: pointer;
                `};
              `}
              labelText={getShareEmailText(
                loggedInOrGuestUser,
                fundraiserCreatorName
              )}
              infoText={getSharePopoverText(
                ShareCheckboxType.FUNDRAISER_CREATOR,
                fundraiserCreatorName
              )}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="shareInfo"
        render={({ field: { onChange, value } }) => (
          <ShareInfoCheckbox
            dataTname="toggleShareInfo"
            checked={value}
            onClick={() => {
              if (!requireShareInfo) {
                onChange(!value);
                if (nonprofit.metadata?.optInCheckbox) {
                  value && form.setValue("optInToMailingList", false);
                }
              }
            }}
            name="shareInfo"
            id="shareInfo"
            disabled={!!requireShareInfo}
            labelCss={css`
              color: var(
                ${value && !requireShareInfo
                  ? colorCssVars.text.body
                  : colorCssVars.text.secondary}
              );
              ${!requireShareInfo
                ? css`
                    cursor: pointer;
                  `
                : ""};
            `}
            labelText={
              requireShareInfo
                ? `Your email will be shared with ${entityName}`
                : getShareEmailText(loggedInOrGuestUser, entityName)
            }
            infoText={getSharePopoverText(
              ShareCheckboxType.EMAIL,
              entityName,
              !!requireShareInfo
            )}
          />
        )}
      />
      {!!partnerWebhookDetailsResponse && (
        <ShareInfoCheckbox
          // it is necessary to match the spacing
          checkboxCss={{ opacity: 0 }}
          labelCss={css`
            color: var(${colorCssVars.text.secondary});
          `}
          dataTname="toggleSharePartnerInfo"
          checked
          name="sharePartnerInfo"
          disabled
          labelText={`Your contact info will be shared with ${
            partnerName || "the partner who referred you to Every.org"
          }`}
        />
      )}
      <Controller
        control={control}
        name="isPublic"
        render={({ field: { onChange, value } }) => (
          <ShareInfoCheckbox
            dataTname="toggleIsPublic"
            checked={value}
            onClick={onChange}
            name="isPublic"
            id="isPublic"
            disabled={publicRequired}
            labelCss={css`
              color: var(
                ${value ? colorCssVars.text.body : colorCssVars.text.secondary}
              );
              cursor: pointer;
            `}
            labelText="Share my support publicly"
            infoText={getSharePopoverText(
              ShareCheckboxType.IS_PUBLIC,
              nonprofit.name,
              publicRequired
            )}
          />
        )}
      />
      {nonprofit.metadata?.optInCheckbox && (
        <Controller
          control={control}
          name="optInToMailingList"
          render={({ field: { onChange, value } }) => (
            <ShareInfoCheckbox
              dataTname="toggleOptInToMailingList"
              checked={value}
              onClick={() => {
                onChange(!value);
                !value && form.setValue("shareInfo", true);
              }}
              name="optInToMailingList"
              id="optInToMailingList"
              disabled={!!requireShareInfo}
              labelCss={css`
                color: var(
                  ${value && !requireShareInfo
                    ? colorCssVars.text.body
                    : colorCssVars.text.secondary}
                );
                ${!requireShareInfo
                  ? css`
                      cursor: pointer;
                    `
                  : ""};
              `}
              labelText="Also subscribe to their mailing list"
            />
          )}
        />
      )}
    </React.Fragment>
  );
};
