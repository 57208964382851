import { AvatarSize } from "@components/Avatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import {
  HeaderClickablePopover,
  clickableCss,
} from "@components/DonationCardHeader/styles";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { LinkAppearance } from "@components/Link";
import { UserLink } from "@components/UserLink";
import { css } from "@emotion/react";

import { useUser } from "src/context/UsersContext/hooks";
import {
  UserIdentifier,
  FETCHING_USER,
  USER_NOT_FOUND,
} from "src/context/UsersContext/types";
import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export const JoinsStreakPopover: React.FCC<{
  userIdentifiers: UserIdentifier[];
}> = ({ userIdentifiers }) => {
  return (
    <HeaderClickablePopover
      placement={"bottom"}
      css={css`
        font-weight: ${FontWeight.BOLD};
        color: var(${colorCssVars.text.body});
      `}
      contentCss={css`
        max-width: 300px;
      `}
      content={
        <div css={verticalStackCss.m}>
          {userIdentifiers.map((identifier, index, { length }) => (
            <JoinsStreakItem
              removeArrow={index === length - 1}
              key={identifier["id"] || identifier["username"]}
              identifier={identifier}
            />
          ))}
        </div>
      }
    >
      <span>{userIdentifiers.length - 1} others</span>
    </HeaderClickablePopover>
  );
};

const JoinsStreakItem: React.FCC<{
  removeArrow?: boolean;
  identifier: UserIdentifier;
}> = ({ removeArrow, identifier }) => {
  const user = useUser(identifier);

  if (user === FETCHING_USER || user === USER_NOT_FOUND) {
    return null;
  }

  return (
    <div
      css={[
        horizontalStackCss.s,
        css`
          align-items: center;
        `,
      ]}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <UserAvatar css={clickableCss} size={AvatarSize.SMALL} user={user} />
        {!removeArrow && (
          <Icon
            iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
            css={css`
              position: absolute;
              left: 50%;
              bottom: -50%;
              z-index: 4;
              transform: translate(-50%, 0) rotate(-90deg);
            `}
            display={IconDisplay.SECONDARY}
            size={IconSize.MEDIUM}
          />
        )}
      </div>
      <UserLink appearance={LinkAppearance.HYPERLINK_UNCOLORED} user={user} />
    </div>
  );
};
