import { Card } from "@components/Card";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";

import { darkBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { FontWeight, textSizeCss } from "src/theme/text";

const FGC_BG_HEIGHT = 215;
const FGC_BG_LEFT_ID = "fgc_banner_bg_l_nlr1i1";
const FGC_BG_RIGHT_ID = "fgc_banner_bg_r_qisqdn";

const StyledCard = styled(Card)<{
  leftImageUrl: string;
  rightImageUrl: string;
}>`
  ${darkBgThemeCss};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${spacing.l};
  border-radius: unset;

  .fgc-banner-headline {
    margin-bottom: ${spacing.xxs};
  }

  .fgc-banner-hashtag {
    margin-bottom: ${spacing.xs};
    font-weight: ${FontWeight.BOLD};
  }

  .fgc-banner-description {
    ${textSizeCss.xs}
    max-width: 716px;
  }

  ${({ leftImageUrl, rightImageUrl }) =>
    `background: url("${leftImageUrl}"),url("${rightImageUrl}"), linear-gradient(180deg, #00a37f 0%, #2f806d 100%)`};
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -200px, calc(100% + 200px), 0;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      border-radius: 16px;
      background-position-x: -100px, calc(100% + 100px), 0;

      .fgc-banner-headline {
        margin-bottom: ${spacing.xs};
      }
    `,
  })}
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      background-position-x: 0%, 100%, 0;
    `,
  })}
`;

export interface FGCBannerProps {
  depleted: boolean | undefined;
}

export function FGCBanner(props: FGCBannerProps) {
  const { depleted } = props;

  const [leftImageUrl, rightImageUrl] = [
    constructCloudinaryUrl({
      cloudinaryId: FGC_BG_LEFT_ID,
      dimensions: { height: FGC_BG_HEIGHT },
    }),
    constructCloudinaryUrl({
      cloudinaryId: FGC_BG_RIGHT_ID,
      dimensions: { height: FGC_BG_HEIGHT },
    }),
  ];

  return (
    <StyledCard {...{ leftImageUrl, rightImageUrl }}>
      <h2 className="fgc-banner-headline">
        {depleted ? "We did it!" : "The Monthly Match"}
      </h2>
      <p className="fgc-banner-hashtag">
        <strong>#FallGivingChallenge</strong>
      </p>
      <p className="fgc-banner-description">
        <div
          css={[
            css`
              display: none;
            `,
            cssForMediaSize({
              min: MediaSize.MEDIUM_LARGE,
              css: css`
                display: block;
              `,
            }),
          ]}
        >
          <strong>
            {depleted
              ? "Matching funds for this year's challenge have been maxed out."
              : "All new monthly donations will be matched up to $50 for the first two months."}
          </strong>{" "}
          {depleted
            ? "Thank you everyone for helping nonprofits have long-term sustained impact with your recurring donations!"
            : "Monthly donations help nonprofits have a long-term, sustained impact. That translates to a bigger impact from every dollar you donate."}
        </div>
        <div
          css={cssForMediaSize({
            min: MediaSize.MEDIUM_LARGE,
            css: css`
              display: none;
            `,
          })}
        >
          {depleted
            ? "Matching funds for this year's challenge have been maxed out!"
            : "All new monthly donations will be matched up to $50 for the first two months."}
        </div>
      </p>
    </StyledCard>
  );
}
