import { FeedbackLink } from "@components/FeedbackLink";
import { getGranteeName } from "@components/donate/DonateV3/FAQ";
import styled from "@emotion/styled";
import React from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { TextSize } from "@every.org/common/src/display/text";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { isOfficialFundraiser } from "@every.org/common/src/helpers/fundraisers";

import { Link } from "src/components/Link";
import { useParentNonprofit } from "src/context/NonprofitsContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const StyledFooter = styled.footer`
  ${verticalStackCss.xs}
  padding: ${spacing.l};
  ${textSizeCss[TextSize.xs]}
  color: var(${colorCssVars.text.secondary});
  a {
    color: var(${colorCssVars.text.secondary});
  }
  a:hover {
    color: var(${colorCssVars.text.secondary});
  }
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: { padding: `0 ${spacing.m}` },
  })}
`;

export const getTaxDeductibleStatement = (
  nonprofit: ContextNonprofit,
  fundraiser?: FundraiserResponse,
  parentNonprofit?: ContextNonprofit
) => {
  const granteeName = getGranteeName(nonprofit, parentNonprofit);

  const base = `100% of your donation is tax-deductible to the extent allowed by US law. 
      Your donation is made to Every.org, a tax-exempt US 501(c)(3) charity that
      grants unrestricted funds to ${granteeName} on your behalf. 
      As a legal matter, Every.org must provide any donations to ${granteeName} on an
      unrestricted basis, regardless of any designations or restrictions made by
      you.`;

  const isUnofficialFundraiser =
    fundraiser && !isOfficialFundraiser(fundraiser);

  let end = "";

  if (nonprofit.hasAdmin && isUnofficialFundraiser) {
    end = `Please note ${nonprofit.name} has not reviewed or approved the content of this peer-to-peer fundraiser.`;
  } else if (!nonprofit.hasAdmin && !isUnofficialFundraiser) {
    end = `Please note ${nonprofit.name} has not provided permission for this solicitation.`;
  } else if (!nonprofit.hasAdmin && isUnofficialFundraiser) {
    end = `Please note ${nonprofit.name} has not provided permission for this solicitation or reviewed or approved the content of this peer-to-peer fundraiser.`;
  }

  return (
    <span>
      {base} {end}
    </span>
  );
};

export const Footer = ({
  nonprofit,
  fundraiser,
}: {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse;
}) => {
  const parentNonprofit = useParentNonprofit(nonprofit);

  return (
    <StyledFooter>
      <p>
        {getTaxDeductibleStatement(nonprofit, fundraiser, parentNonprofit)}{" "}
        <Link
          title="Link to Terms of Service"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.TERMS_OF_SERVICE,
          })}
          data-tname="Footer--Terms"
          target="terms"
        >
          See Terms
        </Link>
        .
      </p>
      <p>
        Need help? See FAQs or contact us at our{" "}
        <Link
          title="Link to Support"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.SUPPORT,
          })}
          data-tname="Footer--Support"
          target="support"
        >
          Help Center
        </Link>
        .
      </p>
      <p>
        Have ideas for how we can build a better donation experience? Send us{" "}
        <FeedbackLink
          title="Open feedback widget"
          data-tname="Footer--Feedback"
        >
          feedback
        </FeedbackLink>
        .
      </p>
      <p>
        We respect your privacy. To learn more, check out our{" "}
        <Link
          title="Link to Privacy Policy"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.PRIVACY_POLICY,
          })}
          data-tname="Footer--Privacy"
          target="privacy"
        >
          Privacy Policy
        </Link>
        .
      </p>
    </StyledFooter>
  );
};
