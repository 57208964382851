import * as t from "io-ts";

import { jsonStringCodec } from "@every.org/common/src/codecs/json";

import { getWindow } from "src/utility/window";

export enum LocalStorageKey {
  MOST_RECENT_COMMUNITY = "MOST_RECENT_COMMUNITY",
}

export const LocalStorageValue: { [key in LocalStorageKey]: t.Type<any> } = {
  [LocalStorageKey.MOST_RECENT_COMMUNITY]: jsonStringCodec.pipe(
    t.type({
      communitySlug: t.string,
      timestamp: t.number,
    })
  ),
};

function initLocalStorage() {
  const test = "__test__";
  // we hide global localStorage member to encourage use of this
  // helper; we access it directly in this helper only
  const window = getWindow() as { localStorage: Storage } | undefined;
  if (window) {
    try {
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return window.localStorage;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
}

const localStorage = initLocalStorage();

export function getLocalStorage() {
  return localStorage;
}
