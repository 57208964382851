import { useContext, useState, useEffect } from "react";

import { NonprofitsContext } from "src/context/NonprofitsContext/";
import { fetchNonprofit } from "src/context/NonprofitsContext/actions";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import {
  NonprofitFetchStatus,
  NonprofitIdentifier,
  NonprofitsState,
  ContextNonprofit,
} from "src/context/NonprofitsContext/types";

export type UseNonprofitResponse =
  | ContextNonprofit
  | NonprofitFetchStatus.FETCHING_NONPROFIT
  | NonprofitFetchStatus.NONPROFIT_NOT_FOUND;

/**
 * Returns the current value for the nonprofit. Used in useNonprofit
 * - to set initial value in useState
 * - and in useEffect to update the value
 */
const getNonprofitOrStatus = (
  nonprofitsState: NonprofitsState,
  identifier?: NonprofitIdentifier
): UseNonprofitResponse | undefined => {
  if (!identifier) {
    return NonprofitFetchStatus.NONPROFIT_NOT_FOUND;
  }
  return getNonprofit(nonprofitsState, identifier);
};

/**
 * Get the nonprofit for the given identifier.
 *
 * Accepts identifier=undefined so this hook doesn't need be called
 * conditionally in cases when there may be no identifier. Returns
 * NONPROFIT_NOT_FOUND in this case
 *
 */
export function useNonprofit(
  identifier?: NonprofitIdentifier
): UseNonprofitResponse {
  const nonprofitsState = useContext(NonprofitsContext);

  const [nonprofit, setNonprofit] = useState<UseNonprofitResponse | undefined>(
    () => getNonprofitOrStatus(nonprofitsState, identifier)
  );

  useEffect(() => {
    const nonprofit = getNonprofitOrStatus(nonprofitsState, identifier);
    if (identifier && !nonprofit) {
      // We don't have this nonprofit locally, kick off an async fetch
      fetchNonprofit(identifier);
    }
    setNonprofit(nonprofit);
  }, [identifier, nonprofitsState]);

  return nonprofit || NonprofitFetchStatus.FETCHING_NONPROFIT;
}

export function useParentNonprofit(nonprofit: ContextNonprofit) {
  const parentNonprofit = nonprofitOrUndefined(
    useNonprofit(
      nonprofit.eligibleDonationRecipientNonprofitIds?.length === 1
        ? { id: nonprofit.eligibleDonationRecipientNonprofitIds[0] }
        : undefined
    )
  );
  return parentNonprofit;
}
