import DonateV3 from "@components/donate/DonateV3";
import {
  DonateV3PageProps,
  DonateV3Layout,
} from "@components/donate/DonateV3/types";
import { Head } from "@components/layout/DefaultPageLayout";
import React from "react";

import { StyledPageSection } from "src/pages/DonateV3/styles";
import { verticalStackCss } from "src/theme/spacing";

const DonateV3Page = (props: DonateV3PageProps) => {
  const pageTitle = "Donate to " + props.nonprofit.name;

  return (
    <React.Fragment>
      <Head pageTitle={pageTitle} omitPageTitleSuffix />
      <div css={[verticalStackCss.xxxl, { height: "100vh" }]}>
        <StyledPageSection>
          <DonateV3 {...props} layout={DonateV3Layout.PAGE} />
        </StyledPageSection>
      </div>
    </React.Fragment>
  );
};

export default DonateV3Page;
