import { Card } from "@components/Card";
import { CausesSelector } from "@components/SearchResults/CausesSelector";
import { css } from "@emotion/react";
import { useContext } from "react";

import { DirectoryFilterSearchInput } from "src/pages/Directory/DirectoryFilterSearchInput";
import { DirectoryFilterContext } from "src/pages/Directory/context";
import { DirectoryFilterLargeContainer } from "src/pages/Directory/styles";
import { verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export function DirectoryFilter({ counts }: { counts: Map<string, number> }) {
  const { causes, onCausesChange } = useContext(DirectoryFilterContext);

  return (
    <DirectoryFilterLargeContainer>
      <Card>
        <div css={verticalStackCss.l}>
          <DirectoryFilterSearchInput
            labelText="Search"
            css={css`
              label {
                ${textSizeCss.s}
              }
            `}
          />
          <CausesSelector
            causes={causes}
            onCausesChange={onCausesChange}
            counts={counts}
            showSuggestedCuases={false}
          />
        </div>
      </Card>
    </DirectoryFilterLargeContainer>
  );
}
