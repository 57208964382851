import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { UUID } from "io-ts-types";

import {
  ABTestingId,
  PersonalUserResponse,
  GuestUserResponse,
} from "@every.org/common/src/codecs/entities";
import { GivelistRedirectTest } from "@every.org/common/src/helpers/abtesting/GivelistRedirect";
import { archiveDonationRouteSpec } from "@every.org/common/src/routes/donate";
import { createGuestUserRouteSpec } from "@every.org/common/src/routes/me";

import { updateAuthState } from "src/context/AuthContext/actions";
import { useIsLoggedIn } from "src/context/AuthContext/hooks";
import { resetTurnstile } from "src/context/TurnstileContext/useTurnstile";
import { EdoRouter } from "src/hooks/useEdoRouter";
import { useABTestTrack } from "src/utility/abtesting";
import { queryApi } from "src/utility/apiClient";
import { getUTMValuesFromCookie } from "src/utility/cookies";
import { logger } from "src/utility/logger";

function isGivelistRedirectUrl(successUrl: string | URL): boolean {
  try {
    const url =
      typeof successUrl === "string" ? new URL(successUrl) : successUrl;
    if (
      url.hostname === "giveli.st" ||
      // testing urls are p22's givelist vercel links; this is hacky but works
      (url.hostname.endsWith(".vercel.app") &&
        url.hostname.includes("givelist"))
    ) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

interface UseGivelistRedirectAbTestParams {
  successUrl: string | undefined;
}
// NOTE: this is actually going to include more people than actually should
// participate in the AB test, because the redirect behavior will only be
// experienced by people who actually donate; rearchitecting the donate flow to
// make this test accurate would have entailed a significant amount of changes,
// so I decided to just allow the test to have extra people in it -@osdiab
export function useGivelistRedirectAbTest({
  successUrl,
}: UseGivelistRedirectAbTestParams) {
  const isLoggedIn = useIsLoggedIn();
  const abTestValue = useABTestTrack(
    GivelistRedirectTest,
    !successUrl || isLoggedIn || !isGivelistRedirectUrl(successUrl)
  );
  return abTestValue;
}

export async function createGuestUserIfNeeded({
  router,
  loggedInOrGuestUser,
  firstName,
  lastName,
  email,
  abTestingId,
  cfTurnstileToken,
}: {
  router: EdoRouter;
  loggedInOrGuestUser?: PersonalUserResponse | GuestUserResponse;
  firstName?: string;
  lastName?: string;
  email?: string;
  abTestingId?: ABTestingId | undefined;
  cfTurnstileToken?: string | undefined;
}): Promise<boolean> {
  if (
    loggedInOrGuestUser &&
    // TODO(#12493) this is an issue b/c if the user changes any of their details, any
    // existing attached payment methods will now be attached to a different
    // user and cause an error when trying to donate
    loggedInOrGuestUser.firstName === firstName &&
    loggedInOrGuestUser.lastName === lastName &&
    loggedInOrGuestUser.email === email
  ) {
    return true;
  }

  try {
    const guestUserResult = await queryApi(createGuestUserRouteSpec, {
      body: {
        abTestingId,
        firstName,
        lastName,
        email,
        metadata: getUTMValuesFromCookie(),
        cfTurnstileToken,
      },
      routeTokens: {},
      queryParams: {},
    });
    resetTurnstile("turnstile");
    updateAuthState(guestUserResult.user);
    return true;
  } catch (e) {
    // do something
    logger.error({ message: "Error creating guest user", error: e });
    return false;
  }
}

export async function archiveDonation(
  donationId: UUID | undefined,
  formContext: DonateFormContext
) {
  await formContext.refreshNonce();
  formContext.setCreateOrUpdateDonationResult(undefined);
  if (donationId) {
    await queryApi(archiveDonationRouteSpec, {
      body: { donationId, cancelAndExpireNewCharge: true },
      routeTokens: {},
      queryParams: {},
    });
  }
}
