import {
  PersonalUserResponse,
  NonprofitAdminResponse,
} from "@every.org/common/src/codecs/entities";
import {
  AdminLevel,
  atLeastAsPrivilegedAs,
  NonprofitAdminStatus,
} from "@every.org/common/src/entity/types";
import { FundraiserResponse } from "@every.org/common/src/routes/fundraiser";

export function isFundraiserAdmin(
  fundraiser: FundraiserResponse["fundraiser"],
  loggedInUser: PersonalUserResponse | undefined,
  nonprofitAdmin: NonprofitAdminResponse | undefined
): boolean {
  const isSuperAdmin = atLeastAsPrivilegedAs({
    level: loggedInUser?.adminLevelForDisplay,
    compareAgainst: AdminLevel.SUPER_ADMIN,
  });

  return !!(
    isSuperAdmin ||
    (fundraiser.creatorNonprofitId &&
      fundraiser.creatorNonprofitId === nonprofitAdmin?.nonprofit?.id &&
      nonprofitAdmin?.status === NonprofitAdminStatus.CONFIRMED)
  );
}

export function canEditOrEndFundraiser(
  fundraiser: FundraiserResponse["fundraiser"],
  loggedInUser: PersonalUserResponse | undefined,
  nonprofitAdmin: NonprofitAdminResponse | undefined
): boolean {
  const isCreator =
    loggedInUser && loggedInUser.id === fundraiser.creatorUserId;

  return !!(
    isCreator || isFundraiserAdmin(fundraiser, loggedInUser, nonprofitAdmin)
  );
}
