import { DEFAULT_OPENGRAPH_CLOUDINARY_ID } from "@every.org/common/src/entity/types";
import {
  ConstructCloudinaryUrlParams,
  Dimensions,
} from "@every.org/common/src/helpers/cloudinary";

export const OPENGRAPH_DIMENSIONS: Dimensions = { width: 1200, height: 630 };
export const INSTAGRAM_POST_DIMENSIONS: Dimensions = {
  width: 1080,
  height: 1080,
};

/**
 * Opengraph image to serve as background of profile overlay
 *
 * Different than the default one since positioned to make avatar not overlap
 * with cause illustrations.
 */
const OPENGRAPH_PROFILE_CLOUDINARY_ID = "og-profile_w3bzgj";

/**
 * Creates Cloudinary transform that overlays a profile image on a predefined
 * opengraph background image uploaded to Cloudinary, with Cloudinary transforms
 *
 * All loose numbers in this function are specific dimensions encoded in the
 * original image uploaded to cloudinary
 *
 * @param xOffset optional horizontal offset from center in pixels
 *
 * For details on how cloudinary overlays work:
 * @see {@link https://cloudinary.com/documentation/image_transformations#adding_image_overlays}
 * @see {@link https://cloudinary.com/documentation/image_transformation_reference}
 */
function overlayAvatarOnDefaultOpengraphTransform(params: {
  avatarCloudinaryId: string;
  xOffset?: number;
}) {
  return [
    `l_${params.avatarCloudinaryId.replace("/", ":")}`, // overlay this image
    "r_max", // round border radius
    "bo_2px_solid_rgb:ffffff", // white border around image
    "c_scale", // scale image according to following
    `h_${Math.floor(OPENGRAPH_DIMENSIONS.height * 0.44)}`, // "avatar should be 44% of background image height"
    `y_${Math.floor(OPENGRAPH_DIMENSIONS.height * 0.15)}`, // "avatar should be 15% the background image's height below its vertical center"
    params.xOffset ? `x_${Math.floor(params.xOffset)}` : undefined,
  ]
    .filter((x) => !!x)
    .join(",");
}

/**
 * Overlay an avatar (or optionally two) on the default share background
 */
export function overlayAvatarOnDefaultOpengraphCloudinaryParams(params: {
  avatarCloudinaryId: string;
  secondAvatarCloudinaryId?: string;
}): Omit<ConstructCloudinaryUrlParams, "dimensions"> {
  const { avatarCloudinaryId, secondAvatarCloudinaryId } = params;
  return {
    cloudinaryId: OPENGRAPH_PROFILE_CLOUDINARY_ID,
    transforms: secondAvatarCloudinaryId
      ? [
          overlayAvatarOnDefaultOpengraphTransform({
            avatarCloudinaryId,
            xOffset: -OPENGRAPH_DIMENSIONS.height * 0.44 * 0.3,
          }),
          overlayAvatarOnDefaultOpengraphTransform({
            avatarCloudinaryId: secondAvatarCloudinaryId,
            xOffset: OPENGRAPH_DIMENSIONS.height * 0.44 * 0.3,
          }),
        ]
      : [overlayAvatarOnDefaultOpengraphTransform({ avatarCloudinaryId })],
  };
}

/**
 * Params to construct the default share image; doesn't contain dimensions since
 * that must be provided for your use case.
 */
export const DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS = {
  cloudinaryId: DEFAULT_OPENGRAPH_CLOUDINARY_ID,
} as const;
