import { MarkdownText } from "@components/MarkdownText";
import {
  descriptionTextCss,
  TitleConatainer,
} from "@components/NewFundraiserCard/styles";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";

export const TitleSection = ({
  fundraiser,
  className,
}: {
  fundraiser: FundraiserResponse;
  className?: string;
}) => (
  <TitleConatainer className={className}>
    <h3>{fundraiser.title}</h3>
    {fundraiser.description && (
      <div css={descriptionTextCss}>
        <MarkdownText hideMarkdown content={fundraiser.description} />
      </div>
    )}
  </TitleConatainer>
);
