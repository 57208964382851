import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { Modal } from "@components/Modal";
import { Popover } from "@components/Popover";
import { PopoverItemButtonProps, PopoverList } from "@components/PopoverItem";
import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";

import {
  PersonalDonationChargeResponse,
  DonationChargeResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";
import { DonationFrequency } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { archiveDonationRouteSpec } from "@every.org/common/src/routes/donate";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { verticalStackCss } from "src/theme/spacing";
import { queryApi } from "src/utility/apiClient";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  ${verticalStackCss.xs};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface ArchiveModalContentProps {
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
  onCancel: () => void;
  onDonationArchived: (
    donationCharge: DonationChargeResponse | PersonalDonationChargeResponse
  ) => void;
}

const ArchiveModalContent: React.FCC<ArchiveModalContentProps> = ({
  donationCharge,
  onCancel,
  onDonationArchived,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const archiveDonation = async () => {
    try {
      setIsSubmitting(true);
      await queryApi(archiveDonationRouteSpec, {
        body: { donationId: donationCharge.donation.id },
        routeTokens: {},
        queryParams: {},
      });
      setIsSubmitting(false);
      onDonationArchived(donationCharge);
    } catch (e) {
      setError("Could not archive donation. Please try again later.");
      setIsSubmitting(false);
    }
  };

  return (
    <ModalContainer>
      <p>
        {
          "Are you sure you want to hide this donation from your profile? This can't be undone."
        }
      </p>
      {donationCharge.donation.frequency !== DonationFrequency.ONCE && (
        <p>
          It will hide all donations of this recurring donation, including
          future ones.
        </p>
      )}
      <ErrorMessage text={error} />
      <ButtonContainer>
        <Button
          data-tname="archiveDonationCancel"
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              onCancel();
            },
          }}
        >
          Cancel
        </Button>
        <Button
          data-tname="archiveDonationConfirm"
          role={ButtonRole.PRIMARY}
          size={ButtonSize.SMALL}
          disabled={isSubmitting}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: archiveDonation,
          }}
        >
          Confirm
        </Button>
      </ButtonContainer>
    </ModalContainer>
  );
};

export const DonationMoreButton: React.FCC<{
  nonprofit: NonprofitResponse;
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
  onDonationArchived: (
    donationCharge: DonationChargeResponse | PersonalDonationChargeResponse
  ) => void;
}> = ({ donationCharge, onDonationArchived, nonprofit }) => {
  const [archiveDonation, setArchiveDonation] = useState(false);
  const username = useLoggedInUserOrUndefined()?.username;
  const [showPopover, setShowPopover] = useState(false);

  const listItems = useMemo<PopoverItemButtonProps[]>(
    () => [
      {
        "data-tname": "viewReceiptButton",
        iconImport: () => import("@components/Icon/icons/ReceiptIcon"),
        label: "View receipt",
        onClick: {
          kind: ButtonTargetKind.LINK,
          to: getRoutePath({
            name: ClientRouteName.MY_GIVING_HISTORY,
            format: URLFormat.RELATIVE,
          }),
        },
      },
      {
        "data-tname": "viewCampaignButton",
        iconImport: () => import("@components/Icon/icons/CampaignIcon"),
        label: "View campaign",
        onClick: {
          kind: ButtonTargetKind.LINK,
          to: getRoutePath({
            name: ClientRouteName.USER_DONATION,
            tokens: {
              username: username as string,
              nonprofitSlug: nonprofit.primarySlug,
            },
            format: URLFormat.RELATIVE,
          }),
        },
      },
      {
        "data-tname": "hideDonationButton",
        iconImport: () => import("@components/Icon/icons/EyeOffIcon"),
        label: "Hide this donation",
        onClick: {
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            setArchiveDonation(true);
          },
        },
      },
    ],
    [nonprofit.primarySlug, username]
  );

  const moreUiRef = useOnClickOutside<HTMLUListElement>(
    useMemo(
      () => ({
        handleClickOutside() {
          setShowPopover(false);
        },
      }),
      []
    )
  );

  if (!username) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={archiveDonation}
        contentLabel="Archive Donation Modal"
        showHeader
        headerText={"Archive donation?"}
        onRequestClose={() => {
          setArchiveDonation(false);
        }}
      >
        {
          <ArchiveModalContent
            onCancel={() => {
              setArchiveDonation(false);
            }}
            donationCharge={donationCharge}
            onDonationArchived={onDonationArchived}
          />
        }
      </Modal>
      <Popover
        visible={showPopover}
        placement={"bottom"}
        content={<PopoverList ref={moreUiRef} items={listItems} />}
      >
        <Button
          aria-label="Show more donation options"
          css={{ display: "block" }}
          data-tname="moreDonationButton"
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              setShowPopover(true);
            },
          }}
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/MoreIcon")}
            display={IconDisplay.SECONDARY}
            size={IconSize.MEDIUM}
          />
        </Button>
      </Popover>
    </React.Fragment>
  );
};
