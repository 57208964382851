import { useState, useCallback } from "react";

import {
  FundraiserRaisedResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";

import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import {
  FundraiserRaisedFetchStatus,
  fetchFundraiserRaised,
} from "src/pages/Fundraiser/fetchFundraiser";
import { logger } from "src/utility/logger";

type FundraiserRaisedResponseOrFetchStatus =
  | FundraiserRaisedResponse
  | FundraiserRaisedFetchStatus;

export const useFundraiserRaisedRisedData = (
  fundraiser: Pick<FundraiserResponse, "id" | "nonprofitId" | "raisedData">
) => {
  const [raisedData, setRaisedData] =
    useState<FundraiserRaisedResponseOrFetchStatus>(
      fundraiser.raisedData ??
        FundraiserRaisedFetchStatus.FETCHING_FUNDRAISER_RAISED
    );

  const asyncOperation = useCallback(
    () =>
      raisedData === FundraiserRaisedFetchStatus.FETCHING_FUNDRAISER_RAISED
        ? fetchFundraiserRaised(fundraiser.id, fundraiser.nonprofitId)
        : Promise.resolve(null),
    [fundraiser.id, fundraiser.nonprofitId, raisedData]
  );

  const handleResponse = useCallback(
    (raisedData: FundraiserRaisedResponseOrFetchStatus | null) =>
      raisedData && setRaisedData(raisedData),
    []
  );

  const handleError = useCallback((error: Error) => {
    logger.error({ message: "Error fetching raised data", error });
    setRaisedData(FundraiserRaisedFetchStatus.FUNDRAISER_RAISED_NOT_FOUND);
  }, []);

  useAsyncEffect({
    asyncOperation,
    handleResponse,
    handleError,
  });

  return raisedData;
};
