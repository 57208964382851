import {
  Button,
  ButtonProps,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { css } from "@emotion/react";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";
import {
  colorPalette,
  LIGHT_THEME,
  EXTERNAL_PALETTE,
} from "@every.org/common/src/display/palette";

import {
  socialAuth0Login,
  linkAuth0Login,
} from "src/context/AuthContext/actions";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import {
  BUTTON_BACKGROUND_COLOR_CSS_VAR,
  BUTTON_CONTENT_COLOR_CSS_VAR,
} from "src/styles/button";
import { colorCssVars, darkBgThemeNoBgCss } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";

const facebookButtonCss = [
  darkBgThemeNoBgCss,
  css`
    ${{
      [BUTTON_BACKGROUND_COLOR_CSS_VAR]: EXTERNAL_PALETTE.facebookBlueLight,
    }};
    border-color: ${EXTERNAL_PALETTE.facebookBlueLight};

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      ${{ [BUTTON_BACKGROUND_COLOR_CSS_VAR]: EXTERNAL_PALETTE.facebookBlue }};
      border-color: ${EXTERNAL_PALETTE.facebookBlue};
    }
  `,
];

const GOOGLE_LIGHT_BACKGROUND = "#FFFFFF";
const GOOGLE_HOVER_GRAY = "#EEEEEE";
const googleButtonCss = css`
  border: 2px solid var(${colorCssVars.googleButtonBorder.normal});
  ${{
    [BUTTON_CONTENT_COLOR_CSS_VAR]: colorPalette.googleBlue,
    [BUTTON_BACKGROUND_COLOR_CSS_VAR]: GOOGLE_LIGHT_BACKGROUND,
  }};
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border: 2px solid var(${colorCssVars.googleButtonBorder.hover});
    ${{
      [BUTTON_CONTENT_COLOR_CSS_VAR]: LIGHT_THEME.text.body,
      [BUTTON_BACKGROUND_COLOR_CSS_VAR]: GOOGLE_HOVER_GRAY,
    }};
  }
`;

/**
 * Readable name for each identity provider to use in user-facing text.
 */
export const IDP_READABLE_NAME = {
  [IdentityProvider.FACEBOOK]: "Facebook",
  [IdentityProvider.GOOGLE]: "Google",
  [IdentityProvider.USERNAME_PASSWORD]: "email/password",
};

const IDP_ICONS = {
  [IdentityProvider.FACEBOOK]: () => import("./Icon/icons/FacebookIcon"),
  [IdentityProvider.GOOGLE]: () => import("./Icon/icons/GoogleIcon"),
  [IdentityProvider.USERNAME_PASSWORD]: () => import("./Icon/icons/MailIcon"),
};

const IDP_BUTTON_CSS = {
  [IdentityProvider.FACEBOOK]: facebookButtonCss,
  [IdentityProvider.GOOGLE]: googleButtonCss,
};

/**
 * Props any social IdP login button supports
 *
 * Omits `role` and `onClick` because appearance and behavior are specific to
 * login behaviors
 */
interface SocialButtonProps extends Omit<ButtonProps, "role" | "onClick"> {
  redirectUrl?: string;
  idp: IdentityProvider.FACEBOOK | IdentityProvider.GOOGLE;
  condensedSignUpFlow?: boolean;
  skipWelcomeModal?: boolean;
  loginAfterDonationFlow?: boolean;
}

export const SocialIdpButton: React.FCC<
  { idp: IdentityProvider } & Omit<ButtonProps, "role">
> = ({ idp, contentCss, children, ...rest }) => {
  return (
    <Button
      css={IDP_BUTTON_CSS[idp]}
      role={ButtonRole.PRIMARY}
      contentCss={[
        horizontalStackCss.s,
        css`
          display: flex;
          align-items: center;
          margin: 0 auto;
          flex-grow: 0;
        `,
        ...(Array.isArray(contentCss) ? contentCss : [contentCss]),
      ]}
      {...rest}
    >
      {children}
    </Button>
  );
};

/**
 * Button styled with an Identity Provider (IdP)'s styles, that logs into an
 * existing account with the IdP or creates a new one if not connected before
 *
 * For linking existing accounts, use `SocialLinkAccountButton` instead
 */
export const SocialLoginButton: React.FCC<SocialButtonProps> = ({
  redirectUrl,
  idp,
  condensedSignUpFlow,
  children,
  skipWelcomeModal,
  loginAfterDonationFlow,
  ...rest
}) => {
  const router = useEdoRouter();
  const searchParams = new URLSearchParams(router.search);
  return (
    <SocialIdpButton
      idp={idp}
      {...rest}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: async () => {
          await socialAuth0Login({
            redirectUrl: searchParams.get("redirectUrl") || redirectUrl,
            idp,
            guestToken: searchParams.get("guestToken") || undefined,
            condensedSignUpFlow,
            skipWelcomeModal,
            loginAfterDonationFlow: !!loginAfterDonationFlow,
          });
        },
      }}
    >
      <Icon
        iconImport={IDP_ICONS[idp]}
        size={IconSize.MEDIUM}
        display={IconDisplay.TEXT}
      />
      <span>{children || `Continue with ${IDP_READABLE_NAME[idp]}`}</span>
    </SocialIdpButton>
  );
};

/**
 * Button styled with an Identity Provider (IdP)'s styles, that links your
 * existing account with that IdP.
 *
 * For logging into an existing account or creating a new account with an IdP,
 * use `SocialLoginButton` instead
 */
export const SocialLinkAccountButton: React.FCC<SocialButtonProps> = (
  props
) => {
  const { idp } = props;
  return (
    <SocialIdpButton
      {...props}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: async () => {
          await linkAuth0Login({
            idp,
            redirectUrl: props.redirectUrl,
          });
        },
      }}
    >
      <Icon
        iconImport={IDP_ICONS[idp]}
        size={IconSize.MEDIUM}
        display={IconDisplay.TEXT}
      />
      <span>Link {IDP_READABLE_NAME[idp]}</span>
    </SocialIdpButton>
  );
};
