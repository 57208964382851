import { EntityName, TagResponse } from "@every.org/common/src/codecs/entities";

import {
  TagsState,
  TagFetchStatus,
  TagOrFetchStatus,
  TrendingTagsFetchStatus,
  TagIdentifier,
  InternalTagsState,
} from "src/context/TagContext/types";
import { logger } from "src/utility/logger";

/**
 * Get a tag that has been previously fetched.
 *
 * @returns the tag if it is found locally, a `TagFetchStatus` if
 * not yet found, or `undefined` if the tag has not been fetched yet.
 *
 */
export function getTag(
  state: InternalTagsState,
  identifier: TagIdentifier
): TagResponse | Exclude<TagFetchStatus, TagFetchStatus.FOUND> | undefined {
  const fetchStatus =
    "id" in identifier
      ? state.tagFetchStatus.get(identifier.id)
      : state.tagFetchStatus.get(identifier.tagName);
  if (!fetchStatus) {
    return undefined;
  }
  if (fetchStatus !== TagFetchStatus.FOUND) {
    return fetchStatus;
  }

  const tag =
    "id" in identifier
      ? state.tagsById.get(identifier.id)
      : state.tagsByTagName.get(identifier.tagName);
  if (!tag) {
    logger.warn({
      message: "Tag had been fetched before, but tag data is missing",
      data: { identifier },
    });
    return undefined;
  }
  return tag;
}

/**
 * Returns `undefined` if a tag is in the fetching or not_found state.
 * Useful for when you only care about the tag if we actually have the
 * data, not if it's in an intermediate state.
 */
export function tagOrUndefined(tag: TagOrFetchStatus | undefined) {
  if (tag && typeof tag !== "string" && tag.entityName === EntityName.TAG) {
    return tag;
  }
  return undefined;
}

/**
 * Gets trending tags that have been previously fetched.
 *
 * @returns the tags if it is found locally, a `TrendingTagsFetchStatus` if
 * not yet found, or `undefined` if they have not been fetched yet.
 *
 */
export function getTrendingTags(
  state: TagsState
):
  | { trendingTags: TagResponse[]; hasMore: boolean }
  | Exclude<TrendingTagsFetchStatus, TrendingTagsFetchStatus.FOUND>
  | undefined {
  const fetchStatus = state.trendingTagsFetchStatus;
  if (!fetchStatus) {
    return undefined;
  }
  if (fetchStatus !== TrendingTagsFetchStatus.FOUND) {
    return fetchStatus;
  }
  const trendingTags = state.trendingTags;
  const hasMore = state.trendingTagsHasMore;
  if (!trendingTags) {
    logger.warn({
      message: "Trending tags had been fetched before, but data is missing",
    });
    return undefined;
  }
  return { trendingTags, hasMore };
}
