import React, { useEffect, useRef } from "react";

import { getWindow } from "src/utility/window";

const window = getWindow();

export const ObservableIntersection: React.FCC<{
  onChange: (state: boolean) => void;
}> = ({ onChange }) => {
  const observableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!window?.IntersectionObserver) {
      return;
    }
    const observer = new IntersectionObserver((observerEntry) => {
      if (observerEntry[0]) {
        onChange(!observerEntry[0].isIntersecting);
      }
    });

    if (observableRef && observableRef.current) {
      observer.observe(observableRef.current);
    }
    return () => {
      observer.disconnect();
    };
  });
  return <div ref={observableRef} />;
};
