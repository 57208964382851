import {
  Icon,
  IconDisplay,
  IconSize,
  heightForIconSize,
} from "@components/Icon";
import { Popover } from "@components/Popover";
import { css } from "@emotion/react";
import React from "react";

import { VerifiedStatus } from "@every.org/common/src/entity/types";

import { spacing, verticalStackCss } from "src/theme/spacing";

interface VerifiedStatusIconProps {
  verifiedStatus?: VerifiedStatus;
  size: IconSize;
}

const POPOVER_WIDTH = "235px";

// Component to render an icon describing user's verified status
// A verified status of PUBLIC_INFO also renders a popup defining it
export const VerifiedStatusIcon: React.FCC<VerifiedStatusIconProps> = ({
  verifiedStatus = VerifiedStatus.NOT_VERIFIED,
  size,
}) => {
  if (verifiedStatus && verifiedStatus !== VerifiedStatus.NOT_VERIFIED) {
    const [icon, message] =
      verifiedStatus === VerifiedStatus.VERIFIED
        ? [
            () => import("./Icon/icons/VerifiedCheckIcon"),
            "This profile is verified and belongs to this person.",
          ]
        : [
            () => import("./Icon/icons/NewspaperIcon"),
            "This profile is created based on publicly available information.",
          ];

    const publicInfoPopoverContent = (
      <div
        css={[
          verticalStackCss.xxs,
          css`
            align-items: center;
            max-width: ${POPOVER_WIDTH};
          `,
        ]}
      >
        <Icon
          iconImport={icon}
          display={IconDisplay.ACCENT}
          size={IconSize.MEDIUM}
        />
        <span>{message}</span>
      </div>
    );
    return (
      <Popover
        placement={"bottom"}
        content={publicInfoPopoverContent}
        css={css`
          display: inline-block;
          padding-bottom: ${spacing.xxxs};
        `}
      >
        <Icon
          iconImport={icon}
          display={IconDisplay.ACCENT}
          size={size}
          css={css`
            min-width: ${heightForIconSize[IconSize.X_SMALL]}px;
          `}
        />
      </Popover>
    );
  }

  return null;
};
