import { Card } from "@components/Card";
import { DonateV3Layout } from "@components/donate/DonateV3/types";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorCssVars, lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";

const rowGapCss = css`
  & > div:not(:last-child):not(:empty) {
    margin-bottom: ${spacing.l};
  }
`;

export const largeGridCss = css`
  grid-column-gap: ${spacing.l};
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: auto auto auto auto auto 1fr;
  grid-template-areas:
    "header header header"
    "promoBanners promoBanners promoBanners"
    "nonprofitInfo paymentProcess faq"
    "fundraiserLink paymentProcess faq"
    "giftCard paymentProcess faq"
    "giftCard footer faq";

  ${rowGapCss};
`;

export const mediumGridCss = css`
  grid-column-gap: ${spacing.l};
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: auto auto auto auto auto auto 1fr;
  grid-template-areas:
    "header header"
    "promoBanners promoBanners"
    "nonprofitInfo paymentProcess"
    "fundraiserLink paymentProcess"
    "giftCard paymentProcess"
    "faq paymentProcess"
    "faq footer";

  ${rowGapCss};
`;

const CssForLayout = {
  [DonateV3Layout.PAGE]: [
    cssForMediaSize({
      min: MediaSize.LARGE,
      css: mediumGridCss,
    }),
    cssForMediaSize({
      min: MediaSize.X_LARGE,
      css: `
        grid-template-columns: 1fr 2fr;
      `,
    }),
    cssForMediaSize({
      min: MediaSize.XX_LARGE,
      css: largeGridCss,
    }),
  ],
  [DonateV3Layout.MODAL]: [
    cssForMediaSize({
      min: MediaSize.LARGE,
      css: mediumGridCss,
    }),
  ],
};

export const Grid = styled.div<{ layout: DonateV3Layout }>`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;

  grid-template-areas:
    "header"
    "promoBanners"
    "nonprofitInfo"
    "paymentProcess"
    "faq"
    "giftCard"
    "footer";

  ${({ layout }) => CssForLayout[layout]}
`;

export const StyledPageSection = styled(PageSection)`
  padding-bottom: ${spacing.xxl};
  background: var(${colorCssVars.background.normal});
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding-bottom: ${spacing.xxxxl};
      background: var(${colorCssVars.background.faded});
    `,
  })}

  > div {
    padding-right: 0;
    padding-left: 0;
    ${lightBgThemeCss};
    ${cssForMediaSize({
      min: MediaSize.LARGE,
      css: css`
        background: unset;
        padding-right: ${spacing.xl};
        padding-left: ${spacing.xl};
      `,
    })}
  }
`;

export const DonateV3GridCard = styled(Card)`
  padding: ${spacing.l};
  border-radius: 0;
  background: unset;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      border-radius: 16px;
      ${lightBgThemeCss};
    `,
  })}
`;
