import { type, TypeOf, string, unknown } from "io-ts";

export const apiResponseCodec = type({ message: string, data: unknown });
export type ApiResponseShape = TypeOf<typeof apiResponseCodec>;

export interface ApiRequestOptions {
  /**
   * Disable rendering a toast indicating that you need to log in
   *
   * @default false
   */
  disableUnauthenticatedToast?: boolean;
  signal?: AbortSignal;
}

export enum ApiStatus {
  FETCHING,
  SUCCESS,
  ERROR,
}
