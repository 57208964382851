import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import {
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
  Button,
} from "@components/Button";
import { Card } from "@components/Card";
import { Loading } from "@components/LoadingIndicator";
import { ModalButton } from "@components/ModalButton";
import { NonprofitLink } from "@components/NonprofitLink";
import { FeedNonprofitCard } from "@components/feed/FeedNonprofitCard";
import { LikeButton } from "@components/feed/LikeButton";
import { getFeedItemId, maxWidthFeedCardCss } from "@components/feed/shared";
import { DonateButton } from "@components/layout/DonateButton";
import { SkeletonNonprofitRecommendationCard } from "@components/skeleton/SkeletonNonprofitRecommendationsCard";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types/UUID";
import React, { useState, useCallback, useContext } from "react";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import {
  FeedPage,
  FeedItemType,
  LikeableType,
} from "@every.org/common/src/entity/types";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import { NonprofitNeighboursFeedResponse } from "@every.org/common/src/routes/nonprofit";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import { fetchNonprofitNeighbours } from "src/context/NonprofitsContext/actions";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { ApiError } from "src/errors/ApiError";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { LinkAppearance } from "src/styles/link";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { textSizeCss, TextCssVar } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";

const takeForCard = 3;
const takeForModal = 10;

export enum RecommendationsCardType {
  SUPPORTED = "SUPPORTED",
  LIKED = "LIKED",
}

interface FeedNonprofitRecommendationsCardProps {
  headerText: string;
  nonprofit: ContextNonprofit;
  type: RecommendationsCardType;
  feedId?: UUID;
}

const StyledContainer = styled.div`
  flex-grow: 1;
  ${horizontalStackCss.m};
  align-items: center;
  padding: ${spacing.m} 0;
`;

const StyledListItem = styled.li`
  position: relative;
  ${horizontalStackCss.s};
  align-items: center;
  &:not(:last-of-type) {
    ${StyledContainer} {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        bottom: -1px;
        border-bottom: 1px solid var(${colorCssVars.dividerSoft});
      }
    }
  }
`;

export const FeedNonprofitRecommendationsCard: React.FCC<
  FeedNonprofitRecommendationsCardProps
> = ({ nonprofit, headerText, type, feedId }) => {
  const [recommendations, setRecommendations] = useState<
    NonprofitNeighboursFeedResponse["items"] | undefined
  >();
  const [hasMore, setHasMore] = useState(false);
  const asyncOperation = useCallback(() => {
    return fetchNonprofitNeighbours(
      nonprofit.id,
      takeForCard as TakeInt,
      0 as SkipInt
    );
  }, [nonprofit.id]);

  const handleResponse = useCallback(
    ({ items, hasMore }: NonprofitNeighboursFeedResponse) => {
      setRecommendations(items);
      setHasMore(hasMore);
    },
    []
  );

  const handleError = useCallback(
    (error: Error) => {
      logger.warn({
        data: {
          nonprofitId: nonprofit.id,
        },
        message: "Error while fetching nonprofit neighbours",
        error,
      });
    },
    [nonprofit.id]
  );

  useAsyncEffect({
    asyncOperation,
    handleResponse,
    handleError,
  });

  if (!recommendations) {
    return <SkeletonNonprofitRecommendationCard />;
  }

  if (recommendations && !recommendations.length) {
    return (
      <FeedNonprofitCard
        feedId={feedId}
        headerText={headerText}
        nonprofitId={nonprofit.id}
      />
    );
  }

  const title = (
    <span
      css={css`
        ${textSizeCss.xs};
        color: var(${colorCssVars.text.secondary});
        display: block;
      `}
    >
      Because you{" "}
      {type === RecommendationsCardType.LIKED ? "liked" : "supported"}{" "}
      <NonprofitLink
        appearance={LinkAppearance.HYPERLINK_UNCOLORED}
        data-tname={`recommendationNonprofit--link`}
        data-action={ClickAction.NONPROFIT}
        nonprofit={nonprofit}
      />
    </span>
  );
  return (
    <Card
      shadowOnHover
      css={[maxWidthFeedCardCss, verticalStackCss.xxs]}
      data-feed-feedid={feedId}
      data-feed-itemtype={FeedItemType.NONPROFIT_RECOMMENDATION}
      data-feed-itemid={nonprofit.id}
    >
      <div css={verticalStackCss.xs}>
        {title}
        <NonprofitRecommendationsList
          data-action={ClickAction.RECOMMENDED_NONPROFIT}
          recommendations={recommendations}
        />
      </div>
      {hasMore && (
        // Wrapped to avoid stretching to card width
        <span>
          <RecommendationViewMoreButton
            modalHeader={title}
            nonprofit={nonprofit}
          />
        </span>
      )}
    </Card>
  );
};

const NonprofitRecommendationsList: React.FCC<{
  recommendations: FeedItemResponse[];
  listItemCss?: CSSInterpolation;
}> = ({ recommendations, listItemCss }) => {
  return (
    <ul>
      {recommendations.map((item) => (
        <NonprofitRecommendationsListItem
          data-action={ClickAction.RECOMMENDED_NONPROFIT}
          item={item}
          listItemCss={listItemCss}
          key={getFeedItemId(item)}
        />
      ))}
    </ul>
  );
};

const NonprofitRecommendationsListItem: React.FCC<{
  item: FeedItemResponse;
  listItemCss?: CSSInterpolation;
}> = ({ item, listItemCss }) => {
  const nonprofitState = useContext(NonprofitsContext);

  if (item.type !== FeedItemType.NONPROFIT_RECOMMENDATION) {
    return null;
  }

  const nonprofit = nonprofitOrUndefined(
    getNonprofit(nonprofitState, { id: item.nonprofitId })
  );

  if (!nonprofit) {
    return null;
  }

  return (
    <StyledListItem
      data-feed-feedid={item.feedId}
      data-feed-itemid={getFeedItemId(item)}
      data-feed-itemtype={FeedItemType.NONPROFIT_RECOMMENDATION}
    >
      <NonprofitAvatar
        data-action={ClickAction.NONPROFIT}
        nonprofit={nonprofit}
        size={AvatarSize.SMALL}
      />
      <StyledContainer>
        <NonprofitLink
          css={[
            css`
              ${TextCssVar.LINE_HEIGHT}: 1.2rem;
              line-height: var(${TextCssVar.LINE_HEIGHT});
            `,
            truncatedTextCss({ numLines: 2 }),
          ]}
          appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          data-tname={`recommendationNonprofit--link`}
          data-action={ClickAction.NONPROFIT}
          nonprofit={nonprofit}
        />
        <div css={[horizontalStackCss.xxs, { marginLeft: "auto" }]}>
          <LikeButton
            id={nonprofit.id}
            loggedInUserLikes={
              nonprofit.likesInfo?.hasLoggedInUserLiked || null
            }
            type={LikeableType.NONPROFIT}
            likeCount={null}
            data-tname="recommendationNonprofit--likeButton"
            data-action={ClickAction.LIKE}
            role={ButtonRole.UNSTYLED}
          />
          <DonateButton
            data-tname="recommendationNonprofit--donateButton"
            data-action={ClickAction.DONATE}
            primarySlug={nonprofit.primarySlug}
            isDisbursable={nonprofit.isDisbursable}
            size={ButtonSize.SMALL}
            toNonprofitPage
          >
            Donate
          </DonateButton>
        </div>
      </StyledContainer>
    </StyledListItem>
  );
};

const RecommendationViewMoreButton: React.FCC<{
  modalHeader: React.ReactNode;
  nonprofit: ContextNonprofit;
}> = ({ modalHeader, nonprofit }) => (
  <ModalButton
    buttonProps={{
      "data-tname": "viewMoreRecommendations",
      "data-action": ClickAction.VIEW_MORE,
      buttonCss: textSizeCss.xs,
      role: ButtonRole.TEXT_ONLY,
    }}
    buttonContent={"View more"}
    modalHeader={modalHeader}
    modalContentLabel={"Recommendations list"}
    modalContent={<ModalRecommendationsList nonprofit={nonprofit} />}
  />
);

interface ModalRecommendationsListProps {
  nonprofit: ContextNonprofit;
}

const ModalRecommendationsList: React.FCC<ModalRecommendationsListProps> = ({
  nonprofit,
}) => {
  const loggedInUserId = useLoggedInUserOrUndefined()?.id;
  const [recommendations, setRecommendations] = useState<
    NonprofitNeighboursFeedResponse["items"]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const [fetching, setFetching] = useState(true);

  useAsyncEffect({
    asyncOperation: useCallback(() => {
      return fetchNonprofitNeighbours(
        nonprofit.id,
        takeForModal as TakeInt,
        (pageNum * takeForModal) as SkipInt
      );
    }, [nonprofit.id, pageNum]),
    handleResponse: useCallback(
      ({ items, hasMore }: NonprofitNeighboursFeedResponse) => {
        setRecommendations((prevRecommendations) => {
          const prevRecommendationsIds = new Set(
            prevRecommendations.map(getFeedItemId)
          );
          return [
            ...prevRecommendations,
            ...items.filter(
              (item) => !prevRecommendationsIds.has(getFeedItemId(item))
            ),
          ];
        });
        setHasMore(hasMore);
        setFetching(false);
      },
      []
    ),
    handleError: useCallback((error) => {
      setHasMore(false);
      if (
        !(error instanceof ApiError) ||
        error.httpStatus !== HttpStatus.FORBIDDEN
      ) {
        logger.error({
          message:
            "Error while fetching nonprofit neighbours in View more modal.",
          error,
        });
      }
      setFetching(false);
    }, []),
  });

  if (!recommendations.length && fetching) {
    return <Loading />;
  }

  return (
    <div
      data-feed-page={FeedPage.RELATED_NONPROFITS}
      data-feed-user={loggedInUserId}
      css={[
        verticalStackCss.m,
        css`
          max-width: 500px;
        `,
      ]}
    >
      <NonprofitRecommendationsList
        recommendations={recommendations}
        listItemCss={horizontalStackCss.m}
      />
      {fetching && <Loading />}
      {hasMore && !fetching && (
        <Button
          data-tname={"LoadMoreRecommendations--Button"}
          data-action={ClickAction.VIEW_MORE}
          data-feed-feedid={recommendations[0].feedId}
          role={ButtonRole.TEXT_ONLY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => setPageNum((pageNum) => pageNum + 1),
          }}
        >
          View more
        </Button>
      )}
    </div>
  );
};
