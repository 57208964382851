import { EntityName } from "@every.org/common/src/codecs/entities";
import { normalizeSlug } from "@every.org/common/src/helpers/slugs";

import {
  NonprofitsState,
  ContextNonprofit,
  NonprofitIdentifier,
  NonprofitFetchStatus,
  NonprofitOrFetchStatus,
  NonprofitSlug,
} from "src/context/NonprofitsContext/types";
import { logger } from "src/utility/logger";

/**
 * Convert a nonprofit identifier to a string value that can be used for
 * indexing into Maps (necessary since ES6 Map doesn't support custom
 * comparators)
 */
export function identifierToNormalizedString({
  identifier,
}: {
  identifier: NonprofitIdentifier;
}): string {
  if ("slug" in identifier) {
    return `slug--${normalizeSlug(identifier)}`;
  }
  return `id--${identifier.id}`;
}

/**
 * Gets the status of a nonprofit fetch on the provided `nonprofitFetchStatus`
 * map; encapsulates encoding of the identifier to ensure that
 * `nonprofitFetchStatus` is used consistently.
 */
function getNonprofitFetchStatus(params: {
  nonprofitFetchStatus: NonprofitsState["nonprofitFetchStatus"];
  identifier: NonprofitIdentifier;
}): NonprofitFetchStatus | undefined {
  const { nonprofitFetchStatus, identifier } = params;
  return nonprofitFetchStatus.get(identifierToNormalizedString({ identifier }));
}

/**
 * Gets the nonprofit ID of a slug from mapping in the state. Doesn't fetch
 * anything, just retrieves from the provided `slugsToIds` object to ensure
 * consistency in usage.
 */
function getNonprofitIdForSlug(params: {
  slugsToIds: NonprofitsState["slugsToIds"];
  slug: NonprofitSlug;
}): string | undefined {
  return params.slugsToIds.get(normalizeSlug(params.slug));
}

/**
 * Get a nonprofit that has been previously fetched.
 *
 * @returns the nonprofit if it is found locally, a `NonprofitFetchStatus` if
 * not yet found, or `undefined` if the nonprofit has not been fetched yet.
 *
 * May return `undefined` even if the corresponding nonprofit has been fetched,
 * if called with a nonprofit's slug alias that wasn't searched for before (this
 * is because the backend currently doesn't bundle slug aliases with
 * nonprofits, just the primary slug).
 */
export function getNonprofit(
  state: NonprofitsState,
  identifier: NonprofitIdentifier
):
  | ContextNonprofit
  | Exclude<NonprofitFetchStatus, NonprofitFetchStatus.FOUND>
  | undefined {
  const fetchStatus = getNonprofitFetchStatus({
    nonprofitFetchStatus: state.nonprofitFetchStatus,
    identifier,
  });
  if (!fetchStatus) {
    return undefined;
  }
  if (fetchStatus !== NonprofitFetchStatus.FOUND) {
    return fetchStatus;
  }

  const id =
    "id" in identifier
      ? identifier.id
      : getNonprofitIdForSlug({
          slugsToIds: state.slugsToIds,
          slug: identifier,
        });
  if (!id) {
    logger.warn({
      message:
        "Nonprofit had been fetched before by slug, but slug to ID mapping didn't have it",
      data: { identifier },
    });
    return undefined;
  }

  const nonprofit = state.nonprofitsById.get(id);
  if (!nonprofit) {
    logger.warn({
      message:
        "Nonprofit had been fetched before, but nonprofit data is missing",
      data: { identifier },
    });
    return undefined;
  }
  return nonprofit;
}

/**
 * Same as getNonprofit above, except the only possible return state are
 * undefined or the nonprofit, so there is no way to distinguish between
 * if a nonprofit is loading or is not found.
 */
export function getNonprofitOrUndefined(
  state: NonprofitsState,
  identifier: NonprofitIdentifier
): ContextNonprofit | undefined {
  return nonprofitOrUndefined(getNonprofit(state, identifier));
}

/**
 * Returns `undefined` if a nonprofit is in the fetching or not_found state.
 * Useful for when you only care about the nonprofit if we actually have the
 * data, not if it's in an intermediate state.
 *
 * It seems we always call this wrapped around a call to getNonprofit, instead
 * you can now use the getNonprofitOrUndefined call above.
 */
export function nonprofitOrUndefined(
  nonprofit: NonprofitOrFetchStatus | undefined
) {
  if (
    nonprofit &&
    typeof nonprofit !== "string" &&
    nonprofit.entityName === EntityName.NONPROFIT
  ) {
    return nonprofit;
  }
  return undefined;
}
