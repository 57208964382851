import { MIN_CARD_WIDTH_RAW } from "@components/DonationCard/types";
import { css } from "@emotion/react";
import { useEffect, useState, useMemo, memo } from "react";
import Masonry, { MasonryProps } from "react-masonry-css";

import { useComponentSize } from "src/hooks/useComponentSize";
import {
  cssForMediaSize,
  MediaSize,
  minWidthForMediaSize,
  useMediaSize,
} from "src/theme/mediaQueries";
import { rawSpacing } from "src/theme/spacing";

/**
 * Minimum size of a column in the masonry grid, as number of px
 *
 * The size was selected to be a reasonable minimum for donation cards in our
 * feed to look attractive.
 * TODO: if we want to reuse masonry for content that can be rendered at
 * different sizes, make this configurable
 */
const MIN_COLUMN_WIDTH_RAW = MIN_CARD_WIDTH_RAW;
export const MAX_COLS = 3;

const GutterSizeForMediaSize = {
  [MediaSize.X_SMALL]: rawSpacing.xs,
  [MediaSize.SMALL]: rawSpacing.xs,
  [MediaSize.MEDIUM_SMALL]: rawSpacing.xs,
  [MediaSize.MEDIUM]: rawSpacing.s,
  [MediaSize.MEDIUM_LARGE]: rawSpacing.l,
  [MediaSize.LARGE]: rawSpacing.l,
  [MediaSize.XX_LARGE]: rawSpacing.l,
};

/**
 * With a measured MasonryList's length and the current media size, determines
 * how many columns will be displayed in the Masonry list
 *
 * @see useComponentSize for how to measure the width of a MasonryList
 */
function columnsRenderedInMasonry({
  width,
  gutter,
}: {
  width: number;
  gutter: number;
}): number {
  return Math.max(
    Math.min(
      Math.floor((width + gutter) / (MIN_COLUMN_WIDTH_RAW + gutter)),
      MAX_COLS
    ),
    1
  );
}

const colClassName = "everydotorg--MasonryGridColumn"; // because react-masonry-css expects this

const masonryCss = [
  css`
    display: flex;

    .${colClassName} {
      &:not(:last-child) {
        margin-right: ${GutterSizeForMediaSize[MediaSize.X_SMALL]}px;
      }
      > :not(:last-child) {
        margin-bottom: ${GutterSizeForMediaSize[MediaSize.X_SMALL]}px;
      }
    }
  `,
  ...Object.values(MediaSize)
    .filter(
      (size): size is Exclude<MediaSize, MediaSize.X_SMALL> =>
        size !== MediaSize.X_SMALL
    )
    .map((mediaSize) =>
      cssForMediaSize({
        min: mediaSize,
        css: css`
          .${colClassName} {
            &:not(:last-child) {
              margin-right: ${GutterSizeForMediaSize[mediaSize]}px;
            }
            > :not(:last-child) {
              margin-bottom: ${GutterSizeForMediaSize[mediaSize]}px;
            }
          }
        `,
      })
    ),
];

export interface MasonryListItem {
  key: string;
  elem: React.ReactNode;
}

export interface MasonryListProps {
  className?: string;
  /**
   * List of items to render
   */
  items: MasonryListItem[];

  /**
   * Override number of columns to display
   */
  numColumns?: MasonryProps["breakpointCols"];
}

export function useCalculateMasonryColumns(): [
  number,
  (elem: HTMLElement | null) => void
] {
  // eslint-disable-next-line no-restricted-syntax
  const { ref, size } = useComponentSize();
  // eslint-disable-next-line no-restricted-syntax
  const mediaSize = useMediaSize() || MediaSize.LARGE;
  const [numCols, setNumCols] = useState(1);

  useEffect(() => {
    const newNumCols = size?.width
      ? columnsRenderedInMasonry({
          width: size.width,
          gutter: GutterSizeForMediaSize[mediaSize],
        })
      : 1;
    if (numCols !== newNumCols) {
      setNumCols(newNumCols);
    }
  }, [numCols, mediaSize, size]);

  return [numCols, ref];
}

const numCols = {
  // 0 to 800 will be one column
  [minWidthForMediaSize[MediaSize.LARGE]]: 1,
  // 800 to 1100 will be two columns
  [minWidthForMediaSize[MediaSize.XX_LARGE]]: 2,
  // more than 1100 will be three columns
  default: MAX_COLS,
};

/**
 * Renders a list of elements in a masonry layout
 *
 * Items will be snapped to a multiple of gutter size + row height;
 * @see GRID_ROW_HEIGHT for details
 */
export const MasonryList: React.FCC<MasonryListProps> = memo(
  function MasonryListImpl({
    items,
    numColumns: overrideNumColumns,
    className,
  }) {
    const masonryChildren = useMemo(
      () => items.map(({ key, elem }) => <div key={key}>{elem}</div>),
      [items]
    );

    return (
      <div
        css={css`
          width: 100%;
        `}
        className={className}
      >
        <Masonry
          css={masonryCss}
          className="" // passed in by emotion
          columnClassName={colClassName}
          breakpointCols={overrideNumColumns || numCols}
        >
          {masonryChildren}
        </Masonry>
      </div>
    );
  }
);
