import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";
import { withMessage } from "io-ts-types/withMessage";

import { DisbursementType, FocusArea } from "../entity/types";

import { currencyValueCodec } from "./currency";
import { dateFromStringCodec } from "./date";
import { safeIntCodec } from "./number";
import { isValidUrl } from "./urls";

import { stringEnumCodec } from ".";

/**
 * Limit nonprofit descriptions so that they fit in a feed card.
 */
export const DESCRIPTION_MAX_LENGTH = 130;

/**
 * Limit nonprofit long descriptions to some high limit.
 */
export const LONG_DESCRIPTION_MAX_LENGTH = 10000;

/**
 * Limit nonprofit messages to donors to not be too long
 */
export const DONATION_THANK_YOU_MESSAGE_MAX_LENGTH = 1000;

export interface DescriptionLongIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DescriptionLong: unique symbol;
}

export interface DescriptionIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly Description: unique symbol;
}

export interface DonationThankYouMessageIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DonationThankYouMessage: unique symbol;
}

export interface WebhookUrlIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly WebhookUrl: unique symbol;
}

export const descriptionLongCodec = withMessage(
  t.brand(
    t.string,
    (input: string): input is t.Branded<string, DescriptionLongIotsBrand> =>
      input.length <= LONG_DESCRIPTION_MAX_LENGTH,
    "DescriptionLong"
  ),
  () => `Max length is ${LONG_DESCRIPTION_MAX_LENGTH}`
);

export const descriptionCodec = withMessage(
  t.brand(
    t.string,
    (input: string): input is t.Branded<string, DescriptionIotsBrand> =>
      input.length <= DESCRIPTION_MAX_LENGTH,
    "Description"
  ),
  () => `Max length is ${DESCRIPTION_MAX_LENGTH}`
);

export const donationThankYouMessageCodec = withMessage(
  t.brand(
    t.string,
    (
      input: string
    ): input is t.Branded<string, DonationThankYouMessageIotsBrand> =>
      input.length <= DONATION_THANK_YOU_MESSAGE_MAX_LENGTH,
    "DonationThankYouMessage"
  ),
  () => `Max length is ${DONATION_THANK_YOU_MESSAGE_MAX_LENGTH}`
);

export const webhookUrlCodec = withMessage(
  t.brand(
    t.string,
    (input: string): input is t.Branded<string, WebhookUrlIotsBrand> =>
      isValidUrl(input, { requireHttps: true }),
    "WebhookUrl"
  ),
  () => `Needs to be a valid URL using https`
);

export const communityFundraisingGoalSpec = t.type({
  amount: safeIntCodec,
  startDate: dateFromStringCodec,
  endDate: dateFromStringCodec,
  onlyRecommendedNonprofits: t.boolean,
});

export const disbursementMetadataCodec = t.intersection([
  t.record(t.string, t.union([t.number, t.string])),
  t.partial({
    // If present, this should be displayed in admin UI and have its own column
    // in the downloadable donation CSV
    projectId: t.string,
  }),
]);
export type DisbursementMetadata = t.TypeOf<typeof disbursementMetadataCodec>;

export const focusAreaCodec = stringEnumCodec({
  name: "FocusArea",
  enumObject: FocusArea,
});

export const nonprofitEntityMetadataCodec = t.partial({
  primaryBrandColor: t.string,
  community: t.partial({
    // Community is public (meaning membership doesn't require confirmation)
    isPublic: t.boolean,
    // If this is set, then a membership request which includes this password will
    // be automatically accepted. Ignored if isPublic is true.
    token: t.string,
    // If present, this will show a fundraising thermometer on the community page
    fundraisingGoal: communityFundraisingGoalSpec,
    // If true, this community will use the new 2023 dashboard layout page
    use2023Style: t.boolean,
    // (2023 style) community banner images
    banner: t.partial({
      foregroundCloudinaryId: t.string,
      backgroundCloudinaryId: t.string,
    }),
    // custom metadata for TED2023 conference (used by Community2023/FeaturedNonprofit component)
    featured: t.array(
      t.partial({
        id: uuidCodec,
        ein: t.string,
        speaker: t.partial({
          name: t.string,
          title: t.string,
          imageUrl: t.string,
        }),
      })
    ),
  }),
  fiscalSponsorNonprofitId: uuidCodec,
  // Show a custom disclaimer on projects / fiscal sponsored organizations'
  // description footers Uses {projectName} as a placeholder for the project's
  // name and {parentLink} for a linkified parent's name.
  fiscalSponsorCustomDisclaimer: t.string,
  // Show a custom disclaimer on projects / fiscal sponsored organizations'
  // donations dashboard explaining how funds are handled
  fiscalSponsorDisbursementCustomDisclaimer: t.string,
  // Custom contact email to show on disclaimer on projects / fiscal sponsore
  // organizations' donations dashboard explaining how funds are handled
  fiscalSponsorDisbursementCustomContactEmail: t.string,
  // Any information that we want to share in stripe charges when disbursing
  // (or in other disbursement methods, but other methods require implementing it)
  disbursementMetadata: disbursementMetadataCodec,
  focusArea: focusAreaCodec,
  // An announcement that shows up on the donation flow for the nonprofit. This
  // will override the Global nonprofit announcement config if present.
  announcement: t.string,
  // Customize the amounts shown in the donation modal
  customDonationAmounts: t.array(t.number),
  // Customize monthly donation text
  customMonthlyDescription: t.string,
  // Show opt-in mail list checkbox in the donation modal
  optInCheckbox: t.boolean,
  // Disable sharing of private notes from donors.
  // The default is to enable private notes for nonprofits that have admins and
  // disable them for those that don't, since we have no way to pass the private
  // note through NFG.
  disablePrivateNotes: t.boolean,
  // Disable sharing of public testimony from donors.
  disablePublicTestimony: t.boolean,
  // Make sharing donor info a must. Checkbox becomes checked and un-uncheckable
  requireDonorInfo: t.boolean,
  // Notes to override default disbursement methods for custom ones This string
  // is the default text for the grant disbursement method in the "internalIO"
  // form.
  //
  //  It's also used to identify nonprofits to whom we need to make direct
  //  disbursements in the Metabase Direct Disbursements Dashboard -
  //  https://metabase.every.org/dashboard/125-direct-disbursements
  directDisbursement: t.string,
  // Link to disbursement instructions for the nonprofit
  disburseLink: t.string,
  hasAdmin: t.union([t.boolean, t.undefined]),
  // Array of DonationFlowPaymentOption values that are disabled for a given
  // nonprofit
  disabledPaymentFlowOptions: t.array(t.string),
  // Overrides our standard minimum donation size
  minDonationValue: currencyValueCodec,
  // If this is set to true when an admin creates a new project for
  // the nonprofit it will be created as a PROJECT_WITH_DISBURSEMENT
  defaultProjectWithDibursement: t.boolean,
  // Automatically hide fundraisers when they are created
  hidePeerFundraisers: t.boolean,
  // Custom disclosure extra text issues at the bottom of receipts
  customReceiptDisclosure: t.string,
  // Sends a single email when disbursing multiple disbursements together (eg: for many projects)
  sendSeparateEmailsPerDisbursement: t.boolean,
  // Deprecated: Shows the new pull stock flow donation. For now only set it to "true" for IRC.
  showStockPull: t.boolean,
  // Hides the fundraiser button
  hideFundraiseButton: t.boolean,
  // This will only hide the fundraise button if the traffic is suspect, as defined
  // at https://console.statsig.com/Bo2uHhvPo181DqzNMNyGR/gates/suspect_traffic
  hideFundraiseButtonSuspectTraffic: t.boolean,
  // Do not return any created funds to the frontend, do not show them on the main nonprofit profile
  hideCreatedFunds: t.boolean,
  logoAltText: t.string,
  coverImageAltText: t.string,
  enableSalesforce: t.boolean,
  hideDonationCount: t.boolean,
  disableTipping: t.boolean,
  showDonateButtons: t.boolean,
  overrideDisbursementMethod: stringEnumCodec({
    name: "overrideDisbursementMethod",
    enumObject: DisbursementType,
  }),
  disbursementsMemo: t.string,
  hideFromAPIs: t.boolean,
});

export type DescriptionLong = t.TypeOf<typeof descriptionLongCodec>;
export type Description = t.TypeOf<typeof descriptionCodec>;
export type DonationThankYouMessage = t.TypeOf<
  typeof donationThankYouMessageCodec
>;
export type CommunityFundraisingGoal = t.TypeOf<
  typeof communityFundraisingGoalSpec
>;
export type NonprofitEntityMetadata = t.TypeOf<
  typeof nonprofitEntityMetadataCodec
>;
export type WebhookUrl = t.TypeOf<typeof webhookUrlCodec>;

/* Slug codec */

export const SLUG_MIN_LENGTH = 2;
export const SLUG_MAX_LENGTH = 128;
export const slugRegexStr = `^[a-z0-9][a-z0-9.-]{${SLUG_MIN_LENGTH - 2},${
  SLUG_MAX_LENGTH - 2
}}[a-z0-9]$`;
export const slugRegex = new RegExp(slugRegexStr);
export interface SlugIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly Slug: unique symbol;
}

/**
 * io-ts codec that checks whether a nonprofit slug is valid.
 */
export const slugCodec = withMessage(
  t.brand(
    t.string,
    (s): s is t.Branded<string, SlugIotsBrand> => !!s.match(slugRegex),
    "Slug"
  ),
  (input) => {
    if (typeof input !== "string") {
      return "Must be a string";
    }
    if (input.length < SLUG_MIN_LENGTH) {
      return `Min length is ${SLUG_MIN_LENGTH}`;
    }
    if (input.length > SLUG_MAX_LENGTH) {
      return `Max length is ${SLUG_MAX_LENGTH}`;
    }
    if (input.startsWith(".") || input.startsWith("-")) {
      return "Cannot start with a symbol";
    }
    if (input.endsWith(".") || input.endsWith("-")) {
      return "Cannot end with a symbol";
    }
    return "Only lowercase letters, periods and dashes allowed";
  }
);

/**
 * A string consisting of valid characters for a slug
 */
export type Slug = t.TypeOf<typeof slugCodec>;

/**
 * Determines if the input is a valid slug, and if so narrows the type of
 * input value. If you wish to know why it's invalid, use the `slugCodec`
 * directly and inspect the error message.
 */
export function isValidSlug(maybeSlug: unknown): maybeSlug is Slug {
  return slugCodec.decode(maybeSlug)._tag === "Right";
}
