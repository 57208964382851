import { RawAvatarProps, RawAvatar } from "@components/Avatar";
import { css } from "@emotion/react";
import React from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ReactComponent as PlaceholderIllustration } from "src/assets/illustrations/nonprofit.svg";
import { Link } from "src/components/Link";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { LinkAppearance } from "src/styles/link";

interface NonprofitAvatarProps extends Omit<RawAvatarProps, "cloudinaryId"> {
  nonprofit: Pick<
    ContextNonprofit,
    "logoCloudinaryId" | "primarySlug" | "name" | "metadata"
  >;
  disableLink?: boolean;
  disablePlaceholder?: boolean;
}

export const NonprofitAvatar: React.FCC<NonprofitAvatarProps> = ({
  nonprofit,
  itemProp,
  ...rest
}) => {
  return (
    <RawNonprofitAvatar
      itemProp={itemProp || "logo"}
      logoCloudinaryId={nonprofit.logoCloudinaryId}
      primarySlug={nonprofit.primarySlug}
      {...rest}
      alt={nonprofit.metadata?.logoAltText || `${nonprofit.name} logo`}
    />
  );
};

export interface RawNonprofitAvatarProps
  extends Omit<RawAvatarProps, "cloudinaryId"> {
  logoCloudinaryId: ContextNonprofit["logoCloudinaryId"];
  primarySlug: ContextNonprofit["primarySlug"];
  disableLink?: boolean;
  disablePlaceholder?: boolean;
}

const PLACEHOLDER_CSS = css`
  height: 100%;
  width: auto;
`;

/**
 * Renders a nonprofit avatar based on provided logo URL and primary slug.
 */
export const RawNonprofitAvatar: React.FCC<RawNonprofitAvatarProps> = ({
  logoCloudinaryId,
  primarySlug,
  drawBorder = true,
  disableLink,
  ...rest
}) => {
  const avatar = (
    <RawAvatar
      cloudinaryId={logoCloudinaryId}
      drawBorder={drawBorder}
      placeholder={<PlaceholderIllustration css={PLACEHOLDER_CSS} />}
      {...rest}
    />
  );

  if (disableLink) {
    return avatar;
  }

  return (
    <Link
      appearance={LinkAppearance.UNSTYLED}
      data-tname="nonprofit"
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: primarySlug },
      })}
    >
      {avatar}
    </Link>
  );
};
