import { either } from "fp-ts/Either";
import * as t from "io-ts";

export const MAX_COMMENT_LEN = 20000;
const MAX_LEGAL_NAME = 100;

export interface CommentTextIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CommentText: unique symbol;
}
export const commentTextCodec = t.brand(
  t.string,
  (input): input is t.Branded<string, CommentTextIotsBrand> => {
    return input.length > 0 && input.length <= MAX_COMMENT_LEN;
  },
  "CommentText"
);
export type CommentText = t.TypeOf<typeof commentTextCodec>;

export const MAX_USER_BIO_LEN = 140;

export interface UserBioTextIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly UserBioText: unique symbol;
}
export const userBioTextCodec = t.brand(
  t.string,
  (input): input is t.Branded<string, UserBioTextIotsBrand> => {
    return input.length > 0 && input.length <= MAX_USER_BIO_LEN;
  },
  "UserBioText"
);
export type UserBioText = t.TypeOf<typeof userBioTextCodec>;

export const emptyStringAsNullCodec = t.string.pipe(
  new t.Type<null, string>(
    "emptyStringAsNullCodec",
    function isNull(u): u is null {
      return u === null;
    },
    function validateEmptyStringAsNull(u, ctx) {
      if (typeof u !== "string") {
        return t.failure(u, ctx, "Not a string");
      }
      if (u.length === 0) {
        return t.success(null);
      }
      return t.failure(u, ctx, "Non-empty string");
    },
    function encodeToEmptyString(a) {
      return "";
    }
  )
);

const MAX_GIFT_CARD_MESSAGE_LENGTH = 10000;
export interface GiftCardMessageTextIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly GiftCardMessageText: unique symbol;
}
export const giftCardMessageTextCodec = t.brand(
  t.string,
  (input): input is t.Branded<string, GiftCardMessageTextIotsBrand> => {
    return input.length > 0 && input.length <= MAX_GIFT_CARD_MESSAGE_LENGTH;
  },
  "GiftCardMessageText"
);
export type GiftCardMessageText = t.TypeOf<typeof giftCardMessageTextCodec>;

const REMOVE_SPECIAL_DEFAULT = /[.:/*&"'<>\\]/g;

/**
 * Prevents people from having certain special characters or links in their name.
 * Note it is when displaying and querying data that we need
 * to be careful to escape and prevent XSS or injection attacks.
 */
export function removeSpecial(str: string) {
  return str.replace(REMOVE_SPECIAL_DEFAULT, "");
}

const MAX_NAME_LEN = 30;

export function toName(str?: string, maxLen = MAX_NAME_LEN) {
  return str ? removeSpecial(str).trim().substr(0, maxLen) : undefined;
}

export const nameCodec = new t.Type<string, string, unknown>(
  "NameCodec",
  (unknownValue): unknownValue is string =>
    typeof unknownValue == "string" &&
    toName(unknownValue) === unknownValue &&
    unknownValue.length > 0,
  (unknownValue, context) =>
    either.chain(t.string.validate(unknownValue, context), (stringValue) => {
      const val = toName(stringValue);
      return val === undefined || val.length === 0
        ? t.failure(val, context)
        : t.success(val as string);
    }),
  t.identity
);

export const legalNameCodec = new t.Type<string, string, unknown>(
  "NameCodec",
  (unknownValue): unknownValue is string =>
    typeof unknownValue == "string" &&
    toName(unknownValue, MAX_LEGAL_NAME) === unknownValue &&
    unknownValue.length > 0,
  (unknownValue, context) =>
    either.chain(t.string.validate(unknownValue, context), (stringValue) => {
      const val = toName(stringValue, MAX_LEGAL_NAME);
      return val === undefined || val.length === 0
        ? t.failure(val, context)
        : t.success(val as string);
    }),
  t.identity
);
