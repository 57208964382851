import { useContext } from "react";

import {
  dispatchTurnstileAction,
  TurnstileActionType,
  TurnstileContext,
} from "src/context/TurnstileContext";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const SITE_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY ||
  "1x00000000000000000000AA";

function addTurnstileToken(divId: string, token: string | undefined) {
  dispatchTurnstileAction({
    type: TurnstileActionType.ADD_TO_CONTEXT,
    id: divId,
    token,
  });
}

export function renderTurnstile(divId: string) {
  try {
    const render = getWindow()?.turnstile?.render;
    if (typeof render === "function") {
      render(`#${divId}`, {
        sitekey: SITE_PUBLIC_KEY,
        "refresh-expired": "auto",
        "response-field-name": "cfTurnstileResponse",
        retry: "auto",
        theme: "light",
        callback: (token: string | undefined) =>
          addTurnstileToken(divId, token),
        "error-callback": () => {},
      });
    } else {
      logger.error({
        message: "Turnstile render property is not a function",
        data: { renderType: typeof render },
      });
    }
  } catch (error) {
    logger.error({ message: "Error when rendering Turnstile", error });
  }
}

export function removeTurnstile(divId: string) {
  try {
    getWindow()?.turnstile?.remove(`#${divId}`);
    dispatchTurnstileAction({
      type: TurnstileActionType.REMOVE_FROM_CONTEXT,
      id: divId,
    });
  } catch (error) {
    logger.error({ message: "Error when removing Turnstile", error });
  }
}

export function resetTurnstile(divId: string) {
  try {
    getWindow()?.turnstile?.reset(`#${divId}`);
  } catch (error) {
    logger.error({ message: "Error when resetting Turnstile", error });
  }
}

export function useTurnstileResponse(divId: string) {
  try {
    const turnstileState = useContext(TurnstileContext);
    const response = turnstileState.get(divId);
    return response;
  } catch (error) {
    logger.error({ message: "Error when getting Turnstile response", error });
    return undefined;
  }
}
