import { css } from "@emotion/react";

const noscriptWarningCss = css`
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  max-width: 800px;
  margin: 130px auto 32px;
  .button {
    display: inline-block;
    color: white;
    background: #00a37f;
    padding: 16px 24px;
    margin-top: 24px;
    border-radius: 500px;
    font-size: 20px;
    font-size: 1.25rem;
    transition: background 0.2s ease;
    text-decoration: none;
  }
  .button:hover {
    background: #008663;
  }
`;

export const NoScript = () => (
  <noscript>
    <div css={noscriptWarningCss}>
      <h1>JavaScript is disabled in your browser!</h1>
      <p>
        In order to use Every.org, your browser must have Javascript enabled.
      </p>
      <a
        className="button"
        href="http://www.enable-javascript.com/"
        target="enable-js"
      >
        Show me how to enable it
      </a>
    </div>
  </noscript>
);
