import React from "react";

import { LoadImageWithPriorityContextProps } from "src/context/LoadImageWithPriorityContext/types";

export const LoadImageWithPriorityContext = React.createContext({
  ids: [] as string[],
});

type LoadImageWithPriorityProviderProps = {
  children: React.ReactNode;
  initialValue: LoadImageWithPriorityContextProps;
};
export const LoadImageWithPriorityProvider = ({
  children,
  initialValue,
}: LoadImageWithPriorityProviderProps) => {
  return (
    <LoadImageWithPriorityContext.Provider
      value={{
        ids: initialValue?.ids || [],
      }}
    >
      {children}
    </LoadImageWithPriorityContext.Provider>
  );
};
