import { IconDisplay, IconSize } from "@components/Icon";
import { LoadingIndicator } from "@components/LoadingIndicator";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import React, { PropsWithChildren, Ref, useMemo } from "react";

import { Link, LinkProps } from "src/components/Link";
import {
  ButtonRole,
  ButtonSize,
  buttonCss as makeButtonCss,
} from "src/styles/button";
import { LinkAppearance } from "src/styles/link";
import { spacing, horizontalStackCss } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";

export { ButtonRole, ButtonSize } from "src/styles/button";

export enum ButtonTargetKind {
  LINK = "LINK",
  FUNCTION = "FUNCTION",
  SUBMIT = "SUBMIT",
}

export type OnClick =
  | (Omit<
      LinkProps,
      "data-tname" | "className" | "appearance" | "ref" | "children"
    > & {
      kind: ButtonTargetKind.LINK;
    })
  | {
      kind: ButtonTargetKind.FUNCTION;
      action: (e: React.MouseEvent) => void;
    }
  | {
      kind: ButtonTargetKind.SUBMIT;
      /**
       * Exposes the onClick handler for the original submit button, to modify
       * its behavior
       *
       * TODO: just expose all raw button attributes that don't conflict with
       * baked in functionality
       */
      onClick?: (e: React.MouseEvent) => void;
    };

export type ButtonProps = PropsWithChildren<{
  onClick: OnClick;
  "data-tname": string;
  title?: string;
  "data-action"?: ClickAction;
  className?: string;
  size?: ButtonSize;
  disabled?: boolean;
  role: ButtonRole;
  contentClassName?: string;
  contentCss?: CSSInterpolation;
  /**
   * Allows overriding styles on the button itself; more reliable than using the
   * `css` prop directly since for link buttons, the `css` prop will style the
   * link wrapper, not the actual button.
   */
  buttonCss?: CSSInterpolation;
  /**
   * Display an icon to the right of other contents in the Button (or centered if
   * no other content)
   *
   * - Styled by default to match text colors (though hover color may be
   *   wrong for unstyled buttons on colored text); color can be overrideen by
   *   styling the Icon directly
   */
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  /**
   * If present, shown an loading indicator in the middle of button
   * and hides everything else
   */
  submitting?: boolean;
  tabIndex?: number;
}>;

const loadingIndicatorCss = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

/**
 * CSS you can add to a button to make it display as a block-level full width
 * (assuming parent flex or grid rules don't prevent it from growing)
 *
 * @example
 * <Button css={fullWidthButtonCss} />
 */
export const fullWidthButtonCss = css`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const submittingCss = css`
  opacity: 0;
`;

const ButtonWithRef = React.forwardRef<HTMLElement, ButtonProps>(
  function ButtonImpl(props, ref) {
    const {
      onClick,
      "data-tname": dataTName,
      "data-action": dataAction,
      className,
      children,
      icon,
      contentClassName,
      contentCss,
      buttonCss: inputButtonCss,
      size = ButtonSize.MEDIUM,
      role,
      submitting,
      title,
      tabIndex,
      ...buttonContentProps
    } = props;

    const iconCss = useMemo(
      () => css`
        display: inline-flex;
        justify-content: flex-end;
        flex: 1;
        opacity: ${submitting ? 0 : 1};
      `,
      [submitting]
    );
    const iconPresent = !!icon;
    const iconContainerCss = useMemo(
      () => [
        horizontalStackCss.m,
        css`
          align-items: center;
          width: 100%;

          /* if icon present, center content with pseudoelement */
          ${iconPresent &&
          css`
            &::before {
              content: " ";
              flex-grow: 1;
              flex-basis: ${spacing.m};
            }
          `}
        `,
      ],
      [iconPresent]
    );

    const childrenContainerCss = useMemo(
      () => [{ flexGrow: 1 }, contentCss],
      [contentCss]
    );

    const content = (
      <span css={iconContainerCss}>
        {submitting && role !== ButtonRole.UNSTYLED && (
          <LoadingIndicator
            css={loadingIndicatorCss}
            iconProps={{
              display: IconDisplay.CURRENT_COLOR,
              size: IconSize.MEDIUM,
            }}
          />
        )}
        {children && (
          <span
            className={contentClassName}
            css={[childrenContainerCss, submitting && submittingCss]}
          >
            {children}
          </span>
        )}
        {icon && <span css={iconCss}>{icon}</span>}
      </span>
    );
    const buttonCss = useMemo(
      () => [makeButtonCss(role, size), inputButtonCss],
      [inputButtonCss, role, size]
    );

    const commonProps = {
      className,
      title,
      "data-tname": dataTName,
      "data-action": dataAction,
      css: buttonCss,
      tabIndex: tabIndex,
    };

    if (
      onClick.kind === ButtonTargetKind.LINK &&
      !buttonContentProps.disabled
    ) {
      const { kind, ...linkRest } = onClick;
      return (
        <Link
          ref={ref as Ref<HTMLAnchorElement>}
          appearance={LinkAppearance.UNSTYLED_NO_COLOR}
          {...commonProps}
          {...linkRest}
        >
          {content}
        </Link>
      );
    }
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={onClick.kind === ButtonTargetKind.SUBMIT ? "submit" : "button"}
        onClick={
          onClick.kind === ButtonTargetKind.LINK
            ? // was a disabled link, render it as a disabled button
              () => {
                // no-op
              }
            : onClick.kind === ButtonTargetKind.SUBMIT
            ? onClick.onClick
            : onClick.action
        }
        {...buttonContentProps}
        {...commonProps}
      >
        {content}
      </button>
    );
  }
);
export const Button = React.memo(ButtonWithRef);
