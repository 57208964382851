import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Popover } from "@components/Popover";

const MAX_POPOVER_LENGTH = "320px";

export const PopoverInfoButton: React.FCC<{
  text: string;
  imgSize?: IconSize;
  className?: string;
}> = ({ text, imgSize = IconSize.SMALL, className }) => {
  return (
    <Popover
      placement={"bottom"}
      className={className}
      css={{ display: "inline-flex" }}
      content={<div css={{ maxWidth: MAX_POPOVER_LENGTH }}>{text}</div>}
    >
      <Button
        data-tname="popoverInfoButton"
        role={ButtonRole.UNSTYLED}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: (e) => {
            e.stopPropagation();
          },
        }}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/InfoIcon")}
          display={IconDisplay.SECONDARY}
          size={imgSize}
        />
      </Button>
    </Popover>
  );
};
