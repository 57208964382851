import {
  ButtonTargetKind,
  Button,
  ButtonProps,
  ButtonRole,
  OnClick,
} from "@components/Button";
import { IconDisplay, IconSize } from "@components/Icon";
import { HeartAnimationIcon } from "@components/Icon/icons/HeartAnimationIcon";
import HeartIcon from "@components/Icon/icons/HeartIcon";
import { LogInOrSignUpRedirect } from "@components/Redirect";
import { css } from "@emotion/react";
import React, { useContext, useState } from "react";

import { Uuid } from "@every.org/common/src/codecs/entities";
import { LikeableType } from "@every.org/common/src/entity/types";

import { AuthContext } from "src/context/AuthContext";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { AuthStatus } from "src/context/AuthContext/types";
import { LikeChange } from "src/context/LikesContext";
import useLike from "src/context/LikesContext/useLike";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

interface Likeable {
  id: Uuid;
  likeCount: number | null;
  loggedInUserLikes: boolean | null;
  type: LikeableType;
}

interface LikeButtonProps
  extends Omit<ButtonProps, "onClick" | "icon" | "disabled">,
    Likeable {
  onChange?: (change: LikeChange) => void;
  /**
   * If passed in, forces link to be disabled (cannot force enabled)
   */
  disabled?: true;
  className?: string;
}

const countCss = css`
  ${textSizeCss.xs};
  color: var(${colorCssVars.accent.large});
  font-weight: ${FontWeight.MEDIUM};
  font-variant-numeric: tabular-nums;
`;

/**
 * Like/unlike button for an object with like counter.
 */
export const LikeButtonWithCounter = React.memo(
  function LikeButtonWithCounterImpl(props: LikeButtonProps) {
    const { onChange } = props;
    const { likeCount } = useLike(props, onChange);

    return (
      <span
        css={[
          css`
            display: flex;
            align-items: center;
          `,
        ]}
      >
        <LikeButton {...props} onChange={onChange} />
        {likeCount > 0 && <b css={countCss}>{likeCount}</b>}
      </span>
    );
  }
);

/**
 * Like/unlike button for an object.
 */
export const LikeButton: React.FCC<LikeButtonProps> = React.memo(
  function LikeButtonImpl({
    disabled: forceDisabled = false,
    onChange,
    ...rest
  }) {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const authState = useContext(AuthContext);
    const loggedInUser = useLoggedInUserOrUndefined();

    const { onLike, onUnlike, loggedInUserLikes, submitting } = useLike(
      rest,
      onChange
    );
    // strip out unneeded props for button
    const {
      loggedInUserLikes: _loggedInUserLikes,
      likeCount: _likeCount,
      ...buttonProps
    } = rest;

    const onClick: OnClick = {
      kind: ButtonTargetKind.FUNCTION,
      action: () => {
        if (authState.status === AuthStatus.LOGGED_OUT || !loggedInUser) {
          setRedirectToLogin(true);
          return;
        }
        (loggedInUserLikes ? onUnlike : onLike)();
      },
    };

    if (redirectToLogin) {
      return <LogInOrSignUpRedirect />;
    }

    return (
      <Button
        {...buttonProps}
        css={
          ![ButtonRole.PRIMARY, ButtonRole.SECONDARY].includes(rest.role) && {
            padding: spacing.xxs,
          }
        }
        onClick={onClick}
        disabled={forceDisabled || submitting}
        icon={
          /** To avoid triggering animation on first load,
           *  do not show HeartAnimationIcon if like is undefined.
           *  Wait for initialize from auth state
           *  We use only the HeartIcon to avoid Layout shifts
           * */
          loggedInUserLikes === undefined ? (
            <HeartIcon size={IconSize.MEDIUM} display={IconDisplay.ACCENT} />
          ) : (
            <HeartAnimationIcon
              size={IconSize.MEDIUM}
              liked={!!loggedInUserLikes}
            />
          )
        }
      />
    );
  }
);
