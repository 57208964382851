import { ButtonRole, ButtonSize } from "@components/Button";
import {
  ShareButton,
  ShareButtonProps,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import React, { useContext, useCallback, useMemo } from "react";

import {
  DonationResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { ShareMedium } from "@every.org/common/src/entity/types";
import { UTM_QUERY_PARAM } from "@every.org/common/src/helpers/analytics";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { getUserDonationShareUrl } from "@every.org/common/src/helpers/share";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import { UsersContext } from "src/context/UsersContext";
import { ClickAction } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  INSTAGRAM_POST_DIMENSIONS,
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
} from "src/utility/opengraph";

type DonationShareButtonProps = {
  donation: DonationResponse | PersonalDonationResponse;
} & Omit<
  ShareButtonProps,
  | "shareData"
  | "instagramImageCloudinaryParams"
  | "data-tname"
  | "role"
  | "size"
> &
  Partial<Pick<ShareButtonProps, "role" | "size">>;

export function trackCampaign({
  campaign,
  url: targetUrl,
}: {
  campaign?: string;
  url: string;
}) {
  if (campaign) {
    const url = new URL(targetUrl);

    url.searchParams.append(UTM_QUERY_PARAM.utm_campaign, campaign);

    return url.toString();
  }
  return targetUrl;
}
/**
 * Triggers native sharing options for the given donation when available, or a
 * sharing UI if not
 */

export const DonationShareButton: React.FCC<DonationShareButtonProps> =
  React.memo(function DonationShareButtonImpl({
    donation,
    hideText = true,
    role = ButtonRole.TEXT_ONLY,
    size = ButtonSize.SMALL,
    isFeedCardShare = true,
    ...rest
  }) {
    const shareData = useShareData(donation);
    const user = useContext(UsersContext).usersById.get(donation.fromUserId);
    const nonprofit = useContext(NonprofitsContext).nonprofitsById.get(
      donation.toNonprofitId
    );
    const { logoCloudinaryId = null, coverImageCloudinaryId = null } =
      typeof nonprofit === "object" ? nonprofit : {};
    const { profileImageCloudinaryId: userAvatarCloudinaryId = null } =
      typeof user === "object" ? user : {};

    return (
      <ShareButton
        isFeedCardShare={isFeedCardShare}
        aria-label="Share donation"
        data-tname="shareDonationButton"
        data-action={ClickAction.SHARE}
        hideText={hideText}
        role={role}
        size={size}
        shareData={shareData}
        instagramImageCloudinaryParams={useMemo(
          () =>
            nonprofitShareImageCloudinaryParams({
              logoCloudinaryId,
              coverImageCloudinaryId,
              userAvatarCloudinaryId,
              imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
            }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
          [coverImageCloudinaryId, logoCloudinaryId, userAvatarCloudinaryId]
        )}
        {...rest}
      />
    );
  });

export function useShareData(
  donation: DonationResponse | PersonalDonationResponse,
  options?: {
    overrideComment?: string;
    // If true, don't use user donation URL
    skipUserDonationUrl?: boolean;
    overrideUrl?: string;
    campaign?: string;
    postscript?: string | undefined;
  }
): ShareButtonProps["shareData"] {
  const loggedInUser = useLoggedInUserOrUndefined();
  const { fromUserId, toNonprofitId } = donation;
  const nonprofit =
    useContext(NonprofitsContext).nonprofitsById.get(toNonprofitId);
  const isMyDonation = fromUserId === loggedInUser?.id;
  const user = useContext(UsersContext).usersById.get(fromUserId);
  const { overrideComment, skipUserDonationUrl, overrideUrl } = options || {};
  return useCallback(
    (medium: ShareMedium) => {
      const url =
        user &&
        typeof user !== "symbol" &&
        user.username &&
        !skipUserDonationUrl
          ? getUserDonationShareUrl({
              nonprofitSlug: nonprofit?.primarySlug,
              donationShortId: donation.shortId,
              donationId: donation.id,
              username: user.username,
              format: URLFormat.ABSOLUTE,
            })
          : nonprofit
          ? getRoutePath({
              name: ClientRouteName.NONPROFIT_OR_CAUSE,
              tokens: { nonprofitSlug: nonprofit.primarySlug },
              query: { donationId: donation.id },
              format: URLFormat.ABSOLUTE,
            })
          : getRoutePath({
              name: ClientRouteName.HOME,
              tokens: {},
              query: { donationId: donation.id },
              format: URLFormat.ABSOLUTE,
            });

      return getSupportNonprofitShareData({
        isMyDonation,
        medium,
        nonprofit,
        body:
          overrideComment ||
          donation.commentText ||
          nonprofit?.description ||
          undefined,
        postscript: options?.postscript,
        url: trackCampaign({
          url: overrideUrl ?? url,
          campaign: options?.campaign,
        }),
      });
    },
    // Revisit
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      donation.commentText,
      donation.id,
      donation.shortId,
      isMyDonation,
      nonprofit,
      user,
      overrideComment,
      skipUserDonationUrl,
      overrideUrl,
    ]
  );
}
