import { IconProps } from "@components/Icon";

import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import { SharedCryptoCurrencyConfig } from "@every.org/common/src/helpers/cryptoCurrency";

type WebsiteCryptoCurrencyConfig =
  | {
      iconImport: IconProps["iconImport"];
    }
  | {
      iconCloudinaryId: string;
    };

const WebsiteCryptoCurrencyConfig: {
  [key in CryptoCurrency]: WebsiteCryptoCurrencyConfig;
} = {
  [CryptoCurrency.AAVE]: {
    iconCloudinaryId: "crypto/aave_oszkzj",
  },
  [CryptoCurrency.ALCX]: {
    iconCloudinaryId: "crypto/alcx_agfvh3",
  },
  [CryptoCurrency.AMP]: {
    iconCloudinaryId: "crypto/amp_zrgutx",
  },
  [CryptoCurrency.ANKR]: {
    iconCloudinaryId: "crypto/ankr_vipelu",
  },
  [CryptoCurrency.ALGO]: {
    iconImport: () => import("@components/Icon/icons/crypto/Algorand"),
  },
  [CryptoCurrency.APE]: {
    iconImport: () => import("@components/Icon/icons/crypto/Apecoin"),
  },
  [CryptoCurrency.AVAX]: {
    iconImport: () => import("@components/Icon/icons/crypto/Avalanche"),
  },
  [CryptoCurrency.AXS]: {
    iconCloudinaryId: "crypto/axs_vdwbc0",
  },
  [CryptoCurrency.BAT]: {
    iconImport: () =>
      import("@components/Icon/icons/crypto/BasicAttentionToken"),
  },
  [CryptoCurrency.BCH]: {
    iconImport: () => import("@components/Icon/icons/crypto/BitcoinCash"),
  },
  [CryptoCurrency.BNB]: {
    iconImport: () => import("@components/Icon/icons/crypto/Binance"),
  },
  [CryptoCurrency.BTC]: {
    iconImport: () => import("@components/Icon/icons/crypto/Bitcoin"),
  },
  [CryptoCurrency.CHZ]: {
    iconCloudinaryId: "crypto/chiliz_j11blm",
  },
  [CryptoCurrency.DAI]: {
    iconImport: () => import("@components/Icon/icons/crypto/Dai"),
  },
  [CryptoCurrency.DOGE]: {
    iconImport: () => import("@components/Icon/icons/crypto/Dogecoin"),
  },
  [CryptoCurrency.DOT]: {
    iconImport: () => import("@components/Icon/icons/crypto/Polkadot"),
  },
  [CryptoCurrency.ETH]: {
    iconImport: () => import("@components/Icon/icons/crypto/Ethereum"),
  },
  [CryptoCurrency.FIL]: {
    iconImport: () => import("@components/Icon/icons/crypto/Filecoin"),
  },
  [CryptoCurrency.GRT]: {
    iconImport: () => import("@components/Icon/icons/crypto/Graph"),
  },
  [CryptoCurrency.LINK]: {
    iconImport: () => import("@components/Icon/icons/crypto/Chainlink"),
  },
  [CryptoCurrency.LTC]: {
    iconImport: () => import("@components/Icon/icons/crypto/Litecoin"),
  },
  [CryptoCurrency.MATIC]: {
    iconImport: () => import("@components/Icon/icons/crypto/Polygon"),
  },
  [CryptoCurrency.MOB]: {
    iconImport: () => import("@components/Icon/icons/crypto/MobileCoin"),
  },
  [CryptoCurrency.RAY]: {
    iconImport: () => import("@components/Icon/icons/crypto/Raydium"),
  },
  [CryptoCurrency.SAMO]: {
    iconImport: () => import("@components/Icon/icons/crypto/Samoyed"),
  },
  [CryptoCurrency.SBR]: {
    iconImport: () => import("@components/Icon/icons/crypto/Saber"),
  },
  [CryptoCurrency.SHIB]: {
    iconImport: () => import("@components/Icon/icons/crypto/ShibaInu"),
  },
  [CryptoCurrency.SLP]: {
    iconCloudinaryId: "crypto/slp_clp71c",
  },
  [CryptoCurrency.SOL]: {
    iconImport: () => import("@components/Icon/icons/crypto/Solana"),
  },
  [CryptoCurrency.UNI]: {
    iconImport: () => import("@components/Icon/icons/crypto/Uniswap"),
  },
  [CryptoCurrency.USDC]: {
    iconImport: () => import("@components/Icon/icons/crypto/UsdCoin"),
  },
  [CryptoCurrency.USDT]: {
    iconImport: () => import("@components/Icon/icons/crypto/Tether"),
  },
  [CryptoCurrency.XLM]: {
    iconImport: () => import("@components/Icon/icons/crypto/Stellar"),
  },
  [CryptoCurrency.XRP]: {
    iconCloudinaryId: "crypto/xrp_uwcehg",
  },
  [CryptoCurrency.XTZ]: {
    iconImport: () => import("@components/Icon/icons/crypto/Tezos"),
  },
  [CryptoCurrency.ZEC]: {
    iconImport: () => import("@components/Icon/icons/crypto/ZCash"),
  },
  [CryptoCurrency.API3]: { iconCloudinaryId: "crypto/xb8xoz6kxev0uytaejlo" },
  [CryptoCurrency.AUDIO]: { iconCloudinaryId: "crypto/ucctvvgwedku9hexplp7" },
  [CryptoCurrency.BNT]: { iconCloudinaryId: "crypto/jgb6ub5gckohghdrt1ig" },
  [CryptoCurrency.BOND]: { iconCloudinaryId: "crypto/cidlkxmdqdoi2ezygfjj" },
  [CryptoCurrency.COMP]: { iconCloudinaryId: "crypto/ayjushqknohuz9x3hcx5" },
  [CryptoCurrency.CRV]: { iconCloudinaryId: "crypto/txyb4aa9a0skuhoyjcti" },
  [CryptoCurrency.CUBE]: { iconCloudinaryId: "crypto/n8su92fe1atqx79hrhqf" },
  [CryptoCurrency.CVC]: { iconCloudinaryId: "crypto/cw8ahifxtnfw8pbgh5o9" },
  [CryptoCurrency.ELON]: { iconCloudinaryId: "crypto/fvsarijyyz1v9j5w1sh2" },
  [CryptoCurrency.ENS]: { iconCloudinaryId: "crypto/naiw9tgamtvxcxctwxog" },
  [CryptoCurrency.FET]: { iconCloudinaryId: "crypto/mwfznnuw28qqeia4uwov" },
  [CryptoCurrency.FTM]: { iconCloudinaryId: "crypto/laq3m7el5wdfmlhwwtk0" },
  [CryptoCurrency.GAL]: { iconCloudinaryId: "crypto/a5km5rat7dpiz6ckkvfi" },
  [CryptoCurrency.GALA]: { iconCloudinaryId: "crypto/rifljrw1ry9wu1i7mxji" },
  [CryptoCurrency.GMT]: { iconCloudinaryId: "crypto/uyyddnskoabbpei7olin" },
  [CryptoCurrency.GUSD]: { iconCloudinaryId: "crypto/w8zltgl5ngsz1lqch17g" },
  [CryptoCurrency.INJ]: { iconCloudinaryId: "crypto/gi0voz42gn33azwaa11a" },
  [CryptoCurrency.JAM]: { iconCloudinaryId: "crypto/xmn1xxzccz84xiej1esw" },
  [CryptoCurrency.KNC]: { iconCloudinaryId: "crypto/mqdyhpc07mle6dkfomye" },
  [CryptoCurrency.KP3R]: { iconCloudinaryId: "crypto/eoabgtxyhpqqfhv1bxud" },
  [CryptoCurrency.LDO]: { iconCloudinaryId: "crypto/thmvnuxks9z3gthhypwb" },
  [CryptoCurrency.LPT]: { iconCloudinaryId: "crypto/setl154mkwd9kpfly7om" },
  [CryptoCurrency.LRC]: { iconCloudinaryId: "crypto/sazpx9zyfsgbmprmfag9" },
  [CryptoCurrency.MANA]: { iconCloudinaryId: "crypto/ekefdi1p3jgoeb08oszc" },
  [CryptoCurrency.MASK]: { iconCloudinaryId: "crypto/ykwym7djaofb2q6lcn5n" },
  [CryptoCurrency.MCO2]: { iconCloudinaryId: "crypto/ysuyysfrukryu1jw2wrh" },
  [CryptoCurrency.MKR]: { iconCloudinaryId: "crypto/tpdipvaap6m150j2hdec" },
  [CryptoCurrency.NMR]: { iconCloudinaryId: "crypto/bqecgq3rawel0k99tohj" },
  [CryptoCurrency.OXT]: { iconCloudinaryId: "crypto/ietkmjcp0yj71vlg0zjy" },
  [CryptoCurrency.PAXG]: { iconCloudinaryId: "crypto/yb5x1aizhhdn8mprrn2m" },
  [CryptoCurrency.QNT]: { iconCloudinaryId: "crypto/dwdx02ifn9fsvbw1pgfz" },
  [CryptoCurrency.QRDO]: { iconCloudinaryId: "crypto/jzbmlpvwoogroaaazkxz" },
  [CryptoCurrency.REN]: { iconCloudinaryId: "crypto/ili8nkjwnifmbfra20ly" },
  [CryptoCurrency.RNDR]: { iconCloudinaryId: "crypto/tm0klwno0hqjbcxsuvrp" },
  [CryptoCurrency.SAND]: { iconCloudinaryId: "crypto/w6g6ep4ghqpsz54vqzmy" },
  [CryptoCurrency.SKL]: { iconCloudinaryId: "crypto/zccvwa5yin8r32s4sfqy" },
  [CryptoCurrency.SNX]: { iconCloudinaryId: "crypto/sxvudvsjujuhdupwssvz" },
  [CryptoCurrency.STORJ]: { iconCloudinaryId: "crypto/le1ikh3apsrxyttjxg64" },
  [CryptoCurrency.SUSHI]: { iconCloudinaryId: "crypto/iivxx5mfdz1bckmhom1f" },
  [CryptoCurrency.UMA]: { iconCloudinaryId: "crypto/imxydvsf4mydmyjukbel" },
  [CryptoCurrency.YFI]: { iconCloudinaryId: "crypto/mal5muwn4fcsbuuyhohz" },
  [CryptoCurrency.ZBC]: { iconCloudinaryId: "crypto/hhiopvuac8qbpcnupgle" },
  [CryptoCurrency.ZRX]: { iconCloudinaryId: "crypto/ge5jqonw89ffg200z0in" },
};

export const CryptoCurrencyConfig = Object.fromEntries(
  Object.values(CryptoCurrency).map((cc: CryptoCurrency) => [
    cc,
    { ...SharedCryptoCurrencyConfig[cc], ...WebsiteCryptoCurrencyConfig[cc] },
  ])
) as {
  [key in CryptoCurrency]: SharedCryptoCurrencyConfig &
    WebsiteCryptoCurrencyConfig;
};
