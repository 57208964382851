import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode, useState } from "react";

import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

const AccordionStyledHeader = styled.div`
  ${horizontalStackCss.xs}
  justify-content: space-between;
  align-items: center;
`;

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.m};
`;

const rotateCss = css`
  position: relative;
  top: -1px;
  transform: rotate(180deg);
`;

const descriptionOpen = css`
  max-height: 1000px;
  transition: all 1s;
  opacity: 1;
`;

const descriptionClose = css`
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s;
`;

const mobileOnlyCss = cssForMediaSize({
  min: MediaSize.MEDIUM_LARGE,
  css: "display: none",
});

export interface AccordionItemTypes {
  id: string;
  title: string;
  description: ReactNode;
  mobileOnly?: boolean;
  omit?: boolean;
}

const AccordionItem: React.FCC<{ itemData: AccordionItemTypes }> = ({
  itemData,
}) => {
  const [isOpen, setOpen] = useState(false);
  if (itemData.omit) {
    return null;
  }
  return (
    <div
      data-tname={"AccordionItem--" + itemData.id}
      css={[
        verticalStackCss.xs,
        textSizeCss[TextSize.xs],
        itemData.mobileOnly && mobileOnlyCss,
      ]}
    >
      <Button
        data-tname="AccordionItem--open"
        role={ButtonRole.UNSTYLED}
        contentCss={{ textAlign: "left" }}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            setOpen(!isOpen);
          },
        }}
      >
        <AccordionStyledHeader>
          <div
            css={
              isOpen
                ? { fontWeight: FontWeight.BOLD }
                : css`
                    color: var(${colorCssVars.text.secondary});
                  `
            }
          >
            {itemData.title}
          </div>
          <Icon
            css={isOpen && rotateCss}
            iconImport={() => import("@components/Icon/icons/SelectArrowIcon")}
            size={IconSize.X_SMALL}
            display={IconDisplay.SECONDARY}
          />
        </AccordionStyledHeader>
      </Button>
      <div
        css={[
          isOpen ? descriptionOpen : descriptionClose,
          css`
            p {
              margin-bottom: ${spacing.s};
            }
          `,
        ]}
      >
        {itemData.description}
      </div>
    </div>
  );
};

export const Accordion = ({ dataList }: { dataList: AccordionItemTypes[] }) => {
  return (
    <AccordionWrapper>
      {dataList.map((item) => (
        <AccordionItem key={item.id} itemData={item} />
      ))}
    </AccordionWrapper>
  );
};
