import urlJoin from "url-join";

import { isPresent } from "./objectUtilities";
import { AtLeastOneKeyPresent } from "./types";

export type Dimensions = { width: number; height: number };
export interface FillDimensionsParams {
  /**
   * Dimensions of the image to display.
   *
   * - will not enlarge beyond the size of the original image
   * - will fill the dimensions, preserving aspect ratio by cropping
   * - if width or height isn't present, uses the provided dimensions while
   *   preserving aspect ratio
   */
  dimensions: AtLeastOneKeyPresent<Dimensions>;
  /**
   * Indicate if you want to multiply the size of the image for a provided
   * device pixel density multiplier
   *
   * @see {@link https://cloudinary.com/blog/how_to_automatically_adapt_website_images_to_retina_and_hidpi_devices}
   */
  dpr?: number | "auto";
  /**
   * Cloudinary fill mode used when setting the dimensions of the image
   *
   * - `lfill` fills a box of the provided dimensions with the image contents,
   *   without upscaling beyond size of original image, preventing upscaling
   *   artifacts and inefficiently large images
   * - `fill` does the same, but allows for upscaling; generally you shouldn't
   *   need this
   *
   * @default "lfill"
   */
  fillMode?: "fill" | "lfill";
}

/**
 * Create a Cloudinary transform that scales an image and crops it to fill the
 * given dimensions
 *
 * Will not scale up an image beyond its original size; that means the image may
 * not be cropped to the aspect ratio specified by width and height, if both are
 * present.
 *
 * @throws if dimensions is 0
 */
export function fillDimensionsTransform(params: FillDimensionsParams) {
  if (params.dimensions.width === 0 || params.dimensions.height === 0) {
    throw new Error("dimensions cannot be 0");
  }
  return [
    `c_${params.fillMode || "lfill"}`,
    params.dimensions.width
      ? `w_${Math.floor(params.dimensions.width)}`
      : undefined,
    params.dimensions.height
      ? `h_${Math.floor(params.dimensions.height)}`
      : undefined,
    params.dpr ? `dpr_${params.dpr}` : undefined,
  ]
    .filter(isPresent)
    .join(",");
}

/**
 * Crops an image to the aspect ratio determined by width and height, if both
 * are present
 */
export function cropAspectRatioTransform(
  params: FillDimensionsParams["dimensions"]
) {
  return params.width && params.height
    ? ["c_crop", `ar_${params.width}:${params.height}`].join(",")
    : null;
}

/**
 * Cloudinary transform that tells Cloudinary to compress, optimize and choose a
 * format for the image efficient for the type of photo.
 *
 * Quality - https://cloudinary.com/documentation/image_optimization#use_q_auto_automatic_quality_and_encoding
 * Format - https://cloudinary.com/documentation/image_transformations#automatic_format_selection
 *
 * "fl_progressive" - Enable progressive JPEG - https://support.cloudinary.com/hc/en-us/articles/202520682-Can-I-generate-Progressive-JPEGs-with-Cloudinary-
 *
 * As a brief comparison of performance, here are the size results for one test image:
 * - Raw .jpg (5568x3093) - 4.9 MB, ~300 ms
 * - q_auto - 2.5 MB, ~300 ms
 * - f_auto - 3.0 MB, ~750 ms
 * - q_auto,f_auto - 3.0 MB, 600 ms (noticably slower load)
 * - w_600,h_300,c_fill (600x300) - 61.5 KB, ~130 ms
 * - w_600,h_300,c_fill,q_auto - 59.0 KB, ~110 ms
 * - w_600,h_300,c_fill,q_auto,f_auto - ~59.0 KB, ~110 ms (maybe nothing changed?)
 */
function qualityTransform(autoFormat: boolean): string {
  return ["q_auto", autoFormat && "f_auto", "fl_progressive"]
    .filter(Boolean)
    .join(",");
}

export const CLOUDINARY_PROD_BASE_URL =
  "https://res.cloudinary.com/everydotorg/image/upload";

export enum ImageFormat {
  png = "png",
  jpg = "jpg",
  svg = "svg",
  auto = "auto",
}

export interface ConstructCloudinaryUrlParams extends FillDimensionsParams {
  /**
   * Public cloudinary ID of the image
   */
  cloudinaryId: string;
  /**
   * Cloudinary transforms to apply to the image
   *
   * This is applied after the base image is resized to the target dimensions;
   * necessary because if the base image surpasses 25 megapixels, Cloudinary
   * transforms fail; so doing the resize first is safe.
   *
   * @see {@link https://support.cloudinary.com/hc/en-us/articles/202520592-Do-you-have-a-file-size-limit-}
   */
  transforms?: string[];
  /**
   * File type of the resulting image; use jpg for images that are likely to be
   * photographic (like user profile images), png for images consisting of
   * symbols, icons, or designs (like nonprofit logos), and svg for
   * illustrations.
   *
   * @default auto Chosen since Cloudinary has functionality to decide the
   * optimal format itself, and we trust it
   */
  imageFormat?: ImageFormat;
  /**
   * Useful for transforms that are dependent on source image size, like overlays
   * @default false
   */
  applySizeAfterTransforms?: boolean;
  /**
   * Do not apply any transforms.
   */
  skipTransforms?: boolean;
}

/**
 * Generate an actual image URL for a cloudinary ID
 *
 * For performance reasons, resizes images to the size provided and applies
 * compression on Cloudinary's end.
 *
 * @see qualityTransform for details on compression performance
 */
export function constructCloudinaryUrl({
  cloudinaryId,
  dimensions,
  dpr,
  fillMode,
  transforms = [],
  applySizeAfterTransforms = false,
  imageFormat = ImageFormat.auto,
  skipTransforms = false,
}: ConstructCloudinaryUrlParams) {
  if (imageFormat === "svg" || skipTransforms) {
    // Do not apply transformations on svg as the vector will be rasterized,
    // we don't want
    return urlJoin(
      ...[CLOUDINARY_PROD_BASE_URL, `${cloudinaryId}.${imageFormat}`].filter(
        isPresent
      )
    );
  }
  const sizingTransforms = [
    fillDimensionsTransform({ dimensions, dpr, fillMode }),
    cropAspectRatioTransform(dimensions),
  ];
  return urlJoin(
    ...[
      CLOUDINARY_PROD_BASE_URL,
      ...(applySizeAfterTransforms ? [] : sizingTransforms),
      ...transforms,
      ...(applySizeAfterTransforms ? sizingTransforms : []),
      qualityTransform(imageFormat === ImageFormat.auto),
      [cloudinaryId, imageFormat === "auto" ? undefined : imageFormat]
        .filter(Boolean)
        .join("."),
    ].filter(isPresent)
  );
}

/**
 * Mapping from multipliers to image urls matching those multipliers
 */
export type SrcSetUrls = { [key in "1x" | "2x" | "3x" | "4x"]: string };

/**
 * Generate actual srcset attribute value to include in HTML from a mapping of multipliers to urls
 */
export function srcsetAttrFromUrls(obj: SrcSetUrls): string {
  return Object.entries(obj)
    .map(([multiple, src]) => `${src} ${multiple}`)
    .join(",");
}

/**
 * Make srcset object using transforms on the provided cloudinary ID. Use
 * `srcsetAttrFromUrls()` to convert to an actual HTML srcset attribute
 *
 * Works best with very large images (at least as large as 4x the provided
 * dimensions) for clean scaling. It appears Cloudinary doesn't make use of
 * vector images effectively, so if scaling an uploaded SVG, scale up before
 * uploading.
 */
export function srcsetObjectForCloudinaryId(
  params: Omit<ConstructCloudinaryUrlParams, "dpr">
): SrcSetUrls {
  return Object.fromEntries(
    [1, 2, 3, 4].map((multiplier) => [
      `${multiplier}x`, // key
      // value:
      constructCloudinaryUrl({
        ...params,
        dpr: multiplier,
      }),
    ])
  ) as SrcSetUrls; // since fromEntries typing is too general as of 2020/07/20
}

/**
 * Convert cloudinary ID to format necessary when using as part of a transform
 */
export function cloudinaryIdToTransformId(cloudinaryId: string): string {
  return cloudinaryId.replace("/", ":");
}
export const BASIS_GROTESQUE_CLOUDINARY_ID = "BasisGrotesque-Bold-Pro.otf";
