import { Big } from "big.js";
import { getUserLocale } from "get-user-locale";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import {
  displayCurrencyValue,
  minimumDenominationAmountToCurrencyValue,
  DisplayCurrencyValueParams,
  MinDenomCurrencyValue,
  DisplayCurrencyError,
} from "@every.org/common/src/helpers/currency";

import { CryptoCurrencyConfig } from "src/utility/cryptoCurrency";
import { logger } from "src/utility/logger";

const DefaultLocale = "en-US";

export function displayCryptoValueInUserLocale({
  cryptoCurrency,
  amount,
  maximumSignificantDigits,
}: {
  cryptoCurrency: CryptoCurrency;
  amount: Big;
  maximumSignificantDigits?: number;
}) {
  const locale = getUserLocale();
  const amountStr = new Intl.NumberFormat(locale, {
    maximumSignificantDigits,
  }).format(parseFloat(amount.toString()));
  return `${amountStr}\xa0${CryptoCurrencyConfig[cryptoCurrency].abbreviation}`;
}

/**
 * Displays a currency value in the current browser locale,
 * and logs if parsing errors were present
 */
export function displayCurrencyValueInUserLocale(
  params: (
    | { currencyValue: CurrencyValue }
    | {
        minDenomCurrencyValue: MinDenomCurrencyValue;
      }
  ) & {
    options?: Omit<DisplayCurrencyValueParams, "locale" | "value">;
  }
): string {
  const currencyValue =
    "minDenomCurrencyValue" in params
      ? minimumDenominationAmountToCurrencyValue(params.minDenomCurrencyValue)
      : params.currencyValue;
  let formatted: string;
  let error: DisplayCurrencyError | null;
  try {
    const displayValue = displayCurrencyValue({
      locale: getUserLocale(),
      value: currencyValue,
      ...params.options,
    });
    formatted = displayValue.formatted;
    error = displayValue.error;
  } catch {
    const displayValue = displayCurrencyValue({
      locale: DefaultLocale,
      value: currencyValue,
    });
    formatted = displayValue.formatted;
    error = displayValue.error;
  }

  if (error) {
    logger.warn({
      message: "Error when formatting currency string",
      data: {
        amount: currencyValue.amount.toString(),
        displayCurrencyError: error,
      },
    });
  }

  return formatted;
}
