interface Window extends globalThis.Window {
  /* eslint-disable @typescript-eslint/naming-convention */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Plaid?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fbq?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer?: any;
  // HotJar library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hj?: any;
  hjSiteSettings?: any;
  google?: {
    maps?: typeof google.maps;
  };
  // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
  ApplePaySession?: unknown;
  IntersectionObserver?: typeof IntersectionObserver;
  /* eslint-enable @typescript-eslint/naming-convention */
  // Turnstile
  turnstile?: {
    render(
      container: string | HTMLElement,
      params: {
        sitekey: string;
        theme?: "light" | "dark";
        callback?(token: string): void;
        "refresh-expired"?: "auto" | "manual" | "never";
        "response-field-name"?: string;
        retry?: "auto" | "manual";
        "error-callback"?(): void;
      }
    ): void;
    remove(container: string | HTMLElement): void;
    reset(container: string | HTMLElement): void;
  };
  YT?: any;
  onYouTubeIframeAPIReady?: () => void;
  everyDotOrgDonateButton?: {
    createWidget: (options: any) => void;
    setOptions: (options: any) => void;
  };
}
/**
 * Gets `window` browser global variable in an server-side-rendering-safe way
 *
 * @returns The globals if in a browser environment, or undefined if in a
 * SSR backend environment
 */
export function getWindow(): Omit<Window, "localStorage"> | undefined {
  // eslint-disable-next-line no-restricted-globals
  return typeof window === "undefined"
    ? undefined
    : // don't include localStorage in typings to force usage of getLocalStorage()
      // eslint-disable-next-line no-restricted-globals
      window;
}
