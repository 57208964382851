import { RawAvatarProps, RawAvatar } from "@components/Avatar";
import { css } from "@emotion/react";

import { UserResponse } from "@every.org/common/src/codecs/entities";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ReactComponent as AvatarPlaceholderImage } from "src/assets/illustrations/UserAvatarPlaceholder.svg";
import { Link } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { ClickAction } from "src/utility/analytics";

export interface UserAvatarProps extends Omit<RawAvatarProps, "cloudinaryId"> {
  user: Pick<UserResponse, "profileImageCloudinaryId" | "username">;
  disableLink?: boolean;
  onClick?: () => void;
  newTab?: boolean;
  // TODO: generalize this kind of LinkComponent logic
}

const placeholderCss = css`
  width: 100%;
  height: auto;
`;

export const UserAvatar: React.FCC<UserAvatarProps> = ({
  user,
  disableLink,
  onClick,
  newTab = false,
  ...rest
}) => {
  const { username, profileImageCloudinaryId } = user;

  const avatar = (
    <RawAvatar
      cloudinaryId={profileImageCloudinaryId}
      placeholder={<AvatarPlaceholderImage css={placeholderCss} />}
      {...rest}
    />
  );

  if (disableLink || !username) {
    return avatar;
  }

  return (
    <Link
      title={`Link to user ${username} profile`}
      data-tname="user"
      data-action={ClickAction.USER}
      onClick={onClick}
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.USER,
        tokens: { username },
      })}
      appearance={LinkAppearance.UNSTYLED}
      target={newTab ? "_blank" : undefined}
    >
      {avatar}
    </Link>
  );
};
