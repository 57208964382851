/**
 * Returns midnight on the next day.
 */
export function tomorrowMidnightUtc(today?: Date) {
  const date = today ? new Date(today) : new Date();
  date.setUTCHours(0, 0, 0, 0);
  date.setUTCDate(date.getUTCDate() + 1);
  return date;
}

export const MILLISECONDS_IN_A_DAY = 1000 * 24 * 60 * 60;

export function daysAgo(date: Date) {
  return (Date.now() - date.getTime()) / MILLISECONDS_IN_A_DAY;
}

/**
 * Returns the beginning of the given year in PST (midnight UTC + 8 hours).
 * Used to create the bounds to check for donations made in a given year. We
 * use PST as a sensible default, but we should do something more robust once
 * we have user timezones.
 * @param year Year string - i.e. "2019"
 */
export function beginningOfYearPst(year: number) {
  const date = new Date(`${year}-01-01`);
  date.setUTCHours(8);
  return date;
}

/**
 * Given a date like Date("02-03-2020"), returns "February"
 * https://stackoverflow.com/a/18648314/888970
 */
export function getMonthNameFromDate(date: Date, shorten?: boolean) {
  return date.toLocaleString("default", { month: shorten ? "short" : "long" });
}

/**
 * Returns string representation of date (either given, or current time)
 * without the time, eg 2020-11-22.
 */
export function getDateString(date?: Date) {
  return (date || new Date()).toISOString().substr(0, "yyyy-mm-dd".length);
}

export function getPrettyDayFromDate(date: Date) {
  const dayString = date.toLocaleString("default", { day: "numeric" });
  switch (dayString.substr(-1)) {
    case "1":
      return `${dayString}st`;
    case "2":
      return `${dayString}nd`;
    case "3":
      return `${dayString}rd`;
    default:
      return `${dayString}th`;
  }
}

/**
 * Compares the dates of to base entities for sorting.
 */
export function dateSortComparisonBaseEntity(
  a: { createdAt: Date },
  b: { createdAt: Date }
) {
  return b.createdAt.getTime() - a.createdAt.getTime();
}

/**
 *  Returns the amount of days between 2 dates
 */
export function daysDifference(date1: Date, date2: Date) {
  return Math.abs(date2.getTime() - date1.getTime()) / (24 * 60 * 60 * 1000);
}
