import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import {
  feedItemResponseCodec,
  nonprofitResponseCodec,
  nonprofitEditStatusCodec,
  nonprofitsTagsEditsResponseCodec,
  tagDataPropsBase,
  tagResponseCodec,
  userResponseCodec,
} from "../codecs/entities";
import { boundedIntFromString } from "../codecs/number";
import { HttpMethod } from "../helpers/http";

import { CAUSE_FEED_MAX_PAGE_NUM } from "./publicCached";

import { listParamsCodec, makeRouteSpec } from ".";

const upsertTagResponseCodec = t.type({
  tag: tagResponseCodec,
  nonprofits: t.array(nonprofitResponseCodec),
});

const newTagBodySpec = t.type({
  tagData: t.type(tagDataPropsBase),
  addNonprofitSlugs: t.array(t.string),
});
export const newTagRouteSpec = makeRouteSpec({
  path: "/tag",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: newTagBodySpec,
  responseBodyCodec: upsertTagResponseCodec,
});

const updateTagBodySpec = t.type({
  tagData: t.partial(tagDataPropsBase),
});
export const updateTagRouteSpec = makeRouteSpec({
  path: "/tag/:tagId",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({ tagId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: updateTagBodySpec,
  responseBodyCodec: upsertTagResponseCodec,
});

export const suggestTaggingNonprofitsCodec = t.type({
  tagIdsToAdd: t.array(uuidCodec),
  tagIdsToRemove: t.array(uuidCodec),
  nonprofitSlugs: t.array(t.string),
});
export const suggestTaggingNonprofitsRouteSpec = makeRouteSpec({
  path: "/tag/suggest-changes",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: suggestTaggingNonprofitsCodec,
  responseBodyCodec: t.type({}),
});

export const getTagNonprofitsFeedRouteSpec = makeRouteSpec({
  path: "/tag/:tagName/feed/nonprofits",
  method: HttpMethod.GET,
  authenticated: false,
  publicRoute: { publicCacheLengthMinutes: 1 },
  tokensCodec: t.type({ tagName: t.string }),
  paramsCodec: t.type({
    pageNum: boundedIntFromString(0, CAUSE_FEED_MAX_PAGE_NUM),
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    items: t.array(feedItemResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    nonprofitTags: t.array(tagResponseCodec),
  }),
});

export const getTagDonationsFeedRouteSpec = makeRouteSpec({
  path: "/tag/:tagName/feed/gifts",
  method: HttpMethod.GET,
  authenticated: false,
  publicRoute: { publicCacheLengthMinutes: 1 },
  tokensCodec: t.type({ tagName: t.string }),
  paramsCodec: t.type({
    pageNum: boundedIntFromString(0, CAUSE_FEED_MAX_PAGE_NUM),
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    items: t.array(feedItemResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    users: t.array(userResponseCodec),
  }),
});

const getTagSupportersResponseBodyCodec = t.type({
  hasMore: t.boolean,
  users: t.array(userResponseCodec),
});
export const getTagSupportersRouteSpec = makeRouteSpec({
  path: "/tag/:tagName/supporters",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ tagName: t.string }),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: getTagSupportersResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const nonprofitTagPendingTagsEditsParamsCodec = t.intersection([
  t.partial({
    tagId: uuidCodec,
    nonprofitId: uuidCodec,
  }),
  listParamsCodec,
]);

export type NonprofitTagPendingTagsEditsParams = t.TypeOf<
  typeof nonprofitTagPendingTagsEditsParamsCodec
>;
export const nonprofitTagPendingTagsEditsRouteSpec = makeRouteSpec({
  path: "/tagsNonprofitsEdits",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: nonprofitTagPendingTagsEditsParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: nonprofitsTagsEditsResponseCodec,
});

const applyNonprofitTaggingEditBodySpec = t.type({
  nonprofitTagEditId: uuidCodec,
  accepted: t.boolean,
});
export const applyNonprofitTaggingEditRouteSpec = makeRouteSpec({
  path: "/tagsNonprofitsEdits",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: applyNonprofitTaggingEditBodySpec,
  responseBodyCodec: t.type({
    edits: t.array(
      t.type({
        nonprofitTagEditId: uuidCodec,
        status: nonprofitEditStatusCodec,
      })
    ),
  }),
});

export const likeNonprofitTagRouteSpec = makeRouteSpec({
  path: "/tag/:id/like",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const unlikeNonprofitTagRouteSpec = makeRouteSpec({
  path: "/tag/:id/like",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});
