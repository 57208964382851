import { stringEnumValues } from "../codecs";

import { NonNeverKeys } from "./types";

/**
 * Useful for filtering to infer types automatically
 */
export function isPresent<T>(value: T): value is Exclude<T, null | undefined> {
  return !(value === null || value === undefined);
}

// The following types are some TypeScript-fu to get the `removeOptionalValues`
// function to be typed correctly. You don't need to understand them, though
// explanations are present in case you're curious.

/**
 * For a given object, makes all optional values required, and replaces all
 * `undefined` values with `never`. This results in undefined keys as having a
 * value of never, which is actually a type that's impossible to instantiate, so
 * alone this isn't that useful.
 */
type ReplaceUndefinedValuesWithNever<Obj, AlsoReplaceNull = false> = {
  [k in keyof Obj]-?: Exclude<
    Obj[k],
    (AlsoReplaceNull extends true ? null : never) | undefined
  >;
};

/**
 * The passed in object, with all `undefined` values removed and optional
 * values made required.
 *
 * Combining the `ReplaceUndefinedValuesWithNever` with the above helpers gives
 * the result we want: `undefined` values are replaced with `never`, then any
 * `never` values are removed so that the resulting object can be instantiated.
 */
export type RemoveUndefinedValues<
  Obj,
  AlsoReplaceNull extends boolean = false
> = {
  [key in NonNeverKeys<
    ReplaceUndefinedValuesWithNever<Obj, AlsoReplaceNull>
  >]: ReplaceUndefinedValuesWithNever<Obj, AlsoReplaceNull>[key];
};

/**
 * Removes missing optional and undefined values from an object
 *
 * @param obj Object to filter
 * @returns Object without any undefined values. `null` values are preserved.
 */
export function removeUndefinedValues<Obj extends object>(
  obj: Obj
): RemoveUndefinedValues<Obj> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v !== undefined)
  ) as RemoveUndefinedValues<Obj>;
}

/**
 * Removes missing optional and undefined/null values from an object
 *
 * @param obj Object to filter
 * @returns Object without any undefined/null values.
 */
export function removeUndefinedOrNullValues<Obj extends object>(
  obj: Obj
): RemoveUndefinedValues<Obj, true> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v !== undefined && v !== null)
  ) as RemoveUndefinedValues<Obj, true>;
}

export type MappedValues<Obj, Value> = { [key in keyof Obj]: Value };
export function mapValues<Obj extends object, Value>(params: {
  obj: Obj;
  mapper: (value: Obj[keyof Obj], key: keyof Obj) => Value;
}): MappedValues<Obj, Value> {
  const { obj, mapper } = params;
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      mapper(value, key as keyof Obj),
    ])
  ) as MappedValues<Obj, Value>;
}

/**
 * @returns object with key-value pairs that fail the test function omitted
 */
export function filterObject<Obj extends object>(
  obj: Obj,
  filterFn: (key: keyof Obj, value: Obj[typeof key]) => boolean
): Partial<Obj> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) =>
      filterFn(key as keyof Obj, value)
    )
  ) as Partial<Obj>;
}

/**
 * Type helper that returns the object literal as a readonly version of itself
 *
 * Under the hood does nothing, just applies the readonly type for convenience
 */
export function readonly<T>(obj: T): Readonly<T> {
  return obj;
}

/**
 * Useful when filtering to remove undefined values as this will
 * help typescript understand array can no longer contain undefined.
 */
export function notUndefined<T>(x: T): x is Exclude<T, undefined> {
  return x !== undefined;
}

/**
 * Useful when filtering to remove undefined or nullvalues as this will
 * help typescript understand array can no longer contain undefined or null.
 */
export function notUndefinedOrNull<T>(x: T): x is Exclude<T, undefined | null> {
  return x !== undefined && x !== null;
}
export function notFalsey<T>(
  x: T
): x is Exclude<T, undefined | null | false | 0 | ""> {
  return !!x;
}

/**
 * Given a string enum and a map function, creates an object whose keys are the
 * enum values, and values are the output of the map function
 */
export function objectFromEnumValues<
  Enum extends Record<keyof Enum, string>,
  Value
>(params: {
  enum: Enum;
  mapFn: (value: Enum[keyof Enum]) => Value;
}): { [key in keyof Enum]: Value } {
  return Object.fromEntries(
    stringEnumValues({ enumObject: params.enum }).map((v) => [
      v,
      params.mapFn(v),
    ])
  ) as {
    [key in keyof Enum]: Value;
  };
}

export function reverseMap<K, V>(orig: Map<K, V>): Map<V, Set<K>> {
  return [...orig.entries()].reduce(
    (result, [key, value]) =>
      result.set(
        value,
        result.has(value) ? result.get(value).add(key) : new Set([key])
      ),
    new Map()
  );
}

/**
 * Returns object only with key-value pairs matching those in the set
 *
 * @example
 * const object = {x: 1, y: 2, z: 3}
 * // "as const" ensures more narrow typing
 * const xOnly = pickKeys(object, new Set(["x"] as const))
 * const xzOnly = pickKeys(object, new Set(["x", "z"] as const))
 */
export function pickKeys<Obj extends object, Key extends keyof Obj>(
  obj: Obj,
  keys: readonly Key[]
): Pick<Obj, Key> {
  const keySet = new Set(keys);
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keySet.has(key as Key))
  ) as Pick<Obj, Key>;
}

/**
 * Returns object without the key-value pairs matching those in the set
 *
 * @example
 * const object = {x: 1, y: 2, z: 3}
 * // "as const" ensures more narrow typing
 * const yzOnly = removeKeys(object, new Set(["x"] as const))
 */
export function removeKeys<Obj extends object, Key extends keyof Obj>(
  obj: Obj,
  keys: readonly Key[]
): Omit<Obj, Key> {
  const keySet = new Set(keys);
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keySet.has(key as Key))
  ) as Omit<Obj, Key>;
}

/**
 * Deep object comparison for base objects, arrays and base types.
 *
 * It is not intended to be used with objects containing maps or sets.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqualObjects(obj1: any, obj2: any) {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (typeof obj1 !== "object") {
    return obj1 === obj2;
  }
  if (new Set(Object.keys(obj1)).size !== new Set(Object.keys(obj2)).size) {
    return false;
  }
  for (const [key, value1] of Object.entries(obj1)) {
    if (!(key in obj2)) {
      return false;
    }
    const value2 = obj2[key];
    if (typeof value1 !== typeof value2) {
      return false;
    }
    if (Array.isArray(value1)) {
      const equalValues =
        Array.isArray(value2) &&
        value1.length == value2.length &&
        value1.every((subvalue1, index) =>
          deepEqualObjects(subvalue1, value2[index])
        );
      if (!equalValues) {
        return false;
      }
    } else if (typeof value1 === "object") {
      const equalValues = deepEqualObjects(value1, value2);
      if (!equalValues) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }
  return true;
}

/**
 * Validates if any previously existing attributes of the metadata
 * are being overwritten with a new value.
 *
 * @param previous: an object, null or undefined
 * @param neo: an object, null or undefined
 * @param considerAddedAttributes: false to consider only existing values in the current metadata
 * @param considerMissingAttributes: false to not consider missing values in the new metadata
 * @param considerExtendingArrays: false to consider an array being extended as not changed
 * @param metadataKey: the key under which the metadata object is found
 */
export function didObjectMetadataChange<ObjA, ObjB>({
  previous,
  neo,
  considerAddedAttributes = true,
  considerMissingAttributes = true,
  considerArrays = false,
  metadataKey = "metadata",
  ignoreKeys = [],
}: {
  previous: ObjA;
  neo: ObjB;
  considerAddedAttributes?: boolean;
  considerMissingAttributes?: boolean;
  considerArrays?: boolean;
  metadataKey?: string;
  ignoreKeys?: string[];
}) {
  if (!previous && !neo) {
    return false;
  }
  const isPreviousEmpty =
    previous === undefined ||
    previous === null ||
    previous[metadataKey] === null ||
    previous[metadataKey] === undefined ||
    !Object.keys(previous[metadataKey]).length;
  const isNewEmpty =
    neo === undefined ||
    neo === null ||
    neo[metadataKey] === null ||
    neo[metadataKey] === undefined ||
    !Object.keys(neo[metadataKey]).length;
  if (isPreviousEmpty !== isNewEmpty) {
    return true;
  }
  return (
    !isPreviousEmpty &&
    !isNewEmpty &&
    ((considerAddedAttributes &&
      Object.keys(neo[metadataKey]).some(
        (subkey) => !(subkey in previous[metadataKey])
      )) ||
      (considerMissingAttributes &&
        Object.keys(previous[metadataKey]).some(
          (subkey) => !(subkey in neo[metadataKey])
        )) ||
      Object.entries(previous[metadataKey]).some(([subkey, subvalue]) =>
        ignoreKeys.includes(subkey)
          ? false
          : !considerMissingAttributes && neo[metadataKey][subkey] === undefined
          ? false
          : Array.isArray(neo[metadataKey][subkey]) && Array.isArray(subvalue)
          ? considerArrays
            ? neo[metadataKey][subkey].length === subvalue.length &&
              !neo[metadataKey][subkey].every(
                (value: unknown, index: number) => value === subvalue[index]
              )
            : false
          : !deepEqualObjects(neo[metadataKey][subkey], subvalue)
      ))
  );
}

/**
 * Converts all the values of an object to string and narrow the type
 */
export function objectValuesToString(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>
): Record<string, string> {
  return Object.entries(object).reduce((newObj, [key, value]) => {
    // Do not surround all strings values '"' by calling JSON.stringify, instead pass them in directly.
    newObj[key] = typeof value === "string" ? value : JSON.stringify(value);
    return newObj;
  }, {});
}
