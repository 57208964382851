import * as t from "io-ts";

/**
 * A custom codec for a location.
 */
export const locationCodec = t.type({
  address: t.string,
  lat: t.number,
  lng: t.number,
});

/**
 * Refers to a location.
 */
export interface Location {
  /**
   * Human-readable address.
   */
  address: string;

  /**
   * Lat/lng coordinates.
   */
  lat: number;
  lng: number;
}
