import AchievementBadge from "@components/Achievements/AchievementBadge";
import { css } from "@emotion/react";

import { AchievementResponse } from "@every.org/common/src/codecs/entities";
import { coerceToSafeIntOrThrow } from "@every.org/common/src/codecs/number";
import { LIGHT_THEME } from "@every.org/common/src/display/palette";
import { Currency } from "@every.org/common/src/entity/types";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";

import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

interface AchievementRowProps {
  achievement: AchievementResponse;
  descriptionSectionClassName?: string;
}

const AchievmentIncentiveText = ({
  achievement,
}: {
  achievement: AchievementResponse;
}) => {
  if (!achievement.incentiveRewardAmount) {
    return null;
  }

  const achievementRewardAmount = displayCurrencyValueInUserLocale({
    currencyValue: minimumDenominationAmountToCurrencyValue({
      amountInMinDenom: coerceToSafeIntOrThrow({
        num: achievement.incentiveRewardAmount,
      }),
      currency: Currency.USD,
    }),
  });

  return (
    <span
      css={[
        textSizeCss.xs,
        css`
          color: ${LIGHT_THEME.text.secondary};
        `,
      ]}
    >
      Earn{" "}
      <span
        css={css`
          color: ${LIGHT_THEME.accent.large};
        `}
      >
        {achievementRewardAmount}
      </span>{" "}
      in giving credit
    </span>
  );
};

export const AchievementRow = ({
  achievement,
  descriptionSectionClassName,
}: AchievementRowProps) => {
  const achievementIncentiveTextComponent = (
    <AchievmentIncentiveText achievement={achievement} />
  );

  return (
    <section css={horizontalStackCss.m}>
      <AchievementBadge
        achievement={achievement}
        badgeNumber={achievement.maxRedeemable - achievement.numEarned}
      />
      <div css={verticalStackCss.none} className={descriptionSectionClassName}>
        <div
          css={[
            horizontalStackCss.xs,
            css`
              align-items: center;
              flex-wrap: wrap;
            `,
          ]}
        >
          <span
            css={css`
              font-weight: ${FontWeight.MEDIUM};
              ${textSizeCss.s}
            `}
          >
            {achievement.title}
          </span>
          {achievement.invalidAfter && (
            <span
              css={css`
                color: ${LIGHT_THEME.text.secondary};
                ${textSizeCss.xs}
              `}
            >
              Expires: {achievement.invalidAfter.toLocaleDateString()}
            </span>
          )}
        </div>
        <div>
          <span css={textSizeCss.xs}>{achievement.description}</span>
        </div>
        {achievementIncentiveTextComponent && (
          <div
            css={css`
              padding-top: ${spacing.xs};
            `}
          >
            {achievementIncentiveTextComponent}
          </div>
        )}
      </div>
    </section>
  );
};

export const EarnedAchievementRow = ({ achievement }: AchievementRowProps) => {
  if (!achievement.numEarned) {
    return null;
  }

  return (
    <section css={horizontalStackCss.m}>
      <AchievementBadge
        achievement={achievement}
        badgeNumber={achievement.numEarned}
      />
      <div css={verticalStackCss.none}>
        <span
          css={css`
            font-weight: ${FontWeight.MEDIUM};
            ${textSizeCss.m}
          `}
        >
          {achievement.title}
        </span>
        {achievement.lastEarnedDate && (
          <div>
            <span
              css={[
                textSizeCss.xs,
                css`
                  color: ${LIGHT_THEME.text.secondary};
                `,
              ]}
            >
              {achievement.lastEarnedDate.toLocaleDateString()}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
