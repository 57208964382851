import { ModalProps } from "@components/Modal";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import * as t from "io-ts";
import { UUID } from "io-ts-types";
import { NumberFromString } from "io-ts-types/NumberFromString";
import { UseFormReturn } from "react-hook-form";

import { emailAddressCodec } from "@every.org/common/src/codecs/contact";
import { cryptoCurrencyCodec } from "@every.org/common/src/codecs/crypto";
import {
  currencyCodec,
  CurrencyValue,
} from "@every.org/common/src/codecs/currency";
import {
  donationFrequencyCodec,
  paymentMethodCodec,
  paymentSourceResponseCodec,
  DonationResponse,
  UserResponse,
  PersonalDonationResponse,
  PersonalDonationChargeResponse,
  SignupIncentiveCampaign,
  FundraiserResponse,
  paymentSourceTypeCodec,
} from "@every.org/common/src/codecs/entities";
import { nameCodec } from "@every.org/common/src/codecs/text";
import {
  PaymentMethod,
  DonationFrequency,
  DonationFlowPaymentOption,
  Currency,
} from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import {
  DonationMatchCampaignIdentifier,
  DonationMatchData,
} from "@every.org/common/src/routes/donate";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export interface DonateFormPropsBase {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  shorten: boolean;
  className?: string;
  donationHeaderText?: string;
  minDonationValue?: CurrencyValue;
  // Restricts the available frequency options
  frequencyOptions?: DonationFrequency[];
  defaultAmount?: CurrencyValue["amount"];
  defaultTipAmount?: CurrencyValue["amount"];
  defaultFrequency?: DonationFrequency | null;
  defaultExternalPaymentSourceId?: string;
  defaultComment?: DonationResponse["commentText"];
  defaultShareInfo?: boolean;
  requireShareInfo?: boolean;
  onStepIdChange?: (id: DonateFormStepId) => void;
  // used for case when we want to redirect user on diferent page before closing modal
  successUrl?: string;
  // used for case when we want to redirect user on diferent page after closing modal
  redirectUrl?: string;
  // A callback for what to do when the user has successfully finished donating.
  // It ignores redirectUrl if provided
  onComplete?: () => void;
  /**
   * Callback that is called when the form step changes.
   */
  onFormStepSelected?: (index: number) => void;
}

export enum DonateModalAction {
  DONATE,
  UPDATE,
}

export interface DonateFormProps extends DonateFormPropsBase {
  donateAction: DonateModalAction.DONATE;
  donationToJoinId?: DonationResponse["id"];
  userToJoinId?: UserResponse["id"];
  matchCampaign?: DonationMatchCampaignIdentifier;
  fromFundraiserId?: UUID;
}

export interface UpdateDonationFormProps extends DonateFormPropsBase {
  donateAction: DonateModalAction.UPDATE;
  editDonationId: DonationResponse["id"];
  onUpdateComplete?: (
    donation: PersonalDonationResponse
  ) => void | Promise<void>;
}

type ExcludedFormKeys = "onConfirm" | "shorten" | "setNoExit";
export type DonateModalProps = ModalProps &
  (
    | Omit<DonateFormProps, ExcludedFormKeys>
    | Omit<UpdateDonationFormProps, ExcludedFormKeys>
  );

export enum DonateFormStepId {
  FREQUENCY_AMT,
  ALTERNATE_PAYMENT,
  CRYPTO,
  DONOR_INFO,
  COMMENT,
  SIGNUP,
  CONFIRM,
  CONFIRM_PAYPAL,
  SHARE_MATCHING,
}

export interface CreateOrUpdateDonationResult {
  donation: PersonalDonationResponse;
  donationCharge?: PersonalDonationChargeResponse;
  match?: DonationMatchData;
  redeemedSignupIncentiveCampaign?: SignupIncentiveCampaign;
}

export const donateFormFields = {
  paymentMethod: paymentMethodCodec,
  paymentSourceType: t.union([paymentSourceTypeCodec, t.undefined]),
  // TODO need a better codec for this
  // TODO how do we make the codec also validate min/max values?
  amount: t.union([NumberFromString, t.undefined]),
  amountToEveryOrg: t.union([t.string, t.undefined]),
  currency: currencyCodec,
  frequency: donationFrequencyCodec,
  shareInfo: t.boolean,
  shareInfoWithFundraiserCreator: t.boolean,
  optInToMailingList: t.union([t.boolean, t.undefined]),
  paymentSource: t.union([paymentSourceResponseCodec, t.undefined]),
  partnerDonationId: t.union([t.string, t.undefined]),
  partnerWebhookToken: t.union([t.string, t.undefined]),
  partnerMetadata: t.union([t.string, t.undefined]),
  firstName: nameCodec,
  lastName: nameCodec,
  email: emailAddressCodec,
  /**
   * These are only specified for crypto donations
   */
  cryptoCurrency: t.union([cryptoCurrencyCodec, t.undefined]),
  cryptoPledgeAmount: t.union([NumberFromString, t.undefined]),
  /**
   * These are only specified for stock donations
   */
  brokeragePull: t.union([t.boolean, t.undefined]),
  brokerageFirm: t.union([t.string, t.undefined]),
  brokerageAccountNumber: t.union([t.string, t.undefined]),
  brokerContactName: t.union([t.string, t.undefined]),
  brokerEmailAddress: t.union([t.string, t.undefined]),
  brokerPhoneNumber: t.union([t.string, t.undefined]),
  signature: t.union([t.string, t.undefined]),
  /**
   * These are present only on guest donations made via Apple / Google pay.
   * All other donors should already have a guest user.
   */
  payerEmail: t.union([emailAddressCodec, t.undefined]),
  payerName: t.union([t.string, t.undefined]),
  paymentRequestPaymentMethodId: t.union([t.string, t.undefined]),
  privateNote: t.union([t.string, t.undefined]),
  toNonprofits: t.union([t.array(t.string), t.undefined]),
  toNonprofitWeights: t.union([t.array(t.number), t.undefined]),
  toNonprofitMatchings: t.union([t.array(t.number), t.undefined]),
  recurringMatches: t.union([t.string, t.undefined]),
  stockSymbol: t.union([t.string, t.undefined]),
  stockAmount: t.union([t.number, t.undefined]),
  isPublic: t.union([t.boolean, t.undefined]),
  designation: t.union([t.string, t.undefined]),
  commentText: t.union([t.string, t.undefined]),
  isDoubleDonation: t.union([t.boolean, t.undefined]),
  doubleDonationMessage: t.union([t.string, t.undefined]),

  givingCreditToRedeem: t.union([t.any, t.undefined]),
  giftCardCode: t.union([t.string, t.undefined]),

  suggestedTipPercent: t.union([t.number, t.undefined]),
  actualTipPercent: t.union([t.number, t.undefined]),
};

const donateFormSchema = t.type(donateFormFields);
// Enables us to add generic form errors (not associated with a specific type)
// See:
// https://github.com/react-hook-form/react-hook-form/issues/2169#issuecomment-657220206
export const DONATE_FORM_ERROR = "DONATE_FORM_ERROR" as const;

interface DonateFormErrors {
  [DONATE_FORM_ERROR]?: string;
}

// will not be sent to the server
interface DonateFormUtilityFields {
  includePrivateNote: boolean;
  includeCommentText: boolean;
}

export type DonateFormType = t.TypeOf<typeof donateFormSchema> &
  DonateFormErrors &
  DonateFormUtilityFields & {
    paymentTab: string;
  };

export type DonateV3FormProps = {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
};
export type PaymentProcessCoreProps =
  | Omit<DonateFormProps, ExcludedFormKeys>
  | Omit<UpdateDonationFormProps, ExcludedFormKeys>;
export type PaymentProcessProps = PaymentProcessCoreProps & DonateV3FormProps;

export type DonateV3PageProps = DonateModalProps & { fromFundraiserId?: UUID }; // this was present in the DonateModalProps type // Not sure why this is necessary but for some reason typescript didn't think

export const paymentFlowOptionToPaymentMethodMap: {
  [key in DonationFlowPaymentOption]: PaymentMethod;
} = {
  [DonationFlowPaymentOption.CREDIT_CARD]: PaymentMethod.STRIPE,
  [DonationFlowPaymentOption.BANK]: PaymentMethod.STRIPE,
  [DonationFlowPaymentOption.PAYPAL]: PaymentMethod.PAYPAL,
  [DonationFlowPaymentOption.VENMO]: PaymentMethod.PAYPAL,
  [DonationFlowPaymentOption.GIFT_CARD]: PaymentMethod.STRIPE,
  [DonationFlowPaymentOption.PAYMENT_REQUEST]: PaymentMethod.STRIPE,
  [DonationFlowPaymentOption.CRYPTO]: PaymentMethod.CRYPTO,
  [DonationFlowPaymentOption.STOCKS]: PaymentMethod.STOCKS,
  [DonationFlowPaymentOption.DAF]: PaymentMethod.DAF,
};

export const ALTERNATE_PAYMENT_FLOW_OPTIONS = [
  DonationFlowPaymentOption.CRYPTO,
  DonationFlowPaymentOption.DAF,
  DonationFlowPaymentOption.STOCKS,
];

export enum DonateV3Layout {
  MODAL = "MODAL",
  PAGE = "PAGE",
}

export interface CryptoTokenRate {
  cryptoCurrency: CryptoCurrency;
  currency: Currency;
  rate: number;
}
