import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import { spacing } from "@every.org/common/src/display/spacing";

import { horizontalStackCss } from "src/theme/spacing";

const hex2rgba = (hex: string, alpha = 1) => {
  const regexHex = hex.match(/\w\w/g);
  if (regexHex) {
    const [r, g, b] = regexHex.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }

  return hex;
};

export const popoverContentCss = css`
  > div:not(:last-child) {
    padding: ${spacing.xxs} ${spacing.s};
  }
`;
export const InputTicks = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
`;

export const Tick = styled.div`
  width: var(--ticks-size);
  height: var(--ticks-size);
  border-radius: 50%;
  margin: 0 calc((var(--track-height) - var(--ticks-size)) / 2);

  :not(:last-child) {
    background-color: var(--ticks-color);
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  position: absolute;
  width: calc(100% - var(--track-height));
  margin: 0 calc(var(--track-height) / 2);
  bottom: var(--track-height);
`;

export const StyledLabel = styled.label`
  display: flex;
  position: absolute;
  left: var(--progress);
  bottom: calc(var(--thumb-offset) + ${spacing.xs});
`;

export const InputTrack = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: var(--track-height);
  display: flex;
  background-color: var(--track-color);
  border-radius: 12px;
  top: 50%;
  transform: translateY(-50%);

  ::before {
    content: "";
    z-index: 2;
    position: absolute;
    display: flex;
    border-radius: 12px;
    width: var(--progress);
    height: var(--track-height);
    background-color: var(--progress-color);
    transform: translate(0, -50%);
    top: 50%;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  height: var(--thumb-size);
  input {
    position: absolute;
    z-index: 2;
    width: calc(100% + calc(var(--thumb-offset) * 2));
    right: calc(var(--thumb-offset) * -1);
    top: 50%;
    transform: translateY(-50%);
  }

  ${InputTrack} {
    z-index: 1;
  }
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  height: var(--thumb-size);

  :focus {
    outline: none;
  }

  &[data-focus-visible-added]:focus {
    /* Chrome, Safari, Opera and Edge */
    ::-webkit-slider-thumb {
      border: 2px solid ${SHARED_PALETTE.focus};
    }

    /*********** Firefox styles ***********/
    ::-moz-range-thumb {
      border: 2px solid ${SHARED_PALETTE.focus};
    }
  }

  /* Chrome, Safari, Opera and Edge */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;

    background-color: var(--thumb-color);
    border-radius: 50%;
    height: var(--thumb-size);
    width: var(--thumb-size);
  }

  /* Firefox */
  ::-moz-range-thumb {
    border: none;

    background-color: var(--thumb-color);
    border-radius: 50%;
    height: var(--thumb-size);
    width: var(--thumb-size);
  }
`;

export const RangeContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const RootContainer = styled.div<{
  progress: number;
  themeColor?: string;
}>`
  ${horizontalStackCss.xs};
  align-items: center;
  --thumb-size: 20px;
  --track-height: 8px;
  --ticks-size: calc(var(--track-height) - 2px);

  ${({ themeColor }) =>
    css`
      --thumb-color: ${themeColor
        ? hex2rgba(themeColor)
        : "rgba(1, 134, 105, 1)"};
      --track-color: ${themeColor
        ? hex2rgba(themeColor, 0.1)
        : "rgba(1, 134, 105, 0.1)"};
      --ticks-color: ${themeColor
        ? hex2rgba(themeColor, 0.6)
        : "rgba(1, 134, 105, 0.6)"};
      --progress-color: ${themeColor
        ? hex2rgba(themeColor)
        : "rgba(1, 134, 105, 1)"};
    `};

  --thumb-offset: calc((var(--thumb-size) - var(--track-height)) / 2);
  --progress: ${({ progress }) => progress}%;

  padding-top: ${spacing.xxxl};
`;
