import { AvatarSize } from "@components/Avatar";
import {
  causeIllustrationLabelContainerCss,
  textSizeForIllustSize,
} from "@components/CauseIllustration/CauseIllustrationLabel";
import { CauseIllustrationSize } from "@components/CauseIllustration/types";
import { TagIllustrationOld } from "@components/TagIllustration";
import { css } from "@emotion/react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { CauseCategory } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { objectFromEnumValues } from "@every.org/common/src/helpers/objectUtilities";

import { Link, LinkProps } from "src/components/Link";
import { colorCssVars } from "src/theme/color";
import { FontWeight, textSizeCss } from "src/theme/text";

const TAG_LABEL_CSS = objectFromEnumValues({
  enum: CauseCategory,
  mapFn: (causeCategory) =>
    css`
      color: var(${colorCssVars.causeCategory[causeCategory].small});
    `,
});

interface TagLabelProps {
  tag: Pick<
    TagResponse,
    "causeCategory" | "tagName" | "title" | "tagImageCloudinaryId"
  >;
  className?: string;
  uncolored?: boolean;
}

export const TagLabel: React.FCC<TagLabelProps> = ({
  tag,
  className,
  uncolored,
}) => {
  return (
    <span
      className={className}
      css={[
        !uncolored && TAG_LABEL_CSS[tag.causeCategory],
        css`
          font-weight: ${FontWeight.MEDIUM};
        `,
      ]}
    >
      {tag.title}
    </span>
  );
};

interface TagIllustrationLabelProps extends TagLabelProps {
  size?: CauseIllustrationSize;
  imageAlt?: string;
}

export const TagIllustrationLabel: React.FCC<TagIllustrationLabelProps> = ({
  tag,
  size = AvatarSize.MEDIUM,
  className,
  uncolored,
  imageAlt,
}) => {
  return (
    <div css={causeIllustrationLabelContainerCss[size]} className={className}>
      <TagIllustrationOld
        tagName={tag.tagName}
        tagImageCloudinaryId={tag.tagImageCloudinaryId}
        causeCategory={tag.causeCategory}
        size={size}
        disableLink
        imageAlt={imageAlt}
      />
      <TagLabel
        tag={tag}
        className={className}
        css={textSizeCss[textSizeForIllustSize[size]]}
        uncolored={uncolored}
      />
    </div>
  );
};
interface TagLinkProps extends Omit<LinkProps, "to" | "ref"> {
  tagName: TagResponse["tagName"];
}

export const TagLink: React.FCC<TagLinkProps> = ({ tagName, ...rest }) => {
  return (
    <Link
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: tagName },
      })}
      {...rest}
    />
  );
};
