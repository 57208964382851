import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Link, LinkProps } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

const TabNumber = styled.span`
  ${textSizeCss.xs};
  font-weight: ${FontWeight.BOLD};
`;

export const NavigationTab = styled.span<{
  selected?: boolean;
  textSize?: TextSize;
}>`
  position: relative;
  ${({ textSize }) => (textSize ? textSizeCss[textSize] : "")};
  font-weight: ${FontWeight.MEDIUM};

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 2px solid var(${colorCssVars.accent.small});
          padding-bottom: 4px;
          margin-bottom: -4px;

          ${TabNumber} {
            color: var(${colorCssVars.accent.small});
          }
        `
      : css`
          color: var(${colorCssVars.text.secondary});

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -6px;
            left: 0;
            background-color: var(${colorCssVars.accent.small});
            transform: scaleX(0);
            transition: transform 0.2s cubic-bezier(0.2, 0.7, 0.6, 1);
          }

          &:hover {
            color: var(${colorCssVars.text.body});
            &:before {
              transform: scaleX(1);
            }
          }
        `}
`;

export const DefaultTabNavigation = styled.ul`
  ${horizontalStackCss.l};
  justify-content: center;
  width: 100%;
  ${cssForMediaSize({
    max: MediaSize.SMALL,
    css: horizontalStackCss.m,
  })};

  ${NavigationTab} {
    ${textSizeCss.m}
    ${cssForMediaSize({
      max: MediaSize.SMALL,
      css: textSizeCss.s,
    })}
  }
`;

export interface TabHeaderProps extends Omit<LinkProps, "ref"> {
  currentTab: string;
  selectedTab: string;
  textSize?: TextSize;
  count?: number;
}

export const TabHeader: React.FCC<TabHeaderProps> = ({
  currentTab,
  selectedTab,
  textSize,
  count,
  children,
  className,
  "data-tname": dataTName,
  ...rest
}) => {
  if (currentTab === selectedTab) {
    return (
      <NavigationTab
        textSize={textSize}
        selected
        className={className}
        data-tname={dataTName}
      >
        {children} {count ? <TabNumber>{count}</TabNumber> : undefined}
      </NavigationTab>
    );
  }
  return (
    <NavigationTab
      textSize={textSize}
      className={className}
      data-tname={dataTName}
    >
      <Link
        appearance={LinkAppearance.UNSTYLED}
        data-tname={`${dataTName}_link`}
        {...rest}
      >
        {children} {count ? <TabNumber>{count}</TabNumber> : undefined}
      </Link>
    </NavigationTab>
  );
};
