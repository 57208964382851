import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { LinkAppearance } from "@components/Link";
import {
  HeaderContainer,
  HeaderText,
  topBorderCss,
} from "@components/NewFundraiserCard/styles";
import { UserLink } from "@components/UserLink";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";
import { isOfficialFundraiser } from "@every.org/common/src/helpers/fundraisers";

import { useUser } from "src/context/UsersContext/hooks";
import { userOrUndefined } from "src/context/UsersContext/selectors";

export enum FundraiserCardHeaderSectionAppearance {
  DEFAULT = "DEFAULT",
  ALTERNATIVE = "ALTERNATIVE",
}

export const FundraiserCardHeaderSection = ({
  fundraiser,
  nonprofit,
  afterHeaderElement,
  showBorder,
  appearance = FundraiserCardHeaderSectionAppearance.DEFAULT,
}: {
  fundraiser: FundraiserResponse;
  nonprofit: NonprofitResponse;
  afterHeaderElement?: React.ReactNode;
  showBorder?: boolean;
  appearance?: FundraiserCardHeaderSectionAppearance;
}) => {
  const alternativeAppearance =
    appearance === FundraiserCardHeaderSectionAppearance.ALTERNATIVE;
  const user = userOrUndefined(
    useUser(
      fundraiser.creatorUserId ? { id: fundraiser.creatorUserId } : undefined
    )
  );

  const fromUser = !isOfficialFundraiser(fundraiser) ? user : undefined;

  const text = fromUser ? (
    <span>
      Fundraiser by{" "}
      <UserLink
        user={fromUser}
        appearance={LinkAppearance.HYPERLINK_UNCOLORED}
        contentCss={{ display: "inline" }}
        isTextLink
      />
    </span>
  ) : (
    <span>Official fundraiser</span>
  );

  if (!user && !isOfficialFundraiser(fundraiser)) {
    return null;
  }

  return (
    <HeaderContainer css={showBorder && topBorderCss}>
      <NonprofitAvatar
        nonprofit={nonprofit}
        size={alternativeAppearance ? AvatarSize.XX_SMALL : AvatarSize.SMALL}
      />
      <HeaderText>
        {!alternativeAppearance && <h5>{nonprofit.name}</h5>}
        {text}
      </HeaderText>
      {afterHeaderElement}
    </HeaderContainer>
  );
};
