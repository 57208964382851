import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { CloudinaryImg } from "@components/CloudinaryImg";
import { IconSize } from "@components/Icon";
import { AddTipCustom } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/AddTipCustom";
import { AddTipHeader } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/AddTipHeader";
import { AddTipSlider } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/AddTipSlider";
import { getInitialSliderValues } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/DefaultTipSlider";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import {
  DonationSummary,
  DonationSummaryProps,
} from "@components/donate/DonateV3/PaymentProcess/components/DonationSummary";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { useState } from "react";

import { colorPalette } from "@every.org/common/src/display/palette";
import { ImageFormat } from "@every.org/common/src/helpers/cloudinary";

import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";
import { getWindow } from "src/utility/window";

//Star icon id - assets/icons/star_b5xiya
//Heart icon id - assets/icons/heart_pihda8
const THUMBS_UP_ICON_ID = "assets/icons/thumbs_up_g3c7zk";

const TipMessageCopyExperiment = () => {
  const message = useStatSigLayer("tip_copy_layer")?.get(
    "message",
    "Thank you for keeping this fundraising platform free."
  );
  return <p>{message}</p>;
};

const TipMessageIconExperiment = () => {
  const icon =
    useStatSigLayer("tip_copy_layer")?.get<string>("icon") || THUMBS_UP_ICON_ID;
  return (
    <CloudinaryImg
      cloudinaryId={icon}
      layout="fixed"
      imageFormat={ImageFormat.svg}
      iconSize={IconSize.X_LARGE}
    />
  );
};

const TipMessage = styled.div`
  ${horizontalStackCss.s};
  align-items: center;
  ${textSizeCss[TextSize.xs]};
  padding: ${spacing.m};
  font-weight: ${FontWeight.MEDIUM};
  background-color: #e5f2f0;
  border-radius: ${spacing.xs};
`;

type AddTipAndDonationSummaryProps = AddTipProps &
  Omit<DonationSummaryProps, "onTipEdit">;

export const AddTipAndDonationSummary = (
  props: AddTipAndDonationSummaryProps
) => {
  const { formContext, form } = props;
  const { watch } = form;
  const tipAmount = watch("amountToEveryOrg") || 0;
  const tipAmountBig = new Big(tipAmount);

  const { initialShowSlider } = getInitialSliderValues(
    formContext.donationTipPercent
  );
  const { enableTipping } = formContext;
  const [showSlider, setShowSlider] = useState(initialShowSlider);

  const isZeroTip = tipAmountBig.eq(0);

  const summaryOnEdit = () => {
    setShowSlider(false);
    getWindow()
      ?.document.getElementById("donateAmountToEveryOrgInput")
      ?.focus();
  };

  return (
    <React.Fragment>
      {enableTipping && (
        <div css={verticalStackCss.xs}>
          <AddTipHeader />
          {showSlider ? (
            <AddTipSlider {...props} isZeroTip={isZeroTip} />
          ) : (
            <AddTipCustom {...props} />
          )}
          <Button
            css={[{ alignSelf: "flex-end" }]}
            contentCss={isZeroTip && { color: colorPalette.grayMedium }}
            data-tname={showSlider ? "EnterCustomAmount" : "UseSlider"}
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: (e) => setShowSlider(!showSlider),
            }}
          >
            {showSlider ? "Enter custom amount" : "Use slider"}
          </Button>

          {!isZeroTip && (
            <TipMessage>
              <TipMessageIconExperiment />
              <TipMessageCopyExperiment />
            </TipMessage>
          )}
        </div>
      )}
      <DonationSummary {...props} onTipEdit={summaryOnEdit} />
    </React.Fragment>
  );
};
