import { avatarSizeToPx } from "@components/Avatar";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "src/theme/spacing";

export const CoverContainer = styled.div<{ rounded?: boolean }>`
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 16px 16px 0 0;
    `}

  overflow: hidden;
`;

export const bottomLeftCss = css`
  position: absolute;
  bottom: ${spacing.m};
  left: ${spacing.m};
  display: inline-flex;
  /* 100% - padding - nonprofit avatar on the left - extra spacing between */
  width: calc(100% - ${spacing.m} * 2 - ${avatarSizeToPx.M}px - ${spacing.xs});
`;

export const bottomRightCss = css`
  position: absolute;
  bottom: ${spacing.m};
  right: ${spacing.m};
  display: inline-flex;
`;

export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;
