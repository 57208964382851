import { TypeOf } from "io-ts";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { nanoid } from "nanoid/async";

import { decodeOrThrow } from "../codecs";

/**
 * Generates a nonce.
 *
 * Use cases:
 * - CSRF protection in Auth0 login flow via the `state`
 * parameter, and as a key for redirection on login.
 * - Prevent duplicate donation charge.
 */
export async function generateNonce(): Promise<TypeOf<typeof NonEmptyString>> {
  return decodeOrThrow(NonEmptyString, await nanoid());
}
