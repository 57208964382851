/**
 * Component for a cause category with the corresponding illustration.
 */
import { AvatarSize } from "@components/Avatar";
import {
  CauseIllustrationProps,
  CauseIllustrationSize,
} from "@components/CauseIllustration/types";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import React from "react";

import {
  CauseMetadata,
  CauseCategory,
} from "@every.org/common/src/entity/types";
import {
  mapValues,
  objectFromEnumValues,
} from "@every.org/common/src/helpers/objectUtilities";

import { CauseIllustration } from "./";

import { colorCssVars } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss, FontWeight } from "src/theme/text";

export const causeIllustrationLabelContainerCss: {
  [key in CauseIllustrationSize]: CSSInterpolation;
} = mapValues({
  obj: {
    [AvatarSize.XX_SMALL]: horizontalStackCss.xs,
    [AvatarSize.X_SMALL]: horizontalStackCss.xs,
    [AvatarSize.SMALL]: horizontalStackCss.xs,
    [AvatarSize.MEDIUM_SMALL]: horizontalStackCss.s,
    [AvatarSize.MEDIUM]: horizontalStackCss.oldM,
    [AvatarSize.LARGE]: horizontalStackCss.m,
  },
  mapper: (style) => [style, { alignItems: "center" }],
});

export const textSizeForIllustSize: {
  [key in CauseIllustrationSize]: TextSize;
} = {
  [AvatarSize.XX_SMALL]: TextSize.s,
  [AvatarSize.X_SMALL]: TextSize.s,
  [AvatarSize.SMALL]: TextSize.m,
  [AvatarSize.MEDIUM_SMALL]: TextSize.m,
  [AvatarSize.MEDIUM]: TextSize.l,
  [AvatarSize.LARGE]: TextSize.xl,
};

interface CauseLabelProps {
  cause: CauseCategory;
  className?: string;
}

const CAUSE_LABEL_CSS = objectFromEnumValues({
  enum: CauseCategory,
  mapFn: (category) =>
    css`
      color: var(${colorCssVars.causeCategory[category].small});
      font-weight: ${FontWeight.MEDIUM};
    `,
});

/**
 * Renders a cause's corresponding title colored according to the cause
 */
export const CauseLabel = ({ cause, className }: CauseLabelProps) => (
  <span css={CAUSE_LABEL_CSS[cause]} className={className}>
    {CauseMetadata[cause].title}
  </span>
);

interface LabeledCauseIllustrationProps
  extends CauseLabelProps,
    Omit<CauseIllustrationProps, "size" | "cause"> {
  size?: CauseIllustrationSize;
}

export const LabeledCauseIllustration: React.FCC<
  LabeledCauseIllustrationProps
> = ({ cause, size = AvatarSize.LARGE, className, ...rest }) => {
  return (
    <div css={causeIllustrationLabelContainerCss[size]} className={className}>
      {cause !== CauseCategory.UNKNOWN && (
        <CauseIllustration cause={cause} size={size} {...rest} />
      )}
      <CauseLabel
        css={textSizeCss[textSizeForIllustSize[size]]}
        cause={cause}
      />
    </div>
  );
};
