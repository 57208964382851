import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import {
  EnterKeyHintType,
  TextInput,
  TextInputProps,
  TextInputType,
} from "@components/TextInput";
import { useContext, useState, useTransition } from "react";

import {
  DirectoryFilterContext,
  DirectoryPendingContext,
} from "src/pages/Directory/context";
import { spacing } from "src/theme/spacing";

interface DirectoryFilterSearchInputProps
  extends Omit<TextInputProps, "onChange" | "data-tname"> {}
export function DirectoryFilterSearchInput(
  props: DirectoryFilterSearchInputProps
) {
  const { query, onQueryChanged } = useContext(DirectoryFilterContext);
  const { setPending } = useContext(DirectoryPendingContext);

  const [inputValue, setInputValue] = useState(query ?? "");
  const [, localStartTransition] = useTransition();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    // to show loading before filtering transition starts;
    setPending(true);
    localStartTransition(() => {
      onQueryChanged(value);
    });
  };

  return (
    <TextInput
      enterKeyHint={EnterKeyHintType.SEARCH}
      data-tname="DirectorySearchInput"
      onChange={onChange}
      type={TextInputType.SEARCH}
      value={inputValue}
      placeholder="Search by name or location"
      inputPrefix={
        <Icon
          iconImport={() => import("@components/Icon/icons/SearchIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.SECONDARY}
          css={{ marginRight: spacing.xs }}
        />
      }
      setInputPrefixColorAndHeight={false}
      inputSuffix={
        !!query && (
          <Button
            title="Cancel search"
            data-tname="SearchTextInput--clear"
            role={ButtonRole.UNSTYLED}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: (e) => {
                e.stopPropagation();
                if (inputValue.length > 0) {
                  onQueryChanged("");
                  setInputValue("");
                }
              },
            }}
          >
            <Icon
              iconImport={() => import("@components/Icon/icons/XIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.ACCENT}
            />
          </Button>
        )
      }
      collapseDescriptionSpace
      {...props}
    />
  );
}
