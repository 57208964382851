import { useAllCauses } from "@components/Tags/TagSelector";
import { isLeft } from "fp-ts/Either";
import Fuse from "fuse.js";
import { UUID } from "io-ts-types";
import { useRouter } from "next/router";
import { useState, useCallback, useMemo, useEffect, useContext } from "react";

import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import { DIRECTORY_TAGS_ALLOW_SETS } from "@every.org/common/src/entity/constants";
import {
  DirectoryFilterSearchParam,
  NonprofitRevenueSize,
  nonprofitRevenueSizeCodec,
} from "@every.org/common/src/entity/types";
import { reporter } from "@every.org/common/src/errors/IotsCodecError";
import {
  decodeCausesUrlQuery,
  joinCauses,
  splitCauses,
} from "@every.org/common/src/helpers/causes";
import {
  ClientRouteName,
  DONATE_HASH,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { getRevenue } from "@every.org/common/src/helpers/nonprofit";

import { fetchNonprofitProjects } from "src/context/NonprofitsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import {
  DirectoryFilterContext,
  DirectoryPendingContext,
} from "src/pages/Directory/context";
import { logger } from "src/utility/logger";

const PROJECTS_ON_PAGE = 30;

type DirectoryFilterOptions = {
  query?: string;
  causes: string[];
  size?: NonprofitRevenueSize;
};

export function useProjects(nonprofit: ContextNonprofit) {
  const [page, setPage] = useState(1);
  const [allProjects, setAllProjects] = useState<NonprofitResponse[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<NonprofitResponse[]>(
    []
  );

  const { pending, setPending, startTransition } = useContext(
    DirectoryPendingContext
  );
  const [error, setError] = useState<string>();
  const tags = useAllCauses();
  const tagIdToTagNameMap = useMemo(
    () => new Map(tags.map((tag) => [tag.id, tag.tagName])),
    [tags]
  );
  const asyncOperation = useCallback(async () => {
    setPending(true);
    return (await fetchNonprofitProjects(nonprofit.id)).nonprofits;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonprofit.id]);

  const handleResponse = useCallback((projects: NonprofitResponse[]) => {
    setAllProjects(projects);
    // stop fetching only if no projects
    // otherwise stop after filtering
    if (projects.length === 0) {
      setPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = useCallback((e: Error) => {
    setError(e.message);
    setPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAsyncEffect({
    asyncOperation,
    handleResponse,
    handleError,
  });

  const { query, size, causes } = useContext(DirectoryFilterContext);

  useEffect(() => {
    // filter only after all projects fetched
    if (pending && allProjects.length === 0) {
      return;
    }

    startTransition(() => {
      const filtered = filterProjects(allProjects, {
        query,
        size,
        causes,
        tagIdToTagNameMap,
      });
      const sorted = sortProjects(filtered);

      setFilteredProjects(sorted);
      setPending(false);
      setPage(1);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjects, query, size, causes]);

  const filteredProjectsForPage = useMemo(
    () => filteredProjects.slice(0, PROJECTS_ON_PAGE * page),
    [filteredProjects, page]
  );

  const hasMore =
    !pending &&
    Boolean(allProjects.length) &&
    Boolean(filteredProjects.length) &&
    filteredProjects.length > filteredProjectsForPage.length;

  const onViewMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const tagCounts = useMemo(() => {
    const allowSet = DIRECTORY_TAGS_ALLOW_SETS.get(nonprofit.id);

    const map = new Map<string, number>();
    allProjects.forEach((project) => {
      project.tags?.forEach((tagId) => {
        const tagName = tagIdToTagNameMap.get(tagId);
        if (tagName) {
          if (allowSet ? allowSet.has(tagName) : true) {
            const prev = map.get(tagName) ?? 0;
            const next = prev + 1;
            map.set(tagName, next);
          }
        }
      });
    });
    return map;
  }, [allProjects, nonprofit.id, tagIdToTagNameMap]);

  return {
    allProjects,
    filteredProjects: filteredProjectsForPage,
    error,
    onViewMore,
    tagCounts,
    hasMore,
    setFetching: setPending,
  };
}

export function useDirectoryFilterController({
  nonprofitSlug,
}: {
  nonprofitSlug: ContextNonprofit["primarySlug"];
}) {
  const router = useRouter();
  const { hash } = useEdoRouter();
  const urlSearchOptions = useDirectoryFilterOptionsFromUrl();
  const { startTransition } = useContext(DirectoryPendingContext);
  const [changesToApply, setChangesToApply] =
    useState<DirectoryFilterOptions>(urlSearchOptions);

  useEffect(() => {
    () => setChangesToApply(urlSearchOptions);
  }, [startTransition, urlSearchOptions]);

  const onQueryChanged = useCallback((query?: string) => {
    setChangesToApply((prev) => ({
      ...prev,
      query,
    }));
  }, []);

  const onSizeChange = useCallback((size?: NonprofitRevenueSize) => {
    () => setChangesToApply((prev) => ({ ...prev, size }));
  }, []);

  const onCausesChange = useCallback((causes?: string[]) => {
    setChangesToApply((prev) => ({ ...prev, causes: causes ?? [] }));
  }, []);

  const clearFilters = useCallback(() => {
    setChangesToApply({ causes: [] });
  }, []);

  const onApplyChanges = useCallback(() => {
    const routeQuery = directoryFilterOptionsToUrlQuery(changesToApply);

    const newPath = getRoutePath({
      format: URLFormat.RELATIVE,
      name: ClientRouteName.NONPROFIT_OR_CAUSE,
      tokens: { nonprofitSlug },
      query: routeQuery,
    });

    const isOpenDonatePage =
      hash.includes(DONATE_HASH) || hash.includes(DONATE_HASH.substring(1));

    !isOpenDonatePage && router.replace(newPath, undefined, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesToApply, nonprofitSlug]);

  useEffect(() => {
    onApplyChanges();
  }, [onApplyChanges]);

  return {
    ...changesToApply,
    onQueryChanged,
    onSizeChange,
    onCausesChange,
    clearFilters,
    onApplyChanges,
  };
}

function useDirectoryFilterOptionsFromUrl(): DirectoryFilterOptions {
  const router = useEdoRouter();
  const { query, causes, size } = getDirectoryFilterOptions(router.search);

  return useMemo(() => {
    return {
      causes: splitCauses(causes) ?? [],
      query,
      size,
    };
  }, [query, size, causes]);
}

// utils
function directoryFilterOptionsToUrlQuery(
  params: DirectoryFilterOptions
): Record<string, string> {
  const { query, causes, size } = params;
  return Object.fromEntries(
    Object.entries({
      [DirectoryFilterSearchParam.SEARCH_TERM]: query,
      [DirectoryFilterSearchParam.CAUSES]: joinCauses(causes),
      [DirectoryFilterSearchParam.ORGANIZATION_SIZE]: size,
    })
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, `${value}`])
  );
}

function getDirectoryFilterOptions(search: string) {
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get(DirectoryFilterSearchParam.SEARCH_TERM) || "",
    causes = decodeCausesUrlQuery(
      searchParams.get(DirectoryFilterSearchParam.CAUSES)
    ),
    sizeRaw =
      searchParams.get(DirectoryFilterSearchParam.ORGANIZATION_SIZE) ||
      undefined;

  const decodedSize = sizeRaw
    ? nonprofitRevenueSizeCodec.decode(sizeRaw)
    : undefined;
  if (decodedSize && isLeft(decodedSize)) {
    logger.warn({
      message: "Invalid organization size provided to directory filter",
      data: { size: sizeRaw, errors: reporter(decodedSize) },
    });
  }
  const size =
    decodedSize && (isLeft(decodedSize) ? undefined : decodedSize.right);

  return {
    query,
    causes,
    size,
  };
}

function filterProjects(
  projects: NonprofitResponse[],
  options: DirectoryFilterOptions & {
    tagIdToTagNameMap: Map<UUID, string>;
  }
): Array<NonprofitResponse & { score?: number }> {
  let newProjects = projects;

  if (options.query) {
    const fuse = new Fuse(newProjects, {
      keys: ["name", "locationAddress", "primarySlug"],
      // A score of `0` indicates a perfect match, while a score of `1` indicates a complete mismatch.
      includeScore: true,
      threshold: 0.4,
    });
    newProjects = fuse.search(options.query).map(({ item, score }) => ({
      ...item,
      // reverse the score so `1` indicates a perfect match, `0` indicates a complete mismatch
      // and multiply by 10 to give more weight for more relevant items
      score: (1 - (score || 1)) * 10,
    }));
  }

  if (options.size) {
    newProjects = newProjects.filter(({ revenueAmt }) =>
      revenueAmt ? getRevenue(revenueAmt) === options.size : false
    );
  }

  if (options.causes && options.causes.length > 0) {
    newProjects = newProjects.filter(({ tags = [] }) => {
      for (const tag of tags) {
        const tagName = options.tagIdToTagNameMap.get(tag);
        if (tagName && options.causes.includes(tagName)) {
          return true;
        }
      }
      return false;
    });
  }
  return newProjects;
}

function sortProjects(
  projects: Array<NonprofitResponse & { score?: number }>
): NonprofitResponse[] {
  const projectsWithScore = projects.map((project) => {
    let score = project.score || 0;

    const hasPictures =
      !!project.logoCloudinaryId && !!project.coverImageCloudinaryId;

    score += project.hasAdmin ? 0.5 : 0;
    score += hasPictures ? 0.5 : 0;
    score += (project.supporterInfo?.numSupporters || 0) * 0.1;
    score += (project.likesInfo?.count || 0) * 0.01;

    return { ...project, score };
  });

  return projectsWithScore.sort((a, b) => b.score - a.score);
}
