import { Icon, IconSize, IconDisplay } from "@components/Icon";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const Container = styled.div`
  ${verticalStackCss.xxs};
  p {
    padding-left: ${spacing.xl};
  }
  .headline {
    ${horizontalStackCss.xs};
    align-items: center;
    h6 {
      ${textSizeCss.s}
    }
  }
`;

export function FGCNoMatchAvaliable() {
  return (
    <Container>
      <div className="headline">
        <Icon
          iconImport={() => import("@components/Icon/icons/AlertIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.TEXT}
        />
        <h6>No match available</h6>
      </div>
      <p>
        Either you already have had a matched recurring donation for this
        nonprofit or you have already had 3 total donations matched.
      </p>
    </Container>
  );
}
