import { AvatarSize } from "@components/Avatar";

import { ValidCauseCategory } from "@every.org/common/src/entity/types";

export const FIT_PARENT = Symbol();

export type CauseIllustrationSize = Exclude<
  AvatarSize,
  AvatarSize.X_LARGE | AvatarSize.XX_LARGE | AvatarSize.XXX_LARGE
>;
const categoryIllustrationSizesRecord: Record<CauseIllustrationSize, 1> = {
  [AvatarSize.XX_SMALL]: 1,
  [AvatarSize.X_SMALL]: 1,
  [AvatarSize.SMALL]: 1,
  [AvatarSize.MEDIUM_SMALL]: 1,
  [AvatarSize.MEDIUM]: 1,
  [AvatarSize.LARGE]: 1,
};
export const categoryIllustrationSizes = new Set(
  Object.keys(categoryIllustrationSizesRecord) as CauseIllustrationSize[]
);

export interface CauseIllustrationProps
  extends Omit<React.SVGProps<SVGSVGElement>, "ref"> {
  /**
   * Category to display
   */
  cause: ValidCauseCategory;
  /**
   * @default false
   */
  grayscale?: boolean;
  size: CauseIllustrationSize | typeof FIT_PARENT;
}
