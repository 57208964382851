import { Big } from "big.js";

import { CurrencyValue } from "../codecs/currency";
import { SafeInt } from "../codecs/number";
import { Currency } from "../entity/types";

const DEFAULT_LOCALE = "en-US";

export const DECIMAL_OFFSET_FOR_CURRENCY: { [key in Currency]: number } = {
  [Currency.USD]: 2,
  [Currency.EUR]: 2,
  [Currency.GBP]: 2,
  [Currency.AUD]: 2,
  [Currency.HKD]: 2,
  [Currency.CAD]: 2,
  [Currency.JPY]: 0,
  [Currency.CHF]: 2,
  [Currency.NZD]: 2,
};

/**
 * Represent a currency value displayed with decimal points, as a number in the
 * minimum denomination of that currency. Rounds values that have too much
 * precision.
 *
 * This function will no longer be needed once `CurrencyValue.amount` contains a
 * minimum precision integer throughout the app.
 *
 * @example
 * const amountInMinDenom = currencyValueToMinimumDenominationAmount({
 *   currency: Currency.USD, amount: new Big(5.25)
 * });
 * expect(amountInMinDenom).toBe("525"); // cents
 */
export function currencyValueToMinimumDenominationAmount(params: {
  value: CurrencyValue;
}): number {
  const {
    value: { amount, currency },
  } = params;
  return parseInt(
    amount.mul(Math.pow(10, DECIMAL_OFFSET_FOR_CURRENCY[currency])).toFixed(0)
  );
}

export type MinDenomCurrencyValue = {
  currency: Currency;
} & ({ amountInMinDenom: SafeInt } | { amount: SafeInt });

/**
 * Represent a number in the minimum denomination of a currency, as a currency
 * value whose amount is encoded in the display format of that currency.
 *
 * @example
 * const value = minimumDenominationAmountToCurrencyValue({
 *   currency: Currency.USD, amountInMinDenom: 525
 * });
 * expect(value.currency).toBe(Currency.USD);
 * expect(value.amount.eq(new Big(5.25))).toBe(true);
 */
export function minimumDenominationAmountToCurrencyValue(
  params: MinDenomCurrencyValue
): CurrencyValue {
  const { currency } = params;
  const amount =
    "amountInMinDenom" in params ? params.amountInMinDenom : params.amount;
  return {
    currency: params.currency,
    amount: new Big(
      amount / Math.pow(10, DECIMAL_OFFSET_FOR_CURRENCY[currency])
    ),
  };
}

export function isPositive(
  currencyValue?: MinDenomCurrencyValue
): currencyValue is MinDenomCurrencyValue {
  return !!(
    currencyValue &&
    ("amountInMinDenom" in currencyValue
      ? currencyValue.amountInMinDenom > 0
      : currencyValue.amount > 0)
  );
}

export interface DisplayCurrencyValueParams {
  value: CurrencyValue;
  forceShowCents?: boolean;
  minimumSignificantDigits?: number;
  locale?: string;
  showCurrency?: boolean;
}

export enum DisplayCurrencyError {
  OUT_OF_BOUNDS = "OUT_OF_BOUNDS",
}

/**
 * Displays a currency amount in a properly formatted locale string.
 *
 * @param forceShowCents if true, always shows cent values
 * @example $5.20 always shows as $5.20
 * @example $5.00 shows as $5 if forceShowCents is false, or $5.00 if true
 *
 * @param showCurrency if true, shows the currency at the end
 * @example true => '$5.20 USD'; false => '$5.20'
 *
 * @param minimumSignificantDigits if set, forces the value to have the specified
 * precision if it would be otherwise 0.
 * @example 2 => '0.000123' => '0.00012'
 */
export function displayCurrencyValue({
  locale = DEFAULT_LOCALE,
  forceShowCents,
  showCurrency,
  minimumSignificantDigits,
  value: { currency, amount },
}: DisplayCurrencyValueParams): {
  formatted: string;
  error: DisplayCurrencyError | null;
} {
  const error =
    amount.gt(Number.MAX_SAFE_INTEGER) || amount.lt(Number.MIN_SAFE_INTEGER)
      ? DisplayCurrencyError.OUT_OF_BOUNDS
      : null;

  const amountStr = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumSignificantDigits,
  }).format(parseFloat(amount.toString()));

  // remove 0 cents if present, tolerant to different locale formats
  // https://stackoverflow.com/a/49724581
  const formatted = `${
    forceShowCents ? amountStr : amountStr.replace(/\D00(?=\D*$)/, "")
  }${showCurrency ? ` ${currency}` : ""}`;

  return {
    formatted,
    error,
  };
}

/**
 * Convert a Big to a DECIMAL as stored in the database. If precision too high,
 * Postgres will throw an error when you try to save it.
 *
 * @param params scale refers to number of digits past the decimal place. Must
 * be integer greater than 0.
 */
export function bigToDecimal(params: { value: Big; scale: number }): string {
  const { value, scale } = params;
  if (!Number.isInteger(scale) || scale < 1) {
    throw new Error(`Invalid scale: must be integer > 0. Got ${scale}`);
  }

  return value.toFixed(scale);
}
