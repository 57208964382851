import * as t from "io-ts";
import React from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import {
  getTrendingCausesRouteSpec,
  getTagRouteSpec,
} from "@every.org/common/src/routes/publicCached";
import {
  newTagRouteSpec,
  updateTagRouteSpec,
  suggestTaggingNonprofitsRouteSpec,
  nonprofitTagPendingTagsEditsRouteSpec,
  NonprofitTagPendingTagsEditsParams,
} from "@every.org/common/src/routes/tag";

import { addNonprofits } from "src/context/NonprofitsContext";
import { dispatchTagsAction } from "src/context/TagContext";
import {
  TagActionType,
  TagIdentifier,
  TagsAction,
} from "src/context/TagContext/types";
import { addUsers } from "src/context/UsersContext/actions";
import { is400LevelApiError } from "src/errors/ApiError";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
/**
 * Fetches a tag identified by tag name.
 */
export async function fetchTag(
  dispatchTagsAction: React.Dispatch<TagsAction>,
  identifier: TagIdentifier,
  onlyActive = true
) {
  const identifierValue: string = identifier["id"] || identifier["tagName"];

  dispatchTagsAction({
    type: TagActionType.FETCHING_TAG,
    data: identifier,
  });

  try {
    const { tag } = await queryApi(getTagRouteSpec, {
      routeTokens: { identifier: identifierValue },
      body: {},
      queryParams: { onlyActive: onlyActive },
    });

    dispatchTagsAction({
      type: TagActionType.ADD_TAG,
      data: { ...tag },
    });

    return tag;
  } catch (e) {
    // TODO: we should set the status to error instead of not found if we get
    // here.
    if (is400LevelApiError(e)) {
      logger.info({
        message: "A 400-level error occurred fetching a tag",
        error: e,
        data: { httpStatus: e.httpStatus },
      });
    } else {
      logger.error({
        message: "An error occurred fetching a tag.",
        error: e,
      });
    }
  }

  dispatchTagsAction({
    type: TagActionType.TAG_NOT_FOUND,
    data: identifier,
  });
  return undefined;
}

export async function fetchTrendingTags(
  dispatchTagsAction: React.Dispatch<TagsAction>,
  take: TakeInt,
  skip: SkipInt,
  pastDays?: TakeInt // Temporal type that works. Replace with proper bounded int
) {
  const queryParams = {
    take,
    skip,
  };
  dispatchTagsAction({
    type: TagActionType.FETCHING_TRENDING_TAGS,
    data: undefined,
  });
  try {
    const responseData = await queryApi(getTrendingCausesRouteSpec, {
      routeTokens: {},
      body: {},
      queryParams,
    });
    dispatchTagsAction({
      type: TagActionType.ADD_TRENDING_TAGS,
      data: responseData,
    });
    responseData.tags && addTrendingTags(dispatchTagsAction, responseData);
    return { trendingTags: responseData.tags, hasMore: responseData.hasMore };
  } catch (e) {
    // TODO: we should set the status to error instead of not found if we get
    // here.
    if (is400LevelApiError(e)) {
      logger.info({
        message: "A 400-level error occurred fetching the trending tags",
        error: e,
        data: { httpStatus: e.httpStatus },
      });
    } else {
      logger.error({
        message: "An error occurred fetching the trending tags.",
        error: e,
      });
    }
  }

  dispatchTagsAction({
    type: TagActionType.TRENDING_TAGS_NOT_FOUND,
    data: undefined,
  });
  return undefined;
}

/**
 * Creates a new trending cause page.
 */
type NewTrendingCauseRequestBody = t.TypeOf<typeof newTagRouteSpec.bodyCodec>;
export async function requestNewTrendingCause(
  dispatchTagsAction: React.Dispatch<TagsAction>,
  body: NewTrendingCauseRequestBody
) {
  const result = await queryApi(newTagRouteSpec, {
    routeTokens: {},
    body,
    queryParams: {},
  });
  dispatchTagsAction({
    type: TagActionType.ADD_TAG,
    data: result.tag,
  });
  addNonprofits(result.nonprofits);
}

/**
 * Updates a new trending cause page.
 */
type UpdateTrendingCauseRequestBody = t.TypeOf<
  typeof updateTagRouteSpec.bodyCodec
>;
export async function requestTrendingCauseUpdate(
  id: TagResponse["id"],
  body: UpdateTrendingCauseRequestBody
) {
  await queryApi(updateTagRouteSpec, {
    routeTokens: { tagId: id },
    body,
    queryParams: {},
  });
}

/**
 * Suggests a cause ~ nonprofit tagging / untagging.
 */
type SuggestTaggingNonprofitsRequestBody = t.TypeOf<
  typeof suggestTaggingNonprofitsRouteSpec.bodyCodec
>;
export async function suggestTaggingNonprofits(
  body: SuggestTaggingNonprofitsRequestBody
) {
  await queryApi(suggestTaggingNonprofitsRouteSpec, {
    routeTokens: {},
    body,
    queryParams: {},
  });
}

/**
 * Add tags data to the context.
 */
function addTrendingTags(
  dispatchTagsAction: React.Dispatch<TagsAction>,
  data: {
    tags: TagResponse[];
    hasMore: boolean;
  }
) {
  dispatchTagsAction({
    type: TagActionType.ADD_TRENDING_TAGS,
    data: data,
  });
}

export function addTags(data: TagResponse[]) {
  if (!dispatchTagsAction) {
    logger.warn({ message: "Dispatch is undefined for TagsContext." });
  }
  dispatchTagsAction({
    type: TagActionType.ADD_TAGS,
    data: data,
  });
}

export async function fetchTagsEdits(
  dispatchTagsAction: React.Dispatch<TagsAction>,
  queryParams: NonprofitTagPendingTagsEditsParams
) {
  const { edits, tags, nonprofits, hasMore, users } = await queryApi(
    nonprofitTagPendingTagsEditsRouteSpec,
    {
      routeTokens: {},
      queryParams,
      body: {},
    }
  );

  dispatchTagsAction({
    type: TagActionType.ADD_TRENDING_TAGS,
    data: { tags, hasMore },
  });
  addNonprofits(nonprofits);
  addUsers(users);

  return { edits, hasMore };
}
