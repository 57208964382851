import { SupportersListPresentation } from "@components/SupportersList/presentation";
import React, { useState, useEffect } from "react";

import { UserResponse } from "@every.org/common/src/codecs/entities";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";
import { GetNonprofitSupportersResponse } from "@every.org/common/src/routes/nonprofit";

import { ApiError } from "src/errors/ApiError";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";

export interface SupportersListProps {
  fetchQuery: (
    take: TakeInt,
    skip: SkipInt
  ) => Promise<GetNonprofitSupportersResponse>;
  onClick?: () => void;
  supporterCount: number;
  label?: string;
}

const defaultTake = 30;

export const SupportersList: React.FCC<SupportersListProps> = ({
  fetchQuery,
  onClick,
  supporterCount,
  label,
}) => {
  const [supporters, setSupporters] = useState<UserResponse[] | null>(null);
  const [moreItemsToFetch, setMoreItemsToFetch] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function fetchSupporters() {
      try {
        setFetching(true);
        const { users, hasMore } = await fetchQuery(
          defaultTake as TakeInt,
          (pageNum * defaultTake) as SkipInt
        );
        setSupporters([...(supporters || []), ...users]);
        setMoreItemsToFetch(hasMore);
      } catch (error) {
        setMoreItemsToFetch(false); // stops retries
        if (
          !(error instanceof ApiError) ||
          error.httpStatus !== HttpStatus.FORBIDDEN
        ) {
          logger.error({
            message:
              "An error occurred fetching supporters for the SupporterList modal.",
            error,
          });
        }
      } finally {
        setFetching(false);
      }
    }
    fetchSupporters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchQuery, pageNum]);

  return (
    <SupportersListPresentation
      hasMore={moreItemsToFetch}
      isFetching={fetching}
      onClickResult={onClick}
      onClickViewMore={() => setPageNum(pageNum + 1)}
      supporters={supporters}
      supporterCount={supporterCount}
      label={label}
    />
  );
};
