import { AchievementResponse } from "@every.org/common/src/codecs/entities";
import { SafeInt } from "@every.org/common/src/codecs/number";

export enum AchievementFetchStatus {
  NOT_FETCHED = "NOT_FETCHED",
  LOADING = "LOADING",
  READY = "READY",
}

export interface AchievementMap {
  [key: string]: AchievementResponse;
}

interface ReadyAchievementsState {
  status: AchievementFetchStatus.READY;
  achievements: AchievementMap;
  creditsEarned: SafeInt;
}

export type AchievementsState =
  | {
      status: Exclude<AchievementFetchStatus, AchievementFetchStatus.READY>;
    }
  | ReadyAchievementsState;

export type AchievementsContextResponse =
  | {
      status: Exclude<AchievementFetchStatus, AchievementFetchStatus.READY>;
    }
  | ({
      availableAchievements: AchievementResponse[];
      earnedAchievements: AchievementResponse[];
    } & ReadyAchievementsState);

export enum AchivementsActionType {
  FETCH = "FETCH",
  SET_ACHIEVEMENTS = "SET_ACHIEVEMENTS",
  SET_CREDITS_EARNED = "SET_CREDITS_EARNED",
}

interface FetchAchievements {
  type: AchivementsActionType.FETCH;
}

interface SetAchievements {
  type: AchivementsActionType.SET_ACHIEVEMENTS;
  achievements: AchievementResponse[];
}

interface SetCreditsEarned {
  type: AchivementsActionType.SET_CREDITS_EARNED;
  creditsEarned: SafeInt;
}

export type AchievementsAction =
  | FetchAchievements
  | SetAchievements
  | SetCreditsEarned;
