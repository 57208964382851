/**
 * Functions to validate User values.
 */
import { isEmail } from "@every.org/common/src/codecs/contact";
import { usernameCodec } from "@every.org/common/src/codecs/username";

import { logger } from "src/utility/logger";

export const LATIN_NAME_REGEX = /^[\p{Script=Latin}0-9 .\-']+$/u;

/**
 * Represents a valid value
 */
export const VALID = Symbol("VALID");

/**
 * Validates the username and returns a user-friendly message if not
 *
 * @returns VALID if valid, undefined if not ready to validate, or an error
 * message if there's a problem
 */
export function errorMessageForUsername(
  username?: string
): string | undefined | typeof VALID {
  if (!username) {
    // don't show an error if the stirng is empty
    return undefined;
  }
  const result = usernameCodec.decode(username);
  if (result._tag === "Right") {
    return VALID;
  }
  const message = result.left.find((err) => err.message)?.message;
  if (!message) {
    logger.warn({
      message: "Invalid username with no error message",
      data: { errors: result.left },
    });
    return "Please enter a valid username";
  }
  return message;
}

/**
 * Makes sure that email looks to be of the correct format. It may still be
 * rejected by our login provider, but we try our best.
 */
export function validateEmail(email?: string) {
  if (email && email.length > 0) {
    if (!isEmail(email)) {
      return "Incorrect email format.";
    }
  }
  return undefined;
}
