import { NonprofitRevenueSize } from "../entity/types";

/**
 * Note that revenueAmt is measured in cents
 */
export function getRevenue(revenueAmt: number) {
  if (revenueAmt < 2e8) {
    // Under 2mm and California does not require audit
    return NonprofitRevenueSize.SMALL;
  }
  // Under 50mm for medium
  if (revenueAmt < 5e9) {
    return NonprofitRevenueSize.MEDIUM;
  }
  return NonprofitRevenueSize.LARGE;
}

export function getSizeRevenueThresholds(size: NonprofitRevenueSize) {
  if (size === NonprofitRevenueSize.SMALL) {
    return [0, 2e8];
  }
  if (size === NonprofitRevenueSize.MEDIUM) {
    return [2e8, 5e9];
  }
  if (size === NonprofitRevenueSize.LARGE) {
    return [5e9, Infinity];
  }
  throw Error(`unknown nonprofit size: ${size}`);
}
