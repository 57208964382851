import {
  HeaderClickablePopover,
  secondaryTextCss,
  TimesChargedAndTimeList,
} from "@components/DonationCardHeader/styles";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Big } from "big.js";
import getUserLocale from "get-user-locale";
import React from "react";

import {
  DonationFrequency,
  DonationFrequencyToUserFacingTextMap,
} from "@every.org/common/src/entity/types";
import { timeDifferenceString } from "@every.org/common/src/helpers/timeDifferenceString";

import { ViewDonationData } from "src/context/ViewDonationContext";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { ordinalNumberString } from "src/utility/ordinalNumberString";

interface TimesChargedAndTimePopoverProps {
  createdAt: Date | null;
  removeTimestamp?: boolean;
  frequency?: DonationFrequency;
  timesCharged?: number;
  donationData?: ViewDonationData | null;
}

/**
 * Exported for testing.
 */
export function cryptoQuantityForDisplay(quantity: number) {
  const quantityBig = new Big(quantity);
  const rounded = quantityBig.toFixed(2);
  // If rounding didn't do anything to the value, then just return the original
  if (quantityBig.eq(rounded)) {
    return quantityBig.toString();
  }
  // If the value rounded down to 0, then show the original very small value.
  // rounded to 1 significant figure (i.e. 0.000000014 would go to 0.00000001)
  if (new Big(rounded).eq(0)) {
    return new Big(quantityBig.toPrecision(1)).toString();
  }
  return rounded.toString();
}

export const TimesChargedAndTimePopover: React.FCC<
  TimesChargedAndTimePopoverProps
> = ({ createdAt, removeTimestamp, frequency, timesCharged, donationData }) => {
  const showTimesCharged =
    !!timesCharged && !!frequency && frequency !== DonationFrequency.ONCE;
  const publicAmounts =
    !!donationData?.user.publicDonationAmount &&
    donationData.donationCharge.amounts;
  const nothingToShow =
    !showTimesCharged && (removeTimestamp || !createdAt) && !publicAmounts;

  if (nothingToShow) {
    return null;
  }

  function getLocaleCreatedAt() {
    const locale = getUserLocale();
    try {
      if (typeof createdAt === "string") {
        createdAt = new Date(createdAt);
      }
      return createdAt && new Intl.DateTimeFormat(locale).format(createdAt);
    } catch (error) {
      logger.warn({
        message: "Unable to format date with user locale",
        error,
        data: {
          createdAt,
          locale,
          typeofCreatedAt: typeof createdAt,
          typeofLocal: typeof locale,
        },
      });
    }
    return undefined;
  }

  const localeCreatedAt = getLocaleCreatedAt();

  return (
    <HeaderClickablePopover
      placement={"bottom"}
      css={secondaryTextCss}
      content={
        <TimesChargedAndTimeList>
          {localeCreatedAt && (
            <li>
              <Icon
                iconImport={() => import("@components/Icon/icons/ClockIcon")}
                display={IconDisplay.SECONDARY}
                size={IconSize.X_SMALL}
              />
              <span>{localeCreatedAt}</span>
            </li>
          )}
          {frequency && (
            <li>
              <Icon
                iconImport={() => import("@components/Icon/icons/CalendarIcon")}
                display={IconDisplay.SECONDARY}
                size={IconSize.X_SMALL}
              />
              <span>{DonationFrequencyToUserFacingTextMap[frequency]}</span>
            </li>
          )}
          {timesCharged && frequency && frequency !== DonationFrequency.ONCE && (
            <li>
              <Icon
                iconImport={() => import("@components/Icon/icons/RepeatIcon")}
                display={IconDisplay.SECONDARY}
                size={IconSize.X_SMALL}
              />
              <span>{ordinalNumberString(timesCharged)} time</span>
            </li>
          )}
        </TimesChargedAndTimeList>
      }
    >
      <span>
        {publicAmounts &&
          publicAmounts.quantity &&
          publicAmounts.symbol &&
          `${cryptoQuantityForDisplay(publicAmounts.quantity)} ${
            publicAmounts.symbol
          }`}
        {publicAmounts &&
          publicAmounts.value &&
          !(publicAmounts.quantity || publicAmounts.symbol) &&
          displayCurrencyValueInUserLocale({
            minDenomCurrencyValue: publicAmounts.value,
          })}
        {showTimesCharged && ` x${timesCharged}`}
        {!removeTimestamp && createdAt && (
          <React.Fragment>
            {` • `}
            {timeDifferenceString(new Date(), new Date(createdAt))}
          </React.Fragment>
        )}
      </span>
    </HeaderClickablePopover>
  );
};
