import styled from "@emotion/styled";
import React from "react";

import { ReactComponent as LogoSvg } from "src/assets/logo.svg";
import { colorCssVars } from "src/theme/color";

const StyledLogo = styled(LogoSvg)`
  .every {
    fill: var(${colorCssVars.text.body});
  }
  .dotorg,
  .logo-mark {
    fill: var(${colorCssVars.logo});
  }
`;

export interface LogoProps {
  className?: string;
}

/**
 * Every.org swirly and custom text logo, with correct positioning
 */
export const Logo: React.FCC<LogoProps> = ({ className }) => (
  <StyledLogo className={className} width={479} height={112} />
);
