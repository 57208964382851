import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
} from "@components/donate/DonateV3/types";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { ReactComponent as DafLogo } from "src/assets/illustrations/dafLogo.svg";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  BUTTON_CONTENT_COLOR_CSS_VAR,
  BUTTON_BACKGROUND_COLOR_CSS_VAR,
} from "src/styles/button";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { getWindow } from "src/utility/window";

const CHARIOT_GREEN = "#ADEFD1";
const CHARIOT_DARK_GREEN = "#7ce6b6";
const CHARIOT_DARK_BLUE = "#00203F";

const StyledButton = styled(Button)`
  align-self: center;
  margin-bottom: ${spacing.m};
  ${BUTTON_CONTENT_COLOR_CSS_VAR}: ${CHARIOT_DARK_BLUE};
  ${BUTTON_BACKGROUND_COLOR_CSS_VAR}: ${CHARIOT_GREEN};

  &:hover {
    background-color: ${CHARIOT_DARK_GREEN};
  }
`;

export const DAFV2Page = ({
  form,
  formContext,
  nonprofit,
  submitDonation,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
}) => {
  const navigate = useNavigate();

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.DAF,
    form,
    formContext,
  });

  useEffect(() => {
    const window = getWindow();
    if (window && formContext.skipAmountAndFrequency) {
      navigate(
        paymentProcessRouteNameToPathMap[
          PaymentProcessRouteName.CONFIRM_DAF_CHARIOT
        ],
        { replace: true }
      );
    }
  }, [formContext.skipAmountAndFrequency, navigate]);

  return (
    <PageContainer>
      <SelectDonationFlowPaymentOption
        selectedPaymentOption={DonationFlowPaymentOption.DAF}
        paymentRequestReadyStatus={
          formContext.paymentRequestInitializer.readyStatus
        }
        paymentRequestIsApplePay={
          formContext.paymentRequestInitializer.isApplePay
        }
        paymentFlowOptions={formContext.paymentFlowOptions}
        showMorePaymentOptions={formContext.showMorePaymentOptions}
        setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
      />
      <ProcessContainer css={{ display: "flex", flexDirection: "column" }}>
        <h4 css={{ marginBottom: spacing.l }}>Instant DAF donation</h4>
        <p css={{ marginBottom: spacing.l }}>
          We use DAFpay to verify your account info and automatically initiate a
          grant from your DAF, or you can get instructions for making the
          donation manually.
        </p>
        <StyledButton
          role={ButtonRole.PRIMARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              navigate(
                paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.DAF_CHARIOT
                ]
              );
            },
          }}
          data-tname={`DAF_Chariot_donation`}
          contentCss={[horizontalStackCss.xs, { alignItems: "center" }]}
        >
          <span>Donate with</span>
          <DafLogo />
        </StyledButton>
        <Button
          role={ButtonRole.TEXT_ONLY}
          css={{ alignSelf: "center" }}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              navigate(
                paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.DAF_MANUAL
                ]
              );
            },
          }}
          data-tname={`DAF_manual_donation`}
        >
          Get instructions for a manual DAF donation
        </Button>
      </ProcessContainer>
    </PageContainer>
  );
};
