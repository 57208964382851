import { NonprofitResponse } from "@every.org/common/src/codecs/entities";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";

export function useNonprofitAdminData(nonprofitId?: NonprofitResponse["id"]) {
  const loggedInUser = useLoggedInUserOrUndefined();

  if (!loggedInUser || !nonprofitId) {
    return undefined;
  }

  const adminFor = loggedInUser.adminFor || [];

  const adminForMap = new Map(
    adminFor.map((item) => [item.nonprofit.id, item])
  );

  return adminForMap.get(nonprofitId);
}
