import { NonprofitCard as NewNonprofitCard } from "@components/feed/NewNonprofitCard";
import { CreatedFundCardActions } from "@components/feed/funds/CreatedFundCardActions";
import { UUID } from "io-ts-types/UUID";
import React, { useContext } from "react";

import {
  FeedItemType,
  FundCreatingEntityType,
} from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { UsersContext } from "src/context/UsersContext";
import { getUser, userOrUndefined } from "src/context/UsersContext/selectors";

interface CreatedFundCardProps {
  fund: ContextNonprofit;
  feedId?: UUID;
  hideActions?: boolean;
}
/**
 * Card that displays a donation based on a feed donation response.
 */
export const CreatedFundCard: React.FCC<CreatedFundCardProps> = ({
  fund,
  feedId,
  hideActions = false,
}) => {
  const { fundCreatingEntityType, fundCreatingEntityId } = fund;

  const usersState = useContext(UsersContext);
  const user =
    fundCreatingEntityId &&
    userOrUndefined(getUser(usersState, { id: fundCreatingEntityId }));

  if (
    fundCreatingEntityType !== FundCreatingEntityType.USER ||
    !fundCreatingEntityId
  ) {
    // KL Sep-2020
    // TODO: Support non-user (nonprofit) created funds in feed
    return <React.Fragment />;
  }

  if (!user) {
    return <React.Fragment />;
  }

  return (
    <NewNonprofitCard
      causeCategoryBackgroundColor
      data-feed-feedid={feedId}
      data-feed-itemid={fund.id}
      data-feed-itemtype={FeedItemType.CREATED_FUND}
      nonprofit={fund}
      customFooter={
        hideActions ? null : (
          <CreatedFundCardActions
            fund={fund}
            numSupporters={fund.supporterCount}
          />
        )
      }
    />
  );
};
