import { css, SerializedStyles } from "@emotion/react";

import {
  ColorTheme,
  DARK_THEME,
  LIGHT_THEME,
} from "@every.org/common/src/display/palette";
import { CauseCategory } from "@every.org/common/src/entity/types";
import {
  readonly,
  objectFromEnumValues,
} from "@every.org/common/src/helpers/objectUtilities";

/**
 * All CSS variables used for color styling
 *
 * Use these when referring to CSS variables in your code
 */
export const colorCssVars = readonly({
  text: readonly({
    body: "--textBodyColor",
    secondary: "--textSecondaryColor",
  }),
  accent: readonly({
    small: "--smallAccentColor",
    smallHighlight: "--smallAccentHighlightColor",
    large: "--largeAccentColor",
    largeHighlight: "--largeAccentHighlightColor",
  }),
  logo: "--logoColor",
  emphasis: "--emphasisColor",
  background: readonly({
    normal: "--backgroundColor",
    faded: "--backgroundFadedColor",
  }),
  dividerSoft: "--dividerSoftColor",
  input: readonly({
    background: readonly({
      default: "--inputBackgroundDefaultColor",
      hover: "--inputBackgroundHoverColor",
      focus: "--inputBackgroundFocusColor",
    }),
    border: readonly({
      error: "--inputBorderErrorColor",
      focus: "--inputBorderFocusColor",
    }),
    outline: readonly({
      error: "--inputOutlineErrorColor",
      focus: "--inputOutlineFocusColor",
    }),
    caret: "--inputCaret",
  }),
  causeCategory: readonly(
    objectFromEnumValues({
      enum: CauseCategory,
      mapFn: (c) => ({
        small: `--${c}SmallColor`,
        smallHighlight: `--${c}SmallHighlightColor`,
        large: `--${c}LargeColor`,
        largeHighlight: `--${c}LargeHighlightColor`,
      }),
    })
  ),
  googleButtonBorder: readonly({
    normal: "--googleButtonBorderColor",
    hover: "--googleButtonHoverBorderColor",
  }),
  headerIcon: readonly({
    background: readonly({
      normal: "--headerIconBackgroundColor",
      hover: "--headerIconHoverBackgroundColor",
    }),
    text: readonly({
      normal: "--headerIconTextColor",
      hover: "--headerIconHoverTextColor",
    }),
  }),
});

/**
 * Generates the CSS variable values and background color based on the palette
 * provided
 */
function makeTheme({
  theme,
  applyBackground = false,
  overrideAll,
}: {
  theme: ColorTheme;
  applyBackground: boolean;
  /**
   * If present replaces all the colors with this one
   */
  overrideAll?: string;
}): SerializedStyles {
  return css`
    ${colorCssVars.text.body}: ${overrideAll || theme.text.body};
    ${colorCssVars.text.secondary}: ${overrideAll || theme.text.secondary};
    ${colorCssVars.accent.small}: ${overrideAll || theme.accent.small};
    ${colorCssVars.accent.smallHighlight}: ${overrideAll ||
    theme.accent.smallHighlight};
    ${colorCssVars.accent.large}: ${overrideAll || theme.accent.large};
    ${colorCssVars.accent.largeHighlight}: ${overrideAll ||
    theme.accent.largeHighlight};
    ${colorCssVars.logo}: ${overrideAll || theme.logo};
    ${colorCssVars.emphasis}: ${overrideAll || theme.emphasis};
    ${colorCssVars.background.normal}: ${overrideAll ||
    theme.background.normal};
    ${colorCssVars.background.faded}: ${overrideAll || theme.background.faded};
    ${colorCssVars.dividerSoft}: ${overrideAll || theme.dividerSoft};
    ${colorCssVars.input.background.default}: ${overrideAll ||
    theme.input.background.default};
    ${colorCssVars.input.background.focus}: ${overrideAll ||
    theme.input.background.focus};
    ${colorCssVars.input.background.focus}: ${overrideAll ||
    theme.input.background.focus};
    ${colorCssVars.input.background.hover}: ${overrideAll ||
    theme.input.background.hover};
    ${colorCssVars.input.border.error}: ${overrideAll ||
    theme.input.border.error};
    ${colorCssVars.input.border.focus}: ${overrideAll ||
    theme.input.border.focus};
    ${colorCssVars.input.outline.error}: ${overrideAll ||
    theme.input.outline.error};
    ${colorCssVars.input.outline.focus}: ${overrideAll ||
    theme.input.outline.focus};
    ${colorCssVars.input.caret}: ${overrideAll || theme.input.caret};

    ${Object.values(CauseCategory).map(
      (c) => css`
        ${colorCssVars.causeCategory[c].small}: ${theme.causeCategory[c].small};
        ${colorCssVars.causeCategory[c].smallHighlight}: ${theme.causeCategory[
          c
        ].smallHighlight};
        ${colorCssVars.causeCategory[c].large}: ${theme.causeCategory[c].large};
        ${colorCssVars.causeCategory[c].largeHighlight}: ${theme.causeCategory[
          c
        ].largeHighlight};
      `
    )};
    ${colorCssVars.googleButtonBorder.normal}: ${overrideAll ||
    theme.googleButtonBorder.normal};
    ${colorCssVars.googleButtonBorder.hover}: ${overrideAll ||
    theme.googleButtonBorder.hover};
    ${colorCssVars.headerIcon.background.normal}: ${overrideAll ||
    theme.headerIcon.background.normal};
    ${colorCssVars.headerIcon.background.hover}: ${overrideAll ||
    theme.headerIcon.background.hover};
    ${colorCssVars.headerIcon.text.normal}: ${overrideAll ||
    theme.headerIcon.text.normal};
    ${colorCssVars.headerIcon.text.hover}: ${overrideAll ||
    theme.headerIcon.text.hover};
    color: var(${colorCssVars.text.body});

    /* apply the background on this element */
    ${applyBackground
      ? css`
          background: var(${colorCssVars.background.normal});
        `
      : ""};
  `;
}

/**
 * CSS Mixin to set relevant CSS variables to dark theme colors for all
 * children of the applied element, and the background itself
 */
export const darkBgThemeCss = makeTheme({
  theme: DARK_THEME,
  applyBackground: true,
});

/**
 * CSS Mixin to set relevant CSS variables to dark theme colors for all
 * children of the applied element, and the background itself
 */
export const darkBgThemeNoBgCss = makeTheme({
  theme: DARK_THEME,
  applyBackground: false,
});

/**
 * CSS Mixin to set relevant CSS variables to light theme colors for all
 * children of the applied element, and the background itself
 */
export const lightBgThemeCss = makeTheme({
  theme: LIGHT_THEME,
  applyBackground: true,
});

/**
 * CSS Mixin to set relevant CSS variables to light theme colors for all
 * children of the applied element, but doesn't apply the background
 */
export const lightBgThemeNoBgCss = makeTheme({
  theme: LIGHT_THEME,
  applyBackground: false,
});

/**
 * CSS Mixin to set all CSS variables to white; useful in some contexts
 *
 * Does not set the background.
 */
export const allWhiteThemeCss = makeTheme({
  theme: DARK_THEME,
  applyBackground: false,
  overrideAll: "white",
});
