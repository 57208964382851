import {
  Button,
  ButtonSize,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import React, { useMemo } from "react";

import { useScrolledDown } from "src/hooks/useScrolledDown";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";
import { getWindow } from "src/utility/window";

const MIN_SCROLL_TO_SHOW_BACK_TO_TOP = 150;

const WRAPPER_CSS = css`
  position: sticky;
  bottom: 0;
  pointer-events: none;
`;

const BUTTON_CSS = css`
  pointer-events: all;
  margin-bottom: ${spacing.l};
  padding: ${spacing.s};
  background: var(${colorCssVars.background.normal});
  border-radius: 500px;
  box-shadow: 0px 2px 4px rgba(46, 52, 52, 0.1);
`;

export const BackToTopButton = () => {
  const scrolledDown = useScrolledDown({
    minY: MIN_SCROLL_TO_SHOW_BACK_TO_TOP,
  });
  const wrapperContentCss = useMemo(
    () => css`
      display: flex;
      justify-content: flex-end;
      transition: transform 0.3s ease-in-out;
      transform: translateY(${scrolledDown ? `0%` : `100%`});
    `,
    [scrolledDown]
  );

  return (
    <PageSection css={WRAPPER_CSS} contentCss={wrapperContentCss}>
      <Button
        data-tname="BackToTop"
        disabled={!scrolledDown}
        css={BUTTON_CSS}
        size={ButtonSize.LARGE}
        role={ButtonRole.UNSTYLED}
        icon={
          <Icon
            iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
            size={IconSize.LARGE}
            display={IconDisplay.ACCENT}
            css={css`
              transform: rotate(90deg);
            `}
          />
        }
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => {
            const window = getWindow();
            if (!window) {
              return;
            }
            if (window.document.location.hash) {
              window.history.pushState({}, window.document.title, " ");
            }
            window.scroll({ top: 0, behavior: "smooth" });
          },
        }}
      />
    </PageSection>
  );
};
