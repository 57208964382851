import { createContext, useState, useTransition } from "react";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  useDirectoryFilterController,
  useProjects,
} from "src/pages/Directory/hooks";

type DirectoryFilterContextType = ReturnType<
  typeof useDirectoryFilterController
>;
type DirectoryProjectsContextType = ReturnType<typeof useProjects>;
type DirectoryPendingContextType = {
  pending: boolean;
  setPending: (pending: boolean) => void;
  startTransition: (func: () => void) => void;
};

export const DirectoryFilterContext = createContext<DirectoryFilterContextType>(
  {} as DirectoryFilterContextType
);
export const DirectoryProjectsContext =
  createContext<DirectoryProjectsContextType>(
    {} as DirectoryProjectsContextType
  );
export const DirectoryPendingContext =
  createContext<DirectoryPendingContextType>({} as DirectoryPendingContextType);

const DirectoryPendingContextProvider: React.FCC = ({ children }) => {
  const [manualPending, setPending] = useState(true);
  const [transitionPending, startTransition] = useTransition();

  return (
    <DirectoryPendingContext.Provider
      value={{
        pending: manualPending || transitionPending,
        startTransition,
        setPending,
      }}
    >
      {children}
    </DirectoryPendingContext.Provider>
  );
};

const DirectoryFilterContextProvider: React.FCC<{
  nonprofit: ContextNonprofit;
}> = ({ children, nonprofit }) => {
  const filterController = useDirectoryFilterController({
    nonprofitSlug: nonprofit.primarySlug,
  });

  return (
    <DirectoryFilterContext.Provider value={filterController}>
      {children}
    </DirectoryFilterContext.Provider>
  );
};

const DirectoryProjectsContextProvider: React.FCC<{
  nonprofit: ContextNonprofit;
}> = ({ children, nonprofit }) => {
  const projectsState = useProjects(nonprofit);

  return (
    <DirectoryProjectsContext.Provider value={projectsState}>
      {children}
    </DirectoryProjectsContext.Provider>
  );
};

export const DirectoryContext: React.FCC<{
  nonprofit: ContextNonprofit;
}> = ({ children, nonprofit }) => {
  return (
    <DirectoryPendingContextProvider>
      <DirectoryFilterContextProvider nonprofit={nonprofit}>
        <DirectoryProjectsContextProvider nonprofit={nonprofit}>
          {children}
        </DirectoryProjectsContextProvider>
      </DirectoryFilterContextProvider>
    </DirectoryPendingContextProvider>
  );
};
