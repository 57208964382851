import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { Loading } from "@components/LoadingIndicator";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { PrivateNoteInput } from "@components/donate/DonateV3/PaymentProcess/components/PrivateNoteInput";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { CryptoAmountInput } from "@components/donate/DonateV3/PaymentProcess/pages/CryptoV2/AmountInput";
import { CoinSelector } from "@components/donate/DonateV3/PaymentProcess/pages/CryptoV2/CoinSelector";
import { ErrorMessage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  validateCommentText,
  validateCryptoAmountAndCurrency,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { Big } from "big.js";
import React, { useContext, useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationFlowPaymentOption,
  Country,
} from "@every.org/common/src/entity/types";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { CryptoCurrencyConfig } from "src/utility/cryptoCurrency";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export const CryptoV2Page = ({
  nonprofit,
  fundraiser,
  form,
  formContext,
  submitDonation,
  createOrUpdateDonationResult,
  handleConfirmedDonation,
}: {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
}) => {
  const selectedCryptoCurrency = form.watch("cryptoCurrency");
  const cryptoPledgeAmount = form.watch("cryptoPledgeAmount");
  const currencyErrorMessage = form.formState.errors["cryptoCurrency"]?.message;
  const amountErrorMessage =
    form.formState.errors["cryptoPledgeAmount"]?.message;
  const { cryptoTokenRate, shorten } = formContext;
  const privateNote = form.watch("privateNote");

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.CRYPTO,
    form,
    formContext,
  });

  const navigate = useNavigate();

  const submit = form.handleSubmit(
    (formValues) => {
      logger.info({
        message: "Submitted!",
        data: {
          ...formValues,
          paymentTab: DonationFlowPaymentOption.CRYPTO,
        },
      });
      if (
        !validateCryptoAmountAndCurrency({
          currency: selectedCryptoCurrency,
          amount: cryptoPledgeAmount?.toString() || "",
          shorten,
          setError: form.setError,
        }) ||
        !validateCommentText(
          privateNote,
          form.setError,
          "privateNote",
          nonprofit.metadata?.privateNoteLimit
        )
      ) {
        return;
      }

      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.CONFIRM_CRYPTO]
      );
    },
    (errors) => {
      logger.error({ message: "Error validating cryptov2 form", data: errors });
    }
  );

  const authState = useContext(AuthContext);

  useEffect(() => {
    const window = getWindow();
    if (window && formContext.skipCryptoAmountAndCurrency) {
      navigate(
        paymentProcessRouteNameToPathMap[
          PaymentProcessRouteName.CONFIRM_CRYPTO
        ],
        { replace: true }
      );
    }
  }, [formContext.skipCryptoAmountAndCurrency, navigate]);

  if (authState.status === AuthStatus.LOADING) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <SelectDonationFlowPaymentOption
        selectedPaymentOption={DonationFlowPaymentOption.CRYPTO}
        paymentRequestReadyStatus={
          formContext.paymentRequestInitializer.readyStatus
        }
        paymentRequestIsApplePay={
          formContext.paymentRequestInitializer.isApplePay
        }
        paymentFlowOptions={formContext.paymentFlowOptions}
        showMorePaymentOptions={formContext.showMorePaymentOptions}
        setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
      />
      <ProcessContainer>
        <form onSubmit={submit} css={verticalStackCss.l}>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Currency
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="cryptoCurrency"
                render={({ field }) => (
                  <CoinSelector
                    setSelectedCryptoCurrency={field.onChange}
                    selectedCryptoCurrency={field.value}
                    nonprofit={nonprofit}
                  />
                )}
              />
            </div>
          </div>
          {selectedCryptoCurrency && (
            <Controller
              control={form.control}
              name="cryptoPledgeAmount"
              render={({ field }) => (
                <CryptoAmountInput
                  minDonationValue={formContext.minValue}
                  maxDonationValue={formContext.maxValue}
                  cryptoCurrency={selectedCryptoCurrency}
                  cryptoAmountValue={field.value?.toString() || ""}
                  setCryptoAmountValue={field.onChange}
                  cryptoTokenRate={formContext.cryptoTokenRate?.rate}
                />
              )}
            />
          )}
          <PrivateNoteInput
            nonprofit={nonprofit}
            form={form}
            fundraiser={fundraiser}
          />
          <div css={verticalStackCss.l}>
            {nonprofit.countryCode !== Country.US && (
              <React.Fragment>
                <div>
                  <b>Sorry</b>, we only support crypto donations to <b>US</b>{" "}
                  nonprofits at the moment.
                </div>
              </React.Fragment>
            )}
            {(currencyErrorMessage || amountErrorMessage) && (
              <div css={horizontalStackCss.xs}>
                <Icon
                  iconImport={() => import("@components/Icon/icons/AlertIcon")}
                  size={IconSize.MEDIUM}
                  display={IconDisplay.ERROR}
                />
                <div css={verticalStackCss.xxxs}>
                  {currencyErrorMessage && (
                    <ErrorMessage>{currencyErrorMessage}</ErrorMessage>
                  )}
                  {amountErrorMessage && (
                    <ErrorMessage>{amountErrorMessage}</ErrorMessage>
                  )}
                </div>
              </div>
            )}
            <Button
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.SUBMIT,
              }}
              data-tname="donateV3Submit"
            >
              Continue with{" "}
              {selectedCryptoCurrency
                ? CryptoCurrencyConfig[selectedCryptoCurrency].abbreviation
                : "crypto"}
            </Button>
            {selectedCryptoCurrency &&
              cryptoTokenRate &&
              cryptoTokenRate.rate > 0 && (
                <p
                  css={css`
                    color: var(${colorCssVars.text.secondary});
                    ${textSizeCss[TextSize.xs]};
                  `}
                >
                  *Estimated exchange rate of{" "}
                  {displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency: cryptoTokenRate.currency,
                      amount: new Big(cryptoTokenRate.rate),
                    },
                    options: { minimumSignificantDigits: 3 },
                  })}{" "}
                  / {selectedCryptoCurrency} is provided by CoinGecko. Final
                  rate will be determined by our brokerage at time of
                  transaction conversion.
                </p>
              )}
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
