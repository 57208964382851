import React, { createContext, useReducer } from "react";

export enum TurnstileActionType {
  ADD_TO_CONTEXT = "ADD_TO_CONTEXT",
  REMOVE_FROM_CONTEXT = "REMOVE_FROM_CONTEXT",
}

type TurnstileData = string | undefined;

interface RecordTurnstileToken {
  type: TurnstileActionType.ADD_TO_CONTEXT;
  id: string;
  token?: string;
}

interface RemoveTurnstileToken {
  type: TurnstileActionType.REMOVE_FROM_CONTEXT;
  id: string;
  token?: string;
}

type TurnstileAction = RecordTurnstileToken | RemoveTurnstileToken;

type TurnstileState = Map<string, TurnstileData>;
//#endregion

const initialState: TurnstileState = new Map();

function turnstileReducer(state: TurnstileState, action: TurnstileAction) {
  const newState = state;
  const { type, id, token } = action;
  switch (type) {
    case TurnstileActionType.ADD_TO_CONTEXT:
      // newState = new Map([...state.entries()]);
      newState.set(id, token);
      break;
    case TurnstileActionType.REMOVE_FROM_CONTEXT:
      // newState = new Map([...state.entries()].filter(([i, token]) => i !== id));
      if (newState.has(id)) {
        newState.delete(id);
      }
      break;
  }
  return newState;
}

export const TurnstileContext = createContext<TurnstileState>(initialState);

export let dispatchTurnstileAction: React.Dispatch<TurnstileAction>;

export const TurnstileProvider: React.FCC = ({ children }) => {
  const [turnstileState, turnstileDispatcher] = useReducer(
    turnstileReducer,
    initialState
  );
  dispatchTurnstileAction = turnstileDispatcher;
  return (
    <TurnstileContext.Provider value={turnstileState}>
      {children}
    </TurnstileContext.Provider>
  );
};
