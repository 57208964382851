import { css } from "@emotion/react";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { TextCssVar, FontWeight, textSizeCss, TextSize } from "src/theme/text";

export const nameCss = [
  truncatedTextCss({ numLines: 2 }),
  css`
    ${TextCssVar.LINE_HEIGHT}: 28px;
  `,
];

export const locationCss = [
  truncatedTextCss({ numLines: 1 }),
  css`
    ${textSizeCss[TextSize.xxs]};
    text-transform: uppercase;
    font-weight: ${FontWeight.BOLD};
    color: #2e3434;
    opacity: 0.6;
  `,
];

export const creatorNameCss = css`
  b {
    font-weight: ${FontWeight.BOLD};
  }
`;
