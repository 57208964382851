import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "@every.org/common/src/routes/me";

import {
  UserProfileFormErrors,
  UserProfileFormValues,
} from "src/utility/signup/UserProfileTypes";
import {
  errorMessageForUsername,
  validateEmail,
} from "src/utility/userValidators";

/**
 * Validates the sign up form values on change of inputs and returns errors.
 */
export function validateChange(
  values: UserProfileFormValues
): UserProfileFormErrors {
  return removeUndefinedValues({
    password: validatePassword(values.password),
    username: errorMessageForUsername(values.username),
    email: validateEmail(values.email),
  } as const);
}

/**
 * Validates the sign up form values on blur (losing focus) of inputs and returns errors.
 */
export function validateBlur(
  values: UserProfileFormValues
): UserProfileFormErrors {
  return {};
}

/**
 * Just validates length; making sure that it's not in the dictionary of common
 * passwords is done on Auth0's side.
 *
 * We don't require different categories of characters to allow for passphrases.
 */
export function validatePassword(password: UserProfileFormValues["password"]) {
  if (
    password &&
    password.length > 0 &&
    password.length < MIN_PASSWORD_LENGTH &&
    password.length > MAX_PASSWORD_LENGTH
  ) {
    return `Password must be ${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters long.`;
  }
  return undefined;
}
