import React from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";

/**
 * Status of a tag being searched for
 */
export enum TagFetchStatus {
  FOUND = "FOUND",
  FETCHING_TAG = "FETCHING_TAG",
  TAG_NOT_FOUND = "TAG_NOT_FOUND",
}
export enum TrendingTagsFetchStatus {
  FOUND = "FOUND",
  FETCHING_TRENDING_TAGS = "FETCHING_TRENDING_TAGS",
  TRENDING_TAGS_NOT_FOUND = "TRENDING_TAGS_NOT_FOUND",
}

export type TagOrFetchStatus =
  | TagResponse
  | Exclude<TagFetchStatus, TagFetchStatus.FOUND>;

/**
 * Current state in browser memory of tags fetched
 */
export interface TagsState {
  dispatchTagsAction: React.Dispatch<TagsAction>;
  /**
   * Successfully fetched Tag entities, indexed by tag name
   */
  tagsByTagName: Map<TagResponse["tagName"], TagResponse>;
  /**
   * Successfully fetched Tag entities, indexed by tag name
   */
  tagsById: Map<TagResponse["id"], TagResponse>;
  /**
   * Mapping from an identifier used to fetch a tag name to the status
   * of that fetch
   */
  tagFetchStatus: Map<TagResponse["tagName"], TagFetchStatus>;
  /* The trending tags in order */
  trendingTags: TagResponse[];
  /* Status of the trending tags fetching */
  trendingTagsFetchStatus: TrendingTagsFetchStatus | undefined;
  /* The trending tags has more indicator */
  trendingTagsHasMore: boolean;
}
export type InternalTagsState = Omit<TagsState, "dispatchTagsAction">;

export type TagIdentifier =
  | { id: TagResponse["id"] }
  | { tagName: TagResponse["tagName"] };

export enum TagActionType {
  FETCHING_TAG = "FETCHING_TAG",
  FETCHING_TRENDING_TAGS = "FETCHING_TRENDING_TAGS",
  ADD_TAG = "ADD_TAG",
  ADD_TAGS = "ADD_TAGS",
  ADD_TRENDING_TAGS = "ADD_TRENDING_TAGS",
  TAG_NOT_FOUND = "TAG_NOT_FOUND",
  TRENDING_TAGS_NOT_FOUND = "TRENDING_TAGS_NOT_FOUND",
}
interface FetchingTagAction {
  type: TagActionType.FETCHING_TAG;
  data: TagIdentifier;
}
interface AddTagAction {
  type: TagActionType.ADD_TAG;
  data: TagResponse;
}
interface AddTagsAction {
  type: TagActionType.ADD_TAGS;
  data: TagResponse[];
}
interface AddTrendingTagsAction {
  type: TagActionType.ADD_TRENDING_TAGS;
  data: { tags: TagResponse[]; hasMore: boolean };
}
interface TagNotFoundAction {
  type: TagActionType.TAG_NOT_FOUND;
  data: TagIdentifier;
}
interface FetchingTrendingTagsAction {
  type: TagActionType.FETCHING_TRENDING_TAGS;
  data: undefined;
}
interface TrendingTagsNotFountAction {
  type: TagActionType.TRENDING_TAGS_NOT_FOUND;
  data: undefined;
}
export type TagsAction =
  | AddTagAction
  | AddTagsAction
  | AddTrendingTagsAction
  | FetchingTagAction
  | TagNotFoundAction
  | FetchingTrendingTagsAction
  | TrendingTagsNotFountAction;
