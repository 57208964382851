/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Length of an EIN, not including the dash
 */
const EIN_NUM_DIGITS = 9;

/**
 * Position of the dash in an EIN
 */
const EIN_DASH_POS = 2;

/**
 * Regex to determine if an EIN with the dash: XX-XXXXXXX where X is a digit
 */
const EIN_WITH_DASH_REGEX = new RegExp(
  `^[0-9]{${EIN_DASH_POS}}-[0-9]{${EIN_NUM_DIGITS - EIN_DASH_POS}}$`
);

/**
 * Regex to determine if an EIN without the dash: 9 digits. This is not yet
 * incapsulated in a RegExp object.
 */
export const EIN_REGEX_STR = `^[0-9]{${EIN_NUM_DIGITS}}$`;

/**
 * Regex to determine if an EIN without the dash: 9 digits
 */
export const EIN_REGEX = new RegExp(EIN_REGEX_STR);

/**
 * Mapping from observed short guidestar IDs in search results from error logs,
 * to actual EIN (without the dash)
 *
 * To add to this: our error logs sometimes report a Guidestar URL with an
 * invalid EIN, like the following (irrelevant keys removed):
 *
 * {
 *   "url":"https://www.guidestar.org/Profile/9715876",
 *   "extractedEin":"9715876",
 *   "msg":"EIN found in URL was not a valid EIN"
 * }
 *
 * Guidestar URLS tend to end in an EIN but sometimes end in a proprietary ID of
 * theirs, so if you see a log like this, follow the link; find the real EIN;
 * and then add the key-value pair to this object.
 */
export const guidestarIdToEin: { readonly [key: string]: string } = {
  "9715876": "825447038", // Cam and Madi S Promise Incorporated
  "9474864": "462785474", // unspoken smiles foundation
  "9613067": "464699448", // color me africa
  "9627811": "821268022", // give and go athletics
  "9837689": "842679298", // ionic alliance foundation
  "9287792": "800875881", // freedom for immigrants
  "9608376": "813868166", // creative movement brazil
  "9626513": "815399091", // mississippi center for re-entry
  "9566926": "813191097", // lgbt center of greater reading
  "7601658": "411782426", // hermantown area chamber of commerce
  "9797238": "371867495", // give foundation
  "7948682": "593277808", // fish and wildlife foundation of florida
  "8436373": "951690972", // catholic big brothers big sisters of LA county
  "9625497": "814814594", // terrell l bruce memorial funda
  "7054945": "205536173", // impact community action
  "8396463": "941703155", // Population Connection
  "9137627": "800671198", // Operation Broken Silence
  "8475871": "990229658", // Hawaii Theatre Center
  "6844391": "043574877", // Centre-South Main Streets
};

/**
 * Given an EIN value, normalize it to an EIN string without the dash
 *
 * @param einValue EIN value to validate and normalize
 * @returns normalized EIN if a valid EIN string, or undefined if not
 */
export function normalizeEin(einValue: string | null): string | undefined {
  if (!einValue) {
    return undefined;
  }

  if (einValue.match(EIN_REGEX)) {
    return einValue;
  }

  return einValue.match(EIN_WITH_DASH_REGEX)
    ? `${einValue.substr(0, EIN_DASH_POS)}${einValue.substr(EIN_DASH_POS + 1)}`
    : undefined;
}

/**
 * PLEASE USE THIS SPARINGLY! Formats an EIN in the format that has a dash
 * (XX-XXXXXXX).
 *
 * This is necessary since some third parties expect the dash, but we don't use
 * it this way anywhere in our product.
 */
export function formatEinWithDash(ein: string): string | undefined {
  const normalized = normalizeEin(ein);
  if (!normalized) {
    return undefined;
  }
  return `${normalized.substr(0, 2)}-${normalized.substr(2)}`;
}

/**
 * Checks that the EIN matchs EIN_REGEX and that the ein
 * is not <= 1000 because today as a hack, nonprofits that
 * should not have an EIN we give them an EIN <= 1000.
 *
 * Instead, we should be allowing NULL ein.
 */
export function isValidEin(ein: string | null) {
  if (!ein) {
    return false;
  }
  const normalized = normalizeEin(ein);
  return normalized && Number(normalized) > 1000;
}
