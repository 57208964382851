import { css } from "@emotion/react";

export const FULL_SIZE_LINK_CLASS = "fullsizelink";
export const FULL_SIZE_LINK_CLASS_FUNDRAISER = "fullsizelinkfundraiser";

export const feedCardCss = css`
  position: relative;
  a,
  button {
    &:not(.${FULL_SIZE_LINK_CLASS}) {
      position: relative;
      z-index: 1;
    }
  }

  // put this class on links within a feed card to make them clickable everywhere
  .${FULL_SIZE_LINK_CLASS} {
    &,
    &:visited,
    &:hover,
    &::selection {
      color: transparent;
      background-color: transparent;
    }
    color: transparent;
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const fundraiserFeedCardCss = css`
  position: relative;
  a,
  button {
    &:not(.${FULL_SIZE_LINK_CLASS_FUNDRAISER}) {
      position: relative;
      z-index: 4;
    }
  }

  // put this class on links within a feed card to make them clickable everywhere
  .${FULL_SIZE_LINK_CLASS_FUNDRAISER} {
    &,
    &:visited,
    &:hover,
    &::selection {
      color: transparent;
      background-color: transparent;
    }
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
`;
