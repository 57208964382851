// types taken from all their default TypeScript types (on hover)

// adapted from https://stackoverflow.com/a/42830295/1105281
if (!Object.values) {
  Object.values = function values<T>(
    obj: { [s: string]: T } | ArrayLike<T>
  ): T[] {
    return Object.keys(obj).map(function (k) {
      return obj[k];
    });
  };
}

// adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
if (!Object.entries) {
  Object.entries = function entries<T>(obj: { [s: string]: T }) {
    return Object.keys(obj).map<[string, T]>(function (k) {
      return [k, obj[k]];
    });
  };
}

// adapted from https://github.com/feross/fromentries/blob/master/index.js
if (!Object.fromEntries) {
  Object.fromEntries = function fromEntries(
    entries: ReturnType<typeof Object.entries>
  ): ReturnType<typeof Object.fromEntries> {
    return [...entries].reduce<ReturnType<typeof Object.fromEntries>>(function (
      obj,
      [k, v]
    ) {
      obj[k] = v;
      return obj;
    },
    {});
  };
}

const defaultExport = null;
export default defaultExport;
