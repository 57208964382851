/**
 * Card that displays a nonprofit recomendation.
 */
import { CreatedFundCard } from "@components/feed/funds/CreatedFundCard";
import { UUID } from "io-ts-types/UUID";
import React, { useContext } from "react";

import { CreatedFundResponse } from "@every.org/common/src/codecs/entities";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { logger } from "src/utility/logger";

interface FeedCreatedFundCardProps {
  item: CreatedFundResponse;
  feedId?: UUID;
}

/**
 * "Created fund" card in a feed.
 */
export const FeedCreatedFundCard: React.FCC<FeedCreatedFundCardProps> = ({
  item,
  feedId,
}) => {
  const { nonprofitId } = item;
  const nonprofitsState = useContext(NonprofitsContext);

  const nonprofit = nonprofitOrUndefined(
    getNonprofit(nonprofitsState, {
      id: nonprofitId,
    })
  );
  if (!nonprofit) {
    logger.error({
      message: "Nonprofit context missing nonprofit in FeedCreatedFundCard",
      data: { nonprofitId },
    });
    return <React.Fragment />;
  }

  return <CreatedFundCard feedId={feedId} fund={nonprofit} />;
};
