import { Card } from "@components/Card";

import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export interface BecomeAnAdminCardProps {
  nonprofit: ContextNonprofit;
  className?: string;
}

export function BecomeAnAdminCard({
  nonprofit,
  className,
}: BecomeAnAdminCardProps) {
  return (
    <Card className={className} css={verticalStackCss.xs}>
      <h4 css={{ fontWeight: FontWeight.REGULAR }}>
        Do you work for {nonprofit.name}?
      </h4>
      <Link
        data-tname="nonprofitOnboarding"
        to={getRoutePath({
          name: ClientRouteName.NONPROFITS_ONBOARDING_INTRO,
          format: URLFormat.RELATIVE,
          tokens: {
            nonprofitSlug: nonprofit.primarySlug,
          },
        })}
      >
        Become an admin of this nonprofit profile
      </Link>
    </Card>
  );
}
