import * as pathToRegexp from "path-to-regexp";

import {
  FUND_ACCOUNT_NONPROFIT_SLUG,
  GIFT_CARDS_SLUG,
} from "../entity/constants";
import {
  ValidCauseCategory,
  CauseMetadata,
  DonationFlowPaymentOption,
} from "../entity/types";

import { removeUndefinedOrNullValues } from "./objectUtilities";

const WEBSITE_ORIGIN =
  process.env.REACT_APP_WEBSITE_ORIGIN ||
  process.env.NEXT_PUBLIC_WEBSITE_ORIGIN ||
  process.env.WEBSITE_ORIGIN ||
  "https://www.every.org";

/**
 * The below are a workaround to overlapping Node and Browser
 * URL/URLSearchParams types as described at
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34960#issuecomment-576906058
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let URL: typeof globalThis extends { URL: infer URLType }
  ? URLType
  : typeof import("url").URL;
declare let URLSearchParams: typeof globalThis extends {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  URLSearchParams: infer URLSPType;
}
  ? URLSPType
  : typeof import("url").URLSearchParams;

export enum ClientRouteName {
  ABOUT = "ABOUT",
  API_LANDING = "API_LANDING",
  BLOG = "BLOG",
  BRAND = "BRAND",
  BUILD_PROFILE = "BUILD_PROFILE",
  CAREERS = "CAREERS",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  CHOOSE_ACCOUNT = "CHOOSE_ACCOUNT",
  CREATE_TRENDING_CAUSE = "CREATE_TRENDING_CAUSE",
  DISBURSEMENTS = "DISBURSEMENTS",
  DONATE_CRYPTO_LANDING = "DONATE_CRYPTO_LANDING",
  DONATE_STOCK_LANDING = "DONATE_STOCK_LANDING",
  EDIT_NONPROFIT = "EDIT_NONPROFIT",
  EDIT_TRENDING_CAUSE = "EDIT_TRENDING_CAUSE",
  FUND_OR_TAG_GIFTS = "FUND_OR_TAG_GIFTS",
  FUND_OR_TAG_NONPROFITS = "FUND_OR_TAG_NONPROFITS",
  FUND_LEARN_MORE = "FUND_LEARN_MORE",
  COMMUNITY_RECOMMENDATIONS = "COMMUNITY_RECOMMENDATIONS",
  COMMUNITY_FEED = "COMMUNITY_FEED",
  COMMUNITY_MEMBERS = "COMMUNITY_MEMBERS",
  COMMUNITY_DONATIONS = "COMMUNITY_DONATIONS",
  DAF_LANDING = "DAF_LANDING",
  GIFT_CARD = "GIFT_CARD",
  GIFT_CARD_PURCHASE = "GIFT_CARD_PURCHASE",
  GIFT_CARD_ORDER = "GIFT_CARD_ORDER",
  GIVERS_LANDING = "GIVERS_LANDING",
  GROUPS = "GROUPS",
  HOME = "HOME",
  INVITE_USERS = "INVITE_USERS",
  INVITE_JOIN = "INVITE_JOIN",
  LINK_ACCOUNTS = "LINK_ACCOUNTS",
  LOGIN = "LOGIN",
  REDIRECT = "REDIRECT",
  ME = "ME",
  MODERATOR = "MODERATOR",
  MY_GIVING = "MY_GIVING",
  MY_GIVING_HISTORY = "MY_GIVING_HISTORY",
  MY_GIVING_PAYMENT = "MY_GIVING_PAYMENT",
  MY_GIVING_RECURRING = "MY_GIVING_RECURRING",
  MY_GIVING_RECURRING_UPDATE = "MY_GIVING_RECURRING_UPDATE",
  MY_GIVING_ACHIEVEMENTS = "MY_GIVING_ACHIEVEMENTS",
  MY_GIVING_FUNDRAISERS = "MY_GIVING_FUNDRAISERS",
  FISCAL_SPONSORS_LANDING = "FISCAL_SPONSORS_LANDING",
  FUND_ACCOUNT = "FUND_ACCOUNT",
  FUND_ACCOUNT_CONFIRMATION = "FUND_ACCOUNT_CONFIRMATION",
  FUNDRAISE_CRYPTO = "FUNDRAISE_CRYPTO",
  FUNDRAISERS_LANDING = "FUNDRAISERS_LANDING",
  NEW_NONPROFIT = "NEW_NONPROFIT",
  NONPROFIT_ADMIN = "NONPROFIT_ADMIN",
  NONPROFIT_ADMIN_EDIT = "NONPROFIT_ADMIN_EDIT",
  NONPROFIT_ADMIN_GET_STARTED = "NONPROFIT_ADMIN_GET_STARTED",
  NONPROFIT_ADMIN_SETTINGS = "NONPROFIT_ADMIN_SETTINGS",
  NONPROFIT_ADMIN_DONATIONS = "NONPROFIT_ADMIN_DONATIONS",
  NONPROFIT_ADMIN_DONORS = "NONPROFIT_ADMIN_DONORS",
  NONPROFIT_ADMIN_FUNDRAISERS = "NONPROFIT_ADMIN_FUNDRAISERS",
  NONPROFIT_ADMIN_PROJECTS = "NONPROFIT_ADMIN_PROJECTS",
  NONPROFIT_ADMIN_BUTTON = "NONPROFIT_ADMIN_BUTTON",
  NONPROFIT_OR_CAUSE = "NONPROFIT_OR_CAUSE",
  NONPROFITS_LANDING = "NONPROFITS_LANDING",
  NONPROFITS_ONBOARDING_SEARCH = "NONPROFITS_ONBOARDING_SEARCH",
  NONPROFITS_ONBOARDING_INTRO = "NONPROFITS_ONBOARDING_INTRO",
  NONPROFITS_PRICING = "NONPROFITS_PRICING",
  NTEE_CATEGORY = "NTEE_CATEGORY",
  PRESS = "PRESS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  PROFILE_SETTINGS = "PROFILE_SETTINGS",
  RELATED_NONPROFITS = "RELATED_NONPROFITS",
  RESET_PASSWORD = "RESET_PASSWORD",
  SEARCH_PAGE = "SEARCH_PAGE",
  SEARCH_RESULTS = "SEARCH_RESULTS",
  CAUSE = "CAUSE",
  CAUSE_NONPROFITS = "CAUSE_NONPROFITS",
  CAUSE_GIFTS = "CAUSE_GIFTS",
  CAUSES = "CAUSES",
  SETTINGS = "SETTINGS",
  DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT",
  REACTIVATE_ACCOUNT = "REACTIVATE_ACCOUNT",
  SIGNUP = "SIGNUP",
  STRIPE_CONNECT = "STRIPE_CONNECT",
  STRIPE_CONNECT_SUCCESS = "STRIPE_CONNECT_SUCCESS",
  STRIPE_CONNECT_FAILED = "STRIPE_CONNECT_FAILED",
  SUBMIT_SURVEY_QUESTION_RESPONSE = "SUBMIT_SURVEY_QUESTION_RESPONSE",
  SUPER_ADMIN = "SUPER_ADMIN",
  SUPPORT = "SUPPORT",
  TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
  USER = "USER",
  USER_LISTS = "USER_FUNDS",
  USER_LIKES = "USER_LIKES",
  USER_JOINS = "USER_JOINS",
  USER_FOLLOWING = "USER_FOLLOWING",
  USER_FOLLOWERS = "USER_FOLLOWERS",
  USER_FUNDRAISERS = "USER_FUNDRAISERS",
  USER_DONATION = "USER_DONATION",
  USER_DONATIONS = "USER_DONATIONS",
  USER_DONATION_SHORT_ID = "USER_DONATION_SHORT",
  VENDORS = "VENDORS",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
  FUNDRAISER_CREATE = "FUNDRAISER_CREATE",
  FUNDRAISER_EDIT = "FUNDRAISER_EDIT",
  SSO_DISCOURSE = "SSO_DISCOURSE",
  MANAGE_NONPROFITS = "MANAGE_NONPROFITS",
  FUNDRAISER = "FUNDRAISER",
  BUTTON = "BUTTON",
  BUTTON_NONPROFIT_SEARCH = "BUTTON_NONPROFIT_SEARCH",
  CONFIGURE_BUTTON = "CONFIGURE_BUTTON",
  DEVELOPER_API_KEYS = "DEVELOPER_API_KEYS",
  DEVELOPER_DOCS = "DEVELOPER_DOCS",
}

export interface ClientRouteMeta {
  /**
   * Next.js route path.
   */
  route?: string;
  path: string;
  routeRestrictions: RouteRestrictions;
  /**
   * If true, meta tag will be appended to tell crawlers not to crawl this page.
   *
   * As a performance improvement, routes can also be added manually to
   * robots.txt to prevent crawlers from even trying to crawl these URLs.
   * However, if a crawler has already indexed a page that wasn't supposed to be
   * indexed (warning on the Google Search Console, for instance), temporarily
   * remove it from robots.txt so that the crawler can see the meta tag and
   * remove it from the index the next time that page is recrawled.
   */
  allowRobots: boolean;
}

export enum RouteRestrictions {
  NONE = "NONE",
  AUTHENTICATION_REQUIRED = "AUTHENTICATION_REQUIRED",
  ONBOARDING_REQUIRED = "ONBOARDING_REQUIRED",
  ONBOARDING_AND_VERIFICATION_REQUIRED = "ONBOARDING_AND_VERIFICATION_REQUIRED",
  ONBOARDING_REQUIRED_IF_AUTH = "ONBOARDING_REQUIRED_IF_AUTH",
}

export const clientRouteMetas: {
  [key in ClientRouteName]: ClientRouteMeta;
} = {
  [ClientRouteName.ABOUT]: {
    path: "/about-us",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.API_LANDING]: {
    path: "/charity-api",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.BLOG]: {
    path: "/blog",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.DONATE_CRYPTO_LANDING]: {
    path: "/donate-crypto",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.BUTTON_NONPROFIT_SEARCH]: {
    path: "/button",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.BUTTON]: {
    path: "/:nonprofitSlug/button",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.CONFIGURE_BUTTON]: {
    path: "/:nonprofitSlug/configure-button",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.DAF_LANDING]: {
    path: "/daf-donations",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.DONATE_STOCK_LANDING]: {
    path: "/donate-stock",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.BRAND]: {
    path: "/brand",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.PRESS]: {
    path: "/press",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.SUPER_ADMIN]: {
    path: "/cheesecake",
    routeRestrictions: RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NEW_NONPROFIT]: {
    path: "/new-nonprofit",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MODERATOR]: {
    path: "/moderator",
    routeRestrictions: RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.HOME]: {
    path: "/",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.LOGIN]: {
    path: "/login",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: false,
  },
  [ClientRouteName.SIGNUP]: {
    path: "/signup",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: false,
  },
  [ClientRouteName.CHOOSE_ACCOUNT]: {
    path: "/choose-account",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: false,
  },
  [ClientRouteName.REDIRECT]: {
    path: "/redirect",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.BUILD_PROFILE]: {
    path: "/build-profile",
    routeRestrictions: RouteRestrictions.AUTHENTICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.RESET_PASSWORD]: {
    path: "/reset-password",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.ME]: {
    path: "/me",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.SETTINGS]: {
    path: "/settings",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.PROFILE_SETTINGS]: {
    path: "/settings/profile",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.DEACTIVATE_ACCOUNT]: {
    path: "/settings/deactivate",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.REACTIVATE_ACCOUNT]: {
    path: "/settings/reactivate",
    routeRestrictions: RouteRestrictions.AUTHENTICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.VERIFY_EMAIL]: {
    path: "/verify-email",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.VERIFICATION_REQUIRED]: {
    path: "/verification-required",
    routeRestrictions: RouteRestrictions.AUTHENTICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.GROUPS]: {
    path: "/groups",
    routeRestrictions: RouteRestrictions.AUTHENTICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.USER]: {
    path: "/@:username",
    route: "/user/[username]",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_LISTS]: {
    path: "/@:username/lists",
    route: "/user/[username]/lists",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_DONATIONS]: {
    path: "/@:username/donations",
    route: "/user/[username]/donations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_LIKES]: {
    path: "/@:username/likes",
    route: "/user/[username]/likes",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_JOINS]: {
    path: "/@:username/joins",
    route: "/user/[username]/joins",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_FOLLOWERS]: {
    path: "/@:username/followers",
    route: "/user/[username]/followers",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: false,
  },
  [ClientRouteName.USER_FOLLOWING]: {
    path: "/@:username/following",
    route: "/user/[username]/following",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: false,
  },
  [ClientRouteName.USER_FUNDRAISERS]: {
    path: "/@:username/fundraisers",
    route: "/user/[username]/fundraisers",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_DONATION_SHORT_ID]: {
    path: "/@:username/:nonprofitSlug/:shortId",
    route: "/user/[username]/:nonprofitSlug/:shortId",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.USER_DONATION]: {
    path: "/@:username/:nonprofitSlug",
    route: "/user/[username]/:nonprofitSlug",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.FUNDRAISE_CRYPTO]: {
    path: "/crypto",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.SEARCH_PAGE]: {
    path: "/new",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.SEARCH_RESULTS]: {
    path: "/search",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.CAUSES]: {
    path: "/causes",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.CAUSE]: {
    path: "/cause/:tagName",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.CAUSE_NONPROFITS]: {
    path: "/cause/:tagName/nonprofits",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.CAUSE_GIFTS]: {
    path: "/cause/:tagName/donations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.FUND_ACCOUNT]: {
    path: `/${FUND_ACCOUNT_NONPROFIT_SLUG}`,
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.FUND_ACCOUNT_CONFIRMATION]: {
    path: `/${FUND_ACCOUNT_NONPROFIT_SLUG}/confirmation`,
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.GIFT_CARD_PURCHASE]: {
    path: `/${GIFT_CARDS_SLUG}`,
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.GIFT_CARD_ORDER]: {
    path: `/${GIFT_CARDS_SLUG}/order/:id`,
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.GIFT_CARD]: {
    path: "/gift",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.LINK_ACCOUNTS]: {
    path: "/link-accounts",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING]: {
    path: "/my-giving",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_RECURRING]: {
    path: "/my-giving/recurring",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_RECURRING_UPDATE]: {
    path: "/my-giving/recurring/:donationId/update",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_HISTORY]: {
    path: "/my-giving/history",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_PAYMENT]: {
    path: "/my-giving/payment",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_ACHIEVEMENTS]: {
    path: "/my-giving/achievements",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MY_GIVING_FUNDRAISERS]: {
    path: "/my-giving/fundraisers",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.PRIVACY_POLICY]: {
    path: "/privacy",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.TERMS_OF_SERVICE]: {
    path: "/terms",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.VENDORS]: {
    path: "/vendors",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.DISBURSEMENTS]: {
    path: "/disbursements",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.SUPPORT]: {
    path: "/support",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.CAREERS]: {
    path: "/careers",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },

  [ClientRouteName.NONPROFITS_LANDING]: {
    path: "/nonprofits",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.NONPROFITS_PRICING]: {
    path: "/pricing",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.GIVERS_LANDING]: {
    path: "/givers",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.NONPROFITS_ONBOARDING_SEARCH]: {
    path: "/nonprofits/get-started",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.NONPROFITS_ONBOARDING_INTRO]: {
    path: "/nonprofits/get-started/:nonprofitSlug",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.INVITE_USERS]: {
    path: "/invite",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.INVITE_JOIN]: {
    path: "/join/@:username",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.STRIPE_CONNECT_FAILED]: {
    path: "/stripe-failed",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.SSO_DISCOURSE]: {
    path: "/sso/discourse",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NTEE_CATEGORY]: {
    path: "/ntee-category/:nteeCode",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.CREATE_TRENDING_CAUSE]: {
    path: "/cause/create",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.EDIT_TRENDING_CAUSE]: {
    path: "/cause/:tagName/edit",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.MANAGE_NONPROFITS]: {
    path: "/manage-nonprofits",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.CHANGE_EMAIL]: {
    path: "/change-email",
    routeRestrictions: RouteRestrictions.AUTHENTICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.DEVELOPER_API_KEYS]: {
    path: "/developer",
    routeRestrictions: RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.DEVELOPER_DOCS]: {
    path: "/docs",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.SUBMIT_SURVEY_QUESTION_RESPONSE]: {
    path: "/survey/respond",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.FISCAL_SPONSORS_LANDING]: {
    path: "/fiscal-sponsors",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.FUNDRAISERS_LANDING]: {
    path: "/fundraisers",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  //
  // NOTE: These routes must be last since they are catch-alls
  //
  [ClientRouteName.NONPROFIT_OR_CAUSE]: {
    path: "/:nonprofitSlug",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.FUND_OR_TAG_GIFTS]: {
    path: "/:nonprofitSlug/donations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.FUND_LEARN_MORE]: {
    path: "/:nonprofitSlug/learn",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.FUND_OR_TAG_NONPROFITS]: {
    path: "/:nonprofitSlug/nonprofits",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.COMMUNITY_FEED]: {
    path: "/:nonprofitSlug/community",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.COMMUNITY_RECOMMENDATIONS]: {
    path: "/:nonprofitSlug/recommendations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.COMMUNITY_DONATIONS]: {
    path: "/:nonprofitSlug/community/donations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.COMMUNITY_MEMBERS]: {
    path: "/:nonprofitSlug/community/members",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
    allowRobots: true,
  },
  [ClientRouteName.RELATED_NONPROFITS]: {
    path: "/:nonprofitSlug/related-nonprofits",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.EDIT_NONPROFIT]: {
    path: "/:nonprofitSlug/edit",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.FUNDRAISER_CREATE]: {
    path: "/:nonprofitSlug/fundraiser",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
  [ClientRouteName.FUNDRAISER_EDIT]: {
    path: "/:nonprofitSlug/f/:fundraiserSlug/edit",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN]: {
    path: "/:nonprofitSlug/admin",
    routeRestrictions: RouteRestrictions.ONBOARDING_AND_VERIFICATION_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_EDIT]: {
    path: "/:nonprofitSlug/admin/edit",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_GET_STARTED]: {
    path: "/:nonprofitSlug/admin/get-started",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_SETTINGS]: {
    path: "/:nonprofitSlug/admin/settings",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_DONATIONS]: {
    path: "/:nonprofitSlug/admin/donations",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_DONORS]: {
    path: "/:nonprofitSlug/admin/donors",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_FUNDRAISERS]: {
    path: "/:nonprofitSlug/admin/fundraisers",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_PROJECTS]: {
    path: "/:nonprofitSlug/admin/projects",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.NONPROFIT_ADMIN_BUTTON]: {
    path: "/:nonprofitSlug/admin/button",
    routeRestrictions: RouteRestrictions.ONBOARDING_REQUIRED,
    allowRobots: false,
  },
  [ClientRouteName.STRIPE_CONNECT_SUCCESS]: {
    path: "/:nonprofitSlug/stripe-success",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.STRIPE_CONNECT]: {
    path: "/:nonprofitSlug/stripe",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: false,
  },
  [ClientRouteName.FUNDRAISER]: {
    path: "/:nonprofitSlug/f/:fundraiserSlug",
    routeRestrictions: RouteRestrictions.NONE,
    allowRobots: true,
  },
};

const pathToRouteMeta = Object.entries(clientRouteMetas).reduce(
  (obj, [clientRouteName, meta]) => {
    obj[meta.path] = clientRouteName;
    return obj;
  },
  {}
);

const routeToRouteMeta = Object.entries(clientRouteMetas)
  .filter(([, value]) => Boolean(value.route))
  .reduce((obj, [clientRouteName, meta]) => {
    obj[meta.route as string] = clientRouteName;
    return obj;
  }, {});

export const getClientRouteFromPath = (
  parsedRoute: string,
  nextRoute: string
): ClientRouteName | string =>
  routeToRouteMeta[nextRoute] || pathToRouteMeta[parsedRoute] || parsedRoute;

function getRoute(
  name: ClientRouteName
): typeof clientRouteMetas[ClientRouteName] {
  const route = clientRouteMetas[name];
  if (!route) {
    throw new Error(`Unknown route: ${name}.`);
  }
  return route;
}

export enum URLFormat {
  /**
   * Fully qualified absolute link
   */
  ABSOLUTE = "ABSOLUTE",
  /**
   * Relative link
   */
  RELATIVE = "RELATIVE",
  /**
   * Absolute without the protocol or leading www
   */
  ABBREVIATED = "ABBREVIATED",
}
export interface GetRoutePathParams {
  name: ClientRouteName;
  tokens?: Record<string, string>;
  query?: Record<string, string>;
  fragment?: string;
  format: URLFormat;
}
export function getRoutePath({
  name,
  tokens = {},
  query,
  fragment,
  format,
}: GetRoutePathParams): string {
  const route = getRoute(name);
  const queryParsed = Object.keys(query || {}).length > 0 ? query : undefined;

  const path = `${pathToRegexp.compile(route.path)(tokens)}${
    queryParsed ? `?${new URLSearchParams(queryParsed).toString()}` : ""
  }${fragment ? `#${fragment}` : ""}`;

  if (format === URLFormat.RELATIVE) {
    return path;
  }
  const absolutePath = new URL(path, WEBSITE_ORIGIN).toString();
  switch (format) {
    case URLFormat.ABSOLUTE:
      return absolutePath;
    case URLFormat.ABBREVIATED:
      return absolutePath.replace(/^https?:[/][/](www.)?/i, "");
  }
}

export function getCauseRoutePath({
  cause,
  ...rest
}: Omit<GetRoutePathParams, "name" | "tokens"> & {
  cause: ValidCauseCategory;
}) {
  const { title } = CauseMetadata[cause];
  return getRoutePath({
    name: ClientRouteName.NONPROFIT_OR_CAUSE,
    tokens: { nonprofitSlug: title.toLowerCase() },
    ...rest,
  });
}

export const DONATE_HASH = "/donate";
export const LEGACY_DONATE_CRYPTO_HASH = "/donate-crypto";
export const NEW_DONATE_CRYPTO_HASH = "/donate/crypto";

export function getDonateRoutePath({
  nonprofitSlug,
  onlyCrypto = false,
  query,
  fundraiserSlug,
  ...rest
}: Omit<GetRoutePathParams, "name" | "tokens" | "fragment"> & {
  nonprofitSlug: string;
  fundraiserSlug?: string;
  onlyCrypto?: boolean;
}) {
  if (onlyCrypto) {
    if (!query) {
      query = {};
    }
    query.method = DonationFlowPaymentOption.CRYPTO;
  }

  return getRoutePath({
    name: fundraiserSlug
      ? ClientRouteName.FUNDRAISER
      : ClientRouteName.NONPROFIT_OR_CAUSE,
    tokens: removeUndefinedOrNullValues({ fundraiserSlug, nonprofitSlug }),
    fragment: onlyCrypto ? NEW_DONATE_CRYPTO_HASH : DONATE_HASH,
    query,
    ...rest,
  });
}

// TODO Some of these params are controlled through DonateRouteModal, and some
// are controlled by the DonateModal itself. Should we unify the handling into
// DonateRouteModal?
export enum DonateModalUrlParams {
  // These params are consumed by `DonateRouteModal`
  DONATE_NONPROFIT_ID = "donateTo",
  JOIN_DONATION_ID = "joinTo",
  JOIN_DONATION_USER_ID = "joinUser",
  MATCH_CAMPAIGN = "match",
  DONATION_HEADER_TEXT = "donation_header_text",
  // Redirects the user after they finish the donation (including comment)
  // Expects a relative url (no external links)
  REDIRECT_URL = "redirect_url",
  FUNDRAISER_ID = "fundraiser_id",
  MIN_VALUE = "min_value",
  FREQUENCY_OPTIONS = "frequency_options",
  REQUIRE_SHARE_INFO = "require_share_info",
  // Comma separated list of all valid DonationFlowPaymentOption
  METHOD = "method",

  // These params are consumed by `DonateModal`
  AMOUNT = "amount",
  FREQUENCY = "frequency",
  DONOR_FIRST_NAME = "first_name",
  DONOR_LAST_NAME = "last_name",
  DONOR_EMAIL = "email",
  DESCRIPTION = "description",
  MONTHLY_TITLE = "monthlyTitle",

  PARTNER_DONATION_ID = "partner_donation_id",
  PARTNER_WEBHOOK_TOKEN = "webhook_token",
  PARTNER_METADATA = "partner_metadata",
  TO_NONPROFITS = "to_nonprofits",
  TO_NONPROFIT_WEIGHTS = "to_nonprofit_weights",
  TO_NONPROFIT_MATCHINGS = "to_nonprofit_matchings",
  RECURRING_MATCHES = "recurring_matches",

  SHARE_INFO = "share_info",
  NO_EXIT = "no_exit",
  // This param immediately redirects the user after their donation is
  // successful (no comment or anything)
  SUCCESS_URL = "success_url",
  // redirects the user after clicking on the exit button
  EXIT_URL = "exit_url",

  // Params required for purchasing gift cards
  GIFT_CARD_AMOUNT = "gift_card_amount",
  GIFT_CARD_QUANTITY = "gift_card_quantity",
  GIFT_CARD_NONPROFIT_ID = "gift_card_nonprofit_ID",
  GIFT_CARD_TAG_ID = "gift_card_tag_ID",

  THEME_COLOR = "theme_color",
  THEME_COLOR_HIGHLIGHT = "theme_color_highlight",

  CRYPTO_AMOUNT = "crypto_amount",
  CRYPTO_CURRENCY = "crypto_currency",

  STOCK_SYMBOL = "stock_symbol",
  STOCK_AMOUNT = "stock_amount",

  SUGGESTED_AMOUNTS = "suggestedAmounts",

  DESIGNATION = "designation",

  PRIVATE_NOTE = "private_note",
  PUBLIC_TESTIMONY = "public_testimony",

  SEARCH_META = "search_meta",

  GIFT_CARD_CODE = "gift_card_code",
}

export enum UrlParams {
  OPEN_NOTIFICATIONS = "notificationsOpen",
}
