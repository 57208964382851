import {
  Tick,
  LabelContainer,
  StyledLabel,
  StyledInput,
  InputTrack,
  InputTicks,
  InputContainer,
  RootContainer,
  popoverContentCss,
  RangeContainer,
} from "@components/CustomRange/styles";
import Popover from "@components/Popover/component";
import React, { useMemo } from "react";

interface CustomRangeProps {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange: (value: number) => void;
  popoverContent: React.ReactNode | string;
  themeColor?: string;
}

export const CustomRange = ({
  onChange,
  min = 0,
  max = 100,
  step,
  value,
  popoverContent,
  themeColor,
}: CustomRangeProps) => {
  const progress = ((value - min) / (max - min)) * 100;
  const steps = step ? (max - min) / step + 1 : 0;

  const ticks = useMemo(() => new Array(steps).fill(<Tick />), [steps]);

  return (
    <RootContainer progress={progress} themeColor={themeColor}>
      <RangeContainer>
        <LabelContainer>
          <StyledLabel htmlFor="range">
            <Popover
              placement="top"
              contentCss={popoverContentCss}
              visible
              content={popoverContent}
              appendTo="parent"
            />
          </StyledLabel>
        </LabelContainer>
        <InputContainer>
          <StyledInput
            id="range"
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e) => onChange(parseInt(e.currentTarget.value))}
          />
          <InputTrack />
          {!!ticks.length && <InputTicks>{ticks}</InputTicks>}
        </InputContainer>
      </RangeContainer>
    </RootContainer>
  );
};
