import { LinkAppearance } from "@components/Link";
import { Loading } from "@components/LoadingIndicator";
import { ActionButtons } from "@components/NewFundraiserCard/components/ActionButtons";
import { CoverSection } from "@components/NewFundraiserCard/components/CoverSection";
import { EndFundraiser } from "@components/NewFundraiserCard/components/EndFundraiser";
import {
  FundraiserCardHeaderSection,
  FundraiserCardHeaderSectionAppearance,
} from "@components/NewFundraiserCard/components/HeaderSection";
import { ThermometerSection } from "@components/NewFundraiserCard/components/ThermometerSection";
import { TitleSection } from "@components/NewFundraiserCard/components/TitleSection";
import {
  bottomBorderCss,
  ContainerCard,
  coverTitleCss,
  InnerSection,
} from "@components/NewFundraiserCard/styles";
import { useFundraiserRaisedRisedData } from "@components/NewFundraiserCard/useFundraiserRaisedRisedData";
import { FULL_SIZE_LINK_CLASS_FUNDRAISER } from "@components/feed/styles";
import Big from "big.js";
import { Props } from "io-ts";
import React from "react";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { FundraiserRaisedFetchStatus } from "src/pages/Fundraiser/fetchFundraiser";

export const FundraiserCard: React.FCC<{
  fundraiser: FundraiserResponse;
  nonprofit: NonprofitResponse;
  showActions?: boolean;
  showHeader?: boolean;
  headerAppearance?: FundraiserCardHeaderSectionAppearance;
  showTextOnCover?: boolean;
  showBorder?: boolean;
  afterHeaderElement?: React.ReactNode;
  rest?: Props;
  clickable?: boolean;
  showStatsForAdmin?: boolean;
  incomingDonationAmount?: Big;
}> = ({
  fundraiser,
  nonprofit,
  rest,
  afterHeaderElement,
  showActions = true,
  showHeader = true,
  headerAppearance,
  showTextOnCover = false,
  showBorder = false,
  clickable = true,
  showStatsForAdmin,
  incomingDonationAmount,
}) => {
  const raisedData = useFundraiserRaisedRisedData(fundraiser);

  if (raisedData === FundraiserRaisedFetchStatus.FUNDRAISER_RAISED_NOT_FOUND) {
    return null;
  }

  if (raisedData === FundraiserRaisedFetchStatus.FETCHING_FUNDRAISER_RAISED) {
    return <Loading />;
  }

  return (
    <ContainerCard shadowOnHover={clickable} {...rest}>
      {clickable ? (
        <Link
          title={`Feed fundraiser card link to ${fundraiser.title}`}
          className={FULL_SIZE_LINK_CLASS_FUNDRAISER}
          data-tname="nonprofitPageFundraisersLink"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.FUNDRAISER,
            tokens: {
              nonprofitSlug: nonprofit.primarySlug,
              fundraiserSlug: fundraiser.slug,
            },
          })}
          appearance={LinkAppearance.UNSTYLED}
        >
          Feed fundraiser card link to {fundraiser.title}
        </Link>
      ) : undefined}
      {showHeader && (
        <FundraiserCardHeaderSection
          fundraiser={fundraiser}
          nonprofit={nonprofit}
          afterHeaderElement={afterHeaderElement}
          showBorder={showBorder}
          appearance={headerAppearance}
        />
      )}
      <CoverSection
        fundraiser={fundraiser}
        nonprofit={nonprofit}
        rounded={!showHeader}
      >
        {showTextOnCover && (
          <TitleSection fundraiser={fundraiser} css={coverTitleCss} />
        )}
      </CoverSection>
      {fundraiser.active ? (
        <InnerSection css={showBorder && bottomBorderCss}>
          {!showTextOnCover && <TitleSection fundraiser={fundraiser} />}
          <ThermometerSection
            fundraiser={fundraiser}
            raisedData={raisedData}
            showStatsForAdmin={showStatsForAdmin}
            incomingDonationAmount={incomingDonationAmount}
          />
          {showActions && (
            <ActionButtons fundraiser={fundraiser} nonprofit={nonprofit} />
          )}
        </InnerSection>
      ) : (
        <InnerSection css={showBorder && bottomBorderCss}>
          <EndFundraiser fundraiser={fundraiser} raisedData={raisedData} />
        </InnerSection>
      )}
    </ContainerCard>
  );
};
