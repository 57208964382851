import { UUID } from "io-ts-types";
import { useCallback, useState } from "react";

import { Button, ButtonTargetKind } from "src/components/Button";
import { Modal, ModalProps } from "src/components/Modal";
import { deleteFundraiser } from "src/pages/Fundraiser/EditForm/actions";
import { ButtonRole, ButtonSize } from "src/styles/button";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";

interface DeleteConfirmationModalProps extends Pick<ModalProps, "isOpen"> {
  onConfirm: () => void;
  onReject: () => void;
  nonprofitName: string;
  fundraiserId: UUID;
  nonprofitId: UUID;
}

export const DeleteConfirmationModal = (
  props: DeleteConfirmationModalProps
) => {
  const [submitting, setSubmitting] = useState(false);

  const onDelete = useCallback(async () => {
    setSubmitting(true);

    await deleteFundraiser(props.fundraiserId, props.nonprofitId);

    props.onConfirm();
  }, [props]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onReject}
      headerText={"Delete fundraiser?"}
      showHeader
      onAfterClose={props.onReject}
    >
      <div css={[verticalStackCss.s, { maxWidth: 440 }]}>
        <p>Deleting a fundraiser cannot be undone</p>
        <p>
          This will NOT delete any donations already made through this
          fundraiser. {props.nonprofitName} will still receive those funds.
        </p>
        <div css={[horizontalStackCss.s, { justifyContent: "space-between" }]}>
          <Button
            data-tname="fundraiserDeleteAbort"
            role={ButtonRole.TEXT_ONLY}
            size={ButtonSize.MEDIUM}
            disabled={submitting}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: props.onReject,
            }}
          >
            Go back
          </Button>
          <Button
            data-tname="fundraiserDeleteConfirm"
            role={ButtonRole.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={submitting}
            submitting={submitting}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: onDelete,
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
