import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { MarkdownText } from "@components/MarkdownText";
import { NewlineAwareText } from "@components/NewlineAwareText";
import { css } from "@emotion/react";
import React, { useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { MediaQueryConstraint } from "src/theme/mediaQueries";

export const TextWithReadMore: React.FCC<{
  text: string;
  numLines: number;
  buttonText?: string;
  mediaQuery?: {
    numLines: number;
  } & MediaQueryConstraint;
  markdown?: boolean;
}> = ({ text, numLines, buttonText, mediaQuery, markdown = false }) => {
  const [shouldReadMoreTruncate, setShouldReadMoreTruncate] =
    useState<boolean>(true);
  const [isReadMoreTruncated, setIsReadMoreTruncated] =
    useState<boolean>(false);

  const readMoreTextRefCallback = (node: HTMLDivElement | null) => {
    if (node !== null) {
      const { scrollHeight, clientHeight } = node;
      if (scrollHeight && clientHeight) {
        setIsReadMoreTruncated(scrollHeight > clientHeight);
      }
    }
  };

  return (
    <React.Fragment>
      {markdown ? (
        <div
          ref={readMoreTextRefCallback}
          css={[
            shouldReadMoreTruncate &&
              truncatedTextCss({ numLines, mediaQuery }),
            css`
              -webkit-box-orient: unset;
            `,
          ]}
        >
          <MarkdownText content={text} />
        </div>
      ) : (
        <NewlineAwareText
          ref={readMoreTextRefCallback}
          content={text}
          css={[
            shouldReadMoreTruncate &&
              truncatedTextCss({ numLines, mediaQuery }),
            css`
              -webkit-box-orient: unset;
            `,
          ]}
        />
      )}
      {isReadMoreTruncated && shouldReadMoreTruncate && (
        <div css={{ paddingTop: spacing.l }}>
          <Button
            data-tname="descriptionLong-SeeMore"
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => setShouldReadMoreTruncate(false),
            }}
          >
            {buttonText ? buttonText : "Read more"}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
