import { brand as iotsBrand, Branded, Int, TypeOf } from "io-ts";
import { NumberFromString } from "io-ts-types/NumberFromString";

export interface BoundedIntIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly BoundedInt: unique symbol;
}

/**
 * @param min Inclusive
 * @param max Inclusive
 */
export const boundedInt = (min: number, max: number) =>
  iotsBrand(
    Int,
    (int): int is Branded<Int, BoundedIntIotsBrand> => {
      return int >= min && int <= max;
    },
    "BoundedInt"
  );

export const boundedIntFromString = (min: number, max: number) =>
  NumberFromString.pipe(boundedInt(min, max));

/**s
 * Codec that confirms that an input value is an integer that is small enough to
 * be safely expressed as a native JavaScript number
 */
export const safeIntCodec = boundedInt(
  Number.MIN_SAFE_INTEGER,
  Number.MAX_SAFE_INTEGER
);
export const safeNonNegativeIntCodec = boundedInt(0, Number.MAX_SAFE_INTEGER);

export type SafeInt = TypeOf<typeof safeIntCodec>;

/**
 * A custom codec to that decodes numeric strings to safely expressable integers
 * allowing non-negative values only
 */
export const nonNegativeIntegerFromStringCodec = NumberFromString.pipe(
  safeNonNegativeIntCodec
);

/**
 * A custom codec to that decodes numeric strings to safely expressable integers
 */
export const integerFromStringCodec = NumberFromString.pipe(safeIntCodec);

export type IntegerFromString = TypeOf<typeof integerFromStringCodec>;

/**
 * Potential statuses of `coerceToSafeInt()`
 */
export enum CoerceToSafeIntStatus {
  /**
   * Number is a safely-sized integer value as is
   */
  IS_SAFE_INT = "IS_SAFE_INT",
  /**
   * Number was not a integer, but after rounding down, is safely sized
   */
  ROUNDED_DOWN = "ROUNDED_DOWN",
  /**
   * Could not coerce to a safely-sized integer - likely that the number is too
   * large to be safely expressed as an integer with Javascript `number`
   */
  COULD_NOT_COERCE = "COULD_NOT_COERCE",
}

interface CoerceToSafeIntParams {
  num: number;
  /**
   * If present, attempts to round down the value
   */
  round?: boolean;
}

/**
 * Coerces a given number to a safe int by checking that it matches the
 * `safeIntCodec`
 */
export function coerceToSafeInt(params: CoerceToSafeIntParams):
  | { status: CoerceToSafeIntStatus.COULD_NOT_COERCE }
  | {
      status: Exclude<
        CoerceToSafeIntStatus,
        CoerceToSafeIntStatus.COULD_NOT_COERCE
      >;
      num: SafeInt;
    } {
  if (safeIntCodec.is(params.num)) {
    return {
      status: CoerceToSafeIntStatus.IS_SAFE_INT,
      num: params.num,
    };
  }
  if (params.round) {
    const roundedDown = parseInt(params.num.toString());
    if (safeIntCodec.is(roundedDown)) {
      return {
        status: CoerceToSafeIntStatus.ROUNDED_DOWN,
        num: roundedDown,
      };
    }
  }
  return {
    status: CoerceToSafeIntStatus.COULD_NOT_COERCE,
  };
}

export function coerceToSafeIntOrThrow(params: CoerceToSafeIntParams): SafeInt {
  const coerceResult = coerceToSafeInt(params);
  if (coerceResult.status !== CoerceToSafeIntStatus.IS_SAFE_INT) {
    throw new Error(
      `${params.num} is not a safe integer value. Coercing returned status: ${coerceResult.status}.`
    );
  }
  return coerceResult.num;
}
