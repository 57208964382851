import { Button, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { PageSection } from "@components/layout/PageSection";
import styled from "@emotion/styled";
import Fuse from "fuse.js";
import { useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link } from "src/components/Link";
import { useSearchOptionsFromUrl } from "src/context/SearchContext/helpers";
import { ButtonRole } from "src/styles/button";
import { LinkAppearance } from "src/styles/link";
import { lightBgThemeCss } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

const InfoBoxContainer = styled.div`
  ${lightBgThemeCss};
  padding: ${spacing.l} 0;
  margin-bottom: ${spacing.m};
  box-shadow: 0px 8px 16px -16px rgba(46, 52, 52, 0.05);
`;

const InfoBoxHeadline = styled.h3`
  flex-grow: 1;

  ${textSizeCss.s};
  text-align: center;
  font-weight: ${FontWeight.REGULAR};
`;

function CryptoInfoBox() {
  const url = getRoutePath({
    name: ClientRouteName.DONATE_CRYPTO_LANDING,
    format: URLFormat.RELATIVE,
  });
  return (
    <InfoBoxHeadline>
      Any nonprofit on Every.org can accept crypto donations,{" "}
      <Link
        data-tname="CryptoInfoBox_Link"
        to={url}
        forceExternal
        appearance={LinkAppearance.HYPERLINK}
      >
        learn more.
      </Link>
    </InfoBoxHeadline>
  );
}

function ButtonInfoBox() {
  const url = "https://github.com/everydotorg/donate-button";
  return (
    <InfoBoxHeadline>
      Any nonprofit on Every.org can use our donate button,{" "}
      <Link
        data-tname="ButtonInfoBox_Link"
        to={url}
        forceExternal
        appearance={LinkAppearance.HYPERLINK}
      >
        try it today.
      </Link>
    </InfoBoxHeadline>
  );
}

function CareerInfoBox() {
  const url = getRoutePath({
    name: ClientRouteName.CAREERS,
    format: URLFormat.RELATIVE,
  });
  return (
    <InfoBoxHeadline>
      Interested in a career at Every.org? See our{" "}
      <Link
        data-tname="CareerInfoBox_Link"
        to={url}
        forceExternal
        appearance={LinkAppearance.HYPERLINK}
      >
        job openings.
      </Link>
    </InfoBoxHeadline>
  );
}

enum InfoBoxType {
  CRYPTO = "CRYPTO",
  BUTTON = "BUTTON",
  CAREER = "CAREER",
}

const PatternByInfoBoxType = {
  [InfoBoxType.CRYPTO]: [
    "crypto",
    "cryptocurrency",
    "crypto button",
    "cryptocurrency button",
  ],
  [InfoBoxType.BUTTON]: ["embed", "embed button", "donate button", "button"],
  [InfoBoxType.CAREER]: ["career", "jobs"],
};

const allPatterns = Object.entries(PatternByInfoBoxType)
  .map(([type, patterns]) => patterns.map((pattern) => ({ type, pattern })))
  .flat();

const InfoBoxTextByInfoBoxType = {
  [InfoBoxType.CRYPTO]: <CryptoInfoBox />,
  [InfoBoxType.BUTTON]: <ButtonInfoBox />,
  [InfoBoxType.CAREER]: <CareerInfoBox />,
};

function useInfoBoxType(): InfoBoxType | null {
  const { query } = useSearchOptionsFromUrl();

  const paternsFuse = new Fuse(allPatterns, {
    keys: ["patterns"],
    shouldSort: true,
    includeScore: true,
  });

  const matched = paternsFuse.search(query).map(({ item }) => item);

  return matched.length ? (matched[0].type as InfoBoxType) : null;
}

export function SearchInfoBox() {
  const infoBoxType = useInfoBoxType();
  const [closedInfoBoxes, setClosedInfoBoxes] = useState<InfoBoxType[]>([]);

  return infoBoxType && !closedInfoBoxes.includes(infoBoxType) ? (
    <InfoBoxContainer>
      <PageSection contentCss={horizontalStackCss.m}>
        {InfoBoxTextByInfoBoxType[infoBoxType]}
        <Button
          data-tname="CloseInfoBox"
          role={ButtonRole.TEXT_ONLY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              setClosedInfoBoxes((prev) => [...prev, infoBoxType]);
            },
          }}
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/XIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
        </Button>
      </PageSection>
    </InfoBoxContainer>
  ) : null;
}
