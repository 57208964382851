import { CryptoTipSlider } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/CryptoTipSlider";
import { DefaultTipSlider } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/DefaultTipSlider";
import { StockTipSlider } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/StockTipSlider";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";

import { PaymentMethod } from "@every.org/common/src/entity/types";

export const AddTipSlider = (props: AddTipProps) => {
  const paymentMethod = props.form.watch("paymentMethod");

  switch (paymentMethod) {
    case PaymentMethod.CRYPTO:
      return <CryptoTipSlider {...props} />;
    case PaymentMethod.STOCKS:
      return <StockTipSlider {...props} />;
    default:
      return <DefaultTipSlider {...props} />;
  }
};
