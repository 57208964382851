import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { getWindow } from "src/utility/window";

export const MobileActionButtonsContainer: React.FCC<{
  headerDonateBlockRef?: React.MutableRefObject<HTMLDivElement | null>;
  alwaysShow?: boolean;
}> = ({ children, headerDonateBlockRef, alwaysShow = false }) => {
  const window = getWindow();

  const [showActionsButtons, setShowActionsButtons] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (headerDonateBlockRef && headerDonateBlockRef.current) {
        const { bottom } = headerDonateBlockRef.current.getBoundingClientRect();
        setShowActionsButtons(bottom <= 0);
      }

      if (window) {
        const scrollPosition = window.innerHeight + window.scrollY;
        const pageHeight = window.document.documentElement.scrollHeight;
        setIsAtBottom(scrollPosition >= pageHeight);
      }
    };

    window?.addEventListener("scroll", handleScroll);
    return () => window?.removeEventListener("scroll", handleScroll);
  }, [headerDonateBlockRef, window]);

  if (!showActionsButtons && !alwaysShow) {
    return null;
  }

  return (
    <div
      css={[
        css`
          position: sticky;
          bottom: 0;
          background: ${isAtBottom
            ? "transparent"
            : "linear-gradient(0deg, #FFFFFF 49.5%, rgba(255, 255, 255, 0.9) 100%)"};
          padding: ${spacing.m};
          z-index: 4;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "none" },
          })}
        `,
      ]}
    >
      {children}
    </div>
  );
};
