import { ButtonSize, ButtonRole } from "@components/Button";
import { FeedCard } from "@components/feed/shared";
import {
  SkeletonAnimationOverlay,
  SkeletonUserSupported,
  SkeletonComment,
  SkeletonImageCover,
  SkeletonButton,
} from "@components/skeleton/SkeletonComponents";
import { css } from "@emotion/react";
import React from "react";

import { horizontalStackCss } from "src/theme/spacing";

function randomInteger(min: number, max: number) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export const SkeletonDonationCard = React.memo(
  function SkeletonDonationCardImpl() {
    return (
      <FeedCard
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        <SkeletonAnimationOverlay />
        <SkeletonUserSupported />
        <SkeletonComment />
        <SkeletonImageCover />
        <div
          css={[
            horizontalStackCss.m,
            css`
              margin-left: auto;
              align-items: center;
            `,
          ]}
        >
          {!!randomInteger(0, 1) && (
            <SkeletonButton
              size={ButtonSize.MEDIUM}
              role={ButtonRole.TEXT_ONLY}
            />
          )}
          <SkeletonButton size={ButtonSize.MEDIUM} role={ButtonRole.PRIMARY} />
        </div>
      </FeedCard>
    );
  }
);
