import { SearchQueryParam } from "../entity/types";
import { SkipInt, TakeInt } from "../routes";
import { SearchParams } from "../routes/search";

// copied from https://github.com/sindresorhus/is-absolute-url/blob/master/index.js
export function isAbsoluteUrl(url: string): boolean {
  if (typeof url !== "string") {
    throw new TypeError("Expected a string");
  }

  return /^[a-z][a-z0-9+.-]*:/.test(url);
}

export function isRelativeUrl(url: string): boolean {
  return !isAbsoluteUrl(url);
}

export function searchParamToUrlQuery(
  params: Omit<SearchParams, "take" | "skip"> & {
    address?: string;
    skip?: SkipInt;
    take?: TakeInt;
    includeCount?: boolean;
  }
): Record<string, string> {
  const { query, causes, lat, lng, size, address, includeCount } = params;
  return Object.fromEntries(
    Object.entries({
      [SearchQueryParam.SEARCH_TERM]: query,
      [SearchQueryParam.CAUSES]: causes,
      [SearchQueryParam.LAT]: lat,
      [SearchQueryParam.LNG]: lng,
      [SearchQueryParam.ORGANIZATION_SIZE]: size,
      [SearchQueryParam.ADDRESS]: address,
      [SearchQueryParam.INCLUDE_COUNT]: includeCount,
    })
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, `${value}`])
  );
}
