import { DonatePage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { UseFormReturn } from "react-hook-form";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export const ManualDAFPage = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
}) => {
  return (
    <DonatePage
      form={form}
      formContext={formContext}
      nonprofit={nonprofit}
      fundraiser={fundraiser}
      paymentOption={DonationFlowPaymentOption.DAF}
      isManualDaf
    />
  );
};
