import { preformRaisedAmounts } from "@components/NewFundraiserCard/preformRaisedAmounts";
import { css } from "@emotion/react";
import { Big } from "big.js";

import {
  FundraiserResponse,
  FundraiserRaisedResponse,
} from "@every.org/common/src/codecs/entities";
import { colorPalette } from "@every.org/common/src/display/palette";
import { spacing } from "@every.org/common/src/display/spacing";

import { verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

export const EndFundraiser = ({
  fundraiser,
  raisedData,
}: {
  fundraiser: FundraiserResponse;
  raisedData: FundraiserRaisedResponse;
}) => {
  const { totalAmount } = preformRaisedAmounts({ fundraiser, raisedData });

  return (
    <div css={(verticalStackCss.m, { textAlign: "center" })}>
      <h4>
        <span css={{ color: colorPalette.teal }}>
          {displayCurrencyValueInUserLocale({
            currencyValue: {
              currency: raisedData.currency,
              amount: new Big(totalAmount.toFixed(0)),
            },
          })}
        </span>{" "}
        raised
      </h4>
      <div css={{ color: colorPalette.teal }}>
        {raisedData.supporters} supporters
      </div>
      <div
        css={css`
          font-weight: ${FontWeight.BOLD};
          margin-top: ${spacing.s};
        `}
      >
        This fundraiser has ended
      </div>
    </div>
  );
};
