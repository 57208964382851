import * as t from "io-ts";
import Cookies, { CookieAttributes } from "js-cookie";

import { decodeOrThrow } from "@every.org/common/src/codecs/index";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import { IotsCodecError } from "@every.org/common/src/errors/IotsCodecError";
import {
  UTM_QUERY_PARAM_TO_VAR_NAME,
  UtmMetadata,
} from "@every.org/common/src/helpers/analytics";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { logger } from "src/utility/logger";

export function getUTMValuesFromCookie(): UtmMetadata {
  return Object.fromEntries(
    Object.values(UTM_QUERY_PARAM_TO_VAR_NAME).map((utmVar) => [
      utmVar,
      Cookies.get(utmVar),
    ])
  );
}

export function getDataFromCookie<Codec extends t.Any>(
  key: string,
  codec: Codec
): t.TypeOf<Codec> | null {
  const cookieRaw = Cookies.get(key);
  if (!cookieRaw) {
    return null;
  }
  const cookieData = decodeURIComponent(cookieRaw);
  try {
    return decodeOrThrow(codec, JSON.parse(cookieData));
  } catch (err) {
    const message = `Stored invalid ${
      err instanceof IotsCodecError ? "data" : "JSON"
    } in ${key} cookie`;
    logger.warn({ data: { cookieData }, message });
    return null;
  }
}

export function setCookie(
  key: CookieKey,
  value: string,
  options?: CookieAttributes
) {
  // eslint-disable-next-line no-restricted-properties
  Cookies.set(key, value, {
    domain: assertEnvPresent(
      process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      "NEXT_PUBLIC_COOKIE_DOMAIN"
    ),
    secure: true,
    ...(options ? options : {}),
  });
}

export function parseCookieFromString(
  cookieString: string | undefined
): Record<string, string> {
  if (!cookieString) {
    return {};
  }

  return cookieString
    .split(";")
    .map((keyval) => keyval.trim().split("="))
    .reduce((obj, val) => {
      const [key, value] = val;
      obj[key] = value;
      return obj;
    }, {});
}
