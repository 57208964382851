import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";

export function wrapStyleInFocusBlock(style: CSSInterpolation) {
  return css`
    &[data-focus-visible-added]:focus {
      ${style};
    }
  `;
}
