import Script from "next/script";
import { Fragment } from "react";

import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export const VendorScripts = () => (
  <Fragment>
    <Script id="gtag">
      {`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${assertEnvPresent(
        process.env.NEXT_PUBLIC_GTM_ID,
        "NEXT_PUBLIC_GTM_ID"
      )}");`}
    </Script>
    <Script id="gtag-datalayer">
      {`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }`}
    </Script>
    <Script id="hotjar">
      {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${assertEnvPresent(
          process.env.NEXT_PUBLIC_HOTJAR_ID,
          "NEXT_PUBLIC_HOTJAR_ID"
        )},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;r.defer=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
    </Script>
    <noscript>
      {/* Google Tag Manager (noscript) */}
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${assertEnvPresent(
          process.env.NEXT_PUBLIC_GTM_ID,
          "NEXT_PUBLIC_GTM_ID"
        )}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  </Fragment>
);
