import { TextInput, TextInputType } from "@components/TextInput";
import { FullNameSection } from "@components/donate/DonateV3/PaymentProcess/components/FullNameSection";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { validateUserEmail } from "@components/donate/DonateV3/PaymentProcess/validators";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";

const StyledTextInput = styled(TextInput)`
  label {
    ${cssForMediaSize({
      min: MediaSize.MEDIUM,
      css: textSizeCss[TextSize.s],
    })}
  }
`;

export const DonorInfo = ({
  form,
  formContext,
}: {
  form: Omit<UseFormReturn<DonateFormType>, "handleSubmit">;
  formContext: DonateFormContext;
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const email = watch("email");
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const userEmailError = errors.email;

  useEffect(() => {
    // reset payment sources on user info change
    // and force the user to attach a new one
    // to prevent the "source is attached to a different customer" error.
    formContext.setPaymentSources([]);
    form.setValue("paymentSource", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, firstName, lastName]);

  return (
    <div
      css={css`
        > :not(:last-child) {
          margin-bottom: ${spacing.oldM};
        }
      `}
    >
      <FullNameSection control={control} />
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, value } }) => (
          <StyledTextInput
            type={TextInputType.EMAIL}
            labelText="Email"
            name="email"
            id="email"
            data-tname="email"
            autoComplete="email"
            value={value}
            onChange={onChange}
            onBlur={() => validateUserEmail(email, form.setError)}
            validationStatus={
              userEmailError && {
                success: false,
                message: userEmailError.message,
              }
            }
            required
            collapseDescriptionSpace
          />
        )}
      />
      {/* {errorMessage && <ErrorMessage text={errorMessage} />} */}
    </div>
  );
};
