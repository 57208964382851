/**
 * Checks if the user has all fields expected after the user has completed sign
 * up. Failing this check indicates that the user needs to go through sign up.
 * @param user
 */
export function isMissingRequiredUserFields(user: any) {
  const emailMissing =
    user.entityName === "User Personal" ? !user.email : false;
  return emailMissing || !user.firstName || !user.lastName || !user.username;
}

// TODO(#11746) this file should be following, but then it won't build
// import {
//   EntityName,
//   PersonalUserResponse,
//   UserResponse,
// } from "@every.org/common/src/codecs/entities";

// /**
//  * Checks if the user has all fields expected after the user has completed sign
//  * up. Failing this check indicates that the user needs to go through sign up.
//  * @param user
//  */
// export function isMissingRequiredUserFields(
//   user: PersonalUserResponse | UserResponse
// ) {
//   const emailMissing =
//     user.entityName === EntityName.USER_PERSONAL ? !user.email : false;
//   return emailMissing || !user.firstName || !user.lastName || !user.username;
// }
