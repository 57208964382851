import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

interface StepperProps {
  steps: string[];
  activeStep: number;
}

//#region Styles
const SteppertText = styled.span`
  ${textSizeCss.xs};
  color: var(${colorCssVars.text.secondary});
  display: none;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: { display: "unset" },
  })}
`;

const StepperNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${textSizeCss.xs};
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-weight: ${FontWeight.BOLD};

  background-color: var(${colorCssVars.background.faded});
  color: var(${colorCssVars.text.secondary});
`;

const activeStepperItemCss = css`
  ${StepperNumber} {
    background-color: var(${colorCssVars.accent.large});
    color: white;
  }
  ${SteppertText} {
    display: unset;
    color: var(${colorCssVars.text.body});
  }
`;

const StepperItem = styled.div<{ active: boolean }>`
  ${horizontalStackCss.xs}
  align-items: center;

  ${({ active }) => active && activeStepperItemCss}
`;

const StepperContainer = styled.div`
  display: flex;
  gap: ${spacing.xs};
  flex-wrap: wrap;
`;
//#endregion

export function Stepper({ steps, activeStep }: StepperProps) {
  if (steps.length === 0) {
    return null;
  }

  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <StepperItem key={index} active={index === activeStep}>
          <StepperNumber>{index + 1}</StepperNumber>
          <SteppertText>{step}</SteppertText>
          {index !== steps.length - 1 && (
            <Icon
              css={{
                transform: "rotate(270deg)",
                color: "#CCCCCC",
              }}
              iconImport={() =>
                import("@components/Icon/icons/SelectArrowIcon")
              }
              size={IconSize.X_SMALL}
              display={IconDisplay.CURRENT_COLOR}
            />
          )}
        </StepperItem>
      ))}
    </StepperContainer>
  );
}
