export function getEnv(name: string): string {
  const value = process.env[name];
  if (value === undefined) {
    throw Error(`${name} not present, add to environment (e.g. via Doppler)`);
  }
  return value;
}

export function getEnvBoolean(name: string) {
  const res = process.env[name];
  if (!res) {
    return false;
  }
  return res.toLocaleLowerCase() === "true";
}

export function assertEnvPresent(
  value: string | undefined,
  // Used for creating the error message if variable is not found
  variableName: string
): string {
  if (value === undefined) {
    throw Error(
      `Environment variable was expected to be present, but was missing for: ${variableName}`
    );
  }
  return value;
}
