interface URL {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  readonly origin: string;
  password: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  username: string;
  toString(): string;
}

declare let URL: {
  prototype: URL;
  new (url: string, base?: string): URL;
};

export function isValidUrl(
  url: string,
  options?: {
    requireHttps?: boolean;
  }
) {
  try {
    const parsedUrl = new URL(url);
    if (options && options.requireHttps && parsedUrl.protocol !== "https:") {
      return false;
    }
    if (!parsedUrl.protocol.startsWith("http")) {
      return false;
    }
    const parsedStr = parsedUrl.toString();
    /*
     * Also test if trailing slash is equivalent because new URL("https://test.com").toString() === "https://test.com"
     * as it test only for trailing slash, it not requaired to be case-sensetive test
     * it is possible to convert everything to lower case
     * and the test won't break if the case-insensitive part of url is different (#12878)
     *                   v                                                            v
     * new URL("https://cAseinsensitive.com/CaSe-SeNsEtIvE").toString() === "https://caseinsensitive.com/CaSe-SeNsEtIvE
     */
    return (
      url.toLocaleLowerCase() === parsedStr.toLocaleLowerCase() ||
      (url + "/").toLocaleLowerCase() === parsedStr.toLocaleLowerCase()
    );
  } catch (e) {
    return false;
  }
}
