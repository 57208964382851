import React, { useReducer } from "react";

import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ButtonTargetKind, OnClick } from "src/components/Button";
import { getWindow } from "src/utility/window";

export enum ToastRole {
  ERROR = "ERROR",
  MESSAGE = "MESSAGE",
  ACCENT = "ACCENT",
}

export const DEFAULT_TOAST_TIMEOUT = 10000; // 10 seconds
interface Toast {
  role: ToastRole;
  message: string | React.ReactNode;
  onClick?: OnClick;
  markedToExit?: boolean;
  // Remove self if clicked
  removeOnClick?: boolean;
}

export enum ToastType {
  API_DOWN = "API_DOWN",
  RATE_LIMITED = "RATE_LIMITED",
  MUST_BE_LOGGED_IN = "MUST_BE_LOGGED_IN",
  NO_INTERNET = "NO_INTERNET",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  IMAGE_TOO_LARGE = "IMAGE_TOO_LARGE",
  AUTH_ERROR = "AUTH_ERROR",
}

export type ToastsState = Map<ToastType, Toast>;

export enum ToastsActionType {
  ADD_TOAST = "ADD_TOAST",
  REMOVE_TOAST = "REMOVE_TOAST",
  MARK_TO_EXIT = "MARK_TO_EXIT",
}

interface AddToast {
  type: ToastsActionType.ADD_TOAST;
  toastType: ToastType;
}

interface RemoveToast {
  type: ToastsActionType.REMOVE_TOAST;
  toastType: ToastType;
}

interface MarkToExit {
  type: ToastsActionType.MARK_TO_EXIT;
  toastType: ToastType;
}

export const TOAST_BY_TYPE: { [type in ToastType]: Toast } = {
  [ToastType.RATE_LIMITED]: {
    role: ToastRole.ERROR,
    message: (
      <React.Fragment>
        <b>You have been temporarily rate limited.</b> Please try again later.
      </React.Fragment>
    ),
  },
  [ToastType.API_DOWN]: {
    role: ToastRole.ERROR,
    message: (
      <React.Fragment>
        <b>Our website is experiencing some problems.</b> Please try again
        later.
      </React.Fragment>
    ),
  },
  [ToastType.MUST_BE_LOGGED_IN]: {
    role: ToastRole.ACCENT,
    message: (
      <React.Fragment>
        <b>Please sign up or log in</b> to proceed
      </React.Fragment>
    ),
    onClick: {
      kind: ButtonTargetKind.LINK,
      to: getRoutePath({
        name: ClientRouteName.SIGNUP,
        tokens: {},
        query: {},
        format: URLFormat.RELATIVE,
      }),
    },
  },
  [ToastType.NO_INTERNET]: {
    role: ToastRole.ERROR,
    message: (
      <React.Fragment>
        You are offline. Please connect then <b>refresh</b>.
      </React.Fragment>
    ),
    onClick: {
      kind: ButtonTargetKind.FUNCTION,
      action: () => getWindow()?.location.reload(),
    },
  },
  [ToastType.EMAIL_VERIFICATION]: {
    role: ToastRole.ACCENT,
    message: "Thank you for verifying your email address!",
  },
  [ToastType.IMAGE_TOO_LARGE]: {
    role: ToastRole.ERROR,
    message: (
      <React.Fragment>
        Image too large, maximum image size is 10MB.
      </React.Fragment>
    ),
  },
  [ToastType.AUTH_ERROR]: {
    role: ToastRole.ERROR,
    message: (
      <React.Fragment>
        <b>An unknown error occurred during log in. Please try again.</b>
      </React.Fragment>
    ),
  },
};

type ToastsAction = AddToast | RemoveToast | MarkToExit;

export function toastReducer(
  state: ToastsState,
  action: ToastsAction
): ToastsState {
  const newState = new Map(state);
  const itemToMark = state.get(action.toastType);

  switch (action.type) {
    case ToastsActionType.ADD_TOAST:
      if (!state.has(action.toastType)) {
        const toast = TOAST_BY_TYPE[action.toastType];
        if (toast.removeOnClick) {
          // We are being a little lazy and not removing this event
          // listener if REMOVE_TOAST is being called for some other
          // reason, but that should not cause any issues.
          getWindow()?.addEventListener(
            "click",
            function () {
              removeToast(action.toastType);
            },
            { once: true }
          );
        }
        newState.set(action.toastType, toast);
      }
      break;
    case ToastsActionType.REMOVE_TOAST:
      newState.delete(action.toastType);
      break;
    case ToastsActionType.MARK_TO_EXIT:
      if (itemToMark) {
        newState.set(action.toastType, {
          ...itemToMark,
          markedToExit: true,
        });
      }
  }
  return newState;
}

const initialState: ToastsState = new Map();

let dispatchToastsAction: React.Dispatch<ToastsAction>;

export const useToasts = () => {
  const [toastsState, ToastsDispatcher] = useReducer(
    toastReducer,
    initialState
  );

  dispatchToastsAction = ToastsDispatcher;

  return toastsState;
};

const TIMEOUT_DISABLED = Symbol("TIMEOUT_DISABLED");

interface AddToastOptions {
  timeout?: number | typeof TIMEOUT_DISABLED;
  removeOnClick?: boolean;
}

/**
 * @param options
 * @default { timeout: 10000 }
 */
export function addToast(toastType: ToastType, options?: AddToastOptions) {
  const { timeout = DEFAULT_TOAST_TIMEOUT } = options || {};

  dispatchToastsAction &&
    dispatchToastsAction({
      type: ToastsActionType.ADD_TOAST,
      toastType,
    });

  if (typeof timeout !== "symbol") {
    setTimeout(() => {
      removeToast(toastType);
    }, timeout);
  }
}

export const EXIT_ANIMATION_DURATION = 300;

export function removeToast(toastType: ToastType) {
  dispatchToastsAction &&
    dispatchToastsAction({
      type: ToastsActionType.MARK_TO_EXIT,
      toastType,
    });

  setTimeout(() => {
    dispatchToastsAction &&
      dispatchToastsAction({
        type: ToastsActionType.REMOVE_TOAST,
        toastType,
      });
  }, EXIT_ANIMATION_DURATION);
}
