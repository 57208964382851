/**
 * Common components for displaying payment methods.
 */

import {
  Button,
  ButtonRole,
  ButtonProps,
  ButtonTargetKind,
} from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { PopoverInfoButton } from "@components/PopoverInfoButton";
import { MAX_TEXT_INPUT_WIDTH } from "@components/TextInput";
import {
  OpenPlaidModalParams,
  openPlaidModal,
  closePlaidModal,
} from "@components/donate/ConnectAccountWithPlaid";
import {
  AddCreditCard,
  AddCreditCardProps,
} from "@components/donate/DonateV3/PaymentProcess/components/AddCreditCard";
import { createGuestUserIfNeeded } from "@components/donate/DonateV3/PaymentProcess/helpers";
import { SecondaryText } from "@components/textHelpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useCallback, useState } from "react";

import {
  EntityName,
  PaymentSourceResponse as PaymentSource,
  StripePaymentSourceResponse as StripePaymentSource,
} from "@every.org/common/src/codecs/entities";
import {
  colorPalette,
  SHARED_PALETTE,
} from "@every.org/common/src/display/palette";
import {
  PaymentMethod,
  PaymentSourceStatus,
  PaymentSourceType,
} from "@every.org/common/src/entity/types";
import { searchParamToBool } from "@every.org/common/src/helpers/string";
import { createPlaidLinkTokenRouteSpec } from "@every.org/common/src/routes/donate";

import {
  useABTestingId,
  useLoggedInOrGuestUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { colorCssVars } from "src/theme/color";
import { roundedBorder, BORDER_RADIUS } from "src/theme/common";
import {
  spacing,
  horizontalStackCss,
  verticalStackCss,
} from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { trackEvent } from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { buildUrlPath } from "src/utility/url";
import { getWindow } from "src/utility/window";

export const PaymentItemLabel = styled.span`
  ${horizontalStackCss.xs};
  flex-wrap: wrap;
  align-items: center;
`;

const PaymentItemEnd = styled.div``;

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_TEXT_INPUT_WIDTH};

  > *:not(:last-child) {
    margin-bottom: ${spacing.xs};
  }
`;

export const paymentItemCss = css`
  width: 100%;
  ${horizontalStackCss.xs};
  ${textSizeCss[TextSize.s]};
  background-color: var(${colorCssVars.input.background.focus});
  align-items: center;
  padding: ${spacing.s} ${spacing.m};
  ${PaymentItemEnd} {
    margin-left: auto;
  }
  &:hover {
    background-color: var(${colorCssVars.input.background.default});
  }
`;
interface PaymentItemButtonProps extends Omit<ButtonProps, "role"> {}
export const PaymentItemButton = React.forwardRef<
  HTMLElement,
  PaymentItemButtonProps
>(function PaymentItemButtonImpl(props, ref) {
  return (
    <Button
      ref={ref}
      css={css`
        width: 100%;
      `}
      contentCss={[
        paymentItemCss,
        !props.disabled &&
          css`
            cursor: pointer;
            :hover {
              background-color: var(${colorCssVars.background.faded});
            }
          `,
      ]}
      role={ButtonRole.UNSTYLED}
      {...props}
    />
  );
});

export const PaymentMethodList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${roundedBorder};
  border: 1px solid ${colorPalette.grayLight};
  > li:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: calc(100% - ${spacing.m} * 2);
      position: absolute;
      z-index: 1;
      bottom: 0;
      margin: 0 ${spacing.m};
      border-bottom: 1px solid ${colorPalette.grayLight};
    }
  }

  > li > [data-focus-visible-added]:focus {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      box-shadow: inset 0 0 0 2px ${SHARED_PALETTE.focus};
      border-top-left-radius: ${BORDER_RADIUS};
      border-top-right-radius: ${BORDER_RADIUS};
    }
  }

  > li:not(:first-of-type) > [data-focus-visible-added]:focus {
    &::after {
      border-radius: 0px;
    }
  }

  > li:last-child > [data-focus-visible-added]:focus {
    &::after {
      border-bottom-left-radius: ${BORDER_RADIUS};
      border-bottom-right-radius: ${BORDER_RADIUS};
    }
  }
`;

const PLAID_TRIGGER_URL_PARAM = "add_bank";

/**
 * Used to display in Payment metods list while real payment source loads
 */
export const bankPlaceholderItem: StripePaymentSource = {
  entityName: EntityName.PAYMENT_SOURCE,
  paymentMethod: PaymentMethod.STRIPE,
  id: "placeholder",
  externalId: "placeholder",
  type: PaymentSourceType.ACH_DEBIT,
  brandName: "BANK",
  displayId: "",
  status: PaymentSourceStatus.CONNECTED,
  default: false,
};

/**
 * Renders a bank payment item that received a click to open the Plaid modal.
 * For pending add bank flows (e.g. in the case of microdeposits), provide a
 * pendingBankSource to open the modal to that flow.
 */
export const AddBankPaymentItem = React.forwardRef<
  HTMLElement,
  OpenPlaidModalParams & {
    pendingBankSource?: PaymentSource;
    pendingDeleteButton?: JSX.Element;
  }
>(function AddBankPaymentItemImpl(
  {
    onExit,
    onPlaidConnectionSuccess,
    onCompletedAddingAccount,
    pendingBankSource,
    pendingDeleteButton,
    firstName,
    lastName,
    email,
  },
  ref
) {
  const window = getWindow();
  const router = useEdoRouter();
  const [plaidIsOpen, setPlaidIsOpen] = useState(false);
  const [linkToken, setLinkToken] = useState<string>();

  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();
  const abTestingId = useABTestingId();
  const deletePlaidTriggerUrlParam = useCallback(() => {
    // We use window.location b/c next router wasn't picking up the latest hash
    // properly (potentially a conflict between NextRouter and ReactRouter state?)
    if (!window) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(PLAID_TRIGGER_URL_PARAM);
    router.replace(
      buildUrlPath({
        pathname: window.location.pathname,
        search: searchParams,
        hash: window.location.hash,
      })
    );
  }, [router, window]);
  const sourceId = pendingBankSource?.id;

  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      if (loggedInOrGuestUser) {
        const { linkToken } = await queryApi(createPlaidLinkTokenRouteSpec, {
          routeTokens: {},
          queryParams: {},
          body: sourceId ? { paymentSourceId: sourceId } : {},
        });
        return linkToken;
      }
      return undefined;
    }, [sourceId, loggedInOrGuestUser]),
    handleResponse: useCallback((linkToken: string | undefined) => {
      if (linkToken) {
        setLinkToken(linkToken);
      }
    }, []),
    handleError: useCallback((error) => {
      logger.error({
        error,
        message: "Error while fetching Plaid link token",
      });
    }, []),
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(router.search);
    const addBank = searchParamToBool(
      searchParams.get(PLAID_TRIGGER_URL_PARAM)
    );
    if (addBank && !plaidIsOpen && linkToken) {
      trackEvent("Viewed add bank account form", {});
      setPlaidIsOpen(true);
      openPlaidModal({
        linkToken,
        onPlaidConnectionSuccess: () => {
          deletePlaidTriggerUrlParam();
          onPlaidConnectionSuccess();
        },
        onCompletedAddingAccount: (props) => {
          deletePlaidTriggerUrlParam();
          onCompletedAddingAccount(props);
        },
        onExit: () => {
          router.back();
          onExit();
        },
        firstName,
        lastName,
        email,
      });
    } else if (!addBank && plaidIsOpen) {
      setPlaidIsOpen(false);
      closePlaidModal();
    }
  }, [
    router,
    deletePlaidTriggerUrlParam,
    onPlaidConnectionSuccess,
    onExit,
    onCompletedAddingAccount,
    plaidIsOpen,
    linkToken,
    firstName,
    lastName,
    email,
  ]);

  const openPlaidAction = useCallback(async () => {
    // properly (potentially a conflict between NextRouter and ReactRouter state?)
    if (!window) {
      return;
    }
    await createGuestUserIfNeeded({
      router,
      abTestingId,
      loggedInOrGuestUser,
      firstName,
      lastName,
      email,
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append(PLAID_TRIGGER_URL_PARAM, "true");
    router.push(
      buildUrlPath({
        pathname: window.location.pathname,
        search: searchParams,
        hash: window.location.hash,
      })
    );
  }, [
    window,
    abTestingId,
    loggedInOrGuestUser,
    firstName,
    lastName,
    email,
    router,
  ]);

  if (!pendingBankSource) {
    return (
      <PaymentItemButton
        ref={ref}
        data-tname={"paymentMethodAddBank"}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: openPlaidAction,
        }}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/PlusIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.ACCENT}
        />
        <PaymentItemLabel>Add bank</PaymentItemLabel>
        <SecondaryText css={{ marginLeft: "auto" }}>Give 100%</SecondaryText>
        <PopoverInfoButton text="The bank ACH fees are so low that donors to the Every.org platform cover them, so nonprofits receive 100% of your donation!" />
      </PaymentItemButton>
    );
  }
  return (
    <div css={[paymentItemCss, { position: "relative" }]}>
      <Button
        data-tname={"paymentMethodVerifyPendingBank"}
        disabled={pendingBankSource.status !== PaymentSourceStatus.PENDING}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: openPlaidAction,
        }}
        role={ButtonRole.UNSTYLED}
        css={css`
          position: absolute;
          left: 0;
          width: 100%;
          top: 0;
          bottom: 0;
          z-index: 1;
        `}
      />
      <Icon
        iconImport={() => import("@components/Icon/icons/BankIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.SECONDARY}
      />
      <PaymentItemLabel>{`${pendingBankSource.brandName} *${pendingBankSource.displayId}`}</PaymentItemLabel>
      <PaymentItemEnd
        css={[
          horizontalStackCss.xs,
          css`
            z-index: 2;
          `,
        ]}
      >
        {pendingBankSource.status === PaymentSourceStatus.PENDING ? (
          <Button
            data-tname={"paymentMethodVerifyPendingBank"}
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: openPlaidAction,
            }}
          >
            Verify pending
          </Button>
        ) : (
          <span>Couldn&apos;t connect, please retry</span>
        )}
        {pendingDeleteButton}
      </PaymentItemEnd>
    </div>
  );
});

const IncursFees: React.FCC = () => (
  <SecondaryText css={{ marginLeft: "auto" }}>Incurs fees</SecondaryText>
);

export const AddCardPaymentItem: React.FCC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <PaymentItemButton
      data-tname={"paymentMethodAddCard"}
      onClick={{ kind: ButtonTargetKind.FUNCTION, action: onClick }}
    >
      <Icon
        iconImport={() => import("@components/Icon/icons/PlusIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.ACCENT}
      />
      <PaymentItemLabel>Use new card</PaymentItemLabel>
      <IncursFees />
      <PopoverInfoButton text="For Visa and Mastercard the fee is 2.2% + $0.30. For Amex, it's a flat 3.5% fee, and cards based outside the US incur an additional 1%." />
    </PaymentItemButton>
  );
};

function renderLogo(source: PaymentSource) {
  if (source.paymentMethod === PaymentMethod.PAYPAL) {
    return (
      <Icon
        iconImport={() => import("@components/Icon/icons/Paypal")}
        size={IconSize.MEDIUM}
        display={IconDisplay.SECONDARY}
      />
    );
  }
  if (source.type === PaymentSourceType.CARD) {
    return (
      <Icon
        iconImport={() => import("@components/Icon/icons/CardIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.SECONDARY}
      />
    );
  }
  if (source.type === PaymentSourceType.ACH_DEBIT) {
    return (
      <Icon
        iconImport={() => import("@components/Icon/icons/BankIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.SECONDARY}
      />
    );
  }
  return null;
}

interface PaymentItemProps {
  source: PaymentSource;
  onClick?: () => void;
  /**
   * if present with onClick, disables click action
   */
  disabled?: boolean;

  /**
   * Optional to render after the payment method name.
   */
  suffix?: React.ReactNode;

  /**
   * Optional to render at the end of the item.
   */
  end?: React.ReactNode;
  tabIndex?: number;
}
export function PaymentMethodItem({
  source,
  onClick,
  suffix,
  end,
  disabled = false,
  tabIndex,
}: PaymentItemProps) {
  const isBankPlaceholderItem = source.id === bankPlaceholderItem.id;
  return (
    <div
      css={[
        paymentItemCss,
        css`
          position: relative;
        `,
      ]}
    >
      {!isBankPlaceholderItem && onClick && (
        <Button
          data-tname="choosePaymentMethodButton"
          role={ButtonRole.UNSTYLED}
          disabled={disabled}
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            bottom: 0;
            z-index: 1;
          `}
          onClick={{ kind: ButtonTargetKind.FUNCTION, action: onClick }}
          tabIndex={tabIndex}
        />
      )}
      {renderLogo(source)}
      <PaymentItemLabel data-tname="paymentMethodName">
        <b>
          {source.brandName}{" "}
          {!isBankPlaceholderItem && source.displayId && (
            <React.Fragment>*{source.displayId}</React.Fragment>
          )}
        </b>
        {suffix}
      </PaymentItemLabel>
      {source.paymentMethod === PaymentMethod.PAYPAL ? <IncursFees /> : null}
      {source.paymentMethod === PaymentMethod.PAYPAL && (
        <div
          css={css`
            z-index: 2;
          `}
        >
          <PopoverInfoButton text="The fee for both PayPal and Venmo is 2.2% + $0.30." />
        </div>
      )}
      {end && (
        <PaymentItemEnd
          css={[
            horizontalStackCss.s,
            css`
              z-index: 2;
            `,
          ]}
        >
          {end}
        </PaymentItemEnd>
      )}
    </div>
  );
}

export const AddNewCardPaymentWidget: React.FCC<AddCreditCardProps> = (
  props
) => (
  <section css={verticalStackCss.s}>
    <AddCreditCard {...props} />
  </section>
);
