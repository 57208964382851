import { useContext, useState, useEffect } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { isUuid } from "@every.org/common/src/helpers/string";
import {
  TakeInt,
  SkipInt,
  LIST_PARAMS_TAKE_MAX,
} from "@every.org/common/src/routes/index";

import { TagsContext } from "src/context/TagContext/";
import { fetchTag, fetchTrendingTags } from "src/context/TagContext/actions";
import { getTrendingTags, getTag } from "src/context/TagContext/selectors";
import {
  TagFetchStatus,
  TrendingTagsFetchStatus,
  TagsState,
  TagIdentifier,
  TagOrFetchStatus,
} from "src/context/TagContext/types";

/**
 * Synchronously get a tag and kick off an async fetch if the tag
 * is not in our state.
 */
function getOrFetchTag(
  dispatchTagsAction: Exclude<TagsState["dispatchTagsAction"], undefined>,
  state: TagsState,
  identifier: TagIdentifier | string,
  onlyActive = true
) {
  if (typeof identifier === "string") {
    identifier = isUuid(identifier)
      ? { id: identifier }
      : { tagName: identifier };
  }

  const tag = getTag(state, identifier);
  if (tag === undefined) {
    fetchTag(dispatchTagsAction, identifier, onlyActive);
  }
  return tag || TagFetchStatus.FETCHING_TAG;
}

/**
 * Synchronously get trending tag and kick off an async fetch if they
 * are not in our state.
 */
function getOrFetchTrendingTags(
  dispatchTagsAction: Exclude<TagsState["dispatchTagsAction"], undefined>,
  state: TagsState
) {
  const ret = getTrendingTags(state);
  if (ret === undefined) {
    // When we have more than LIST_PARAMS_TAKE_MAX causes we should
    // investigate whether to send them all using this endpoint or
    // whether it makes sense to implement backend cause searching.
    fetchTrendingTags(
      dispatchTagsAction,
      LIST_PARAMS_TAKE_MAX as TakeInt,
      0 as SkipInt,
      7 as TakeInt
    );
  }
  return ret || TrendingTagsFetchStatus.FETCHING_TRENDING_TAGS;
}

/**
 * Use this hook instead of getOrFetchTag to avoid warning
 * errors about cannot update a component while rendering
 * a different component.
 */
export function useTag(identifier?: TagIdentifier | string, onlyActive = true) {
  const tagsState = useContext(TagsContext);
  const [tag, setTag] = useState<TagOrFetchStatus>(
    identifier
      ? getOrFetchTag(
          tagsState.dispatchTagsAction,
          tagsState,
          identifier,
          onlyActive
        ) ?? TagFetchStatus.FETCHING_TAG
      : TagFetchStatus.TAG_NOT_FOUND
  );

  useEffect(() => {
    if (!identifier) {
      return;
    }
    setTag(
      getOrFetchTag(
        tagsState.dispatchTagsAction,
        tagsState,
        identifier,
        onlyActive
      )
    );
  }, [identifier, tagsState, onlyActive]);

  return tag;
}

/**
 * Use this hook to get trending tags.
 */
export function useTrendingTags() {
  const tagsState = useContext(TagsContext);
  const [trendingTagsObject, setTrendingTags] = useState<
    | { trendingTags: TagResponse[]; hasMore: boolean }
    | TrendingTagsFetchStatus.FETCHING_TRENDING_TAGS
    | TrendingTagsFetchStatus.TRENDING_TAGS_NOT_FOUND
    | undefined
  >(undefined);

  useEffect(() => {
    if (
      trendingTagsObject === TrendingTagsFetchStatus.FETCHING_TRENDING_TAGS ||
      trendingTagsObject === TrendingTagsFetchStatus.TRENDING_TAGS_NOT_FOUND ||
      trendingTagsObject === undefined
    ) {
      setTrendingTags(
        getOrFetchTrendingTags(tagsState.dispatchTagsAction, tagsState)
      );
    }
  }, [tagsState, trendingTagsObject]);

  return trendingTagsObject;
}
