import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import {
  TextInput,
  TextInputType,
  TextInputProps,
} from "@components/TextInput";
import { css } from "@emotion/react";
import React, { useState } from "react";

import { MAX_PASSWORD_LENGTH } from "@every.org/common/src/routes/me";

export const PasswordInput: React.FCC<TextInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const iconProps = {
    size: IconSize.X_SMALL,
    display: IconDisplay.SECONDARY,
  };

  return (
    <TextInput
      maxLength={MAX_PASSWORD_LENGTH}
      {...props}
      type={showPassword ? TextInputType.TEXT : TextInputType.PASSWORD}
      inputSuffix={
        <Button
          aria-label={showPassword ? "Hide password" : "Show password"}
          css={css`
            &:focus {
              outline: auto;
            }
          `}
          data-tname={`${props.name}--showPassword--${
            showPassword ? "on" : "off"
          }`}
          role={ButtonRole.UNSTYLED}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              setShowPassword(!showPassword);
            },
          }}
          icon={
            showPassword ? (
              <Icon
                iconImport={() => import("@components/Icon/icons/EyeIcon")}
                {...iconProps}
              />
            ) : (
              <Icon
                iconImport={() => import("@components/Icon/icons/EyeOffIcon")}
                {...iconProps}
              />
            )
          }
        />
      }
    />
  );
};
