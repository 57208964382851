import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { lightBgThemeCss } from "src/theme/color";
import { spacing } from "src/theme/spacing";

export interface CardProps {
  /**
   * If true, renders a container which emulates card padding and sizing, but
   * doesn't set a background, color palette or rounded borders
   *
   * Useful for making content align to cards even if the element itself doesnt
   * visually render itself as a card
   */
  hideBackground?: boolean;
  shadow?: boolean;
  shadowOnHover?: boolean;
}

const cardShadowCss = css`
  box-shadow: 0px 8px 32px -16px rgba(46, 52, 52, 0.2);
`;

/**
 * Encapsulates content in a card
 */
export const Card = styled.article<CardProps>`
  padding: ${spacing.l};
  max-width: 100%;
  ${({ hideBackground = false }) =>
    !hideBackground &&
    css(
      lightBgThemeCss,
      css`
        border-radius: 16px;
      `
    )};
  ${({ shadow = false }) => shadow && cardShadowCss}
  ${({ shadowOnHover = false }) =>
    shadowOnHover &&
    css`
      box-shadow: 0;
      transition: box-shadow 0.1s ease;
      &:hover {
        ${cardShadowCss}
      }
    `}
`;

export const HeaderCard = (
  props: Pick<CardProps, "hideBackground"> &
    React.PropsWithChildren<{ className?: string }>
) => <Card shadow {...props} />;
