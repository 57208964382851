import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { css } from "@emotion/react";

import { Link, LinkProps } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { horizontalStackCss } from "src/theme/spacing";
import { logger } from "src/utility/logger";

interface WebsiteLinkProps
  extends Omit<
      LinkProps,
      "to" | "forceExternal" | "data-tname" | "ref" | "children"
    >,
    Pick<Partial<LinkProps>, "data-tname"> {
  websiteUrl: string;
  hideIcon?: boolean;
  iconSize?: IconSize;
  iconDisplay?: IconDisplay;

  /**
   * If false or not set, we will only display the core domain/path and strip everything else (scheme, directory, params)
   */
  showFullUrl?: boolean;

  /**
   * Set true in situations where you want to show the entire URL path but hide the scheme `https://` and `www.`
   */
  hideScheme?: boolean;
}

export function WebsiteLink({
  websiteUrl,
  hideIcon = false,
  showFullUrl = false,
  hideScheme = false,
  iconSize = IconSize.X_SMALL,
  iconDisplay = IconDisplay.ACCENT,
  ...rest
}: WebsiteLinkProps) {
  const isMissingProtocol = !/^http[s]?:\/\//.test(websiteUrl);
  const getUrlForDisplay = () => {
    const url = websiteUrl.endsWith("/") ? websiteUrl.slice(0, -1) : websiteUrl;

    if (showFullUrl) {
      if (hideScheme) {
        return url.replace(/^(:?http[s]?:\/\/)?(:?www\.)?/, "");
      }
      return url;
    }

    try {
      const url = new URL(websiteUrl);
      return url.hostname.replace(/^(www\.)/, "");
    } catch (error) {
      logger.error({ message: "Invalid URL for WebsiteLink component", error });

      // Probably invalid URL just show the full thing
      return websiteUrl;
    }
  };

  return (
    <div
      css={[
        horizontalStackCss.xs,
        css`
          align-items: center;
        `,
      ]}
    >
      {!hideIcon && (
        <Icon
          iconImport={() => import("@components/Icon/icons/GlobeIcon")}
          display={iconDisplay}
          size={iconSize}
        />
      )}
      <span>
        <Link
          css={truncatedTextCss({ numLines: 1 })}
          data-tname="nonprofitWebsite"
          to={isMissingProtocol ? "http://" + websiteUrl : websiteUrl}
          appearance={LinkAppearance.HYPERLINK}
          target="_blank"
          forceExternal
          {...rest}
        >
          {getUrlForDisplay()}
        </Link>
      </span>
    </div>
  );
}
