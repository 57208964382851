/**
 * Methods for validating username criteria.
 */

import { string, brand, Branded, TypeOf } from "io-ts";
import { withMessage } from "io-ts-types/withMessage";

/**
 * Min length of a username or nonprofit slug
 */
export const USERNAME_MIN_LENGTH = 2;
/**
 * Max length of a username or nonprofit slug
 *
 * NOTE: legacy nonprofit slugs may defy this max
 */
export const USERNAME_MAX_LENGTH = 30;

/**
 * Regular expression for valid username or nonprofit slug, expressed as a
 * string (useful for passing into postgres)
 */
export const usernameRegexStr = `^[a-z0-9][a-z0-9._]{${
  USERNAME_MIN_LENGTH - 2
},${USERNAME_MAX_LENGTH - 2}}[a-z0-9]$`;

/**
 * Regular expression that checks if a username is valid
 */
export const usernameRegex = new RegExp(usernameRegexStr);
export interface UsernameIotsBrand {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly Username: unique symbol;
}

export enum UsernameError {
  MIN_LENGTH = "Min length is 2",
  MAX_LENGTH = "Max length is 30",
  START_SYMBOL = "Cannot start with a symbol",
  END_SYMBOL = "Cannot end with a symbol",
  INVALID_CHARS = "Only letters, periods and underscores allowed",
}

/**
 * io-ts codec that checks whether username is valid.
 */
export const usernameCodec = withMessage(
  brand(
    string,
    (s): s is Branded<string, UsernameIotsBrand> => !!s.match(usernameRegex),
    "Username"
  ),
  (input) => {
    if (typeof input !== "string") {
      return "Must be a string";
    }
    if (input.length < USERNAME_MIN_LENGTH) {
      return UsernameError.MIN_LENGTH;
    }
    if (input.length > USERNAME_MAX_LENGTH) {
      return UsernameError.MAX_LENGTH;
    }
    if (input.startsWith(".") || input.startsWith("_")) {
      return UsernameError.START_SYMBOL;
    }
    if (input.endsWith(".") || input.endsWith("_")) {
      return UsernameError.END_SYMBOL;
    }
    return UsernameError.INVALID_CHARS;
  }
);

/**
 * A string consisting of valid characters for a username
 */
export type Username = TypeOf<typeof usernameCodec>;

/**
 * Determines if the input is a valid username, and if so narrows the type of
 * input value. If you wish to know why it's invalid, use the `usernameCodec`
 * directly and inspect the error message.
 */
export function isValidUsername(
  maybeUsername: unknown
): maybeUsername is Username {
  return usernameCodec.decode(maybeUsername)._tag === "Right";
}
