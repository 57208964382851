import { Card } from "@components/Card";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { TextSize } from "@every.org/common/src/display/text";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const StyledCard = styled(Card)`
  padding: ${spacing.l} ${spacing.m} ${spacing.xs};

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding: ${spacing.xxl} ${spacing.l} ${spacing.xl};
    `,
  })}
`;

const StyledHeadline = styled.h2<{
  textColor?: string;
  accentColor?: string;
}>`
  ${textSizeCss[TextSize.m]}

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      ${textSizeCss[TextSize.xl]}
    `,
  })}
`;

export const NonprofitAnnouncement: React.FCC = ({ children }) => {
  return (
    <PageSection>
      <StyledCard hideBackground>
        <StyledHeadline>{children}</StyledHeadline>
      </StyledCard>
    </PageSection>
  );
};
