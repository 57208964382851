import { ImageLoader } from "next/image";

import { CLOUDINARY_PROD_BASE_URL } from "@every.org/common/src/helpers/cloudinary";

/**
 * Source: https://nextjs.org/docs/app/api-reference/next-config-js/images#cloudinary
 * Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg
 */
const cloudinaryLoader: ImageLoader = ({ src, width, quality }) => {
  const params = ["f_auto", "c_limit", `w_${width}`, `q_${quality || "auto"}`];
  return `${CLOUDINARY_PROD_BASE_URL}/${params.join(",")}${
    src.startsWith("/") ? src : `/${src}`
  }`;
};

export default cloudinaryLoader;
