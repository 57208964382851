const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

export function ordinalNumberString(number: number): string {
  try {
    const pr = new Intl.PluralRules("en-US", {
      type: "ordinal",
    });

    const rule = pr.select(number);
    const suffix = suffixes.get(rule);
    return `${number}${suffix}`;
  } catch (error) {
    // some devices don't have Intl.PluralRules
    return `${number}º`;
  }
}
