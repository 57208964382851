import * as t from "io-ts";

import { NonprofitEditStatus } from "@every.org/common/src/entity/types";
import { normalizeSlug } from "@every.org/common/src/helpers/slugs";
import {
  getNonprofitEditsRouteSpec,
  applyNonprofitEditRouteSpec,
} from "@every.org/common/src/routes/admin";
import {
  ListParams,
  SkipInt,
  TakeInt,
} from "@every.org/common/src/routes/index";
import {
  createNonprofitEditRouteSpec,
  getNonprofitSupportersRouteSpec,
  getNonprofitMembersRouteSpec,
  getNonprofitRouteSpec,
  getNonprofitNeighboursFeedRouteSpec,
  getNonprofitProjectsRouteSpec,
} from "@every.org/common/src/routes/nonprofit";

import { dispatchMyDonationsAction } from "src/context/MyDonationsContext";
import { MyDonationsActionType } from "src/context/MyDonationsContext/types";
import {
  addNonprofits,
  dispatchNonprofitsAction,
} from "src/context/NonprofitsContext/";
import {
  ContextNonprofit,
  NonprofitIdentifier,
  NonprofitActionType,
} from "src/context/NonprofitsContext/types";
import { addTags } from "src/context/TagContext/actions";
import { addUsers } from "src/context/UsersContext/actions";
import { is400LevelApiError } from "src/errors/ApiError";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

/**
 * Fetches a nonprofit identified by slug or id.
 */
export async function fetchNonprofit(identifier: NonprofitIdentifier) {
  dispatchNonprofitsAction({
    type: NonprofitActionType.FETCHING_NONPROFIT,
    data: identifier,
  });

  const identifierValue: string = identifier["id"] || identifier["slug"];
  try {
    const {
      nonprofit,
      supporterCount,
      endorsedNonprofits,
      createdFunds,
      endorserNonprofits,
      nonprofitTags,
      loggedInUserDonations,
      fundMetadata,
      giftCardCampaign,
      donatedCurrencies,
      eligibleDonationRecipientNonprofits,
      donationCount,
    } = await queryApi(getNonprofitRouteSpec, {
      routeTokens: { identifier: identifierValue },
      body: {},
      queryParams: {},
    });

    dispatchNonprofitsAction({
      type: NonprofitActionType.ADD_NONPROFITS,
      data: [
        {
          ...nonprofit,
          supporterCount,
          nonprofitTags,
          ...fundMetadata,
          giftCardCampaign,
          donatedCurrencies,
          donationCount,
        },
        ...(endorsedNonprofits || []),
        ...(createdFunds || []),
        ...(endorserNonprofits || []),
        ...(eligibleDonationRecipientNonprofits || []),
      ],
    });

    loggedInUserDonations &&
      dispatchMyDonationsAction({
        type: MyDonationsActionType.ADD_DONATIONS,
        data: loggedInUserDonations,
      });

    if (
      "slug" in identifier &&
      normalizeSlug({ slug: nonprofit.primarySlug }) !==
        normalizeSlug(identifier)
    ) {
      // this was a slug alias, tie the alias to the actual nonprofit object
      dispatchNonprofitsAction({
        type: NonprofitActionType.REGISTER_SLUG_ALIAS,
        data: {
          slug: identifier,
          nonprofitId: nonprofit.id,
        },
      });
    }
    return nonprofit;
  } catch (e) {
    // TODO: we should set the status to error instead of not found if we get
    // here.
    if (is400LevelApiError(e)) {
      logger.info({
        message: "A 400-level error occurred fetching a nonprofit",
        error: e,
        data: { httpStatus: e.httpStatus },
      });
    } else {
      logger.error({
        message: "An error occurred fetching a nonprofit.",
        error: e,
      });
    }
  }

  dispatchNonprofitsAction({
    type: NonprofitActionType.NONPROFIT_NOT_FOUND,
    data: identifier,
  });
  return undefined;
}

export async function fetchNonprofitSupporters(
  nonprofitId: ContextNonprofit["id"],
  take: TakeInt,
  skip: SkipInt
) {
  const result = await queryApi(getNonprofitSupportersRouteSpec, {
    routeTokens: { id: nonprofitId },
    body: {},
    queryParams: { take, skip },
  });
  addUsers(result.users);
  return result;
}

export async function fetchNonprofitNeighbours(
  nonprofitId: ContextNonprofit["id"],
  take: TakeInt,
  skip: SkipInt,
  kinds?: string
) {
  const queryParams = {
    take,
    skip,
    ...(kinds && kinds.length ? { kinds } : {}),
  };

  const responseData = await queryApi(getNonprofitNeighboursFeedRouteSpec, {
    routeTokens: { id: nonprofitId },
    body: {},
    queryParams,
  });
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  return responseData;
}

/**
 * Edit nonprofit actions
 */
type CreateNonprofitEditResult = t.TypeOf<
  typeof createNonprofitEditRouteSpec.responseBodyCodec
>;
type CreateNonprofitEditBody = t.TypeOf<
  typeof createNonprofitEditRouteSpec.bodyCodec
>;
export async function createNonprofitEdit(
  nonprofitId: ContextNonprofit["id"],
  body: CreateNonprofitEditBody
): Promise<CreateNonprofitEditResult> {
  const result = await queryApi(createNonprofitEditRouteSpec, {
    routeTokens: { id: nonprofitId },
    body,
    queryParams: {},
  });
  // In the case that the user is already an admin, the edit is immediately accepted.
  if (result.status === NonprofitEditStatus.ACCEPTED) {
    dispatchNonprofitsAction({
      type: NonprofitActionType.ADD_NONPROFIT,
      data: result.nonprofit,
    });
  }
  return result;
}

export type GetNonprofitEditsResult = t.TypeOf<
  typeof getNonprofitEditsRouteSpec.responseBodyCodec
>;
export async function fetchNonprofitEditsFeed({
  take,
  skip,
}: ListParams): Promise<GetNonprofitEditsResult> {
  const result = await queryApi(getNonprofitEditsRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: { take, skip },
  });

  dispatchNonprofitsAction({
    type: NonprofitActionType.ADD_NONPROFITS,
    data: result.nonprofits,
  });
  addUsers(result.users);

  return result;
}

type ApplyNonprofitEditResult = t.TypeOf<
  typeof applyNonprofitEditRouteSpec.responseBodyCodec
>;
type ApplyNonprofitEditBody = t.TypeOf<
  typeof applyNonprofitEditRouteSpec.bodyCodec
>;
export async function applyNonprofitEdit(
  body: ApplyNonprofitEditBody
): Promise<ApplyNonprofitEditResult> {
  const result = await queryApi(applyNonprofitEditRouteSpec, {
    routeTokens: {},
    body,
    queryParams: {},
  });
  if (result.status === NonprofitEditStatus.ACCEPTED) {
    dispatchNonprofitsAction({
      type: NonprofitActionType.ADD_NONPROFIT,
      data: result.nonprofit,
    });
  }
  return result;
}

export async function fetchNonprofitMembers(
  nonprofitId: ContextNonprofit["id"],
  take: TakeInt,
  skip: SkipInt
) {
  const result = await queryApi(getNonprofitMembersRouteSpec, {
    routeTokens: { id: nonprofitId },
    body: {},
    queryParams: { take, skip },
  });
  addUsers(result.users);
  return result;
}

export async function fetchNonprofitProjects(
  nonprofitId: ContextNonprofit["id"],
  queryParams: {
    includeArchived?: boolean;
    includeHideFromSearch?: boolean;
  } = {}
) {
  const result = await queryApi(getNonprofitProjectsRouteSpec, {
    routeTokens: { nonprofitId },
    body: {},
    queryParams,
  });
  addNonprofits(result.nonprofits);
  addTags(result.tags);
  return result;
}
