import { MIN_CARD_WIDTH_RAW } from "@components/DonationCard/types";
import { Feed, FeedProps, TransformMasonryItemsFn } from "@components/feed";
import { getFeedItemId } from "@components/feed/shared";
import {
  DefaultPageLayout,
  MetaTags,
} from "@components/layout/DefaultPageLayout";
import {
  MAX_LANDING_PAGE_SECTION_WIDTH,
  PageSection,
  SECTION_WIDTH_VAR,
} from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import {
  FeedItemResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { FeedPage } from "@every.org/common/src/entity/types";
import { TakeInt, SkipInt } from "@every.org/common/src/routes/index";

import {
  fetchLandingData,
  fetchGiftsFeed,
} from "src/context/DonationsContext/actions";
import { EdoTheme, useEdoTheme } from "src/context/ThemeContext";
import { useInfiniteScrollFeed } from "src/hooks/useInfiniteScrollFeed";
import { colorCssVars, darkBgThemeCss } from "src/theme/color";
import {
  MediaSize,
  cssForMediaSize,
  minWidthForMediaSize,
} from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { textSizeCss, TextSize } from "src/theme/text";

const CONTENT_MIN_WIDTH = 290;
const CONTENT_MAX_WIDTH = 540;

export const MAX_CARD_WIDTH_RAW = 392;

const Hero = styled(PageSection)`
  height: 100%;
  padding-top: ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding-top: 0;
    `,
  })}
`;

const heroContentCss = css`
  position: relative;
  height: 100%;

  ${cssForMediaSize({
    max: MediaSize.MEDIUM_SMALL,
    css: verticalStackCss.xl,
  })}

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      > article {
        min-width: ${CONTENT_MIN_WIDTH}px;
        max-width: ${CONTENT_MAX_WIDTH}px;
        margin-right: ${spacing.xxxxl};
        flex-shrink: 1;
      }
    `,
  })}

  > article {
    ${cssForMediaSize({
      min: MediaSize.MEDIUM,
      css: css`
        align-items: flex-start;
      `,
    })}

    h2 {
      ${cssForMediaSize({
        min: MediaSize.MEDIUM,
        css: css`
          ${textSizeCss[TextSize.l]};
        `,
      })}

      ${textSizeCss[TextSize.m]};
      color: var(${colorCssVars.emphasis});
    }
  }

  ${cssForMediaSize({
    css: css`
      padding-bottom: ${spacing.xxl};
    `,
    max: MediaSize.MEDIUM_SMALL,
  })};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      justify-content: space-between;
      > article {
        max-width: ${CONTENT_MAX_WIDTH}px;
        margin-right: ${spacing.xxxxl};
      }
    `,
  })};
`;

const pageLayoutCss = css`
  ${SECTION_WIDTH_VAR}: ${MAX_LANDING_PAGE_SECTION_WIDTH};
`;

/**
 * Wrapper around actual feed card to cause a "staggered" look for the masonry
 * layout on large screens
 */
const FeedSpacingWrapper = styled.div`
  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: {
      paddingTop: 150,
    },
  })}
`;

interface FeedContainerProps {
  isLight?: boolean;
  availableWidth?: string;
}

const FeedScrollContainer = styled.section<FeedContainerProps>`
  ${({ isLight }) =>
    cssForMediaSize({
      min: MediaSize.MEDIUM,
      css: css`
        position: absolute;
        top: 0;
        bottom: 0;
        /* creates fade out effect for feed content as it scrolls up */
        &::before {
          content: "";
          display: block;
          position: sticky;
          z-index: 5;
          top: 0;
          width: 100%;
          box-shadow: 0 5px 30px 30px
            var(
              ${isLight
                ? colorCssVars.background.faded
                : colorCssVars.background.normal}
            );
        }
        flex-shrink: 0;
        width: 100%;
        align-self: stretch;
        overflow-y: scroll;

        // mask-image: linear-gradient(to bottom, transparent 0, black ${spacing.xxl});

        /* Hide Scrollbar - OK accessibility-wise since scrolling non-essential on this page */
        scrollbar-width: none; /* firefox */
        &::-webkit-scrollbar {
          /* safari, chrome, opera */
          width: 0;
          height: 0;
        }
      `,
    })};
`;

const staggerItems: TransformMasonryItemsFn = ({ items }) => {
  if (items.length < 2) {
    return items;
  }
  const [first, { elem: secondElem, ...secondRest }, ...rest] = items;
  return [
    first,
    {
      ...secondRest,
      elem: <FeedSpacingWrapper>{secondElem}</FeedSpacingWrapper>,
    },
    ...rest,
  ];
};

const staggerMasonryItems = [staggerItems];

interface ScrollingFeedLandingPageProps {
  // Appears to the left of the feed on desktop, above the feed on mobile
  mainContent: React.ReactNode;
  // Hidden on desktop, below the feed on mobile
  mobileAfterFeedContent: React.ReactNode;
  pageTitle: string;
  // Meta tags
  metas?: Partial<MetaTags>;
  userId?: UserResponse["id"];
  canonical?: string;
  initialItems?: FeedItemResponse[];
  showGetStarted?: boolean;
}

/**
 * Let's keep a number in between for mobile and desktop
 */
const TAKE_FETCH = 8;

/**
 * Gets a feed of either global data, or if user id
 * is specified gets the user feed as well.
 * Ideally this should be done on backend with a single
 * call instead of combining two on front end.
 */
async function getFeed(userId?: UserResponse["id"]) {
  if (!userId) {
    return fetchLandingData();
  }
  const [{ items: userItems }, { items: globalItems }] = await Promise.all([
    fetchGiftsFeed({
      userId,
      take: TAKE_FETCH as TakeInt,
      skip: 0 as SkipInt,
    }),
    fetchLandingData(),
  ]);
  const unfilteredItems = [...userItems, ...globalItems];
  const seenItems = new Set();
  const items = unfilteredItems.filter((item) => {
    const id = getFeedItemId(item);
    if (seenItems.has(id)) {
      return false;
    }
    seenItems.add(id);
    return true;
  });
  return {
    items,
    hasMore: false,
  };
}

const ScrollingFeedLandingPageTheme = EdoTheme.DARK;

export const ScrollingFeedLandingPage: React.FCC<
  ScrollingFeedLandingPageProps
> = ({
  mainContent,
  mobileAfterFeedContent,
  pageTitle,
  userId,
  metas,
  canonical,
  initialItems,
  showGetStarted,
}) => {
  const { containerRef: feedContainerRef, contentRef: feedContentRef } =
    useInfiniteScrollFeed();

  const getLandingFeed = useMemo<FeedProps["loadMoreItems"]>(() => {
    return () => getFeed(userId);
  }, [userId]);

  const { isLight } = useEdoTheme(ScrollingFeedLandingPageTheme);

  return (
    <DefaultPageLayout
      css={[!isLight && darkBgThemeCss, pageLayoutCss]}
      themeOverride={ScrollingFeedLandingPageTheme}
      pageTitle={pageTitle}
      omitPageTitleSuffix
      metas={metas}
      disableFooterMargin
      canonical={canonical}
      hideSignup={showGetStarted}
      showGetStarted={showGetStarted}
    >
      <Hero contentCss={heroContentCss}>
        <article
          css={[
            cssForMediaSize({
              css: css`
                ${verticalStackCss.xxl};
                padding-bottom: ${spacing.xxl};
              `,
              min: MediaSize.MEDIUM,
            }),
            cssForMediaSize({
              max: MediaSize.LARGE,
              css: css`
                flex-basis: 50%;
              `,
            }),
            cssForMediaSize({
              css: css`
                ${verticalStackCss.l};
                flex-basis: auto;
              `,
              max: MediaSize.MEDIUM,
            }),
          ]}
        >
          {mainContent}
        </article>
        <div
          css={[
            {
              position: "relative",
              height: "100%",
            },
            cssForMediaSize({
              max: MediaSize.X_LARGE,
              css: css`
                max-width: ${MAX_CARD_WIDTH_RAW}px;
                width: 100%;
                margin: 0 auto;
              `,
            }),
            cssForMediaSize({
              min: MediaSize.XX_LARGE,
              css: css`
                flex-shrink: 0;
                /* two cards and gap between columns*/
                flex-basis: calc(${MIN_CARD_WIDTH_RAW}px * 2 + ${spacing.l});
              `,
            }),
          ]}
        >
          {/*
           * Use data-nosnippet because we had Google SEO snippet results for the main landing page showing nonprofit descriptions.
           * https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#data-nosnippet-attr
           */}
          <FeedScrollContainer
            data-nosnippet
            ref={feedContainerRef}
            isLight={isLight}
          >
            <Feed
              page={FeedPage.LANDING}
              take={undefined}
              data-tname="landingFeed"
              initialItems={initialItems}
              loadMoreItems={getLandingFeed}
              transformMasonryItems={staggerMasonryItems}
              showSkeleton={false}
              ref={feedContentRef}
              feedAfterItemsContent={
                <HideOnLarge>{mobileAfterFeedContent}</HideOnLarge>
              }
              numColumns={{
                default: 2,
                [minWidthForMediaSize[MediaSize.XX_LARGE]]: 1,
              }}
              isScrollFeed
            />
          </FeedScrollContainer>
        </div>
      </Hero>
    </DefaultPageLayout>
  );
};

const HideOnLarge = styled.div`
  margin-top: ${spacing.l};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: { display: "none" },
  })};
`;

export const LandingPageHeader = styled.h1`
  ${cssForMediaSize({
    css: css`
      letter-spacing: -1px;
      font-size: 3rem;
    `,
    min: MediaSize.LARGE,
  })}

  ${cssForMediaSize({
    css: css`
      letter-spacing: -1px;
      font-size: 2rem;
    `,
    max: MediaSize.MEDIUM,
  })}
`;

export const LandingPageSubheader = styled.h2``;

export const LandingPageParagraph = styled.p`
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: textSizeCss[TextSize.m],
  })}
`;
