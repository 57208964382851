import {
  strokeIconCss,
  colorForDisplay,
  heightForIconSize,
  BaseIconProps,
} from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { ReactComponent as Logomark } from "src/assets/logomark.svg";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";

const maskSvgString =
  "data:image/svg+xml,%3Csvg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='logoMask' stroke-dasharray='170px' d='M32.547 43C39.081 43 48 38.895 48 29.5C48 20.104 38.132 12.5 29.5 12.5C20.868 12.5 9 19.506 9 36C9 52.495 23.805 59.345 32.547 59.345C41.288 59.345 62.5 54.104 62.5 29.5' stroke='white' stroke-width='10'/%3E%3Canimate attributeType='XML' attributeName='stroke-dashoffset' from='170px' to='-170px' dur='1.25s' repeatCount='indefinite'/%3E%3C/svg%3E%0A";
interface IndicatorProps {
  color?: string;
  height?: string | number;
  iconProps?: BaseIconProps;
}
const indicatorProps: { [key in keyof IndicatorProps]: true } = {
  color: true,
  height: true,
  iconProps: true,
};

const Indicator = styled(Logomark, {
  shouldForwardProp: (prop) => !indicatorProps[prop],
})<IndicatorProps>`
  display: inline-block;
  vertical-align: middle;

  mask-image: url("${maskSvgString}");
  -webkit-mask-image: url("${maskSvgString}");
  -webkit-mask-size: cover;
  mask-size: cover;

  ${(props) =>
    props.iconProps
      ? css`
          ${strokeIconCss(props.iconProps)};
          /* TODO: Why are these not applied at the Icon level? */
          height: ${heightForIconSize[props.iconProps.size]}px;
          width: ${heightForIconSize[props.iconProps.size]}px;
        `
      : css`
          height: ${props.height ? props.height : "1rem"};
          width: auto;
        `}

  .logo {
    fill: ${({ color, iconProps }) =>
      iconProps
        ? colorForDisplay[iconProps.display]
        : color
        ? color
        : `var(${colorCssVars.text.secondary})`};
  }
`;

const LoadingText = styled.h4<IndicatorProps>`
  color: ${(props) =>
    props.color ? props.color : `var(${colorCssVars.text.secondary})`};
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > :not(:last-child) {
    margin-right: ${spacing.xs};
  }
`;

const PageLoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  text-align: center;
`;

/**
 * Standalone loading indicator.
 * TODO: This is the new version using IconProps. Remove other instances!
 */
export function LoadingIcon(props: BaseIconProps) {
  return <Indicator iconProps={props} className={props.className} />;
}

/**
 * Standalone loading indicator.
 */
export const LoadingIndicator: React.FCC<IndicatorProps> = (props) => (
  <Indicator {...props} />
);

/**
 * Centered large loading indicator.
 */
export const PageLoadingIndicator: React.FCC<IndicatorProps> = (props) => (
  <PageLoadingContainer>
    <Indicator {...props} height={"2rem"} />
  </PageLoadingContainer>
);

/**
 * Centered loading indicator with optional message.
 */
export const Loading: React.FCC<IndicatorProps & { text?: string }> = (
  props
) => {
  const { text, ...rest } = props;
  return (
    <LoadingContainer>
      <LoadingIndicator {...rest} />
      {text && <LoadingText>{text}</LoadingText>}
    </LoadingContainer>
  );
};
