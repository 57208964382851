import { Box, Arrow } from "@components/Popover/styles";
import { PopoverProps } from "@components/Popover/types";
import Tippy, { TippyProps } from "@tippyjs/react/headless";
import React, { forwardRef, useContext } from "react";

import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";

import { PageStackingContext } from "src/context/PageStackingContext";
import { rawSpacing, spacing } from "src/theme/spacing";

const PopoverChildrenWrapper = forwardRef<
  HTMLDivElement,
  { children: TippyProps["children"]; className?: string }
>(function PopoverChildren({ children, className }, ref) {
  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
});

function calculateOffsets({
  placement,
  reference,
}: {
  placement: string;
  reference?: { width?: number };
}) {
  const offset = 0 || (reference?.width || 0) / 2;

  switch (placement) {
    case "bottom-start":
    case "top-start":
      return [offset, rawSpacing.s];
    case "bottom-end":
    case "top-end":
      return [-offset, rawSpacing.s];
    default:
      return [0, rawSpacing.s];
  }
}

/**
 * Wrapper around tippy for consistent styles
 */
const Popover: React.FCC<PopoverProps> = React.memo(function PopoverImpl({
  content,
  children,
  contentCss,
  disableInteraction = false,
  zIndex: inputZIndex,
  className,
  popperOptions,
  ...rest
}) {
  const parentZIndex = useContext(PageStackingContext);
  const ownZIndex = (inputZIndex || parentZIndex || 0) + 2;
  return (
    <PageStackingContext.Provider value={ownZIndex}>
      <Tippy
        zIndex={ownZIndex}
        render={(attrs) => (
          <Box
            disableInteraction={disableInteraction}
            css={contentCss}
            {...attrs}
          >
            <div data-popper-content css={{ padding: spacing.m }}>
              {content}
            </div>
            <div data-popper-arrow>
              <Arrow width="16" height="7" viewBox="0 0 16 7" fill="none">
                {["top", "bottom"].includes(rest.placement || "") ? (
                  <path d="M0.901,6.901L6.901,1.289C7.453,0.772 8.349,0.772 8.901,1.289L14.901,6.901" />
                ) : (
                  <path d="M0 7L13.1056 0.447213C14.4354 -0.217688 16 0.749304 16 2.23607V7H0Z" />
                )}
              </Arrow>
            </div>
          </Box>
        )}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: calculateOffsets,
              },
            },
          ],
          ...(popperOptions || {}),
        }}
        {...removeUndefinedValues(rest)}
      >
        <PopoverChildrenWrapper className={className}>
          {children}
        </PopoverChildrenWrapper>
      </Tippy>
    </PageStackingContext.Provider>
  );
});

export default Popover;
