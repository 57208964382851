import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { FontWeight } from "src/theme/text";

export const MAX_INPUT_WIDTH = "700px";
const MIN_SIDE_DESCRIPTION_WIDTH = "220px";

export const InputWithSideDescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spacing.xl};
  grid-row-gap: ${spacing.m};

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      grid-template-columns: minmax(60%, ${MAX_INPUT_WIDTH}) minmax(
          ${MIN_SIDE_DESCRIPTION_WIDTH},
          1fr
        );
    `,
  })};
`;

export const Description = styled.p`
  color: var(${colorCssVars.text.secondary});

  strong {
    color: var(${colorCssVars.text.body});
    font-weight: ${FontWeight.MEDIUM};
  }
`;
