import { LoadingPage } from "@components/LoadingPage";
import { MobileActionButtonsContainer } from "@components/MobileActionButtonsContainer";
import { preformRaisedAmounts } from "@components/NewFundraiserCard/preformRaisedAmounts";
import { ObservableIntersection } from "@components/ObservableIntersection";
import { Redirect } from "@components/Redirect";
import { editorTextCss } from "@components/RichTextEditor/styles";
import { SupportedBy } from "@components/SupportedBy";
import { TextWithReadMore } from "@components/TextWithReadMore";
import { useJoinDonationParams } from "@components/donate/DonateRouteModal";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { Big } from "big.js";
import { NextPage } from "next";
import React, { useEffect, useState, useCallback } from "react";

import {
  CoverAsset,
  UserResponse,
  FundraiserRaisedResponse,
  NonEmptyMatchingCampaignResponse,
} from "@every.org/common/src/codecs/entities";
import { Slug } from "@every.org/common/src/codecs/nonprofit";
import { colorPalette } from "@every.org/common/src/display/palette";
import {
  Currency,
  DonationsTabQueryParam,
  MediaType,
  NonprofitAdminStatus,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { isOfficialFundraiser } from "@every.org/common/src/helpers/fundraisers";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  isUuid,
  searchParamToBool,
} from "@every.org/common/src/helpers/string";
import { getDonationMatchCampaignRouteSpec } from "@every.org/common/src/routes/campaign";
import {
  getFundraiserRouteSpec,
  FundraiserResponse,
  getFundraiserRaisedRouteSpec,
} from "@every.org/common/src/routes/fundraiser";

import RecentSupportersPlaceholder from "src/assets/illustrations/recent_supporters_placeholder.svg";
import { AvatarSize } from "src/components/Avatar";
import { NonprofitAvatar } from "src/components/Avatar/NonprofitAvatar";
import { UserAvatar } from "src/components/Avatar/UserAvatar";
import {
  ButtonSize,
  Button,
  ButtonRole,
  ButtonTargetKind,
  fullWidthButtonCss,
} from "src/components/Button";
import { HeaderCard, Card } from "src/components/Card";
import { CardCover } from "src/components/CardCover";
import { Icon, IconDisplay, IconSize } from "src/components/Icon";
import { Link, LinkAppearance } from "src/components/Link";
import { LoadingIcon } from "src/components/LoadingIndicator";
import { NonprofitLink } from "src/components/NonprofitLink";
import { RecentContributorsCard } from "src/components/RecentContributorsCard";
import { ResizingSpan } from "src/components/ResizingSpan";
import { LabeledThermometer } from "src/components/Thermometer/LabeledThermometer";
import { UserLink } from "src/components/UserLink";
import { WebsiteLink } from "src/components/WebsiteLink";
import { DefaultPageLayout } from "src/components/layout/DefaultPageLayout";
import { ToFundraiserPageDonateButton } from "src/components/layout/DonateButton";
import { Grid, Cell } from "src/components/layout/GridSystem";
import {
  PageSection,
  headerCardPageSectionCss,
  DefaultPageSection,
} from "src/components/layout/PageSection";
import {
  useIsSuperAdmin,
  useLoggedInUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { addNonprofits } from "src/context/NonprofitsContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { addUsers } from "src/context/UsersContext/actions";
import { is400LevelApiError } from "src/errors/ApiError";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useNonprofitAdminData } from "src/hooks/useNonprofitAdminData";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { useNonprofitMatchingCampaign } from "src/hooks/useNonprofitMatchingCampaign";
import { useOpenDonatePage } from "src/hooks/useOpenDonatePage";
import { ArchivedNonprofitPage } from "src/pages/ArchivedNonprofit";
import DonateV3Page from "src/pages/DonateV3";
import { DeleteConfirmationModal } from "src/pages/Fundraiser/EditForm/DeleteConfirmationModal";
import { HideConfirmationModal } from "src/pages/Fundraiser/EditForm/HideConfirmationModal";
import { ShareModal } from "src/pages/Fundraiser/EditForm/ShareCard";
import {
  hideFundraiser,
  unhideFundraiser,
} from "src/pages/Fundraiser/EditForm/actions";
import { canEditOrEndFundraiser } from "src/pages/Fundraiser/fundraiserPermissions";
import {
  DonationDisabledNotice,
  RegisteredNonprofit,
  RevenueIndicator,
} from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3AboutCard";
import { NotFoundPage } from "src/pages/NotFound";
import { AnnouncementHeader } from "src/pages/User/UserDonationPage";
import { BUTTON_CONTENT_COLOR_CSS_VAR } from "src/styles/button";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  verticalStackCss,
  spacing,
  horizontalStackCss,
} from "src/theme/spacing";
import { textSizeCss, TextSize, FontWeight } from "src/theme/text";
import { getFundraiserInitialProps } from "src/utilities/ssr/getFundraiserInitialProps";
import { useStatSigLayer } from "src/utility/abtesting";
import { queryApi } from "src/utility/apiClient";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { OPENGRAPH_DIMENSIONS } from "src/utility/opengraph";
import { getWindow } from "src/utility/window";

const contentButtonCss = css`
  ${horizontalStackCss.xs}
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  ${textSizeCss.s}
`;

const buttonCss = css`
  color: ${colorPalette.tealLogoDark};
  display: flex;
  flex-grow: 1;
  margin-bottom: ${spacing.xs};
`;

export function fundraiserCurrentAmount(raisedData: FundraiserRaisedResponse) {
  const { raised, raisedMatches, currency } = raisedData;
  return (
    raised
      ? minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: raised,
          currency,
        }).amount
      : new Big(0)
  ).add(
    // TODO #10670: remove this from here and add its own separate bar with a tooltip
    raisedMatches
      ? minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: raisedMatches,
          currency,
        }).amount
      : new Big(0)
  );
}

const FundraiserPage: NextPage<
  Awaited<ReturnType<typeof getFundraiserInitialProps>>
> = ({
  fundraiserData: fundraiserDataParam,
  raisedData: raisedDataParam,
  fundraiserFeed: fundraiserFeedParam,
  fundraiserFeedHasMore: fundraiserFeedHasMoreParam,
}) => {
  const { getPathnameWithParams, search, replace, pathname, push } =
    useEdoRouter();
  const { params } = getPathnameWithParams();

  const fundraiserIdentifierValue = params?.fundraiserSlug;
  const nonprofitIdentifierValue = params?.nonprofitSlug;
  const isPostEdit = params?.postEdit;
  const [fundraiserData, setFundraiserData] =
    useState<FundraiserResponse | null>(fundraiserDataParam ?? null);
  const [raisedData, setRaisedData] = useState<FundraiserRaisedResponse | null>(
    raisedDataParam ?? null
  );
  const [matchCampaign, setMatchCampaign] =
    useState<NonEmptyMatchingCampaignResponse | null>(null);
  const [showActionsBackground, setShowActionsBackground] = useState(false);
  const showMobileActionButtons = useStatSigLayer(
    "mobile_donate_button_layer"
  )?.get("showButtons", false);
  const [error, setError] = useState<string>();

  const loggedInUser = useLoggedInUserOrUndefined();
  const nonprofitAdmin = useNonprofitAdminData(
    fundraiserData?.fundraiser.nonprofitId
  );

  const isNonprofitAdmin =
    nonprofitAdmin?.status === NonprofitAdminStatus.CONFIRMED;

  const isSuperAdmin = useIsSuperAdmin();

  const { donationToJoinId, userToJoinId } = useJoinDonationParams();

  const fetchFundraiserData = useCallback(async () => {
    if (fundraiserIdentifierValue && nonprofitIdentifierValue) {
      try {
        const fundraiserIdentifier = isUuid(fundraiserIdentifierValue)
          ? fundraiserIdentifierValue
          : (fundraiserIdentifierValue as Slug);
        const nonprofitIdentifier = isUuid(nonprofitIdentifierValue)
          ? nonprofitIdentifierValue
          : (nonprofitIdentifierValue as Slug);
        const response = await queryApi(getFundraiserRouteSpec, {
          routeTokens: { fundraiserIdentifier, nonprofitIdentifier },
          body: {},
          queryParams:
            isPostEdit && isPostEdit === "true"
              ? { skipCache: !!isPostEdit }
              : {},
        });
        setFundraiserData(response);
        if (response?.users && response.users.length > 0) {
          addUsers(response.users);
        }
        if (response?.nonprofits && response.nonprofits.length > 0) {
          addNonprofits(response.nonprofits);
        }
      } catch (e) {
        if (is400LevelApiError(e)) {
          logger.info({
            message: "A 400-level error occurred fetching a fundraiser",
            error: e,
            data: { httpStatus: e.httpStatus },
          });
          setError("A 400-level error occurred fetching a fundraiser");
          return;
        }
        setError("Something went wrong, please try again.");
      }
    }
  }, [fundraiserIdentifierValue, nonprofitIdentifierValue, isPostEdit]);

  const fetchFundraiserRaisedData = useCallback(async () => {
    if (fundraiserIdentifierValue && nonprofitIdentifierValue) {
      try {
        const fundraiserIdentifier = isUuid(fundraiserIdentifierValue)
          ? fundraiserIdentifierValue
          : (fundraiserIdentifierValue as Slug);
        const nonprofitIdentifier = isUuid(nonprofitIdentifierValue)
          ? nonprofitIdentifierValue
          : (nonprofitIdentifierValue as Slug);
        const response = await queryApi(getFundraiserRaisedRouteSpec, {
          routeTokens: { fundraiserIdentifier, nonprofitIdentifier },
          body: {},
          queryParams:
            isPostEdit && isPostEdit === "true"
              ? { skipCache: !!isPostEdit }
              : {},
        });
        setRaisedData(response);
      } catch (e) {
        if (is400LevelApiError(e)) {
          logger.info({
            message: "A 400-level error occurred fetching a fundraiser",
            error: e,
            data: { httpStatus: e.httpStatus },
          });
          setError("A 400-level error occurred fetching a fundraiser");
          return;
        }
        setError("Something went wrong, please try again.");
      }
    }
  }, [fundraiserIdentifierValue, nonprofitIdentifierValue, isPostEdit]);

  const fetchFundraiserMatchCampaign = useCallback(async () => {
    if (
      fundraiserData?.matchCampaigns &&
      fundraiserData.matchCampaigns.length > 0
    ) {
      try {
        const response = await queryApi(getDonationMatchCampaignRouteSpec, {
          routeTokens: {
            identifier: fundraiserData.matchCampaigns[0],
          },
          body: {},
          queryParams: {},
        });
        if (Object.keys(response).length > 0 && "id" in response) {
          setMatchCampaign(response);
        }
      } catch (e) {
        if (is400LevelApiError(e)) {
          logger.info({
            message: "A 400-level error occurred fetching a fundraiser",
            error: e,
            data: { httpStatus: e.httpStatus },
          });
          setError("A 400-level error occurred fetching a fundraiser");
          return;
        }
        setError("Something went wrong, please try again.");
      }
    }
  }, [fundraiserData?.matchCampaigns]);

  // Null out the fundraiser data whenever slugs change to force refresh
  useCallback(() => {
    if (
      (fundraiserIdentifierValue &&
        fundraiserIdentifierValue !== fundraiserData?.fundraiser.slug) ||
      (nonprofitIdentifierValue &&
        fundraiserData?.nonprofits &&
        !fundraiserData?.nonprofits
          .map((n) => n.primarySlug)
          .includes(nonprofitIdentifierValue))
    ) {
      setFundraiserData(null);
      // setRaisedData(null);
    }
  }, [fundraiserIdentifierValue, nonprofitIdentifierValue, fundraiserData]);

  useEffect(() => {
    let unsubscribedFromQuery = false;
    if (!unsubscribedFromQuery) {
      fetchFundraiserData();
      fetchFundraiserRaisedData();
    }
    return () => {
      unsubscribedFromQuery = true;
    };
  }, [fetchFundraiserData, fetchFundraiserRaisedData]);

  useEffect(() => {
    let unsubscribedFromQuery = false;
    if (!unsubscribedFromQuery) {
      fetchFundraiserMatchCampaign();
    }
    return () => {
      unsubscribedFromQuery = true;
    };
  }, [fetchFundraiserMatchCampaign]);

  const [showShareModal, setShowShareModal] = useState(false);
  const [showShareModalFromUrl, setShowShareModalFromUrl] = useState(false);
  useEffect(() => {
    if (searchParamToBool(new URLSearchParams(search).get("share"))) {
      replace(pathname);
      setShowShareModalFromUrl(true);
    }
  }, [pathname, search, replace]);

  const nonprofitMatchingCampaign = useNonprofitMatchingCampaign({
    nonprofitId: fundraiserData?.fundraiser.nonprofitId,
    fundraiserId: fundraiserData?.fundraiser.id,
  });
  const nonprofitAnnouncement = useNonprofitAnnouncement(
    fundraiserData && raisedData ? fundraiserData.nonprofits[0] : undefined,
    nonprofitMatchingCampaign
  );

  const openDonatePage = useOpenDonatePage();

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  if (error) {
    return <NotFoundPage />;
  }

  if (!fundraiserData || !raisedData) {
    return <LoadingPage />;
  }

  const { nonprofits, fundraiser, users } = fundraiserData;

  if (nonprofits[0].archived) {
    return <ArchivedNonprofitPage nonprofit={nonprofits[0]} />;
  }

  const window = getWindow();
  if (
    window &&
    params?.nonprofitSlug !== fundraiserData.nonprofits[0].primarySlug
  ) {
    return (
      <Redirect
        to={window.location
          .toString()
          .replace(
            params?.nonprofitSlug,
            fundraiserData.nonprofits[0].primarySlug
          )}
      />
    );
  }

  const {
    raised,
    raisedMonthly,
    supporters,
    currency: raisedCurrency,
  } = raisedData;
  // Never show information about user who created fundraiser if it is an official nonprofit fundraiser
  const fromUser = !isOfficialFundraiser(fundraiser) ? users[0] : undefined;
  const {
    currentAmount,
    goalAmount,
    raisedMounthly,
    raisedOfflineAmount,
    totalAmount,
    raisedMatchesAmount,
  } = preformRaisedAmounts({ fundraiser, raisedData });

  const valueRaisedLoading = raised === undefined;

  const canEdit = canEditOrEndFundraiser(
    fundraiser,
    loggedInUser,
    nonprofitAdmin
  );

  const endFundraiser = (
    <div css={verticalStackCss.m}>
      <h4 css={{ textAlign: "center" }}>
        <span css={{ color: colorPalette.teal }}>
          {displayCurrencyValueInUserLocale({
            currencyValue: {
              currency: raisedCurrency,
              amount: new Big(totalAmount.toFixed(0)),
            },
          })}
        </span>{" "}
        raised
      </h4>
      <div css={{ textAlign: "center", color: colorPalette.teal }}>
        {supporters} supporter{supporters > 1 ? "s" : ""}
      </div>
      <Card css={{ background: "#EAEDED" }}>
        <p>
          This fundraiser has ended. You can still support this nonprofit by{" "}
          <Link
            data-tname={"donateStockLandingSignupLink"}
            to={getRoutePath({
              name: ClientRouteName.NONPROFIT_OR_CAUSE,
              tokens: { nonprofitSlug: nonprofits[0].primarySlug },
              format: URLFormat.RELATIVE,
            })}
          >
            visiting their profile
          </Link>{" "}
        </p>
      </Card>
    </div>
  );

  const goalThermometer =
    fundraiser?.goalAmount === 0 ? null : (
      <div css={{ position: "relative" }}>
        {valueRaisedLoading && (
          <LoadingIcon
            css={css`
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, 50%);
            `}
            size={IconSize.MEDIUM}
            display={IconDisplay.ACCENT}
          />
        )}
        <LabeledThermometer
          css={valueRaisedLoading && { visibility: "hidden" }}
          currency={fundraiser.goalCurrency ?? Currency.USD}
          goalLabel={fundraiser.goalAmount ? "Goal" : "Next milestone"}
          progressLabel="Raised"
          {...(fundraiser?.goalAmount || currentAmount || raisedMonthly
            ? {
                goalAmount,
                currentAmount,
                // monthly: new Big(raisedMonthly),
                monthly: raisedMounthly,
                // raisedOffline: raisedOffline && new Big(raisedOffline),
                raisedOffline: raisedOfflineAmount,
                // TODO: add the raisedMatched here as its own bar with a tooltip
                raisedMatches: raisedMatchesAmount,
              }
            : { dataMissing: true })}
        />
      </div>
    );

  const matchCompaignNotice = matchCampaign?.active ? (
    <div>
      <p
        css={css`
          font-weight: ${FontWeight.BOLD};
        `}
      >
        Double your impact! All donations will be matched.
      </p>
      {new Big(matchCampaign.availableForMatching).gt(0) && (
        <div
          css={css`
            color: var(${colorCssVars.text.secondary});
            ${textSizeCss[TextSize.xs]}
          `}
        >
          (
          {displayCurrencyValueInUserLocale({
            minDenomCurrencyValue: {
              currency: raisedCurrency,
              amountInMinDenom: matchCampaign.availableForMatching,
            },
          })}{" "}
          in matching funds available)
        </div>
      )}
    </div>
  ) : null;

  const coverImageCloudinaryId =
    fundraiser.coverImageCloudinaryId || nonprofits[0].coverImageCloudinaryId;
  const coverAssetOverride = fundraiser.metadata?.coverAssetOverride;
  const coverAsset =
    coverAssetOverride ||
    (coverImageCloudinaryId && {
      mediaType: MediaType.IMAGE,
      cloudinaryId: coverImageCloudinaryId,
    }) ||
    undefined;

  const headerCard = (
    <HeaderCard
      css={css`
        padding: 0;
        overflow: hidden;
      `}
    >
      <HeaderCardAsset
        coverAsset={coverAsset}
        coverImageAlt={fundraiser.metadata?.coverImageAltText}
        coverYoutubeVideoUrl={fundraiser.metadata?.coverYoutubeVideoUrl}
      />
      <div
        css={[
          verticalStackCss.m,
          css`
            padding: ${spacing.l};
            max-width: 100%;
          `,
        ]}
      >
        {fromUser ? (
          <UserHeading user={fromUser} />
        ) : (
          <NonprofitHeading nonprofit={nonprofits[0]} hideRegisteredNonprofit />
        )}
        <section css={verticalStackCss.l}>
          <h1 css={textSizeCss.l}>{fundraiser.title}</h1>
          {fundraiser.description && (
            <span css={editorTextCss}>
              <TextWithReadMore
                text={fundraiser.description}
                numLines={10}
                markdown
              />
            </span>
          )}
          <section
            css={[
              verticalStackCss.l,
              cssForMediaSize({
                min: MediaSize.LARGE,
                css: { display: "none" },
              }),
            ]}
          >
            {fundraiser.active ? goalThermometer : endFundraiser}
            {isNonprofitAdmin || isSuperAdmin ? (
              <AdminCta fundraiserData={fundraiserData} canEdit={canEdit} />
            ) : canEdit ? (
              <EditFundraiserButton
                fundraiserSlug={fundraiser.slug}
                nonprofitSlug={nonprofits[0].primarySlug}
              />
            ) : null}
            {fundraiser.active && matchCompaignNotice}
          </section>
        </section>
      </div>
    </HeaderCard>
  );

  const shareModalProps = {
    fundraiser,
    nonprofit: nonprofits[0],
    isMyFundraiser:
      !!loggedInUser?.id && fundraiser.creatorUserId === loggedInUser.id,
  };

  const metas = removeUndefinedOrNullValues({
    "og:description": fundraiser.description,
    "og:image":
      coverImageCloudinaryId &&
      constructCloudinaryUrl({
        cloudinaryId: coverImageCloudinaryId,
        dimensions: OPENGRAPH_DIMENSIONS,
      }),
  });

  const navigateToFundraiserPage = () => {
    push(
      getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.FUNDRAISER,
        tokens: {
          nonprofitSlug: nonprofits[0].primarySlug,
          fundraiserSlug: fundraiser.slug,
        },
      })
    );
  };

  if (openDonatePage && !isSSR) {
    return (
      <DonateV3Page
        donateAction={DonateModalAction.DONATE}
        nonprofit={nonprofits[0]}
        fromFundraiserId={fundraiser.id}
        isOpen
        onRequestClose={navigateToFundraiserPage}
        donationToJoinId={donationToJoinId || undefined}
        userToJoinId={userToJoinId || undefined}
      />
    );
  }

  return (
    <DefaultPageLayout
      pageTitle={fundraiser.title}
      metas={metas}
      headerContent={<AnnouncementHeader text={nonprofitAnnouncement} />}
    >
      <PageSection
        contentCss={[
          headerCardPageSectionCss,
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "none" },
          }),
        ]}
      >
        {headerCard}
      </PageSection>
      <DefaultPageSection>
        <Grid>
          <Cell numCols={6} numColsLarge={8} css={verticalStackCss.l}>
            <div
              css={css`
                ${verticalStackCss.m}
                display: none;
                ${cssForMediaSize({
                  min: MediaSize.LARGE,
                  css: { display: "block" },
                })}
              `}
            >
              {headerCard}
            </div>
            <Card
              css={[
                verticalStackCss.m,
                cssForMediaSize({
                  min: MediaSize.LARGE,
                  css: { display: "none" },
                }),
              ]}
            >
              <NonprofitHeading nonprofit={nonprofits[0]} />
              <NonprofitDetails nonprofit={nonprofits[0]} />
            </Card>
            <RecentContributorsCard
              nonprofitFeed={fundraiserFeedParam}
              nonprofit={nonprofits[0]}
              fundraiserId={fundraiser.id}
              hasMore={fundraiserFeedHasMoreParam}
              sortChronologically
            />
          </Cell>
          <Cell numCols={6} numColsLarge={4}>
            <section
              css={css`
                ${verticalStackCss.l}
                display: none;
                ${cssForMediaSize({
                  min: MediaSize.LARGE,
                  css: { display: "block" },
                })}
              `}
            >
              {isNonprofitAdmin || isSuperAdmin ? (
                <AdminCta fundraiserData={fundraiserData} canEdit={canEdit} />
              ) : canEdit ? (
                <EditFundraiserButton
                  fundraiserSlug={fundraiser.slug}
                  nonprofitSlug={nonprofits[0].primarySlug}
                />
              ) : null}
              <Card css={verticalStackCss.m}>
                {" "}
                <React.Fragment>
                  {fundraiser.active ? (
                    <div css={verticalStackCss.m}>
                      {goalThermometer}
                      <ActionButtons
                        fundraiserData={fundraiserData}
                        supporters={supporters}
                        onShare={() => setShowShareModal(true)}
                      />
                      {matchCompaignNotice}
                    </div>
                  ) : (
                    <div>{endFundraiser}</div>
                  )}
                </React.Fragment>
              </Card>
              <Card css={verticalStackCss.m}>
                <NonprofitHeading nonprofit={nonprofits[0]} />
                <NonprofitDetails nonprofit={nonprofits[0]} />
              </Card>
            </section>
          </Cell>
        </Grid>
        <ObservableIntersection onChange={setShowActionsBackground} />
        <ShareModal
          isOpen={showShareModal}
          headlineText={
            "Share this fundraiser to help it reach the next milestone"
          }
          onRequestClose={() => setShowShareModal(false)}
          {...shareModalProps}
        />
        <ShareModal
          isOpen={showShareModalFromUrl}
          headlineText={
            <React.Fragment>
              <span>Your fundraiser has launched!</span>
              <br />
              Now share it to recruit supporters.
            </React.Fragment>
          }
          onRequestClose={() => setShowShareModalFromUrl(false)}
          {...shareModalProps}
        />
      </DefaultPageSection>
      {fundraiser.active &&
        (showMobileActionButtons ? (
          <MobileActionButtonsContainer alwaysShow>
            <div css={horizontalStackCss.s}>
              <Button
                data-tname="fundraiserShareButton"
                role={ButtonRole.UNSTYLED}
                size={ButtonSize.MEDIUM}
                onClick={{
                  kind: ButtonTargetKind.FUNCTION,
                  action: () => setShowShareModal(true),
                }}
              >
                <Icon
                  iconImport={() =>
                    import("src/components/Icon/icons/ShareIcon")
                  }
                  size={IconSize.MEDIUM}
                  display={IconDisplay.ACCENT}
                />
              </Button>
              <ToFundraiserPageDonateButton
                data-tname="startDonateButton"
                css={[
                  fullWidthButtonCss,
                  css`
                    span {
                      height: 100%;
                    }
                  `,
                ]}
                size={ButtonSize.MEDIUM}
                nonprofitSlug={nonprofits[0].primarySlug}
                fundraiserSlug={fundraiser.slug}
                disabled={!nonprofits[0].isDisbursable}
              >
                <span
                  css={css`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  Donate
                </span>
              </ToFundraiserPageDonateButton>
            </div>
          </MobileActionButtonsContainer>
        ) : (
          <PageSection
            css={[
              css`
                position: sticky;
                bottom: 0;
                background: ${showActionsBackground
                  ? "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%)"
                  : "transparent"};
                padding: ${showActionsBackground ? spacing.xl : spacing.s} 0
                  ${spacing.s} 0;
                z-index: 2;
                ${cssForMediaSize({
                  min: MediaSize.LARGE,
                  css: { display: "none" },
                })},
              `,
            ]}
          >
            <ActionButtons
              fundraiserData={fundraiserData}
              fillShare={showActionsBackground}
              horizontal
              supporters={supporters}
              onShare={() => setShowShareModal(true)}
            />
          </PageSection>
        ))}
    </DefaultPageLayout>
  );
};

const EditFundraiserButton = ({
  nonprofitSlug,
  fundraiserSlug,
}: {
  nonprofitSlug: string;
  fundraiserSlug: string;
}) => {
  return (
    <Button
      role={ButtonRole.SECONDARY}
      size={ButtonSize.LARGE}
      css={css`
        color: ${colorPalette.tealLogoDark};
      `}
      onClick={{
        kind: ButtonTargetKind.LINK,
        to: getRoutePath({
          name: ClientRouteName.FUNDRAISER_EDIT,
          format: URLFormat.RELATIVE,
          tokens: { nonprofitSlug, fundraiserSlug },
        }),
      }}
      data-tname="EditFundraiser"
    >
      Edit my fundraiser
    </Button>
  );
};

const ActionButtons: React.FCC<{
  fundraiserData: FundraiserResponse;
  horizontal?: boolean;
  fillShare?: boolean;
  supporters: number;
  onShare: () => void;
}> = ({ fundraiserData, horizontal, fillShare, onShare, supporters }) => {
  const { fundraiser, nonprofits } = fundraiserData;

  return (
    <div css={verticalStackCss.s}>
      <div
        css={[
          horizontal ? horizontalStackCss.s : verticalStackCss.s,
          css`
            align-items: stretch;
            > * {
              display: flex;
              flex-basis: 100%;
            }
          `,
        ]}
      >
        <Button
          css={[
            fullWidthButtonCss,
            fillShare &&
              css`
                background-color: ${colorPalette.white};
              `,
          ]}
          data-tname="fundraiserShareButton"
          role={ButtonRole.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: onShare,
          }}
          icon={
            <Icon
              iconImport={() => import("src/components/Icon/icons/ShareIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          }
        >
          Share
        </Button>
        <ToFundraiserPageDonateButton
          data-tname="startDonateButton"
          css={[
            fullWidthButtonCss,
            css`
              span {
                height: 100%;
              }
            `,
          ]}
          size={ButtonSize.MEDIUM}
          nonprofitSlug={nonprofits[0].primarySlug}
          fundraiserSlug={fundraiser.slug}
          disabled={!nonprofits[0].isDisbursable}
        >
          <span
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            Donate
          </span>
        </ToFundraiserPageDonateButton>
      </div>
      {!!supporters && (
        <SupportedBy
          css={{
            paddingTop: spacing.xxs,
            fontWeight: FontWeight.MEDIUM,
            color: `var(${colorCssVars.accent.large})`,
          }}
          numSupporters={supporters}
        />
      )}
      {!nonprofits[0].isDisbursable && (
        <DonationDisabledNotice nonprofit={nonprofits[0]} />
      )}
    </div>
  );
};

const NonprofitDetails: React.FCC<{ nonprofit: ContextNonprofit }> = ({
  nonprofit,
}) => {
  return (
    <div css={verticalStackCss.m}>
      <p>{nonprofit.description}</p>
      {nonprofit.revenueAmt && (
        <RevenueIndicator revenueAmt={nonprofit.revenueAmt} />
      )}
      {nonprofit.websiteUrl && (
        <WebsiteLink
          data-tname="nonprofitWebsiteLink"
          websiteUrl={nonprofit.websiteUrl}
        />
      )}
    </div>
  );
};

const VideoCoverCard: React.FCC<{
  videoUrl: string;
}> = ({ videoUrl }) => {
  return (
    <Card
      css={css`
        position: relative;
        /* Defaulting to 16:9 ratio */
        padding: 0 0 56.25% 0;
        overflow: hidden;
        border-radius: 16px 16px 0 0;
      `}
    >
      <iframe
        title="embedded-video"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
        id="smh_player"
        width="100%"
        height="100%"
        src={videoUrl}
        allowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        frameBorder="0"
      />
    </Card>
  );
};

const HeaderCardAsset: React.FCC<{
  coverAsset?: CoverAsset;
  coverImageAlt?: string;
  coverYoutubeVideoUrl?: string;
}> = ({ coverAsset, coverImageAlt, coverYoutubeVideoUrl }) => {
  if (coverYoutubeVideoUrl) {
    return <VideoCoverCard videoUrl={coverYoutubeVideoUrl} />;
  }

  if (coverAsset) {
    switch (coverAsset.mediaType) {
      case MediaType.IMAGE: {
        const { cloudinaryId } = coverAsset;
        return (
          <CardCover
            coverImageCloudinaryId={cloudinaryId}
            alt={coverImageAlt}
            height={160}
            largeScreenHeight={320}
            editable={false}
            priority
            isLargeCard
          />
        );
      }
      case MediaType.VIDEO: {
        const { videoUrl } = coverAsset;
        return <VideoCoverCard videoUrl={videoUrl} />;
      }
    }
  }

  return (
    <div
      css={css`
        background-image: ${`url("${RecentSupportersPlaceholder}")`};
        background-position: 10% 0%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 0 0;
        height: "320px";
        ${cssForMediaSize({
          min: MediaSize.LARGE,
          css: { height: "160px" },
        })}
      `}
    />
  );
};

const UserHeading: React.FCC<{ user: UserResponse }> = ({ user }) => {
  return (
    <section css={horizontalStackCss.m}>
      <UserAvatar user={user} size={AvatarSize.MEDIUM} />
      <div
        css={
          (cssForMediaSize({
            min: MediaSize.LARGE,
            css: [
              horizontalStackCss.m,
              css`
                flex-grow: 1;
                align-items: center;
              `,
            ],
          }),
          cssForMediaSize({
            max: MediaSize.MEDIUM,
            css: [
              verticalStackCss.s,
              css`
                align-items: flex-start;
              `,
            ],
          }))
        }
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {user.username ? (
            <Link
              data-tname="user"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.USER,
                tokens: { username: user.username },
              })}
              appearance={LinkAppearance.UNSTYLED}
            >
              <b
                css={[
                  textSizeCss.xs,
                  css`
                    font-weight: ${FontWeight.MEDIUM};
                    color: var(${colorCssVars.accent.small});
                  `,
                ]}
              >
                @{user.username}
              </b>
            </Link>
          ) : (
            <div
              css={[
                textSizeCss.xs,
                css`
                  display: inline-flex;
                  align-items: center;
                `,
              ]}
            >
              &nbsp;
            </div>
          )}
          <UserLink
            user={user}
            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          />
        </div>
      </div>
    </section>
  );
};

const NonprofitHeading: React.FCC<{
  nonprofit: ContextNonprofit;
  hideRegisteredNonprofit?: boolean;
}> = ({ nonprofit, hideRegisteredNonprofit }) => {
  return (
    <section css={verticalStackCss.s}>
      <div
        css={cssForMediaSize({
          min: MediaSize.LARGE,
          css: css`
            ${horizontalStackCss.m};
            justify-content: space-between;
          `,
        })}
      >
        <div
          css={[
            horizontalStackCss.m,
            css`
              align-items: center;
              flex-grow: 1;
              flex-wrap: nowrap;
            `,
          ]}
        >
          <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.MEDIUM} />
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <h4 css={textSizeCss.s}>
              <NonprofitLink
                appearance={LinkAppearance.UNSTYLED}
                nonprofit={nonprofit}
                data-tname="nonprofitName"
              >
                <ResizingSpan
                  css={css`
                    ${truncatedTextCss({ numLines: 2 })};
                    width: 100%;
                  `}
                  maxTextSize={TextSize.m}
                  minTextSize={TextSize.s}
                  compressor={nonprofit.name.length > 24 ? 2 : 1}
                >
                  {nonprofit.name}
                </ResizingSpan>
              </NonprofitLink>
            </h4>
            {nonprofit.supporterCount && nonprofit.supporterCount > 1 ? (
              <span
                css={css`
                  font-weight: ${FontWeight.REGULAR};
                  color: var(${colorCssVars.text.secondary});
                `}
              >
                <b
                  css={css`
                    font-weight: ${FontWeight.MEDIUM};
                    color: var(${colorCssVars.text.body});
                  `}
                >
                  {nonprofit.supporterCount}
                </b>{" "}
                supporters
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {!hideRegisteredNonprofit && (
        <RegisteredNonprofit nonprofit={nonprofit} />
      )}
    </section>
  );
};

const AdminCta: React.FCC<{
  fundraiserData: FundraiserResponse;
  canEdit: boolean;
}> = ({ fundraiserData, canEdit }) => {
  const { push: routerPush } = useEdoRouter();
  const { fundraiser, nonprofits } = fundraiserData;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showHideModal, setShowHideModal] = useState(false);

  const hideFundraiserAction = async () => {
    try {
      if (fundraiser) {
        if (fundraiser.hidden) {
          const res = await unhideFundraiser(
            fundraiser.id,
            fundraiser.nonprofitId
          );
          fundraiser.hidden = res?.hidden;
        } else {
          const res = await hideFundraiser(
            fundraiser.id,
            fundraiser.nonprofitId
          );
          fundraiser.hidden = res?.hidden;
        }
      }
    } catch (error) {
      logger.error({
        error,
        message: "Failed to (un)hide Fundraiser",
        data: { fundraiserId: fundraiser.id, hidden: fundraiser.hidden },
      });
    }
    setShowHideModal(false);
  };

  return (
    <div>
      <div css={verticalStackCss.none}>
        <div
          css={css`
            ${horizontalStackCss.xxs}
            flex-wrap: wrap;
          `}
        >
          {canEdit && (
            <Button
              role={ButtonRole.SECONDARY}
              size={ButtonSize.MEDIUM}
              css={buttonCss}
              onClick={{
                kind: ButtonTargetKind.LINK,
                to: getRoutePath({
                  name: ClientRouteName.FUNDRAISER_EDIT,
                  format: URLFormat.RELATIVE,
                  tokens: {
                    nonprofitSlug: nonprofits[0].primarySlug,
                    fundraiserSlug: fundraiser.slug,
                  },
                }),
              }}
              data-tname="EditFundraiser"
              contentCss={contentButtonCss}
            >
              <Icon
                iconImport={() => import("src/components/Icon/icons/EditIcon")}
                size={IconSize.MEDIUM}
                display={IconDisplay.CURRENT_COLOR}
              />
              <span>Edit</span>
            </Button>
          )}
          <Button
            role={ButtonRole.SECONDARY}
            size={ButtonSize.MEDIUM}
            css={buttonCss}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => setShowHideModal(true),
            }}
            data-tname="HideFundraiser"
            contentCss={contentButtonCss}
          >
            <Icon
              iconImport={() =>
                fundraiser.hidden
                  ? import("@components/Icon/icons/EyeOffIcon")
                  : import("@components/Icon/icons/EyeIcon")
              }
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
            <span>{fundraiser.hidden ? "Unhide" : "Hide"}</span>
          </Button>
          <Button
            role={ButtonRole.SECONDARY}
            size={ButtonSize.MEDIUM}
            css={css`
              ${buttonCss}
              ${BUTTON_CONTENT_COLOR_CSS_VAR}: ${colorPalette.persimmonOrange};
              &:hover:not(:disabled) {
                ${BUTTON_CONTENT_COLOR_CSS_VAR}: #B24B2C;
              }
            `}
            contentCss={contentButtonCss}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => setShowDeleteModal(true),
            }}
            data-tname="DeleteFundraiser"
          >
            <Icon
              iconImport={() => import("@components/Icon/icons/XIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
            <span>Delete</span>
          </Button>
        </div>
        <Button
          role={ButtonRole.SECONDARY}
          size={ButtonSize.LARGE}
          css={css`
            color: ${colorPalette.tealLogoDark};
          `}
          contentCss={contentButtonCss}
          onClick={{
            kind: ButtonTargetKind.LINK,
            to: getRoutePath({
              name: ClientRouteName.NONPROFIT_ADMIN_DONATIONS,
              format: URLFormat.RELATIVE,
              tokens: {
                nonprofitSlug: nonprofits[0].primarySlug,
              },
              query: {
                [DonationsTabQueryParam.SEARCH_TERM]: fundraiser.slug,
              },
            }),
          }}
          data-tname="ViewFundraiserDonations"
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/DollarIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.CURRENT_COLOR}
          />
          View Donations
        </Button>
      </div>
      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onReject={() => setShowDeleteModal(false)}
        nonprofitName={nonprofits[0].name}
        fundraiserId={fundraiser.id}
        nonprofitId={fundraiser.nonprofitId}
        onConfirm={() =>
          routerPush(
            getRoutePath({
              name: ClientRouteName.NONPROFIT_OR_CAUSE,
              format: URLFormat.RELATIVE,
              tokens: {
                nonprofitSlug: nonprofits[0].primarySlug,
              },
            })
          )
        }
      />
      <HideConfirmationModal
        isOpen={showHideModal}
        onReject={() => setShowHideModal(false)}
        nonprofitName={nonprofits[0].name}
        fundraiserId={fundraiser.id}
        nonprofitId={fundraiser.nonprofitId}
        hidden={fundraiser.hidden}
        onConfirm={hideFundraiserAction}
      />
    </div>
  );
};

export default FundraiserPage;
