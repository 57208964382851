import {
  Button,
  ButtonTargetKind,
  ButtonSize,
  ButtonRole,
} from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { LoadingIndicator } from "@components/LoadingIndicator";
import { ErrorMessage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { useEffect, useState, useCallback, useRef } from "react";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import { safeIntCodec } from "@every.org/common/src/codecs/number";
import { Currency } from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import { MAX_CRYPTO_DECIMALS_FOR_DISPLAY } from "@every.org/common/src/helpers/cryptoCurrency";

import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import {
  FontWeight,
  remTextSizes,
  TextSize,
  textSizeCss,
} from "src/theme/text";
import { trackEvent } from "src/utility/analytics";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

export const StyledLabel = styled.label`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

export const CryptoAmountInput = ({
  minDonationValue,
  maxDonationValue,
  cryptoCurrency,
  cryptoAmountValue,
  setCryptoAmountValue,
  cryptoTokenRate,
}: {
  minDonationValue: CurrencyValue;
  maxDonationValue: CurrencyValue;
  cryptoCurrency: CryptoCurrency;
  cryptoAmountValue: string | undefined;
  setCryptoAmountValue: (value: string) => void;
  cryptoTokenRate?: number;
}) => {
  const [amountWarning, setAmountWarning] = useState<string | undefined>(
    undefined
  );
  const [isCryptoInput, setIsCryptoInput] = useState(true);

  const amountInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (cryptoCurrency && amountInputRef && amountInputRef.current) {
      amountInputRef.current.focus();
    }
  }, [cryptoCurrency]);

  const setDonationAmount = useCallback(
    (newAmount: string) => {
      const newAmountBig =
        newAmount && newAmount !== "" ? new Big(newAmount) : undefined;
      if (isCryptoInput) {
        setCryptoAmountValue(newAmount);
      } else {
        setCryptoAmountValue(
          newAmountBig && cryptoTokenRate
            ? newAmountBig
                .div(cryptoTokenRate)
                .round(MAX_CRYPTO_DECIMALS_FOR_DISPLAY)
                .toString()
            : "0"
        );
      }
    },
    [setCryptoAmountValue, isCryptoInput, cryptoTokenRate]
  );

  const amountValue =
    cryptoTokenRate &&
    cryptoAmountValue &&
    new Big(cryptoAmountValue).times(cryptoTokenRate).round(2).toString();

  useEffect(() => {
    if (!amountWarning) {
      return;
    }
    const timer = setTimeout(() => {
      setAmountWarning(undefined);
    }, 1000);
    return () => clearTimeout(timer);
  }, [amountWarning]);

  const onAmountChanged = useCallback(
    (strValue: string) => {
      setAmountWarning(undefined);
      if (strValue === "") {
        setDonationAmount("");
        return false;
      }
      if (strValue === ".") {
        setDonationAmount("0.");
        return true;
      }
      setAmountWarning(undefined);
      try {
        const bigValue = new Big(strValue);
        if (bigValue.lt(0)) {
          return false;
        }

        if (!safeIntCodec.is(Number.parseInt(strValue))) {
          setAmountWarning("Amount is too large");
          return false;
        }
      } catch (e) {
        return false;
      }
      setDonationAmount(strValue);
      trackEvent("Donation amount changed", {});
      return true;
    },
    [setDonationAmount]
  );

  const zeroRate = cryptoTokenRate === 0;

  return (
    <fieldset css={verticalStackCss.s}>
      <StyledLabel
        htmlFor={
          isCryptoInput ? "cryptoDonateAmountInput" : "donateAmountInput"
        }
      >
        Amount
      </StyledLabel>
      <div
        css={css`
          ${horizontalStackCss.xs};
          background: #f3f6f6;
          border-radius: 8px;
          padding: 16px;
          ${textSizeCss[TextSize.s]};
          align-items: center;
          justify-content: flex-end;
        `}
      >
        <label htmlFor="donateAmountInput">
          <div
            css={[
              horizontalStackCss.m,
              css`
                justify-content: flex-end;
                text-align: end;
              `,
            ]}
          >
            <div
              css={[
                verticalStackCss.xxs,
                css`
                  justify-content: flex-end;
                `,
              ]}
            >
              <input
                css={css`
                  ${textSizeCss[TextSize.l]};
                  line-height: var(${remTextSizes.l});
                  font-weight: ${FontWeight.BOLD};
                  text-align: right;
                  border: none;
                  outline: none;
                  background: none;
                  max-width: 220px;
                `}
                type="text"
                value={isCryptoInput ? cryptoAmountValue : amountValue}
                onChange={(e) => onAmountChanged(e.target.value)}
                name="donateAmountInput"
                id="donateAmountInput"
                ref={amountInputRef}
                inputMode="decimal"
              />
              {!zeroRate && (
                <div
                  css={css`
                    color: var(${colorCssVars.text.secondary});
                    max-width: 220px;
                    overflow: hidden;
                  `}
                >
                  {cryptoTokenRate === undefined ? (
                    <LoadingIndicator />
                  ) : isCryptoInput ? (
                    displayCurrencyValueInUserLocale({
                      currencyValue: {
                        amount: new Big(amountValue || 0),
                        currency: Currency.USD,
                      },
                      options: {
                        minimumSignificantDigits: amountValue ? 3 : undefined,
                      },
                    })
                  ) : (
                    cryptoAmountValue || 0
                  )}
                </div>
              )}
            </div>
            <div
              css={[
                verticalStackCss.xxs,
                css`
                  justify-content: ${zeroRate ? "center" : "flex-end"};
                  align-items: flex-start;
                `,
              ]}
            >
              <div>{isCryptoInput ? cryptoCurrency : "USD"}</div>
              {!zeroRate && (
                <div
                  css={css`
                    color: var(${colorCssVars.text.secondary});
                  `}
                >
                  {isCryptoInput ? "USD" : cryptoCurrency}*
                </div>
              )}
            </div>
          </div>
        </label>
        {!zeroRate && (
          <Button
            data-tname="rotateCurrency"
            role={ButtonRole.UNSTYLED}
            size={ButtonSize.MEDIUM}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                setIsCryptoInput(!isCryptoInput);
              },
            }}
            disabled={cryptoTokenRate === undefined}
          >
            <Icon
              iconImport={() => import("@components/Icon/icons/RepeatIcon")}
              display={IconDisplay.SECONDARY}
              size={IconSize.MEDIUM}
              css={css`
                transform: rotate(90deg);
              `}
            />
          </Button>
        )}
      </div>
      {amountWarning && (
        <div css={horizontalStackCss.xs}>
          <Icon
            iconImport={() => import("@components/Icon/icons/AlertIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ERROR}
          />
          <ErrorMessage>{amountWarning}</ErrorMessage>
        </div>
      )}
    </fieldset>
  );
};
