import styled from "@emotion/styled";
import React from "react";

import { abbreviateNumber } from "@every.org/common/src/helpers/number";

import { spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${spacing.xs};
  }
`;

/**
 * Component that shows how many supporters a nonprofit has.
 * Renders nothing if there are no supporters.
 *
 * @param numSupporters Total number of supporters of this nonprofit, including people you know.
 * @param small If true, makes the text shorter.
 *
 * Removed around 2020-09-2X:
 * @param knownSupporters Optional list of known supporters to show their avatars.
 * @param showKnownSupporterNames If true, shows names of the known supporters.
 *
 * Example text:
 *   - Supported by You, Rahul, Omar, and 37 others
 *   - Supported by Rahul, Omar, and 37 others
 *   - Supported by 40 people
 *   - (small) You & 37 others
 */
interface SupportedByLineArgs {
  loggedInUserSupported?: boolean;
  numSupporters: number;
  small?: boolean;
  noSupportersFallbackText?: string;
  className?: string;
}
export const SupportedBy: React.FCC<SupportedByLineArgs> = (props) => {
  const { numSupporters, noSupportersFallbackText, className } = props;
  if (numSupporters <= 0 && !noSupportersFallbackText) {
    return <React.Fragment />;
  }
  return (
    <Container className={className}>
      <span css={textSizeCss.xs}>
        {abbreviateNumber(numSupporters, 1)} supporter
        {numSupporters !== 1 && "s"}
      </span>
    </Container>
  );
};
