import { css } from "@emotion/react";

import { colorCssVars } from "src/theme/color";

export const loadingOverlayCss = css`
  #loading-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: var(${colorCssVars.background.faded});

    display: none;
    align-items: center;
    justify-content: center;
  }

  #loading-overlay.active {
    display: block;
  }
`;
