/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Types whose values, if run through through JSON.stringifiable are both a)
 * fast to compute and b) unambiguous; this excludes objects, symbols and arrays
 */
type CheaplyStringifiable = string | number | boolean | undefined | null;

/**
 * Saves the return value of a function for the given parameters so that the
 * next time it's invoked with the same parameters, it returns from the cache
 * instead of actually invoking the function
 *
 * Note: be careful to not introduce memory leaks with this, since there
 * currently is no cache eviction so memoized data can accumulate.
 *
 * @param fn function to memoize
 * @param inputToCacheKey function to transform the parameters of func into a
 * cache key. Must be present if a param is not a primitive (@see CheaplyStringifiable
 * ). Default is to a pipe-delimited string of JSON-stringified params, but if
 * that doesn't work for your use case please customize
 */
export function memoize<Fn extends (...params: any[]) => any>({
  fn: func,
  inputToCacheKey = (...params: Parameters<Fn>) =>
    params.map((p) => JSON.stringify(p)).join("|"),
}: { fn: Fn } & (Parameters<Fn> extends CheaplyStringifiable[] // inputToCacheKey is optional if the function parameters are primitives
  ? { inputToCacheKey?: (...params: Parameters<Fn>) => string }
  : { inputToCacheKey: (...params: Parameters<Fn>) => string })): (
  ...params: Parameters<Fn>
) => ReturnType<Fn> {
  const cache = {};
  return (...params) => {
    const cacheKey = inputToCacheKey(...params);
    if (cacheKey in cache) {
      return cache[cacheKey];
    }
    const result = func(...params);
    cache[cacheKey] = result;
    return result;
  };
}
