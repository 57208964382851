import { Logger } from "pino";

export interface LogParams<Data extends Record<string, unknown>> {
  /**
   * A JavaScript native error to log, to be parsed and displayed by our log parsers
   */
  error?: Error | unknown;
  /**
   * Message that explains the context of the log
   */
  message: string;
  /**
   * Related information to provide context to a log
   *
   * Since errors should be at the top level, disallows key-value pairs that
   * look like errors
   */
  data?: Data extends
    | { error: unknown }
    | { exception: unknown }
    | { err: unknown }
    | { e: unknown }
    ? never
    : Data;
}

/**
 * Log levels allowed in our app
 */
export enum LogLevel {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
  FATAL = "fatal",
}

/**
 * Converts a pino logger into our custom logger interface to keep ourselves
 * from misusing the library and shooting ourselves in the foot
 *
 * @param pinoLogger instance of Pino to make our own custom logger interface
 * with (may vary for different apps based on transports, serializers they need)
 */
export function createLogger({
  pinoLogger,
  transformLogs,
}: {
  pinoLogger: Omit<Logger, "addLevel">;
  transformLogs?: <Data extends Record<string, unknown>>(
    params: LogParams<Data>
  ) => Record<string, unknown>;
}): {
  [level in LogLevel]: <Data extends Record<string, unknown>>(
    params: LogParams<Data>
  ) => void;
} {
  return Object.fromEntries(
    (
      [
        [LogLevel.DEBUG, pinoLogger.debug],
        [LogLevel.INFO, pinoLogger.info],
        [LogLevel.WARN, pinoLogger.warn],
        [LogLevel.ERROR, pinoLogger.error],
        [LogLevel.FATAL, pinoLogger.fatal],
      ] as const
    ).map(([level, pinoLogFn]) => [
      level,
      (log) =>
        pinoLogFn.bind(pinoLogger)(transformLogs ? transformLogs(log) : log),
    ])
  ) as ReturnType<typeof createLogger>;
}
