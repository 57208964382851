import { CloudinaryImg } from "@components/CloudinaryImg";
import { css } from "@emotion/react";

import { AchievementResponse } from "@every.org/common/src/codecs/entities";
import { LIGHT_THEME } from "@every.org/common/src/display/palette";

const ACHIEVEMENT_BADGE_SIZE = 48;
const ACHIVEMENT_BADGE_NUMBER_SIZE = 20;

const AchievementBadge = ({
  achievement,
  badgeNumber = 1,
}: {
  achievement: AchievementResponse;
  badgeNumber?: number;
}) => {
  return (
    <div
      css={css`
        height: ${ACHIEVEMENT_BADGE_SIZE}px;
        width: ${ACHIEVEMENT_BADGE_SIZE}px;
      `}
    >
      {achievement.badgeCloudinaryId && (
        <div
          css={css`
            position: relative;
          `}
        >
          {badgeNumber > 1 && (
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                background-color: white;
                border-radius: 50%;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
                width: ${ACHIVEMENT_BADGE_NUMBER_SIZE}px;
                height: ${ACHIVEMENT_BADGE_NUMBER_SIZE}px;
                z-index: 1;
              `}
            >
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <span
                  css={css`
                    color: ${LIGHT_THEME.accent.large};
                    font-size: 12px;
                  `}
                >
                  x{badgeNumber}
                </span>
              </div>
            </div>
          )}
          <CloudinaryImg
            cloudinaryId={achievement.badgeCloudinaryId}
            dimensions={{
              width: ACHIEVEMENT_BADGE_SIZE,
              height: ACHIEVEMENT_BADGE_SIZE,
            }}
            layout="fixed"
            alt={`${achievement.title} badge`}
          />
        </div>
      )}
    </div>
  );
};

export default AchievementBadge;
