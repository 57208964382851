import { avatarSizeToPx } from "@components/Avatar";
import {
  CauseIllustrationProps,
  FIT_PARENT,
} from "@components/CauseIllustration/types";
import { css } from "@emotion/react";
import memoize from "lodash.memoize";
import dynamic from "next/dynamic";

import { CAUSE_ILLUSTRATION_BG_PALETTE } from "@every.org/common/src/display/palette";
import { CauseCategory } from "@every.org/common/src/entity/types";

const categorySwatchCss = memoize(
  (params: {
    cause: CauseCategory;
    size: CauseIllustrationProps["size"];
  }) => css`
    width: ${params.size === FIT_PARENT
      ? "100%"
      : `${avatarSizeToPx[params.size]}px`};
    height: ${params.size === FIT_PARENT
      ? "100%"
      : `${avatarSizeToPx[params.size]}px`};
    border-radius: 50%;
    background-color: ${CAUSE_ILLUSTRATION_BG_PALETTE[params.cause]};
  `
);

function CauseSwatch({
  ...rest
}: Pick<CauseIllustrationProps, "size" | "cause" | "className">) {
  const { size, cause } = rest;
  return (
    <div css={categorySwatchCss({ size, cause })} className={rest.className} />
  );
}

export const CauseIllustration = ({ ...rest }: CauseIllustrationProps) => {
  const IllustrationComponent = dynamic(() => import("./IllustrationByCause"), {
    loading: () => <CauseIllustrationFallback {...rest} />,
  });

  return <IllustrationComponent {...rest} />;
};

function CauseIllustrationFallback({
  cause,
  size,
}: Pick<CauseIllustrationProps, "cause" | "size">) {
  return <CauseSwatch cause={cause} size={size} />;
}
