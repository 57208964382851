import styled from "@emotion/styled";
import React from "react";

import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";

const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -${spacing.xs};

  > li {
    margin: ${spacing.xs} 0 0;
    display: flex;
  }

  > li:not(:last-child)::after {
    content: "";
    display: block;
    margin: 0 ${spacing.xs};
    align-self: center;
    width: 1px;
    height: 1px;
    background: var(${colorCssVars.text.secondary});
  }
`;

interface FooterNavListEntry {
  elem: React.ReactNode;
  key: string;
}

export interface FooterNavListProps {
  entries: [FooterNavListEntry, ...FooterNavListEntry[]];
}

export const FooterNavList: React.FCC<FooterNavListProps> = ({ entries }) => (
  <StyledNavList>
    {entries.map(({ elem, key }) => (
      <li key={key}>{elem}</li>
    ))}
  </StyledNavList>
);
