import { createContext, useContext } from "react";

import { useEdoRouter } from "src/hooks/useEdoRouter";

export enum EdoTheme {
  DARK = "DARK",
  LIGHT = "LIGHT",
}
const DEFAULT_THEME = EdoTheme.LIGHT;

export const EdoThemeContext = createContext(DEFAULT_THEME);
export const EdoThemeContextProvider: React.FCC<{ override?: EdoTheme }> = ({
  override,
  children,
}) => {
  const urlTheme = useThemeFromUrl();

  const theme = override !== undefined ? override : urlTheme ?? DEFAULT_THEME;

  return (
    <EdoThemeContext.Provider value={theme}>
      {children}
    </EdoThemeContext.Provider>
  );
};

export function useEdoTheme(themeOverride?: EdoTheme) {
  const theme = useContext(EdoThemeContext);

  return {
    value: themeOverride ?? theme,
    isLight: (themeOverride ?? theme) === EdoTheme.LIGHT,
    isDark: (themeOverride ?? theme) === EdoTheme.DARK,
  };
}

function useThemeFromUrl() {
  const router = useEdoRouter();
  const urlTheme = new URLSearchParams(router.search)
    .get("theme")
    ?.toUpperCase();

  switch (urlTheme) {
    case EdoTheme.DARK:
      return EdoTheme.DARK;
    case EdoTheme.LIGHT:
      return EdoTheme.LIGHT;
    default:
      return undefined;
  }
}
