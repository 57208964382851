import {
  AmountTextInput,
  truncateDecimalPoint,
} from "@components/TextInput/AmountTextInput";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import React, { useCallback, useEffect, useState } from "react";

import { TIP_SLIDER_DEFAULT_SETTINGS } from "@every.org/common/src/entity/constants";

export const DefaultTipCustom = ({
  value,
  onChange,
  form,
  formContext,
  amountBig,
}: AddTipProps) => {
  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const amountErrorMessage = form.formState.errors.amountToEveryOrg?.message;

  const defaultTip = amountBig
    .div(100)
    .mul(TIP_SLIDER_DEFAULT_SETTINGS.initial)
    .toString();

  useEffect(() => {
    setInputValue(defaultTip);
    onChange(defaultTip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAmountErrorMessage = (message?: string) =>
    form.setError("amountToEveryOrg", { message });

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        return;
      }
      onChange(truncateDecimalPoint(e.currentTarget.value));
    },
    [onChange]
  );

  return (
    <AmountTextInput
      data-tname="donateAmountToEveryOrgTextInputV3"
      id="donateAmountToEveryOrgInput"
      name="donateAmountToEveryOrgInput"
      shorten={formContext.shorten}
      setAmount={onChange}
      minValue={formContext.minValue}
      maxValue={formContext.maxValue}
      amount={inputValue}
      amountErrorMessage={amountErrorMessage}
      setAmountErrorMessage={setAmountErrorMessage}
      collapseDescriptionSpace
      allowZero
      allowDecimal
      onBlur={onBlur}
    />
  );
};
