import { Card } from "@components/Card";
import { maxWidthFeedCardCss } from "@components/feed/shared";
import { feedCardCss } from "@components/feed/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { CAUSE_PASTEL_BG_PALETTE } from "@every.org/common/src/display/palette";
import { CauseCategory } from "@every.org/common/src/entity/types";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { verticalStackCss, spacing } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export const ContainerCard = styled(Card)`
  ${feedCardCss};
  ${maxWidthFeedCardCss};
  padding: 0;
`;

export const InnerLinkContainer = styled.div`
  ${feedCardCss};
  position: relative;
  z-index: 3;
`;

export const Description = styled.span<{ lines: number }>`
  ${({ lines }) => truncatedTextCss({ numLines: lines })};
  ${textSizeCss.xs};
`;

export const coloredBackgroundCss = (causeCategory: CauseCategory) => css`
  background-color: ${CAUSE_PASTEL_BG_PALETTE[causeCategory].pastel40};
`;

export const InnerSection = styled.div<{
  rounded?: boolean;
  cause?: CauseCategory;
}>`
  ${verticalStackCss.m};
  padding: ${spacing.m};

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 0 0 16px 16px;
    `};
  ${({ cause }) => cause && coloredBackgroundCss(cause)};
`;
