import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { getWindow } from "src/utility/window";

export function getAPIOrigin() {
  const origin =
    ["staging.every.org", "www.every.org"].includes(
      getWindow()?.location?.host || ""
    ) ||
    getWindow() === undefined ||
    (getWindow()?.location?.host || "").includes("localhost")
      ? assertEnvPresent(
          process.env.NEXT_PUBLIC_API_ORIGIN,
          "NEXT_PUBLIC_API_ORIGIN"
        )
      : "";
  return origin;
}
