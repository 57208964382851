import { Uuid } from "@every.org/common/src/codecs/entities";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { Link, LinkProps } from "src/components/Link";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";

interface NonprofitIdLinkProps extends Omit<LinkProps, "to"> {
  nonprofitId: Uuid;
}

export function NonprofitIdLink({
  nonprofitId,
  ...rest
}: NonprofitIdLinkProps) {
  const nonprofit = nonprofitOrUndefined(useNonprofit({ id: nonprofitId }));
  if (!nonprofit) {
    return null;
  }

  return <NonprofitLink nonprofit={nonprofit} {...rest} />;
}

interface NonprofitLinkProps extends Omit<LinkProps, "to" | "ref"> {
  nonprofit: Pick<ContextNonprofit, "name" | "primarySlug">;
}

export const NonprofitLink: React.FCC<NonprofitLinkProps> = ({
  nonprofit,
  children,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      to={getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })}
    >
      {children || nonprofit.name}
    </Link>
  );
};
