import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";

export const PageContainer = styled.div`
  width: 100%;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      padding: ${spacing.xl};
    `,
  })}
  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      display: flex;
      align-items: stretch;
      border-radius: 16px;
      overflow: hidden;
      padding: 0;
    `,
  })}
`;

export const ThankYouPageContainer = styled.div`
  ${verticalStackCss.m};

  width: 100%;
  padding: 0;

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding: ${spacing.xl};
    `,
  })}
`;

export const ProcessContainer = styled.div`
  width: 100%;
  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      padding: ${spacing.xxl};
    `,
  })}
`;
