import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyValueCodec } from "../codecs/currency";
import {
  feedItemResponseCodec,
  membershipResponseCodec,
  nonprofitResponseCodec,
  userResponseCodec,
} from "../codecs/entities";
import { HttpMethod } from "../helpers/http";

import { listParamsCodec, listResponseCodec, makeRouteSpec } from ".";

const getCommunityMembersResponseCodec = t.type({
  members: t.array(
    t.type({
      userId: uuidCodec,
      supportedNonprofits: t.array(uuidCodec),
    })
  ),
  users: t.array(userResponseCodec),
  nonprofits: t.array(nonprofitResponseCodec),
  hasMore: t.boolean,
});
export type GetCommunityMembersResponse = t.TypeOf<
  typeof getCommunityMembersResponseCodec
>;
export const getCommunityMembersRouteSpec = makeRouteSpec({
  path: "/communities/:id/members",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: getCommunityMembersResponseCodec,
});

const getCommunityFeaturedNonprofitsResponseCodec = t.type({
  featured: t.array(
    t.partial({
      nonprofit: nonprofitResponseCodec,
      from: t.string,
      to: t.string,
      speaker: t.partial({
        name: t.string,
        title: t.string,
        imageUrl: t.string,
      }),
      session: t.partial({
        id: t.number,
        title: t.string,
        date: t.string,
        start: t.string,
        end: t.string,
      }),
    })
  ),
});
export type GetCommunityFeaturedNonprofitsResponse = t.TypeOf<
  typeof getCommunityFeaturedNonprofitsResponseCodec
>;
export const getCommunityFeaturedNonprofitsRouteSpec = makeRouteSpec({
  path: "/communities/:id/featured",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getCommunityFeaturedNonprofitsResponseCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getCommunityImpactResponseCodec = t.intersection([
  t.type({
    donationCount: t.number,
    memberCount: t.number,
    nonprofitsSupported: t.number,
    amountRaised: currencyValueCodec,
  }),
  t.partial({ goalAmount: currencyValueCodec }),
]);
export type GetCommunityImpactResponse = t.TypeOf<
  typeof getCommunityImpactResponseCodec
>;
export const getCommunityImpactRouteSpec = makeRouteSpec({
  path: "/communities/:id/impact",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getCommunityImpactResponseCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export const joinCommunityRouteSpec = makeRouteSpec({
  path: "/communities/:id/join",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.partial({ token: t.string }),
  responseBodyCodec: t.type({ membership: membershipResponseCodec }),
});

const getCommunityFeedParamsCodec = listParamsCodec;
const getCommunityFeedBodyCodec = t.type({});
const getCommunityFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
  }),
  listResponseCodec,
]);
export const getCommunityFeedRouteSpec = makeRouteSpec({
  path: "/communities/:id/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getCommunityFeedParamsCodec,
  bodyCodec: getCommunityFeedBodyCodec,
  responseBodyCodec: getCommunityFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});
