/**
 * Displays an error message with an X next to it.
 *
 * This component can be rendered with an empty text field in order to show
 * empty space.
 */
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";

import { horizontalStackCss } from "src/theme/spacing";

interface ErrorMessageProps {
  "data-tname"?: string;
  text?: string;
  className?: string;
}

const MessageContainer = styled.div`
  display: flex;
  color: ${SHARED_PALETTE.error};
  align-items: center;
  a {
    color: ${SHARED_PALETTE.error};
  }
`;

const Message = styled.span`
  color: ${SHARED_PALETTE.error};
`;

export const ErrorMessage: React.FCC<ErrorMessageProps> = ({
  text,
  children,
  ...rest
}) => {
  const message = text || children;
  return (
    <MessageContainer {...rest}>
      {message && (
        <div
          css={css`
            ${horizontalStackCss.xs};
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            iconImport={() => import("@components/Icon/icons/XIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.ERROR}
          />
          <Message>{message}</Message>
        </div>
      )}
    </MessageContainer>
  );
};
