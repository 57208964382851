import { CustomRange } from "@components/CustomRange";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import React, { useEffect, useMemo, useState } from "react";

import { colorPalette } from "@every.org/common/src/display/palette";
import { TIP_SLIDER_DEFAULT_SETTINGS } from "@every.org/common/src/entity/constants";

import { useStatSigLayer } from "src/utility/abtesting";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

export function getInitialSliderValues(percentage?: number) {
  const { min, max, step, initial } = TIP_SLIDER_DEFAULT_SETTINGS;

  const validPercentage =
    percentage !== undefined &&
    percentage >= min &&
    percentage <= max &&
    !(percentage % step)
      ? percentage
      : undefined;

  if (percentage === undefined) {
    return {
      initialSliderValue: initial,
      initialShowSlider: true,
    };
  }

  if (typeof percentage === "number" && typeof validPercentage === "number") {
    return {
      initialSliderValue: percentage,
      initialShowSlider: true,
    };
  }

  return {
    initialSliderValue: initial,
    initialShowSlider: false,
  };
}

export const DefaultTipSlider = (props: AddTipProps) => {
  const { formContext, form, onChange, isZeroTip, amountBig } = props;
  const currency = form.watch("currency");

  const sliderSettings = useMemo(() => {
    const { initialSliderValue } = getInitialSliderValues(
      formContext.donationTipPercent
    );
    return {
      ...TIP_SLIDER_DEFAULT_SETTINGS,
      initial: initialSliderValue,
    };
  }, [formContext.donationTipPercent]);

  // A/B test for tip percentage
  const skipTest = // skip test if amount is less than $1000 or if tip percent is already set
    formContext.donationTipPercent !== undefined || amountBig.lt(1000);

  const tipPercentage =
    useStatSigLayer("default_tipping_rate_layer", skipTest)?.get<number>(
      "percentage"
    ) || sliderSettings.initial;

  const [sliderValue, setSliderValue] = useState(tipPercentage);

  useEffect(() => {
    form.setValue("suggestedTipPercent", tipPercentage);
  }, [tipPercentage, form]);

  useEffect(() => {
    form.setValue("actualTipPercent", sliderValue);
  }, [sliderValue, form]);

  const computedValue = useMemo(
    () => amountBig.div(100).mul(sliderValue),
    [amountBig, sliderValue]
  );

  useEffect(() => {
    onChange(computedValue.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedValue]);

  return (
    <CustomRange
      themeColor={isZeroTip ? colorPalette.grayMedium : formContext.themeColor}
      {...sliderSettings}
      value={sliderValue}
      onChange={setSliderValue}
      popoverContent={displayCurrencyValueInUserLocale({
        currencyValue: {
          currency,
          amount: computedValue,
        },
      })}
    />
  );
};
