import { useEffect } from "react";

import { DONATE_HASH } from "@every.org/common/src/helpers/clientRoutes";

import { useEdoRouter } from "src/hooks/useEdoRouter";
import { getWindow } from "src/utility/window";

export function useOpenDonatePage() {
  const { hash, replace } = useEdoRouter();
  const window = getWindow();

  const openDonatePage =
    hash.includes(DONATE_HASH) ||
    // no slash case
    hash.includes(DONATE_HASH.substring(1));

  //forces the donation page to open if the user returns to it using the browser's back or forward button
  useEffect(() => {
    if (!window || openDonatePage) {
      return;
    }

    function handleOpenDonate() {
      if (!window) {
        return;
      }

      const hash = window.location.hash;
      const isDonateHash =
        hash.includes(DONATE_HASH) || hash.includes(DONATE_HASH.substring(1));
      isDonateHash && replace(hash);
    }

    window.addEventListener("popstate", handleOpenDonate);

    return () => {
      window.removeEventListener("popstate", handleOpenDonate);
    };
  }, [openDonatePage, window, replace]);

  return openDonatePage;
}
