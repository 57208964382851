import { Button, ButtonProps, ButtonTargetKind } from "@components/Button";
import styled from "@emotion/styled";
import React, { useState, useCallback, useEffect, useRef } from "react";

import { getWindow } from "src/utility/window";

const StyledHiddenInput = styled.input`
  position: absolute;
  left: -9999;
  pointer-events: none;
  opacity: 0;
`;

export const HiddenInput = React.forwardRef<
  HTMLInputElement,
  {
    textToCopy: string;
  }
>(function hiddenInputImpl({ textToCopy }, ref) {
  return (
    <StyledHiddenInput
      type="text"
      ref={ref}
      value={textToCopy}
      aria-hidden="true"
      readOnly
    />
  );
});

interface CopyToClipboardButtonProps extends Omit<ButtonProps, "onClick"> {
  textToCopy: string;
  onCopied?: () => Promise<void> | void;
  onTimerEnd?: () => void;
  beforeCopiedText?: string;
  afterCopiedText?: string;
}

export const CopyToClipboardButton: React.FCC<CopyToClipboardButtonProps> = ({
  textToCopy,
  onCopied,
  children,
  onTimerEnd,
  beforeCopiedText = "Copy link",
  afterCopiedText = "Copied!",
  ...rest
}) => {
  const window = getWindow();
  const hiddenLinkRef = useRef<HTMLInputElement | null>(null);
  const [copied, setCopied] = useState(false);

  const action = useCallback(async () => {
    hiddenLinkRef?.current?.select();
    window?.navigator.clipboard.writeText(textToCopy);

    setCopied(true);
    onCopied && (await onCopied());
  }, [onCopied, textToCopy, window]);

  useEffect(() => {
    if (!copied) {
      return;
    }
    const timer = setTimeout(() => {
      setCopied(false);
      onTimerEnd && onTimerEnd();
    }, 5000);
    return () => clearTimeout(timer);
  }, [copied, onTimerEnd]);

  return (
    <Button onClick={{ kind: ButtonTargetKind.FUNCTION, action }} {...rest}>
      {children ? children : copied ? afterCopiedText : beforeCopiedText}
    </Button>
  );
};
