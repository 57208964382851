import { ApiError } from "src/errors/ApiError";
import { INVALID_CSRF_MSG } from "src/utility/apiClient";

export function getMessageForError(
  error: Error,
  defaultErrorMessage = "An unknown error occurred. Please try again."
) {
  if (error instanceof ApiError) {
    return error.data.message;
  }
  if (error.message === INVALID_CSRF_MSG) {
    return "We had a problem authenticating your request. Please restart your browser or clean your cookies and try again.";
  }
  return defaultErrorMessage;
}
