/**
 * An enum of useful HttpStatuses.
 */
export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  /**
   * User is not logged in.
   *
   * Technically, the HTTP standard calls this UNAUTHORIZED, but this a more
   * accurate name, so we adopt it to avoid confusion.
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#Client_error_responses
   */
  UNAUTHENTICATED = 401,
  /**
   * User is logged in, but is not allowed to take an action.
   */
  FORBIDDEN = 403,
  /**
   * Page not found.
   */
  NOT_FOUND = 404,
  /**
   * Conflicting resource e.g. posting donation twice.
   */
  CONFLICT = 409,
  /**
   * Internal server error.
   */
  INTERNAL_SERVER_ERROR = 500,
}
