import { Modal } from "@components/Modal";
import DonateV3 from "@components/donate/DonateV3";
import {
  DonateV3Layout,
  DonateV3PageProps,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";

import { spacing } from "@every.org/common/src/display/spacing";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";

const modalBodyCss = [
  css`
    display: flex;
    flex-grow: 2;
    > div {
      padding: 0;
    }
  `,
  cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      background: var(${colorCssVars.background.faded});
      max-width: 1000px;
      width: calc(100vw - ${spacing.xl} * 2);
      > div {
        padding: 0 ${spacing.xl} ${spacing.xl};
      }
    `,
  }),
];

const DonateV3Modal = (props: DonateV3PageProps) => {
  return (
    <Modal
      isOpen={props.isOpen}
      modalBodyCss={modalBodyCss}
      // Styling is currently totally broken for this
      // noExit={props.noExit || noExit}
    >
      <DonateV3 {...props} layout={DonateV3Layout.MODAL} />
    </Modal>
  );
};

export default DonateV3Modal;
