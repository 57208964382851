/**
 * A module to encapsulate web app globals.
 *
 * DO NOT STORE SENSITIVE DATA HERE! If the data can be contained in a React
 * context instead, do so.
 */

import type { UserResponse } from "@every.org/common/src/codecs/entities";

interface GlobalsType {
  // Consumed by our logger when reporting errors/warnings
  everydotorgLoggedInUserId: UserResponse["id"] | undefined;
}

export const Globals: GlobalsType = {
  everydotorgLoggedInUserId: undefined,
};
