import { LoadingPage } from "@components/LoadingPage";
import { LogInOrSignUpRedirect } from "@components/Redirect";
import React, { useContext } from "react";

import {
  clientRouteMetas,
  RouteRestrictions,
} from "@every.org/common/src/helpers/clientRoutes";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";

// RouteRestrictions which don't require authentication
const OPEN_ROUTES = new Set([
  RouteRestrictions.NONE,
  RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH,
  undefined,
]);

const restrictedRoutes: Set<string> = new Set(
  Object.values(clientRouteMetas)
    .filter(({ routeRestrictions }) => !OPEN_ROUTES.has(routeRestrictions))
    .map(({ path }) => path)
);

type AuthGatewayProps = {
  children: React.ReactNode;
};
export const AuthGateway = ({ children }: AuthGatewayProps) => {
  const { getPathnameWithParams } = useEdoRouter();
  const { path } = getPathnameWithParams();
  const authenticationRequired = restrictedRoutes.has(path);

  const authState = useContext(AuthContext);

  if (authenticationRequired) {
    if (authState.status === AuthStatus.LOADING) {
      return <LoadingPage />;
    }

    if (authState.status === AuthStatus.LOGGED_OUT) {
      return <LogInOrSignUpRedirect />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};
