import { either } from "fp-ts/Either";
import * as t from "io-ts";

/**
 * A custom `io-ts` type for a Date. Decodes the date from an ISO format string.
 *
 * Copied from https://github.com/gcanti/io-ts#custom-types
 *
 * See isBig on currency.ts for a reference on (u instanceof Object && u.constructor?.name === "Date")
 */
export const dateFromStringCodec = new t.Type<Date, string, unknown>(
  "DateFromString",
  (u): u is Date =>
    u instanceof Date ||
    (u instanceof Object && u.constructor?.name === "Date"),
  (u, c) =>
    either.chain(t.string.validate(u, c), (s) => {
      const d = new Date(s);
      return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }),
  (a) => a.toISOString()
);
