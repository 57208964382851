import dynamic from "next/dynamic";
import React, { useEffect, useContext } from "react";

import {
  FeedItemResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { NonprofitDisplayType } from "@every.org/common/src/entity/types";

import { FundsPage } from "src/components/Funds";
import { LoadingPage } from "src/components/LoadingPage";
import { Redirect } from "src/components/Redirect";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import { fetchNonprofit } from "src/context/NonprofitsContext/actions";
import { getNonprofit } from "src/context/NonprofitsContext/selectors";
import {
  NonprofitFetchStatus,
  ContextNonprofit,
} from "src/context/NonprofitsContext/types";
import { ArchivedNonprofitPage } from "src/pages/ArchivedNonprofit";
import DirectoryPage from "src/pages/Directory";
import { NonprofitPageV3 } from "src/pages/Nonprofit/NonprofitPageV3";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const CommunityPage = dynamic(() => import("src/components/Community"));
const Community2023Page = dynamic(() => import("src/pages/Community2023"));

const TagPage = dynamic(() => import("src/pages/Tags/Tags"));

export function hasParentId(nonprofit: ContextNonprofit, parentId: string) {
  return nonprofit?.eligibleDonationRecipientNonprofitIds?.includes(
    parentId as any
  );
}

type NonprofitPageWrapperProps = {
  slug: string;
  contributorList?: FeedItemResponse[];
  contributorListHasMore?: boolean;
  initialFundraisers?: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
};

const NonprofitPageWrapper: React.FCC<NonprofitPageWrapperProps> = ({
  slug,
  contributorList = [],
  contributorListHasMore,
  initialFundraisers,
}) => {
  const nonprofitsState = useContext(NonprofitsContext);
  useEffect(() => {
    if (slug) {
      fetchNonprofit({ slug });
    }
  }, [slug]);

  const nonprofit = slug
    ? getNonprofit(nonprofitsState, { slug: slug })
    : undefined;

  if (!nonprofit || nonprofit === NonprofitFetchStatus.FETCHING_NONPROFIT) {
    return <LoadingPage />;
  }

  if (nonprofit === NonprofitFetchStatus.NONPROFIT_NOT_FOUND) {
    // Causes should be be rendered using this page, they should be rewritten to
    // /cause/<slug> by next.conf.js. Howerver, if they were created since the
    // last deployment they could end up here. So we render to the cause (tag)
    // page to handle that case.
    logger.warn({
      message: `Nonprofit not found. If this is a new cause then a redeploy will prevent it reaching the nonprofit page`,
      data: { slug },
    });

    return <TagPage tagName={slug} />;
  }

  const window = getWindow();
  if (window && slug !== nonprofit.primarySlug) {
    return (
      <Redirect
        to={window.location.toString().replace(slug, nonprofit.primarySlug)}
      />
    );
  }

  if (nonprofit.archived) {
    return <ArchivedNonprofitPage nonprofit={nonprofit} />;
  }

  if (nonprofit.displayType === NonprofitDisplayType.FUND) {
    return <FundsPage fund={nonprofit} />;
  }

  if (nonprofit.displayType === NonprofitDisplayType.COMMUNITY) {
    // only use new (2023) community pages for select communities (e.g. TED 2023) until we are sure we want to switch over
    if (nonprofit.metadata?.community?.use2023Style) {
      return <Community2023Page community={nonprofit} />;
    }
    return <CommunityPage community={nonprofit} />;
  }

  if (nonprofit.displayType === NonprofitDisplayType.DIRECTORY) {
    return <DirectoryPage nonprofit={nonprofit} />;
  }

  return (
    <NonprofitPageV3
      nonprofit={nonprofit}
      contributorList={contributorList}
      contributorListHasMore={contributorListHasMore}
      initialFundraisers={initialFundraisers}
    />
  );
};

export default NonprofitPageWrapper;
