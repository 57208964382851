import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";

export async function installRequiredPolyfills() {
  await (async function installIntlPolyfills() {
    // order matters for these polyfills, dont parallelize them
    if (shouldPolyfillGetCanonicalLocales()) {
      await import("@formatjs/intl-getcanonicallocales/polyfill");
    }
    if (shouldPolyfillLocale()) {
      await import("@formatjs/intl-locale/polyfill");
    }
    if (shouldPolyfillPluralRules()) {
      await import("@formatjs/intl-pluralrules/polyfill");
      await import("@formatjs/intl-pluralrules/locale-data/en");
    }
  })();
}
/**
 * polyfills we don't need to block on - no awaiting
 */
export function installOptionalPolyfills() {
  (async function smoothscrollPolyfill() {
    // polyfills are intended to only be loaded on browsers, so using document
    // directly is safe
    // eslint-disable-next-line no-restricted-globals
    if (getComputedStyle(document.body).scrollBehavior !== "smooth") {
      const smoothscroll = await import("smoothscroll-polyfill");
      smoothscroll.polyfill();
    }
  })();
}
