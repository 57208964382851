import { Card } from "@components/Card";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { TextCssVar, textSizeCss } from "src/theme/text";

export const Chip = styled.div`
  ${horizontalStackCss.s};

  padding: ${spacing.l};
  align-items: center;
`;

export const ExternalCard = styled(Card)`
  ${verticalStackCss.s};
  h5 {
    ${textSizeCss.s};
    ${TextCssVar.LINE_HEIGHT}: 20px;
  }

  p {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss.xs};
  }
`;

export const HeaderChip = styled.div`
  display: block;
  ${horizontalStackCss.s};
  padding: ${spacing.l};
  align-items: center;
  h3 {
    ${truncatedTextCss({ numLines: 1 })}
  }
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_SMALL,
    css: { display: "none" },
  })}
`;
