import { AvatarSize } from "@components/Avatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import {
  HeaderClickablePopover,
  secondaryTextCss,
} from "@components/DonationCardHeader/styles";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { css } from "@emotion/react";
import { UUID } from "io-ts-types/UUID";

import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { useUser } from "src/context/UsersContext/hooks";
import { FETCHING_USER, USER_NOT_FOUND } from "src/context/UsersContext/types";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

interface InviterPopoverProps {
  inviter: UUID;
  name: string;
}

export const InviterPopover: React.FCC<InviterPopoverProps> = ({
  inviter,
  name,
}) => {
  const inviterUser = useUser({ id: inviter });

  if (inviterUser === FETCHING_USER || inviterUser === USER_NOT_FOUND) {
    return null;
  }

  return (
    <HeaderClickablePopover
      placement={"bottom"}
      css={[secondaryTextCss, { alignSelf: "flex-start" }]}
      contentCss={css`
        max-width: 300px;
      `}
      content={
        <div css={horizontalStackCss.s}>
          <UserAvatar size={AvatarSize.MEDIUM} user={inviterUser} />
          <p
            css={css`
              font-weight: ${FontWeight.BOLD};
              & > span {
                font-weight: ${FontWeight.REGULAR};
              }
            `}
          >
            {name} <span>joined Every.org from</span>{" "}
            {getUserFullNameOrPlaceholder(inviterUser)}
            <span>{"'s"} invite</span>
          </p>
        </div>
      }
    >
      <Icon
        iconImport={() => import("@components/Icon/icons/InfoIcon")}
        display={IconDisplay.CURRENT_COLOR}
        size={IconSize.X_SMALL}
      />
    </HeaderClickablePopover>
  );
};
