import { css } from "@emotion/react";

export const BORDER_RADIUS = "8px";
export const INPUT_BORDER_RADIUS = "8px";
export const MODAL_BORDER_RADIUS = "24px";
// export const HEADER_ICON_HEIGHT = "24px";
const ROUGH_HEADER_HEIGHT_NUM_PX = 106;
export const ROUGH_HEADER_HEIGHT = `${ROUGH_HEADER_HEIGHT_NUM_PX}px`;

export const roundedBorder = css`
  border-radius: ${BORDER_RADIUS};
`;

export const roundedInputBorder = css`
  border-radius: ${INPUT_BORDER_RADIUS};
`;
