import { isLeft } from "fp-ts/Either";
import { useMemo } from "react";

import {
  NonprofitRevenueSize,
  nonprofitRevenueSizeCodec,
  SearchQueryParam,
} from "@every.org/common/src/entity/types";
import { reporter } from "@every.org/common/src/errors/IotsCodecError";
import { decodeCausesUrlQuery } from "@every.org/common/src/helpers/causes";
import {
  ClientRouteName,
  clientRouteMetas,
} from "@every.org/common/src/helpers/clientRoutes";

import {
  ContextSearchParams,
  SearchRouteNameType,
} from "src/context/SearchContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { logger } from "src/utility/logger";

const DEFAULT_SEARCH_DISTANCE = 100;
export const FILTER_MODAL_URL_PARAM = "filter";

export function compareSearchParams(
  obj1: ContextSearchParams | null,
  obj2: ContextSearchParams | null
) {
  if (!obj1 || !obj2) {
    return false;
  }
  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (let i = 0; i < obj1Props.length; i++) {
    const propName = obj1Props[i];
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }
  return true;
}

export type UrlSearchOptions = {
  query: string;
  causes?: string;
  lat?: number;
  lng?: number;
  distance?: number;
  address?: string;
  size?: NonprofitRevenueSize;
};
export const getSearchOptions = (search: string): UrlSearchOptions => {
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get(SearchQueryParam.SEARCH_TERM) || "",
    causes = decodeCausesUrlQuery(searchParams.get(SearchQueryParam.CAUSES)),
    lat = searchParams.get(SearchQueryParam.LAT),
    lng = searchParams.get(SearchQueryParam.LNG),
    address = searchParams.get(SearchQueryParam.ADDRESS) || undefined,
    sizeRaw = searchParams.get(SearchQueryParam.ORGANIZATION_SIZE) || undefined;

  const decodedSize = sizeRaw
    ? nonprofitRevenueSizeCodec.decode(sizeRaw)
    : undefined;
  if (decodedSize && isLeft(decodedSize)) {
    logger.warn({
      message: "Invalid organization size provided to search",
      data: { size: sizeRaw, errors: reporter(decodedSize) },
    });
  }
  const size =
    decodedSize && (isLeft(decodedSize) ? undefined : decodedSize.right);

  const parsedLat = (lat && parseFloat(lat)) || undefined,
    parsedLng = (lng && parseFloat(lng)) || undefined;

  return {
    query,
    causes,
    lat: parsedLat && isNaN(parsedLat) ? undefined : parsedLat,
    lng: parsedLng && isNaN(parsedLng) ? undefined : parsedLng,
    distance: DEFAULT_SEARCH_DISTANCE,
    address,
    size,
  };
};

export function useSearchOptionsFromUrl(): UrlSearchOptions {
  const router = useEdoRouter();

  const { query, causes, lat, lng, distance, address, size } = getSearchOptions(
    router.search
  );

  return useMemo(() => {
    return {
      query,
      causes,
      lat,
      lng,
      distance,
      address,
      size,
    };
  }, [address, lat, lng, query, size, causes, distance]);
}
export function isEmptySearch(input: ContextSearchParams | null) {
  if (!input) {
    return true;
  }
  if (
    !input.query &&
    !input.address &&
    !input.lat &&
    !input.lng &&
    !input.causes &&
    !input.size
  ) {
    // If all these filters are blank, it's still considered an empty search
    return true;
  }

  return false;
}

export function useSearchRoute(): {
  name: SearchRouteNameType;
  isCurrent: boolean;
} {
  const { getPathnameWithParams, search } = useEdoRouter();
  const { path } = getPathnameWithParams();
  const searchEmptyOrFilterOnly =
    search === `?${FILTER_MODAL_URL_PARAM}=true` || !search;

  switch (path) {
    case clientRouteMetas[ClientRouteName.CAUSES].path:
      return searchEmptyOrFilterOnly
        ? { name: ClientRouteName.CAUSES, isCurrent: true }
        : { name: ClientRouteName.SEARCH_RESULTS, isCurrent: false };
    case clientRouteMetas[ClientRouteName.NONPROFIT_OR_CAUSE].path:
      return { name: ClientRouteName.NONPROFIT_OR_CAUSE, isCurrent: true };
    case clientRouteMetas[ClientRouteName.NONPROFIT_ADMIN_DONATIONS].path:
      return {
        name: ClientRouteName.NONPROFIT_ADMIN_DONATIONS,
        isCurrent: true,
      };
    case clientRouteMetas[ClientRouteName.SEARCH_RESULTS].path:
      return { name: ClientRouteName.SEARCH_RESULTS, isCurrent: true };
    default:
      return { name: ClientRouteName.SEARCH_RESULTS, isCurrent: false };
  }
}
