import {
  StatsigProvider as LibStatsigProvider,
  useClientBootstrapInit,
} from "@statsig/react-bindings";
import { useContext } from "react";

import { ABTestingId } from "@every.org/common/src/codecs/entities";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { AuthContext } from "src/context/AuthContext";
import { getTestingId } from "src/utility/abtesting";
import { STATSIG_PROXY_URL } from "src/utility/statsig";

type StatsigProviderProps = {
  children: React.ReactNode;
  statsigInitProps?: Record<string, unknown>;
  abTestingId?: string;
};
export const StatsigProvider = ({
  children,
  statsigInitProps = {},
  abTestingId = undefined,
}: StatsigProviderProps) => {
  const authState = useContext(AuthContext);
  const testingId = getTestingId({
    authState,
    ssrTestingId: abTestingId,
  }) as ABTestingId;

  // Note, in dev we see a warning about Statsig being initialized twice This
  // shouldn't happen in prod, as React renders everything twice in dev due to
  // Strict mode
  // https://legacy.reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
  const bootstrappedClient = useClientBootstrapInit(
    assertEnvPresent(
      process.env.NEXT_PUBLIC_STATSIG_PUBLIC_API_KEY,
      "STATSIG_API_KEY"
    ),
    { userID: testingId, customIDs: { stableID: testingId } },
    JSON.stringify(statsigInitProps),
    {
      environment: {
        tier: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
      },
      networkConfig: {
        // Allows us to proxy statsig requests through our own domain
        api: STATSIG_PROXY_URL,
      },
    }
  );

  return (
    <LibStatsigProvider client={bootstrappedClient}>
      {children}
    </LibStatsigProvider>
  );
};
