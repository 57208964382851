import { Card } from "@components/Card";
import { css } from "@emotion/react";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";

export const PromoBanner = ({ nonprofit }: { nonprofit: ContextNonprofit }) => {
  const announcement = useNonprofitAnnouncement(nonprofit);
  return announcement ? (
    <Card
      css={css`
        ${cssForMediaSize({
          max: MediaSize.MEDIUM,
          css: css`
            border-radius: 0;
          `,
        })}
      `}
    >
      <h4>{announcement}</h4>
    </Card>
  ) : null;
};
