import React, { createContext, useReducer } from "react";

import { PersonalDonationChargeResponse } from "@every.org/common/src/codecs/entities";

import {
  PersonalDonationsAction,
  DonationActionType,
} from "src/context/DonationsContext/types";

/**
 * This state contains donations that the logged in user has made during this
 * current session so that we can append them to feeds right after the user has
 * made the donation.
 *
 * In the future, when the feeds are placed in specific pages, we could scope
 * it down to one state per page with a give button.
 */
type MyDonationsState = PersonalDonationChargeResponse[];

const INITIAL_DONATIONS_STATE: MyDonationsState = [];

function donationsReducer(
  state: MyDonationsState,
  action: PersonalDonationsAction
): MyDonationsState {
  switch (action.type) {
    case DonationActionType.ADD_NEW_DONATION:
      return state.concat(action.data);
    case DonationActionType.UPDATE_DONATION: {
      // TODO not so efficient, consider using Map
      const idx = state.findIndex((d) => d.id === action.data.id);
      if (idx === -1) {
        return state.concat(action.data);
      }
      return [...state.slice(0, idx), action.data, ...state.slice(idx + 1)];
    }
  }
}

export const MyDonationsContext = createContext<MyDonationsState>(
  INITIAL_DONATIONS_STATE
);
export let dispatchPersonalDonationsAction: React.Dispatch<PersonalDonationsAction>;

export const DonationsProvider: React.FCC = ({ children }) => {
  const [donationsState, donationsDispatcher] = useReducer(
    donationsReducer,
    INITIAL_DONATIONS_STATE
  );

  dispatchPersonalDonationsAction = donationsDispatcher;

  return (
    <MyDonationsContext.Provider value={donationsState}>
      {children}
    </MyDonationsContext.Provider>
  );
};
