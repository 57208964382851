import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { Link } from "@components/Link";
import { css } from "@emotion/react";

import { spacing } from "@every.org/common/src/display/spacing";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { DonateV3GridCard } from "src/pages/DonateV3/styles";
import { horizontalStackCss } from "src/theme/spacing";

export const GiftCardLink = () => {
  return (
    <DonateV3GridCard
      css={[
        horizontalStackCss.xs,
        { padding: spacing.m, alignItems: "center" },
      ]}
    >
      <Icon
        css={css`
          flex-shrink: 0;
          path {
            stroke-width: 3px;
          }
        `}
        iconImport={() => import("@components/Icon/icons/GiftIcon")}
        size={IconSize.X_LARGE}
        display={IconDisplay.ACCENT}
      />
      <p>
        Give the gift of giving with an Every.org{" "}
        <Link
          data-tname="donateV3GiftCardLink"
          to={getRoutePath({
            name: ClientRouteName.GIFT_CARD_PURCHASE,
            format: URLFormat.RELATIVE,
          })}
        >
          charity gift card
        </Link>
      </p>
    </DonateV3GridCard>
  );
};
