import {
  PersonalUserResponse,
  EntityName,
  GuestUserResponse,
} from "@every.org/common/src/codecs/entities";

import { AuthState, AuthStatus } from "src/context/AuthContext/types";

/**
 * Returns true if the user has been authenticated with Auth0.
 * @param authState
 */
export function isLoggedIn(authState: AuthState) {
  return authState.status === AuthStatus.LOGGED_IN;
}

export function isLoggedInUser(
  user?: PersonalUserResponse | GuestUserResponse
): user is PersonalUserResponse {
  return !!user && user.entityName === EntityName.USER_PERSONAL;
}
