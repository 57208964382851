/**
 * Card that displays a nonprofit recomendation.
 */
import { SuggestedNonprofitCard } from "@components/feed/SuggestedNonprofitCard";
import { UUID } from "io-ts-types/UUID";
import React, { useContext } from "react";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { logger } from "src/utility/logger";

interface FeedNonprofitCardProps {
  nonprofitId: string;
  feedId?: UUID;
  headerText: string;
  hideCauseText?: boolean;
}

/**
 * "Suggested nonprofit" card in a feed.
 */
export const FeedNonprofitCard: React.FCC<FeedNonprofitCardProps> = React.memo(
  function FeedNonprofitCardImpl({
    nonprofitId,
    feedId,
    headerText,
    hideCauseText,
  }) {
    const nonprofitsState = useContext(NonprofitsContext);

    const nonprofit = nonprofitOrUndefined(
      getNonprofit(nonprofitsState, {
        id: nonprofitId,
      })
    );
    if (!nonprofit) {
      logger.error({
        message: "Nonprofit context missing nonprofit in FeedNonprofitCard",
        data: { nonprofitId },
      });
      return <React.Fragment />;
    }

    return (
      <SuggestedNonprofitCard
        feedId={feedId}
        hideCauseText={hideCauseText}
        headerText={headerText}
        nonprofit={nonprofit}
      />
    );
  }
);
