import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import {
  userFollowResponseCodec,
  userResponseCodec,
  followInfoResponseCodec,
} from "../codecs/entities";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec, listParamsCodec, listResponseCodec } from ".";

const followParamsCodec = t.type({});
const followBodyCodec = t.type({ toId: uuidCodec });
const followResponseBodyCodec = t.type({
  follow: userFollowResponseCodec,
  user: userResponseCodec,
});
export const followRouteSpec = makeRouteSpec({
  path: "/follow",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: followParamsCodec,
  bodyCodec: followBodyCodec,
  responseBodyCodec: followResponseBodyCodec,
});

export const existingUnfollowResponseBodyCodec = t.type({
  follow: userFollowResponseCodec,
  user: userResponseCodec,
});
const unfollowParamsCodec = t.type({});
const unfollowBodyCodec = t.union([
  t.type({ toId: uuidCodec }),
  t.type({ fromId: uuidCodec }),
]);
const unfollowResponseBodyCodec = t.union([
  t.type({}),
  existingUnfollowResponseBodyCodec,
]);
export const unfollowRouteSpec = makeRouteSpec({
  path: "/follow",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: unfollowParamsCodec,
  bodyCodec: unfollowBodyCodec,
  responseBodyCodec: unfollowResponseBodyCodec,
});

const approveFollowParamsCodec = t.type({});
const approveFollowBodyCodec = t.type({
  fromId: uuidCodec,
  approve: t.boolean,
});
const approveFollowResponseBodyCodec = t.type({
  fromUser: userResponseCodec,
});
export const approveFollowRouteSpec = makeRouteSpec({
  path: "/follow",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: approveFollowParamsCodec,
  bodyCodec: approveFollowBodyCodec,
  responseBodyCodec: approveFollowResponseBodyCodec,
});

const getFollowRecommendationsParamsCodec = listParamsCodec;
const getFollowRecommendationsBodyCodec = t.type({});
const getFollowRecommendationsResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(followInfoResponseCodec),
    users: t.array(userResponseCodec),
  }),
  listResponseCodec,
]);
export const getFollowRecommendationsRouteSpec = makeRouteSpec({
  path: "/recommendations/follows",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getFollowRecommendationsParamsCodec,
  bodyCodec: getFollowRecommendationsBodyCodec,
  responseBodyCodec: getFollowRecommendationsResponseBodyCodec,
});
