import { NextParsedUrlQuery } from "next/dist/server/request-meta";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

// TODO: In most cases this should be able to be replaced with Next Router

export interface EdoRouter {
  /**
   * An initial / followed by the remainder of the URL up to the ?
   * For NextJS, doesn't contain the `basePath` or `locale`
   */
  pathname: string;

  /**
   * A function that retrieves the path and the params of the current url
   *
   * path: An initial / followed by the remainder of the URL up to the ?
   * It retrieves the path with the params of the url (e.g. '/:nonprofitSlug/donate')
   *
   * params: Object containing the params as key value pairs
   */
  getPathnameWithParams: () => {
    path: string;
    params: Record<string, string>;
  };

  /**
   * Contains an initial ?  followed by the key=value pairs in the query string.
   * If there are no parameters, this value may be the empty string (i.e. '').
   */
  search: string;
  /**
   * Contains an initial # followed by fragment identifier of the URL. If there
   * is no fragment identifier, this value may be the empty string (i.e. '').
   */
  hash: string;
  push: (path: string) => void;
  replace: (path: string) => void;
  back: () => void;
}

const getRouteParam = (nonprofitSlugOrUsername: string) => {
  if (isUsernameRoute(nonprofitSlugOrUsername)) {
    return { username: nonprofitSlugOrUsername.replace("@", "") };
  }

  return { nonprofitSlug: nonprofitSlugOrUsername };
};

const isUsernameRoute = (param = "") => param.startsWith("@");

export const parseNextRoute = (pathname: string, params: NextParsedUrlQuery) =>
  pathname
    .replace(
      "[nonprofitSlugOrUsername]",
      isUsernameRoute(params?.nonprofitSlugOrUsername as string)
        ? "@:username"
        : ":nonprofitSlug"
    )
    .replace(new RegExp(/\[/g), ":") // need to use regex to match all occurrences
    .replace(new RegExp(/\]/g), "");

export const useEdoRouter = (): EdoRouter => {
  const {
    asPath,
    push,
    replace,
    query: params,
    pathname,
    back,
    isReady,
  } = useRouter();
  const [pathAndSearch, hash = ""] = asPath.split("#");
  const [pathnameParsed, search = ""] = pathAndSearch.split("?");
  const pathnameWithParams = parseNextRoute(pathname, params);

  const pathnameWithParamsParsed = pathnameWithParams.startsWith("/n")
    ? pathnameWithParams.slice(2)
    : pathnameWithParams;

  const getPathnameWithParams = useCallback(
    () => ({
      path: pathnameWithParamsParsed,
      params: (params?.nonprofitSlugOrUsername
        ? {
            ...params,
            ...getRouteParam(params.nonprofitSlugOrUsername as string),
          }
        : params) as Record<string, string>,
    }),
    [params, pathnameWithParamsParsed]
  );

  const router = useMemo(
    () => ({
      pathname: pathnameParsed,
      getPathnameWithParams,
      search: search && `?${search}`,
      hash,
      push,
      replace,
      back,
      isReady,
    }),
    [
      hash,
      push,
      replace,
      search,
      pathnameParsed,
      back,
      getPathnameWithParams,
      isReady,
    ]
  );
  return router;
};
