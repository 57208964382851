import {
  Button,
  ButtonProps,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Loading } from "@components/LoadingIndicator";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  validateAmountAndFrequency,
  validateCommentText,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  CreateOrUpdateDonationResult,
  DonateModalAction,
  DonateFormType,
  DONATE_FORM_ERROR,
} from "@components/donate/DonateV3/types";
import { redirectToLogin } from "@components/donate/helpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useStripe } from "@stripe/react-stripe-js";
import type { PaymentRequest } from "@stripe/stripe-js";
import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { currencyValueCodec } from "@every.org/common/src/codecs/currency";
import { decodeOrThrow } from "@every.org/common/src/codecs/index";
import { spacing } from "@every.org/common/src/display/spacing";
import { TextSize } from "@every.org/common/src/display/text";
import {
  Currency,
  DonationFrequencyToUserFacingTextMap,
  PaymentRequestType,
} from "@every.org/common/src/entity/types";
import { currencyValueToMinimumDenominationAmount } from "@every.org/common/src/helpers/currency";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { PaymentRequestReadyStatus } from "src/context/PaymentRequestContext";
import { getMessageForError } from "src/errors";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { horizontalStackCss } from "src/theme/spacing";
import { remTextSizes } from "src/theme/text";
import { logger } from "src/utility/logger";

const ApplePayText = styled.span``;
const ApplePayLogo = styled.span``;

const applePayCss = css`
  /* Calculate the height of a large button - safari will resize contents accordingly */
  height: calc(2 * ${spacing.m} + ${remTextSizes[TextSize.m]});

  @supports (-webkit-appearance: -apple-pay-button) {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate;
    -apple-pay-button-style: black;

    > * {
      display: none;
    }
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    --apple-pay-scale: 1;

    background-color: black;
    color: white;

    ${ApplePayLogo} {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
      width: calc(35px * var(--apple-pay-scale));
      height: 100%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      margin-left: calc(2px * var(--apple-pay-scale));
      border: none;
    }
    ${ApplePayText} {
      font-family: -apple-system;
      font-size: calc(1em * var(--apple-pay-scale));
      font-weight: 300;
      align-self: center;
      margin-right: calc(2px * var(--apple-pay-scale));
    }
  }
`;

const googlePayCss = css`
  background: rgba(0, 0, 0, 1);
  &:hover {
    background: rgba(46, 52, 52, 1);
  }
`;

export interface PaymentRequestButtonProps
  extends Omit<ButtonProps, "onClick" | "role" | "data-tname"> {
  donateAction: DonateModalAction;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
}

enum PaymentRequestStatus {
  NOT_STARTED,
  PENDING,
  SUCCESS,
}

// TODO #8478: parametrize currency?
export const PaymentRequestButton: React.FCC<PaymentRequestButtonProps> = ({
  donateAction,
  disabled,
  form,
  formContext,
  submitDonation,
  handleConfirmedDonation,
  nonprofit,
  createOrUpdateDonationResult,
  ...rest
}) => {
  const { paymentRequestInitializer } = formContext;
  const { readyStatus, isApplePay, initializePaymentRequest } =
    paymentRequestInitializer;
  const stripe = useStripe();
  const [paymentRequestStatus, setPaymentRequestStatus] =
    useState<PaymentRequestStatus>(PaymentRequestStatus.NOT_STARTED);
  const navigate = useNavigate();
  const router = useEdoRouter();

  const { amount, frequency, privateNote, commentText } = form.watch();
  const { successUrl, minValue, maxValue, shorten } = formContext;

  const registerPaymentRequestEventHandlers = useCallback(
    (paymentRequest: PaymentRequest) => {
      paymentRequest.on("cancel", () => {
        setPaymentRequestStatus(PaymentRequestStatus.NOT_STARTED);
      });
      paymentRequest.on("paymentmethod", async (paymentMethodEvent) => {
        const { payerEmail, payerName, paymentMethod } = paymentMethodEvent;
        if (payerEmail) {
          // Do we need to set both fields?
          form.setValue("email", payerEmail);
          form.setValue("payerEmail", payerEmail);
        }
        if (payerName) {
          form.setValue("payerName", payerName);
        }
        form.setValue("paymentRequestPaymentMethodId", paymentMethod.id);
        try {
          const result = await submitDonation({
            ...form.getValues(),
            paymentTab: isApplePay
              ? PaymentRequestType.APPLE_PAY
              : PaymentRequestType.GOOGLE_PAY,
          });
          if (!result) {
            paymentMethodEvent.complete("fail");
            setPaymentRequestStatus(PaymentRequestStatus.NOT_STARTED);
            return;
          }
          paymentMethodEvent.complete("success");
          setPaymentRequestStatus(PaymentRequestStatus.SUCCESS);
          if (!handleConfirmedDonation(result)) {
            navigate(
              paymentProcessRouteNameToPathMap[
                PaymentProcessRouteName.THANK_YOU
              ]
            );
          }
        } catch (e) {
          if (e instanceof Error) {
            if (
              payerEmail &&
              e.message &&
              // TODO Return more granular statuses for donations so we're not
              // relying on parsing an API error
              (e.message as string).includes("already registered")
            ) {
              redirectToLogin({
                router,
                amount: amount?.toString() || "",
                frequency,
                userEmail: payerEmail,
                nonprofitSlug: nonprofit.primarySlug,
                successUrl,
              });
            }
            form.setError(DONATE_FORM_ERROR, getMessageForError(e));
            paymentMethodEvent.complete("fail");
            setPaymentRequestStatus(PaymentRequestStatus.NOT_STARTED);
          }
        }
      });
    },
    [
      form,
      submitDonation,
      isApplePay,
      handleConfirmedDonation,
      navigate,
      router,
      amount,
      frequency,
      nonprofit.primarySlug,
      successUrl,
    ]
  );

  const onPaymentRequestButtonClicked = useCallback(
    (paymentRequest: PaymentRequest | undefined) => {
      const validated =
        validateAmountAndFrequency({
          amount: amount?.toString() || "",
          frequency,
          minValue,
          maxValue,
          shorten,
          setError: form.setError,
        }) &&
        validateCommentText(
          privateNote,
          form.setError,
          "privateNote",
          nonprofit.metadata?.privateNoteLimit
        ) &&
        validateCommentText(commentText, form.setError, "commentText");

      if (
        !paymentRequest ||
        !validated ||
        donateAction === DonateModalAction.UPDATE ||
        !frequency
      ) {
        return;
      }
      const value = decodeOrThrow(currencyValueCodec, {
        amount: formContext.amountToCharge,
        currency: Currency.USD,
      });
      paymentRequest.update({
        total: {
          label: `${DonationFrequencyToUserFacingTextMap[frequency]} gift for ${nonprofit.name}`,
          amount: currencyValueToMinimumDenominationAmount({ value }),
        },
      });
      form.clearErrors(DONATE_FORM_ERROR);

      setPaymentRequestStatus(PaymentRequestStatus.PENDING);
      paymentRequest.show();

      setTimeout(() => {
        if (!paymentRequest.isShowing()) {
          form.setError(DONATE_FORM_ERROR, {
            type: "string",
            message: "Allow pop-ups, then try again",
          });
          paymentRequest.on("paymentmethod", (paymentMethodEvent) => {
            paymentMethodEvent.complete("fail");
          });

          setPaymentRequestStatus(PaymentRequestStatus.NOT_STARTED);
        }
      }, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      amount,
      donateAction,
      form.setError,
      formContext.amountToCharge,
      frequency,
      maxValue,
      minValue,
      nonprofit.name,
      shorten,
      privateNote,
    ]
  );

  if (readyStatus === PaymentRequestReadyStatus.UNINITIALIZED) {
    return <Loading />;
  }

  const paymentPending = paymentRequestStatus === PaymentRequestStatus.PENDING;

  return readyStatus === PaymentRequestReadyStatus.UNABLE ? (
    <React.Fragment />
  ) : (
    <Button
      {...rest}
      disabled={disabled || paymentPending}
      data-tname="paymentRequestButton"
      role={ButtonRole.PRIMARY}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: async () => {
          if (!stripe) {
            logger.error({
              message: "Tried to do payment request, but stripe wasn't loaded",
            });
            return;
          }
          const pr = await initializePaymentRequest(stripe);
          if (!pr) {
            return; // if was able to make before, should be able to make it now
          }
          registerPaymentRequestEventHandlers(pr);
          onPaymentRequestButtonClicked(pr);
        },
      }}
      css={[isApplePay ? applePayCss : googlePayCss, { width: "100%" }]}
    >
      {paymentPending ? (
        <Loading
          iconProps={{
            display: IconDisplay.CURRENT_COLOR,
            size: IconSize.MEDIUM,
          }}
        />
      ) : isApplePay ? (
        <React.Fragment>
          <ApplePayText />
          <ApplePayLogo />
        </React.Fragment>
      ) : (
        <div
          css={[
            horizontalStackCss.xxxs,
            { alignItems: "center", justifyContent: "center" },
          ]}
        >
          <span>Donate with</span>
          <Icon
            size={IconSize.SMALL}
            display={IconDisplay.CURRENT_COLOR}
            iconImport={() => import("@components/Icon/icons/GoogleIcon")}
          />
          <span>Pay</span>
        </div>
      )}
    </Button>
  );
};
