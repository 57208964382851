import React, { createContext, useReducer } from "react";

import { PersonalDonationResponse } from "@every.org/common/src/codecs/entities";

import {
  MyDonationsAction,
  MyDonationsActionType,
} from "src/context/MyDonationsContext/types";

type MyDonationsState = {
  donationsById: Map<PersonalDonationResponse["id"], PersonalDonationResponse>;
  loaded: boolean;
};

function myDonationsReducer(
  state: MyDonationsState,
  action: MyDonationsAction
): MyDonationsState {
  const updatedMap = new Map(state.donationsById);
  switch (action.type) {
    case MyDonationsActionType.CANCEL_DONATION: {
      updatedMap.delete(action.data.id);
      return { ...state, donationsById: updatedMap };
    }
    case MyDonationsActionType.ADD_DONATION:
    case MyDonationsActionType.UPDATE_DONATION:
      return {
        ...state,
        donationsById: updatedMap.set(action.data.id, action.data),
      };
    case MyDonationsActionType.ADD_DONATIONS: {
      action.data.forEach((curRecurringDonation) => {
        updatedMap.set(curRecurringDonation.id, curRecurringDonation);
      });
      return { ...state, donationsById: updatedMap };
    }
    case MyDonationsActionType.SET_LOADED:
      return { ...state, loaded: true };
  }
}

const initialState: MyDonationsState = {
  donationsById: new Map(),
  loaded: false,
};

export const MyDonationsContext = createContext<MyDonationsState>(initialState);

export let dispatchMyDonationsAction: React.Dispatch<MyDonationsAction>;

export const MyDonationsProvider: React.FCC = ({ children }) => {
  const [myDonationsState, myDonationsDispatcher] = useReducer(
    myDonationsReducer,
    initialState
  );

  dispatchMyDonationsAction = myDonationsDispatcher;

  return (
    <MyDonationsContext.Provider value={myDonationsState}>
      {children}
    </MyDonationsContext.Provider>
  );
};
