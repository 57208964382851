import { useContext, useEffect, useState } from "react";

import { LikeableType } from "@every.org/common/src/entity/types";
import {
  likeDonationRouteSpec,
  unlikeDonationRouteSpec,
} from "@every.org/common/src/routes/donation";
import {
  likeNonprofitRouteSpec,
  unlikeNonprofitRouteSpec,
} from "@every.org/common/src/routes/nonprofit";
import {
  likeNonprofitTagRouteSpec,
  unlikeNonprofitTagRouteSpec,
} from "@every.org/common/src/routes/tag";

import {
  dispatchLikesAction,
  LikesContext,
  LikeChange,
  LikeIdentificator,
  LikesActionType,
} from "src/context/LikesContext";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

function getToggleLikeApiRouteSpec(
  likeableType: LikeableType,
  actionType: LikesActionType.SUBMIT_LIKE | LikesActionType.SUBMIT_UNLIKE
) {
  let route;

  switch (likeableType) {
    case LikeableType.DONATION:
      route =
        actionType === LikesActionType.SUBMIT_LIKE
          ? likeDonationRouteSpec
          : unlikeDonationRouteSpec;
      break;
    case LikeableType.TAG:
      route =
        actionType === LikesActionType.SUBMIT_LIKE
          ? likeNonprofitTagRouteSpec
          : unlikeNonprofitTagRouteSpec;
      break;
    case LikeableType.FUND:
    case LikeableType.NONPROFIT:
      route =
        actionType === LikesActionType.SUBMIT_LIKE
          ? likeNonprofitRouteSpec
          : unlikeNonprofitRouteSpec;
  }

  return route;
}

export default function useLike(
  initData: LikeIdentificator & {
    loggedInUserLikes: boolean | null;
    likeCount: number | null;
  },
  onChange?: (change: LikeChange) => void
) {
  const likesState = useContext(LikesContext);
  const [addedToContext, setAddedToContext] = useState(false);
  const { id, type } = initData;
  const likeData = likesState[type].get(id);

  async function onLike() {
    if (!likeData) {
      return;
    }
    dispatchLikesAction({
      type: LikesActionType.SUBMIT_LIKE,
      data: { id, type, ...likeData },
    });

    try {
      await sendLikeRequest({
        actionType: LikesActionType.SUBMIT_LIKE,
        likeableType: type,
      });
    } catch (err) {
      onChange && onChange(LikeChange.ERROR);
    } finally {
      dispatchLikesAction({
        type: LikesActionType.LIKE_SAVED,
        data: { id, type },
      });

      onChange && onChange(LikeChange.LIKED);
    }
  }

  async function onUnlike() {
    if (!likeData) {
      return;
    }
    dispatchLikesAction({
      type: LikesActionType.SUBMIT_UNLIKE,
      data: { id, type, ...likeData },
    });
    try {
      await sendLikeRequest({
        actionType: LikesActionType.SUBMIT_UNLIKE,
        likeableType: type,
      });
    } catch (err) {
      onChange && onChange(LikeChange.ERROR);
    } finally {
      dispatchLikesAction({
        type: LikesActionType.UNLIKE_SAVED,
        data: { id, type },
      });
      onChange && onChange(LikeChange.UNLIKED);
    }
  }

  async function sendLikeRequest({
    actionType,
    likeableType,
  }: {
    actionType: LikesActionType.SUBMIT_LIKE | LikesActionType.SUBMIT_UNLIKE;
    likeableType: LikeableType;
  }) {
    const likeRoute = getToggleLikeApiRouteSpec(likeableType, actionType);

    try {
      await queryApi(likeRoute, {
        routeTokens: { id },
        queryParams: {},
        body: {},
      });
    } catch (err) {
      if (err instanceof Error) {
        logger.warn({ message: err.message, data: { id } });
        throw new Error(err.message);
      }
    }
  }

  useEffect(() => {
    if (addedToContext) {
      return;
    }

    if (!likeData) {
      dispatchLikesAction({
        type: LikesActionType.ADD_TO_CONTEXT,
        data: {
          ...initData,
          loggedInUserLikes: initData.loggedInUserLikes || false,
          likeCount: initData.likeCount || 0,
        },
      });
      setAddedToContext(true);
    }
  }, [addedToContext, initData, likeData, likesState]);

  return {
    ...likeData,
    likeCount: likeData?.likeCount || initData.likeCount || 0,
    id,
    type,
    onLike,
    onUnlike,
  };
}
