import { CardCover } from "@components/CardCover";
import {
  CoverContainer,
  DarkCover,
} from "@components/NewFundraiserCard/styles";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";

export const CoverSection: React.FCC<{
  fundraiser: FundraiserResponse;
  nonprofit: NonprofitResponse;
  rounded?: boolean;
}> = ({ fundraiser, nonprofit, rounded = false, children }) => (
  <CoverContainer rounded={rounded}>
    {children && <DarkCover />}
    <CardCover
      coverImageCloudinaryId={
        fundraiser.coverImageCloudinaryId
          ? fundraiser.coverImageCloudinaryId
          : nonprofit.coverImageCloudinaryId
      }
      alt={fundraiser.metadata?.coverImageAltText}
      height={160}
      largeScreenHeight={320}
      editable={false}
    />
    {children}
  </CoverContainer>
);
