import { css } from "@emotion/react";

import {
  numPxLineHeights,
  numPxTextSizes,
  TextSize,
} from "@every.org/common/src/display/text";
import { mapValues } from "@every.org/common/src/helpers/objectUtilities";

import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";

export { numPxTextSizes, TextSize } from "@every.org/common/src/display/text";

/**
 * CSS variables that dictate text properties
 *
 * TODO: expand this
 */
export enum TextCssVar {
  SIZE = "--textSize",
  LINE_HEIGHT = "--lineHeight",
}

/**
 * Text sizes expressed as rems, to respect user text size preferences
 *
 * Unless you absolutely need to, prefer to use the `textSizeCss` mixins instead
 * of this directly - they set appropriate CSS vars for better extensibility
 *
 * Standard default text size is 16px, hence dividing by 16.
 */
export const remTextSizes = mapValues({
  obj: numPxTextSizes,
  mapper: (sizePx) => `${sizePx / 16}rem`,
});

const remLineHeights = mapValues({
  obj: numPxLineHeights,
  mapper: (sizePx) => `${sizePx / 16}rem`,
});

const remTextSizesAndLineHeights = Object.fromEntries(
  Object.keys(remTextSizes).map((key) => [
    key,
    [remTextSizes[key], remLineHeights[key]],
  ])
);
/**
 * CSS Mixins to apply the proper text size to a given element
 *
 * Sets sizes via a CSS variable, which allows for more flexible programmatic
 * manipulation of font sizes - @see truncatedTextCss for an example
 */
export const textSizeCss = mapValues({
  obj: remTextSizesAndLineHeights,
  mapper: ([sizeTextRem, lineHeight]) => css`
    ${TextCssVar.SIZE}: ${sizeTextRem};
    ${TextCssVar.LINE_HEIGHT}: ${lineHeight};
    line-height: var(${TextCssVar.LINE_HEIGHT});
    font-size: var(${TextCssVar.SIZE});
  `,
});

export enum FontPropertiesCssVar {
  /**
   * Name of font
   */
  FONT_FAMILY = "--fontFamily",
}

/**
 * font details retrieved by loading regular font into
 * https://opentype.js.org/font-inspector.html
 * NOTE: there are multiple places in the font header tables that list out font
 * measurements, I used the OS/2 ones
 */
export const basisFontPropertiesCss = css`
  ${FontPropertiesCssVar.FONT_FAMILY}: "Basis Grotesque", Helvetica, sans-serif;
  font-family: var(${FontPropertiesCssVar.FONT_FAMILY});
`;

/**
 * Font weights available for use in the app
 *
 * If you add a new font weight (and you should really consider not for
 * performance reasons), please also add a prefetch link element to the
 * `public/index.html` <head /> element
 */
export enum FontWeight {
  REGULAR = "400",
  MEDIUM = "500",
  BOLD = "700",
}

/**
 * In our design, it's common for pages to have a "long" heading that summarizes
 * the page, which this styles
 */
export const longH2Css = [
  css`
    font-weight: ${FontWeight.REGULAR};
  `,
  textSizeCss[TextSize.m],
  cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: [
      textSizeCss[TextSize.xl],
      css`
        font-weight: ${FontWeight.REGULAR};
      `,
    ],
  }),
];

/**
 * In our design, it's common for pages to have small section headings that
 * demarcate a section but are not visually prominent, which this styles
 */
export const secondaryMetadataTextCss = css`
  text-transform: uppercase;
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.xxs]};
  color: var(${colorCssVars.text.secondary});
`;

export const MAX_TEXT_WIDTH = "650px";
/**
 * Common styles for bodies of text to be formatted as a composition of
 * paragraphs (think a blog post or news article)
 */
export const compositionTextCss = css`
  strong,
  b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  table,
  blockquote {
    &:not(:last-child) {
      margin-bottom: ${spacing.m};
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: ${MAX_TEXT_WIDTH};
    padding-right: ${spacing.m};
  }

  p:not(:last-child) {
    margin-bottom: ${spacing.l};
  }

  p + h1,
  p + h2 {
    margin-top: ${spacing.xxl};
  }
  p + h3 {
    margin-top: ${spacing.xl};
  }

  ul,
  ol {
    list-style-position: outside;
    /* to compensate for bullet points being outside */
    margin-left: ${spacing.l};
    ${verticalStackCss.xs};
    /* to include bullets into width */
    max-width: calc(${MAX_TEXT_WIDTH} - ${spacing.l});
    & > li {
      padding-right: ${spacing.m};
    }
  }
  ul {
    list-style-type: disc;
    margin-bottom: ${spacing.m};
  }

  ol {
    list-style-type: decimal;
    &[type="a"] {
      list-style-type: lower-alpha;
    }
    &[type="A"] {
      list-style-type: upper-alpha;
    }
    &[type="i"] {
      list-style-type: lower-roman;
    }
    &[type="I"] {
      list-style-type: upper-roman;
    }
  }
`;
