import React, { createContext, useReducer } from "react";

enum ErrorMessageActionType {
  ERROR_MESSAGE_ADD = "ERROR_MESSAGE_ADD",
  ERROR_MESSAGE_DELETE = "ERROR_MESSAGE_DELETE",
}

interface ErrorMessageState {
  message: string | null;
}

interface AddErrorMessage {
  type: ErrorMessageActionType.ERROR_MESSAGE_ADD;
  data: { message: string };
}

interface RemoveErrorMessage {
  type: ErrorMessageActionType.ERROR_MESSAGE_DELETE;
  data: { message: null };
}

type ErrorMessageAction = AddErrorMessage | RemoveErrorMessage;

function errorMessageReducer(
  state: ErrorMessageState,
  action: ErrorMessageAction
): ErrorMessageState {
  switch (action.type) {
    case ErrorMessageActionType.ERROR_MESSAGE_ADD:
      return { message: action.data.message };
    case ErrorMessageActionType.ERROR_MESSAGE_DELETE:
      return { message: null };
    default:
      throw new Error(`Error message action with unknown type: ${action}`);
  }
}

const INITIAL_STATE: ErrorMessageState = {
  message: null,
};

let dispatchErrorMessageAction: React.Dispatch<ErrorMessageAction>;

export const SearchErrorMessageContext =
  createContext<ErrorMessageState>(INITIAL_STATE);

export const SearchErrorMessageProvider: React.FCC = ({ children }) => {
  const [errorMessageState, errorMessageDispatcher] = useReducer(
    errorMessageReducer,
    INITIAL_STATE
  );

  dispatchErrorMessageAction = errorMessageDispatcher;

  return (
    <SearchErrorMessageContext.Provider value={errorMessageState}>
      {children}
    </SearchErrorMessageContext.Provider>
  );
};

export function addSearchErrorMessage(message: string) {
  if (dispatchErrorMessageAction) {
    dispatchErrorMessageAction({
      type: ErrorMessageActionType.ERROR_MESSAGE_ADD,
      data: { message },
    });
  }
}

export function removeSearchErrorMessage() {
  if (dispatchErrorMessageAction) {
    dispatchErrorMessageAction({
      type: ErrorMessageActionType.ERROR_MESSAGE_DELETE,
      data: { message: null },
    });
  }
}
