import iotsReporter from "@osdiab/io-ts-reporters";
import { Left } from "fp-ts/Either";
import { Errors } from "io-ts";

import { ApplicationError } from "./ApplicationError";

export const reporter = iotsReporter.report;

export class IotsCodecError extends ApplicationError<{
  errorMessages: string[];
  errors: Left<Errors>;
}> {
  public constructor(errors: Left<Errors>) {
    super("Validation errors from io-ts codec", {
      errorMessages: iotsReporter.report(errors),
      errors: errors,
    });
  }
}
