/**
 * Simple circular approve/unapprove button for a donation.
 */
import { Button, ButtonTargetKind, ButtonRole } from "@components/Button";
import { Loading } from "@components/LoadingIndicator";
import React, { useContext, useState } from "react";

import {
  DonationChargeResponse,
  PersonalDonationChargeResponse,
} from "@every.org/common/src/codecs/entities";
import {
  approveRouteSpec,
  unApproveRouteSpec,
} from "@every.org/common/src/routes/admin";

import { ApprovesContext, setApproves } from "src/context/ApprovesContext";
import { queryApi } from "src/utility/apiClient";

export const DonationApproveButton: React.FCC<{
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
  className?: string;
}> = ({ donationCharge, className }) => {
  const { isLoading, donationIds } = useContext(ApprovesContext);
  const [submitting, setSubmitting] = useState(false);
  const donationId = donationCharge.donation.id;
  const isApproved = donationIds?.has(donationId);

  if (isLoading || submitting) {
    return <Loading />;
  }

  async function approveDonation() {
    setSubmitting(true);
    await queryApi(approveRouteSpec, {
      routeTokens: {},
      queryParams: { donationId },
      body: {},
    });
    setApproves({
      isLoading,
      donationIds: new Set([donationId, ...donationIds]),
    });
    setSubmitting(false);
  }

  async function unapproveDonation() {
    setSubmitting(true);
    await queryApi(unApproveRouteSpec, {
      routeTokens: {},
      queryParams: { donationId },
      body: {},
    });
    setApproves({
      isLoading,
      donationIds: new Set([...donationIds].filter((x) => x !== donationId)),
    });
    setSubmitting(false);
  }

  return (
    <Button
      className={className}
      data-tname="approveButton"
      role={ButtonRole.PRIMARY}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: () => {
          isApproved ? unapproveDonation() : approveDonation();
        },
      }}
      disabled={submitting}
    >
      {isApproved ? "Turn off approve" : "Approve for landing page"}
    </Button>
  );
};
