import { css } from "@emotion/react";

import { colorPalette } from "@every.org/common/src/display/palette";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  TextSize,
  textSizeCss,
  FontWeight,
  basisFontPropertiesCss,
} from "src/theme/text";

export const textStyles = [
  css`
    :root {
      ${basisFontPropertiesCss};
      ${textSizeCss[TextSize.s]};
    }

    ::selection {
      color: ${colorPalette.blackWarmHex};
      background: ${colorPalette.tealLogo};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${FontWeight.BOLD};
    }

    h1 {
      ${textSizeCss[TextSize.xl]};
      letter-spacing: -0.5px;
    }

    h2 {
      ${textSizeCss[TextSize.l]};
      letter-spacing: -0.5px;
    }

    /* right now, h3-h6 all look the same */
    h3,
    h4,
    h5,
    h6 {
      ${textSizeCss[TextSize.m]};
    }

    code {
      font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
        monospace;
    }
  `,
  cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      h1 {
        ${textSizeCss[TextSize.xxl]};
      }

      h2 {
        ${textSizeCss[TextSize.xl]};
      }

      h3 {
        ${textSizeCss[TextSize.l]};
      }

      /* right now, h4-h6 all look the same */
      h4,
      h5,
      h6 {
        ${textSizeCss[TextSize.m]};
      }
    `,
  }),
];
