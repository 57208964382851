import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";

import { colorCssVars, darkBgThemeNoBgCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";

const pageSectionContentCss = css`
  ${verticalStackCss.xxs};
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${spacing.l};
  ${darkBgThemeNoBgCss};
  h2 > span {
    color: var(${colorCssVars.accent.large});
  }
  p {
    opacity: 0.8;
  }
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      ${verticalStackCss.xs};
      padding-top: ${spacing.xxl};
    `,
  })}
`;

export function FGCNonprofitHeader() {
  return (
    <PageSection
      css={css`
        flex-grow: 100;
      `}
      contentCss={pageSectionContentCss}
    >
      <h2>
        New monthly donations will be matched up to <span>$50</span> for the
        first two months
      </h2>
      <p>#FallGivingChallenge</p>
    </PageSection>
  );
}
