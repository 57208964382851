import { ConstructCloudinaryUrlParams } from "@every.org/common/src/helpers/cloudinary";

import { overlayAvatarOnDefaultOpengraphCloudinaryParams } from "src/utility/opengraph";

/**
 * Expects image with height OPENGRAPH_DIMENSIONS.height
 *
 * @param xOffset optional horizontal offset from center in pixels
 * @returns a transform that overlays an avatar in a way that looks decent on
 * top of a nonprofit cover image
 */
function overlayAvatarOnCoverImageTransform(params: {
  avatarCloudinaryId: string;
  xOffset?: number;
  imageHeight: number;
}) {
  return [
    `l_${params.avatarCloudinaryId.replace("/", ":")}`, // overlay this image
    "r_max", // round border radius
    "bo_2px_solid_rgb:ffffff", // white border
    "c_scale", // scale the image based on the following
    `h_${Math.floor(params.imageHeight / 2)}`, // "avatar should be 50% of image height"
    `y_${Math.floor(params.imageHeight / 5)}`, // "avatar should be 20% from vertical center of image"
    params.xOffset && `x_${Math.floor(params.xOffset)}`,
  ]
    .filter((part) => !!part)
    .join(",");
}

/**
 * @returns URL to a nonprofit share image featuring the nonprofit's avatar,
 * its cover image, and optionally a user's profile image
 *
 * For details on how cloudinary overlays work:
 * @see {@link https://cloudinary.com/documentation/image_transformations#adding_image_overlays}
 * @see {@link https://cloudinary.com/documentation/image_transformation_reference}
 */
export function nonprofitShareImageCloudinaryParams(params: {
  logoCloudinaryId: string | null;
  coverImageCloudinaryId: string | null;
  userAvatarCloudinaryId?: string | null;
  imageHeight: number;
}): Omit<ConstructCloudinaryUrlParams, "dimensions"> | null {
  const avatarIds = [
    params.userAvatarCloudinaryId,
    params.logoCloudinaryId,
  ].filter((x) => !!x) as [] | [string] | [string, string];
  if (avatarIds.length === 0) {
    if (!params.coverImageCloudinaryId) {
      return null;
    }
    return {
      cloudinaryId: params.coverImageCloudinaryId,
      fillMode: "fill",
    };
  }
  return params.coverImageCloudinaryId
    ? {
        cloudinaryId: params.coverImageCloudinaryId,
        fillMode: "fill",
        transforms: [
          overlayAvatarOnCoverImageTransform({
            avatarCloudinaryId: avatarIds[0],
            xOffset:
              avatarIds.length === 2 ? -params.imageHeight / 5 : undefined,
            imageHeight: params.imageHeight,
          }),
          avatarIds.length === 2
            ? overlayAvatarOnCoverImageTransform({
                avatarCloudinaryId: avatarIds[1],
                xOffset: params.imageHeight / 5,
                imageHeight: params.imageHeight,
              })
            : undefined,
        ].filter((x): x is string => !!x),
      }
    : overlayAvatarOnDefaultOpengraphCloudinaryParams({
        avatarCloudinaryId: avatarIds[0],
        secondAvatarCloudinaryId: avatarIds[1],
      });
}
