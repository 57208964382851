/**
 * Enum of most common bots/crawlers and their useragent name
 */
export enum UserBotType {
  BAIDU = "Baidu",
  BING = "Bing",
  DUCK_DUCK_GO = "Duckduckbot",
  GHOST_INSPECTOR = "Ghost Inspector",
  GOOGLE = "Google",
  GOOGLE_LIGHTHOUSE = "Chrome-Lighthouse",
  FACEBOOK = "Facebook",
  FLIPBOARD = "Flipboard",
  PRERENDER = "Prerender",
  TEOMA = "Teoma",
  TUMBLR = "Tumblr",
  WHATSAPP = "Whatsapp",
  QUORA = "Quora",
  YAHOO = "Slurp",
  YANDEX = "Yandex",
  OTHER_BOT = "Other",
}

const OTHER_BOT_TERMS = [
  "bot",
  "spider",
  "crawler",
  "crawling",
  "Bitrix link preview",
  "Qwantify",
  "SkypeUriPreview",
  "nuzzel",
  "outbrain",
  "vkShare",
  "W3C_Validator",
  "embedly",
  "XING-contenttabreceiver",
];
/**
 * Never call this on the client, instead use getClientBotType
 * which memoizes the result.
 */
export const getUserAgentBotType = (userAgent?: string): UserBotType | null => {
  if (!userAgent) {
    return null;
  }

  for (const botType of Object.values(UserBotType)) {
    // OTHER is a special case, handle separately
    if (botType == UserBotType.OTHER_BOT) {
      continue;
    }

    // Go through and see if the useragent matches any of these
    if (new RegExp(botType, "i").test(userAgent)) {
      return botType as UserBotType;
    }
  }

  if (new RegExp(OTHER_BOT_TERMS.join("|"), "i").test(userAgent)) {
    return UserBotType.OTHER_BOT;
  }

  return null;
};
