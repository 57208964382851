import { Icon, IconDisplay, IconSize } from "@components/Icon";
import type { CSSInterpolation } from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";

import { colorCssVars } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";

const HiddenRadio = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 2px solid ${SHARED_PALETTE.inputBorder};
  cursor: pointer;
  position: relative;

  ${HiddenRadio}:checked + & {
    border: 2px solid var(${colorCssVars.accent.large});
  }

  ${HiddenRadio}:checked + &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(${colorCssVars.accent.large});
  }

  ${HiddenRadio}[data-focus-visible-added]:focus + & {
    border-color: ${SHARED_PALETTE.focus};
  }
`;

const StyledLabel = styled.label`
  ${horizontalStackCss.xs};
  cursor: pointer;
  display: inline-flex;
`;

export interface RadioInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "as" | "label"> {
  className?: string;
  label?: React.ReactNode;
  labelCss?: CSSInterpolation;
  "data-tname": string;
}

export const RadioInput: React.FCC<RadioInputProps> = ({
  className,
  label,
  labelCss,
  ...props
}) => {
  return (
    <StyledLabel className={className} htmlFor={props.id}>
      <span css={{ display: "inherit" }}>
        <HiddenRadio role="radio" type="radio" {...props} />
        <StyledRadio />
      </span>
      {label && <span css={labelCss}>{label}</span>}
    </StyledLabel>
  );
};

/**
 * AlternativeRadioInput component represents a radio input with TaskCircleIcon.
 */
export const AlternativeRadioInput: React.FCC<RadioInputProps> = ({
  className,
  label,
  labelCss,
  ...props
}) => {
  return (
    <StyledLabel className={className} htmlFor={props.id}>
      <span css={{ display: "inherit" }}>
        <HiddenRadio role="radio" type="radio" {...props} />
        <Icon
          size={IconSize.MEDIUM}
          display={IconDisplay.ACCENT}
          iconImport={() =>
            props.checked
              ? import("src/components/Icon/icons/TaskCircleCompleteIcon")
              : import("src/components/Icon/icons/TaskCircleIcon")
          }
        />
      </span>
      {label && <span css={labelCss}>{label}</span>}
    </StyledLabel>
  );
};
