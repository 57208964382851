import { useContext, useMemo } from "react";

import {
  PersonalUserResponse,
  GuestUserResponse,
  ABTestingId,
} from "@every.org/common/src/codecs/entities";
import {
  AdminLevel,
  atLeastAsPrivilegedAs,
} from "@every.org/common/src/entity/types";

import { AuthContext } from "src/context/AuthContext";
import { isLoggedIn } from "src/context/AuthContext/selectors";
import { AuthStatus } from "src/context/AuthContext/types";
import { getTestingId } from "src/utility/abtesting";

export function useLoggedInOrGuestUserOrUndefined():
  | PersonalUserResponse
  | GuestUserResponse
  | undefined {
  const authState = useContext(AuthContext);
  return authState.user || authState.guestUser;
}

/**
 * Danger only use this function if you are certain you are authenticated.
 */
export function useLoggedInUser(): PersonalUserResponse {
  const user = useLoggedInUserOrUndefined();
  if (!user) {
    throw Error(
      "Can only call useLoggedInUser from an authenticated client route"
    );
  }
  return user;
}

export function useIsLoggedIn(): boolean {
  const authState = useContext(AuthContext);
  return isLoggedIn(authState);
}

export function useLoggedInUserOrUndefined(): PersonalUserResponse | undefined {
  const authState = useContext(AuthContext);
  return useMemo(
    () =>
      authState.status === AuthStatus.LOGGED_IN ? authState.user : undefined,
    [authState.status, authState.user]
  );
}

export function useABTestingId(): ABTestingId | undefined {
  const authState = useContext(AuthContext);
  return getTestingId({ authState, createIdIfMissing: false });
}

export function useIsSuperAdmin() {
  const user = useLoggedInUserOrUndefined();

  const isSuperAdmin = atLeastAsPrivilegedAs({
    level: user?.adminLevelForDisplay || AdminLevel.RESTRICTED,
    compareAgainst: AdminLevel.SUPER_ADMIN,
  });

  return isSuperAdmin;
}
