const units = {
  now: 0,
  m: 60000,
  h: 3600000,
  d: 86400000,
  w: 604800000,
  mo: 2629743000,
  y: 31556926000,
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function timeDifferenceString(from: Date, to: Date): string {
  const delta = Math.abs(from.getTime() - to.getTime());

  const unit = Object.keys(units).find(
    (key) =>
      units[key] ===
      Math.max(
        ...Object.entries(units)
          .map(([unit, number]) => number)
          .filter((number) => number <= delta)
      )
  );

  if (!unit) {
    return "";
  }

  if (unit === "now") {
    return `Now`;
  }

  if (unit === "mo") {
    return `${months[to.getMonth()]}`;
  }

  const time = Math.floor(delta / units[unit]);

  return `${time}${unit}`;
}
