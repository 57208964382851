import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { Card } from "@components/Card";
import { NonprofitLink } from "@components/NonprofitLink";
import React, { ReactNode } from "react";

import { useParentNonprofit } from "src/context/NonprofitsContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { horizontalStackCss } from "src/theme/spacing";

const PROJECT_NAME_PLACEHOLDER = "{projectName}";
const PARENT_NAME_PLACEHOLDER = "{parentLink}";

interface ParentNonprofitNoteProps {
  nonprofit: ContextNonprofit;
}

export function ParentNonprofitNote({ nonprofit }: ParentNonprofitNoteProps) {
  const parentNonprofit = useParentNonprofit(nonprofit);

  if (!parentNonprofit) {
    return null;
  }

  const splitComponents =
    parentNonprofit.metadata?.fiscalSponsorCustomDisclaimer
      ?.replace(PROJECT_NAME_PLACEHOLDER, nonprofit.name)
      .split(PARENT_NAME_PLACEHOLDER);
  // First turn the string into a list splitting by PARENT_NAME_PLACEHOLDER.
  // Then add a <NonprofitLink> after each element of the list to join it later.
  // This mimics a `replace(PARENT_NAME_PLACEHOLDER, <NonprofitLink>` function.
  const customDisclaimerComponents = splitComponents?.reduce(
    (acc: ReactNode[], part: string, index: number) => {
      if (index + 1 !== splitComponents?.length) {
        return [
          ...acc,
          part,
          <NonprofitLink
            key={`parentLink-${index}`}
            nonprofit={parentNonprofit}
            data-tname={`ParentNonprofitDisclaimerLink-${index}`}
          />,
        ];
      }
      return [...acc, part];
    },
    []
  );

  return (
    <Card css={[horizontalStackCss.xs]}>
      <NonprofitAvatar nonprofit={parentNonprofit} size={AvatarSize.XX_SMALL} />
      <span>
        {customDisclaimerComponents ? (
          customDisclaimerComponents
        ) : (
          <React.Fragment>
            {nonprofit.name} is hosted at{" "}
            <NonprofitLink
              nonprofit={parentNonprofit}
              data-tname="ParentNonprofitDisclaimerLink"
            />
          </React.Fragment>
        )}
      </span>
    </Card>
  );
}
