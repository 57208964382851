import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import {
  colorPalette,
  SHARED_PALETTE,
} from "@every.org/common/src/display/palette";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { textSizeCss, FontWeight } from "src/theme/text";

interface SelectorOption {
  value: string;
  title: React.ReactNode;
  description?: React.ReactNode;
}

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  justify-content: center;
  color: var(--mainColor);
  font-weight: ${FontWeight.MEDIUM};

  border: 2px solid var(--mainColor);
  border-right: none;

  input:not(:disabled) + &:hover {
    background-color: rgba(1, 134, 105, 0.1);
    color: var(${colorCssVars.accent.small});
  }
`;

const ToggleSelectorItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;

  input {
    appearance: none;
    height: 0;
  }

  input:checked + label {
    background-color: var(--mainColor);
    color: ${colorPalette.white};
  }
  input:checked + label:hover {
    background-color: var(--mainColor);
    color: ${colorPalette.white};
  }

  input[data-focus-visible-added]:focus + label {
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: -2px;
      box-shadow: 0px 0px 5px 1px ${SHARED_PALETTE.focus};
    }
  }

  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      h3 {
        ${textSizeCss.m}
      }
      span {
        ${textSizeCss.s}
      }
    `,
  })}
`;

const ToggleSelectorItemsWrapper = styled.div<{ disabled?: boolean }>`
  --mainColor: var(
    ${({ disabled }) =>
      disabled ? colorCssVars.text.secondary : colorCssVars.accent.large}
  );

  display: flex;
  width: 100%;

  ${ToggleSelectorItem}:first-of-type > ${StyledLabel} {
    border-radius: 6px 0 0 6px;
    &::after {
      border-radius: 6px 0 0 6px;
    }
  }

  ${ToggleSelectorItem}:last-of-type > ${StyledLabel} {
    border-radius: 0 6px 6px 0;
    border-right: 2px solid var(--mainColor);
    &::after {
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const ToggleSelector = ({
  selectorOptions,
  onChange,
  option,
  disabled,
}: {
  selectorOptions: SelectorOption[];
  onChange: (value: string) => void;
  option?: string;
  disabled?: boolean;
}) => {
  return (
    <ToggleSelectorItemsWrapper disabled={disabled}>
      {selectorOptions.map(({ value, title }) => {
        return (
          <ToggleSelectorItem
            key={value}
            onClick={() => {
              !disabled && onChange(value);
            }}
          >
            <input
              type="radio"
              value={value}
              id={`checkbox_${value}`}
              checked={value === option}
              disabled={disabled}
              onChange={() => {
                onChange(value);
              }}
            />
            <StyledLabel htmlFor={`checkbox_${value}`}>{title}</StyledLabel>
          </ToggleSelectorItem>
        );
      })}
    </ToggleSelectorItemsWrapper>
  );
};
