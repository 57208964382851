import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";

import {
  feedItemResponseCodec,
  nonprofitResponseCodec,
  tagResponseCodec,
  userResponseCodec,
} from "../codecs/entities";
import { configVariableCodec } from "../entity/types/configVariable";
import { cryptoAddressCodec } from "../entity/types/crypto";
import { HttpMethod } from "../helpers/http";

import { tagListResultCodec } from "./search";

import { listParamsCodec, listResponseCodec, makeCachedRouteSpec } from ".";

const getLandingDataParamsCodec = t.type({});
const getLandingDataBodyParamsCodec = t.type({});
const getLandingDataResponseBodyCodec = t.type({
  items: t.array(feedItemResponseCodec),
  users: t.array(userResponseCodec),
  nonprofits: t.array(nonprofitResponseCodec),
  nonprofitTags: t.array(tagResponseCodec),
});
export const getLandingDataRouteSpec = makeCachedRouteSpec({
  path: "/landingData",
  method: HttpMethod.GET,
  version: 1,
  tokensCodec: t.type({}),
  paramsCodec: getLandingDataParamsCodec,
  bodyCodec: getLandingDataBodyParamsCodec,
  responseBodyCodec: getLandingDataResponseBodyCodec,
});

export const CAUSE_FEED_PAGE_SIZE = 50;
export const CAUSE_FEED_MAX_PAGE_NUM = 4;
export const getTrendingCausesRouteSpec = makeCachedRouteSpec({
  path: "/causes/trending",
  method: HttpMethod.GET,
  version: 1,
  tokensCodec: t.type({}),
  // TODO: accept currency param for the total donated amount
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: t.intersection([
    t.type({ tags: t.array(tagResponseCodec) }),
    listResponseCodec,
  ]),
});

export const getTagRouteSpec = makeCachedRouteSpec({
  path: "/tag/:identifier/data",
  method: HttpMethod.GET,
  version: 1,
  tokensCodec: t.type({ identifier: t.string }),
  // TODO: accept currency param for the total donated amount
  paramsCodec: t.partial({ onlyActive: BooleanFromString }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ tag: tagResponseCodec }),
});

export const listTagsRouteSpec = makeCachedRouteSpec({
  path: "/causes/all",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: tagListResultCodec,
});

/**
 * This is a backup endpoint in case /feed has scaling issues.
 * If we cannot serve you a personalized feed and the request times
 * out (or is instantly blocked by cloudflare) then client will instead
 * request this cached publicFeed endpoint to show you recent, popular
 * items without any personalization.
 */
export const getPublicHomeFeedRouteSpec = makeCachedRouteSpec({
  path: "/publicFeed",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    items: t.array(feedItemResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    users: t.array(userResponseCodec),
  }),
});

/**
 * Get global feature variables
 */
export const getConfigVariablesRouteSpec = makeCachedRouteSpec({
  path: "/configVariables",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    configVariables: t.array(configVariableCodec),
  }),
});

/**
 * Get our public crypto donation addresses
 */
export const getCryptoAddressesRouteSpec = makeCachedRouteSpec({
  path: "/crypto-addresses",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.partial({
    addresses: t.array(cryptoAddressCodec),
  }),
});
