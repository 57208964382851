import { Big } from "big.js";
import * as t from "io-ts";

import { CURRENCY_SCALE } from "../entity/constants";
import { CryptoCurrency } from "../entity/types/crypto";
import { isBig } from "../helpers/big";

import { stringEnumCodec } from "./";

/**
 * A custom `io-ts` type for our crypto currency enum. Validates that the value we
 * receive is a member of the crypto currency enum.
 */
export const cryptoCurrencyCodec = stringEnumCodec({
  name: "CryptoCurrency",
  enumObject: CryptoCurrency,
});

export const cryptoCurrencyAmountCodec = new t.Type<Big, string>(
  "CryptoCurrencyAmount",
  isBig,
  /**
   * Decode an unknown value to a `CryptoCurrencyValue` object, or fail if not a
   * validly serialized `CryptoCurrencyValue` (see `CurrencyValueSerialized`).
   */
  function decode(unknownValue, context): t.Validation<Big> {
    if (
      !(
        typeof unknownValue === "string" ||
        typeof unknownValue === "number" ||
        isBig(unknownValue)
      )
    ) {
      return t.failure(unknownValue, context, "value was not a Big instance");
    }
    const amountString = unknownValue.toString();
    if (amountString.toLowerCase().includes("e")) {
      return t.failure(
        unknownValue,
        context,
        "Exponential numbers not allowed"
      );
    }

    try {
      return t.success(new Big(amountString));
    } catch (e) {
      return t.failure(
        unknownValue,
        context,
        "Could not convert amount to Big"
      );
    }
  },
  function encode(value): string {
    // represent at maximum scale we store at, with trailing 0's removed
    return value.toFixed(CURRENCY_SCALE).replace(/0+$/, "");
  }
);

export const cryptoCurrencyValueCodec = t.type({
  currency: cryptoCurrencyCodec,
  amount: cryptoCurrencyAmountCodec,
});
/**
 * Refers to an amount of a currency.
 */
export type CryptoCurrencyValue = t.TypeOf<typeof cryptoCurrencyValueCodec>;

export type CryptoCurrencyValueSerialized = {
  [key in keyof CryptoCurrencyValue]: string;
};
