import { IconProps } from "@components/Icon";

import {
  EXTERNAL_PALETTE,
  DARK_THEME,
} from "@every.org/common/src/display/palette";
import { ShareMedium } from "@every.org/common/src/entity/types";

export interface BrowserShareData {
  text: string;
  url: string;
}

export const ShareMeta: {
  [key in Exclude<ShareMedium, ShareMedium.NATIVE | ShareMedium.COPY>]: {
    name: string;
    color: string;
    iconImport: IconProps["iconImport"];
  };
} = {
  [ShareMedium.FACEBOOK]: {
    iconImport: () => import("../Icon/icons/FacebookIcon"),
    color: EXTERNAL_PALETTE.facebookBlue,
    name: "Facebook",
  },
  [ShareMedium.TWITTER]: {
    iconImport: () => import("../Icon/icons/XLogoCircleIcon"),
    color: EXTERNAL_PALETTE.xBlack,
    name: "X",
  },
  [ShareMedium.WHATSAPP]: {
    iconImport: () => import("../Icon/icons/WhatsappLogoCircleIcon"),
    color: EXTERNAL_PALETTE.whatsappGreen,
    name: "WhatsApp",
  },
  [ShareMedium.REDDIT]: {
    iconImport: () => import("../Icon/icons/RedditLogoCircleIcon"),
    color: EXTERNAL_PALETTE.redditOrange,
    name: "Reddit",
  },
  [ShareMedium.LINKEDIN]: {
    iconImport: () => import("../Icon/icons/LinkedinLogoCircleIcon"),
    color: EXTERNAL_PALETTE.linkedinBlue,
    name: "LinkedIn",
  },
  [ShareMedium.EMAIL]: {
    iconImport: () => import("../Icon/icons/MailCircleIcon"),
    color: DARK_THEME.text.secondary,
    name: "Email",
  },
  [ShareMedium.LINK]: {
    iconImport: () => import("../Icon/icons/LinkCircleIcon"),
    color: DARK_THEME.text.secondary,
    name: "Copy link",
  },
};
