import { AvatarSize } from "@components/Avatar";
import { Button, ButtonTargetKind } from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { TagIllustrationLabel } from "@components/TagIllustrationLabel";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback } from "react";

import { joinCauses, splitCauses } from "@every.org/common/src/helpers/causes";

import { submitSearchAction } from "src/context/SearchContext";
import { useSearchOptionsFromUrl } from "src/context/SearchContext/helpers";
import { useTag } from "src/context/TagContext/hooks";
import { tagOrUndefined } from "src/context/TagContext/selectors";
import { ButtonRole } from "src/styles/button";
import { colorCssVars, darkBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

interface SearchBadgeProps {
  value: string;
}

const SearchBadge = styled.span`
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      ${horizontalStackCss.xs};
      display: inline-flex;
      padding: 6px;
      color: white;
      border-radius: 500px;
      font-weight: ${FontWeight.MEDIUM};
    `,
  })}
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      color: var(${colorCssVars.accent.small});
      background: unset;
    `,
  })}
`;

const IconContainer = styled.div`
  border-radius: 500px;
  ${darkBgThemeCss};
  background: rgba(255, 255, 255, 0.3);
  padding: ${spacing.xxs};
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: { display: "none" },
  })}
`;

const TagIllustrationLabelStyled = styled(TagIllustrationLabel)`
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      div {
        display: none;
      }
    `,
  })}
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      .SearchBadges-tag {
        color: white;
      }
    `,
  })},
`;

const DeleteButton = ({ onDelete }: { onDelete: () => void }) => (
  <Button
    aria-label="Deselect filter"
    data-tname="deleteButton"
    role={ButtonRole.UNSTYLED}
    onClick={{ kind: ButtonTargetKind.FUNCTION, action: onDelete }}
    buttonCss={cssForMediaSize({
      max: MediaSize.MEDIUM,
      css: { display: "none" },
    })}
  >
    <Icon
      iconImport={() => import("@components/Icon/icons/XIcon")}
      size={IconSize.SMALL}
      display={IconDisplay.CURRENT_COLOR}
    />
  </Button>
);

export const AddressSearchBadge = ({ value }: SearchBadgeProps) => {
  const urlSearchOptions = useSearchOptionsFromUrl();

  const onDelete = useCallback(() => {
    submitSearchAction &&
      submitSearchAction({
        ...urlSearchOptions,
        address: undefined,
        lat: undefined,
        lng: undefined,
      });
  }, [urlSearchOptions]);

  return (
    <SearchBadge
      css={{
        background: `var(${colorCssVars.accent.largeHighlight})`,
      }}
    >
      <IconContainer>
        <Icon
          iconImport={() => import("@components/Icon/icons/LocationIcon")}
          size={IconSize.X_SMALL}
          display={IconDisplay.CURRENT_COLOR}
        />
      </IconContainer>
      <span css={textSizeCss[TextSize.s]}>{value.split(",")[0]}</span>
      <DeleteButton onDelete={onDelete} />
    </SearchBadge>
  );
};

export const SizeSearchBadge = ({ value }: SearchBadgeProps) => {
  const urlSearchOptions = useSearchOptionsFromUrl();

  const onDelete = useCallback(() => {
    submitSearchAction &&
      submitSearchAction({
        ...urlSearchOptions,
        size: undefined,
      });
  }, [urlSearchOptions]);

  return (
    <SearchBadge
      css={{
        background: `var(${colorCssVars.accent.largeHighlight})`,
      }}
    >
      <IconContainer>
        <Icon
          iconImport={() => import("@components/Icon/icons/OrganizationIcon")}
          size={IconSize.X_SMALL}
          display={IconDisplay.CURRENT_COLOR}
        />
      </IconContainer>
      <span css={textSizeCss[TextSize.s]}>{value}</span>
      <DeleteButton onDelete={onDelete} />
    </SearchBadge>
  );
};

export const TagSearchBadge = ({ value: tagName }: SearchBadgeProps) => {
  const tag = tagOrUndefined(useTag({ tagName }));

  const urlSearchOptions = useSearchOptionsFromUrl();

  const onDelete = useCallback(() => {
    const causes =
      urlSearchOptions.causes &&
      joinCauses(
        splitCauses(urlSearchOptions.causes)?.filter((name) => tagName !== name)
      );

    submitSearchAction &&
      submitSearchAction({
        ...urlSearchOptions,
        causes,
      });
  }, [tagName, urlSearchOptions]);

  if (!tag) {
    return null;
  }

  return (
    <SearchBadge
      css={{
        background: `var(${
          colorCssVars.causeCategory[tag.causeCategory].large
        })`,
      }}
    >
      <TagIllustrationLabelStyled
        tag={tag}
        size={AvatarSize.XX_SMALL}
        className="SearchBadges-tag"
      />
      <DeleteButton onDelete={onDelete} />
    </SearchBadge>
  );
};
