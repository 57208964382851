import { AchievementRow } from "@components/Achievements/AchievementRow";
import { TilingCauseCategories } from "@components/TilingCauseCategories";
import { DefaultPageLayoutQueryParam } from "@components/layout/DefaultPageLayout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useCallback, useEffect } from "react";

import { clientRouteMetas } from "@every.org/common/src/helpers/clientRoutes";
import { getAvailableGivingCreditRouteSpec } from "@every.org/common/src/routes/me";

import { AvatarSize } from "src/components/Avatar";
import { Button, ButtonRole, ButtonTargetKind } from "src/components/Button";
import { Loading } from "src/components/LoadingIndicator";
import { Modal } from "src/components/Modal";
import { useAchievements } from "src/context/AchievementsContext";
import { AchievementFetchStatus } from "src/context/AchievementsContext/types";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { colorCssVars, darkBgThemeCss } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  verticalStackCss,
  spacing,
} from "src/theme/spacing";
import { textSizeCss, FontWeight } from "src/theme/text";
import { useApi } from "src/utility/apiClient";
import { ApiStatus } from "src/utility/apiClient/types";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { getWindow } from "src/utility/window";

const NO_WELCOME_MODAL_PATHS = new Set([clientRouteMetas.GIFT_CARD.path]);

const CircleNumber = styled.div`
  ${darkBgThemeCss};
  border-radius: 100%;
  width: ${spacing.xl};
  height: ${spacing.xl};
  background-color: var(${colorCssVars.accent.large});
  display: flex;
  justify-content: center;
  text-align: center;
`;

const NoBreak = styled.span`
  white-space: nowrap;
`;

const BoldText = styled.span`
  color: var(${colorCssVars.accent.smallHighlight});
  font-weight: ${FontWeight.BOLD};
`;

const ACHIEVEMENTS_TO_SHOW = 3;
const AvailableAchievements = () => {
  const achievementsContext = useAchievements();
  if (achievementsContext.status !== AchievementFetchStatus.READY) {
    return <Loading />;
  }

  const { availableAchievements } = achievementsContext;
  return (
    <div css={verticalStackCss.m}>
      <span>Here are some great ways to get started.</span>
      <div css={verticalStackCss.l}>
        {availableAchievements
          .slice(0, ACHIEVEMENTS_TO_SHOW)
          .map((achievement, i) => {
            return <AchievementRow key={i} achievement={achievement} />;
          })}
      </div>
    </div>
  );
};

const Walkthrough = () => {
  const pressVerb =
    "ontouchstart" in (getWindow()?.window || {}) ? "tap" : "click";
  return (
    <ol
      css={css`
        ${horizontalStackCss.xl}
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: ${spacing.m} ${spacing.xs};

        ${cssForMediaSize({
          min: MediaSize.MEDIUM,
          css: css`
            grid-gap: ${spacing.m};
            grid-template-columns: repeat(3, 1fr);
          `,
        })}

        ${CircleNumber} {
          flex-shrink: 0;
          display: flex;
          align-items: center;

          ${cssForMediaSize({
            min: MediaSize.MEDIUM,
            css: css`
              margin-right: auto;
            `,
          })}
        }

        > li {
          ${horizontalStackCss.s};
          width: 100%;
          align-items: center;
          ${cssForMediaSize({
            min: MediaSize.MEDIUM,
            css: [
              verticalStackCss.s,
              css`
                width: auto;
                align-items: flex-start;
              `,
            ],
          })};
        }
      `}
    >
      <li>
        <CircleNumber>
          <span>1</span>
        </CircleNumber>
        <p>
          Search for your favorite charity, or see where and why your network
          gives!
        </p>
      </li>
      <li>
        <CircleNumber>
          <span>2</span>
        </CircleNumber>
        <p>
          Donate with a few {pressVerb}s to any nonprofit and Give 100% if you
          use a bank account.
        </p>
      </li>
      <li>
        <CircleNumber>
          <span>3</span>
        </CircleNumber>
        <p>
          Track all your tax-deductible <NoBreak>501(c)(3)</NoBreak> giving and
          get a single annual donation receipt.
        </p>
      </li>
    </ol>
  );
};

/**
 * Welcome modal for the /?start=giving version of home feed
 * that user sees after sign up.
 */
export const WelcomeModal = () => {
  const router = useEdoRouter();
  const achievementsContext = useAchievements();
  const [isOpen, setIsOpen] = useState(false);
  const loggedInUser = useLoggedInUserOrUndefined();
  const firstName = loggedInUser?.firstName;
  const inviteIncentive = loggedInUser?.signupIncentive;

  const givingCreditResponse = useApi(
    () =>
      isOpen
        ? {
            routeSpec: getAvailableGivingCreditRouteSpec,
            routeTokens: {},
            queryParams: {},
            body: {},
          }
        : null,
    [isOpen]
  );
  const givingCredit =
    givingCreditResponse?.status === ApiStatus.SUCCESS
      ? givingCreditResponse.response
      : null;
  useEffect(() => {
    const showParam =
      new URLSearchParams(router.search).get(
        DefaultPageLayoutQueryParam.SHOW_WELCOME_MODAL
      ) !== null;
    const canShowWelcome = !NO_WELCOME_MODAL_PATHS.has(router.pathname);

    setIsOpen(canShowWelcome && showParam);
  }, [router.search, router.pathname]);
  const onClose = useCallback(() => {
    const params = new URLSearchParams(router.search);
    params.delete(DefaultPageLayoutQueryParam.SHOW_WELCOME_MODAL);
    router.replace(
      `${router.pathname}${
        [...params.keys()].length > 0 ? `?${params.toString()}` : ""
      }${router.hash}`
    );
    setIsOpen(false);
  }, [router]);

  const inviteIncentiveAmountForDisplay =
    inviteIncentive &&
    displayCurrencyValueInUserLocale({
      minDenomCurrencyValue: {
        amountInMinDenom: inviteIncentive.campaign.incentiveAmount,
        currency: inviteIncentive.campaign.incentiveCurrency,
      },
    });

  const getSubheader = useCallback(() => {
    if (inviteIncentive && !inviteIncentive.redeemed) {
      return (
        <p
          css={css`
            color: var(${colorCssVars.text.secondary});
          `}
        >
          You&rsquo;ll get{" "}
          <BoldText>{inviteIncentiveAmountForDisplay}</BoldText> in credit to
          support any nonprofit after your first donation!
        </p>
      );
    }

    if (givingCredit && givingCredit.amount > 0) {
      // TODO #8478: detect user locale and translate to currency?
      const givingCreditText = displayCurrencyValueInUserLocale({
        minDenomCurrencyValue: givingCredit,
      });

      return (
        <p
          css={css`
            color: var(${colorCssVars.text.secondary});
          `}
        >
          You already have <BoldText>{givingCreditText}</BoldText> to support
          any nonprofit!
        </p>
      );
    }

    return null;
  }, [inviteIncentive, givingCredit, inviteIncentiveAmountForDisplay]);

  if (!isOpen || !loggedInUser) {
    return <React.Fragment />;
  }

  return (
    <Modal
      isOpen={isOpen}
      headerText={`Welcome${firstName ? `, ${firstName}` : ""}`}
      showHeader
      footerDecoration={<TilingCauseCategories size={AvatarSize.LARGE} />}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <section
        css={css`
          ${verticalStackCss.l};
          display: flex;
          justify-content: center;
          z-index: 1;
          padding-bottom: ${spacing.xxl};
          ${cssForMediaSize({
            min: MediaSize.SMALL,
            css: verticalStackCss.l,
          })}

          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: css`
              width: 620px;
            `,
          })}
        `}
      >
        <div css={[verticalStackCss.xxs]}>
          <h1
            css={[
              textSizeCss.l,
              cssForMediaSize({
                min: MediaSize.SMALL,
                css: textSizeCss.xl,
              }),
            ]}
          >
            Join the giving movement
          </h1>
          {getSubheader()}
        </div>
        {achievementsContext.status === AchievementFetchStatus.LOADING ? (
          <Loading />
        ) : achievementsContext.status === AchievementFetchStatus.READY &&
          achievementsContext.availableAchievements.length ? (
          <AvailableAchievements />
        ) : (
          <Walkthrough />
        )}
        <Button
          css={css`
            width: 230px;
            margin-top: ${spacing.m};
            align-self: center;
          `}
          data-tname={"getStarted"}
          role={ButtonRole.PRIMARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: onClose,
          }}
        >
          Get started
        </Button>
      </section>
    </Modal>
  );
};
