import { AvatarSize } from "@components/Avatar";
import { Link, LinkAppearance } from "@components/Link";
import { TagIllustrationOld } from "@components/TagIllustration";
import { TagIllustrationLabel } from "@components/TagIllustrationLabel";
import { PreconfiguredPopover } from "@components/feed/NewNonprofitCard/MultiCausesPopover/PreconfiguredPopover";
import {
  HighlightListItem,
  PopoverItemsContainer,
  StyledList,
  StyledListItem,
  tagLabelCss,
} from "@components/feed/NewNonprofitCard/MultiCausesPopover/styles";
import React, { useMemo } from "react";

import { SearchQueryParam } from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";

interface MultiCausesPopover {
  nonprofitTags: ContextNonprofit["nonprofitTags"];
  highlightedCauses?: string[];
}

export function MultiCausesPopover({
  nonprofitTags,
  highlightedCauses: highlightedCausesTagNames,
}: MultiCausesPopover) {
  const highlightedCauses = useMemo(() => {
    return (
      nonprofitTags?.filter(({ tagName }) =>
        highlightedCausesTagNames?.includes(tagName)
      ) || []
    );
  }, [highlightedCausesTagNames, nonprofitTags]);

  const restCauses = useMemo(() => {
    return (
      nonprofitTags?.filter(
        ({ tagName }) => !highlightedCausesTagNames?.includes(tagName)
      ) || []
    );
  }, [highlightedCausesTagNames, nonprofitTags]);

  if (
    !nonprofitTags ||
    !nonprofitTags?.length ||
    (!highlightedCauses.length && !restCauses.length)
  ) {
    return null;
  }

  const popoverContent = (
    <ul css={verticalStackCss.xs}>
      {[...highlightedCauses, ...restCauses].map((cause) => (
        <li key={cause.id} css={tagLabelCss[cause.causeCategory]}>
          <Link
            data-tname={`MultiCausesPopover--${cause.tagName}`}
            appearance={LinkAppearance.UNSTYLED}
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.SEARCH_RESULTS,
              query: {
                [SearchQueryParam.CAUSES]: cause.tagName,
              },
            })}
          >
            <TagIllustrationLabel tag={cause} size={AvatarSize.X_SMALL} />
          </Link>
        </li>
      ))}
    </ul>
  );

  if (highlightedCauses.length) {
    return (
      <PreconfiguredPopover content={popoverContent}>
        <StyledList highlighted>
          <PopoverItemsContainer>
            {highlightedCauses.map((cause, index, { length }) => (
              <HighlightListItem
                key={cause.id}
                causeCategory={cause.causeCategory}
                css={{ zIndex: length - index }}
              >
                <TagIllustrationOld
                  tagName={cause.tagName}
                  tagImageCloudinaryId={cause.tagImageCloudinaryId}
                  causeCategory={cause.causeCategory}
                  size={AvatarSize.SMALL}
                  disableLink
                />
              </HighlightListItem>
            ))}
          </PopoverItemsContainer>
          {restCauses.map((cause, index, { length }) => (
            <StyledListItem key={cause.id} css={{ zIndex: length - index }}>
              <TagIllustrationOld
                tagName={cause.tagName}
                tagImageCloudinaryId={cause.tagImageCloudinaryId}
                causeCategory={cause.causeCategory}
                size={AvatarSize.X_SMALL}
                disableLink
              />
            </StyledListItem>
          ))}
        </StyledList>
      </PreconfiguredPopover>
    );
  }

  return (
    <PreconfiguredPopover content={popoverContent}>
      <StyledList>
        {nonprofitTags.map((cause, index, { length }) => (
          <StyledListItem key={cause.id} css={{ zIndex: length - index }}>
            <TagIllustrationOld
              tagName={cause.tagName}
              tagImageCloudinaryId={cause.tagImageCloudinaryId}
              causeCategory={cause.causeCategory}
              size={AvatarSize.X_SMALL}
              disableLink
            />
          </StyledListItem>
        ))}
      </StyledList>
    </PreconfiguredPopover>
  );
}
