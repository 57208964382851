import { AvatarSize } from "@components/Avatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import { ButtonSize } from "@components/Button";
import { Card } from "@components/Card";
import { FollowButton } from "@components/FollowButton";
import { UserLink } from "@components/UserLink";
import { SecondaryText } from "@components/textHelpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useContext, ReactNode, useState } from "react";

import {
  UserResponse,
  FollowInfoResponse,
} from "@every.org/common/src/codecs/entities";

import { UsersContext } from "src/context/UsersContext";
import { getUser, userOrUndefined } from "src/context/UsersContext/selectors";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import {
  spacing,
  horizontalStackCss,
  verticalStackCss,
} from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";

interface UsersInfo {
  followInfo?: FollowInfoResponse;
  user?: UserResponse;
}

interface UserListOptions {
  showLocation?: boolean;
  onClick?: () => void;
  renderChild?: (user: UserResponse) => ReactNode;
}

const StyledContainer = styled.div`
  flex-grow: 1;
  ${horizontalStackCss.xs};
  align-items: center;
  padding: ${spacing.m} 0;
`;

const UserItem: React.FCC<UsersInfo & UserListOptions> = ({
  user,
  followInfo,
  showLocation,
  onClick,
  renderChild,
}) => {
  const [followerCount, setFollowerCount] = useState<number | undefined>(
    followInfo && followInfo.followerCount
  );

  if (!user || !user.username) {
    return <React.Fragment />;
  }
  return (
    <UserListItem>
      <UserAvatar
        css={{ display: "flex" }}
        size={AvatarSize.SMALL}
        user={user}
        onClick={onClick}
      />
      <StyledContainer>
        <div css={[verticalStackCss.xxs, { flexGrow: 1 }]}>
          <UserLink
            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
            user={user}
            onClick={onClick}
          />
          {!showLocation && followerCount ? (
            <SecondaryText css={[textSizeCss.xs, { lineHeight: "1rem" }]}>
              {followerCount} follower{followerCount > 1 && "s"}
            </SecondaryText>
          ) : null}
          {showLocation && (
            <SecondaryText css={[textSizeCss.xs, { lineHeight: "1rem" }]}>
              {user.locationAddress}
            </SecondaryText>
          )}
        </div>
        <FollowButton
          toFollowUserId={user.id}
          size={ButtonSize.SMALL}
          followerCount={followerCount}
          setFollowerCount={setFollowerCount}
        />
      </StyledContainer>
      {renderChild && renderChild(user)}
    </UserListItem>
  );
};

interface UserListCardProps
  extends Omit<UserListCardFromFollowsProps, "usersFollowInfo"> {
  usersInfo?: UsersInfo[];
  className?: string;
}

export const UserList: React.FCC<
  UserListOptions & {
    usersInfo: UsersInfo[];
  }
> = ({ usersInfo, showLocation, onClick, renderChild }) => {
  return (
    <ul>
      {usersInfo.map(({ user, followInfo }, index) => (
        <UserItem
          key={followInfo?.userId || user?.id || index}
          followInfo={followInfo}
          user={user}
          onClick={onClick}
          showLocation={showLocation}
          renderChild={renderChild}
        />
      ))}
    </ul>
  );
};

/**
 * User list constructed from follows, fetching user objects as needed
 */
export const UserListCard: React.FCC<UserListCardProps> = ({
  title,
  viewMore,
  usersInfo,
  showLocation,
  placeholder,
  className,
}) => {
  return (
    <Card shadowOnHover className={className} css={verticalStackCss.xs}>
      <span
        css={[
          textSizeCss[TextSize.xs],
          css`
            color: var(${colorCssVars.text.secondary});
          `,
        ]}
      >
        {title}
      </span>
      {!usersInfo || (usersInfo.length === 0 && placeholder) ? (
        placeholder
      ) : (
        <React.Fragment>
          <UserList usersInfo={usersInfo} showLocation={showLocation} />
          {viewMore}
        </React.Fragment>
      )}
    </Card>
  );
};

interface UserListCardFromFollowsProps {
  title?: string;
  viewMore?: ReactNode;
  usersFollowInfo: FollowInfoResponse[];
  placeholder?: React.ReactNode;

  /*
   * If present, renders a location,
   * if absent, renders a number of followers below username in Follow Item,
   */
  showLocation?: boolean;
  className?: string;
}

/**
 * User list constructed from follows, fetching user objects as needed
 */
export const UserListFromFollowsCard: React.FCC<
  UserListCardFromFollowsProps
> = ({ usersFollowInfo, ...rest }) => {
  const usersState = useContext(UsersContext);
  const usersInfo = usersFollowInfo.map((followInfo) => ({
    user: userOrUndefined(getUser(usersState, { id: followInfo.userId })),
    followInfo,
  }));
  return <UserListCard {...rest} usersInfo={usersInfo} />;
};

const UserListItem = styled.li`
  position: relative;
  ${horizontalStackCss.s};
  align-items: center;
  &:not(:last-of-type) {
    ${StyledContainer} {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        bottom: -1px;
        border-bottom: 1px solid var(${colorCssVars.dividerSoft});
      }
    }
  }

  &:last-of-type {
    margin-bottom: -${spacing.m};
  }
`;
