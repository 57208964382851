export enum CookieKey {
  INITIAL_REFERRING_PAGE = "initialReferringPage_v2",
  SIGNUP_SHARED_DONATION = "signupSharedDonation_v2",
  SIGNUP_INVITE = "signupInvite_v2",
  LANDING_PAGE = "landingPage_v2",
  SIGNUP_ADMIN_REQUEST_NONPROFIT_ID = "signupAdminRequestNonprofitId_v2",

  SESSION_KEY = "koaSess_v2", // for koa-session
  SESSION_EXISTS = "sessionExists_v2",
  // Cookie which, if present, tells the client that we just created a new user through SSO and should track it
  TRACK_NEW_SSO_USER = "trackNewSSOUser_v2",
  BACKEND_LOGIN_CSRF = "backendLoginCsrf_v2",

  // Key of cookie where testing ID for logged out users is stored; but user ID
  TESTING_ID_CACHE = "everydotorgAbtestId_v2",

  // used to tell the middleware about the user's status (emailVerified, deactivated, profileIncomplete) without having to make an API request
  USER_STATUS = "userStatus_v2",

  /**
   * Cookie used by the backend to send the CSRF cookie sent by the frontend
   * back after authentication has completed
   *
   * The frontend needs to access the cookie in order to validate that the state
   * hasnbeen tampered and it should take action based on authentication like
   * redirecting the user post-login, but post login, the page reloads so we send
   * the token back this way
   */
  RETURN_FRONTEND_AUTH_CSRF = "returnFrontendCsrfToken_v2",
  FORCE_LINK_IDP = "linkIdp_v2",
}
