import {
  Button,
  ButtonTargetKind,
  ButtonProps,
  ButtonRole,
  ButtonSize,
} from "@components/Button";
import { IconDisplay, IconSize } from "@components/Icon";
import { LoadingIcon } from "@components/LoadingIndicator";
import { Modal } from "@components/Modal";
import { useLoginOrSignUpPath } from "@components/Redirect";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useContext, useState, useMemo } from "react";

import { UserResponse } from "@every.org/common/src/codecs/entities";
import { FollowRequestStatus } from "@every.org/common/src/entity/types";
import { URLFormat } from "@every.org/common/src/helpers/clientRoutes";
import { mapValues } from "@every.org/common/src/helpers/objectUtilities";

import { AuthContext } from "src/context/AuthContext";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { AuthStatus } from "src/context/AuthContext/types";
import { UsersContext } from "src/context/UsersContext";
import { follow, unfollow } from "src/context/UsersContext/actions";
import { USER_NOT_FOUND, FETCHING_USER } from "src/context/UsersContext/types";
import { MediaSize, useMatchesScreenSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";

interface FollowButtonProps
  extends Omit<ButtonProps, "onClick" | "data-tname" | "role"> {
  toFollowUserId: UserResponse["id"];
  overrideButtonText?: string;
  setFollowerCount?: (value: number) => void;
  followerCount?: number;
}

const followHoverText = {
  [FollowRequestStatus.ACCEPTED]: "Unfollow",
  [FollowRequestStatus.UNSET]: "Cancel",
};

const buttonContentClassName = "followButtonContent";

const HOVER_TEXT_CSS = mapValues({
  obj: followHoverText,
  mapper: (_, status) =>
    status in followHoverText
      ? css`
          &:hover .${buttonContentClassName} {
            position: relative;
            visibility: hidden;
            &::after {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              visibility: visible;
              content: "${followHoverText[status]}";
            }
          }
        `
      : null,
});

export const FollowButton: React.FCC<FollowButtonProps> = React.memo(
  function FollowButtonImpl({
    toFollowUserId,
    overrideButtonText,
    setFollowerCount,
    followerCount,
    ...rest
  }) {
    const loginOrSignupPath = useLoginOrSignUpPath({
      format: URLFormat.RELATIVE,
    });
    const loggedInUser = useLoggedInUserOrUndefined();
    const authState = useContext(AuthContext);
    const [submitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const usersState = useContext(UsersContext);
    const toFollowUser = usersState.usersById.get(toFollowUserId);

    const isLargeScreen =
      // eslint-disable-next-line no-restricted-syntax
      useMatchesScreenSize({ min: MediaSize.MEDIUM }) ?? false;

    const buttonProps = useMemo<
      (ButtonProps & { text: string }) | undefined
    >(() => {
      if (!toFollowUser || typeof toFollowUser !== "object") {
        return;
      }

      const canUpdateFollowersState =
        !toFollowUser.isPrivate && setFollowerCount && followerCount;

      const processFollow = async () => {
        setIsSubmitting(true);
        await follow(toFollowUser.id);
        canUpdateFollowersState && setFollowerCount(followerCount + 1);
        setIsSubmitting(false);
      };

      const processUnfollow = async () => {
        setIsSubmitting(true);
        await unfollow(toFollowUser.id);
        canUpdateFollowersState && setFollowerCount(followerCount - 1);
        setIsSubmitting(false);
        setShowModal(false);
      };

      function triggerShowModal() {
        setShowModal(true);
      }

      switch (toFollowUser.followedByCurrentUserStatus) {
        case FollowRequestStatus.ACCEPTED:
          return {
            "data-tname": "unfollow",
            onClick: {
              kind: ButtonTargetKind.FUNCTION,
              action: isLargeScreen ? processUnfollow : triggerShowModal,
            },
            role: ButtonRole.SECONDARY,
            text: "Following",
          };
        case FollowRequestStatus.UNSET:
          return {
            "data-tname": "cancel-follow",
            role: ButtonRole.SECONDARY,
            onClick: {
              kind: ButtonTargetKind.FUNCTION,
              action: isLargeScreen ? processUnfollow : triggerShowModal,
            },
            text: "Requested",
          };
        case FollowRequestStatus.NONE:
          return {
            "data-tname": "follow",
            role: ButtonRole.SECONDARY,
            onClick: {
              kind: ButtonTargetKind.FUNCTION,
              action: processFollow,
            },
            text: "Follow",
          };
        case FollowRequestStatus.REJECTED:
          return undefined;
      }
    }, [isLargeScreen, toFollowUser, setFollowerCount, followerCount]);

    if (
      !toFollowUser ||
      toFollowUser === USER_NOT_FOUND ||
      (typeof toFollowUser === "object" && toFollowUser.id === loggedInUser?.id)
    ) {
      return <React.Fragment />;
    }
    if (toFollowUser === FETCHING_USER) {
      return (
        <LoadingIcon display={IconDisplay.SECONDARY} size={IconSize.MEDIUM} />
      );
    }

    if (authState.status === AuthStatus.LOGGED_OUT) {
      return (
        <Button
          disabled={submitting}
          data-tname="followButton-follow-login"
          role={ButtonRole.SECONDARY}
          onClick={{
            kind: ButtonTargetKind.LINK,
            to: loginOrSignupPath,
          }}
          {...rest}
        >
          Follow
        </Button>
      );
    }

    if (!buttonProps) {
      return <React.Fragment />;
    }

    const { text, onClick, ...buttonRest } = buttonProps;

    return (
      <React.Fragment>
        <Button
          css={HOVER_TEXT_CSS[toFollowUser.followedByCurrentUserStatus]}
          contentClassName={buttonContentClassName}
          onClick={onClick}
          disabled={submitting || authState.status === AuthStatus.LOADING}
          {...buttonRest}
          {...rest}
        >
          {overrideButtonText || text}
        </Button>

        <Modal
          isOpen={showModal}
          headerText={"Unfollow Modal"}
          showHeader
          onRequestClose={() => {
            setShowModal(false);
          }}
        >
          <ModalContainer css={verticalStackCss.s}>
            {toFollowUser.followedByCurrentUserStatus ===
              FollowRequestStatus.ACCEPTED && (
              <p>Are you sure you want to unfollow {toFollowUser.firstName}?</p>
            )}
            {toFollowUser.followedByCurrentUserStatus ===
              FollowRequestStatus.UNSET && (
              <p>
                Are you sure you want reject request to follow{" "}
                {toFollowUser.firstName}?
              </p>
            )}
            <ButtonContainer>
              <Button
                data-tname="unfollow-cancel"
                role={ButtonRole.TEXT_ONLY}
                size={ButtonSize.SMALL}
                onClick={{
                  kind: ButtonTargetKind.FUNCTION,
                  action: () => {
                    setShowModal(false);
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                data-tname="unfollow-confirm"
                role={ButtonRole.SECONDARY}
                size={ButtonSize.SMALL}
                onClick={onClick}
              >
                Unfollow
              </Button>
            </ButtonContainer>
          </ModalContainer>
        </Modal>
      </React.Fragment>
    );
  }
);

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
