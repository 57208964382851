import { IconSize, heightForIconSize } from "@components/Icon";
import Image, { ImageProps } from "next/image";
import React from "react";

import {
  srcsetObjectForCloudinaryId,
  ConstructCloudinaryUrlParams,
  ImageFormat,
  Dimensions,
} from "@every.org/common/src/helpers/cloudinary";
export interface CloudinaryImgProps
  extends Omit<ImageProps, "alt" | "src">,
    Omit<ConstructCloudinaryUrlParams, "dimensions"> {
  objectCover?: boolean;
  sizes?: string;
  layout?: "fill" | "responsive" | "fixed" | "intrinsic";
  dimensions?: ConstructCloudinaryUrlParams["dimensions"];
  priority?: ImageProps["priority"];
  alt?: string;
  iconSize?: IconSize;
}

/**
 *
 * @see ConstructCloudinaryUrlParams for details on how to customize the output
 */
export const CloudinaryImg = React.memo(function CloudinaryImgImpl({
  cloudinaryId,
  applySizeAfterTransforms,
  transforms,
  dimensions,
  imageFormat,
  fillMode,
  objectCover = true,
  sizes,
  layout,
  priority,
  alt = "",
  iconSize,
  ...rest
}: CloudinaryImgProps) {
  const finalLayout =
    layout ??
    (!dimensions?.width || !dimensions?.height ? "fill" : "responsive");

  const dimensionsObj =
    iconSize && !dimensions
      ? {
          height: heightForIconSize[iconSize],
          width: heightForIconSize[iconSize],
        }
      : dimensions;

  const imgSrc =
    imageFormat === ImageFormat.svg
      ? srcsetObjectForCloudinaryId({
          cloudinaryId,
          applySizeAfterTransforms,
          dimensions: dimensionsObj as Dimensions,
          imageFormat,
          fillMode,
        })["1x"]
      : cloudinaryId.startsWith("/")
      ? cloudinaryId
      : `/${cloudinaryId}`;

  return (
    <Image
      {...rest}
      src={imgSrc}
      layout={finalLayout}
      style={{
        objectFit: objectCover ? "cover" : "contain",
      }}
      {...dimensionsObj}
      sizes={sizes}
      unoptimized={imageFormat === ImageFormat.svg}
      priority={priority}
      alt={alt}
    />
  );
});
