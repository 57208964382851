/**
 * Component to add and select a payment method during donation flow, along with
 * prompts to encourage giving using bank account.
 *
 * Shows the default selected payment method, if any, and allows the user to
 * change it.
 */

import { ButtonTargetKind, Button, ButtonRole } from "@components/Button";
import { IconSize, IconDisplay } from "@components/Icon";
import { TextInputLabelText } from "@components/InputContainer";
import { Loading, LoadingIcon } from "@components/LoadingIndicator";
import {
  PaymentsContainer,
  PaymentMethodList,
  AddCardPaymentItem,
  AddBankPaymentItem,
  PaymentMethodItem,
  AddNewCardPaymentWidget,
  bankPlaceholderItem,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentMethod";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { SecondaryText } from "@components/textHelpers";
import { Big } from "big.js";
import React, { useState, useCallback, useEffect, useMemo } from "react";

import {
  MAX_NUM_PAYMENT_METHODS,
  PaymentSourceResponse as PaymentSource,
  StripePaymentSourceResponse as StripePaymentSource,
} from "@every.org/common/src/codecs/entities";
import { PaymentSourceType } from "@every.org/common/src/entity/types";

import { trackEvent } from "src/utility/analytics";

function feeHelperText(type: PaymentSourceType): string {
  switch (type) {
    case PaymentSourceType.CARD:
      return "";
    case PaymentSourceType.ACH_DEBIT:
      return "100% of your support will go to the nonprofit.";
    default:
      return "";
  }
}

interface PaymentSourceListProps {
  className?: string;
  /**
   * Giving credit to consume, which will be listed as a payment source.
   */
  creditAmount: Big;

  /**
   * Amount to charge on the selected payment source.
   */
  amountToCharge: Big;

  creditsOnly: boolean;

  selectedPaymentSourceType: PaymentSourceType;

  /**
   * Whether a payment source such as a card or bank account is needed. If true,
   * renders the selected payment source or the options to edit/add a new source.
   */
  renewablePaymentSourceRequired: boolean;

  paymentSources?: DonateFormContext["paymentSources"];
  setPaymentSources: DonateFormContext["setPaymentSources"];

  selectedPaymentSource: PaymentSource | undefined;
  setSelectedPaymentSource: (paymentSource?: PaymentSource) => void;
  setErrorMessage: (error?: string) => void;
  label?: string;
  disallowAddingType?: Set<StripePaymentSource["type"]>;
  /**
   * Hide the helper text and do not allow PayPal for gift card purchases.
   */
  isGiftCardPurchase?: boolean;
  onAddingCard?: (isAddingCard: boolean) => void;
  firstName?: string;
  lastName?: string;
  email?: string;
  clearFormError?: () => void;
}

export const SelectPaymentSource: React.FCC<PaymentSourceListProps> = (
  props
) => {
  const {
    paymentSources,
    setPaymentSources,
    selectedPaymentSource,
    selectedPaymentSourceType,
    setSelectedPaymentSource,
    setErrorMessage,
    creditsOnly,
    renewablePaymentSourceRequired,
    className,
    label,
    isGiftCardPurchase,
    onAddingCard,
    firstName,
    lastName,
    email,
    clearFormError,
  } = props;
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [newBankAccountDetailsLoading, setNewBankAccountDetailsLoading] =
    useState(false);

  const [addBankAccountTriggered, setAddBankAccountTriggered] = useState(false);
  const [addBankAccountBtn, setAddBankAccountBtn] =
    useState<HTMLElement | null>(null);
  const [oldPaymentSource, setOldPaymentSource] = useState(
    selectedPaymentSource
  );

  const disallowAddingType = props.disallowAddingType ?? new Set();

  // remove old payment source if payment sources are empty
  // this is happening for logged out users
  // when paymentSources resets to empty array on user info change
  useEffect(() => {
    if (paymentSources?.length === 0) {
      setOldPaymentSource(undefined);
    }
  }, [paymentSources]);

  // if add bank account is requested but button to add bank account is still
  // being mounted, this waits for it to mount and then clicks the button for
  // you.
  useEffect(
    function triggerAddBankAccount() {
      if (addBankAccountTriggered && !!addBankAccountBtn) {
        setAddBankAccountTriggered(false);
        addBankAccountBtn.click();
      }
    },
    [addBankAccountTriggered, addBankAccountBtn]
  );

  const onPaymentSourceAdded = useCallback(
    (paymentSource: PaymentSource) => {
      setNewBankAccountDetailsLoading(false); // even if wasn't bank acct, this is safe
      setPaymentSources((prevSources) =>
        (prevSources?.filter((s) => s.id !== paymentSource.id) || []).concat(
          paymentSource
        )
      );
      setSelectedPaymentSource(paymentSource);
      setErrorMessage(undefined);
      clearFormError && clearFormError();
      setIsAddingCard(false);
      onAddingCard && onAddingCard(false);
      trackEvent("Payment source added", { type: paymentSource.type });
    },
    [
      setPaymentSources,
      setSelectedPaymentSource,
      setErrorMessage,
      clearFormError,
      onAddingCard,
    ]
  );

  const onAddBankAccountFailure = useCallback(
    (errorMessage: string) => {
      setNewBankAccountDetailsLoading(false); // even if wasn't bank acct, this is safe
      setErrorMessage(errorMessage);
    },
    [setErrorMessage]
  );

  const onPlaidConnectionSuccess = useCallback(() => {
    setNewBankAccountDetailsLoading(true);
  }, []);

  const availablePaymentSources = useMemo(
    () =>
      paymentSources &&
      paymentSources.filter(
        (source) => source.type === selectedPaymentSourceType
      ),

    [paymentSources, selectedPaymentSourceType]
  );

  /**
   * If the currently selected payment source is not in the available list of
   * payment sources, pick either the default or the first payment source from
   * actually available payment sources
   */
  useEffect(() => {
    if (
      availablePaymentSources &&
      selectedPaymentSource &&
      !availablePaymentSources?.find(
        (source) => source.id === selectedPaymentSource.id
      )
    ) {
      setSelectedPaymentSource(
        availablePaymentSources.find((source) => source.default) ||
          availablePaymentSources[0]
      );
    }
  }, [
    availablePaymentSources,
    selectedPaymentSource,
    setSelectedPaymentSource,
  ]);

  if (availablePaymentSources === undefined) {
    return <Loading />;
  }

  if (
    isAddingCard ||
    // Always show add card if no payment sources and type is cc
    (selectedPaymentSourceType === PaymentSourceType.CARD &&
      availablePaymentSources.length === 0 &&
      !creditsOnly)
  ) {
    return (
      <PaymentsContainer className={className}>
        <AddNewCardPaymentWidget
          handleConnectBankAccount={() => {
            setIsAddingCard(false);
            onAddingCard && onAddingCard(false);
            setAddBankAccountTriggered(true);
          }}
          onAddCancelled={() => {
            setIsAddingCard(false);
            setSelectedPaymentSource(oldPaymentSource);
            onAddingCard && onAddingCard(false);
          }}
          // Only allow cancelling when no payment source is visible
          cancellable={!!oldPaymentSource}
          onPaymentSourceAdded={onPaymentSourceAdded}
          firstName={firstName}
          lastName={lastName}
          email={email}
          isAddingCard={isAddingCard}
        />
      </PaymentsContainer>
    );
  }

  const paymentListItems: { elem: React.ReactNode; key: string }[] = [];

  if (renewablePaymentSourceRequired) {
    if (selectedPaymentSource) {
      const onClick = () => {
        setOldPaymentSource(selectedPaymentSource);
        setSelectedPaymentSource(undefined);
      };
      const key =
        selectedPaymentSource?.id || selectedPaymentSource.paymentMethod;
      paymentListItems.push({
        elem: (
          <PaymentMethodItem
            source={selectedPaymentSource}
            onClick={onClick}
            tabIndex={-1}
            end={
              <Button
                data-tname="changePaymentMethodButton"
                role={ButtonRole.TEXT_ONLY}
                onClick={{ kind: ButtonTargetKind.FUNCTION, action: onClick }}
              >
                Change
              </Button>
            }
          />
        ),
        key,
      });
    } else {
      const paymentSourcesToDisplay = newBankAccountDetailsLoading
        ? [bankPlaceholderItem, ...availablePaymentSources]
        : availablePaymentSources;
      paymentSourcesToDisplay.forEach((source) => {
        const isBankPlaceholderItem = source.id === bankPlaceholderItem.id;
        const key = source.id || source.paymentMethod;
        paymentListItems.push({
          elem: (
            <PaymentMethodItem
              source={source}
              onClick={() => {
                setSelectedPaymentSource(source);
                setErrorMessage(undefined);
                clearFormError && clearFormError();
              }}
              // if being deleted, dont allow selecting; if new bank account
              // loading, it will be auto-selected so dont let anything get
              // selected
              disabled={
                // !!deletingPaymentMethod ||
                newBankAccountDetailsLoading
              }
              end={
                isBankPlaceholderItem ? (
                  <LoadingIcon
                    size={IconSize.X_SMALL}
                    display={IconDisplay.SECONDARY}
                  />
                ) : undefined
              }
            />
          ),
          key,
        });
      });
    }
  }

  const canAddSources =
    !selectedPaymentSource &&
    availablePaymentSources.length < MAX_NUM_PAYMENT_METHODS &&
    !creditsOnly;
  const canAddCard =
    canAddSources &&
    !disallowAddingType.has(PaymentSourceType.CARD) &&
    selectedPaymentSourceType === PaymentSourceType.CARD;
  const canAddBank =
    canAddSources &&
    !disallowAddingType.has(PaymentSourceType.ACH_DEBIT) &&
    selectedPaymentSourceType === PaymentSourceType.ACH_DEBIT &&
    !newBankAccountDetailsLoading;

  return (
    <PaymentsContainer className={className}>
      {label && <TextInputLabelText>{label}</TextInputLabelText>}
      <PaymentMethodList>
        {paymentListItems.map(({ elem, key }) => (
          <li key={key}>{elem}</li>
        ))}
        {canAddCard && (
          <li key="addCard">
            <AddCardPaymentItem
              onClick={() => {
                setIsAddingCard(true);
                onAddingCard && onAddingCard(true);
              }}
            />
          </li>
        )}
        {canAddBank && (
          <li key="addBank">
            <AddBankPaymentItem
              ref={setAddBankAccountBtn}
              onPlaidConnectionSuccess={onPlaidConnectionSuccess}
              onCompletedAddingAccount={(result) => {
                if (result.success) {
                  onPaymentSourceAdded(result.paymentSource);
                } else {
                  onAddBankAccountFailure(
                    "Could not add bank account, please try again"
                  );
                }
              }}
              onExit={() => {
                // setIsAddingNewPayment(false);
              }}
              firstName={firstName}
              lastName={lastName}
              email={email}
            />
          </li>
        )}
      </PaymentMethodList>
      {!isGiftCardPurchase &&
        renewablePaymentSourceRequired &&
        selectedPaymentSource &&
        !!selectedPaymentSource.type && (
          <SecondaryText>
            {feeHelperText(selectedPaymentSource.type)}
          </SecondaryText>
        )}
    </PaymentsContainer>
  );
};
