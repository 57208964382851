import {
  PersonalUserResponse,
  GuestUserResponse,
} from "@every.org/common/src/codecs/entities";

export enum AuthStatus {
  LOADING = "LOADING",
  LOGGED_OUT = "LOGGED_OUT",
  LOGGED_IN = "LOGGED_IN",
}

export type AuthContextUser =
  | PersonalUserResponse
  | GuestUserResponse
  | undefined;

export type AuthState =
  | {
      status: AuthStatus.LOADING;
      abTestingId: PersonalUserResponse["abTestingId"] | undefined;
      user: undefined;
      guestUser: undefined;
    }
  | {
      status: AuthStatus.LOGGED_IN;
      abTestingId: PersonalUserResponse["abTestingId"] | undefined;
      user: PersonalUserResponse;
      guestUser: undefined;
    }
  | {
      status: AuthStatus.LOGGED_OUT;
      abTestingId: PersonalUserResponse["abTestingId"] | undefined;
      user: undefined;
      // The guest user is how we track user data if they haven't signed in
      // but have taken some action like creating a donation.
      // We define guest user as a separate property from user to make sure
      // we're not accidentally using the guest user when we only wanted a
      // real one.
      guestUser?: GuestUserResponse;
    };
