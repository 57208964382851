import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyCodec, currencyValueCodec } from "../codecs/currency";
import { dateFromStringCodec } from "../codecs/date";
import {
  appliedNonprofitEditResponseCodec,
  nonprofitEditResponseCodec,
  nonprofitResponseCodec,
  userResponseCodec,
} from "../codecs/entities";
import { DisbursementType, disbursementTypeCodec } from "../entity/types";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec, listParamsCodec, listResponseCodec } from ".";

const approveParamsCodec = t.type({ donationId: uuidCodec });

export const approveRouteSpec = makeRouteSpec({
  path: "/approve",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: approveParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const unApproveRouteSpec = makeRouteSpec({
  path: "/approve",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: approveParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const getAllApprovesRouteSpec = makeRouteSpec({
  path: "/approve",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    approvedDonationIds: t.array(t.string),
  }),
});

const getNonprofitEditsParamsCodec = listParamsCodec;
export const getNonprofitEditsRouteSpec = makeRouteSpec({
  path: "/nonprofitEdits",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: getNonprofitEditsParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: t.intersection([
    t.type({
      edits: t.array(nonprofitEditResponseCodec),
      nonprofits: t.array(nonprofitResponseCodec),
      users: t.array(userResponseCodec),
    }),
    listResponseCodec,
  ]),
});

const applyNonprofitEditBodySpec = t.type({
  nonprofitEditId: uuidCodec,
  accepted: t.boolean,
});
export const applyNonprofitEditRouteSpec = makeRouteSpec({
  path: "/nonprofitEdits",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: applyNonprofitEditBodySpec,
  responseBodyCodec: appliedNonprofitEditResponseCodec,
});

const disbursementKindBodyCodec = t.union([
  t.type({
    type: t.literal(DisbursementType.STRIPE_CONNECT),
    applicationSubmitted: t.boolean,
    email: t.union([t.string, t.null]),
    isConnected: t.boolean,
    isReadyToBeDisbursed: t.boolean,
    stripeAccountLinkURL: t.string,
  }),
  t.type({ type: t.literal(DisbursementType.PAYPAL_GRANTS) }),
  t.type({ type: t.literal(DisbursementType.MANUAL), metadata: t.string }),
  t.type({
    type: t.literal(DisbursementType.NFG_BATCH_FILE),
    isNfgDisbursable: t.boolean,
  }),
]);
export type DisbursementKindBody = t.TypeOf<typeof disbursementKindBodyCodec>;
export const getNonprofitDisbursementKindRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/disbursementKind",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: disbursementKindBodyCodec,
});

export const getAvailableBalancesRouteSpec = makeRouteSpec({
  path: "/getAvailableBalances",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({
    currency: currencyCodec,
    disbursementType: disbursementTypeCodec,
  }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.array(
    t.intersection([
      t.type({
        nonprofit: nonprofitResponseCodec,
        availableBalance: currencyValueCodec,
        blockDisbursementsMemo: t.union([t.string, t.null]),
        lastDisbursementAt: t.union([dateFromStringCodec, t.null]),
      }),
      t.partial({
        // This may include types not fomally supported by the frontend, so we
        // loosen the type here.
        lastDisbursementType: t.string,
        disbursableEntities: t.array(t.type({ id: t.string, name: t.string })),
        disbursementsMemo: t.string,
      }),
    ])
  ),
});

export const getAvailableStripeBalanceRouteSpec = makeRouteSpec({
  path: "/getAvailableStripeBalance",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.array(
    t.type({
      totalAmount: t.number,
      currency: t.string,
      bank: t.number,
      card: t.number,
    })
  ),
});

export const unarchiveNonprofitRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/unarchive",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ archived: t.union([t.boolean, t.null]) }),
});
