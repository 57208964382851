import { BottomActions } from "@components/Funds/BottomActions";
import { Donations } from "@components/Funds/Donations";
import { FundHeaderCard } from "@components/Funds/FundHeaderCard";
import { LearnMore } from "@components/Funds/LearnMore";
import { Nonprofits } from "@components/Funds/Nonprofits";
import { FundPageTabs, TabNavigation } from "@components/Funds/TabNavigation";
import { useJoinDonationParams } from "@components/donate/DonateRouteModal";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { ImageHeaderPageLayout } from "@components/layout/ImageHeaderPageLayout";
import {
  DefaultPageSection,
  headerCardPageSectionCss,
  PageSection,
} from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { Fragment, useEffect, useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import { NonprofitType } from "@every.org/common/src/entity/types";
import {
  clientRouteMetas,
  ClientRouteName,
  URLFormat,
  getRoutePath,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useOpenDonatePage } from "src/hooks/useOpenDonatePage";
import DonateV3Page from "src/pages/DonateV3";
import { ROUGH_HEADER_HEIGHT } from "src/theme/common";
import {
  cssForMediaSize,
  MediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";
import { ClientTrackingRoute, trackPageChange } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import { OPENGRAPH_DIMENSIONS } from "src/utility/opengraph";
import { NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT } from "src/utility/pageMetadata";

const CANDIDATES_HASH = "candidates";

const HiddenAnchor = styled.div`
  display: block;
  position: relative;
  visibility: hidden;
  margin-bottom: 0;
  top: 0;
  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: { top: -ROUGH_HEADER_HEIGHT },
  })}
`;

type FundsPageProps = { fund: ContextNonprofit };

function selectedTabFromPath(
  hasEndorsedNonprofits: boolean,
  hasDonations: boolean,
  hasDescriptionLong: boolean,
  match?: string | null
) {
  if (match) {
    switch (match) {
      case clientRouteMetas[ClientRouteName.FUND_OR_TAG_NONPROFITS].path:
        return FundPageTabs.NONPROFITS;
      case clientRouteMetas[ClientRouteName.FUND_OR_TAG_GIFTS].path:
        return FundPageTabs.GIFTS;
      case clientRouteMetas[ClientRouteName.FUND_LEARN_MORE].path:
        return FundPageTabs.LEARN_MORE;
      case clientRouteMetas[ClientRouteName.NONPROFIT_OR_CAUSE].path:
        break;
    }
  }
  // If a tab is not specified, try to default to a tab that has content
  return hasEndorsedNonprofits
    ? FundPageTabs.NONPROFITS
    : hasDonations
    ? FundPageTabs.GIFTS
    : hasDescriptionLong
    ? FundPageTabs.LEARN_MORE
    : undefined;
}

export const FundsPage: React.FCC<FundsPageProps> = ({ fund }) => {
  const { push, getPathnameWithParams } = useEdoRouter();
  const { path } = getPathnameWithParams();

  const { donationToJoinId, userToJoinId } = useJoinDonationParams();
  const {
    name,
    description,
    descriptionLong,
    coverImageCloudinaryId,
    logoCloudinaryId,
    donationChargesCount,
    endorsedNonprofitsCount,
  } = fund;
  const selectedTab = selectedTabFromPath(
    (fund?.endorsedNonprofitIds?.length || 0) > 0,
    !!donationChargesCount && donationChargesCount > 0,
    !!descriptionLong,
    path
  );

  useEffect(() => {
    fund.type !== NonprofitType.LIST
      ? trackPageChange(ClientTrackingRoute.FUND, fund.name)
      : trackPageChange(ClientTrackingRoute.LIST, fund.name);
  }, [fund.type, fund.name]);

  const isLargeScreen =
    // eslint-disable-next-line no-restricted-syntax
    useMatchesScreenSize({ min: MediaSize.XX_LARGE }) ?? false;

  const { endorsedNonprofitIds } = fund;
  const hasNonprofits = (endorsedNonprofitIds?.length || 0) > 0;

  const openDonatePage = useOpenDonatePage();

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const navigateToFundPage = () => {
    push(
      getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: fund.primarySlug },
      })
    );
  };
  const opengraphImageCloudinaryParams = nonprofitShareImageCloudinaryParams({
    logoCloudinaryId,
    coverImageCloudinaryId,
    imageHeight: OPENGRAPH_DIMENSIONS.height,
  });
  const metas = removeUndefinedOrNullValues({
    "og:description": description,
    "og:image":
      opengraphImageCloudinaryParams &&
      constructCloudinaryUrl({
        ...opengraphImageCloudinaryParams,
        dimensions: OPENGRAPH_DIMENSIONS,
      }),
  });

  if (openDonatePage && !isSSR) {
    return (
      <DonateV3Page
        donateAction={DonateModalAction.DONATE}
        nonprofit={fund}
        isOpen={openDonatePage}
        onRequestClose={navigateToFundPage}
        donationToJoinId={donationToJoinId || undefined}
        userToJoinId={userToJoinId || undefined}
      />
    );
  }

  const pageContents = (
    <React.Fragment>
      <PageSection contentCss={headerCardPageSectionCss}>
        <FundHeaderCard fund={fund} />
      </PageSection>
      <DefaultPageSection>
        <div
          css={[
            verticalStackCss.xl,
            css`
              ${HiddenAnchor} {
                margin: 0;
              }
            `,
            cssForMediaSize({
              max: MediaSize.SMALL,
              css: verticalStackCss.m,
            }),
          ]}
        >
          <Fragment>
            <HiddenAnchor id={CANDIDATES_HASH} />
            <TabNavigation
              fund={fund}
              selectedTab={selectedTab}
              css={[
                { marginBottom: spacing.xl },
                cssForMediaSize({
                  max: MediaSize.SMALL,
                  css: { marginBottom: spacing.l },
                }),
              ]}
              donationsCount={donationChargesCount}
              nonprofitsCount={endorsedNonprofitsCount}
            />
            {selectedTab === FundPageTabs.NONPROFITS && hasNonprofits ? (
              <Nonprofits fund={fund} />
            ) : null}
            {selectedTab === FundPageTabs.GIFTS && <Donations fund={fund} />}
            {selectedTab === FundPageTabs.LEARN_MORE && descriptionLong && (
              <LearnMore content={descriptionLong} />
            )}
            <BottomActions fund={fund} />
          </Fragment>
        </div>
      </DefaultPageSection>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isLargeScreen || !fund.coverImageCloudinaryId ? (
        <DefaultPageLayout
          pageTitle={name}
          metas={metas}
          pageContentCss={{ paddingTop: spacing.l }}
          philanthropyforEveryoneSuffix
        >
          {pageContents}
        </DefaultPageLayout>
      ) : (
        <ImageHeaderPageLayout
          pageTitle={name}
          metas={metas}
          headerOverlapHeight={NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT}
          imgCloudinaryId={fund.coverImageCloudinaryId}
          philanthropyforEveryoneSuffix
        >
          {pageContents}
        </ImageHeaderPageLayout>
      )}
    </React.Fragment>
  );
};
