import { AvatarSize } from "@components/Avatar";
import { ButtonSize, ButtonRole } from "@components/Button";
import { Card } from "@components/Card";
import { maxWidthFeedCardCss } from "@components/feed/shared";
import {
  SkeletonButton,
  SkeletonAvatar,
  SkeletonText,
  SkeletonTextHeight,
  SkeletonAnimationOverlay,
} from "@components/skeleton/SkeletonComponents";
import { css } from "@emotion/react";
import React from "react";

import { colorCssVars } from "src/theme/color";
import {
  verticalStackCss,
  horizontalStackCss,
  spacing,
} from "src/theme/spacing";

export const SkeletonNonprofitRecommendationCard = React.memo(
  function SkeletonNonprofitRecommendationCardImpl() {
    const items = new Array(3).fill(null).map((_, index) => (
      <div
        key={index}
        css={css`
          align-items: center;
          &:not(:last-of-type) {
            border-bottom: 1px solid var(${colorCssVars.dividerSoft});
          }
          padding: ${spacing.m} 0;
          ${horizontalStackCss.xs};

          &:last-of-type {
            padding-bottom: ${spacing.s};
          }
        `}
      >
        <SkeletonAvatar size={AvatarSize.SMALL} />
        <div
          css={css`
            width: 100%;
          `}
        >
          <SkeletonText
            css={css`
              width: 80%;
            `}
            height={20}
          />
        </div>
        <SkeletonButton
          size={ButtonSize.SMALL}
          role={ButtonRole.PRIMARY}
          textSize={SkeletonTextHeight.LARGE}
        />
      </div>
    ));

    return (
      <Card
        css={[
          verticalStackCss.xxs,
          maxWidthFeedCardCss,
          css`
            position: relative;
            overflow: hidden;
          `,
        ]}
      >
        <SkeletonAnimationOverlay />
        <div css={verticalStackCss.s}>
          <SkeletonText
            height={SkeletonTextHeight.MEDIUM}
            css={css`
              max-width: 65%;
            `}
          />
          <div>{items}</div>
        </div>
        <SkeletonButton
          size={ButtonSize.SMALL}
          role={ButtonRole.TEXT_SECONDARY}
          textSize={SkeletonTextHeight.SMALL}
        />
      </Card>
    );
  }
);
