import { getRoutePath, ClientRouteName, URLFormat } from "./clientRoutes";

/**
 * Builds a link to a specific donation page.
 */
export function getUserDonationShareUrl({
  username,
  nonprofitSlug,
  donationId,
  donationShortId,
  extraQueryParams,
  format,
}: {
  username: string;
  donationId: string;
  donationShortId?: number;
  nonprofitSlug?: string;
  extraQueryParams?: Record<string, string>;
  format: URLFormat;
}) {
  if (!nonprofitSlug) {
    // Fallback if nonprofit is missing
    return getRoutePath({
      name: ClientRouteName.USER,
      tokens: { username },
      query: { ...extraQueryParams, donationId },
      format,
    });
  }
  // If donation short ID is missing, just default back to the first one.
  const isFirstDonation = donationShortId === 1;
  // Omit shortId in favor for the simplified alias if it's "1".
  return !donationShortId || isFirstDonation
    ? getRoutePath({
        name: ClientRouteName.USER_DONATION,
        tokens: {
          username,
          nonprofitSlug,
        },
        query: extraQueryParams,
        format,
      })
    : getRoutePath({
        name: ClientRouteName.USER_DONATION_SHORT_ID,
        tokens: {
          username,
          nonprofitSlug,
          shortId: String(donationShortId),
        },
        query: extraQueryParams,
        format,
      });
}

export interface ShareTextArgs {
  nonprofitName?: string;
  isMyDonation?: boolean;
  bodyText?: string | null;
  postscript?: string | null;
}

export const getTwitterShareText = ({
  nonprofitTwitterHandle,
  nonprofitName,
  isMyDonation = false,
  postscript,
  bodyText,
}: ShareTextArgs & {
  nonprofitTwitterHandle?: string | null;
}) => {
  const nonprofitDisplay = nonprofitTwitterHandle
    ? `@${nonprofitTwitterHandle}`
    : nonprofitName || "this cause";

  return getShareText({
    nonprofitName: nonprofitDisplay,
    isMyDonation,
    postscript,
    bodyText,
  });
};

export const getShareText = ({
  nonprofitName,
  isMyDonation = false,
  postscript,
  bodyText,
}: ShareTextArgs) => {
  const nonprofitDisplay = nonprofitName || "this cause";
  let text = `Please ${
    isMyDonation ? "join me in supporting" : "support"
  } ${nonprofitDisplay}.`;
  if (isMyDonation) {
    // Requested by #stoptinyvampires, maybe we should undo later
    text = `I just donated to ${nonprofitDisplay}.`;
  }
  if (bodyText) {
    text += " " + bodyText;
  }
  if (postscript) {
    text += " " + postscript;
  }

  return text;
};

export interface ShareFundraiserArgs {
  fundraiserName: string;
  isMyFundraiser: boolean;
}

export const getFundraiserShareText = ({
  fundraiserName,
  isMyFundraiser,
}: ShareFundraiserArgs) => {
  return isMyFundraiser
    ? `Join my fundraiser: ${fundraiserName}`
    : `Support ${fundraiserName}`;
};
