import { CryptoTipCustom } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/CryptoTipCustom";
import { DefaultTipCustom } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/DefaultTipCustom";
import { StockTipCustom } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/StockTipCustom";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import React from "react";

import { PaymentMethod } from "@every.org/common/src/entity/types";

export const AddTipCustom = (props: AddTipProps) => {
  const paymentMethod = props.form.watch("paymentMethod");

  switch (paymentMethod) {
    case PaymentMethod.CRYPTO:
      return <CryptoTipCustom {...props} />;
    case PaymentMethod.STOCKS:
      return <StockTipCustom {...props} />;
    default:
      return <DefaultTipCustom {...props} />;
  }
};
