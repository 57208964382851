import { getWindow } from "src/utility/window";

/**
 * Type of global resize observer constructor instance, copied from
 * `resize-observer-polyfill` types
 *
 * The `ResizeObserver` and `ResizeObserverCallback` types are supplied by the
 * typings of that library
 */
export interface ResizeObserverConstructor {
  prototype: ResizeObserver;
  new (callback: ResizeObserverCallback): ResizeObserver;
}

let ResizeObserverPonyfill: ResizeObserverConstructor | undefined;

export async function getResizeObserverOrPonyfill(): Promise<
  ResizeObserverConstructor | undefined
> {
  const window = getWindow();
  if (!window) {
    return undefined;
  }
  const BuiltinResizeObserver =
    ("ResizeObserver" in window &&
      (
        window as unknown as {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ResizeObserver: ResizeObserverConstructor;
        }
      ).ResizeObserver) ||
    undefined;
  if (BuiltinResizeObserver) {
    return BuiltinResizeObserver;
  }
  ResizeObserverPonyfill = (await import("resize-observer-polyfill")).default;
  return ResizeObserverPonyfill;
}
