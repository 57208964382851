/**
 * Custom hook used with a EditableText component so that the state can be
 * managed at any level.
 */
import { useState, useEffect } from "react";

import {
  PersonalDonationChargeResponse,
  DonationResponse,
} from "@every.org/common/src/codecs/entities";
import { commentTextCodec } from "@every.org/common/src/codecs/text";

import { updateComment } from "src/context/DonationsContext/actions";
import { logger } from "src/utility/logger";

export interface EditableTextInterface {
  /**
   * Text which contains the latest updates and will be saved.
   */
  text: string;
  /**
   * Updates the text.
   */
  setText: (text: string) => void;
  /**
   * Whether the async saveAction has been called and hasn't yet finished.
   */
  isSaving: boolean;
  /**
   * If defined, contains error message for saving the text.
   */
  errorSavingText?: string;
  /**
   * Updates whether there's an error or not
   */
  setErrorSavingText: (error: string | undefined) => void;
  /**
   * Whether text has been changed since the last save.
   */
  hasTextChanged: boolean;
  /**
   * Triggers saving the text, running through any error checks and updating the
   * error message if there are any errors. Returns true if successful.
   */
  saveText: () => Promise<boolean>;
}

export enum SaveTextActionStatus {
  /**
   * Save successful
   */
  SUCCESS = "SUCCESS",
  /**
   * Save errored
   */
  ERROR = "ERROR",
}
export type SaveTextActionResult =
  | {
      status: SaveTextActionStatus.SUCCESS;
    }
  | {
      status: SaveTextActionStatus.ERROR;
      errorMessage: string;
    };
interface EditableTextProps {
  /**
   * Text to initially set.
   */
  initialText?: string | null;
  /**
   * Async call to save text, often an API call.
   */
  saveAction: (text: string | null) => Promise<SaveTextActionResult>;
  /**
   * Tag for log messages.
   */
  logTag: string;
}

export function useEditableText({
  initialText,
  saveAction,
  logTag,
}: EditableTextProps): EditableTextInterface {
  const [text, setText] = useState(initialText || "");
  const [savedText, setSavedText] = useState(initialText || "");
  const [errorSavingText, setErrorSavingText] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const hasTextChanged = savedText !== text;

  useEffect(() => {
    setText(initialText || "");
  }, [initialText]);

  async function saveText() {
    let success = true;
    if (savedText === text) {
      return true;
    }

    setErrorSavingText(undefined);
    if (!hasTextChanged) {
      return true;
    }

    let toSaveCommentText: string | null = null;
    if (text === "") {
      toSaveCommentText = null;
    } else {
      toSaveCommentText = text;
    }

    if (hasTextChanged) {
      setIsSaving(true);
      try {
        const result = await saveAction(toSaveCommentText);
        if (result.status === SaveTextActionStatus.SUCCESS) {
          setSavedText(text);
        } else if (result.status === SaveTextActionStatus.ERROR) {
          setErrorSavingText(result.errorMessage);
        }
      } catch (e) {
        logger.error({
          error: e,
          data: { logTag },
          message: "An error occurred while saving text",
        });
        setErrorSavingText("Something went wrong... please try again!");
        success = false;
      } finally {
        setIsSaving(false);
      }
    }

    return success;
  }

  return {
    saveText,
    text,
    setText,
    isSaving,
    errorSavingText,
    setErrorSavingText,
    hasTextChanged,
  };
}

export function getSaveCommentCallback(
  donationId: DonationResponse["id"],
  donationCharge?: PersonalDonationChargeResponse
): EditableTextProps["saveAction"] {
  return async function saveComment(
    toSaveCommentText: string | null
  ): Promise<SaveTextActionResult> {
    if (toSaveCommentText === null || commentTextCodec.is(toSaveCommentText)) {
      await updateComment({
        commentText: toSaveCommentText,
        donationId: donationId,
        donationCharge,
      });
      return {
        status: SaveTextActionStatus.SUCCESS,
      };
    }
    return {
      status: SaveTextActionStatus.ERROR,
      errorMessage: "Text is too long.",
    };
  };
}
